import { useLocation } from '@reach/router';
import React, { useMemo } from 'react';
import SideTab from '../../components/Shared/SideTabs/SideTabs';
import { NOTIFICATION_COUNT_TYPES } from '../../components/Shared/SideTabs/notificationCountTypes';
import ToolsBar from '../../components/Shared/ToolBar/ToolBar';
import useCheckSelectedTabAndRefetch from '../../hooks/useCheckSelectedTabAndRefetch';
import useMediaQuery from '../../hooks/useMediaQuery';
import usePaginatedQuery from '../../hooks/usePaginatedQuery';
import { GetBranchOrders } from '../../queries';
import { MainContainer, ViewWrapper } from '../../components/Shared/Shared';
import OrdersList from './OrdersList';
import { ORDERS_TABS_TYPES, ORDER_STATUS_TYPES, ProductOrder } from './types';
import useBranchProductOrdersViewCounts from '../../hooks/useBranchProductOrdersViewCounts';

const Orders = () => {
  const location = useLocation();

  const tabs = [
    { name: ORDERS_TABS_TYPES.NEW, status: ORDER_STATUS_TYPES.NEW, value: ORDERS_TABS_TYPES.NEW.toLowerCase() },
    { name: ORDERS_TABS_TYPES.UPCOMING, status: ORDER_STATUS_TYPES.ACTIVE, value: ORDERS_TABS_TYPES.UPCOMING.toLowerCase() },
    { name: ORDERS_TABS_TYPES.FULFILLED, status: ORDER_STATUS_TYPES.ARCHIVED, value: ORDERS_TABS_TYPES.FULFILLED.toLowerCase() }
  ];

  const tabsValues = tabs.map(tab => tab.value);
  const tabValue = tabsValues?.find(tab => tab === location.pathname.split('/')[3]);
  const selected = tabValue || 'new';

  const SelectedTabStatus = useMemo(() => tabs.find(tab => tab.value === selected)?.status, [selected, JSON.stringify(tabs)]);

  const paginatedQueryResult = usePaginatedQuery<ProductOrder[]>({
    query: GetBranchOrders,
    defaultStatus: ORDER_STATUS_TYPES.NEW,
    otherVariables: { type: 'product', requisite_queries: [], alternative_queries: [], status: SelectedTabStatus },
    limit: 20
  });

  const [[_hasMoreItems, setHasMoreItems], queryResult] = paginatedQueryResult;

  const checkSelectedTabAndRefetch = useCheckSelectedTabAndRefetch<ProductOrder[]>({
    tabs,
    queryResult,
    setHasMoreItems,
    selected
  });

  // const actions = <RefreshButton checkSelectedTabAndRefetch={checkSelectedTabAndRefetch} setHasMoreItems={setHasMoreItems} />;

  // const headerTabs = Object.values(ORDERS_TABS_TYPES);

  const sections = [{ tabs }];

  const { newProductOrdersCount } = useBranchProductOrdersViewCounts();

  return (
    <ViewWrapper>
      {/* <TabsHeader
        tabs={mobile ? headerTabs?.slice(0, -1) : headerTabs}
        actions={actions}
        selected={selected}
        setSelected={setSelected}
        notifications={{
          [ORDERS_TABS_TYPES.NEW]: unreadProductOrdersCount || 0
        }}
      /> */}
      <SideTab
        sections={sections}
        tabsWithCount={[
          {
            tabValue: ORDERS_TABS_TYPES.NEW.toLowerCase(),
            count: newProductOrdersCount || 0,
            requiresAction: true
          }
        ]}
        defaultTab={ORDERS_TABS_TYPES.NEW}
      />

      <MainContainer>
        <ToolsBar urlSearchParam="orders" />
        <OrdersList paginatedQueryResult={paginatedQueryResult} />
      </MainContainer>
    </ViewWrapper>
  );
};

export default Orders;
