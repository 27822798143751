import { useQuery } from '@apollo/client';
import React, { Fragment, useState } from 'react';
import { sentenceCase } from 'sentence-case';
import Colors from '../../../../Colors';
import { MobileCloseButton } from '../../../../components/DrawerBar/DrawerButtons';
import {
  ActionButton,
  ActionButtonsContainer,
  AdjustAmountContainer,
  BookedByForContainer,
  CallToAction,
  CallToActionContainer,
  Divider,
  DrawerContainer,
  DrawerContent,
  DrawerDetailes,
  DrawerFooter,
  DrawerLabel,
  DrawerLabelContainer,
  DrawerList,
  DrawerSection,
  DrawerSubLabel,
  FailReason,
  FailedPaymentReason,
  PaymentLabel,
  PriceDetailsContainer,
  ProfileListItemsContainer
} from '../../../../components/DrawerBar/styled';
import { DRAWER_IDS } from '../../../../components/DrawerBar/types';
import ModalDialog from '../../../../components/Modal/ModalDialog';
import { PETS_EXTENSTION_TABS } from '../../../../components/PetDrawer/PetExtension';
import { PetDrawerTag, PetDrawerTagsContainer } from '../../../../components/PetDrawer/styled';
import PetProfilesList from '../../../../components/Pets/PetProfilesList';
import ProfileActionBar from '../../../../components/Profile/ProfileActionBar';
import { FormLinkButtonStyled } from '../../../../components/Shared/Forms/Forms';
import { FlexBreak } from '../../../../components/Shared/Shared';
import { CenteredLoader, LoadingOverlay } from '../../../../components/Shared/Spinner';
import useBookingActions from '../../../../hooks/useBookingActions';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import { GetBranchAppointments, GetBranchOrderVouchers } from '../../../../queries';
import { vars } from '../../../../reactive';
import { setDrawerBar } from '../../../../reactive/actions';
import { calculateNights, displayTime, findDayName, findMonthName, findThreeLetterDayName, formatAppointmentPeriod, toReadableDate } from '../../../../utils/dates';
import { getBranchCurrencySymbol } from '../../../../utils/getBranchCurrencySymbol';
import { getKeys } from '../../../../utils/helpers';
import { ORDER_STATUS } from '../../../Orders/types';
import { OrderVoucher } from '../../../Store/Discounts/types';
import { BOOKING_STATUS_TYPES, BOOKING_TYPE, BookingOrder } from '../../types';
import AdjustPaymentModal from './AdjustPaymentModal';
import BookingDrawerButtons from './BookingDrawerButtons';
import { BookingDrawerButtonsContainer } from './styled';
import BranchNotes from '../../../../components/Shared/BranchNotes/BranchNotes';
import { BranchNoteType } from '../../../../components/Shared/BranchNotes/types';
import SlotAvailability from './SlotAvailability';

const BookingDrawer = ({ data: appointemtns }: { data: BookingOrder }) => {
  const [displayDetails, setDisplayDetails] = useState(false);
  const [selected, setSelected] = useState('details');
  const { mobile } = useMediaQuery({ mobile: true });
  const refetch = vars.drawerBars().find(drawerBar => drawerBar.drawerId === DRAWER_IDS.BOOKING_DRAWER)?.otherData?.refetch || (() => {});

  const { data: { getBranchAppointments: bookingOrderAppointments = [] } = {}, loading: loadingAppointments } = useQuery<{ getBranchAppointments: BookingOrder }>(GetBranchAppointments, {
    variables: {
      appointment_id: appointemtns?.map(item => item.id),
      requisite_queries: ['appointment_id'],
      alternative_queries: [],
      offset: 0,
      limit: 1000,
      status: [BOOKING_STATUS_TYPES.CONFIRMED, BOOKING_STATUS_TYPES.CANCELED, BOOKING_STATUS_TYPES.REQUESTED],
      booking_type: [BOOKING_TYPE.SLOT, BOOKING_TYPE.MULTI_SLOT, BOOKING_TYPE.MULTI_DAY]
    },
    fetchPolicy: 'cache-and-network'
  });

  const submittedForms = bookingOrderAppointments?.[0]?.OrderItem?.Order?.SubmittedForms;

  const bookingActions = useBookingActions({
    booking: bookingOrderAppointments,
    options: {
      loadOrderItems: true,
      loadAvailability: true,
      refetch
    }
  });

  const { actions, shouldDisplay, loading: loadingActions, error, bookingsToRender, selectedSlotForEachDate } = bookingActions;

  const { shouldDisplayConfirmPayment, shouldDisplayConfirmationAndRejection, showAdjustButton, shouldDisplayAvailability, inConfirimed, shouldDisplayRetryPayment, shouldDisplayMarkAsPaid } =
    shouldDisplay;

  const { handleConfirmPayment, handleConfirmation, handleRejectCancel, handleMarkAsPaid, handleRetryPayment } = actions;

  const handleClickOnTotal = () => {
    setDisplayDetails(!displayDetails);
  };

  const drawerBar = vars.drawerBars().find(drawerBar => drawerBar.drawerId === DRAWER_IDS.BOOKING_DRAWER)!;
  const bookingBusUsers = bookingOrderAppointments?.[0]?.BusUsers;

  const orderItem = bookingOrderAppointments[0]?.OrderItem;
  const order = orderItem?.Order;
  const product = orderItem?.item;
  const durationHours = product?.duration ? Math.floor(product?.duration / 60) : 0;
  const durationMinutes = product?.duration ? product?.duration % 60 : 0;
  const durationValues = {
    hours: durationHours,
    minutes: durationMinutes
  };
  const durationString = `${durationHours ? durationHours + 'h' : ''} ${durationMinutes ? durationMinutes + 'm' : ''}`;
  const profile = order?.AppUser;
  const customizations = orderItem?.customizations || [];

  const pets = bookingOrderAppointments.map(booking => booking.PetRecord.Pet);
  const isMultiday = !!bookingOrderAppointments[0]?.timestamp_until;

  const { data: { getBranchOrderVouchers: orderVouchers = [] } = {} } = useQuery<{ getBranchOrderVouchers: OrderVoucher[] }>(GetBranchOrderVouchers, {
    variables: { OrderId: order?.id },
    skip: !order?.id
  });

  const petsCount = orderItem?.item?.use_pets_count ? pets.length : 1;

  const customizationsPrice = customizations.reduce((acc, customization) => acc + getKeys(customization.fields).reduce((acc, key) => acc + Number(customization.fields[key]?.price), 0), 0);

  const nightsNumber = calculateNights(bookingOrderAppointments[0]?.timestamp, bookingOrderAppointments[0]?.timestamp_until);
  const subTotal = Number(orderItem?.item?.price || 0) * (isMultiday ? calculateNights(bookingOrderAppointments[0].timestamp, bookingOrderAppointments[0].timestamp_until) : petsCount);
  const voucher = orderVouchers?.[0]?.voucher;
  const voucherDiscount = voucher?.discount;
  const discount = voucherDiscount?.type === 'percentage' ? `%${voucherDiscount?.amount}` : `${getBranchCurrencySymbol()}${voucherDiscount?.amount}`;
  const orderTotalPrice = Number(order?.total);
  const creditUsed = Number(order?.credit_used || 0);
  const orderTotalRefunds = order?.Refunds?.reduce((acc, refund) => acc + Number(refund.amount), 0);
  const isFree = orderTotalPrice < 0.5;
  const tags = bookingOrderAppointments?.[0]?.BranchAppointmentTags;
  const credit = order?.credit_used;

  const [failReasonClicked, setFailReasonClicked] = useState<boolean>(false);

  const handleClicked = () => {
    setFailReasonClicked(!failReasonClicked);
  };

  const FormAnswersButton = ({ pet }: { pet: any }) => {
    return (
      <FormLinkButtonStyled
        textAlign="left"
        onClick={() => {
          setDrawerBar({
            drawerId: DRAWER_IDS.PETS_DRAWER,
            recordData: pet.id,
            isExtended: true,
            otherData: {
              selectedTab: PETS_EXTENSTION_TABS.FORMS
            }
          });
        }}
      >
        Submitted form
      </FormLinkButtonStyled>
    );
  };

  return (
    <>
      {loadingAppointments && <LoadingOverlay />}
      {!loadingAppointments && (
        <DrawerContainer>
          <DrawerSection paddingT={16} paddingB={16} paddingL={20} paddingR={20} isFixed flexRow center>
            <MobileCloseButton drawer={drawerBar} />
            <DrawerLabel>{order?.number}</DrawerLabel>
            <BookingDrawerButtonsContainer>
              <BookingDrawerButtons appointments={bookingOrderAppointments} bookingActions={bookingActions} />
            </BookingDrawerButtonsContainer>
          </DrawerSection>
          <Divider />
          <ActionButtonsContainer>
            <ActionButton onClick={() => setSelected('details')} isSelected={selected === 'details'}>
              Details
            </ActionButton>
            <ActionButton isSelected={selected === 'notes'} onClick={() => setSelected('notes')}>
              Notes
            </ActionButton>
          </ActionButtonsContainer>

          <DrawerContent padding="8px 20px" justifyFlexStart>
            {selected === 'details' && (
              <BookedByForContainer padding="0" backgroundColor="transparent">
                {!!tags?.length && selected === 'details' && (
                  <>
                    <PetDrawerTagsContainer backgroundColor="transparent" padding="0 0 20px 0">
                      {tags?.map((item, index) => (
                        <PetDrawerTag color={Colors.black} key={index}>
                          <span>{item.name}</span>
                        </PetDrawerTag>
                      ))}
                    </PetDrawerTagsContainer>
                  </>
                )}
                <DrawerSubLabel weight={600} size={12} marginBottom={12}>
                  Booked by
                </DrawerSubLabel>
                <ProfileListItemsContainer>
                  <ProfileActionBar profile={profile} drawerId={DRAWER_IDS.BOOKING_DRAWER} paddingB={20} />
                </ProfileListItemsContainer>
                <Divider />
                <DrawerSubLabel weight={600} size={12} marginBottom={12} paddingT={20}>
                  Booked for
                </DrawerSubLabel>
                <DrawerList padding={true}>
                  <PetProfilesList
                    pets={pets}
                    booking={bookingOrderAppointments?.[0]}
                    drawerId={DRAWER_IDS.BOOKING_DRAWER}
                    loadPetTags
                    renderUnderPetProfile={pet => (submittedForms?.length ? <FormAnswersButton pet={pet} /> : null)}
                  />
                </DrawerList>
                <Divider marginTop={0} marginBottom={20} />
              </BookedByForContainer>
            )}

            <div>
              {selected === 'details' && (
                <>
                  <DrawerSubLabel marginBottom={8} weight={600} size={12}>
                    Service
                  </DrawerSubLabel>
                  <DrawerSubLabel marginBottom={20} weight={800} color={Colors.black} size={14}>
                    {product?.name}
                  </DrawerSubLabel>
                </>
              )}

              {/* {!!bookingBusUsers?.[0]?.id && (
                <>
                  <DrawerSubLabel marginBottom={4} weight={600} size={14}>
                    Assigned to:
                  </DrawerSubLabel>
                  <DrawerSubLabel marginBottom={28} weight={700} color={Colors.black}>
                    {bookingBusUsers?.map(({ name }) => name).join(', ')}
                  </DrawerSubLabel>
                </>
              )}
              {!!customizations?.length && (
                <>
                  <DrawerSubLabel marginBottom={4} weight={600} size={14}>
                    Variations
                  </DrawerSubLabel>
                  <DrawerSubLabel marginBottom={28} weight={700} color={Colors.black}>
                    {[...(customizations || [])].map((customization, index) => (
                      <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }} key={index}>
                        {getKeys(customization.fields).map(customKey => (
                          <div key={customKey} style={{ display: 'flex', flexDirection: 'column' }}>
                            <span>{customKey}:</span>
                            <span style={{ fontWeight: 400 }}>{customization.fields[customKey]?.title}</span>
                          </div>
                        ))}
                      </div>
                    ))}
                  </DrawerSubLabel>
                </>
              )}
              {!loadingActions && (
                <>
                  <DrawerSubLabel marginBottom={6} weight={600} size={12}>
                    {isMultiday ? 'Booking From' : 'Booking Slot'}
                  </DrawerSubLabel>
                  {bookingsToRender.map((booking, index) => {
                    const period = formatAppointmentPeriod(new Date(booking[0]?.timestamp), product?.duration || 0);
                    return (
                      <Fragment key={index}>
                        <DrawerSubLabel marginBottom={16} weight={700} color={Colors.black}>
                          {toReadableDate(booking[0]?.timestamp, { noTime: true, withDay: true })} {isMultiday ? '' : period}
                        </DrawerSubLabel>
                        {!isMultiday && shouldDisplayAvailability && <SlotAvailability index={index} selectedSlotForEachDate={selectedSlotForEachDate} />}
                      </Fragment>
                    );
                  })}

                  {isMultiday && (
                    <>
                      <DrawerSubLabel marginBottom={6} weight={600} size={12}>
                        Booking Until
                      </DrawerSubLabel>
                      {bookingsToRender.map((booking, index) => (
                        <DrawerSubLabel marginBottom={20} key={index} weight={800} color={Colors.black} size={14}>
                          {findDayName(new Date(booking[0]?.timestamp_until).getDay())} at{' '}
                          {displayTime(new Date(booking[0]?.timestamp_until).getUTCHours(), new Date(booking[0]?.timestamp_until).getMinutes())}
                        </DrawerSubLabel>
                      ))}
                    </>
                  )}

                  {
                    <>
                      <DrawerSubLabel marginBottom={6} weight={600} size={12}>
                        Details
                      </DrawerSubLabel>
                      {customizations?.length ? (
                        <DrawerSubLabel marginBottom={20} weight={800} color={Colors.black} size={14}>
                          {customizations.map((customization, index) => (
                            <DrawerSubLabel weight={800} color={Colors.black} size={14} key={index}>
                              {getKeys(customization.fields)
                                .map(customKey => `${customKey}: ${customization.fields[customKey]?.title}`)
                                .join('\n')
                                .trim()}
                            </DrawerSubLabel>
                          ))}
                        </DrawerSubLabel>
                      ) : (
                        <DrawerSubLabel color={Colors.black}>No Details</DrawerSubLabel>
                      )}
                    </>
                  }

                  {isMultiday && shouldDisplayAvailability && <SlotAvailability index={0} selectedSlotForEachDate={selectedSlotForEachDate} />}
                </>
              )}
              {!isMultiday && (
                <>
                  <DrawerSubLabel marginBottom={4} weight={600} size={14}>
                    Duration
                  </DrawerSubLabel>
                  <DrawerSubLabel marginBottom={28} weight={700} color={Colors.black}>
                    {durationValues.hours}h {durationValues.minutes}m
                  </DrawerSubLabel>
                </>
              )}
              {loadingActions && (
                <div style={{ padding: 20 }}>
                  <CenteredLoader size={30} color={Colors.primary} />
                </div>
              )}
              {!!order?.id && <BranchNotes type={BranchNoteType.ORDER} OrderId={order?.id} />} */}

              {/* //////////////////////////// */}
              {!loadingActions && selected === 'details' && (
                <>
                  <DrawerSubLabel marginBottom={6} weight={600} size={12}>
                    {isMultiday ? 'Check-in' : 'Booking Slot'}
                  </DrawerSubLabel>
                  {bookingsToRender.map((booking, index) => {
                    const period = formatAppointmentPeriod(new Date(booking[0]?.timestamp), product?.duration || 0);
                    return (
                      <Fragment key={index}>
                        <DrawerSubLabel marginBottom={20} weight={800} color={Colors.black} size={14}>
                          {toReadableDate(booking[0]?.timestamp, { noTime: true, withDay: true })} {isMultiday ? '' : period}
                        </DrawerSubLabel>
                        {!isMultiday && shouldDisplayAvailability && <SlotAvailability index={index} selectedSlotForEachDate={selectedSlotForEachDate} />}
                      </Fragment>
                    );
                  })}

                  {isMultiday && (
                    <>
                      <DrawerSubLabel marginBottom={6} weight={600} size={12}>
                        Check-out
                      </DrawerSubLabel>
                      {bookingsToRender.map((booking, index) => (
                        <DrawerSubLabel marginBottom={20} key={booking?.[0].id} weight={800} color={Colors.black} size={14}>
                          {findThreeLetterDayName(new Date(booking[0]?.timestamp_until).getDay())}, {new Date(booking[0]?.timestamp_until).getDate()}{' '}
                          {findMonthName(new Date(booking[0]?.timestamp_until).getMonth())} at{' '}
                          {displayTime(new Date(booking[0]?.timestamp_until).getUTCHours(), new Date(booking[0]?.timestamp_until).getMinutes())}
                        </DrawerSubLabel>
                      ))}
                    </>
                  )}

                  {
                    <>
                      <DrawerSubLabel marginBottom={6} weight={600} size={12}>
                        Length
                      </DrawerSubLabel>
                      <DrawerSubLabel marginBottom={20} weight={800} color={Colors.black} size={14}>
                        {isMultiday ? nightsNumber + (nightsNumber === 1 ? 'Night' : 'Nights') : durationString}
                      </DrawerSubLabel>
                    </>
                  }
                  {
                    <>
                      {/* <DrawerSubLabel marginBottom={6} weight={600} size={12}>
                        Details
                      </DrawerSubLabel> */}
                      {customizations?.length ? (
                        <div>
                          {customizations.map((customization, index) =>
                            getKeys(customization.fields).map(customKey => (
                              <div key={index} style={{ marginBottom: '20px' }}>
                                <DrawerSubLabel weight={600} color={Colors.black} size={12} marginBottom={4}>
                                  {customKey}:
                                </DrawerSubLabel>
                                <DrawerSubLabel color={Colors.black} size={14} weight={800}>
                                  {customization.fields[customKey]?.title}
                                </DrawerSubLabel>
                              </div>
                            ))
                          )}
                        </div>
                      ) : (
                        <DrawerSubLabel color={Colors.black} weight={800} size={14}></DrawerSubLabel>
                      )}
                    </>
                  }

                  {/* {isMultiday && shouldDisplayAvailability && <SlotAvailability index={0} selectedSlotForEachDate={selectedSlotForEachDate} />} */}
                </>
              )}

              {selected === 'notes' && !!order?.id && <BranchNotes type={BranchNoteType.ORDER} OrderId={order?.id} />}
            </div>
          </DrawerContent>
          <DrawerDetailes padding="8px 20px 0 20px">
            {displayDetails && (
              <PriceDetailsContainer>
                <Divider />

                {/* {!customizations.length && !isFree && (
                    <>
                      <DrawerSubLabel marginBottom={4} weight={600} color={Colors.secondaryGrey}>
                        {'Subtotal'}
                      </DrawerSubLabel>
                      <DrawerSubLabel marginBottom={20} weight={600} color={Colors.secondaryGrey}>
                      {getBranchCurrencySymbol()}
                      {subTotal?.toFixed(2)}
                      </DrawerSubLabel>
                    </>
                  )} */}

                {/* {!!customizations?.length && !isFree && ( */}
                <>
                  <DrawerLabelContainer>
                    <DrawerSubLabel marginBottom={0} weight={600} color={Colors.secondaryGrey} size={12}>
                      {'Subtotal'}
                    </DrawerSubLabel>
                    <DrawerSubLabel marginBottom={0} weight={600} color={Colors.secondaryGrey} size={12}>
                      £{subTotal?.toFixed(2)}
                    </DrawerSubLabel>
                  </DrawerLabelContainer>
                  <DrawerLabelContainer>
                    <DrawerSubLabel marginBottom={0} weight={600} color={Colors.secondaryGrey} size={12}>
                      {'Extras'}
                    </DrawerSubLabel>

                    {customizations.length ? (
                      <>
                        <DrawerSubLabel size={12} weight={600}>
                          £
                          {customizations
                            .reduce((total, customization) => {
                              return (
                                total +
                                getKeys(customization.fields).reduce((sum, customKey) => {
                                  return sum + (Number(customization.fields[customKey]?.price) || 0);
                                }, 0)
                              );
                            }, 0)
                            .toFixed(2)}
                        </DrawerSubLabel>
                      </>
                    ) : (
                      <DrawerSubLabel size={12} weight={600}>
                        £0.00
                      </DrawerSubLabel>
                    )}
                  </DrawerLabelContainer>
                </>
                {
                  <DrawerLabelContainer>
                    <DrawerSubLabel marginBottom={0} weight={600} color={Colors.secondaryGrey} size={12}>
                      Discount
                    </DrawerSubLabel>
                    <DrawerSubLabel marginBottom={0} weight={600} color={Colors.secondaryGrey} size={12}>
                      ({discount.includes('undefined') ? 'No Discount' : discount})
                    </DrawerSubLabel>
                  </DrawerLabelContainer>
                }

                {
                  <DrawerLabelContainer>
                    <DrawerSubLabel marginBottom={0} weight={600} color={Colors.secondaryGrey} size={12}>
                      Credit
                    </DrawerSubLabel>
                    <DrawerSubLabel marginBottom={0} weight={600} color={Colors.secondaryGrey} size={12}>
                      (£{credit}:00)
                    </DrawerSubLabel>
                  </DrawerLabelContainer>
                }
                <Divider />
              </PriceDetailsContainer>
            )}
            <DrawerLabelContainer marginY={11} onClick={handleClickOnTotal} style={{ cursor: 'pointer' }}>
              {creditUsed > 0 && (
                <DrawerLabelContainer marginY={15}>
                  <DrawerSubLabel marginBottom={0} weight={600} color={Colors.secondaryGrey}>
                    Credit used
                  </DrawerSubLabel>
                  <DrawerSubLabel marginBottom={0} weight={600} color={Colors.secondaryGrey}>
                    {getBranchCurrencySymbol()}
                    {creditUsed.toFixed(2)}
                  </DrawerSubLabel>
                </DrawerLabelContainer>
              )}
              {!isFree && (
                <>
                  <DrawerSubLabel marginBottom={0} weight={800} color={Colors.black} size={16}>
                    Total
                  </DrawerSubLabel>
                  <DrawerSubLabel marginBottom={0} weight={800} color={Colors.black} size={16}>
                    {getBranchCurrencySymbol()}
                    {orderTotalPrice.toFixed(2)}{' '}
                  </DrawerSubLabel>
                </>
              )}

              {isFree && (
                <DrawerSubLabel marginBottom={4} weight={700} color={Colors.black} size={22}>
                  Free
                </DrawerSubLabel>
              )}
              <FlexBreak />
              {orderTotalRefunds > 0 && (
                <>
                  <DrawerSubLabel marginBottom={4} weight={600} color={Colors.black} size={14}>
                    Total Refunds
                  </DrawerSubLabel>
                  <DrawerSubLabel marginBottom={4} weight={600} color={Colors.black} size={14}>
                    {getBranchCurrencySymbol()}
                    {orderTotalRefunds.toFixed(2)}
                  </DrawerSubLabel>
                </>
              )}
              <FlexBreak />
              {/* {showAdjustButton && (
                <AdjustAmountContainer>
                  <FormLinkButtonStyled
                    onClick={() =>
                      ModalDialog.openModal({
                        title: 'Adjust Payment Amount',
                        content: () => <AdjustPaymentModal oldAmount={order?.total} orderId={order?.id} />
                      })
                    }
                  >
                    Adjust Amount
                  </FormLinkButtonStyled>
                </AdjustAmountContainer>
              )} */}
            </DrawerLabelContainer>
          </DrawerDetailes>

          <DrawerFooter>
            {/* {loadingActions && (
              <div style={{ padding: 20 }}>
                <CenteredLoader size={30} color={Colors.primary} />
              </div>
            )} */}
            {order?.payment?.fail_reason &&
              ![ORDER_STATUS.DISCOUNT_APPLIED, ORDER_STATUS.PAYMENT_SUCCEEDED, ORDER_STATUS.REQUESTED, ORDER_STATUS.CONFIRMED].includes(order?.status) &&
              !inConfirimed && (
                <CallToActionContainer>
                  <DrawerSubLabel color="#FF7A00" gap={4} size={12} weight={800}>
                    {' '}
                    {!!order && sentenceCase(order?.status)}
                    <FailedPaymentReason onClick={handleClicked}>i</FailedPaymentReason>
                  </DrawerSubLabel>
                  <CallToAction onClick={handleRetryPayment} error={!!error}>
                    Retry Payment
                  </CallToAction>

                  <CallToAction onClick={handleMarkAsPaid} error={!!error} secondary>
                    Mark as paid
                  </CallToAction>
                </CallToActionContainer>
              )}
            {failReasonClicked && <FailReason>{sentenceCase(order?.payment?.fail_reason)}</FailReason>}

            {!mobile && shouldDisplayConfirmationAndRejection && (
              <>
                <CallToActionContainer>
                  <CallToAction onClick={handleConfirmation} error={!!error}>
                    Accept Booking
                  </CallToAction>
                  <CallToAction onClick={() => handleRejectCancel()} error={!!error} secondary>
                    Reject Booking
                  </CallToAction>
                </CallToActionContainer>
              </>
            )}
            {shouldDisplayConfirmPayment && (
              <>
                <CallToActionContainer>
                  <CallToAction onClick={handleConfirmPayment} error={!!error}>
                    Confirm Payment
                  </CallToAction>
                </CallToActionContainer>
              </>
            )}
            {!shouldDisplayConfirmationAndRejection && !shouldDisplayConfirmPayment && !order?.payment?.fail_reason && (
              <CallToActionContainer>
                <PaymentLabel danger={![ORDER_STATUS.DISCOUNT_APPLIED, ORDER_STATUS.PAYMENT_SUCCEEDED, ORDER_STATUS.REQUESTED, ORDER_STATUS.CONFIRMED].includes(order?.status)}>
                  {!!order && order?.status === ORDER_STATUS.PAYMENT_SUCCEEDED ? 'Paid' : sentenceCase(order?.status)}
                </PaymentLabel>
              </CallToActionContainer>
            )}
          </DrawerFooter>
        </DrawerContainer>
      )}
    </>
  );
};

export default BookingDrawer;
