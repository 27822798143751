import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { sentenceCase } from 'sentence-case';
import Colors from '../../Colors';
import { GetBranchOrderSubscriptionsById, GetBranchOrderVouchers } from '../../queries';
import { formatOrdinals, getHourByIndex, getUTCHourOffset, toReadableDate } from '../../utils/dates';
import { getBranchCurrencySymbol } from '../../utils/getBranchCurrencySymbol';
import { getKeys } from '../../utils/helpers';
import { BookingDrawerButtonsContainer } from '../../views/Bookings/components/BookingsDrawer/styled';
import { OrderVoucher } from '../../views/Store/Discounts/types';
import { Subscription } from '../../views/Subscriptions/types';
import { getSubscriptionOrder, getSubscriptionTitles } from '../../views/Subscriptions/utils';
import { Divider, DrawerContainer, DrawerContent, DrawerDetailes, DrawerLabel, DrawerLabelContainer, DrawerList, DrawerSection, DrawerSubLabel, PaymentLabel } from '../DrawerBar/styled';
import { DRAWER_IDS } from '../DrawerBar/types';
import PetProfilesList from '../Pets/PetProfilesList';
import ProfileActionBar from '../Profile/ProfileActionBar';
import { FlexBreak } from '../Shared/Shared';
import { LoadingOverlay } from '../Shared/Spinner';
import SubscriptionDrawerButtons from './SubscriptionDrawerButtons';

const SubscriptionDrawer = ({ data }: { data: Subscription }) => {
  const { data: { getBranchOrderSubscriptions: [subscription] = [] } = {}, loading: loadingSubscription } = useQuery<{ getBranchOrderSubscriptions: Subscription[] }>(GetBranchOrderSubscriptionsById, {
    variables: { id: data.id, status: [data.status] }
  });

  const [displayTotalDetails, setDisplayTotalDetails] = useState<boolean>(false);

  const { order } = getSubscriptionOrder(subscription);
  const {
    data: { getBranchOrderVouchers: orderVouchers = [] } = {},
    loading: loadingDiscounts,
    refetch: refetchOrderVouchers
  } = useQuery<{
    getBranchOrderVouchers: OrderVoucher[];
  }>(GetBranchOrderVouchers, { variables: { OrderId: order?.id }, fetchPolicy: 'cache-and-network', skip: !subscription?.id });

  const { user, pets, subscriptionNumber, product, nextDate, isCanceled, total, discount, voucher, customizations, isFree, period, activeText, petDaycareDaysString } = getSubscriptionTitles({
    subscription,
    orderVouchers
  });

  const handledDisplayTotalDetails = () => {
    setDisplayTotalDetails(!displayTotalDetails);
  };

  return (
    <>
      {loadingSubscription && <LoadingOverlay />}
      {!loadingSubscription && (
        <>
          <DrawerContainer>
            <DrawerSection paddingT={16} paddingB={12} paddingR={20} paddingL={20} isFixed flexRow>
              <DrawerLabel>{subscriptionNumber}</DrawerLabel>
              <BookingDrawerButtonsContainer>
                <SubscriptionDrawerButtons subscription={subscription} orderVouchers={orderVouchers} loading={loadingDiscounts} onUpdate={() => refetchOrderVouchers()} />
              </BookingDrawerButtonsContainer>
            </DrawerSection>
            <Divider />
            <DrawerSection paddingB={20}>
              <DrawerSubLabel weight={600} size={12} marginBottom={12}>
                Subscribed by
              </DrawerSubLabel>
              <ProfileActionBar profile={user} drawerId={DRAWER_IDS.SUBSCRIPTION_DRAWER} />
              <Divider marginBottom={20} marginTop={20} />
              <DrawerSubLabel weight={600} size={12} marginBottom={12}>
                Membership for
              </DrawerSubLabel>
              <DrawerList>
                <PetProfilesList pets={pets} drawerId={DRAWER_IDS.SUBSCRIPTION_DRAWER} />
              </DrawerList>
            </DrawerSection>

            <DrawerContent padding="24px 20px">
              <div>
                <DrawerSubLabel marginBottom={8} weight={600} size={12}>
                  Membership
                </DrawerSubLabel>
                <DrawerSubLabel marginBottom={20} weight={700} color={Colors.black} size={14}>
                  {product?.name}
                </DrawerSubLabel>
                <DrawerSubLabel marginBottom={8} weight={600} size={12}>
                  Status
                </DrawerSubLabel>
                <DrawerSubLabel marginBottom={20} weight={700} color={Colors.black} size={14}>
                  {sentenceCase(isCanceled ? 'Cancelled' : subscription?.collection_status || '')}
                </DrawerSubLabel>
                <DrawerSubLabel marginBottom={8} weight={600} size={12}>
                  Trial
                </DrawerSubLabel>
                <DrawerSubLabel marginBottom={20} weight={700} color={Colors.black} size={14}>
                  {new Date(subscription?.trial_end).getTime() > new Date().getTime() ? toReadableDate(subscription?.trial_end, { noTime: true }) : 'Ended'}
                </DrawerSubLabel>
                {customizations?.length ? (
                  <div>
                    {customizations.map((customization, index) =>
                      getKeys(customization.fields).map(customKey => (
                        <div key={index} style={{ marginBottom: '10px' }}>
                          <DrawerSubLabel weight={600} size={12} marginBottom={4}>
                            {customKey}:
                          </DrawerSubLabel>
                          <DrawerSubLabel color={Colors.black} size={14} weight={800}>
                            {customization.fields[customKey]?.title}
                          </DrawerSubLabel>
                        </div>
                      ))
                    )}
                  </div>
                ) : (
                  <DrawerSubLabel color={Colors.black}>No Details</DrawerSubLabel>
                )}
                <DrawerSubLabel marginBottom={8} weight={600} size={12}>
                  Start Date
                </DrawerSubLabel>
                <DrawerSubLabel marginBottom={20} weight={700} color={Colors.black} size={14}>
                  {toReadableDate(subscription?.start_date, { isLocale: true })}
                </DrawerSubLabel>
                {!isCanceled && subscription?.next_date && (
                  <>
                    <DrawerSubLabel marginBottom={8} weight={600} size={12}>
                      Next Billing Date
                    </DrawerSubLabel>
                    <DrawerSubLabel marginBottom={20} weight={800} color={Colors.black} size={14}>
                      {`${sentenceCase(period)} on ~ ${formatOrdinals(new Date(nextDate.setUTCDate(nextDate?.getUTCDate() + 1)).getUTCDate())} at ${getHourByIndex(getUTCHourOffset())}`}
                    </DrawerSubLabel>
                  </>
                )}
                {isCanceled && (
                  <>
                    <DrawerSubLabel marginBottom={8} weight={600} size={12}>
                      Cancelled At
                    </DrawerSubLabel>
                    <DrawerSubLabel marginBottom={20} weight={700} color={Colors.black} size={14}>
                      {toReadableDate(subscription?.ended_at, { isLocale: true })}
                    </DrawerSubLabel>
                  </>
                )}

                {/* {!!order?.id && <BranchNotes type={BranchNoteType.ORDER} OrderId={order?.id} />}
                <Divider marginTop={12} /> */}
                <DrawerSubLabel marginBottom={8} weight={600} size={12}>
                  Selected Days :
                </DrawerSubLabel>
                <DrawerSubLabel marginBottom={20} weight={700} color={Colors.black} size={14} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '4px' }}>
                  {petDaycareDaysString.map((day, index) => (
                    <div key={index}>{day}</div>
                  ))}
                </DrawerSubLabel>
              </div>
            </DrawerContent>
            <DrawerDetailes padding="8px 20px 20px 20px">
              {displayTotalDetails && (
                <div>
                  <Divider marginBottom={8} />
                  <DrawerLabelContainer>
                    {!!customizations?.length && !isFree && (
                      <>
                        <DrawerLabelContainer>
                          <DrawerSubLabel marginBottom={6} weight={600} color={Colors.secondaryGrey} size={12}>
                            {'Original Price'}
                          </DrawerSubLabel>
                          <DrawerSubLabel marginBottom={6} weight={600} color={Colors.secondaryGrey} size={12}>
                            {getBranchCurrencySymbol()}
                            {product?.price}
                          </DrawerSubLabel>
                        </DrawerLabelContainer>
                        <DrawerSubLabel marginBottom={0} weight={600} color={Colors.secondaryGrey} size={12}>
                          {'Variations'}
                        </DrawerSubLabel>
                        {customizations.map((customization, index) => {
                          return (
                            <DrawerSubLabel key={index} size={12}>
                              {getKeys(customization.fields)
                                .map(customKey => `(${customization.fields[customKey]?.title}: ${getBranchCurrencySymbol()}${Number(customization.fields[customKey]?.price)})`)
                                .join(' ')
                                .trim()}
                            </DrawerSubLabel>
                          );
                        })}
                      </>
                    )}
                    {!customizations.length && (
                      <>
                        <DrawerSubLabel marginBottom={0} weight={600} color={Colors.secondaryGrey} size={12}>
                          {'Subtotal'}
                        </DrawerSubLabel>

                        <DrawerSubLabel marginBottom={0} weight={600} color={Colors.secondaryGrey} size={12}>
                          {getBranchCurrencySymbol()}
                          {total?.toFixed(2)}
                        </DrawerSubLabel>
                      </>
                    )}
                  </DrawerLabelContainer>
                  {!!orderVouchers.length && (
                    <DrawerLabelContainer>
                      <DrawerSubLabel marginBottom={0} weight={600} color={Colors.secondaryGrey} size={12}>
                        Discount
                      </DrawerSubLabel>
                      <DrawerSubLabel marginBottom={0} weight={600} color={Colors.secondaryGrey} size={12}>
                        {voucher?.code} -{discount}
                      </DrawerSubLabel>
                    </DrawerLabelContainer>
                  )}
                  <Divider marginTop={11} />
                </div>
              )}

              <DrawerLabelContainer marginY={11} onClick={handledDisplayTotalDetails} style={{ cursor: 'pointer' }}>
                <DrawerSubLabel weight={800} color={Colors.black} size={20}>
                  Total
                </DrawerSubLabel>
                <DrawerSubLabel weight={800} color={Colors.black} size={20}>
                  {getBranchCurrencySymbol()}
                  {order?.total}
                </DrawerSubLabel>
                <FlexBreak />
              </DrawerLabelContainer>
              <PaymentLabel grey={['CANCELED'].includes(subscription?.status)} danger={['PAUSED'].includes(subscription?.status)}>
                {/* {sentenceCase(subscription?.status === 'CONFIRMED' ? 'Active' : subscription?.status || '')} */}
                {activeText}
              </PaymentLabel>
            </DrawerDetailes>
          </DrawerContainer>
        </>
      )}
    </>
  );
};

export default SubscriptionDrawer;
