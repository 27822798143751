import React, { useState } from 'react';
import { sentenceCase } from 'sentence-case';
import Colors from '../../Colors';
import useMediaQuery from '../../hooks/useMediaQuery';
import { calculateAgeFromBirthdate, toReadableDate } from '../../utils/dates';
import { getKeys } from '../../utils/helpers';
import { ActionButton, ActionButtonsContainer, Divider, PetDrawerContent } from '../DrawerBar/styled';
import { PetDrawerTag, PetDrawerTagsContainer } from '../PetDrawer/styled';
import { Pet } from '../Pets/types';
import { ProfileInfoContainer, ProfileInfoWrapper, ProfileLabel, ProfileSection, ProfileSubLabel } from './styled';
import BranchNotes from '../Shared/BranchNotes/BranchNotes';
import { BranchNoteType } from '../Shared/BranchNotes/types';

const PetProfileInfo = ({ profile, selected = 'details' }: { profile: Pet; selected: string }) => {
  const { mobile } = useMediaQuery({ mobile: true });
  const allergies = profile?.PetRecord?.allergies;
  const conditions = getKeys(profile?.PetRecord?.chronic_conditions || {})
    .filter(cond => profile?.PetRecord?.chronic_conditions[cond])
    .map(cond => sentenceCase(cond))
    .join(', ');
  const petAllergies = getKeys(allergies || {})
    ?.filter(allergy => allergies[allergy])
    .map(cond => sentenceCase(cond))
    .join(', ');
  const diet = profile?.PetRecord?.BranchCategories?.map(category => category?.name).join(', ');
  const dietBranch = [...new Set(profile?.PetRecord?.Meals?.map(meal => meal?.name))].join(', ');
  const vet = profile?.PetRecord?.Branch?.name;
  const weight = profile?.PetRecord?.measurements?.weights?.slice(-1)?.[0]?.weight;
  const age = calculateAgeFromBirthdate(profile?.birthdate);

  const sections = [
    { subLabel: 'Age', label: age, unit: '' },
    { subLabel: 'Birthdate', label: toReadableDate(profile?.birthdate, { noTime: true, isLocale: true }), unit: '' },
    { subLabel: 'Gender', label: sentenceCase(profile?.gender || ''), unit: '' },
    { subLabel: profile?.gender === 'FEMALE' ? 'Spayed' : 'Neutered', label: profile?.PetRecord?.hasOwnProperty('neutred') ? (profile?.PetRecord?.neutred ? 'Yes' : 'No') : '', unit: '' },
    { subLabel: 'Colour', label: profile?.PetColor?.name, unit: '' },
    { subLabel: 'Current Weight', label: weight, unit: '' },
    { subLabel: 'Allergies', label: petAllergies, unit: '' },
    { subLabel: 'Chronic Conditions', label: conditions, unit: '' },
    { subLabel: 'Behavior', label: null, unit: '' },
    // { subLabel: 'Diet', label: diet, unit: '' },
    // { subLabel: 'Food Brands', label: dietBranch, unit: '' },
    // { subLabel: 'Vet', label: vet, unit: '' },
    { subLabel: 'Microchip', label: profile?.PetRecord?.microchip_number, unit: '' },
    { subLabel: 'Microchip Provider', label: profile?.PetRecord?.microchip_provider, unit: '' },
    { subLabel: 'Passport Number', label: profile?.PetRecord?.passport_number, unit: '' }
  ];

  const petTags = profile?.PetRecord?.BranchPetRecordTags;

  return (
    <PetDrawerContent>
      <ProfileInfoWrapper>
        <ProfileInfoContainer>
          {profile?.PetRecord?.id && selected === 'notes' && <BranchNotes type={BranchNoteType.PET_RECORD} PetRecordId={profile?.PetRecord?.id} />}

          {selected === 'details' &&
            sections.map(({ subLabel, label, unit }, i) => (
              <>
                <ProfileSection key={i}>
                  <ProfileSubLabel>{subLabel}</ProfileSubLabel>
                  <ProfileLabel>{label ? label + (unit || '') : '-'}</ProfileLabel>
                </ProfileSection>
                {mobile && i !== sections.length - 1 && <Divider marginBottom={17} />}
              </>
            ))}
        </ProfileInfoContainer>
      </ProfileInfoWrapper>
    </PetDrawerContent>
  );
};

export default PetProfileInfo;
