import { TransformedCustomization, Customizations as CustomizationsType, Customizations } from './types';

export const getSelectedCustomizations = (customizations?: TransformedCustomization[]) => {
  const customizationsState: CustomizationsType = (customizations || [])
    ?.sort((a, b) => (a.sort_index || 1) - (b.sort_index || 1))
    ?.reduce((acc, customization) => {
      return {
        ...acc,
        [customization.name]: customization.options.map(option => ({
          title: option.title,
          price: option.price,
          customization_sort_index: customization.sort_index || 1,
          description: option.description || '',
          sort_index: option.sort_index || 1,
          meta: option.meta.type !== 'none' ? { [option.meta.type]: option.meta.value } : 'none',
          rules: option.rules?.filter(rule => rule.type !== 'none')
        }))
      };
    }, {});

  return customizationsState;
};

export const getCustomizationsDefaultValues = (defaultValue?: Customizations) => {
  const transformedValue = Object.keys(defaultValue || {}).map(key => {
    const options = (defaultValue || {})[key];
    return {
      name: key,
      sort_index: options?.[0]?.customization_sort_index || 1,
      options: options?.map(item => {
        const metaKey = Object.keys(item.meta || {})?.[0];
        const meta = item.meta && item.meta !== 'none' ? metaKey : 'none';
        const metaValue = item.meta && item.meta !== 'none' ? item.meta[metaKey] : '';
        return {
          title: item.title,
          price: item.price,
          description: item.description,
          sort_index: item.sort_index || 1,
          meta: {
            type: meta,
            value: metaValue
          },
          rules: item.rules
        };
      })
    };
  });

  return transformedValue;
};
