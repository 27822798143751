import { useMutation, useQuery } from '@apollo/client';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useEffect, useRef, useState } from 'react';
import useIcons from '../../hooks/useIcons';
import useMediaQuery from '../../hooks/useMediaQuery';
import usePhotoUpload from '../../hooks/usePhotoUpload';
import { GetBusUserProfile, SendChatMessage } from '../../queries';
import config from '../../utils/config';
import { buildLink } from '../../utils/dynamicLinks/helpers';
import { formatTextParagraphs, generateUUID } from '../../utils/helpers';
import { ActionBtn } from '../../views/Store/styled';
import ModalDialog from '../Modal/ModalDialog';
import { CloseButton } from '../Shared/Filters/styled';
import OptionDropdown from '../Shared/Menus/OptionDropdown/OptionDropdown';
import { OPTION_DROPDOWN_MENU_BUTTON_TYPES, OPTION_DROPDOWN_MENU_OVERLAY_POSITIONS, OPTION_DROPDOWN_MENU_POSITIONS, OPTION_DROPDOWN_TYPES } from '../Shared/Menus/OptionDropdown/types';
import { LineLoader } from '../Shared/Spinner';
import EmbedReportModal from './EmbedReportModal';
import {
  ArrowIconWrapper,
  AttachIconWrapper,
  EmbedReportContainer,
  EmbededItemsContainer,
  InputBarArea,
  InputBarAreaContainer,
  InputBarAreaSend,
  InputBarAreaWrapper,
  InputContainer,
  LoaderContainer
} from './styled';
enum EMBEDED_ITEMS_TYPES {
  REPORT = 'REPORT',
  IMAGE = 'IMAGE',
  VIDEO_CALL = 'VIDEO_CALL'
}

const allowedVideoCallBranches = [
  // Vet on the Loose
  'f10db148-540f-46ca-a1d5-ce1d92b1aa57',
  // Collar store
  '014ef20d-33e4-4333-a5b3-2ca0d5d1ddc1'
];

const InputBar = ({ chatRoomId, scrollToBottom, onSend, branchId }: { chatRoomId: string; scrollToBottom: () => void; onSend?: () => void; branchId: string }) => {
  const [sendMessage, { data, called }] = useMutation(SendChatMessage);
  const { data: { getBusUserProfile: profile = {} } = {} } = useQuery(GetBusUserProfile);
  const [embededItems, setEmbededItems] = useState<{ type: EMBEDED_ITEMS_TYPES; items: Record<string, any> }[]>([]);
  const icons = useIcons();
  const { tablet } = useMediaQuery({ tablet: true });
  const closeIcon = icons?.closeModal?.childImageSharp.gatsbyImageData;
  const attachIcon = icons?.attach?.childImageSharp?.gatsbyImageData;
  const topArrow = icons?.topArrow?.childImageSharp?.gatsbyImageData;
  const messageId = useRef<string>('');
  const messageBody = useRef<string>('');
  const inputRef = useRef<HTMLInputElement>(null);
  const inputBarAreaRef = useRef<HTMLTextAreaElement>({ value: '' } as HTMLTextAreaElement);
  const handleFileUpload = () => inputRef?.current?.click();
  const [handlePhotosUpload, { data: photos, loading: photoUploadLoading, error: photoUploadError }, { checkPhotosSize }] = usePhotoUpload();

  const addEmbededItem = (type: EMBEDED_ITEMS_TYPES, items: Record<string, any>) => {
    setEmbededItems(prev => [...prev, { type, items }]);
  };

  const embedReport = ({ selectedReport, selectedPet }: { selectedReport: Record<string, any>; selectedPet: Record<string, any> }) => {
    addEmbededItem(EMBEDED_ITEMS_TYPES.REPORT, { selectedReport, selectedPet });
  };

  const sendVideoCall = () => {
    addEmbededItem(EMBEDED_ITEMS_TYPES.VIDEO_CALL, {});
  };

  const getEmbededItemsUrls = () => {
    const embededItemsUrls = embededItems.map(({ type, items }) => {
      if (type === EMBEDED_ITEMS_TYPES.REPORT) {
        const { selectedPet, selectedReport } = items;
        return buildLink(`/pets/${selectedPet?.id}/reports/${selectedReport?.id}`);
      }
    });
    return embededItemsUrls.join(' ');
  };

  const sendBusUserMessage = (options: { includeMessage?: boolean } = { includeMessage: true }) => {
    const { includeMessage } = options;
    const embededItemsUrls = getEmbededItemsUrls();

    const body = formatTextParagraphs(`${messageBody.current} ${embededItemsUrls}`);

    const defaultMessageVariables = {
      ChatRoomId: chatRoomId,
      user_id: profile?.id,
      user_type: 'bus',
      timestamp: new Date().toISOString(),
      location: null,
      video: null
    };

    if (embededItems.find(({ type }) => type === EMBEDED_ITEMS_TYPES.VIDEO_CALL)) {
      setEmbededItems([]);

      return sendMessage({
        variables: {
          ...defaultMessageVariables,
          body,
          photos: [],
          video: { duration: 1000000 }
        },
        fetchPolicy: 'no-cache'
      });
    }

    if (includeMessage) {
      setEmbededItems([]);

      return sendMessage({
        variables: {
          ...defaultMessageVariables,
          body,
          photos: []
        },
        fetchPolicy: 'no-cache'
      });
    }

    return sendMessage({
      variables: {
        ...defaultMessageVariables,
        id: messageId.current,
        body: '',
        photos: photos || []
      },
      fetchPolicy: 'no-cache'
    });
  };
  const activeLoading = (called && !data) || (photoUploadLoading && !photos);

  const handleMessageSend = () => {
    const message = inputBarAreaRef?.current?.value!;
    const hasMessage = message.trim() !== '';
    const selectedImages = embededItems.filter(item => item.type === EMBEDED_ITEMS_TYPES.IMAGE).map(item => item.items);
    const otherEmbededItems = embededItems.filter(item => item.type !== EMBEDED_ITEMS_TYPES.IMAGE);
    const hasEmbededItems = !!otherEmbededItems.length;

    if ((!hasMessage && (hasEmbededItems || !selectedImages)) || activeLoading) {
      return;
    }

    messageBody.current = message;
    inputBarAreaRef.current.value = '';

    if (!selectedImages.length && hasMessage) {
      sendBusUserMessage();
      return;
    }

    messageId.current = generateUUID();
    const imagesURL = `images/messages/${messageId.current}`;
    handlePhotosUpload(imagesURL, selectedImages as File[], { resizeImages: true, indexId: true });
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> = e => {
    if (e.key === 'Enter' && !e.shiftKey && !tablet) {
      e.preventDefault();
      handleMessageSend();
    }
  };

  useEffect(() => {
    if (photos?.length) {
      (async () => {
        await sendBusUserMessage({ includeMessage: false });
        if (messageBody.current) {
          sendBusUserMessage();
        }
        setEmbededItems([]);
      })();
    }
  }, [photos]);

  useEffect(() => {
    if (data) {
      scrollToBottom();
      onSend?.();
    }
  }, [data]);

  const embededImages = embededItems.filter(({ type }) => type === EMBEDED_ITEMS_TYPES.IMAGE);

  const embededReports = embededItems.filter(({ type }) => type === EMBEDED_ITEMS_TYPES.REPORT);

  const embededVideos = embededItems.filter(({ type }) => type === EMBEDED_ITEMS_TYPES.VIDEO_CALL);

  const allowedVideoCall = allowedVideoCallBranches.includes(branchId) || config.ENV === 'development';

  return (
    <InputContainer>
      <input
        ref={inputRef}
        type={'file'}
        accept={'image/*'}
        multiple
        onChange={e => {
          if (!e.target.files?.length || !checkPhotosSize(e)) {
            return;
          }

          const files: File[] = [...(e.target.files || [])];

          files?.forEach((file: File) => {
            addEmbededItem(EMBEDED_ITEMS_TYPES.IMAGE, file);
          });
          if (inputRef.current) {
            inputRef.current.value = '';
          }
        }}
        style={{ display: 'none' }}
      />
      {!!embededItems?.length && (
        <EmbededItemsContainer>
          <AttachIconWrapper>
            <GatsbyImage image={attachIcon} alt="attach" />
          </AttachIconWrapper>
          <EmbedReportContainer>
            {!!embededReports.length && embededReports.map(({ items }) => `${items?.selectedPet?.name}'s Report `).join('& ')}

            {!!embededReports.length && !!embededImages.length && ' & '}

            {!!embededImages.length && `${embededImages.length} attached images`}

            {!!embededVideos.length && ' Starting a video call'}
          </EmbedReportContainer>

          <CloseButton onClick={() => setEmbededItems([])}>
            <GatsbyImage image={closeIcon} alt="close" />
          </CloseButton>
        </EmbededItemsContainer>
      )}
      <InputBarAreaContainer>
        <OptionDropdown
          menuButtonType={OPTION_DROPDOWN_MENU_BUTTON_TYPES.PLUS_BLACK}
          menuOverlayPosition={OPTION_DROPDOWN_MENU_OVERLAY_POSITIONS.UP}
          menuPosition={OPTION_DROPDOWN_MENU_POSITIONS.RIGHT}
          noApplyButton
          options={[
            {
              id: 'OPTIONS',
              optionType: OPTION_DROPDOWN_TYPES.BUTTONS,
              items: [
                {
                  name: 'Attach Report',
                  value: EMBEDED_ITEMS_TYPES.REPORT,
                  onClick: () =>
                    ModalDialog.openModal({
                      content: () => (
                        <EmbedReportModal
                          chatRoomId={chatRoomId}
                          embedReport={embedReport}
                          embededReportsIds={embededItems.filter(({ type }) => type === EMBEDED_ITEMS_TYPES.REPORT).map(({ items }) => items?.selectedReport?.id)}
                        />
                      ),
                      title: 'Attach Report'
                    })
                },
                {
                  name: 'Attach Images',
                  value: EMBEDED_ITEMS_TYPES.IMAGE,
                  onClick: handleFileUpload
                },
                ...(allowedVideoCall
                  ? [
                      {
                        name: 'Start Video Call',
                        value: EMBEDED_ITEMS_TYPES.VIDEO_CALL,
                        onClick: sendVideoCall
                      }
                    ]
                  : [])
              ]
            }
          ]}
        />
        <InputBarAreaWrapper>
          <LoaderContainer>
            <LineLoader active={activeLoading} loadingContent={photoUploadLoading} />
          </LoaderContainer>
          <InputBarAreaSend>
            <InputBarArea onKeyDown={handleKeyDown} maxRows={5} disabled={activeLoading} textError={!!photoUploadError} ref={inputBarAreaRef} borderRadius={19} padding="8px 12px" />
            {tablet && (
              <ArrowIconWrapper>
                <ActionBtn onClick={() => handleMessageSend()} noMargin>
                  <GatsbyImage image={topArrow} alt="attach" />
                </ActionBtn>
              </ArrowIconWrapper>
            )}
          </InputBarAreaSend>
        </InputBarAreaWrapper>
      </InputBarAreaContainer>
    </InputContainer>
  );
};

export default InputBar;
