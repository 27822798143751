import React, { useRef } from 'react';
import { Divider } from '../../../components/Shared/SideTabs/styles';
import { getBranchCurrencySymbol } from '../../../utils/getBranchCurrencySymbol';
import { HoverRecordOptionsContainer, RecordBody, RecordDataContainer } from '../../styled';
import { productStatuses } from '../types';
import { Booking } from '../../Bookings/components/BookingsBody/styled';
import { BtnContainer } from '../../../components/Shared/ToolBar/styles';
import { GatsbyImage } from 'gatsby-plugin-image';
import useIcons from '../../../hooks/useIcons';
import ModalDialog from '../../../components/Modal/ModalDialog';
import StoreProductModal from '../Operations/StoreProductModal/StoreProductModal';

const StoreSubscriptionRecord = ({ subscription, navigateDrawer }) => {
  const icons = useIcons();
  const categories = subscription?.ProductCategories.map(category => category?.name).join(',');
  const ref = useRef(null);

  const handleClick = event => {
    if (ref?.current?.contains(event?.target)) {
      return;
    }
    navigateDrawer({ drawerLinkId: subscription?.id });
  };

  const handleClickButton = () => {
    ModalDialog.openModal({
      content: () => <StoreProductModal type="subscription" productId={subscription?.id} />,
      title: 'Edit Membership'
    });
  };

  const status = productStatuses.find(status => status.value === subscription?.status)?.label;

  return (
    <>
      <Booking onClick={handleClick} padding="6px 10px" gap={10}>
        <RecordBody fontWeight={'800'}>{subscription?.name}</RecordBody>
        <RecordBody flex="unset" fontWeight="600" width="100">
          {getBranchCurrencySymbol()}
          {subscription?.price}
        </RecordBody>
        <RecordBody flex="unset" fontWeight="600" width="100">
          {categories}
        </RecordBody>
        <RecordBody flex="unset" fontWeight="600" width="100" flexEnd>
          {status}
        </RecordBody>
        <HoverRecordOptionsContainer>
          <BtnContainer onClick={handleClickButton} ref={ref}>
            <GatsbyImage image={icons?.moreIcon?.childImageSharp?.gatsbyImageData} alt="more" />
          </BtnContainer>
        </HoverRecordOptionsContainer>
      </Booking>
      <Divider />
    </>
  );
};

export default StoreSubscriptionRecord;
