import styled from 'styled-components';

export const QuestionCategoriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  box-sizing: border-box;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  padding-top: 12px;
  box-sizing: border-box;
`;

export const QuestionRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 6px;
  background-color: #f2f2f2;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 0 18px;
  padding-bottom: 18px;
`;

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  box-sizing: border-box;
`;

export const QuestionTypeContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 12px;
  box-sizing: border-box;
`;
