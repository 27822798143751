import React from 'react';
import { ProductTitle } from '../ProductDrawer/styled';
import { DrawerContainer, DrawerSection } from '../../DrawerBar/styled';
import { BranchMessage, BRANCH_MESSAGE_TYPES_LABELS } from '../../../views/Store/BranchMessages/BranchMessages';

const BranchMessageDrawer = ({ data: branchMessage }: { data: BranchMessage }) => {
  return (
    <DrawerContainer scrollable>
      <DrawerSection paddingT={55}>
        <ProductTitle>
          Name: <strong>{branchMessage?.name}</strong>
        </ProductTitle>
        <ProductTitle>
          Sent After: <strong>{BRANCH_MESSAGE_TYPES_LABELS[branchMessage?.type]}</strong>
        </ProductTitle>
        <ProductTitle>
          Message: <strong>{branchMessage?.message}</strong>
        </ProductTitle>
        {branchMessage?.Product?.id && (
          <ProductTitle>
            Product: <strong>{branchMessage?.Product?.name || '-'}</strong>
          </ProductTitle>
        )}
      </DrawerSection>
    </DrawerContainer>
  );
};

export default BranchMessageDrawer;
