import { useMutation } from '@apollo/client';
import React, { FC } from 'react';
import Alert from '../../../components/Alert/Alert';
import ModalDialog, { ModalLayout } from '../../../components/Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../../../components/Modal/styled';
import { FormButtonsContainer, FormLabel, FormSubmitButton, InputsWrapper, WideInputGroup } from '../../../components/Shared/Forms/Forms';
import { Container } from '../../../components/Shared/Shared';
import { TriggerProcessOrderBills } from '../../../queries';

const GenerateInvoicesModal: FC = () => {
  const [handleTrigger, { data, loading, error }] = useMutation(TriggerProcessOrderBills);

  const onSubmit = () => {
    handleTrigger().then(() => {
      Alert.alert({
        title: 'Invoices will be generated shortly',
        description: 'The invoices will be generated shortly. Please check back later.',
        onAccept: () => ModalDialog.closeModal(),
        acceptButtonText: 'Ok',
        options: {
          hideDenyButton: true
        }
      });
    });
  };

  return (
    <>
      <ModalLayout
        compact
        isMini
        buttons={[
          <FormSubmitButton error={!!error} loading={loading} onClick={() => onSubmit()}>
            Generate Invoices
          </FormSubmitButton>
        ]}
      >
        <Container width={600}>
          <InputsWrapper>
            <WideInputGroup>
              <FormLabel bold>This action will generate invoices for all users who have pending bills.</FormLabel>
              <FormLabel>Are you sure you want to proceed?</FormLabel>
            </WideInputGroup>
          </InputsWrapper>
        </Container>
      </ModalLayout>
    </>
  );
};

export default GenerateInvoicesModal;
