import React, { FC } from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import { FormError, FormInput, FormLabel, FormSelect, InputGroup } from '../../components/Shared/Forms/Forms';
import { Divider } from '../../components/SideBar/styled';
import { getBranchCurrencySymbol } from '../../utils/getBranchCurrencySymbol';
import { ProductOptionsTypes } from './ProductOptions';
import { StoreProductModalForm } from './Operations/StoreProductModal/types';
import { CHARGE_STATUS, CHARGE_TYPE, Product, chargeStatuses } from './types';
import Select from '../../components/Shared/Forms/Select';

type ProductPriceProps = {
  defaultValues?: Partial<StoreProductModalForm>;
  formOptions: UseFormMethods<StoreProductModalForm>;
  type: Product['type'];
};

export const getChargeType = (chargeStatus: CHARGE_STATUS, productOptions?: Record<ProductOptionsTypes, boolean>) => {
  const { charge_type } = chargeStatuses[chargeStatus] || {};
  const isNoCharge = chargeStatus === CHARGE_STATUS.NO_CHARGE;
  const shouldBePostpaid = productOptions && (productOptions?.allows_repeated_orders || productOptions?.allows_bulk_orders);
  if (isNoCharge && shouldBePostpaid) {
    return CHARGE_TYPE.POSTPAID;
  }
  return charge_type;
};

export const isProductNoCharge = (product: Product) => {
  return +product?.original_price === 0;
};

export const isProductPostpaid = (product: Product) => {
  return product?.charge_type === CHARGE_TYPE.POSTPAID;
};

const ProductPrice: FC<ProductPriceProps> = ({ defaultValues, formOptions, type }) => {
  const { control, errors, setValue, watch } = formOptions;

  const watchedChargeStatus = watch('charge_status');

  const selectedNoCharge = watchedChargeStatus === CHARGE_STATUS.NO_CHARGE;

  const isSubscription = type === 'subscription';

  return (
    <>
      {!isSubscription && (
        <InputGroup maxWidth="450px">
          <FormLabel>Billing Type</FormLabel>
          <Controller
            render={({ onChange, value }) => (
              <Select
                onChange={option => {
                  if (option?.value === CHARGE_STATUS.NO_CHARGE) {
                    setValue('price_discount', 0);
                    setValue('original_price', 0);
                    setValue('price', 0);
                  }

                  onChange(option?.value);
                }}
                value={value ? chargeStatuses[value as CHARGE_STATUS] : undefined}
                options={Object.values(chargeStatuses)}
              />
            )}
            control={control}
            name={'charge_status'}
            defaultValue={defaultValues?.charge_status}
          />
        </InputGroup>
      )}

      <Divider />
      {!selectedNoCharge && (
        <>
          <InputGroup maxWidth="450px">
            <FormLabel>Base Price ({getBranchCurrencySymbol()})</FormLabel>
            <Controller
              as={<FormInput error={errors.original_price?.message} type={'number'} flexBasis={78} min={isSubscription ? 0.5 : 0} max={9999} />}
              control={control}
              name={'original_price'}
              defaultValue={defaultValues?.original_price}
              rules={{
                required: true,
                min: isSubscription ? 0 : 0,
                max: 9999
              }}
            />
            {errors.original_price && <FormError>{errors.original_price.message || 'Original Price is required'}</FormError>}
          </InputGroup>
          <InputGroup maxWidth="450px">
            <FormLabel>Discount (%)</FormLabel>
            <Controller
              as={<FormInput error={errors.price_discount?.message} type={'number'} flexBasis={78} min={0} max={100} />}
              control={control}
              name={'price_discount'}
              defaultValue={defaultValues?.price_discount}
              rules={{
                min: 0,
                max: 100
              }}
            />
            {errors.price_discount && <FormError>{errors.price_discount.message || 'Discounted Price is invalid'}</FormError>}
          </InputGroup>
        </>
      )}
    </>
  );
};

export default ProductPrice;
