import React, { FC, useMemo, useCallback } from 'react';
import { GetBusUserProfile } from '../../queries';
import { isTrainerBranch, isVetBranch, PETS_EXTENSTION_TABS_IDS } from '../PetDrawer/PetExtension';
import useNavigateDrawer from '../../hooks/useNavigateDrawer';
import { DRAWER_IDS } from '../DrawerBar/types';
import { useQuery } from '@apollo/client';
import { clearDrawerBars, setDrawerBar } from '../../reactive/actions';
import { handleReportAdd, NOTE_TYPES } from '../Profile/utils';
import { AddReportButton, ReportButtonsContainer } from './styled';
import { ModalBody, ModalFooter } from '../Modal/styled';
import { FormButtonsContainer, FormSubmitButton } from '../Shared/Forms/Forms';
import ModalDialog, { ModalLayout } from '../Modal/ModalDialog';
import useURLSearchParams from '../../hooks/useURLSearchParams';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';

const NOTE_TITLES: Record<NOTE_TYPES, string> = {
  [NOTE_TYPES.MEDICAL]: 'Medical Report',
  [NOTE_TYPES.GROOMING]: 'Grooming Report',
  [NOTE_TYPES.TRAINING]: 'Training Report',
  [NOTE_TYPES.WALKING]: 'Walking Report',
  [NOTE_TYPES.SITTING]: 'Sitting Report',
  [NOTE_TYPES.DAYCARE]: 'Daycare Report'
};

const ReportActionModal: FC<{ orderId?: string; petProfile: Record<string, any> }> = ({ orderId, petProfile }) => {
  const { data: { getBusUserProfile: profile = {} } = {} } = useQuery(GetBusUserProfile);
  const isVet = isVetBranch(profile?.Branch?.id);
  const isTrainer = isTrainerBranch(profile?.Branch?.id);
  const noteTypes = Object.values(NOTE_TYPES);
  const noteTabs = useMemo(() => {
    // if (isVet) {
    //   return noteTypes;
    // }
    // if (isTrainer) {
    //   return [NOTE_TYPES.TRAINING];
    // }

    return noteTypes;
  }, [isVet, isTrainer]);

  const navigateDrawer = useNavigateDrawer({
    drawerData: { drawerId: DRAWER_IDS.PETS_DRAWER }
  });

  const handleClick = ({ noteType }: { noteType: NOTE_TYPES }) => {
    clearDrawerBars();
    navigateDrawer({
      drawerLinkId: petProfile.id
    });

    handleReportAdd({
      noteType,
      petProfile,
      orderId
    });
  };

  const closeModal = useCallback(() => {
    ModalDialog.closeModal();
  }, []);

  const items: {
    name: string;
    value: NOTE_TYPES;
    onClick: () => void;
  }[] = noteTabs.map(noteType => ({
    name: NOTE_TITLES[noteType],
    value: noteType,
    onClick: () => handleClick({ noteType })
  }));

  return (
    <ModalLayout compact buttons={[<FormSubmitButton onClick={closeModal}>Close</FormSubmitButton>]}>
      <ReportButtonsContainer>
        {items.map(({ name, value, onClick }) => (
          <AddReportButton key={value} onClick={onClick}>
            {name}
            <span>{'>'}</span>
          </AddReportButton>
        ))}
      </ReportButtonsContainer>
    </ModalLayout>
  );
};

export default ReportActionModal;
