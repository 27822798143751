import { useMutation, useQuery } from '@apollo/client';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Divider } from '../../../../components/DrawerBar/styled';
import ModalDialog, { ModalLayout } from '../../../../components/Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../../../../components/Modal/styled';
import { MedCond, VaccRecord } from '../../../../components/Pets/types';
import {
  FormButtonsContainer,
  FormError,
  FormInput,
  FormLabel,
  FormSelect,
  FormSubmitButton,
  InputContainer,
  InputsWrapper,
  RadioBtnsGroup,
  SectionLabel,
  WideInputGroup
} from '../../../../components/Shared/Forms/Forms';
import useIcons from '../../../../hooks/useIcons';
import {
  AddMedCondVaccRecordItem,
  AddVaccination,
  DeleteMedCondVaccRecordItem,
  DeleteVaccination,
  EditVaccination as EditVaccinationQuery,
  GetBranchPetProfile,
  GetPetRecordRecords,
  GetVaccRecordTypes
} from '../../../../queries';
import client from '../../../../utils/client';
import { getNextDay, offsetUTC } from '../../../../utils/dates';
import { RequireAtLeastOne } from '../../../../utils/helpers';
import { ActionBtn, AddNewContainer } from '../../../Store/styled';

const nextShots = [
  { id: 1, name: 'In 1 week', diff: (date: Date) => date.setUTCDate(date.getUTCDate() + 7) },
  { id: 2, name: 'In 2 weeks', diff: (date: Date) => date.setUTCDate(date.getUTCDate() + 14) },
  { id: 3, name: 'In 1 month', diff: (date: Date) => date.setMonth(date.getMonth() + 1) },
  { id: 4, name: 'In 3 months', diff: (date: Date) => date.setMonth(date.getMonth() + 3) },
  { id: 5, name: 'In 6 months', diff: (date: Date) => date.setMonth(date.getMonth() + 6) },
  { id: 6, name: 'In 12 months', diff: (date: Date) => date.setFullYear(date.getFullYear() + 1) },
  { id: 7, name: 'In 24 months', diff: (date: Date) => date.setFullYear(date.getFullYear() + 2) },
  { id: 8, name: 'In 36 months', diff: (date: Date) => date.setFullYear(date.getFullYear() + 3) }
];
const colors = ['rgb(155,200,254)', 'rgb(27,152,153)', 'rgb(255,138,50)', 'rgb(255,216,179)', 'rgb(216,216,216)', 'rgb(255, 216, 94)', 'rgb(255, 166, 94)'];

export const AddNewVaccination = ({ vaccRecord, medCond, autoOpenModal = false }: { vaccRecord?: VaccRecord; medCond: MedCond; autoOpenModal?: boolean }) => {
  const icons = useIcons();
  const addIcon = icons.add.childImageSharp.gatsbyImageData.images.fallback.src;
  const showModal = useCallback(
    () =>
      ModalDialog.openModal({
        content: () => <VaccinationModal medCond={medCond} vaccRecord={vaccRecord} />,
        title: 'Add Vaccination',
        autoOpenModal
      }),
    [vaccRecord]
  );

  useEffect(() => {
    if (autoOpenModal) {
      showModal();
    }
  }, [autoOpenModal, showModal]);

  return <ActionBtn bgImage={addIcon} onClick={showModal} />;
};

type VaccinationFormProps = {
  medicalCaseName?: string;
  onSubmit: (form: any, addMore?: boolean) => void;
  loading: boolean;
  error: boolean;
  vaccRecord?: VaccRecord;
  handleDelete?: () => void;
  loadingDelete?: boolean;
};

const VaccinationForm: FC<VaccinationFormProps> = ({ medicalCaseName, onSubmit, loading, error, vaccRecord, handleDelete, loadingDelete }) => {
  const { control, handleSubmit, errors } = useForm();
  const { data: { vaccRecordTypeGet: vaccRecordTypes = [] } = {} } = useQuery<{ vaccRecordTypeGet: VaccRecord['VaccRecordType'][] }>(GetVaccRecordTypes, { fetchPolicy: 'cache-and-network' });
  const handleSave = handleSubmit(form => onSubmit({ ...form }));
  const handleSaveAddMore = handleSubmit(form => onSubmit({ ...form }, true));

  const nextdate = vaccRecord?.nextdate ? new Date(vaccRecord?.nextdate) : null;
  const lastDate = vaccRecord?.dates?.[vaccRecord?.dates?.length - 1];
  const lastShotDate = lastDate ? new Date(lastDate) : null;

  const nextShot = nextdate && lastShotDate ? nextShots.find(shot => shot.diff(new Date(lastShotDate)) === nextdate.getTime()) : null;

  const disabled = vaccRecord?.end_date ? new Date(vaccRecord?.end_date).getTime() < new Date().getTime() : false;

  const created24HoursAgo = vaccRecord ? (getNextDay(new Date(vaccRecord?.createdAt)) as Date).getTime() < new Date().getTime() : false;

  return (
    <ModalLayout
      compact
      buttons={[
        <FormSubmitButton error={error} loading={loading} onClick={handleSave} disabled={disabled}>
          {vaccRecord ? 'Save Changes' : 'Add Vaccination'}
        </FormSubmitButton>,
        !vaccRecord ? (
          <FormSubmitButton error={error} loading={loading} onClick={handleSaveAddMore}>
            Save & Add more
          </FormSubmitButton>
        ) : (
          <></>
        ),
        vaccRecord && !created24HoursAgo ? (
          <FormSubmitButton error={error} loading={loadingDelete} onClick={handleDelete} danger>
            Delete
          </FormSubmitButton>
        ) : (
          <></>
        )
      ]}
    >
      <>
        {!!disabled && <SectionLabel style={{ color: 'red' }}>This vaccination is expired. You can't edit it. Please add a new one.</SectionLabel>}

        <WideInputGroup>
          <FormLabel>Case Name</FormLabel>
          <FormInput error={errors.name} disabled value={medicalCaseName} />
          {errors.name && <FormError>{errors.name.message || 'case name is required'}</FormError>}
        </WideInputGroup>

        <WideInputGroup>
          <FormLabel>Vaccination Type</FormLabel>
          {!!vaccRecordTypes.length && (
            <Controller
              render={({ onChange }) => (
                <FormSelect
                  height={48}
                  fontSize={16}
                  name={'shape'}
                  onChange={e => onChange(e.target.value)}
                  defaultValue={vaccRecord?.VaccRecordType?.id || vaccRecordTypes[0].id}
                  disabled={disabled}
                >
                  {vaccRecordTypes.map(vaccRecordType => (
                    <option key={vaccRecordType.id} value={vaccRecordType.id}>
                      {vaccRecordType.name}
                    </option>
                  ))}
                </FormSelect>
              )}
              control={control}
              name={'vaccRecordType'}
              rules={{ required: true }}
              defaultValue={vaccRecord?.VaccRecordType?.id || vaccRecordTypes[0].id}
            />
          )}
          {errors.vaccRecordType && <FormError>{errors.vaccRecordType.message || 'Vaccination type is invalid'}</FormError>}
        </WideInputGroup>
        <WideInputGroup>
          <FormLabel>Brand Name</FormLabel>
          <Controller
            as={<FormInput error={errors.name} type={'text'} defaultValue={vaccRecord?.name || ''} disabled={disabled} />}
            control={control}
            name={'name'}
            rules={{ required: true }}
            defaultValue={vaccRecord?.name || ''}
          />
          {!!errors.name && <FormError>{errors.name?.message || 'Name is invalid'}</FormError>}
        </WideInputGroup>
        <WideInputGroup>
          <FormLabel>Batch Number</FormLabel>
          <Controller
            as={<FormInput error={errors.batchNumber} type={'text'} defaultValue={vaccRecord?.batch_number || ''} disabled={disabled} />}
            control={control}
            name={'batchNumber'}
            rules={{ required: true }}
            defaultValue={vaccRecord?.batch_number || ''}
          />
          {!!errors.batchNumber && <FormError>{errors.batchNumber?.message || 'Batch Number is invalid'}</FormError>}
        </WideInputGroup>
        <WideInputGroup>
          <FormLabel>Colour</FormLabel>
          <InputContainer isRow style={{ justifyContent: 'flex-start' }} marginBottom={10}>
            <Controller
              render={({ onChange, value }) => (
                <RadioBtnsGroup
                  options={colors}
                  defaultValue={vaccRecord?.color || colors[0]}
                  onChange={e => {
                    onChange(e?.target?.value);
                  }}
                  name={`color`}
                  asType={'color'}
                  noLabel
                  disabled={disabled}
                />
              )}
              control={control}
              name={`color`}
              defaultValue={vaccRecord?.color || colors[0]}
            />
          </InputContainer>
        </WideInputGroup>

        <Divider marginBottom={24} />

        <WideInputGroup>
          <SectionLabel>Date</SectionLabel>
          <FormLabel>Last Shot Date</FormLabel>
          <Controller
            as={<FormInput error={errors.lastShot} type={'date'} disabled={disabled} />}
            control={control}
            name={'lastShot'}
            defaultValue={lastShotDate?.toISOString().split('T')[0] || new Date().toISOString().split('T')[0]}
            rules={{
              required: true,
              validate: val => !!new Date(val).getTime()
            }}
          />
          {errors.lastShot && <FormError>{errors.lastShot.message || 'Last shot date is invalid'}</FormError>}
        </WideInputGroup>
        <WideInputGroup>
          <FormLabel>Next Shot Date</FormLabel>
          <Controller
            render={({ onChange }) => (
              <FormSelect height={48} fontSize={16} name={'shape'} onChange={e => onChange(e.target.value)} defaultValue={nextShot?.id || ''} disabled={disabled}>
                <option value={''} selected>
                  -- Unspecified --
                </option>
                {nextShots.map(timeFrame => (
                  <option key={timeFrame.id} value={timeFrame.id}>
                    {timeFrame.name}
                  </option>
                ))}
              </FormSelect>
            )}
            control={control}
            name={'nextShot'}
            rules={{ required: false }}
            defaultValue={nextShot?.id || ''}
          />
          {errors.nextShot && <FormError>{errors.nextShot.message || 'Next shot date is invalid'}</FormError>}
        </WideInputGroup>
      </>
    </ModalLayout>
  );
};

type VaccinationModalProps = RequireAtLeastOne<
  {
    medCond?: MedCond;
    vaccRecord?: VaccRecord;
    petId?: string;
  },
  'medCond' | 'vaccRecord'
>;

const VaccinationModal: FC<VaccinationModalProps> = ({ medCond, vaccRecord, petId }) => {
  const [addMore, setAddMore] = useState(false);
  const [addVaccRecord, { loading: loadingAdd, error: errorAdd, data: dataAdd }] = useMutation(AddVaccination);
  const [editVaccRecord, { loading: loadingEdit, error: errorEdit, data: dataEdit }] = useMutation(EditVaccinationQuery);
  const [addMedCondVaccRecordItem] = useMutation(AddMedCondVaccRecordItem);
  const [deleteVaccination, { loading: loadingDeleteVacc, error: errorDeleteVacc, data: dataDeleteVacc }] = useMutation(DeleteVaccination);
  const [deleteVaccRecordItem, { loading: loadingDeleteItem, error: errorDeleteItem, data: dataDeleteItem }] = useMutation(DeleteMedCondVaccRecordItem);

  const disabled = vaccRecord?.end_date ? new Date(vaccRecord?.end_date).getTime() < new Date().getTime() : false;

  const onFormSubmit = (form: Record<string, any>, addMore?: boolean) => {
    if (disabled) {
      return;
    }
    const lastShot = new Date(new Date(form.lastShot).getTime() - offsetUTC(form.lastShot));

    const nextdate = form.nextShot ? new Date(nextShots.find(shot => shot.id === Number(form.nextShot))?.diff(new Date(lastShot)) || 0) : null;

    const payload = {
      variables: {
        PetRecordId: medCond?.PetRecordId,
        VaccRecordTypeId: form.vaccRecordType,
        name: form.name.trim(),
        color: form.color,
        dates: [lastShot],
        nextdate,
        batch_number: form.batchNumber.trim(),
        end_date: nextdate ? null : lastShot
      }
    };
    if (!vaccRecord) {
      addVaccRecord(payload);
      setAddMore(!!addMore);
    }

    if (vaccRecord) {
      editVaccRecord({
        variables: {
          id: vaccRecord.id,
          ...payload.variables
        }
      });
    }
  };

  const handleDelete = () => {
    deleteVaccination({
      variables: {
        id: vaccRecord?.id
      }
    });
  };

  const refetch = async () => {
    let variables = {};
    if (!medCond?.id) {
      const { data: { getBranchPetProfile: petProfile = {} } = {} } = await client.query({
        query: GetBranchPetProfile,
        variables: {
          id: petId
        }
      });
      variables = {
        PetRecordId: petProfile?.PetRecord?.id
      };
    }

    await client.query({
      query: GetPetRecordRecords,
      variables: {
        ...(medCond?.id ? { MedCondId: [medCond?.id] } : variables)
      },
      fetchPolicy: 'network-only'
    });
  };

  useEffect(() => {
    const deleted = dataDeleteVacc?.deleteVaccRecord?.message;
    const edited = vaccRecord && dataEdit?.vaccRecordEdit?.id;
    const added = dataAdd?.vaccRecordAdd;
    if (deleted) {
      deleteVaccRecordItem({
        variables: {
          VaccRecordId: vaccRecord?.id,
          MedCondId: medCond?.id
        }
      }).then(() => {
        refetch();
        ModalDialog.closeModal();
      });
    }
    if (edited) {
      refetch();
      ModalDialog.closeModal();
    }

    if (added) {
      addMedCondVaccRecordItem({
        variables: {
          VaccRecordId: dataAdd.vaccRecordAdd.id,
          MedCondId: medCond?.id
        }
      }).then(() => {
        refetch();
        ModalDialog.closeModal();
        if (addMore) {
          setTimeout(() => {
            ModalDialog.openModal({
              content: () => <VaccinationModal medCond={medCond!} vaccRecord={vaccRecord!} petId={petId} />,
              title: 'Add Vaccination'
            });
          }, 1000);
        }
      });
    }
  }, [dataAdd, dataEdit, dataDeleteVacc]);

  return (
    <VaccinationForm
      medicalCaseName={vaccRecord ? vaccRecord?.MedConds?.[0]?.name || 'No medical case' : medCond?.name}
      onSubmit={onFormSubmit}
      loading={loadingAdd || loadingEdit}
      handleDelete={handleDelete}
      loadingDelete={loadingDeleteVacc || loadingDeleteItem}
      error={!!errorAdd || !!errorEdit || !!errorDeleteVacc || !!errorDeleteItem}
      vaccRecord={vaccRecord}
    />
  );
};

export default VaccinationModal;
