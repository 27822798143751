import {
  GetBranchNotesByMedCondId,
  GetBranchNotesByGroomingReportId,
  GetBranchNotesByTrainingReportId,
  GetBranchNotesByWalkingReportId,
  GetBranchNotesBySittingReportId,
  GetBranchNotesByDaycareReportId,
  GetBranchNotesByOrderId,
  GetBranchNotesByAppUserId,
  GetBranchNotesByPetRecordId
} from '../../../queries';

export enum BranchNoteType {
  MED_COND = 'MED_COND',
  GROOMING_REPORT = 'GROOMING_REPORT',
  TRAINING_REPORT = 'TRAINING_REPORT',
  WALKING_REPORT = 'WALKING_REPORT',
  SITTING_REPORT = 'SITTING_REPORT',
  DAYCARE_REPORT = 'DAYCARE_REPORT',
  ORDER = 'ORDER',
  APPUSER = 'APPUSER',
  PET_RECORD = 'PET_RECORD'
}

export const BranchNoteQueries = {
  [BranchNoteType.MED_COND]: GetBranchNotesByMedCondId,
  [BranchNoteType.GROOMING_REPORT]: GetBranchNotesByGroomingReportId,
  [BranchNoteType.TRAINING_REPORT]: GetBranchNotesByTrainingReportId,
  [BranchNoteType.WALKING_REPORT]: GetBranchNotesByWalkingReportId,
  [BranchNoteType.SITTING_REPORT]: GetBranchNotesBySittingReportId,
  [BranchNoteType.DAYCARE_REPORT]: GetBranchNotesByDaycareReportId,
  [BranchNoteType.ORDER]: GetBranchNotesByOrderId,
  [BranchNoteType.APPUSER]: GetBranchNotesByAppUserId,
  [BranchNoteType.PET_RECORD]: GetBranchNotesByPetRecordId
};

export type Note = {
  id: string;
  body: {
    content: string;
    content_html?: string;
  };
  timestamp: string;
  visibility: {
    BranchId: string;
  } | null;
  BusUser: {
    id: string;
    name: string;
  };
  MedCondId: string | null;
  GroomingReportId: string | null;
  TrainingReportId: string | null;
  WalkingReportId: string | null;
  SittingReportId: string | null;
  DaycareReportId: string | null;
  OrderId: string | null;
  AppUserId: string | null;
  PetRecordId: string | null;
};

export type BranchNoteBodyContentRef = {
  textAreaFocus: () => void;
  setIsEditing: (isEditing: boolean) => void;
};

export type BranchNotesRef = {
  addNote: () => void;
};
