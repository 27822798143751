import { useEffect, useState } from 'react';
import Notifier from '../Notifier';
import { GetBusUserProfile } from '../queries';
import AppStorage from '../utils/AppStorage';
import client from '../utils/client';
import config from '../utils/config';

type Options<T extends Record<string, any>> = {
  type: 'reports' | 'invoices' | 'balance-transactions' | 'invoice';
  fileFormat?: 'csv' | 'pdf';
  variables?: T;
  fileName?: string;
  headers?: HeadersInit;
  includeProviderBranchId?: boolean;
};

const useDownload = <T extends Record<string, any>>(options: Options<T>): [(options?: Omit<Options<T>, 'type'>) => Promise<void>, { loading: boolean; error: unknown }] => {
  const { variables: vars, type, headers, includeProviderBranchId } = options || {};
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>(null);

  const getFile = async (props?: Omit<Options<T>, 'type'>) => {
    const { variables = vars, fileName } = props || options || {};
    try {
      setLoading(true);
      const { data } = await client.query({ query: GetBusUserProfile, fetchPolicy: 'network-only' });
      if (!data?.getBusUserProfile?.id) {
        throw new Error('User not found');
      }

      const tokens = AppStorage.get('tokens');
      const token = tokens.token;
      const res = await fetch(`${config.BASE_API_URL}/download/${type}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          providerbranchid: includeProviderBranchId ? AppStorage.get('providerBranchId') || 'null' : 'null',
          Authorization: `Bearer ${token}`,
          clientid: config.CLIENT_ID,
          accept: 'text/csv',
          slotsv2: true,
          ...(headers || {})
        },
        body: JSON.stringify({ variables })
      });

      if (!res.ok) {
        throw new Error('Error downloading reports');
      }

      const blob = await res.blob();

      const file = new File([blob], `${fileName || 'file'}.${options.fileFormat || 'csv'}`, { type: blob.type });
      const url = window.URL.createObjectURL(file);
      const a = document.createElement('a');
      a.href = url;
      a.download = file.name;
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (e) {
      setError(e);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (error) {
      Notifier.error({
        message: 'Request Error',
        description: "We couldn't process your request Please try again later. If the problem persists, please contact Support."
      });
    }
  }, [error]);

  return [getFile, { loading, error }];
};

export default useDownload;
