import { GatsbyImage } from 'gatsby-plugin-image';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { DatePicker, RangePicker, Calendar } from 'react-trip-date';
import useIcons from '../../hooks/useIcons';
import { SUN_THREE_DAYS, SUN_THREE_LETTERS, getStartOfToday, toUtc } from '../../utils/dates';
import { startOfDay } from 'date-fns';
import { UserExtendButton } from '../UserDrawer/styled';
import { CalendarRangeContainer } from './styled';

type CalendarRangeProps = {
  currentCalendarLength: number;
  setUTCDate: (value: Date) => void;
  useDatePicker?: boolean;
  defaultValues?: { from: string; to: string };
};

export type CalendarRangeRef = {
  toggleRangePicker: () => void;
  clearRange: () => void;
  setRange: (range: { from: string; to: string }) => void;
};

export const getRange = (startDate: Date, length: number) => {
  const sd = toUtc(startDate);
  const endDate = new Date(new Date(sd).setUTCDate(sd.getUTCDate() + length - 1));
  return { from: sd.toISOString().slice(0, 10), to: endDate.toISOString().slice(0, 10) };
};

const CalendarRange = forwardRef<CalendarRangeRef, CalendarRangeProps>(({ currentCalendarLength, setUTCDate, useDatePicker = false, defaultValues = { from: '', to: '' } }, ref) => {
  const [range, setRange] = useState(defaultValues);
  const [datePickerSelectedDate, setUTCDatePickerSelectedDate] = useState<string[]>([]);
  const [show, setShow] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const icons = useIcons();
  const lessIcon = icons.lessLarge.childImageSharp.gatsbyImageData;
  const moreIcon = icons.moreLarge.childImageSharp.gatsbyImageData;

  const onRangeChange = (range: { from: string; to: string }) => {
    const [fromY, fromM, fromD] = range.from.split('-').map(Number);
    const from = new Date(fromY, fromM - 1, fromD);

    const fromDate = new Date(Date.UTC(from.getFullYear(), from.getMonth(), from.getDate()));
    const toDate = new Date(fromDate);
    toDate.setUTCDate(toDate.getUTCDate() + currentCalendarLength - 1);
    const newRange = {
      from: fromDate.toISOString().slice(0, 10),
      to: toDate.toISOString().slice(0, 10)
    };

    setRange(newRange);
    setUTCDatePickerSelectedDate([newRange.from]);
    setUTCDate(from);
  };

  const onDatePickerChange = (dates: string[]) => {
    const newDate = dates[dates.length - 1];
    setUTCDatePickerSelectedDate([newDate]);
    setUTCDate(new Date(newDate));
  };

  useEffect(() => {
    if (defaultValues.from && defaultValues.to) {
      setRange(defaultValues);
      setUTCDatePickerSelectedDate([defaultValues.from]);
    }
  }, [defaultValues]);

  const clearInput = () => {
    setRange({ from: '', to: '' });
    setUTCDatePickerSelectedDate([]);
  };

  // const handleBlur = useCallback(
  //   (e: React.FocusEvent<HTMLDivElement, Element>) => {
  //     const currentTarget = e.currentTarget;
  //     requestAnimationFrame(() => {
  //       if (!currentTarget.contains(document.activeElement)) {
  //         setShow(false);
  //       }
  //     });
  //   },
  //   [setShow]
  // );

  const goToToday = () => {
    const today = getStartOfToday();
    const fromDate = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()));
    const toDate = new Date(fromDate);
    toDate.setUTCDate(toDate.getUTCDate() + currentCalendarLength - 1);
    const newRange = {
      from: fromDate.toISOString().slice(0, 10),
      to: toDate.toISOString().slice(0, 10)
    };

    setRange(newRange);
    setUTCDatePickerSelectedDate([newRange.from]);
    setUTCDate(today);
  };

  useImperativeHandle(ref, () => ({
    toggleRangePicker: () => {
      // setShow(prev => {
      //   if (!prev) {
      //     containerRef.current?.focus();
      //   }
      //   return !prev;
      // });
    },

    clearRange: clearInput,

    setRange: (range: { from: string; to: string }) => {
      setRange(range);
      setUTCDatePickerSelectedDate([range.from]);
    }
  }));

  return (
    <CalendarRangeContainer show tabIndex={0} ref={containerRef}>
      {/* <Triangle menuPosition={OPTION_DROPDOWN_MENU_POSITIONS.RIGHT} /> */}
      {!useDatePicker && (
        <RangePicker
          initialMonthAndYear={range.from ? range.from : undefined}
          autoResponsive
          onChange={onRangeChange}
          selectedDays={range}
          numberOfMonths={2}
          components={{
            header: {
              monthIcons: {
                right: <GatsbyImage image={moreIcon} alt="moreIcon" style={{ cursor: 'pointer' }} />,
                left: <GatsbyImage image={lessIcon} alt="lessIcon" style={{ cursor: 'pointer' }} />
              }
            },
            titleOfWeek: {
              titles: SUN_THREE_LETTERS
            }
          }}
        />
      )}
      {useDatePicker && (
        <DatePicker
          autoResponsive
          onChange={onDatePickerChange}
          selectedDays={datePickerSelectedDate}
          initialMonthAndYear={datePickerSelectedDate.length > 0 ? datePickerSelectedDate[0] : undefined}
          numberOfMonths={2}
          components={{
            header: {
              monthIcons: {
                right: <GatsbyImage image={moreIcon} alt="moreIcon" style={{ cursor: 'pointer' }} />,
                left: <GatsbyImage image={lessIcon} alt="lessIcon" style={{ cursor: 'pointer' }} />
              }
            },
            titleOfWeek: {
              titles: SUN_THREE_DAYS
            }
          }}
        />
      )}
      <UserExtendButton noMargin onClick={goToToday}>
        Today
      </UserExtendButton>
    </CalendarRangeContainer>
  );
});

export default CalendarRange;
