import { useEffect } from 'react';
import { isValidUUID } from '../utils/validators';
import { useLocation } from '@reach/router';
import { setDrawerBar } from '../reactive/actions';
import { DRAWER_IDS } from '../components/DrawerBar/types';

export default function useSetDrawerOnNavigation({
  itemList,
  drawerId,
  otherData,
  otherParams,
  itemsToFilterFrom,
  deepFilter = false,
  fireOnce = true
}: {
  itemList: any[];
  drawerId: DRAWER_IDS;
  otherData?: Record<string, any>;
  otherParams?: { isExtended?: boolean; isPinned?: boolean };
  itemsToFilterFrom?: any[];
  deepFilter?: boolean;
  fireOnce?: boolean;
}) {
  const location = useLocation();
  const depsArray = !fireOnce ? [itemList, location] : [];

  useEffect(() => {
    setDrawerNavigation({ location: location.pathname, itemsToFilterFrom, deepFilter, drawerId, otherData, otherParams });
  }, depsArray);
}

type setDrawerNavigationParams = {
  location: any;
  itemsToFilterFrom?: any[];
  deepFilter?: boolean;
  drawerId: DRAWER_IDS;
  otherData?: Record<string, any>;
  otherParams?: { isExtended?: boolean; isPinned?: boolean };
};

export const setDrawerNavigation = ({ location, itemsToFilterFrom, deepFilter, drawerId, otherData, otherParams }: setDrawerNavigationParams) => {
  const [_, itemId] = isValidUUID(location) || [''];
  if (itemId) {
    const selectedItem = itemsToFilterFrom ? itemsToFilterFrom.find(item => (!deepFilter ? item.id : item[0].id) === itemId) : itemId;
    if (selectedItem) {
      setDrawerBar({ drawerId: drawerId, recordData: selectedItem, ...otherParams, otherData });
    }
  }
};
