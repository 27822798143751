import { useQuery, useReactiveVar } from '@apollo/client';
import React, { useEffect } from 'react';
import { Controller, ControllerRenderProps, useForm, useWatch } from 'react-hook-form';
import { getFilterKeyOptions } from '../../../../components/Shared/Filters/helpers';
import { PetTagFilterPill, PetTagsQuickFilterContainer, TagsOptionsContainer, TagsQuickFiltersWrapper } from '../../../../components/Shared/Filters/styled';
import { FiltersControl } from '../../../../components/Shared/Filters/useFilters';
import { FormInput } from '../../../../components/Shared/Forms/Forms';
import OptionDropdown from '../../../../components/Shared/Menus/OptionDropdown/OptionDropdown';
import { OPTION_DROPDOWN_MENU_BUTTON_TYPES, OPTION_DROPDOWN_MENU_POSITIONS, OPTION_DROPDOWN_TYPES } from '../../../../components/Shared/Menus/OptionDropdown/types';
import { VerticallyCenteredLoader } from '../../../../components/Shared/Spinner';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import { GetBranchAppointmentTags, GetBranchTagCategories } from '../../../../queries';
import { vars } from '../../../../reactive';
import { BranchAppointmentTag } from '../../../Store/BranchTags/types';
import { BOOKINGS_FILTER_TYPES, BOOKINGS_MORE_OPTIONS_TYPES } from '../../BookingsFilters';

const BookingTagItem = ({ form, item }: { form: ControllerRenderProps<Record<string, any>>; item: BranchAppointmentTag }) => {
  const { onChange, value } = form;
  const { mobile } = useMediaQuery({ mobile: true });
  return (
    <PetTagFilterPill>
      <FormInput
        type={'checkbox'}
        onChange={e => {
          if (e.target.checked) {
            onChange([item.id]);
          } else {
            onChange([]);
          }
        }}
        checked={value.includes(item.id)}
      />

      <span style={{ display: 'flex', gap: '10px' }}>
        {item.name}
        {/* <div>{item.appointmentsCountToday}</div> */}
      </span>
    </PetTagFilterPill>
  );
};

type BookingTagsQuickFilterProps = {
  filtersControl: FiltersControl<BOOKINGS_FILTER_TYPES, BOOKINGS_MORE_OPTIONS_TYPES>;
};

const BookingTagsQuickFilter = ({ filtersControl }: BookingTagsQuickFilterProps) => {
  const { control } = useForm();
  const { mobile } = useMediaQuery({ mobile: true });
  const selectedCategoryId = useReactiveVar(vars.bookingSelectedCategoryId);

  const selectedQuickFilters = useWatch<string[]>({
    control,
    name: 'selectedQuickFilters',
    defaultValue: []
  });

  const { data: { getBranchTagCategories: tagCategories = [] } = {}, loading: loadingTagCategories } = useQuery<{ getBranchTagCategories: { id: string; name: string }[] }>(GetBranchTagCategories);

  const {
    data: { getBranchAppointmentTags: categoryTags = [] } = {},
    loading: loadingTagsByCategory,
    refetch: refetchTagsByCategory
  } = useQuery<{ getBranchAppointmentTags: BranchAppointmentTag[] }>(GetBranchAppointmentTags, {
    variables: {
      offset: 0,
      limit: 1000,
      BranchTagCategoryId: [selectedCategoryId],
      visibility: ['showOnBar']
    },
    skip: !selectedCategoryId,
    fetchPolicy: 'cache-first'
  });

  const { data: { getBranchAppointmentTags: allQuickTags = [] } = {}, loading: loadingAllQuickTags } = useQuery<{
    getBranchAppointmentTags: BranchAppointmentTag[];
  }>(GetBranchAppointmentTags, {
    fetchPolicy: 'cache-and-network',
    variables: {
      offset: 0,
      limit: 1000,
      visibility: ['showOnBar']
    }
  });
  const loading = loadingTagCategories || loadingTagsByCategory || loadingAllQuickTags;

  const quickTags = selectedCategoryId ? categoryTags : allQuickTags;

  const { onChange } = getFilterKeyOptions({
    filtersControl,
    keys: {
      filterType: BOOKINGS_FILTER_TYPES.BOOKING_QUICK_TAGS,
      moreOption: BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_TAGS
    },
    transformValues: (values: string[]) => quickTags.filter(({ id }) => values.includes(id)).map(({ id, name }) => ({ value: id, name }))
  });

  useEffect(() => {
    onChange(selectedQuickFilters?.length ? selectedQuickFilters : null);
  }, [selectedQuickFilters]);

  return (
    <TagsQuickFiltersWrapper>
      {mobile && (
        <TagsOptionsContainer>
          <OptionDropdown
            menuButtonType={OPTION_DROPDOWN_MENU_BUTTON_TYPES.MORE}
            loading={loading}
            options={[
              {
                id: 'categories',
                optionType: OPTION_DROPDOWN_TYPES.BUTTONS,
                items: [
                  {
                    name: 'All',
                    value: 'all',
                    onClick: () => vars.bookingSelectedCategoryId(null),
                    green: !selectedCategoryId
                  },
                  ...tagCategories.map(({ id, name }) => ({
                    name,
                    value: id,
                    onClick: () => vars.bookingSelectedCategoryId(id),
                    green: selectedCategoryId === id
                  }))
                ]
              }
            ]}
            noApplyButton
            buttonLoading={loading}
            menuPosition={OPTION_DROPDOWN_MENU_POSITIONS.RIGHT}
          />
        </TagsOptionsContainer>
      )}

      <PetTagsQuickFilterContainer>
        {loading && <VerticallyCenteredLoader size={25} />}
        {!loading && (
          <>
            <Controller
              name={`selectedQuickFilters`}
              control={control}
              defaultValue={[]}
              render={form => (
                <>
                  {quickTags?.map((item, index) => (
                    <BookingTagItem key={index} form={form} item={item as BranchAppointmentTag} />
                  ))}
                </>
              )}
            />

            {/* {!mobile && (
              <AddPetTagButton
                onClick={() =>
                  ModalDialog.openModal({
                    content: () => (
                      <BranchTagsModal
                        presetType={BRANCH_TAGS_TYPES.APPOINTMENT}
                        defaultCategoryId={selectedCategoryId}
                        onAddTag={() => (selectedCategoryId ? refetchTagsByCategory()?.then(ModalDialog.closeModal) : null)}
                      />
                    ),
                    title: 'Add New Smart Tag'
                  })
                }
              >
                <span>+ Add</span>
              </AddPetTagButton>
            )} */}
          </>
        )}
      </PetTagsQuickFilterContainer>
      {!mobile && (
        <TagsOptionsContainer>
          <OptionDropdown
            menuButtonType={OPTION_DROPDOWN_MENU_BUTTON_TYPES.MORE}
            loading={loading}
            options={[
              {
                id: 'categories',
                optionType: OPTION_DROPDOWN_TYPES.BUTTONS,
                items: [
                  {
                    name: 'All',
                    value: 'all',
                    onClick: () => vars.bookingSelectedCategoryId(null),
                    green: !selectedCategoryId
                  },
                  ...tagCategories.map(({ id, name }) => ({
                    name,
                    value: id,
                    onClick: () => vars.bookingSelectedCategoryId(id),
                    green: selectedCategoryId === id
                  }))
                ]
              }
            ]}
            noApplyButton
            buttonLoading={loading}
          />
        </TagsOptionsContainer>
      )}
    </TagsQuickFiltersWrapper>
  );
};

export default BookingTagsQuickFilter;
