import React, { useEffect } from 'react';
import { BusUserProfile } from '../../../components/Profile/types';
import InfiniteList from '../../../components/Shared/InfiniteList/InfiniteList';
import usePaginatedQuery from '../../../hooks/usePaginatedQuery';
import { GetBranchRooms } from '../../../queries';
import Common from '../Common';
import { BranchRoom } from './types';
import BranchRoomRecord from './BranchRoomRecord';
import ToolBar from '../../../components/Shared/ToolBar/ToolBar';
import ModalDialog from '../../../components/Modal/ModalDialog';
import useIcons from '../../../hooks/useIcons';
import BranchRoomModal from './BranchRoomModal';
import { MenuButton } from '../../../components/Shared/Menus/OptionDropdown/styled';

const BranchRooms = () => {
  const [[hasMoreItems, setHasMoreItems], queryResult] = usePaginatedQuery<BranchRoom[]>({ query: GetBranchRooms, otherParams: { fetchPolicy: 'cache-and-network' } });

  const { data: { getBranchRooms: branchRooms = [] } = {}, loading, fetchMore, refetch } = queryResult;

  useEffect(() => {
    Common.set(`BranchRooms.GetBranchRooms.refetch`, () => {
      refetch?.().then(() => setHasMoreItems(true));
    });
  }, []);

  const icons = useIcons();

  const plusBlackIcon = icons?.plusBlackSVG?.publicURL;
  const onAdd = () => {
    ModalDialog.openModal({
      content: () => <BranchRoomModal />,
      title: 'Add New Pen',
      isMini: true
    });
  };

  return (
    <>
      <ToolBar
        displayString="Pens"
        middleAction={
          <>
            <MenuButton onClick={onAdd} bgImage={plusBlackIcon} width="28px" height="28px" />
          </>
        }
      />

      <InfiniteList
        list={branchRooms}
        itemRenderer={(branchRoom: BranchRoom) => <BranchRoomRecord branchRoom={branchRoom} />}
        hasMoreItems={hasMoreItems}
        loading={loading}
        fetchMore={fetchMore}
        offset={branchRooms?.length}
        setHasMoreItems={setHasMoreItems}
        paddingL={20}
        paddingR={20}
      />
    </>
  );
};

export default BranchRooms;
