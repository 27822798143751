import { Branch } from '../../../components/Profile/types';
import { getKeys } from '../../../utils/helpers';
import { BRANCH_BILLING_FILTERS, BRANCH_BILLING_FILTERS_SECONDARY_TOTALS, BRANCH_BILLING_FILTERS_TOTALS, CurrentPeriod } from './types';

export const getCountForSelectedFilter = ({
  counts,
  selectedFilter,
  useSecondaryTotals = false
}: {
  counts: NonNullable<Branch['branchCounts']>;
  selectedFilter: BRANCH_BILLING_FILTERS;
  useSecondaryTotals?: boolean;
}) => {
  const countKeys = getKeys(counts);
  const totals = BRANCH_BILLING_FILTERS_TOTALS[selectedFilter] || [];
  const secondaryTotals = BRANCH_BILLING_FILTERS_SECONDARY_TOTALS[selectedFilter] || [];
  const count = countKeys.reduce((acc, key) => {
    if ((useSecondaryTotals ? secondaryTotals : totals).includes(key)) {
      return acc + Number(Number(counts[key]).toFixed(2));
    }
    return acc;
  }, 0);

  return count;
};

export const getCurrentBillingFilters = (currentPeriod: CurrentPeriod) => {
  const hasSelectedPastBillingPeriod = !!currentPeriod.end && new Date(currentPeriod.end).getTime() < new Date().getTime();

  const currentSubscriptionFilter = hasSelectedPastBillingPeriod
    ? [BRANCH_BILLING_FILTERS.SUBSCRIPTIONS_APP_USERS, BRANCH_BILLING_FILTERS.SUBSCRIPTIONS, BRANCH_BILLING_FILTERS.SUBSCRIPTIONS_ORDER_TOTAL]
    : [
        // BRANCH_BILLING_FILTERS.SUBSCRIPTIONS_APP_USERS,
        BRANCH_BILLING_FILTERS.SUBSCRIPTIONS_ACTIVE,
        BRANCH_BILLING_FILTERS.SUBSCRIPTIONS_ISSUED_INVOICES,
        BRANCH_BILLING_FILTERS.SUBSCRIPTION_INVOICES_BILLED,
        BRANCH_BILLING_FILTERS.SUBSCRIPTION_INVOICES_PENDING_REJECTED,
        BRANCH_BILLING_FILTERS.SUBSCRIPTION_INVOICES_REFUNDED,
        BRANCH_BILLING_FILTERS.SUBSCRIPTIONS_ORDER_TOTAL
        // BRANCH_BILLING_FILTERS.SUBSCRIPTION_INVOICES_UNBILLED_ALL
      ];

  const currentBookingFilter = [
    BRANCH_BILLING_FILTERS.BOOKINGS_APP_USERS,
    // BRANCH_BILLING_FILTERS.BOOKINGS,
    BRANCH_BILLING_FILTERS.BOOKINGS_BILLED,
    BRANCH_BILLING_FILTERS.BOOKINGS_PENDING_REJECTED,
    BRANCH_BILLING_FILTERS.BOOKINGS_UPCOMING,
    BRANCH_BILLING_FILTERS.BOOKINGS_TOTAL
  ];

  const currentInvoiceFilter = [
    BRANCH_BILLING_FILTERS.INVOICES,
    BRANCH_BILLING_FILTERS.INVOICES_BILLED,
    BRANCH_BILLING_FILTERS.INVOICES_UNBILLED,
    BRANCH_BILLING_FILTERS.INVOICES_PENDING_REJECTED,
    BRANCH_BILLING_FILTERS.INVOICES_ORDER_TOTAL
  ];

  // const currentOrderFilter = [BRANCH_BILLING_FILTERS.ORDERS_APP_USERS, BRANCH_BILLING_FILTERS.ORDERS];

  const currentUnpaidFilter = [BRANCH_BILLING_FILTERS.SUBSCRIPTION_INVOICES_UNBILLED_ALL, BRANCH_BILLING_FILTERS.BOOKINGS_UNBILLED_ALL];

  return { currentSubscriptionFilter, currentBookingFilter, currentInvoiceFilter, currentUnpaidFilter };
};
