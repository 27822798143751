import React from 'react';
import usePaginatedQuery from '../../../hooks/usePaginatedQuery';
import { GetBranchOrders } from '../../../queries';
import { ORDER_STATUS, ORDER_STATUS_TYPES } from '../../Orders/types';
import OrdersList from '../../Orders/OrdersList';
import { ProductOrder } from '../../Orders/types';
import { CurrentPeriod } from './types';

const BranchBillingOrders = ({ period, variables }: { period: CurrentPeriod; variables: Record<string, any> }) => {
  const paginatedQueryResult = usePaginatedQuery<ProductOrder[]>({
    query: GetBranchOrders,
    defaultStatus: [ORDER_STATUS.PAYMENT_PENDING, ORDER_STATUS.PAYMENT_SUCCEEDED, ORDER_STATUS.CONFIRMED, ORDER_STATUS.OUT_FOR_DELIVERY],
    limit: 20,
    otherVariables: {
      type: 'product',
      createdAt_from: period.start,
      createdAt_to: period.end,
      ...variables
    }
  });

  return <OrdersList paginatedQueryResult={paginatedQueryResult} totalAtTheEnd />;
};

export default BranchBillingOrders;
