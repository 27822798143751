import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import React, { FC, useEffect, useState } from 'react';
import { Controller, UseFormMethods, useForm } from 'react-hook-form';
import { AddAppUsersToBranch, AddBranchAppUser, EditBranchAppUser, GetAllCountries, GetAppUserBranchConnectionStatus, GetBusUserProfile } from '../../queries';
import { errorCodes } from '../../utils/constants/errors';
import { RequireAtLeastOne, getKeys } from '../../utils/helpers';
import { BranchAppUser } from '../../views/Store/BranchBilling/types';
import { Country } from '../../views/Store/types';
import Alert from '../Alert/Alert';
import { Divider } from '../DrawerBar/styled';
import ModalDialog, { ModalLayout } from '../Modal/ModalDialog';
import { FlexContainer } from '../Pets/styled';
import { AppUserBranchConnectionStatus, BusUserProfile } from '../Profile/types';
import { FormCheckbox, FormError, FormInput, FormLabel, FormSubmitButton, InputGroup, InputsWrapper } from '../Shared/Forms/Forms';
import { FlexRow } from '../Shared/Shared';
import { CenteredLoader } from '../Shared/Spinner';
import PetDetailsModal from './PetDetailsModal';
import { isEmail, isValidCountryCode, isValidPhoneNumber } from '../../utils/validators';
import Select from '../Shared/Forms/Select';
import { CustomizationFormOptionsBodyContainer, CustomizationFormOptionsBodyWrapper, CustomizationFormOptionsContainer } from '../../views/Store/Customizations/styled';
import Colors from '../../Colors';
import { GroupPetDetails } from '../../views/Bookings/components/BookingsDrawer/styled';
import debounce from '../../utils/debounce';

type Address = { line1: string; line2: string; city: string; country: string };
type PhoneNumber = { countryCode: string; number: string };
type AdditionalInfo = { emergency_contact_name: string; secret_word: string };

type AppUserDetailsForm = {
  search_type: 'email' | 'phone';
  address: Address;
  phoneNumbers: { primary: PhoneNumber; emergency: PhoneNumber };
  postcode: string;
  name: string;
  email: string;
  isTermsAccepted: boolean;
  AdditionalInfo: AdditionalInfo;
};

type AppUserDetailsModalFormProps = {
  onSubmit: () => void;
  profile?: BranchAppUser;
  loading: boolean;
  error: boolean;
  formOptions: UseFormMethods<AppUserDetailsForm>;
  countries: Country[];
  busUserProfile?: BusUserProfile;
  defaultValues?: Partial<AppUserDetailsForm>;
  appUserStatus?: AppUserBranchConnectionStatus;
};

const AppUserDetailsModalForm: FC<AppUserDetailsModalFormProps> = ({ error, formOptions, loading, onSubmit, profile, countries, busUserProfile, defaultValues, appUserStatus }) => {
  const { control, errors, watch } = formOptions;
  const watchedTerms = profile?.id ? true : !!watch('isTermsAccepted');
  const watchedSearchType = watch('search_type');

  const renderPrimaryPhone = () => {
    return (
      <>
        <FlexContainer gap={6}>
          <Controller
            as={<FormInput error={!!errors?.phoneNumbers?.primary?.countryCode} type={'text'} name={'phoneNumbers.primary.countryCode'} width={80} />}
            control={control}
            name={'phoneNumbers.primary.countryCode'}
            defaultValue={defaultValues?.phoneNumbers?.primary?.countryCode || '+44'}
            rules={{ pattern: isValidCountryCode }}
          />
          <Controller
            as={<FormInput error={!!errors?.phoneNumbers?.primary?.number} type={'text'} name={'phoneNumbers.primary.number'} fullWidth />}
            control={control}
            name={'phoneNumbers.primary.number'}
            defaultValue={defaultValues?.phoneNumbers?.primary?.number || ''}
            rules={{ pattern: isValidPhoneNumber }}
          />
        </FlexContainer>
        {errors?.phoneNumbers?.primary?.countryCode && <FormError>{errors?.phoneNumbers?.primary?.countryCode?.message || 'Please add a valid country code'}</FormError>}
        {errors?.phoneNumbers?.primary?.number && <FormError>{errors?.phoneNumbers?.primary?.number?.message || 'Please add a valid number'}</FormError>}
      </>
    );
  };

  const renderEmail = ({ optional }: { optional?: boolean } = {}) => {
    return (
      <>
        <Controller
          as={<FormInput error={!!errors?.email} type={'text'} name={'email'} />}
          control={control}
          name={'email'}
          defaultValue={defaultValues?.email || ''}
          rules={{ required: optional ? false : 'Please add an email', pattern: isEmail }}
        />
        {errors?.email && <FormError>{errors?.email?.message || 'Please add a valid email'}</FormError>}
      </>
    );
  };

  const searchTypeOptions = [
    { value: 'email', label: 'Email' },
    { value: 'phone', label: 'Phone' }
  ];

  const countriesOptions = countries.map(country => ({ value: String(country.id), label: country.name }));

  const isArchived = (appUserStatus?.type === 'CONNECTED' || appUserStatus?.type === 'CONNECTED_THROUGH_CHAPPED_PET') && appUserStatus?.status === 'ARCHIVED';

  const isNotConnected = appUserStatus?.type === 'NOT_CONNECTED';

  const isNotAdded = appUserStatus?.type === 'NO_APP_USER';

  const isDisabled = !isArchived && !isNotConnected && (!watchedTerms || (appUserStatus && !isNotAdded));

  const [isExpanded, setIsExpanded] = useState(profile?.id ? true : false);

  return (
    <ModalLayout
      buttons={
        <FormSubmitButton error={error} loading={loading} onClick={onSubmit} disabled={isDisabled}>
          {profile?.id ? 'Update' : !appUserStatus ? 'Add' : isNotAdded ? 'Add' : isNotConnected ? 'Add to branch' : isArchived ? 'Unarchive' : 'Already exists'}
        </FormSubmitButton>
      }
      compact
    >
      <>
        {!profile?.id && (
          <FlexRow gap={10} width="400px">
            <InputGroup width={120}>
              <FormLabel error={!!errors.search_type}>Add by</FormLabel>
              <Controller
                render={({ onChange, value }) => {
                  return (
                    <Select
                      options={searchTypeOptions}
                      value={{ value: value, label: searchTypeOptions.find(option => option.value === value)?.label }}
                      onChange={option => {
                        const newValue = option?.value;
                        onChange(newValue);
                      }}
                    />
                  );
                }}
                control={control}
                name={'search_type'}
                rules={{ required: true }}
                defaultValue={defaultValues?.search_type || 'email'}
              />
            </InputGroup>
            {watchedSearchType === 'email' && (
              <InputGroup flex1>
                <FormLabel error={!!errors.email}>Email</FormLabel>
                {renderEmail()}
              </InputGroup>
            )}

            {watchedSearchType === 'phone' && (
              <InputGroup flex1>
                <FormLabel>Phone</FormLabel>
                {renderPrimaryPhone()}
              </InputGroup>
            )}
          </FlexRow>
        )}
        {!!profile?.id && !profile?.is_verified && (
          <>
            <InputGroup flex1>
              <FormLabel error={!!errors.email}>Email</FormLabel>
              {renderEmail()}
            </InputGroup>
            <Divider />
          </>
        )}
        {!!appUserStatus && !isNotAdded && (
          <GroupPetDetails backgroundColor={Colors.info2}>{isArchived ? 'User is archived' : isNotConnected ? 'User exists but not connected to branch' : 'User already exists'}</GroupPetDetails>
        )}
        {(isNotAdded || !!profile?.id) && (
          <>
            <InputGroup>
              <FormLabel bold>Details</FormLabel>
              <FormLabel>Name</FormLabel>
              <Controller
                as={<FormInput error={!!errors?.name} type={'text'} name={'name'} />}
                control={control}
                name={'name'}
                defaultValue={defaultValues?.name || ''}
                rules={{ required: 'Please add your name', pattern: /^[a-zA-Z0-9\s,.'-]{3,}$/ }}
              />
              {errors?.name && <FormError>{errors?.name?.message || 'Please add a valid name'}</FormError>}
            </InputGroup>
            <InputGroup>
              {!profile?.id && (
                <CustomizationFormOptionsContainer isExpanded={isExpanded} onClick={() => setIsExpanded(prev => !prev)} isTitle>
                  <FormLabel bold marginBottom={6}>
                    Optional Details
                  </FormLabel>
                </CustomizationFormOptionsContainer>
              )}
              <CustomizationFormOptionsBodyContainer isExpanded={isExpanded} backgroundColor={Colors.lightGrey2}>
                <CustomizationFormOptionsBodyWrapper backgroundColor={Colors.lightGrey2}>
                  {watchedSearchType === 'phone' && (
                    <CustomizationFormOptionsContainer>
                      <InputGroup>
                        <FormLabel error={!!errors.email}>Email</FormLabel>
                        {renderEmail({ optional: true })}
                      </InputGroup>
                    </CustomizationFormOptionsContainer>
                  )}
                  <CustomizationFormOptionsContainer>
                    <InputGroup>
                      <FormLabel bold>Address</FormLabel>
                      <FormLabel>Country</FormLabel>
                      <Controller
                        render={({ onChange, value }) => (
                          <Select
                            options={countriesOptions}
                            value={{ value: value, label: countriesOptions.find(option => option.value === value)?.label }}
                            onChange={option => {
                              const newValue = option?.value;
                              onChange(newValue);
                            }}
                          />
                        )}
                        control={control}
                        name={'address.country'}
                        defaultValue={defaultValues?.address?.country || String(countries[0]?.id) || ''}
                        rules={{ required: 'Please add your country' }}
                      />
                    </InputGroup>
                  </CustomizationFormOptionsContainer>
                  <CustomizationFormOptionsContainer>
                    <InputGroup>
                      <FormLabel>City</FormLabel>
                      <Controller
                        as={<FormInput error={!!errors?.address?.city} type={'text'} name={'address.city'} />}
                        control={control}
                        name={'address.city'}
                        defaultValue={defaultValues?.address?.city || ''}
                        rules={{ pattern: /^[a-zA-Z0-9\s,.'-]{3,}$/ }}
                      />
                      {errors?.address?.city && <FormError>{errors?.address?.city?.message || 'Please add a valid city'}</FormError>}
                    </InputGroup>
                  </CustomizationFormOptionsContainer>
                  <CustomizationFormOptionsContainer>
                    <InputGroup>
                      <FormLabel>Address Line 1</FormLabel>
                      <Controller
                        as={<FormInput error={!!errors?.address?.line1} type={'text'} name={'address.line1'} />}
                        control={control}
                        name={'address.line1'}
                        defaultValue={defaultValues?.address?.line1 || ''}
                        rules={{ pattern: /^[a-zA-Z0-9\s,.'-]{3,}$/ }}
                      />
                      {errors?.address?.line1 && <FormError>{errors?.address?.line1?.message || 'Please add a valid address'}</FormError>}
                    </InputGroup>
                  </CustomizationFormOptionsContainer>
                  <CustomizationFormOptionsContainer>
                    <InputGroup>
                      <FormLabel>Address Line 2</FormLabel>
                      <Controller
                        as={<FormInput error={!!errors?.address?.line2} type={'text'} name={'address.line2'} />}
                        control={control}
                        name={'address.line2'}
                        defaultValue={defaultValues?.address?.line2 || ''}
                        rules={{ pattern: /^[a-zA-Z0-9\s,.'-]{3,}$/ }}
                      />
                      {errors?.address?.line2 && <FormError>{errors?.address?.line2?.message || 'Please add a valid address'}</FormError>}
                    </InputGroup>
                  </CustomizationFormOptionsContainer>
                  <CustomizationFormOptionsContainer>
                    <InputGroup>
                      <FormLabel>Post Code</FormLabel>
                      <Controller
                        as={<FormInput error={!!errors?.postcode} type={'text'} name={'postcode'} />}
                        control={control}
                        name={'postcode'}
                        defaultValue={defaultValues?.postcode || ''}
                        rules={{ pattern: /^[a-zA-Z0-9\s,.'-]{3,}$/ }}
                      />
                      {errors?.postcode && <FormError>{errors?.postcode?.message || 'Please add a valid postcode'}</FormError>}
                    </InputGroup>
                  </CustomizationFormOptionsContainer>
                  <Divider />
                  <FormLabel bold>Phone Numbers</FormLabel>
                  {watchedSearchType !== 'phone' && (
                    <CustomizationFormOptionsContainer>
                      <InputGroup>
                        <FormLabel>Primary</FormLabel>
                        {renderPrimaryPhone()}
                      </InputGroup>
                    </CustomizationFormOptionsContainer>
                  )}
                  <CustomizationFormOptionsContainer>
                    <InputGroup>
                      <FormLabel>Emergency</FormLabel>
                      <FlexContainer gap={6}>
                        <Controller
                          as={<FormInput error={!!errors?.phoneNumbers?.emergency?.countryCode} type={'text'} name={'phoneNumbers.emergency.countryCode'} width={80} />}
                          control={control}
                          name={'phoneNumbers.emergency.countryCode'}
                          defaultValue={defaultValues?.phoneNumbers?.emergency?.countryCode || '+44'}
                          rules={{ pattern: /^(\+?\d{1,3}|\d{1,4})$/ }}
                        />
                        <Controller
                          as={<FormInput error={!!errors?.phoneNumbers?.emergency?.number} type={'text'} name={'phoneNumbers.emergency.number'} fullWidth />}
                          control={control}
                          name={'phoneNumbers.emergency.number'}
                          defaultValue={defaultValues?.phoneNumbers?.emergency?.number || ''}
                          rules={{ pattern: /^[a-zA-Z0-9\s,.'-]{3,}$/ }}
                        />
                      </FlexContainer>
                      {errors?.phoneNumbers?.emergency?.countryCode && <FormError>{errors?.phoneNumbers?.emergency?.countryCode?.message || 'Please add a valid country code'}</FormError>}
                      {errors?.phoneNumbers?.emergency?.number && <FormError>{errors?.phoneNumbers?.emergency?.number?.message || 'Please add a valid number'}</FormError>}
                    </InputGroup>
                  </CustomizationFormOptionsContainer>
                  <InputGroup>
                    <FormLabel bold>Emergency Contact Name</FormLabel>
                    <Controller
                      as={<FormInput error={error?.additional_info?.emergency_contact_name} type={'text'} name={'emergency_contact_name'} />}
                      control={control}
                      name={'emergency_contact_name'}
                      defaultValue={defaultValues?.additionalInfo?.emergency_contact_name || ''}
                    />
                  </InputGroup>
                  <InputGroup>
                    <FormLabel bold>Secret Word</FormLabel>
                    <Controller
                      as={<FormInput error={error?.additionalInfo?.secret_word} type={'text'} name={'secret_word'} />}
                      control={control}
                      name={'secret_word'}
                      defaultValue={defaultValues?.additionalInfo?.secret_word || ''}
                    />
                  </InputGroup>
                </CustomizationFormOptionsBodyWrapper>
              </CustomizationFormOptionsBodyContainer>
            </InputGroup>
            {isNotAdded && (
              <InputGroup>
                <FormLabel bold>Terms</FormLabel>
                <FormLabel>Accept Terms</FormLabel>
                <Controller
                  render={({ onChange, value }) => (
                    <FormCheckbox
                      itemsArray={[
                        {
                          name: `I confirm that ${busUserProfile?.Branch?.name} have obtained full consent form the customer whose details entered above to store their personal details and create an account for them for the purpose of fulfilling services and/or product orders. I confirm that they have been informed of our terms and condition including service, payment and privacy policies.`,
                          id: 'isTermsAccepted'
                        }
                      ]}
                      onChange={newVal => {
                        onChange(!!newVal[0]);
                      }}
                      value={value ? ['isTermsAccepted'] : []}
                      column
                      fontSize={14}
                    />
                  )}
                  control={control}
                  name={'isTermsAccepted'}
                  defaultValue={false}
                  rules={{ required: 'Please accept the terms' }}
                />

                {errors?.isTermsAccepted && <FormError>{errors?.isTermsAccepted?.message || 'Please accept the terms'}</FormError>}
              </InputGroup>
            )}
          </>
        )}
      </>
    </ModalLayout>
  );
};

const AppUserDetailsModalContent = ({
  countries,
  profile,
  currentBusUserProfile,
  onClose
}: {
  profile?: BranchAppUser;
  currentBusUserProfile?: BusUserProfile;
  countries: Country[];
  onClose?: () => void;
}) => {
  const { data: { getBusUserProfile: busUserProfile } = {} } = useQuery<{ getBusUserProfile: BusUserProfile }>(GetBusUserProfile, {
    fetchPolicy: 'cache-only'
  });

  const getCountryIdFromName = (name: string) => String(countries.find(country => country.name === name)?.id) || '';
  const getCountryNameFromId = (id: string) => countries.find(country => String(country.id) === id)?.name || '';

  const defaultValues = {
    search_type: 'email' as 'email' | 'phone',
    name: profile?.name || '',
    address: {
      country: getCountryIdFromName(profile?.addresses?.[0]?.country || busUserProfile?.Branch?.address?.country || 'United Kingdom') || (countries[0]?.id ? String(countries[0]?.id) : '1'),
      city: profile?.addresses?.[0]?.city || 'London',
      line1: profile?.addresses?.[0]?.line1 || '',
      line2: profile?.addresses?.[0]?.line2 || ''
    },
    postcode: profile?.postcode || '',
    phoneNumbers: {
      primary: {
        countryCode: profile?.phone_numbers?.find(phone => phone?.label === 'primary')?.country_code || '+44',
        number: profile?.phone_numbers?.find(phone => phone?.label === 'primary')?.number || ''
      },
      emergency: {
        countryCode: profile?.phone_numbers?.find(phone => phone?.label === 'emergency')?.country_code || '+44',
        number: profile?.phone_numbers?.find(phone => phone?.label === 'emergency')?.number || ''
      }
    },
    email: profile?.email || '',
    isTermsAccepted: false,
    additionalInfo: {
      emergency_contact_name: profile?.additional_info?.emergency_contact_name || '',
      secret_word: profile?.additional_info?.secret_word || ''
    }
  };

  const formOptions = useForm<AppUserDetailsForm>({ defaultValues, shouldUnregister: false });
  const { handleSubmit, getValues, watch, formState, trigger } = formOptions;

  const [getAppUserConnectionStatus, { data: appUserConnectionStatusData }] = useLazyQuery<{ getAppUserBranchConnectionStatus: AppUserBranchConnectionStatus }>(GetAppUserBranchConnectionStatus, {
    fetchPolicy: 'network-only'
  });

  const appUserStatus = appUserConnectionStatusData?.getAppUserBranchConnectionStatus;

  const [handleEditUser, { data: editedUser, loading: loadingEditUser, error: errorEditUser }] = useMutation<{ editBranchAppUser: BranchAppUser }>(EditBranchAppUser);

  const [addAppUsersToBranch, { data: addedAppUsersToBranch, loading: loadingAddAppUsersToBranch, error: errorAddAppUsersToBranch }] = useMutation<
    { addAppUsersToBranch: BranchAppUser[] },
    RequireAtLeastOne<{ email: string[]; id: string[] }>
  >(AddAppUsersToBranch, {
    onError: error => {
      if (error.graphQLErrors?.[0]?.code === errorCodes.BRANCH_APP_USER_ALREADY_ADDED) {
        Alert.alert({
          title: 'User already exists',
          description: 'This user already exists in the system and in the branch.',
          acceptButtonText: 'Okay',
          denyButtonText: 'Close',
          onDeny: () => {
            ModalDialog.closeModal();
          }
        });
      }
    }
  });

  const [handleAddUser, { data: addedUser, loading: loadingAddUser, error: errorAddUser }] = useMutation<{ addBranchAppUser: BranchAppUser }>(AddBranchAppUser, {
    refetchQueries: ['getBranchAppUsers'],
    awaitRefetchQueries: true,
    onError: error => {
      if (error.graphQLErrors?.[0]?.code === errorCodes.BRANCH_APP_USER_ALREADY_ADDED) {
        Alert.alert({
          title: 'User already exists',
          description: 'This user already exists in the system and in the branch.',
          acceptButtonText: 'Okay',
          denyButtonText: 'Close',
          onDeny: () => {
            ModalDialog.closeModal();
          }
        });
      }
      if (error.graphQLErrors?.[0]?.code === errorCodes.APP_USER_ALREADY_EXISTS) {
        Alert.alert({
          title: 'User already exists',
          description: 'This user already exists in the system. Do you want to add this user to the branch instead?',
          acceptButtonText: 'Add to branch',
          onAccept: () => {
            addAppUsersToBranch({ variables: { email: [getValues().email] } });
          }
        });
      }
    }
  });

  const getPhoneNumbers = (phoneNumbersValue?: { primary?: PhoneNumber; emergency?: PhoneNumber }) => {
    const phoneNumbers = getKeys(phoneNumbersValue || {})
      .map(key => {
        const phone = phoneNumbersValue?.[key];
        if (phone?.number && phone?.countryCode) {
          return { label: key, country_code: phone?.countryCode.trim(), number: phone?.number.trim() };
        }
        return { label: key, country_code: '', number: '' };
      })
      .filter(phone => phone.number && phone.country_code);

    return phoneNumbers;
  };

  const onSubmit = handleSubmit(form => {
    if (appUserStatus && !['NO_APP_USER'].includes(appUserStatus?.type)) {
      addAppUsersToBranch({ variables: { id: [appUserStatus?.id || ''] } });
      return;
    }
    const addresses = [{ ...form.address }].filter(address => address.city && address.country)?.map(address => ({ ...address, country: getCountryNameFromId(address.country) })) || [];

    const phoneNumbers = getPhoneNumbers(form.phoneNumbers);

    const emergencyContactName = form?.emergency_contact_name;
    const secretWord = form?.secret_word;

    const variables = { addresses, phone_numbers: phoneNumbers, postcode: form.postcode || '', name: form.name, country_id: form.address.country };

    if (!profile?.id) {
      if (!form.isTermsAccepted) {
        return;
      }
      handleAddUser({ variables: { ...variables, additional_info: { emergency_contact_name: emergencyContactName, secret_word: secretWord }, email: form.email } });
      return;
    }

    handleEditUser({
      variables: {
        ...variables,
        id: profile?.id,
        additional_info: { ...(profile?.additional_info || {}), emergency_contact_name: emergencyContactName, secret_word: secretWord },
        ...(profile?.is_verified
          ? {}
          : {
              email: form.email
            })
      }
    });
  });

  const watchedEmail = watch('email');
  const watchedPhoneNumbers = watch('phoneNumbers');
  const watchedSearchType = watch('search_type');

  useEffect(() => {
    const primaryPhoneNumber = getPhoneNumbers(watchedPhoneNumbers)?.find(phone => phone.label === 'primary');
    const isValidEmail = watchedEmail && isEmail.test(watchedEmail);
    const isValidPrimaryPhoneNumber =
      primaryPhoneNumber &&
      primaryPhoneNumber.number &&
      primaryPhoneNumber.country_code &&
      isValidCountryCode.test(primaryPhoneNumber.country_code) &&
      isValidPhoneNumber.test(primaryPhoneNumber.number);

    const isEmailSearch = watchedSearchType === 'email';

    if (!profile?.id && (isEmailSearch ? isValidEmail : isValidPrimaryPhoneNumber)) {
      debounce(() => {
        getAppUserConnectionStatus({ variables: isEmailSearch ? { email: watchedEmail } : { phone_numbers_primary: primaryPhoneNumber } });
      }, 500);
    }
  }, [watchedEmail, profile?.id, JSON.stringify(watchedPhoneNumbers), watchedSearchType]);

  useEffect(() => {
    if (editedUser?.editBranchAppUser?.id || addedUser?.addBranchAppUser?.id || addedAppUsersToBranch?.addAppUsersToBranch?.length) {
      ModalDialog.closeModal();

      if (editedUser?.editBranchAppUser?.id || (appUserStatus && !['NO_APP_USER'].includes(appUserStatus?.type))) {
        return;
      }

      ModalDialog.openModal({
        content: () => <PetDetailsModal initialValues={{ AppUserId: addedUser?.addBranchAppUser?.id || addedAppUsersToBranch?.addAppUsersToBranch?.[0]?.id }} onClose={onClose} />,
        title: 'Add Pet'
      });
    }
  }, [editedUser, addedUser, addedAppUsersToBranch]);

  const loading = loadingEditUser || loadingAddUser || loadingAddAppUsersToBranch;
  const error = !!errorEditUser?.message || !!errorAddUser?.message || !!errorAddAppUsersToBranch?.message;

  return (
    <AppUserDetailsModalForm
      onSubmit={onSubmit}
      loading={loading}
      error={error}
      profile={profile}
      formOptions={formOptions}
      countries={countries}
      busUserProfile={currentBusUserProfile}
      defaultValues={defaultValues}
      appUserStatus={appUserStatus}
    />
  );
};

const AppUserDetailsModal = ({ profile, onClose }: { profile?: BranchAppUser; onClose?: () => void }) => {
  const { data: { countryGet: countries = [] } = {}, loading: loadingCountries } = useQuery<{ countryGet: Country[] }>(GetAllCountries, {
    fetchPolicy: 'cache-first'
  });
  const { data: { getBusUserProfile: currentBusUserProfile = {} } = {}, loading: loadingBusUser } = useQuery(GetBusUserProfile, {
    fetchPolicy: 'cache-only'
  });

  const loading = loadingCountries || loadingBusUser;

  if (loading) {
    return <CenteredLoader />;
  }

  return <AppUserDetailsModalContent countries={countries} profile={profile} currentBusUserProfile={currentBusUserProfile} onClose={onClose} />;
};

export default AppUserDetailsModal;
