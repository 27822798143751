import { useMutation } from '@apollo/client';
import React, { FC, useEffect } from 'react';
import { Controller, UseFormMethods, useForm } from 'react-hook-form';
import { AddBranchPet, EditBranchPet } from '../../queries';
import ModalDialog, { ModalLayout } from '../Modal/ModalDialog';
import { Pet, PetType } from '../Pets/types';
import { FormError, FormInput, FormLabel, FormSubmitButton, InputsWrapper, InputGroup } from '../Shared/Forms/Forms';
import AppUsersList from '../Shared/Lists/AppUsersList';
import BreedsList from '../Shared/Lists/BreedsList';
import PetColorsList from '../Shared/Lists/PetColorsList';
import Select from '../Shared/Forms/Select';
import { petTypesOptions } from '../../views/Store/Operations/StoreProductModal/types';
import NewBookingModal from '../Shared/Modals/NewBookingModal/NewBookingModal';
import { vars } from '../../reactive';
import { hideCalendarActionMessage } from '../../reactive/actions';
import { Booking } from '../../views/Bookings/types';
import Common from '../../views/Store/Common';

type PetDetailsForm = {
  type: keyof typeof PetType;
  name: string;
  birthdate: string;
  gender: Pet['gender'];
  // profile_pic: string;
  AppUserId: string;
  BreedId: string;
  PetColorId: string;
  petRecord_neutred: string;
};

type PetDetailsModalFormProps = {
  onSubmit: () => void;
  pet?: Pet;
  loading: boolean;
  error: boolean;
  formOptions: UseFormMethods<PetDetailsForm>;
  defaultValues?: PetDetailsForm;
};

const PetDetailsModalForm: FC<PetDetailsModalFormProps> = ({ error, formOptions, loading, onSubmit, pet, defaultValues }) => {
  const { control, errors, watch } = formOptions;

  const watchedValues = watch(['type', 'gender']);

  const petGenderOptions = [
    {
      value: 'MALE',
      label: 'Male'
    },
    {
      value: 'FEMALE',
      label: 'Female'
    }
  ];

  const petNeuteredOptions = [
    {
      value: 'true',
      label: 'Yes'
    },
    {
      value: 'false',
      label: 'No'
    }
  ];

  return (
    <ModalLayout
      buttons={
        <FormSubmitButton error={error} loading={loading} onClick={onSubmit}>
          {pet?.id ? 'Update' : 'Add'}
        </FormSubmitButton>
      }
      compact
    >
      <>
        {!pet?.id && (
          <InputGroup>
            <FormLabel bold>Client</FormLabel>
            <FormLabel>Client</FormLabel>
            <AppUsersList
              formOptions={formOptions}
              name="AppUserId"
              rules={{
                required: true
              }}
              defaultValues={defaultValues?.AppUserId ? [defaultValues.AppUserId] : []}
            />
          </InputGroup>
        )}
        <InputGroup>
          <FormLabel bold>Details</FormLabel>
          <FormLabel>Name</FormLabel>
          <Controller
            as={<FormInput error={errors?.name?.message} type={'text'} name={'name'} />}
            control={control}
            name={'name'}
            defaultValue={defaultValues?.name || ''}
            rules={{ required: 'Please add a name', pattern: /^[a-zA-Z0-9\s,.'-]{3,}$/ }}
          />
          {errors?.name && <FormError>{errors?.name?.message}</FormError>}
        </InputGroup>
        <InputGroup>
          <FormLabel>Type</FormLabel>
          <Controller
            render={({ onChange, value }) => (
              <Select
                options={petTypesOptions}
                value={{ value: value, label: petTypesOptions.find(option => option.value === value)?.label }}
                onChange={option => {
                  const newValue = option?.value;
                  onChange(newValue);
                }}
              />
            )}
            control={control}
            name={'type'}
            defaultValue={defaultValues?.type || ''}
            rules={{ required: 'Please select a type' }}
          />
          {errors?.type && <FormError>{errors?.type?.message}</FormError>}
        </InputGroup>
        <InputGroup>
          <FormLabel>Gender</FormLabel>
          <Controller
            render={({ onChange, value }) => (
              <Select
                options={petGenderOptions}
                value={{ value, label: petGenderOptions.find(option => option.value === value)?.label }}
                onChange={option => {
                  const newValue = option?.value;
                  onChange(newValue);
                }}
              />
            )}
            control={control}
            name={'gender'}
            defaultValue={defaultValues?.gender || 'MALE'}
            rules={{ required: 'Please select a gender' }}
          />

          {errors?.gender && <FormError>{errors?.gender?.message}</FormError>}
        </InputGroup>
        <InputGroup>
          <FormLabel>Birthdate</FormLabel>
          <Controller
            as={<FormInput error={errors?.birthdate?.message} type={'date'} name={'birthdate'} max={new Date().toISOString().split('T')[0]} />}
            control={control}
            name={'birthdate'}
            defaultValue={defaultValues?.birthdate || ''}
            rules={{ required: 'Please add a birthdate', validate: value => new Date(value) < new Date() || 'Birthdate cannot be in the future', max: new Date().toISOString().split('T')[0] }}
          />
          {errors?.birthdate && <FormError>{errors?.birthdate?.message}</FormError>}
        </InputGroup>
        <InputGroup>
          <FormLabel>Breed</FormLabel>
          <BreedsList
            formOptions={formOptions}
            name="BreedId"
            defaultValues={defaultValues?.BreedId ? [defaultValues?.BreedId] : []}
            petType={watchedValues?.type ? [watchedValues?.type] : undefined}
          />
        </InputGroup>
        <InputGroup>
          <FormLabel>{watchedValues?.gender === 'FEMALE' ? 'Spayed' : 'Neutered'}</FormLabel>
          <Controller
            render={({ onChange, value }) => (
              <Select
                options={petNeuteredOptions}
                value={{ value: value, label: petNeuteredOptions.find(option => option.value === value)?.label }}
                onChange={option => {
                  const newValue = option?.value;
                  onChange(newValue);
                }}
              />
            )}
            control={control}
            name={'petRecord_neutred'}
            rules={{ required: true }}
            defaultValue={defaultValues?.petRecord_neutred || false}
          />
          {errors?.petRecord_neutred && <FormError>{errors?.petRecord_neutred?.message}</FormError>}
        </InputGroup>
        <InputGroup>
          <FormLabel>Color</FormLabel>
          <PetColorsList formOptions={formOptions} name="PetColorId" defaultColorId={defaultValues?.PetColorId} petType={watchedValues?.type} />
        </InputGroup>
      </>
    </ModalLayout>
  );
};

const PetDetailsModal = ({ pet, initialValues, onClose }: { pet?: Pet; initialValues?: Partial<PetDetailsForm>; onClose?: () => void }) => {
  const defaultValues: PetDetailsForm = {
    AppUserId: initialValues?.AppUserId || pet?.AppUser?.id || pet?.AppUserId || '',
    BreedId: initialValues?.BreedId || pet?.Breed?.id || '',
    PetColorId: initialValues?.PetColorId || pet?.PetColor?.id || '',
    birthdate: initialValues?.birthdate || pet?.birthdate || '',
    gender: initialValues?.gender || pet?.gender || 'MALE',
    name: initialValues?.name || pet?.name || '',
    type: initialValues?.type || pet?.type || PetType.DOG,
    petRecord_neutred: 'false'
  };
  const formOptions = useForm<PetDetailsForm>({ defaultValues });
  const { handleSubmit } = formOptions;

  const [handleEditPet, { data: editedUser, loading: loadingEditUser, error: errorEditUser }] = useMutation<{ editBranchPet: Pet }>(EditBranchPet);

  const [handleAddPet, { data: addedUser, loading: loadingAddUser, error: errorAddUser }] = useMutation<{ addBranchPet: Pet }>(AddBranchPet);

  const onSubmit = handleSubmit(form => {
    const variables = {
      BreedId: form.BreedId,
      PetColorId: form.PetColorId || null,
      birthdate: form.birthdate,
      gender: form.gender,
      name: form.name,
      type: form.type,
      petRecord_neutred: form.petRecord_neutred === 'true'
    };

    if (!pet?.id) {
      handleAddPet({ variables: { ...variables, AppUserId: form.AppUserId } });
      return;
    }

    handleEditPet({ variables: { ...variables, id: pet?.id } });
  });

  useEffect(() => {
    if (editedUser?.editBranchPet?.id || addedUser?.addBranchPet?.id) {
      ModalDialog.closeModal();
      onClose?.();
    }
  }, [editedUser, addedUser]);

  const loading = loadingEditUser || loadingAddUser;
  const error = !!errorEditUser?.message || !!errorAddUser?.message;

  return <PetDetailsModalForm onSubmit={onSubmit} loading={loading} error={error} pet={pet} formOptions={formOptions} defaultValues={defaultValues} />;
};

export default PetDetailsModal;
