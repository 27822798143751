import React from 'react';
import usePaginatedQuery from '../../../hooks/usePaginatedQuery';
import { GetBranchOrderSubscriptionRepeats } from '../../../queries';
import SubscriptionRepeatsList from '../../Subscriptions/SubscriptionRepeatsList';
import { OrderSubscriptionRepeat } from '../../Subscriptions/types';
import { CurrentPeriod } from './types';

const BranchBillingSubscriptionRepeats = ({ period, variables }: { period: CurrentPeriod; variables: Record<string, any> }) => {
  const paginatedQueryResult = usePaginatedQuery<OrderSubscriptionRepeat[]>({
    query: GetBranchOrderSubscriptionRepeats,
    limit: 20,
    otherVariables: {
      payment_invoice_date_from: period.start,
      payment_invoice_date_to: period.end,
      total_from: 0,
      ...variables
    }
  });

  return <SubscriptionRepeatsList paginatedQueryResult={paginatedQueryResult} />;
};

export default BranchBillingSubscriptionRepeats;
