import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { BranchAppUsersViewCountsSubscription, GetBranchAppUsersViewCounts, GetBusUserProfile } from '../queries';

type PetTypesCount = {
  DOG_count: number;
  CAT_count: number;
  RABBIT_count: number;
  HORSE_count: number;
  DUCK_count: number;
  GUINEA_PIG_count: number;
};

export type GetBranchAppUsersViewCountsReturn = {
  submittedFormsCount: number;
  appUsersCount: number;
  petsCount: number;
  petsTypesCount: PetTypesCount;
  activeAppUsersCount: number;
  archivedAppUsersCount: number;
  activePetsCount: number;
  archivedPetsCount: number;
  petsTypesCountActive: PetTypesCount;
  petsTypesCountArchived: PetTypesCount;
  shouldNotify: boolean;
};

const useBranchAppUsersViewCounts = () => {
  const { data: { getBusUserProfile: profile = {} } = {} } = useQuery(GetBusUserProfile, {
    fetchPolicy: 'cache-only'
  });

  const { data: unreadOrdersCount, subscribeToMore } = useQuery<{ getBranchAppUsersViewCounts: GetBranchAppUsersViewCountsReturn }>(GetBranchAppUsersViewCounts, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true
  });

  const {
    submittedFormsCount,
    appUsersCount,
    petsCount,
    petsTypesCount,
    activeAppUsersCount,
    activePetsCount,
    archivedAppUsersCount,
    archivedPetsCount,
    petsTypesCountActive,
    petsTypesCountArchived,
    shouldNotify
  } = unreadOrdersCount?.getBranchAppUsersViewCounts || {};

  useEffect(() => {
    const sub = subscribeToMore<{ branchAppUsersViewCountsUpdated: GetBranchAppUsersViewCountsReturn }>({
      document: BranchAppUsersViewCountsSubscription,
      variables: { BranchId: [profile?.Branch?.id] },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData) {
          return prev;
        }

        const newCounts = subscriptionData?.data?.branchAppUsersViewCountsUpdated;

        return Object.assign({}, prev, {
          getBranchAppUsersViewCounts: {
            submittedFormsCount: newCounts.submittedFormsCount,
            appUsersCount: newCounts.appUsersCount,
            petsCount: newCounts.petsCount,
            petsTypesCount: newCounts.petsTypesCount,
            activeAppUsersCount: newCounts.activeAppUsersCount,
            archivedAppUsersCount: newCounts.archivedAppUsersCount,
            activePetsCount: newCounts.activePetsCount,
            archivedPetsCount: newCounts.archivedPetsCount,
            petsTypesCountActive: newCounts.petsTypesCountActive,
            petsTypesCountArchived: newCounts.petsTypesCountArchived,
            shouldNotify: newCounts.shouldNotify,
            __typename: 'BranchAppUsersViewCount'
          }
        });
      }
    });
    return () => sub();
  }, [subscribeToMore, profile?.Branch?.id]);

  return {
    submittedFormsCount,
    appUsersCount,
    petsCount,
    petsTypesCount,
    activeAppUsersCount,
    activePetsCount,
    archivedAppUsersCount,
    archivedPetsCount,
    petsTypesCountActive,
    petsTypesCountArchived,
    shouldNotify
  };
};

export default useBranchAppUsersViewCounts;
