import { useLazyQuery, useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { Controller, FieldValues, UseFormMethods, useForm, useWatch } from 'react-hook-form';
import { GetAppUserProfileById, GetBranchChatRoom, GetPetRecordReports } from '../../queries';
import { toReadableDate } from '../../utils/dates';
import ModalDialog from '../Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../Modal/styled';
import { FormButtonsContainer, FormLabel, FormSelect, FormSubmitButton, InputsWrapper, WideInputGroup } from '../Shared/Forms/Forms';
import { Container } from '../Shared/Shared';
import { CenteredLoader } from '../Shared/Spinner';

const EmbedReportModalForm = ({
  formOptions,
  allPets,
  allReports,
  loading,
  onSubmit,
  selectedPetId,
  embededReportsIds
}: {
  formOptions: UseFormMethods<FieldValues>;
  allPets: Record<string, any>[];
  allReports: { label: string; items: Record<string, any>[] }[];
  loading: boolean;
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  selectedPetId: string;
  embededReportsIds: string[];
}) => {
  const { control } = formOptions;
  return (
    <>
      <ModalBody>
        <Container>
          {loading && <CenteredLoader size={15} />}
          <InputsWrapper noWrap>
            <WideInputGroup>
              <FormLabel>Select Pet</FormLabel>
              <Controller
                render={({ onChange }) => (
                  <FormSelect
                    height={48}
                    fontSize={16}
                    name={'selectedPetId'}
                    onChange={e => {
                      onChange(e.target.value);
                    }}
                    defaultValue={''}
                  >
                    <option disabled value={''}>
                      -- select a pet --
                    </option>
                    {allPets.map(({ id, name }, index) => (
                      <option key={index} value={id}>
                        {name}
                      </option>
                    ))}
                  </FormSelect>
                )}
                control={control}
                name={'selectedPetId'}
                rules={{ required: true, validate: value => value !== '' }}
                defaultValue={''}
              />
            </WideInputGroup>
          </InputsWrapper>
          <InputsWrapper noWrap>
            <WideInputGroup>
              <FormLabel>Select Report</FormLabel>
              <Controller
                render={({ onChange }) => (
                  <FormSelect
                    height={48}
                    fontSize={16}
                    name={'selectedReportId'}
                    onChange={e => {
                      onChange(e.target.value);
                    }}
                    defaultValue={''}
                  >
                    <option disabled value={''}>
                      -- select a report --
                    </option>
                    {allReports
                      ?.filter(({ items }) => items?.length)
                      ?.map(({ items, label }) => (
                        <optgroup label={label} key={label}>
                          {items
                            .filter(({ id }) => !embededReportsIds.includes(id))
                            .map(({ id, name, updatedAt }, index) => (
                              <option disabled={!selectedPetId} key={index} value={id}>
                                {name} - {toReadableDate(updatedAt, { noTime: true, isLocale: true })}
                              </option>
                            ))}
                        </optgroup>
                      ))}
                  </FormSelect>
                )}
                control={control}
                name={'selectedReportId'}
                rules={{ required: true, validate: value => value !== '' }}
                defaultValue={''}
              />
            </WideInputGroup>
          </InputsWrapper>
        </Container>
      </ModalBody>
      <ModalFooter>
        <FormButtonsContainer>
          <FormSubmitButton loading={false} onClick={onSubmit}>
            Embed
          </FormSubmitButton>
        </FormButtonsContainer>
      </ModalFooter>
    </>
  );
};

const EmbedReportModal = ({
  chatRoomId,
  embedReport,
  embededReportsIds
}: {
  chatRoomId: string;
  embedReport: ({ selectedReport, selectedPet }: { selectedReport: Record<string, any>; selectedPet: Record<string, any> }) => void;
  embededReportsIds: string[];
}) => {
  const { data: { getBranchChatRooms: [room] = [] } = {}, loading: loadingChatRoom } = useQuery(GetBranchChatRoom, {
    variables: { chatRoom_id: [chatRoomId], requisite_queries: ['chatRoom_id'] },
    skip: !chatRoomId,
    fetchPolicy: 'cache-only'
  });

  const appUserId = room?.AppUsers[0]?.id;

  const { data: { getAppUserById: appUserProfile = {} } = {}, loading: loadingAppUserProfile } = useQuery(GetAppUserProfileById, {
    variables: { id: appUserId },
    skip: !appUserId
  });

  const allPets = [...(appUserProfile?.Pets || []), ...(appUserProfile?.ChappedPets || [])];

  const formOptions = useForm();

  const { control, handleSubmit, setValue } = formOptions;

  const selectedPetId = useWatch({
    control,
    name: 'selectedPetId',
    defaultValue: ''
  });

  const [
    getPetRecordReports,
    {
      data: { getPetRecordReports: { MedConds = [], GroomingReports = [], TrainingReports = [], WalkingReports = [], SittingReports = [], DaycareReports = [] } = {} } = {},
      loading: loadingPetRecordReports
    }
  ] = useLazyQuery(GetPetRecordReports);

  useEffect(() => {
    if (selectedPetId) {
      setValue('selectedReportId', '');
    }
  }, [selectedPetId]);

  useEffect(() => {
    if (!selectedPetId) {
      return;
    }
    const petProfile = allPets.find(pet => pet.id === selectedPetId);
    const petRecordId = petProfile?.PetRecord?.id;
    getPetRecordReports({ variables: { petRecordId } });
  }, [selectedPetId]);

  const allReports = [
    {
      label: 'Medical Conditions',
      items: MedConds
    },
    {
      label: 'Grooming Reports',
      items: GroomingReports
    },
    {
      label: 'Training Reports',
      items: TrainingReports
    },
    {
      label: 'Walking Reports',
      items: WalkingReports
    },
    {
      label: 'Sitting Reports',
      items: SittingReports
    },
    {
      label: 'Daycare Reports',
      items: DaycareReports
    }
  ];

  const combinedReports = [...MedConds, ...GroomingReports, ...TrainingReports, ...WalkingReports, ...SittingReports, ...DaycareReports];

  const onSubmit = handleSubmit(({ selectedPetId, selectedReportId }) => {
    embedReport({ selectedReport: combinedReports.find(report => report.id === selectedReportId), selectedPet: allPets.find(pet => pet.id === selectedPetId) });
    ModalDialog.closeModal();
  });

  const loading = loadingChatRoom || loadingAppUserProfile || loadingPetRecordReports;

  return (
    <EmbedReportModalForm
      embededReportsIds={embededReportsIds}
      formOptions={formOptions}
      allReports={allReports}
      allPets={allPets}
      loading={loading}
      onSubmit={onSubmit}
      selectedPetId={selectedPetId}
    />
  );
};

export default EmbedReportModal;
