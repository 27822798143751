import React from 'react';
import { PetRecordOptionsContainer, PetRecordTagPill, PetRecordTagPillsContainer } from '../../../components/Pets/styled';
import useIcons from '../../../hooks/useIcons';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { BranchAppUser } from '../../Store/BranchBilling/types';
import { BranchAppUserTag } from '../../Store/BranchTags/types';
import {
  AppUserImage,
  AppUserImageAnimation,
  AppUserImageContainer,
  AppUserImageContainerAnimation,
  AppUserImageNameContainer,
  AppUserName,
  BlankAppUserImage,
  RecordBody,
  RecordDataContainer
} from '../../styled';
import AppUserRecordOptions from './AppUserRecordOptions';
import { setDrawerBar } from '../../../reactive/actions';
import { DRAWER_IDS } from '../../../components/DrawerBar/types';
import { UseBulkSelectOutput } from '../../../hooks/useBulkSelect';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Checkbox, CheckboxItemContainer } from '../../../components/Shared/Forms/styled';
import { Icon } from '../../../components/Shared/Forms/Forms';
import { Booking, PetRecordTag, PetRecordTags, PetRecordTagsContainer } from '../../Bookings/components/BookingsBody/styled';
import { VerticalDivider } from '../../../components/Shared/ToolBar/styles';
import { PetAndInfo, PetContainer, PetImg } from '../../Bookings/components/BookingRecord/styled';

const BranchAppUserRecord = ({
  branchAppUser,
  appUserQuickTags = [],
  tagsVisibleOnItem = [],
  bulkSelect
}: {
  branchAppUser: BranchAppUser;
  appUserQuickTags?: BranchAppUserTag[];
  tagsVisibleOnItem?: BranchAppUserTag[];
  bulkSelect: UseBulkSelectOutput;
}) => {
  const { mobile } = useMediaQuery({ mobile: true });
  const icons = useIcons();
  const optionsRef = React.useRef<HTMLDivElement>(null);

  const { show: showBulkSelect, toggleSelectMultiple, isSelected } = bulkSelect || {};

  const handleClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    if (optionsRef?.current?.contains(e.target as Node)) {
      return;
    }

    if (showBulkSelect) {
      toggleSelectMultiple([branchAppUser?.id]);
      return;
    }

    setDrawerBar({
      drawerId: DRAWER_IDS.USER_DRAWER,
      isExtended: !mobile,
      recordData: branchAppUser?.id
    });
  };

  const { id, profile_pic, name } = branchAppUser || {};

  const recordOptions = (
    <PetRecordOptionsContainer>
      <AppUserRecordOptions ref={optionsRef} showAlways={mobile} loadTags={false} appUsers={[branchAppUser]} appUserQuickTags={appUserQuickTags} />
    </PetRecordOptionsContainer>
  );

  const tags = branchAppUser?.BranchAppUserTags || [];
  const tagsToRender = tagsVisibleOnItem ? tags.filter(tag => tagsVisibleOnItem.some(visibleTag => visibleTag.id === tag.id)) : tags;
  const countryCode = branchAppUser?.phone_numbers?.[0]?.country_code;
  const number = branchAppUser?.phone_numbers?.[0]?.number;
  const phoneNumber = countryCode !== undefined && number !== undefined ? `${countryCode} ${number}` : '';

  const renderTags = () => {
    return (
      <PetRecordTagsContainer>
        <PetRecordTags>
          {tagsToRender?.map(({ name, id, color }) => (
            <PetRecordTag color={color} key={id}>
              {name}
            </PetRecordTag>
          ))}
        </PetRecordTags>
      </PetRecordTagsContainer>
    );
  };

  return (
    <Booking onClick={handleClick} mobilePadding="16px 8px" gap={20}>
      {!mobile && (
        <RecordBody width="50" flex="unset">
          <AppUserImageContainer noPadding={!showBulkSelect}>
            <AppUserImageContainerAnimation rotateOption={!!showBulkSelect} noPadding>
              <AppUserImageAnimation key={branchAppUser?.id}>
                {branchAppUser?.profile_pic && <AppUserImage src={branchAppUser?.profile_pic} index={0} />}
                {!branchAppUser?.profile_pic && <BlankAppUserImage alt="user" image={icons.user.childImageSharp.gatsbyImageData} />}
              </AppUserImageAnimation>
              <AppUserImageAnimation absolute>
                <CheckboxItemContainer
                  checked={isSelected(branchAppUser?.id)}
                  noMargin
                  style={{
                    justifyContent: 'center'
                  }}
                >
                  <Checkbox>
                    <Icon viewBox="0 0 24 24">
                      <polyline points="20 6 9 17 4 12" />
                    </Icon>
                  </Checkbox>
                </CheckboxItemContainer>
              </AppUserImageAnimation>
            </AppUserImageContainerAnimation>
          </AppUserImageContainer>
        </RecordBody>
      )}
      {!mobile && (
        <>
          <RecordBody width="150" flex="unset" fontWeight="700">
            {branchAppUser?.name}
          </RecordBody>
          <RecordBody width="200" flex="unset" fontWeight="700">
            {branchAppUser?.email}
          </RecordBody>
          <RecordBody width="200" flex="unset" fontWeight="700">
            {phoneNumber}
          </RecordBody>
          <VerticalDivider displayNone />
          {renderTags()}
        </>
      )}
      {mobile && (
        <PetContainer>
          <PetImg src={profile_pic || icons.user.childImageSharp.gatsbyImageData.images.fallback.src} alt={branchAppUser?.name} />
          <PetAndInfo mobileFlexDirection="column">
            <RecordBody mobileFlexDirection="column" mobileWidth="100%">
              <RecordBody fontWeight="800" color="#212121" mobileWidth="100%">
                {branchAppUser?.name}
              </RecordBody>
            </RecordBody>
            <RecordBody mobileWidth="100%">{branchAppUser?.email}</RecordBody>
          </PetAndInfo>
          {recordOptions}
        </PetContainer>
      )}
      {!mobile && recordOptions}
    </Booking>
  );
};

export default BranchAppUserRecord;
