import React from 'react';
import InifiniteList, { InfiniteListGroup } from '../../components/Shared/InfiniteList/InfiniteList';
import { UsePagintaedQueryResult } from '../../hooks/usePaginatedQuery';
import { OrderSubscriptionRepeat } from './types';
import SubscriptionRepeatRecord from './SubscriptionRepeatRecord';

const SubscriptionRepeatsList = ({
  paginatedQueryResult,
  group,
  reverseItems
}: {
  paginatedQueryResult: UsePagintaedQueryResult<OrderSubscriptionRepeat[]>;
  group?: (list: OrderSubscriptionRepeat[]) => InfiniteListGroup;
  reverseItems?: boolean;
}) => {
  const [[hasMoreItems, setHasMoreItems], queryResult] = paginatedQueryResult;

  const { data: { getBranchOrderSubscriptionRepeats: subscriptionRepeats = [] } = {}, loading, fetchMore } = queryResult;

  return (
    <InifiniteList
      list={subscriptionRepeats}
      itemRenderer={(repeat: OrderSubscriptionRepeat) => <SubscriptionRepeatRecord key={repeat?.id} subscriptionRepeat={repeat} />}
      hasMoreItems={hasMoreItems}
      loading={loading}
      fetchMore={fetchMore as any}
      offset={subscriptionRepeats?.length}
      setHasMoreItems={setHasMoreItems}
      group={group?.(subscriptionRepeats)}
      reversedItems={reverseItems}
    />
  );
};

export default SubscriptionRepeatsList;
