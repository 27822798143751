import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { BranchProductOrdersViewCountsSubscription, GetBranchProductOrdersViewCounts, GetBusUserProfile } from '../queries';

export type GetBranchProductOrdersViewCountsReturn = {
  newProductOrdersCount: number;
  shouldNotify: boolean;
};

const useBranchProductOrdersViewCounts = () => {
  const { data: { getBusUserProfile: profile = {} } = {} } = useQuery(GetBusUserProfile, {
    fetchPolicy: 'cache-only'
  });

  const { data: unreadOrdersCount, subscribeToMore } = useQuery<{ getBranchProductOrdersViewCounts: GetBranchProductOrdersViewCountsReturn }>(GetBranchProductOrdersViewCounts, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true
  });

  const { newProductOrdersCount, shouldNotify } = unreadOrdersCount?.getBranchProductOrdersViewCounts || {};

  useEffect(() => {
    const sub = subscribeToMore<{ branchProductOrdersViewCountsUpdated: GetBranchProductOrdersViewCountsReturn }>({
      document: BranchProductOrdersViewCountsSubscription,
      variables: { BranchId: [profile?.Branch?.id] },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData) {
          return prev;
        }

        const newCounts = subscriptionData?.data?.branchProductOrdersViewCountsUpdated;

        return Object.assign({}, prev, {
          getBranchProductOrdersViewCounts: {
            newProductOrdersCount: newCounts.newProductOrdersCount,
            shouldNotify: newCounts.shouldNotify,
            __typename: 'BranchProductOrdersViewCount'
          }
        });
      }
    });
    return () => sub();
  }, [subscribeToMore, profile?.Branch?.id]);

  return {
    newProductOrdersCount,
    shouldNotify
  };
};

export default useBranchProductOrdersViewCounts;
