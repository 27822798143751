import { useLazyQuery, useMutation } from '@apollo/client';
import React, { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ModalDialog, { ModalLayout } from '../../../../components/Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../../../../components/Modal/styled';
import {
  FormButtonsContainer,
  FormCheckbox,
  FormError,
  FormInput,
  FormLabel,
  FormSubmitButton,
  InputContainer,
  InputGroup,
  InputsWrapper,
  WideInputGroup
} from '../../../../components/Shared/Forms/Forms';
import AppUsersList from '../../../../components/Shared/Lists/AppUsersList';
import { Container } from '../../../../components/Shared/Shared';
import { GetBranchPets, GetUpdatePetRecordsAppointmentsTimestampSummary, UpdatePetRecordsAppointmentsTimestamp } from '../../../../queries';
import { getEndOfDate, SUN_THREE_DAYS, THREE_LETTER_WEEK_DAYS } from '../../../../utils/dates';
import debounce from '../../../../utils/debounce';
import { Divider } from '../../../Pets/Health/styled';
import Select from '../../../../components/Shared/Forms/Select';
import { Pet } from '../../../../components/Pets/types';
import ProductsList from '../../../../components/Shared/Lists/ProductsList';

type AdjustPetRecordsAppointmentsTimestampsModalFormValues = {
  selectedAppUsers: string[];
  selectedPets: string[];
  selectedProducts: string[];
  timestamp_from: string;
  timestamp_to: string;
  original_day_of_week: ('Sun' | 'Mon' | 'Tue' | 'Wed' | 'Thu' | 'Fri' | 'Sat')[];
  day_of_week: 'Sun' | 'Mon' | 'Tue' | 'Wed' | 'Thu' | 'Fri' | 'Sat';
  notifications_disabled: boolean;
};

type AdjustPetRecordsAppointmentsTimestampsModalModalProps = {
  initialValues?: Partial<AdjustPetRecordsAppointmentsTimestampsModalFormValues>;
};

const validateForm = (values: Partial<AdjustPetRecordsAppointmentsTimestampsModalFormValues>) => {
  const { selectedAppUsers, selectedProducts, timestamp_from, timestamp_to, day_of_week, original_day_of_week, selectedPets } = values;
  return !!selectedAppUsers?.length && !!selectedProducts?.length && !!timestamp_from && !!timestamp_to && !!day_of_week && !!original_day_of_week?.length && !!selectedPets?.length;
};

const AdjustPetRecordsAppointmentsTimestampsModal: FC<AdjustPetRecordsAppointmentsTimestampsModalModalProps> = ({ initialValues }) => {
  const defaultValues: Partial<AdjustPetRecordsAppointmentsTimestampsModalFormValues> = {
    selectedAppUsers: initialValues?.selectedAppUsers || [],
    selectedProducts: initialValues?.selectedProducts || [],
    timestamp_from: initialValues?.timestamp_from || '',
    timestamp_to: initialValues?.timestamp_to || '',
    day_of_week: initialValues?.day_of_week || 'Mon',
    notifications_disabled: initialValues?.notifications_disabled || false,
    original_day_of_week: initialValues?.original_day_of_week || [],
    selectedPets: initialValues?.selectedPets || []
  };

  const formOptions = useForm<AdjustPetRecordsAppointmentsTimestampsModalFormValues>({ defaultValues });

  const [updatePetRecordsAppointmentsTimestamp, { data: dataSubmit, loading: loadingSubmit, error: errorSubmit }] = useMutation(UpdatePetRecordsAppointmentsTimestamp);

  const [getUpdatePetRecordsAppointmentsTimestampSummary, { data }] = useLazyQuery<{
    getUpdatePetRecordsAppointmentsTimestampSummary?: { ordersCount: number; appUsersCount: number };
  }>(GetUpdatePetRecordsAppointmentsTimestampSummary, {
    fetchPolicy: 'network-only'
  });

  const [getBranchPets, { data: { getBranchPets: allPets = [] } = {}, loading: loadingAppUserProfile }] = useLazyQuery<{ getBranchPets: Pet[] }>(GetBranchPets);

  const summary = data?.getUpdatePetRecordsAppointmentsTimestampSummary;

  const { control, handleSubmit, errors, watch } = formOptions;

  const watchedValues = watch();

  const getVariables = (form: Partial<AdjustPetRecordsAppointmentsTimestampsModalFormValues>) => {
    const { selectedAppUsers, selectedProducts, timestamp_from, timestamp_to, day_of_week, notifications_disabled, original_day_of_week, selectedPets } = form;

    const pets = allPets.filter(pet => selectedPets?.includes(pet.id));

    const variables = {
      AppUserId: selectedAppUsers,
      ProductId: selectedProducts,
      timestamp_from: timestamp_from ? new Date(timestamp_from).toISOString() : null,
      timestamp_to: timestamp_to ? getEndOfDate(timestamp_to).toISOString() : null,
      day_of_week: SUN_THREE_DAYS.indexOf(day_of_week!),
      notifications_disabled,
      PetRecordId: pets.map(pet => pet.PetRecord.id),
      original_day_of_week: original_day_of_week?.map(day => SUN_THREE_DAYS.indexOf(day))
    };

    return variables;
  };

  useEffect(() => {
    if (watchedValues?.selectedAppUsers?.length) {
      debounce(
        () => {
          getBranchPets({ variables: { appUser_id: watchedValues?.selectedAppUsers, requisite_queries: ['appUser_id'], alternative_queries: [] } });
        },
        500,
        'SPECIAL'
      );
    }
  }, [JSON.stringify(watchedValues?.selectedAppUsers || [])]);

  const onSubmit = handleSubmit(form => {
    if (!validateForm(form)) {
      return;
    }

    const variables = getVariables(form);

    updatePetRecordsAppointmentsTimestamp({ variables });
  });

  useEffect(() => {
    if (dataSubmit?.updatePetRecordsAppointmentsTimestamp) {
      ModalDialog.closeModal();
    }
  }, [dataSubmit?.updatePetRecordsAppointmentsTimestamp]);

  useEffect(() => {
    debounce(() => {
      if (!validateForm(watchedValues)) {
        return;
      }
      const variables = getVariables(watchedValues);
      getUpdatePetRecordsAppointmentsTimestampSummary({ variables });
    }, 500);
  }, [JSON.stringify(watchedValues)]);

  const petsByAppUser = allPets.reduce<Record<string, Pet[]>>((acc, pet) => {
    if (!acc[pet?.AppUser?.id]) {
      acc[pet?.AppUser?.id] = [];
    }

    acc[pet?.AppUser?.id].push(pet);

    return acc;
  }, {});

  const timestampFromMin = undefined;
  const timestampToMin = watchedValues?.timestamp_from ?? undefined;

  const daysOptions = THREE_LETTER_WEEK_DAYS.map(day => ({ id: day, name: day }));

  return (
    <ModalLayout
      compact
      buttons={
        <FormButtonsContainer>
          <FormSubmitButton error={!!errorSubmit} loading={loadingSubmit} onClick={() => onSubmit()}>
            Confirm Adjustment
          </FormSubmitButton>
        </FormButtonsContainer>
      }
    >
      <>
        <InputGroup>
          <FormLabel>Clients</FormLabel>
          <AppUsersList isMulti formOptions={formOptions} name="selectedAppUsers" defaultValues={defaultValues?.selectedAppUsers} />
          <WideInputGroup marginTop={20}>
            <FormLabel error={(Object.values(errors?.selectedPets || {}) || [])?.find(e => e?.message)?.message}>Pets {!allPets?.length && !loadingAppUserProfile && '(Select a client)'}</FormLabel>
            <InputContainer marginBottom={20}>
              <Controller
                control={control}
                name={`selectedPets`}
                defaultValue={defaultValues?.selectedPets}
                rules={{
                  required: true,
                  validate: value => {
                    if (value.length === 0) {
                      return 'Please select at least one pet';
                    }
                  }
                }}
                render={({ onChange, value }) => (
                  <>
                    {watchedValues.selectedAppUsers?.map(appUserId => (
                      <InputGroup key={appUserId}>
                        <FormLabel>{petsByAppUser[appUserId]?.[0]?.AppUser?.name}</FormLabel>
                        <FormCheckbox
                          error={(Object.values(errors?.selectedPets || {}) || [])?.find(e => e?.message)?.message}
                          onChange={onChange}
                          value={value}
                          itemsArray={petsByAppUser[appUserId]?.map(pet => ({ id: pet?.id, name: pet?.name }))}
                        />
                      </InputGroup>
                    ))}
                  </>
                )}
              />
            </InputContainer>
            {errors?.selectedPets && <FormError>{(Object.values(errors?.selectedPets || {}) || []).find(e => e?.message)?.message || 'Please select at least one pet'}</FormError>}
          </WideInputGroup>
        </InputGroup>
        <Divider />
        <FormLabel>Services</FormLabel>
        <ProductsList isMulti type={['service']} formOptions={formOptions} name="selectedProducts" defaultValues={defaultValues?.selectedProducts} />
        <Divider />
        <WideInputGroup marginTop={20} flexDirection="row" gap={10}>
          <InputGroup>
            <FormLabel>From</FormLabel>
            <Controller as={<FormInput type={'date'} min={timestampFromMin} />} control={control} name={'timestamp_from'} defaultValue={''} rules={{ required: true, min: timestampFromMin }} />
            {errors.timestamp_from && <FormError>{errors.timestamp_from.message || 'From is required'}</FormError>}
          </InputGroup>
          <InputGroup>
            <FormLabel>To</FormLabel>
            <Controller
              as={<FormInput type={'date'} min={timestampToMin} disabled={!watchedValues?.timestamp_from} />}
              control={control}
              name={'timestamp_to'}
              defaultValue={''}
              rules={{ required: true, min: timestampToMin, validate: value => watchedValues?.timestamp_from && value >= watchedValues?.timestamp_from }}
            />
            {errors.timestamp_to && <FormError>{errors.timestamp_to.message || 'To is required'}</FormError>}
          </InputGroup>
        </WideInputGroup>{' '}
        <WideInputGroup marginTop={12}>
          <FormLabel error={(errors?.original_day_of_week || [])?.find(e => !!e?.message)?.message}>Original Day of Week</FormLabel>
          <Controller
            control={control}
            name={'original_day_of_week'}
            render={({ onChange, value }) => (
              <FormCheckbox fontSize={16} error={(errors?.original_day_of_week || [])?.find(e => !!e?.message)?.message} onChange={onChange} value={value || ''} itemsArray={daysOptions} />
            )}
            rules={{ required: true, validate: (value: any) => value.length > 0 || 'Please select at least one day' }}
          />
        </WideInputGroup>
        <WideInputGroup marginTop={12}>
          <FormLabel>Day of Week</FormLabel>
          <Controller
            control={control}
            name={'day_of_week'}
            render={({ onChange, value }) => (
              <Select
                options={daysOptions.map(item => ({
                  label: item.name,
                  value: item.id
                }))}
                onChange={selectedOption => {
                  onChange(selectedOption?.value);
                }}
                value={value ? { value, label: value } : undefined}
              />
            )}
            rules={{ required: true }}
          />
        </WideInputGroup>
        <WideInputGroup>
          <Controller
            control={control}
            name="notifications_disabled"
            render={({ onChange, value }) => (
              <FormCheckbox
                onChange={(newValue: string[]) => {
                  const isSelected = newValue.includes('notifications_disabled');
                  onChange(isSelected);
                }}
                value={value ? ['notifications_disabled'] : []}
                itemsArray={[{ id: 'notifications_disabled', name: 'Disable notifications' }]}
              />
            )}
            defaultValue={false}
          />
        </WideInputGroup>
        {summary && (
          <WideInputGroup>
            <FormLabel>Orders to update: {summary?.ordersCount}</FormLabel>
            <FormLabel>Clients to update: {summary?.appUsersCount}</FormLabel>
          </WideInputGroup>
        )}
      </>
    </ModalLayout>
  );
};

export default AdjustPetRecordsAppointmentsTimestampsModal;
