import { useLazyQuery } from '@apollo/client';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useEffect, useState } from 'react';
import useIcons from '../../../hooks/useIcons';
import debounce from '../../../utils/debounce';
import { CloseButtonContainer } from '../../ChatDrawer/styled';
import { Pet } from '../../Pets/types';
import { CloseButton, SearchBarContainer, SearchBarWrapper } from '../Filters/styled';
import { FormInput } from '../Forms/Forms';
import { GetBranchPetsReduced } from '../../../queries';

const PetsSearchList = ({
  defaultText,
  limit = 100,
  renderItem,
  containerStyle,
  minLength
}: {
  defaultText?: string;
  renderItem: (pet: Pet, index: number) => React.ReactNode;
  limit?: number;
  containerStyle?: React.CSSProperties;
  minLength: number;
}) => {
  const icons = useIcons();
  const closeIcon = icons?.closeIcon?.childImageSharp?.gatsbyImageData;
  const [inputValue, setInputValue] = useState('');
  const [getSearchBranchPets, { data: { getBranchPets: searchedPets = [] } = {} }] = useLazyQuery<{ getBranchPets: Pet[] }>(GetBranchPetsReduced, {
    fetchPolicy: 'cache-and-network'
  });

  const onSearch = (value: string) => {
    if (!value || value.length < minLength) {
      return;
    }

    getSearchBranchPets({
      variables: {
        alternative_queries: ['pet_name', 'branchPetRecordTag_name'],
        requisite_queries: [],
        pet_name: [value],
        branchPetRecordTag_name: [value],
        limit,
        offset: 0,
        daycare_filter: false,
        vaccination_filter: false,
        treatment_filter: false,
        weight_filter: false,
        neutering_filter: false,
        age_filter: false
      }
    });
  };

  useEffect(() => {
    debounce(() => onSearch(inputValue), 500);
  }, [inputValue]);

  const clearSearch = () => {
    setInputValue('');
  };

  const inputRef = React.useRef(null);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSearch(inputValue);
    }
  };

  return (
    <SearchBarContainer column style={containerStyle}>
      <SearchBarWrapper show fullWidth>
        <FormInput
          paddingTopBottom={16}
          borderBox
          fullWidth
          onKeyDown={handleKeyDown}
          placeholder={defaultText || 'Search'}
          ref={inputRef}
          height={40}
          borderRadius={20}
          backgroundColor={'#f0f0f0'}
          fontSize={14}
          defaultBorder
          onChange={e => setInputValue(e.currentTarget.value)}
          value={inputValue}
        />
        {inputValue && (
          <CloseButtonContainer style={{ position: 'absolute', right: -2, top: 2 }}>
            <CloseButton onClick={clearSearch}>
              <GatsbyImage image={closeIcon} alt="close" />
            </CloseButton>
          </CloseButtonContainer>
        )}
      </SearchBarWrapper>
      {searchedPets?.map((pet, index) => (
        <React.Fragment key={pet.id}>{renderItem(pet, index)}</React.Fragment>
      ))}
    </SearchBarContainer>
  );
};

export default PetsSearchList;
