import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import useIcons from '../../hooks/useIcons';
import { ModalContainer, ModalHeader, BackgroundOpacity, ModalDivider, CloseModal } from './styled';

type ModalProps = {
  title: string;
  modalContent?: (props: Record<string, any>) => JSX.Element;
  ModalBtn?: (props: any) => JSX.Element;
  onClose?: () => void;
  onOpen?: () => void;
  autoOpenModal?: boolean;
  noButton?: boolean;
};

export type ModalRef = {
  openModal: (props?: Record<string, any>) => void;
  closeModal: () => void;
};

const Modal = forwardRef<ModalRef, ModalProps>(({ ModalBtn, title, modalContent, onClose, onOpen, autoOpenModal = false, noButton = false }, ref) => {
  const icons = useIcons();
  const [modalVisible, setModalVisible] = useState(autoOpenModal);
  const [animationShowStatus, setAnimationShowStatus] = useState(false);
  const [props, setProps] = useState<Record<string, any>>({});
  const closeModal = () => {
    setModalVisible(false);
    if (onClose) {
      onClose();
    }
  };
  useImperativeHandle(ref, () => ({
    closeModal,
    openModal: (props: Record<string, unknown> = {}) => {
      setModalVisible(true);
      setProps(props);
    }
  }));
  useEffect(() => {
    if (modalVisible) {
      setAnimationShowStatus(true);
    }
  }, [modalVisible]);

  return (
    <>
      {!noButton && ModalBtn && (
        <ModalBtn
          onClick={() => {
            onOpen?.();
            setModalVisible(true);
          }}
        />
      )}
      {!noButton && !ModalBtn && <button onClick={() => setModalVisible(true)}>Show modal</button>}
      <BackgroundOpacity showModal={modalVisible} onAnimationEnd={() => !modalVisible && setAnimationShowStatus(false)}>
        <ModalContainer>
          <ModalHeader>
            {props?.title || title}
            <CloseModal bgImage={icons.closeModal.childImageSharp.gatsbyImageData.images.fallback.src} onClick={closeModal} />
          </ModalHeader>
          <ModalDivider />
          {animationShowStatus && modalContent && modalContent(props)}
        </ModalContainer>
      </BackgroundOpacity>
    </>
  );
});

export default Modal;
