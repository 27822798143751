import { OPTION_DROPDOWN_TYPES } from '../../../components/Shared/Menus/OptionDropdown/types';
import {
  AddBranchAppUserTag,
  AddBranchAppointmentTag,
  AddBranchPetRecordTag,
  DeleteBranchAppUserTag,
  DeleteBranchAppointmentTag,
  DeleteBranchPetRecordTag,
  EditBranchAppUserTag,
  EditBranchAppointmentTag,
  EditBranchPetRecordTag,
  GetBranchAppUserTags,
  GetBranchAppointmentTags,
  GetBranchPetRecordTags
} from '../../../queries';

type BranchTagCommon = {
  id: string;
  name: string;
  Branch: { id: string; name: string };
  quick_action: boolean;
  automations: { type: TAGS_AUTOMATIONS_TYPES; id: string | string[] }[] | null;
  BranchTagCategories: {
    id: string;
    name: string;
  }[];
  color: string;
  capacity: number;
  reset_at: {
    hour: number;
    minute: number;
  } | null;
  visibility?: {
    showOnBar: boolean;
    showOnTaggedItem: boolean;
    showOnAppointments: boolean;
  } | null;
};

export type BranchPetRecordTag = BranchTagCommon & {
  petRecordsCount: number;
};

export type BranchAppointmentTag = BranchTagCommon & {
  appointmentsCount: number;
  appointmentsCountToday: number;
};

export type BranchAppUserTag = BranchTagCommon & {
  appUsersCount: number;
};

export enum TAGS_AUTOMATIONS_TYPES {
  SEND_MESSAGE = 'SEND_MESSAGE',
  REMOVE_TAGS = 'REMOVE_TAGS',
  ADD_BRANCH_NOTE = 'ADD_BRANCH_NOTE'
}

export const TAGS_AUTOMATIONS_TYPES_LABELS = {
  [TAGS_AUTOMATIONS_TYPES.SEND_MESSAGE]: 'Send message to owner',
  [TAGS_AUTOMATIONS_TYPES.REMOVE_TAGS]: 'Remove Tag',
  [TAGS_AUTOMATIONS_TYPES.ADD_BRANCH_NOTE]: 'Add private note'
};

export const TAGS_AUTOMATIONS_TYPES_TITLES = {
  [TAGS_AUTOMATIONS_TYPES.SEND_MESSAGE]: 'Message to send',
  [TAGS_AUTOMATIONS_TYPES.REMOVE_TAGS]: 'Tags to remove',
  [TAGS_AUTOMATIONS_TYPES.ADD_BRANCH_NOTE]: 'Note to add'
};

export const AUTOMATIONS_OPTION_DROPDOWN_TYPES = {
  [TAGS_AUTOMATIONS_TYPES.SEND_MESSAGE]: OPTION_DROPDOWN_TYPES.SELECT,
  [TAGS_AUTOMATIONS_TYPES.REMOVE_TAGS]: OPTION_DROPDOWN_TYPES.MULTI_SELECT,
  [TAGS_AUTOMATIONS_TYPES.ADD_BRANCH_NOTE]: OPTION_DROPDOWN_TYPES.TEXT_INPUT
};

export enum BRANCH_TAGS_TYPES {
  PET_RECORD = 'PET_RECORD',
  APPOINTMENT = 'APPOINTMENT',
  APP_USER = 'APP_USER'
}

export const BRANCH_TAGS_TYPES_LABELS = {
  [BRANCH_TAGS_TYPES.PET_RECORD]: 'Pet',
  [BRANCH_TAGS_TYPES.APPOINTMENT]: 'Booking',
  [BRANCH_TAGS_TYPES.APP_USER]: 'App User'
};

export const BRANCH_TAGS_QUERIES = {
  [BRANCH_TAGS_TYPES.PET_RECORD]: {
    GetBranchTags: GetBranchPetRecordTags,
    AddBranchTag: AddBranchPetRecordTag,
    EditBranchTag: EditBranchPetRecordTag,
    DeleteBranchTag: DeleteBranchPetRecordTag
  },
  [BRANCH_TAGS_TYPES.APPOINTMENT]: {
    GetBranchTags: GetBranchAppointmentTags,
    AddBranchTag: AddBranchAppointmentTag,
    EditBranchTag: EditBranchAppointmentTag,
    DeleteBranchTag: DeleteBranchAppointmentTag
  },
  [BRANCH_TAGS_TYPES.APP_USER]: {
    GetBranchTags: GetBranchAppUserTags,
    AddBranchTag: AddBranchAppUserTag,
    EditBranchTag: EditBranchAppUserTag,
    DeleteBranchTag: DeleteBranchAppUserTag
  }
};

export enum BRANCH_TAGS_TABS_TYPES {
  ALL = 'ALL',
  PETS = 'Pet',
  BOOKINGS = 'Booking',
  APP_USERS = 'User'
}

export type BranchTag = BranchPetRecordTag | BranchAppointmentTag | BranchAppUserTag;
