import { Pet } from '../../../components/Pets/types';
import { Branch } from '../../../components/Profile/types';
import { BranchAppUser } from '../BranchBilling/types';
import { Product } from '../types';

export type FORM_QUESTION = {
  id: string;
  title: string;
  get_answers_from: 'BREED' | 'CHRONIC_CONDITIONS' | 'VET' | 'INSURER' | null;
  sort_index: number;
  type: 'TEXT' | 'RADIO' | 'CHECKBOX' | 'DATE' | 'DROPDOWN' | 'NUMBER' | 'TEXTAREA' | 'BOOLEAN' | 'ADDRESS' | 'PHONE' | 'EMAIL' | 'POSTCODE';
  QuestionCategories: {
    id: string;
    name: string;
    Questions: FORM_QUESTION[];
  }[];
  ask_per_pet: boolean;
};

export type SubmittedForm = {
  id: string;
  createdAt: string;
  Answers: {
    id: string;
    PetRecordId: string;
    PetRecord: Pet['PetRecord'];
    body: string;
    Question: FORM_QUESTION;
    AnswerOptions: {
      id: string;
      title: string;
    }[];
  }[];
  AppUser: BranchAppUser;
  Order: {
    id: string;
    number: string;
  };
  Form: BranchForm;
};

export type BranchForm = {
  name: string;
  Products: Product[] | null;
  Orders: {
    id: string;
    Answers: {
      id: string;
      body: string;
      PetRecordId: string;
      AnswerOptions: {
        id: string;
        title: string;
      }[];
    }[];
  }[];
  Branch: Branch;
  id: string;
  type: BRANCH_FORM_TYPES;
  level: BRANCH_FORM_LEVELS;
  Questions: FORM_QUESTION[];
  updatedAt: string;
  createdAt: string;
};

export enum BRANCH_FORM_TYPES {
  DETACHED = 'DETACHED',
  ATTACHED = 'ATTACHED'
}

export enum BRANCH_FORM_LEVELS {
  BRANCH = 'BRANCH',
  PRODUCT = 'PRODUCT',
  ORDER = 'ORDER'
}

export const BRANCH_FORM_TYPES_LABELS = {
  [BRANCH_FORM_TYPES.ATTACHED]: 'Attached',
  [BRANCH_FORM_TYPES.DETACHED]: 'Detached'
};

export const BRANCH_FORM_LEVELS_LABELS = {
  [BRANCH_FORM_LEVELS.BRANCH]: 'First Time any service/product is selected',
  [BRANCH_FORM_LEVELS.PRODUCT]: 'First Time any of these services is selected',
  [BRANCH_FORM_LEVELS.ORDER]: 'Every time this service is selected'
};
