import React, { Fragment, useRef } from 'react';
import Colors from '../../Colors';
import useIcons from '../../hooks/useIcons';
import useMediaQuery from '../../hooks/useMediaQuery';
import { findMonthName, toReadableDate } from '../../utils/dates';
import useDynamicLinks from '../../utils/dynamicLinks/useDynamicLinks';

import { Booking, PetRecordTag, PetRecordTags, PetRecordTagsContainer } from '../../views/Bookings/components/BookingsBody/styled';
import { BranchAppUser } from '../../views/Store/BranchBilling/types';
import { BranchAppUserTag } from '../../views/Store/BranchTags/types';
import {
  AppUserImage,
  AppUserImageAnimation,
  AppUserImageContainer,
  AppUserImageContainerAnimation,
  AppUserImageNameContainer,
  RecordBody,
  RecordDataContainer,
  TagsTimeOptionalDropdownContainer
} from '../../views/styled';
import { PetRecordOptionsContainer } from '../Pets/styled';
import { RedDot } from '../Shared/Shared';
import { Divider } from '../Shared/SideTabs/styles';
import ChatRecordOptions, { ChatRecordOptionsRef } from './ChatRecordOptions';
import { PetAndInfo, PetContainer, PetImg } from '../../views/Bookings/components/BookingRecord/styled';
import { GatsbyImage } from 'gatsby-plugin-image';
import { BusUserProfile } from '../Profile/types';
import { PetProfilePic, PetProfilePicsContainer } from '../UserDrawer/styled';
import { DRAWER_IDS } from '../DrawerBar/types';
import useNavigateDrawer from '../../hooks/useNavigateDrawer';
import draftToHtml from 'draftjs-to-html';

export type Room = {
  id: string;
  name: string;
  createdAt: string;
  cover_photo: string;
  AppUsers: BranchAppUser[];
  BusUsers: BusUserProfile[];
  is_bus_room: boolean;
  MessageReadStatuses: {
    id: string;
    status: string;
    last_read_message: string;
    last_read_timestamp: string;
    Branch: {
      id: string;
    };
  }[];
  Messages: {
    id: string;
    body: string;
    photos: string[];
    location: string;
    video: string;
    timestamp: string;
    user_id: string;
    ChatRoomId: string;
    isSilent?: boolean;
  }[];
  last_message: Room['Messages'][0];
  read_status: {
    is_read: boolean;
    user_id: string;
  }[];
};
type Props = {
  room: Room;
  navigateDrawer: ({ drawerLinkId }: { drawerLinkId: string }) => void;
  isRoomSeen: (room: Room) => boolean;
  appUserQuickTags?: BranchAppUserTag[];
  profile: BusUserProfile;
  tagsVisibleOnItem?: BranchAppUserTag[];
  updateChatRooms: (newRooms: Room[]) => void;
  isStaff: boolean;
};

const resolveMessage = (message: any) => {
  if (message?.body) {
    return message.body;
  }
  if (message?.photos?.length) {
    return 'Photo';
  }
  if (message?.location) {
    return 'Location';
  }
  if (message?.video) {
    return 'Video';
  }
  return '';
};

const ChatRecord = ({ room, navigateDrawer, isRoomSeen, appUserQuickTags, profile, tagsVisibleOnItem, updateChatRooms, isStaff }: Props) => {
  const icons = useIcons();
  const users = isStaff ? room?.BusUsers?.filter(busUser => busUser?.id !== profile?.id) : room?.AppUsers || [];
  const userRef = useRef(null);
  // const time = toReadableDate(new Date(room?.last_message?.timestamp || room?.createdAt), { isLocale: true });

  const time = new Date(room?.last_message?.timestamp || room?.createdAt);

  const day = () => {
    return time.getDate() === new Date().getDate() ? '' : time.getDate() === new Date().getDate() - 1 ? 'Yesterday,' : time.getDate() < 10 ? `0${time.getDate()}` : time.getDate();
  };
  const month = () => {
    return time.getDate() !== new Date().getDate() && time.getDate() !== new Date().getDate() - 1 ? findMonthName(time.getMonth()) + ',' : '';
  };
  const hour = () => {
    return time.getHours() < 10 ? `0${time.getHours()}` : time.getHours();
  };
  const minutes = () => {
    return time.getMinutes() < 10 ? `0${time.getMinutes()}` : time.getMinutes();
  };
  const year = () => {
    let numberOfYears = 0;
    numberOfYears = new Date()?.getFullYear() - time?.getFullYear();
    return numberOfYears > 1 ? `${numberOfYears} years ago` : `A year ago`;
  };

  const timeToReturn = new Date().getFullYear() !== time.getFullYear() ? year() : `${day()} ${month()} ${hour()}:${minutes()}`;

  const { mobile } = useMediaQuery({ mobile: true });
  const chatRecordOptionsRef = useRef<ChatRecordOptionsRef>(null);
  const toggleMenu = () => {
    chatRecordOptionsRef?.current?.getOptionDropdownRefOptions?.()?.toggleMenu?.();
  };
  const navigateDrawerUser = useNavigateDrawer({
    drawerData: { drawerId: DRAWER_IDS.USER_DRAWER }
  });

  const handleClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    const isTapToTagElement = e.target instanceof HTMLElement && e.target.tagName.toLowerCase() === 'img' && mobile;
    if (userRef?.current?.contains?.(e.target as Node) && !isStaff && users?.length === 1 && !mobile) {
      navigateDrawerUser({ drawerLinkId: users[0]?.id });
      return;
    }
    if (isTapToTagElement) {
      toggleMenu();
    } else {
      if (chatRecordOptionsRef?.current?.getContainerRefOptions?.()?.contains?.(e.target as Node)) {
        return;
      }
      const read_status = (room?.read_status || [])?.filter(status => status?.user_id !== profile?.id);
      updateChatRooms([{ ...room, read_status: [...read_status, { is_read: true, user_id: profile?.id }] }]);
      navigateDrawer({ drawerLinkId: room?.id });
    }
  };

  const hasFilters = false;

  const tags = room?.AppUsers?.flatMap(user => user?.BranchAppUserTags) || [];
  const tagsToRender = tagsVisibleOnItem ? tags.filter(tag => tagsVisibleOnItem.some(visibleTag => visibleTag.id === tag.id)) : tags;

  const recordOptions = (
    <PetRecordOptionsContainer>
      <ChatRecordOptions
        ref={chatRecordOptionsRef}
        showAlways={mobile}
        loadTags={false}
        appUsers={room?.AppUsers}
        appUserQuickTags={appUserQuickTags}
        profile={profile}
        chatRoom={room}
        right={mobile}
        updateChatRooms={updateChatRooms}
      />
    </PetRecordOptionsContainer>
  );

  const renderTags = () => {
    return (
      <>
        {!hasFilters && (
          <PetRecordTagsContainer flex="none">
            <PetRecordTags widthAuto reduceWidth="100px" mobileAutoWidth>
              {tagsToRender?.map((item, index) => (
                <PetRecordTag color={item.color} key={index}>
                  <span>{item.name}</span>
                </PetRecordTag>
              ))}
            </PetRecordTags>
          </PetRecordTagsContainer>
        )}
      </>
    );
  };
  const renderMobile = () => {
    return (
      <div style={{ width: '100%' }}>
        <RecordBody marginBottom="4">
          <RecordBody noMargin mobileWidth="120px" fontWeight="800" marginLeft="24" mobileDisplay="block" mobileOverflow="hidden">
            {users.map(user => user.name).join(' & ') || 'No User'}
          </RecordBody>
          <RecordBody style={{ color: '#7A7A7A' }}>{timeToReturn}</RecordBody>
        </RecordBody>
        <div style={{ display: 'flex', gap: '8px', justifyContent: 'center', alignItems: 'center' }}>
          <RecordBody mobileWidth="200px" marginLeft="24" mobileOverflow="hidden" mobileDisplay="block" mobileFlex="1">
            {lastMessage}
          </RecordBody>
        </div>
      </div>
    );
  };

  const lastMessage = resolveMessage(room?.last_message);

  const { messageWithoutDynamicLinks } = useDynamicLinks(lastMessage, { loadHandler: false });

  const isSeen = isRoomSeen(room);
  const usersProfilePics = users?.map(user => (user?.profile_pic ? user?.profile_pic : icons.user.childImageSharp.gatsbyImageData.images.fallback.src));

  return (
    <>
      <RecordDataContainer onClick={handleClick} gap={12}>
        {
          <AppUserImageNameContainer ref={userRef}>
            {!isSeen && <RedDot color={isSeen ? 'transparent' : Colors.alert} mobileBottom="auto" mobileLeft="35px" mobileZIndex mobileTop="29px" />}
            {/* {isSeen && !mobile && <div style={{ height: '16px', width: '16px' }} />} */}
            <AppUserImageContainer mobileWidth="50px">
              <RecordBody style={{ position: 'absolute', left: '28px', height: 0 }}>{recordOptions}</RecordBody>
              {room?.is_bus_room && (
                <AppUserImageContainer noPadding mobileWidth="40px">
                  <AppUserImageContainerAnimation noPadding>
                    <AppUserImageAnimation>
                      {room?.cover_photo ? (
                        <AppUserImage src={room?.cover_photo} index={0} />
                      ) : (
                        <GatsbyImage
                          image={icons.user.childImageSharp.gatsbyImageData}
                          alt="user"
                          style={{ width: mobile ? '44px' : '40px', height: mobile ? '44px' : '40px', borderRadius: '25px' }}
                        />
                      )}
                    </AppUserImageAnimation>
                  </AppUserImageContainerAnimation>
                </AppUserImageContainer>
              )}
              {!room?.is_bus_room && (
                <>
                  {users?.length === 1 &&
                    users?.map(user => (
                      <AppUserImageContainer noPadding key={user?.id} mobileWidth="40px">
                        <AppUserImageContainerAnimation noPadding>
                          <AppUserImageAnimation key={user?.id}>
                            {user?.profile_pic && <AppUserImage src={user?.profile_pic} index={0} />}
                            {!user?.profile_pic && (
                              <GatsbyImage
                                image={icons.user.childImageSharp.gatsbyImageData}
                                alt="user"
                                style={{ width: mobile ? '44px' : '40px', height: mobile ? '44px' : '40px', borderRadius: '25px' }}
                              />
                            )}
                          </AppUserImageAnimation>
                        </AppUserImageContainerAnimation>
                      </AppUserImageContainer>
                    ))}

                  {usersProfilePics?.length === 2 && (
                    <PetProfilePicsContainer>
                      {usersProfilePics?.map((profile_pic, index) => (
                        <PetProfilePic key={index} bgUrl={profile_pic} isDuo={usersProfilePics?.length === 2} />
                      ))}
                    </PetProfilePicsContainer>
                  )}
                  {usersProfilePics?.length > 2 && (
                    <PetProfilePicsContainer>
                      <PetProfilePic bgUrl={usersProfilePics[0]} isDuo />
                      <PetProfilePic isMulti> +{usersProfilePics?.length - 1}</PetProfilePic>
                    </PetProfilePicsContainer>
                  )}
                </>
              )}
            </AppUserImageContainer>

            {!mobile && (
              <RecordBody width="120" color=" #212121" fontSize={14} fontWeight="800" noMargin>
                {room?.is_bus_room ? room?.name : users.map(user => user.name).join(' & ') || 'No User'}
              </RecordBody>
            )}
          </AppUserImageNameContainer>
        }

        {!mobile && <RecordBody minWidth={120}>{messageWithoutDynamicLinks}</RecordBody>}
        {!mobile && tags.length >= 1 && users.length === 1 && renderTags()}
        {!mobile && (
          <div style={{ display: 'flex', marginLeft: 'auto' }}>
            <RecordBody width="100" minWidth={100} maxWidth={150} color="#959595" fontWeight="600" flex="1" overflow="unset" flexEnd={true} fontSize={12}>
              {timeToReturn}
            </RecordBody>
            {recordOptions}
          </div>
        )}
        {mobile && renderMobile()}
      </RecordDataContainer>
      {!mobile && <Divider />}
    </>
  );
};

export default ChatRecord;
