import { useMutation, useQuery } from '@apollo/client';
import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import ModalDialog, { ModalLayout } from '../../../components/Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../../../components/Modal/styled';
import { FormButtonsContainer, FormHeaderLabel, FormSubmitButton } from '../../../components/Shared/Forms/Forms';
import { CenteredLoadingContainer, Container } from '../../../components/Shared/Shared';
import { VerticallyCenteredLoader } from '../../../components/Shared/Spinner';
import useIcons from '../../../hooks/useIcons';
import { AddBranchDiscount, DeleteBranchDiscount, EditBranchDiscount, GetBranchDiscounts, GetBranchDiscountsWithoutVouchers } from '../../../queries';
import { Divider } from '../../Pets/Health/styled';
import Common from '../Common';
import { DiscountsContainer, DiscountsFormWrapper } from '../Operations/styled';
import { AddNewBtn, AddNewBtnIcon } from '../Products/styled';
import DiscountForm from './DiscountForm';
import { Discount, DiscountInput, DiscountsForm as DiscountsFormType, DiscountsModalFormProps, DiscountsModalProps, DiscountsRef, FormDiscount } from './types';
import { getDiscountsDefaultValues, getSelectedDiscounts } from './utils';

const DiscountsModalForm = forwardRef<DiscountsRef, DiscountsModalFormProps>(({ allowAddAndRemove = false, discountId, discounts, editVouchers = true, onDelete }, ref) => {
  const formOptions = useForm<DiscountsFormType>({
    shouldUnregister: false,
    defaultValues: {
      discounts: getDiscountsDefaultValues(discounts)
    }
  });

  const [handleEditDiscount, { loading: editDiscountLoading, error: editDiscountError, data: editedDiscount }] = useMutation(EditBranchDiscount, {
    refetchQueries: ['getBranchDiscounts']
  });

  const [handleAddDiscount, { loading: addDiscountLoading, error: addDiscountError, data: addedDiscount }] = useMutation(AddBranchDiscount, {
    refetchQueries: ['getBranchDiscounts']
  });

  const [handleDeleteDiscount, { loading: deleteDiscountDelete, error: deleteDiscountError, data: deletedDiscount }] = useMutation(DeleteBranchDiscount, {
    refetchQueries: ['getBranchDiscounts']
  });

  const { control, getValues, handleSubmit, watch } = formOptions;

  const watchedDiscounts: FormDiscount[] = watch('discounts');

  const handleFormSubmit = handleSubmit(async form => {
    const discounts = getSelectedDiscounts(form.discounts);
    if (!discounts.length) {
      return;
    }

    const variables = {
      ...discounts[0],
      extraFields: {
        editVouchers
      }
    };

    if (discountId) {
      await handleEditDiscount({ variables });
    } else {
      await handleAddDiscount({ variables });
    }
    Common.get<() => Promise<void>>(`Discounts.GetBranchDiscounts.refetch`)?.();
    ModalDialog.closeModal();
  });

  const handleRemoveDiscountRow = (index: number) => {
    discountsFieldArray.remove(index);
  };

  const icons = useIcons();

  const discountsFieldArray = useFieldArray<FormDiscount, 'itemId'>({
    control,
    name: 'discounts',
    keyName: 'itemId'
  });

  const handleAddDiscountRow = () => {
    discountsFieldArray.append({
      name: '',
      amount: 0,
      type: 'amount',
      status: 'ACTIVE',
      rules: [],
      vouchers: []
    });
  };

  const handleDiscountsSubmit: () => DiscountInput[] = () => {
    const { discounts } = getValues();

    return getSelectedDiscounts(discounts);
  };

  useImperativeHandle(ref, () => ({
    handleDiscountsSubmit
  }));

  const allDiscountsUnused = watchedDiscounts.every(discount => discount.isUnused);

  const onDiscountDelete = async (index: number) => {
    const discount = watchedDiscounts[index];
    if (!discount?.isUnused) {
      return;
    }
    if (discount?.id) {
      await handleDeleteDiscount({
        variables: {
          id: [discount.id]
        }
      });
      onDelete?.();
      ModalDialog.closeModal();
      return;
    }
    discountsFieldArray.remove(index);
  };

  const handleDeleteAllDiscounts = async () => {
    const ids = watchedDiscounts.filter(discount => discount?.isUnused && discount?.id).map(discount => discount.id);
    if (!ids.length) {
      return;
    }
    await handleDeleteDiscount({
      variables: {
        id: ids
      }
    });
    ModalDialog.closeModal();
  };

  return (
    <ModalLayout
      compact
      buttons={[
        allDiscountsUnused ? (
          <FormSubmitButton error={!!deleteDiscountError} loading={deleteDiscountDelete} onClick={handleDeleteAllDiscounts} danger>
            Delete
          </FormSubmitButton>
        ) : (
          <></>
        ),
        <FormSubmitButton error={!!addDiscountError || !!editDiscountError} loading={addDiscountLoading || editDiscountLoading} onClick={handleFormSubmit}>
          Save
        </FormSubmitButton>
      ]}
    >
      <DiscountsContainer>
        {discountsFieldArray.fields.map((item, index) => {
          const isLast = index === discountsFieldArray.fields.length - 1;
          const allowDelete = watchedDiscounts[index]?.isUnused;
          return (
            <DiscountsFormWrapper key={item.itemId}>
              <DiscountForm
                discount={item}
                index={index}
                formOptions={formOptions}
                handleRemoveRow={handleRemoveDiscountRow}
                allowAddAndRemove={allowAddAndRemove}
                allowDelete={allowDelete}
                handleDelete={() => onDiscountDelete(index)}
                editVouchers={editVouchers}
              />
              {!isLast && <Divider />}
            </DiscountsFormWrapper>
          );
        })}
        {allowAddAndRemove && (
          <AddNewBtn onClick={handleAddDiscountRow} noSpaceArround>
            <AddNewBtnIcon src={icons.addPhoto.childImageSharp.gatsbyImageData.images.fallback.src} />
            Add New Discount
          </AddNewBtn>
        )}
      </DiscountsContainer>
    </ModalLayout>
  );
});

const DiscountsModal = forwardRef<DiscountsRef, DiscountsModalProps>(({ discountId, allowAddAndRemove = false, editVouchers = true, onDelete }, ref) => {
  const { data: { getBranchDiscounts: discounts = [] } = {}, loading: loadingDiscounts } = useQuery<{ getBranchDiscounts: Discount[] }>(
    editVouchers ? GetBranchDiscounts : GetBranchDiscountsWithoutVouchers,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        id: discountId ? [discountId] : null,
        editVouchers
      },
      skip: !discountId
    }
  );

  const sortedDiscounts = discounts.map(discount => ({
    ...discount,
    Vouchers: [...(discount?.Vouchers || [])]?.sort?.((voucherA, voucherB) => {
      if (!voucherA.AppUsers || !voucherA.AppUsers[0] || !voucherA.AppUsers[0].name) {
        return 1;
      }
      if (!voucherB.AppUsers || !voucherB.AppUsers[0] || !voucherB.AppUsers[0].name) {
        return -1;
      }

      const nameA = voucherA.AppUsers[0].name.toLowerCase();
      const nameB = voucherB.AppUsers[0].name.toLowerCase();
      return nameA.localeCompare(nameB);
    })
  }));

  return (
    <>
      {loadingDiscounts && (
        <CenteredLoadingContainer style={{ padding: 20 }}>
          <VerticallyCenteredLoader size={25} />
        </CenteredLoadingContainer>
      )}
      {!loadingDiscounts && (
        <DiscountsModalForm ref={ref} discountId={discountId} discounts={sortedDiscounts} allowAddAndRemove={allowAddAndRemove} editVouchers={editVouchers} onDelete={onDelete} key={discountId} />
      )}
    </>
  );
});

export default DiscountsModal;
