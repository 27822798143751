import React, { useEffect } from 'react';
import { GetAllBranchDiscounts, GetAllSubscriptions, GetBranchDiscounts, GetBusUserProfile, UpdateOrderSubscription } from '../../queries';
import { useMutation, useQuery } from '@apollo/client';
import { getSubscriptionOrder } from '../../views/Subscriptions/utils';
import { SUBSCRIPTION_COLLECTION_STATUS_TYPES, SUBSCRIPTION_STATUS_TYPES, Subscription } from '../../views/Subscriptions/types';
import { ActivityIndicator, VerticallyCenteredLoader } from '../Shared/Spinner';
import { FormButtonsContainer, FormError, FormInput, FormLabel, FormRadio, FormSelect, FormSubmitButton, InputContainer, RadioBtnsGroup, WideInputGroup } from '../Shared/Forms/Forms';
import { Controller, useForm } from 'react-hook-form';
import { ModalBody, ModalFooter } from '../Modal/styled';
import { ColorCircle, Container, PatternCircle } from '../Shared/Shared';
import { BusUserProfile } from '../Profile/types';
import { LoaderContainer } from '../../views/Store/styled';
import ModalDialog, { ModalLayout } from '../Modal/ModalDialog';
import { Customization } from '../../views/Store/types';
import { isValidURL } from '../../utils/validators';
import { getSelectedCustomizations } from '../Shared/Modals/NewBookingModal/utils';
import { getKeys } from '../../utils/helpers';
import { sentenceCase } from 'sentence-case';
import { Discount, OrderVoucher } from '../../views/Store/Discounts/types';

type SubscriptionFormValues = {
  productId: string;
  voucherId?: string;
  discountId?: string;
  overrideTotal?: boolean;
  overriddenTotal?: number;
  customizations?: {
    [key: string]: string;
  };
  collectionStatus?: keyof typeof SUBSCRIPTION_COLLECTION_STATUS_TYPES;
  trialEnd?: string;
  trialEndNow?: boolean;
};

const EditSubscriptionModal = ({ subscription, orderVouchers = [], onUpdate }: { subscription: Subscription; orderVouchers?: OrderVoucher[]; onUpdate: () => void }) => {
  const defaultCustomizations = subscription?.OrderSubscriptionRepeats[0].OrderItem.customizations || [];

  const defaultValues = {
    productId: subscription?.OrderSubscriptionRepeats[0].OrderItem.item.id || '',
    voucherId: orderVouchers[0]?.Voucher?.id || '',
    discountId: orderVouchers[0]?.Voucher?.Discount?.id || '',
    overrideTotal: false,
    overriddenTotal: 0,
    customizations:
      (defaultCustomizations?.flatMap?.(({ fields }) => getKeys(fields).reduce((acc, key) => ({ ...acc, [key]: fields[key].title || '' }), {}))[0] as SubscriptionFormValues['customizations']) || {},
    collectionStatus: subscription?.collection_status,
    trialEnd: new Date(subscription?.trial_end).toISOString().split('T')[0],
    trialEndNow: false
  };

  const formOptions = useForm<SubscriptionFormValues>({ defaultValues });

  const { control, errors, handleSubmit, watch, setValue } = formOptions;

  const {
    subscription: { id: subscriptionId }
  } = getSubscriptionOrder(subscription);

  const watchedValues = watch(['overrideTotal', 'productId', 'customizations', 'trialEndNow', 'collectionStatus', 'discountId']);

  const { data: { getProducts: subscriptions = [] } = {}, loading: loadingSubscriptions } = useQuery<{ getProducts: { id: string; name: string; customizations: Record<string, Customization> }[] }>(
    GetAllSubscriptions,
    {
      fetchPolicy: 'cache-and-network'
    }
  );

  useEffect(() => {
    if (!watchedValues.productId) {
      return;
    }

    if (watchedValues.productId === defaultValues.productId) {
      setValue('customizations', defaultValues.customizations);
    } else {
      setValue('customizations', {});
    }
  }, [watchedValues.productId]);

  const selectedSubscription = subscriptions?.find(({ id }) => id === watchedValues.productId);

  const customizationsMap = selectedSubscription?.customizations || {};

  const titlesArray: string[] = Object.keys(customizationsMap)
    .slice()
    .sort((a, b) => a.localeCompare(b));

  const customizationsList: Customization[] = titlesArray.map(property =>
    customizationsMap?.[property]?.map(item => ({
      ...item,
      id: item?.title,
      name: item?.title,
      Indicator: item?.meta?.hex ? <ColorCircle color={item?.meta?.hex} /> : item?.meta?.url && isValidURL.test(item?.meta?.url) && <PatternCircle src={`${item?.meta?.url}`} alt={item?.title} />
    }))
  );

  const { data: { getBranchDiscounts: discounts = [] } = {}, loading: loadingDiscounts } = useQuery<{ getBranchDiscounts: Discount[] }>(GetAllBranchDiscounts, {
    fetchPolicy: 'cache-and-network'
  });

  const { data: { getBranchDiscounts: [{ Vouchers: vouchers = [] } = {}] = [] } = {} } = useQuery<{ getBranchDiscounts: Discount[] }>(GetBranchDiscounts, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: [watchedValues.discountId]
    },
    skip: !watchedValues.discountId
  });

  const loading = loadingSubscriptions || loadingDiscounts;

  const [updateSubscription, { loading: loadingUpdateSubscription, data: { updateOrderSubscription: updatedSubscription = {} } = {} }] = useMutation(UpdateOrderSubscription);

  const handleEdit = handleSubmit(form => {
    const { productId, overrideTotal, overriddenTotal, voucherId, customizations, collectionStatus, trialEnd: formTrialEnd, trialEndNow } = form;
    let trialEnd: string | undefined = subscription?.trial_end;

    if (formTrialEnd) {
      trialEnd = new Date(formTrialEnd).toISOString();
    }

    if (new Date(trialEnd).getTime() < new Date().getTime()) {
      trialEnd = undefined;
    }

    if (trialEndNow) {
      trialEnd = 'now';
    }

    const selectedCustomizations = getSelectedCustomizations({
      customizations: customizations || {},
      customizationsMap
    });

    const newCustomizations = Object.keys(customizations || {}).length ? [selectedCustomizations] : null;

    const variables = {
      id: subscriptionId,
      overriddenTotal: overrideTotal ? Number(overriddenTotal) : null,
      ProductId: productId,
      customizations: newCustomizations,
      VoucherId: (overrideTotal ? null : voucherId ? [voucherId] : []) || [],
      collectionStatus,
      trialEnd
    };

    const pausedSubscriptionVariables = {
      id: subscriptionId,
      ProductId: productId,
      customizations: newCustomizations,
      collectionStatus
    };

    const isPaused = collectionStatus === 'PAUSED';

    updateSubscription({ variables: isPaused ? pausedSubscriptionVariables : variables });
  });

  useEffect(() => {
    if (updatedSubscription?.id) {
      onUpdate();

      ModalDialog.closeModal();
    }
  }, [updatedSubscription]);

  const trialEndsInTheFuture = new Date(subscription.trial_end).getTime() > new Date().getTime();

  return (
    <ModalLayout
      buttons={[
        <FormSubmitButton loading={loadingUpdateSubscription} onClick={handleEdit}>
          Save
        </FormSubmitButton>
      ]}
      compact
    >
      <WideInputGroup>
        <FormLabel error={errors?.productId?.message}>Membership</FormLabel>
        <Controller
          render={({ onChange, value }) => (
            <FormSelect
              height={48}
              fontSize={16}
              name={'product'}
              error={errors?.productId?.message}
              onChange={e => {
                onChange(e.target.value);
              }}
              value={value || ''}
            >
              <option disabled value={''}>
                -- select a membership --
              </option>
              {subscriptions?.map(({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </FormSelect>
          )}
          control={control}
          name={'productId'}
          defaultValue={defaultValues.productId}
          rules={{
            required: true,
            validate: (value: string) => {
              if (value === '') {
                return 'Please select a service';
              }
            }
          }}
        />
        {errors?.productId && <FormError>{errors?.productId.message || 'Please select a service'}</FormError>}
      </WideInputGroup>
      {!!Object.keys(customizationsMap).length && (
        <WideInputGroup>
          <FormLabel error={Object.values(errors?.customizations || {})?.find(c => c?.message)?.message}>Variations</FormLabel>
          {loading && <VerticallyCenteredLoader size={15} />}
          {!loading && (
            <>
              {titlesArray?.map((title, index) => (
                <WideInputGroup key={index}>
                  <Controller
                    control={control}
                    name={`customizations.${title}`}
                    render={({ onChange, value }) => (
                      <>
                        <FormLabel>{title}</FormLabel>
                        <FormSelect
                          height={48}
                          fontSize={16}
                          name={`customizations.${title}`}
                          error={Object.values(errors?.customizations || {})?.find(c => c?.message)?.message}
                          onChange={e => {
                            onChange(e.target.value);
                          }}
                          value={value || ''}
                        >
                          <option disabled value={''}>
                            -- select a variation --
                          </option>

                          {customizationsList[index].map((customization, i) => (
                            <option key={i} value={customization.name}>
                              {customization.name}
                            </option>
                          ))}
                        </FormSelect>
                      </>
                    )}
                    rules={{
                      required: true,
                      validate: (value: any) => {
                        if (value.length === 0) {
                          return 'Please select a variation';
                        }
                      }
                    }}
                    defaultValue={defaultValues.customizations?.[title] || ''}
                  />
                </WideInputGroup>
              ))}
            </>
          )}
          {errors?.customizations && <FormError>{Object.values(errors?.customizations || {})?.find(c => c?.message)?.message || 'Variations are required'}</FormError>}
        </WideInputGroup>
      )}
      <WideInputGroup>
        <FormLabel error={errors?.collectionStatus?.message}>Status</FormLabel>
        <Controller
          render={({ onChange, value }) => (
            <FormRadio
              fontSize={16}
              error={errors?.collectionStatus?.message}
              itemsArray={Object.values(SUBSCRIPTION_COLLECTION_STATUS_TYPES).map(status => ({ id: status, name: sentenceCase(status) }))}
              onChange={onChange}
              value={value || ''}
            />
          )}
          control={control}
          name={'collectionStatus'}
          defaultValue={defaultValues.collectionStatus}
          rules={{
            required: true,
            validate: (value: string) => {
              if (value === '') {
                return 'Please select a status';
              }
            }
          }}
        />
        {errors?.collectionStatus && <FormError>{errors?.collectionStatus.message || 'Please select a status'}</FormError>}
      </WideInputGroup>
      {watchedValues.collectionStatus === 'ACTIVE' && (
        <>
          <WideInputGroup>
            <FormLabel error={errors?.trialEnd?.message}>Trial End</FormLabel>
            <Controller
              render={({ onChange, value }) => (
                <FormInput
                  fontSize={16}
                  name={'trialEnd'}
                  error={errors?.trialEnd?.message}
                  type={'date'}
                  disabled={watchedValues.trialEndNow}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  value={value || ''}
                />
              )}
              control={control}
              name={'trialEnd'}
              defaultValue={defaultValues.trialEnd}
              min={new Date().toISOString().split('T')[0]}
              rules={{
                required: true,
                validate: (value: string) => {
                  if (value === '') {
                    return 'Please select a trial end date';
                  }
                }
              }}
            />

            {errors?.trialEnd && <FormError>{errors?.trialEnd.message || 'Please select a trial end date'}</FormError>}
          </WideInputGroup>

          {trialEndsInTheFuture && (
            <WideInputGroup>
              <InputContainer isRow flex={1} marginBottom={20}>
                <Controller
                  render={({ onChange, value }) => (
                    <RadioBtnsGroup
                      options={['End trial now']}
                      defaultValue={''}
                      onChange={() => {
                        onChange(!value);
                      }}
                      itemStyle={{ width: '100%' }}
                      name={`trialEndNow`}
                      inputType={'checkbox'}
                    />
                  )}
                  control={control}
                  name={`trialEndNow`}
                  defaultValue={defaultValues.trialEndNow}
                />
              </InputContainer>
            </WideInputGroup>
          )}

          {!watchedValues.overrideTotal && (
            <>
              <WideInputGroup>
                <FormLabel error={errors?.discountId?.message}>Discount</FormLabel>
                <Controller
                  render={({ onChange, value }) => (
                    <FormSelect
                      height={48}
                      fontSize={16}
                      name={'discountId'}
                      error={errors?.discountId?.message}
                      onChange={e => {
                        onChange(e.target.value);
                      }}
                      value={value || ''}
                    >
                      <option value={''}>-- No Discount --</option>
                      {discounts?.map(({ id, name }) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))}
                    </FormSelect>
                  )}
                  control={control}
                  name={'discountId'}
                  defaultValue={defaultValues.discountId}
                />
                {errors?.discountId && <FormError>{errors?.discountId.message || 'Please select a discount'}</FormError>}
              </WideInputGroup>
              {watchedValues.discountId && (
                <WideInputGroup>
                  <FormLabel error={errors?.voucherId?.message}>Voucher</FormLabel>
                  <Controller
                    render={({ onChange, value }) => (
                      <FormSelect
                        height={48}
                        fontSize={16}
                        name={'voucherId'}
                        error={errors?.voucherId?.message}
                        onChange={e => {
                          onChange(e.target.value);
                        }}
                        value={value || ''}
                      >
                        <option value={''}>-- No Voucher --</option>
                        {vouchers?.map(({ id, code }) => (
                          <option key={id} value={id}>
                            {code}
                          </option>
                        ))}
                      </FormSelect>
                    )}
                    control={control}
                    name={'voucherId'}
                    defaultValue={defaultValues.voucherId}
                    rules={{
                      required: true,
                      validate: (value: string) => {
                        if (value === '') {
                          return 'Please select a voucher';
                        }
                      }
                    }}
                  />
                  {errors?.voucherId && <FormError>{errors?.voucherId.message || 'Please select a voucher'}</FormError>}
                </WideInputGroup>
              )}
            </>
          )}

          <WideInputGroup>
            <InputContainer isRow flex={1} marginBottom={20}>
              <Controller
                render={({ onChange, value }) => (
                  <RadioBtnsGroup
                    options={['Override total']}
                    defaultValue={''}
                    onChange={() => {
                      onChange(!value);
                    }}
                    itemStyle={{ width: '100%' }}
                    name={`overrideTotal`}
                    inputType={'checkbox'}
                  />
                )}
                control={control}
                name={`overrideTotal`}
                defaultValue={defaultValues.overrideTotal}
              />
            </InputContainer>
            {watchedValues.overrideTotal && (
              <InputContainer marginBottom={20}>
                <FormLabel error={errors?.overriddenTotal?.message}>Total</FormLabel>
                <Controller
                  as={<FormInput error={errors?.overriddenTotal?.message} type={'number'} />}
                  control={control}
                  name={'overriddenTotal'}
                  rules={{ required: true, min: { value: 0, message: 'Total must be greater than 0' } }}
                  defaultValue={defaultValues.overriddenTotal}
                />
                {errors?.overriddenTotal && <FormError>{errors?.overriddenTotal.message || 'Total is required'}</FormError>}
              </InputContainer>
            )}
          </WideInputGroup>
        </>
      )}
    </ModalLayout>
  );
};

export default EditSubscriptionModal;
