import React, { FC, Fragment } from 'react';
import { Controller, useFieldArray, UseFormMethods } from 'react-hook-form';
import { FormError, FormInput, FormLabel, InputGroup } from '../../../components/Shared/Forms/Forms';
import { deliveryRulesOptions, ProviderBranchModalFormType } from './types';
import { FlexRow } from '../../../components/Shared/Shared';
import Select from '../../../components/Shared/Forms/Select';
import { Divider } from '../../../components/DrawerBar/styled';
import { AddNewBtn, AddNewBtnIcon } from '../Products/styled';
import useIcons from '../../../hooks/useIcons';

type DeliveryProps = {
  formOptions: UseFormMethods<ProviderBranchModalFormType>;
};

const Delivery: FC<DeliveryProps> = ({ formOptions }) => {
  const icons = useIcons();
  const { control, errors, watch } = formOptions;
  const fieldArray = useFieldArray<ProviderBranchModalFormType['delivery_rules'][0]>({
    control,
    name: 'delivery_rules'
  });

  const watchedDeliveryRules = watch('delivery_rules');

  const handleAddRule = () => {
    fieldArray.append({
      description: '',
      price: 0,
      rule: {
        type: 'none',
        value: 0
      }
    });
  };

  return (
    <>
      {fieldArray.fields.map((field, index) => (
        <Fragment key={field.id}>
          <InputGroup>
            <FormLabel>Title</FormLabel>
            <Controller
              render={({ onChange, value }) => (
                <FormInput
                  value={value}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                />
              )}
              control={control}
              name={`delivery_rules[${index}].description`}
              defaultValue={fieldArray.fields[index].description}
              rules={{ required: true }}
            />
            {errors.delivery_rules && errors.delivery_rules[index] && <FormError>{errors.delivery_rules[index].description?.message || 'Description is required'}</FormError>}
          </InputGroup>
          <InputGroup>
            <FormLabel>Price</FormLabel>
            <Controller
              as={<FormInput type={'number'} error={!!errors.delivery_rules && errors.delivery_rules[index]?.price?.message} />}
              control={control}
              name={`delivery_rules[${index}].price`}
              defaultValue={fieldArray.fields[index].price || 0}
              rules={{ required: true }}
            />
            {errors.delivery_rules && errors.delivery_rules[index] && <FormError>{errors.delivery_rules[index].price?.message || 'Price is required'}</FormError>}
          </InputGroup>
          <FlexRow gap={10}>
            <InputGroup width="200px">
              <FormLabel>Rule</FormLabel>
              <Controller
                render={({ onChange, value }) => (
                  <Select
                    options={deliveryRulesOptions}
                    value={value ? deliveryRulesOptions.find(option => option.value === value) : deliveryRulesOptions[0]}
                    onChange={values => {
                      onChange(values?.value || 'none');
                    }}
                  />
                )}
                control={control}
                name={`delivery_rules[${index}].rule.type`}
                defaultValue={fieldArray.fields[index].rule?.type || 'none'}
                rules={{ required: true }}
              />
              {errors.delivery_rules && errors.delivery_rules[index] && <FormError>{errors.delivery_rules[index].rule?.type?.message || 'Rule is required'}</FormError>}
            </InputGroup>
            {watchedDeliveryRules[index]?.rule?.type !== 'none' && (
              <InputGroup>
                <FormLabel>Value</FormLabel>
                <Controller
                  as={<FormInput type={'number'} error={!!errors.delivery_rules && errors.delivery_rules[index]?.rule?.value?.message} />}
                  control={control}
                  name={`delivery_rules[${index}].rule.value`}
                  defaultValue={fieldArray.fields[index].rule?.value || 0}
                  rules={{ required: true }}
                />
                {errors.delivery_rules && errors.delivery_rules[index] && <FormError>{errors.delivery_rules[index].rule?.value?.message || 'Value is required'}</FormError>}
              </InputGroup>
            )}
          </FlexRow>
          {index !== fieldArray.fields.length - 1 && <Divider />}
        </Fragment>
      ))}

      <AddNewBtn onClick={handleAddRule} noSpaceArround>
        <AddNewBtnIcon src={icons.plusBlack.childImageSharp.gatsbyImageData.images.fallback.src} />
        New Delivery Rule
      </AddNewBtn>
    </>
  );
};

export default Delivery;
