import { useQuery, useReactiveVar } from '@apollo/client';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { BusUserProfile } from '../../../../components/Profile/types';
import Filters from '../../../../components/Shared/Filters/Filters';
import SearchBar from '../../../../components/Shared/Filters/SearchBar';
import { getFilterKeyOptions } from '../../../../components/Shared/Filters/helpers';
import { FiltersControl } from '../../../../components/Shared/Filters/useFilters';
import { FlexRow, TabsHeaderContainer } from '../../../../components/Shared/Shared';
import { Divider } from '../../../../components/Shared/SideTabs/styles';
import ToolBar from '../../../../components/Shared/ToolBar/ToolBar';
import useIcons from '../../../../hooks/useIcons';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import useURLSearchParams from '../../../../hooks/useURLSearchParams';
import { GetAllServices, GetAllBranchBusUsers, GetBusUserProfile } from '../../../../queries';
import { vars } from '../../../../reactive';
import debounce from '../../../../utils/debounce';
import { Product } from '../../../Store/types';
import { BOOKINGS_FILTER_TYPES, BOOKINGS_MORE_OPTIONS_TYPES } from '../../BookingsFilters';
import { BOOKING_RECORD_VIEW_SETTINGS, BOOKING_TAB, BOOKING_TABS_TYPES, BOOKING_TABS_VALUES_TYPES } from '../../types';
import BookingsNavigation from '../BookingsNavigation/BookingsNavigation';
import BookingTagsQuickFilter from './BookingTagsQuickFilter';
import { Header, ViewSwitchContainer } from './styled';
import MultiStaffCalendar from '../../../../components/Calendar/MultiStaffCalendar/MultiStaffCalendar';
import { OPTION_DROPDOWN_MENU_BUTTON_TYPES, OPTION_DROPDOWN_MENU_POSITIONS, OPTION_DROPDOWN_TYPES } from '../../../../components/Shared/Menus/OptionDropdown/types';
import OptionDropdown from '../../../../components/Shared/Menus/OptionDropdown/OptionDropdown';
import { useForm } from 'react-hook-form';
import { RecordBody } from '../../../styled';
import { VerticalDivider } from '../../../../components/Shared/ToolBar/styles';
import { MoreIcon } from '../BookingsBody/styled';

type BookingsHeaderProps = {
  tabs: BOOKING_TAB[];
  services: Product[];
  filtersControl: FiltersControl<BOOKINGS_FILTER_TYPES, BOOKINGS_MORE_OPTIONS_TYPES>;
  middleAction?: JSX.Element;
};

const BookingsHeader: FC<BookingsHeaderProps> = ({ tabs, services, filtersControl, middleAction }) => {
  const { mobile } = useMediaQuery({ mobile: true });
  const { headerAction: filtersHeaderAction } = filtersControl;
  const isFiltered = filtersControl.selectedFiltersItems.length > 0;

  const HeaderViewSettings = useCallback(() => {
    const selectedItemsForm = useForm({
      defaultValues: {
        viewSettings: vars.activeViewSettings()?.record ? [vars.activeViewSettings().record].flat() : [BOOKING_RECORD_VIEW_SETTINGS[0].id]
      }
    });
    const { watch } = selectedItemsForm;
    const watchedValues = watch('viewSettings');

    useEffect(() => {
      vars.activeViewSettings({ record: watchedValues });
    }, [watchedValues]);

    return (
      <OptionDropdown
        menuButtonType={OPTION_DROPDOWN_MENU_BUTTON_TYPES.MORE_BLACK}
        width="28px"
        height="28px"
        selectedItemsForm={selectedItemsForm}
        menuPosition={mobile ? OPTION_DROPDOWN_MENU_POSITIONS.RIGHT : OPTION_DROPDOWN_MENU_POSITIONS.LEFT}
        options={[
          {
            title: 'View options',
            id: 'viewSettings',
            optionType: OPTION_DROPDOWN_TYPES.CHECKBOX,
            items: BOOKING_RECORD_VIEW_SETTINGS.map(({ id, title }) => ({
              value: id,
              name: title
            }))
          }
        ]}
        noApplyButton
        mobileDisplayIcon
      />
    );
  }, []);

  const [selectedServices] = useURLSearchParams('services');
  const icons = useIcons();
  const tabsValues = tabs.map(tab => tab.value);
  const tabValue = tabsValues?.find(tab => tab === location.pathname.split('/')[3]) || BOOKING_TABS_TYPES.CONFIRMED;
  const selectedTab = tabs.find(tab => tab.value === tabValue);
  const isNew = selectedTab?.value === BOOKING_TABS_VALUES_TYPES.NEW;

  const headerAction = (
    <>
      {filtersHeaderAction}
      {middleAction}
      {!isNew && <HeaderViewSettings />}
    </>
  );

  const { onChange: onSearch } = getFilterKeyOptions({
    filtersControl,
    keys: {
      filterType: BOOKINGS_FILTER_TYPES.BOOKING_SEARCH,
      moreOption: BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_SEARCH
    }
  });

  const isConfirmed = selectedTab?.value === BOOKING_TABS_VALUES_TYPES.CONFIRMED;
  const isMultiStaffView = useReactiveVar(vars.isMultiStaffView) && isConfirmed;
  const [isClicked, setIsClicked] = useState(isMultiStaffView);
  const viewSewitchOn = icons?.viewSwitchOn?.childImageSharp.gatsbyImageData;
  const viewSewitchOff = icons?.viewSwitchOff?.childImageSharp.gatsbyImageData;
  const isAllServices = !selectedServices?.length;

  const { data: { getBranchBusUsers: busUsers = [] } = {} } = useQuery<{ getBranchBusUsers: BusUserProfile[] }>(GetAllBranchBusUsers);

  const handleClick = () => {
    setIsClicked(!isClicked);
    debounce(() => {
      vars.isMultiStaffView(!isClicked);
    }, 300);
  };

  const { data: { getProducts: newTabServices = [] } = {} } = useQuery<{ getProducts: Product[] }>(GetAllServices);

  const isUpcoming = selectedTab?.value === BOOKING_TABS_VALUES_TYPES.UPCOMING;
  const isCanceled = selectedTab?.value === BOOKING_TABS_VALUES_TYPES.CANCELED;
  const isPayment = selectedTab?.value === BOOKING_TABS_VALUES_TYPES.PAYMENT;
  const toolBarButtonsConfirmed = isConfirmed && !isMultiStaffView && services;

  const toolBarButtonsStaff = isConfirmed && isMultiStaffView && busUsers;

  const toolBarButtonsNew = selectedTab?.value === BOOKING_TABS_VALUES_TYPES.NEW && services;

  const toolBarButtonsUpcomiung = isUpcoming && services;

  // const toolBarButtonsMultiDay = selectedTab?.value === BOOKING_TABS_VALUES_TYPES.MULTI_DAY && multiDayToolBarButtons;

  const toolBarButtons = toolBarButtonsConfirmed || toolBarButtonsNew || toolBarButtonsStaff || toolBarButtonsUpcomiung || [];

  const urlSearchParam = (isConfirmed && !isMultiStaffView) || isNew || isUpcoming ? 'services' : isMultiStaffView ? 'staff' : 'status';

  return (
    <>
      {isConfirmed && !mobile && (
        <>
          <Header>
            <BookingsNavigation />
            <ViewSwitchContainer onClick={handleClick}>
              {!isClicked ? (
                <GatsbyImage image={viewSewitchOn} alt="viewSewitchOn" style={{ cursor: 'pointer' }} />
              ) : (
                <GatsbyImage image={viewSewitchOff} alt="viewSewitchOff" style={{ cursor: 'pointer' }} />
              )}
            </ViewSwitchContainer>
          </Header>
          <Divider />
        </>
      )}
      {!mobile && <ToolBar toolBarButtons={toolBarButtons} urlSearchParam={urlSearchParam} onSearchChange={onSearch} middleAction={headerAction} displayDivider={true} />}

      {mobile && !isNew && !isCanceled && !isPayment && (
        <div style={{ zIndex: 3 }}>
          <ToolBar
            toolBarButtons={toolBarButtons}
            urlSearchParam={urlSearchParam}
            onSearchChange={onSearch}
            middleAction={headerAction}
            mobileMiddleAction={!isNew && <HeaderViewSettings />}
            selectOnlyOne={mobile}
            displayAll={isConfirmed}
            noMobileBoxShadow={isConfirmed}
            displayDivider={true}
          />
        </div>
      )}

      {mobile && (isNew || isCanceled || isPayment) && <SearchBar onSearch={onSearch} showAlways />}

      {!isMultiStaffView && ((!isConfirmed && !isPayment) || (isConfirmed && isAllServices)) && !mobile && !isFiltered && (
        <TabsHeaderContainer padding="12px 20px" mobilePadding="12px 16px 0 16px" boxShadow>
          <BookingTagsQuickFilter filtersControl={filtersControl} />
        </TabsHeaderContainer>
      )}

      {mobile && isConfirmed && (
        <TabsHeaderContainer padding="12px 20px" mobilePadding="0 0 0 16px" MobileBoxShadow>
          <BookingTagsQuickFilter filtersControl={filtersControl} />
        </TabsHeaderContainer>
      )}

      {/* <Divider /> */}
      {!mobile && isFiltered && (
        <>
          <FlexRow width="100%" gap={10} padding="0 0 0 20px">
            <RecordBody width="60" flex="unset" fontSize={14} fontWeight="700">
              Filter by
            </RecordBody>
            <VerticalDivider />
            <Filters filtersControl={filtersControl} />
          </FlexRow>
          <Divider />
        </>
      )}
    </>
  );
};

export default BookingsHeader;
