import { useMutation } from '@apollo/client';
import React, { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ModalDialog, { ModalLayout } from '../../../components/Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../../../components/Modal/styled';
import { PetBehavior } from '../../../components/Pets/types';
import { FormButtonsContainer, FormError, FormInput, FormLabel, FormSubmitButton, InputsWrapper, WideInputGroup } from '../../../components/Shared/Forms/Forms';
import { Container } from '../../../components/Shared/Shared';
import { EditPetBehavior } from '../../../queries';

type BehaviorPetRecordModalFormProps = {
  onSubmit: () => void;
  petBehavior: PetBehavior;
  loading: boolean;
  error: boolean;
  formOptions: ReturnType<typeof useForm>;
};

const RoutinePetRecordModalForm: FC<BehaviorPetRecordModalFormProps> = ({ loading, error, petBehavior, formOptions, onSubmit }) => {
  const { control, errors, setValue } = formOptions;

  const petSleepingPlace = petBehavior?.sleep_place;
  const petFavoriteTreats = petBehavior?.favorite_treats;
  const petFavoriteActivities = petBehavior?.favorite_activities;
  const petWalkingPlaces = petBehavior?.walking_places;
  const petDailyRoutine = petBehavior?.daily_routine;
  const petLeftAloneDuration = petBehavior?.left_alone_duration;

  return (
    <ModalLayout
      compact
      buttons={[
        <FormSubmitButton error={error} loading={loading} onClick={onSubmit}>
          Update
        </FormSubmitButton>
      ]}
    >
      <WideInputGroup>
        <FormLabel>Sleeping Place</FormLabel>

        <Controller as={<FormInput error={errors.petSleepingPlace} type={'string'} flexBasis={78} />} control={control} name={'petSleepingPlace'} defaultValue={petSleepingPlace || ''} />
        {errors.petSleepingPlace && <FormError>{errors.petSleepingPlace.message || 'Pet Sleeping Place is required'}</FormError>}
      </WideInputGroup>

      <WideInputGroup>
        <FormLabel>Favorite Treats</FormLabel>

        <Controller as={<FormInput error={errors.petFavoriteTreats} type={'string'} flexBasis={78} />} control={control} name={'petFavoriteTreats'} defaultValue={petFavoriteTreats || ''} />

        {errors.petFavoriteTreats && <FormError>{errors.petFavoriteTreats.message || 'Pet Favorite Treats is required'}</FormError>}
      </WideInputGroup>

      <WideInputGroup>
        <FormLabel>Favorite Activities</FormLabel>

        <Controller
          as={<FormInput error={errors.petFavoriteActivities} type={'string'} flexBasis={78} />}
          control={control}
          name={'petFavoriteActivities'}
          defaultValue={petFavoriteActivities || ''}
        />
        {errors.petFavoriteActivities && <FormError>{errors.petFavoriteActivities.message || 'Pet Favorite Activities is required'}</FormError>}
      </WideInputGroup>

      <WideInputGroup>
        <FormLabel>Walking Places</FormLabel>

        <Controller as={<FormInput error={errors.petWalkingPlaces} type={'string'} flexBasis={78} />} control={control} name={'petWalkingPlaces'} defaultValue={petWalkingPlaces || ''} />
        {errors.petWalkingPlaces && <FormError>{errors.petWalkingPlaces.message || 'Pet Walking Places is required'}</FormError>}
      </WideInputGroup>

      <WideInputGroup>
        <FormLabel>Daily Routine</FormLabel>
        <Controller as={<FormInput error={errors.petDailyRoutine} type={'string'} flexBasis={78} />} control={control} name={'petDailyRoutine'} defaultValue={petDailyRoutine || ''} />
        {errors.petDailyRoutine && <FormError>{errors.petDailyRoutine.message || 'Pet Daily Routine is required'}</FormError>}
      </WideInputGroup>

      <WideInputGroup>
        <FormLabel>Can be left alone for</FormLabel>
        <Controller as={<FormInput error={errors.petLeftAloneDuration} type={'string'} flexBasis={78} />} control={control} name={'petLeftAloneDuration'} defaultValue={petLeftAloneDuration || ''} />
        {errors.petLeftAloneDuration && <FormError>{errors.petLeftAloneDuration.message || 'Pet Left Alone Duration is required'}</FormError>}
      </WideInputGroup>
    </ModalLayout>
  );
};

const RoutinePetRecordModal = ({ petBehavior }: { petBehavior: PetBehavior }) => {
  const formOptions = useForm();
  const { handleSubmit } = formOptions;

  const [handleEditPetRecord, { data: editPetBehaviorData, loading: loadingEditPerBehavior, error: errorEditPetBehavior }] = useMutation(EditPetBehavior);

  const onSubmit = handleSubmit(form => {
    handleEditPetRecord({
      variables: {
        id: petBehavior?.id,
        sleep_place: form.petSleepingPlace,
        favorite_treats: form.petFavoriteTreats,
        favorite_activities: form.petFavoriteActivities,
        walking_places: form.petWalkingPlaces,
        daily_routine: form.petDailyRoutine,
        left_alone_duration: form.petLeftAloneDuration
      }
    });
  });

  useEffect(() => {
    if (editPetBehaviorData?.editPetBehavior?.id) {
      ModalDialog?.closeModal();
    }
  }, [editPetBehaviorData?.editPetBehavior?.id]);

  const loading = loadingEditPerBehavior;
  const error = !!errorEditPetBehavior?.message;

  return <RoutinePetRecordModalForm onSubmit={onSubmit} loading={loading} error={error} petBehavior={petBehavior} formOptions={formOptions} />;
};

export default RoutinePetRecordModal;
