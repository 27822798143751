import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { GetBranchOrderSubscriptionsCounts } from '../../queries';
import { getBranchCurrencySymbol } from '../../utils/getBranchCurrencySymbol';
import { numberWithCommas } from '../../utils/helpers';
import { Product } from '../Store/types';
import {
  ContentWrapper,
  SubscriptionsDashboardContainer,
  SubscriptionsDashboardItem,
  SubscriptionsDashboardItemSubtitle,
  SubscriptionsDashboardItemTitle,
  SubscriptionsDashboardWrapper
} from './styled';
import { SUBSCRIPTION_COLLECTION_STATUS_TYPES, SUBSCRIPTION_STATUS_TYPES, SubscriptionCounts } from './types';

const SubscriptionsHeaderDashboard = () => {
  const [selectedHeaderProduct, setSelectedHeaderProduct] = useState<string | null>(null);
  const { data: { getBranchOrderSubscriptionsCounts: subscriptionCounts } = {} } = useQuery<{
    getBranchOrderSubscriptionsCounts: {
      counts: SubscriptionCounts;
      subscriptionsProductsCounts: {
        Product: Product;
        counts: SubscriptionCounts;
      }[];
    };
  }>(GetBranchOrderSubscriptionsCounts, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      setSelectedHeaderProduct(data?.getBranchOrderSubscriptionsCounts?.subscriptionsProductsCounts?.[0]?.Product?.id || null);
    },
    variables: {
      status: [SUBSCRIPTION_STATUS_TYPES.CONFIRMED],
      collectionStatus: [SUBSCRIPTION_COLLECTION_STATUS_TYPES.ACTIVE]
    }
  });

  const { counts, subscriptionsProductsCounts: productCounts } = subscriptionCounts || {};

  const selectedHeaderProductData = productCounts?.find(product => product.Product.id === selectedHeaderProduct);
  const selectedHeaderProductCounts = selectedHeaderProductData?.counts;

  const switchProduct = () => {
    const selectedProductIndex = productCounts?.findIndex(product => product.Product.id === selectedHeaderProduct) || 0;
    const nextIndex = selectedProductIndex === (productCounts?.length || 0) - 1 ? 0 : selectedProductIndex + 1;
    const productId = productCounts?.[nextIndex]?.Product?.id || null;
    setSelectedHeaderProduct(productId);
  };

  if (!counts?.totalOrderSubscriptionsCount) {
    return null;
  }

  return (
    <SubscriptionsDashboardContainer>
      <SubscriptionsDashboardWrapper>
        <SubscriptionsDashboardItem>
          <ContentWrapper>
            <SubscriptionsDashboardItemTitle>Memberships</SubscriptionsDashboardItemTitle>
            <SubscriptionsDashboardItemSubtitle>{numberWithCommas(counts?.totalOrderSubscriptionsCount)}</SubscriptionsDashboardItemSubtitle>
          </ContentWrapper>
        </SubscriptionsDashboardItem>
        <SubscriptionsDashboardItem>
          <ContentWrapper>
            <SubscriptionsDashboardItemTitle>Active</SubscriptionsDashboardItemTitle>
            <SubscriptionsDashboardItemSubtitle>{numberWithCommas(counts?.activeOrderSubscriptionsCount)}</SubscriptionsDashboardItemSubtitle>
          </ContentWrapper>
        </SubscriptionsDashboardItem>
        <SubscriptionsDashboardItem>
          <ContentWrapper>
            <SubscriptionsDashboardItemTitle>On Trial</SubscriptionsDashboardItemTitle>
            <SubscriptionsDashboardItemSubtitle>{numberWithCommas(counts?.onTrialSubscriptionsCount)}</SubscriptionsDashboardItemSubtitle>
          </ContentWrapper>
        </SubscriptionsDashboardItem>
        <SubscriptionsDashboardItem onClick={switchProduct} flexOne clickable>
          <ContentWrapper>
            <SubscriptionsDashboardItemTitle>Breakdown</SubscriptionsDashboardItemTitle>
            <SubscriptionsDashboardItemSubtitle>
              {selectedHeaderProductData?.Product.name}
              <span>{`${numberWithCommas(selectedHeaderProductCounts?.totalOrderSubscriptionsCount)} | ${getBranchCurrencySymbol()}${numberWithCommas(
                selectedHeaderProductCounts?.totalOrderSubscriptionsAmount
              )}`}</span>
            </SubscriptionsDashboardItemSubtitle>
          </ContentWrapper>
        </SubscriptionsDashboardItem>
        <SubscriptionsDashboardItem>
          <ContentWrapper>
            <SubscriptionsDashboardItemTitle>Estimated MRR</SubscriptionsDashboardItemTitle>
            <SubscriptionsDashboardItemSubtitle>
              {getBranchCurrencySymbol()}
              {numberWithCommas(counts?.totalOrderSubscriptionsAmount)}
            </SubscriptionsDashboardItemSubtitle>
          </ContentWrapper>
        </SubscriptionsDashboardItem>
      </SubscriptionsDashboardWrapper>
    </SubscriptionsDashboardContainer>
  );
};

export default SubscriptionsHeaderDashboard;
