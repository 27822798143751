import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import React, { useCallback, useEffect, useRef } from 'react';
import ModalDialog from '../components/Modal/ModalDialog';
import PetTagsModal from '../components/Pets/PetTagsModal';
import ReportActionModal from '../components/Pets/ReportActionModal';
import { Pet } from '../components/Pets/types';
import { checkAddPetTagCapacity } from '../components/Pets/utils';
import NewBookingModal from '../components/Shared/Modals/NewBookingModal/NewBookingModal';
import { AddBranchPetRecordItemTag, AddPetToBranch, EditBranchPetRecordConnectionStatus, GetBranchPetRecordTags, GetBranchPets, GetBusUserProfile } from '../queries';
import { vars } from '../reactive';
import { hideCalendarActionMessage } from '../reactive/actions';
import client from '../utils/client';
import { RequireAtLeastOne } from '../utils/helpers';
import { Booking } from '../views/Bookings/types';
import { BranchPetRecordTag } from '../views/Store/BranchTags/types';
import Common from '../views/Store/Common';
import { BusUserProfile } from '../components/Profile/types';

type usePetRecordOptionsProps = RequireAtLeastOne<
  {
    quickTags?: BranchPetRecordTag[];
    pets: Pet[];
    loadTags?: boolean;
    orderId?: string;
    refetchPetsAfterTag?: boolean;
    defaultAppointments?: any[];
    afterTag?: (newPets: Pet[]) => void;
    defaultProductsIds?: string[];
    onClickAction?: () => void;
    displayEditPetTags?: boolean;
  },
  'quickTags' | 'loadTags'
>;

export default function usePetRecordOptions({
  quickTags,
  pets,
  loadTags = false,
  orderId,
  refetchPetsAfterTag,
  defaultAppointments = [],
  afterTag,
  defaultProductsIds,
  onClickAction,
  displayEditPetTags = true
}: usePetRecordOptionsProps) {
  const { data: { getBusUserProfile: userProfile } = {} } = useQuery<{ getBusUserProfile: BusUserProfile }>(GetBusUserProfile, {
    fetchPolicy: 'cache-only'
  });

  const allPetsTags = pets?.flatMap(pet => pet?.PetRecord?.BranchPetRecordTags);
  const allPetsUniqueTags = [...new Set(allPetsTags?.map(item => item?.id))].map(id => allPetsTags?.find(item => item?.id === id)!);
  const existingTagsIds = allPetsUniqueTags?.map(tag => tag?.id);

  const [addTag, { data: addedTag, loading: loadingAddPetTag }] = useMutation(AddBranchPetRecordItemTag);

  const [editConnectionStatus, { data: editedConnectionStatus, loading: loadingEditConnectionStatus }] = useMutation(EditBranchPetRecordConnectionStatus, {
    refetchQueries: ['getBranchPetsViews', 'getBranchPetProfile', 'getAppUserById']
  });

  const [addPetToBranch, { loading: loadingAddPetToBranch }] = useMutation(AddPetToBranch, {
    refetchQueries: ['getBranchPetsViews', 'getBranchPetProfile', 'getAppUserById']
  });

  const [refetchTagsById, { data: refetchedTags, loading: loadingRefetchTags }] = useLazyQuery<{ getBranchPetRecordTags: BranchPetRecordTag[] }>(GetBranchPetRecordTags, {
    fetchPolicy: 'cache-and-network'
  });
  const [getTags, { data: { getBranchPetRecordTags: tags = [] } = {} }] = useLazyQuery<{ getBranchPetRecordTags: BranchPetRecordTag[] }>(GetBranchPetRecordTags, {
    fetchPolicy: 'cache-and-network',
    variables: { offset: 0, limit: 1000, quick_action: true }
  });

  useEffect(() => {
    if (loadTags && !quickTags?.length) {
      getTags();
    }
  }, [loadTags, getTags]);

  const currentTagId = useRef<string>('');

  const handleQuickTagClick = useCallback(
    (tag: BranchPetRecordTag) => {
      checkAddPetTagCapacity({
        handleAddTag: () => {
          addTag({ variables: { PetRecordId: pets?.map(pet => pet?.PetRecord?.id), BranchPetRecordTagId: [tag.id] } });
          currentTagId.current = tag.id;
        },
        pets,
        tags: [tag]
      });
    },
    [addTag, pets]
  );

  useEffect(() => {
    if (addedTag) {
      refetchTagsById({ variables: { id: [currentTagId.current] } });
    }
  }, [addedTag, refetchTagsById]);

  const refetchPets = Common.get<() => Promise<{ data: { getBranchPets: Pet[] } }>>(`Pets.GetBranchPets.refetch`);
  const isPetsTab = window.location.pathname.includes('pets');

  useEffect(() => {
    if (!refetchedTags) {
      return;
    }

    (async () => {
      let newPets: Pet[] = [];
      if (pets?.length) {
        const { data: { getBranchPets: updatedPets = [] } = {} } = await client.query<{ getBranchPets: Pet[] }>({
          query: GetBranchPets,
          variables: { pet_id: pets.map(({ id }) => id), requisite_queries: ['pet_id'], alternative_queries: [], offset: 0, limit: 1000 }
        });
        newPets = updatedPets;
      }
      afterTag?.(newPets);
    })();

    // if (refetchPetsAfterTag && refetchPets) {
    //   refetchPets();
    // }
  }, [refetchedTags, refetchPetsAfterTag, refetchPets, afterTag, pets]);

  const defaultAppUserId = pets?.[0]?.AppUser?.id;
  const allPetsSameAppUser = pets?.every(pet => pet?.AppUser?.id === defaultAppUserId);

  const branchConnectStatus = pets?.[0]?.PetRecord?.BranchPetRecordConnections?.find(({ BranchId }) => BranchId === userProfile?.Branch?.id);

  const isArchived = branchConnectStatus?.status === 'ARCHIVED';

  const items = [
    allPetsSameAppUser && {
      name: 'New booking',
      value: 'newBooking',
      onClick: () => {
        const defaultPets = pets.map(pet => pet?.id);
        vars.newBookingDefaultAppointments(defaultAppointments);
        ModalDialog.openModal({
          content: () => <NewBookingModal defaultValues={{ pets: defaultPets, appUserId: defaultAppUserId }} />,
          title: 'New booking',
          onClose() {
            vars.newBookingDefaultAppointments([]);
            vars.selectedDate(null);
            hideCalendarActionMessage();
          },
          onCloseBySave: () => {
            onClickAction?.();
            setTimeout(() => {
              const refetchAppointments = Common.get<() => Promise<{ data: { getBranchAppointments: Booking[] } }>>(`Bookings.GetBranchAppointments.refetch`);
              refetchAppointments?.();
              vars.selectedDate(null);
            }, 3000);
          }
        });
      }
    },
    allPetsSameAppUser && {
      name: 'New Order',
      value: 'newOrder',
      onClick: () => {
        const defaultPets = pets.map(pet => pet?.id);
        ModalDialog.openModal({
          content: () => <NewBookingModal defaultValues={{ pets: defaultPets, appUserId: defaultAppUserId }} productModalType defaultProductsIds={defaultProductsIds} />,
          title: 'New Order',
          onClose() {
            vars.newBookingDefaultAppointments([]);
            vars.selectedDate(null);
            hideCalendarActionMessage();
          },
          onCloseBySave: onClickAction
        });
      }
    },
    pets?.length === 1 &&
      !!branchConnectStatus && {
        name: 'New Report',
        value: 'addReport',
        onClick: () => ModalDialog.openModal({ content: () => <ReportActionModal petProfile={pets[0]} orderId={orderId} />, title: 'Add Report' })
      },
    pets?.length === 1 &&
      !!branchConnectStatus &&
      isPetsTab && {
        name: isArchived ? 'Unarchive' : 'Archive',
        value: 'archivePet',
        onClick: () => editConnectionStatus({ variables: { id: pets[0]?.PetRecord?.id, status: isArchived ? 'ACTIVE' : 'ARCHIVED' } })
      },

    pets?.length === 1 &&
      !branchConnectStatus && {
        name: 'Add to Branch',
        value: 'addToBranch',
        onClick: () => addPetToBranch({ variables: { id: pets[0]?.id } })
      },

    ...(pets?.length === 1
      ? [
          displayEditPetTags && {
            name: pets.length > 1 ? 'Tag all Pets' : existingTagsIds?.length ? 'Tags' : 'Add Pet Tag',
            value: 'addTag',
            onClick: () =>
              ModalDialog.openModal({
                content: () => <PetTagsModal pets={pets} refetchPetsAfterTag={refetchPetsAfterTag} afterTag={afterTag} />,
                title: pets.length > 1 ? 'Tag all Pets' : `${pets?.[0]?.name}'s Tags`,
                onCloseBySave: onClickAction,
                isMini: true
              })
          }
        ]
      : []),
    ...(isPetsTab
      ? (quickTags || tags || [])
          ?.filter(({ id }) => !existingTagsIds.includes(id))
          .map(tag => ({
            name: tag.name,
            value: tag.id,
            onClick: () => handleQuickTagClick(tag)
          }))
      : [])
  ].filter(Boolean) as { name: string; value: string; onClick: () => void }[];

  const loading = loadingAddPetTag || loadingRefetchTags || loadingEditConnectionStatus || loadingAddPetToBranch;
  return { items, loading };
}
