import styled, { css } from 'styled-components';
import Colors from '../../../Colors';

export const ProductTitle = styled.h3`
  font-size: 22px;
  font-weight: 800;
  margin-bottom: 25px;
`;
export const ProductPrice = styled.p`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 25px;
`;
export const MorePhotosIndicator = styled.div`
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: ${Colors.grey};
  opacity: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: ${Colors.primary};
`;
export const ProductImageContainer = styled.div<{ hasMorePhotos?: boolean }>`
  position: relative;
  width: 312px;
  height: 209px;
  border-radius: 10px;
  margin-bottom: 25px;
  ${props =>
    props.hasMorePhotos &&
    css`
      &:hover {
        filter: grayscale(0.5);
      }
      &:hover > ${MorePhotosIndicator} {
        opacity: 1;
      }
    `}
`;
export const ProductImage = styled.img`
  width: 312px;
  height: 209px;
  border-radius: 10px;
  border: solid 1px #e8e8e8;
  object-fit: cover;
`;
export const ProductDescription = styled.div`
  margin-bottom: 25px;
`;
