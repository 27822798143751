import { useMutation, useQuery } from '@apollo/client';
import React, { FC, useCallback, useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { sentenceCase } from 'sentence-case';
import { DRAWER_IDS } from '../../../../components/DrawerBar/types';
import ModalDialog, { ModalLayout } from '../../../../components/Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../../../../components/Modal/styled';
import { FlexColumnCenterContainer } from '../../../../components/Pets/styled';
import { BusUserProfile } from '../../../../components/Profile/types';
import { FormButtonsContainer, FormError, FormInput, FormLabel, FormSelect, FormSubmitButton, InputsWrapper, SectionLabel, WideInputGroup } from '../../../../components/Shared/Forms/Forms';
import { Container } from '../../../../components/Shared/Shared';
import { ActivityIndicator } from '../../../../components/Shared/Spinner';
import useIcons from '../../../../hooks/useIcons';
import usePhotoUpload, { useDeletePhotos } from '../../../../hooks/usePhotoUpload';
import useReduceAppointments from '../../../../hooks/useReduceAppointments';
import { GetBranchAppointments, GetAllBranchBusUsers, GetBranchPetProfile, GetBusUserProfile, NoteAdd, NoteEdit, WalkingReportAdd, WalkingReportEdit } from '../../../../queries';
import { vars } from '../../../../reactive';
import { toReadableDate } from '../../../../utils/dates';
import { BOOKING_STATUS_TYPES, BOOKING_TYPE } from '../../../Bookings/types';
import { ActionBtn } from '../../../Store/styled';
import NotePhotos from '../BinaryUpload';
import NotesMarkupEditor from '../NotesMarkupEditor';
import BinaryUpload from '../BinaryUpload';
import { MenuButton } from '../../../../components/Shared/Menus/OptionDropdown/styled';

const POO_SCALES = ['normal', 'constipated', 'mild_diarrhoea', 'sever_diarrhoea'];

export enum NOTE_ACTION_TYPES {
  ADD = 'ADD',
  EDIT = 'EDIT'
}

const WalkingReportNoteModal: FC<{
  selectedReport?: Record<string, any>;
  selectedNote?: Record<string, any>;
  refetchNotes?: ReturnType<typeof useQuery>['refetch'];
  refetchReports?: ReturnType<typeof useQuery>['refetch'];
}> = ({ selectedReport, selectedNote, refetchNotes, refetchReports }) => {
  const { data: { getBranchBusUsers: BusUsers = [] } = {} } = useQuery<{ getBranchBusUsers: BusUserProfile[] }>(GetAllBranchBusUsers);

  const [handleAddNote, { loading: loadingAddNote, error: errorNoteAdd }] = useMutation(NoteAdd);
  const [handleEditNote, { loading: loadingEditNote, error: errorNoteEdit }] = useMutation(NoteEdit);
  const noteIdRef = useRef<string>('');
  const photosRef = useRef<Record<string, any>>({});

  const uploadPhotos = usePhotoUpload();
  const [_, { loading: loadingUploadPhotos, error: errorUploadPhotos }] = uploadPhotos;
  const deletePhotos = useDeletePhotos();

  const { control, handleSubmit, errors } = useForm();

  const loading = loadingAddNote || loadingEditNote || loadingUploadPhotos;
  const error = !!errorNoteAdd?.message || !!errorNoteEdit?.message || !!errorUploadPhotos;

  const drawerBars = vars.drawerBars();
  const drawer = drawerBars.find(drawerBar => drawerBar.drawerId === DRAWER_IDS.PETS_DRAWER);
  const petId = drawer?.recordData;
  const orderId = drawer?.otherData?.orderId;

  // get pet profile
  const { data: { getBranchPetProfile: petProfile = {} } = {}, loading: loadingPetProfile } = useQuery(GetBranchPetProfile, {
    variables: { id: petId }
  });

  // get past pet appointments

  const { data: { getBranchAppointments: appUserAppointments = [] } = {}, loading: loadingAppUserAppointments } = useQuery(GetBranchAppointments, {
    variables: {
      PetRecordId: [petProfile?.PetRecord?.id],
      status: [BOOKING_STATUS_TYPES.CONFIRMED, BOOKING_STATUS_TYPES.REQUESTED],
      limit: 50,
      offset: 0,
      booking_type: [BOOKING_TYPE.SLOT, BOOKING_TYPE.MULTI_SLOT, BOOKING_TYPE.MULTI_DAY],
      requisite_queries: [],
      alternative_queries: []
    }
  });

  const reducedAppointments = useReduceAppointments(appUserAppointments || []);
  const [handleAddWalkingReport, { loading: loadingAdd, error: errorAdd, data: { walkingReportAdd: addedReport = null } = {} }] = useMutation(WalkingReportAdd);
  const [handleEditWalkingReport, { loading: loadingEdit, error: errorEdit, data: { walkingReportEdit: editedReport = null } = {} }] = useMutation(WalkingReportEdit);

  const updatedReport = addedReport || editedReport;

  const handleSaveNote = handleSubmit(async form => {
    if (selectedNote) {
      noteIdRef.current = selectedNote.id;
    }

    if (!selectedNote) {
      const { data: { noteAdd: addedNote = {} } = {} } = await handleAddNote({
        variables: {
          body: form,
          timestamp: new Date(),
          walkingReportId: updatedReport.id
        }
      });
      noteIdRef.current = addedNote.id;
    }
    const photos = await photosRef.current?.getPhotos();
    const attachments = await photosRef.current?.getAttachments();

    await handleEditNote({
      variables: {
        id: noteIdRef.current,
        body: {
          ...form,
          photos: {
            primary: photos
          },
          attachments: {
            primary: attachments
          }
        },
        timestamp: new Date()
      }
    });

    ModalDialog.closeModal();
    refetchNotes?.();
    refetchReports?.();
  });

  const handleSaveReport = handleSubmit(async form => {
    const { appointmentId } = form;
    if (selectedReport) {
      await handleEditWalkingReport({ variables: { ...form, id: selectedReport?.id, appointmentId: appointmentId || null, description: '' } });
    } else {
      await handleAddWalkingReport({ variables: { ...form, petRecordId: petProfile.PetRecord.id, appointmentId: appointmentId || null, description: '' } });
    }

    refetchReports?.();
  });

  const handleSubmitReport = useCallback(() => {
    handleSaveReport();
  }, [handleSaveNote, handleSaveReport]);

  useEffect(() => {
    if (updatedReport?.id) {
      handleSaveNote();
    }
  }, [updatedReport]);

  const defaultAppointment = appUserAppointments.find(appointment => appointment.OrderItem.Order.id === orderId) || {};

  if (loadingPetProfile || loadingAppUserAppointments) {
    return <ActivityIndicator />;
  }

  return (
    <ModalLayout
      compact
      buttons={[
        <FormSubmitButton error={error} loading={loading} onClick={handleSubmitReport}>
          Save note
        </FormSubmitButton>
      ]}
    >
      <WideInputGroup>
        <FormLabel>Name</FormLabel>
        <Controller as={<FormInput error={errors.name} />} control={control} name={'name'} defaultValue={selectedReport?.name || ''} rules={{ required: true }} rows={6} />
        {errors?.name && <FormError>{errors.name.message || 'name is required'}</FormError>}
      </WideInputGroup>
      <WideInputGroup>
        <FormLabel error={errors?.appointmentId}>Appointment</FormLabel>
        <Controller
          render={({ onChange, value }) => (
            <FormSelect
              height={48}
              fontSize={16}
              error={errors?.appointmentId}
              onChange={e => {
                onChange(e.target.value);
              }}
              value={value || ''}
            >
              <option value={''}>Not specified</option>
              {reducedAppointments?.map((orders, index) => (
                <option key={index} value={orders[0].id}>
                  {orders[0].OrderItem.item.name} - {toReadableDate(orders[0].timestamp || new Date())}
                </option>
              ))}
            </FormSelect>
          )}
          control={control}
          name={'appointmentId'}
          defaultValue={defaultAppointment?.id || selectedReport?.Appointment?.id || ''}
        />
      </WideInputGroup>

      <SectionLabel>Notes</SectionLabel>
      <WideInputGroup>
        <FormLabel>Notes</FormLabel>
        <NotesMarkupEditor control={control} defaultValue={selectedNote?.body?.notes?.notes} name="notes.notes" />
      </WideInputGroup>

      <FlexColumnCenterContainer>
        <SectionLabel>Day Summary</SectionLabel>
        <FormLabel>Walker</FormLabel>
      </FlexColumnCenterContainer>
      <WideInputGroup>
        <Controller
          render={({ onChange, value }) => (
            <FormSelect
              height={48}
              fontSize={16}
              name={'daySummary.walker.details'}
              onChange={e => {
                onChange(e.target.value);
              }}
              value={value || ''}
            >
              <option value={''}>-- No Staff Member --</option>
              {BusUsers.map(({ name }, index) => (
                <option key={index} value={name}>
                  {name}
                </option>
              ))}
            </FormSelect>
          )}
          control={control}
          name={'daySummary.walker.details'}
          defaultValue={selectedNote?.body?.daySummary?.walker?.details || ''}
        />
      </WideInputGroup>
      <WideInputGroup>
        <FormLabel>Walks</FormLabel>
        <Controller as={<FormInput type={'number'} />} name={`daySummary.walks.details`} control={control} defaultValue={selectedNote?.body?.daySummary?.walks?.details || ''} />
      </WideInputGroup>
      <WideInputGroup>
        <FormLabel>Poos</FormLabel>
        <Controller as={<FormInput type={'number'} />} name={`daySummary.poos.details`} control={control} defaultValue={selectedNote?.body?.daySummary?.poos?.details || ''} />
      </WideInputGroup>
      {/* <WideInputGroup>
              <FormLabel>Poo Scale</FormLabel>
              <Controller
                render={({ onChange, value }) => (
                  <FormSelect name={'daySummary.pooScale.details'} onChange={e => onChange(e.target.value)} value={value} height={48} fontSize={16}>
                    {POO_SCALES.map(scale => (
                      <option key={scale} value={scale}>
                        {sentenceCase(scale)}
                      </option>
                    ))}
                  </FormSelect>
                )}
                control={control}
                name={'daySummary.pooScale.details'}
                defaultValue={selectedNote?.body?.daySummary?.pooScale?.details || ''}
              />
            </WideInputGroup> */}

      <BinaryUpload
        uploadPhotos={uploadPhotos}
        deletePhotos={deletePhotos}
        ref={photosRef}
        getRoute={() => `reports/primary/${noteIdRef.current}`}
        defaultBinaries={{
          attachments: selectedNote?.body?.attachments?.primary || [],
          photos: selectedNote?.body?.photos?.primary || []
        }}
      />
    </ModalLayout>
  );
};

const WalkingReportNoteAction: FC<{
  type: NOTE_ACTION_TYPES;
  note?: Record<string, any>;
  selectedReport: Record<string, any>;
  refetchNotes?: ReturnType<typeof useQuery>['refetch'];
  autoOpenModal?: boolean;
}> = ({ type, note, selectedReport, refetchNotes, autoOpenModal }) => {
  const icons = useIcons();
  const icon = type === NOTE_ACTION_TYPES.ADD ? icons.plusBlackSVG.publicURL : icons.moreIconSVG.publicURL;

  const showModal = useCallback(
    () =>
      ModalDialog.openModal({
        content: () => <WalkingReportNoteModal selectedNote={note} selectedReport={selectedReport} refetchNotes={refetchNotes} />,
        title: `${sentenceCase(type)} Walking Note`
      }),
    [note, selectedReport, refetchNotes, type]
  );

  useEffect(() => {
    if (autoOpenModal) {
      showModal();
    }
  }, [autoOpenModal, showModal]);

  return <MenuButton bgImage={icon} onClick={showModal} width="28px" height="28px" mobileDisplayIcon />;
};

export default WalkingReportNoteAction;
