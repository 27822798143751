import { useQuery, useReactiveVar } from '@apollo/client';
import React, { useEffect } from 'react';
import { Controller, ControllerRenderProps, useForm, useWatch } from 'react-hook-form';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { GetBranchAppUserTags, GetBranchTagCategories } from '../../../queries';
import { vars } from '../../../reactive';
import BranchTagsModal from '../../../views/Store/BranchTags/BranchTagsModal';
import { BRANCH_TAGS_TYPES, BranchAppUserTag } from '../../../views/Store/BranchTags/types';
import ModalDialog from '../../Modal/ModalDialog';
import { getFilterKeyOptions } from '../../Shared/Filters/helpers';
import { AddPetTagButton, PetTagFilterPill, PetTagsQuickFilterContainer, TagsOptionsContainer, TagsQuickFiltersWrapper } from '../../Shared/Filters/styled';
import { FiltersControl } from '../../Shared/Filters/useFilters';
import { FormInput } from '../../Shared/Forms/Forms';
import OptionDropdown from '../../Shared/Menus/OptionDropdown/OptionDropdown';
import { OPTION_DROPDOWN_MENU_BUTTON_TYPES, OPTION_DROPDOWN_MENU_POSITIONS, OPTION_DROPDOWN_TYPES } from '../../Shared/Menus/OptionDropdown/types';
import { VerticallyCenteredLoader } from '../../Shared/Spinner';
import { CHATS_FILTER_TYPES, CHATS_MORE_OPTIONS_TYPES } from '../ChatsFilters';

const ChatTagItem = ({ form, item }: { form: ControllerRenderProps<Record<string, any>>; item: BranchAppUserTag }) => {
  const { onChange, value } = form;

  return (
    <PetTagFilterPill>
      <FormInput
        type={'checkbox'}
        onChange={e => {
          if (e.target.checked) {
            onChange([item.id]);
          } else {
            onChange([]);
          }
        }}
        checked={value.includes(item.id)}
      />
      <span style={{ display: 'flex', gap: '10px' }}>
        {item.name}
        {/* <div>{item.appUsersCount}</div> */}
      </span>
    </PetTagFilterPill>
  );
};

type ChatTagsQuickFilterProps = {
  filtersControl: FiltersControl<CHATS_FILTER_TYPES, CHATS_MORE_OPTIONS_TYPES>;
};

const ChatTagsQuickFilter = ({ filtersControl }: ChatTagsQuickFilterProps) => {
  const { control } = useForm();
  const { mobile } = useMediaQuery({ mobile: true });
  const selectedCategoryId = useReactiveVar(vars.chatSelectedCategoryId);

  const selectedQuickFilters = useWatch<string[]>({
    control,
    name: 'selectedQuickFilters',
    defaultValue: []
  });

  const { data: { getBranchTagCategories: tagCategories = [] } = {}, loading: loadingTagCategories } = useQuery<{ getBranchTagCategories: { id: string; name: string }[] }>(GetBranchTagCategories);

  const {
    data: { getBranchAppUserTags: categoryTags = [] } = {},
    loading: loadingTagsByCategory,
    refetch: refetchTagsByCategory
  } = useQuery<{
    getBranchAppUserTags: BranchAppUserTag[];
  }>(GetBranchAppUserTags, {
    variables: {
      offset: 0,
      limit: 1000,
      BranchTagCategoryId: [selectedCategoryId],
      visibility: ['showOnBar']
    },
    skip: !selectedCategoryId,
    fetchPolicy: 'cache-and-network'
  });

  const { data: { getBranchAppUserTags: allQuickTags = [] } = {}, loading: loadingAllQuickTags } = useQuery<{
    getBranchAppUserTags: BranchAppUserTag[];
  }>(GetBranchAppUserTags, {
    fetchPolicy: 'cache-and-network',
    variables: {
      offset: 0,
      limit: 1000,
      visibility: ['showOnBar']
    }
  });

  const loading = loadingTagCategories || loadingTagsByCategory || loadingAllQuickTags;

  const quickTags = selectedCategoryId ? categoryTags : allQuickTags;

  const { onChange } = getFilterKeyOptions({
    filtersControl,
    keys: {
      filterType: CHATS_FILTER_TYPES.CHAT_QUICK_TAGS,
      moreOption: CHATS_MORE_OPTIONS_TYPES.CHAT_TAGS
    },
    transformValues: (values: string[]) => quickTags.filter(({ id }) => values.includes(id)).map(({ id, name }) => ({ value: id, name }))
  });

  useEffect(() => {
    onChange(selectedQuickFilters?.length ? selectedQuickFilters : null);
  }, [selectedQuickFilters]);

  return (
    <TagsQuickFiltersWrapper>
      {mobile && (
        <TagsOptionsContainer>
          <OptionDropdown
            menuButtonType={OPTION_DROPDOWN_MENU_BUTTON_TYPES.MORE}
            loading={loading}
            options={[
              {
                id: 'categories',
                optionType: OPTION_DROPDOWN_TYPES.BUTTONS,
                items: [
                  {
                    name: 'All',
                    value: 'all',
                    onClick: () => vars.chatSelectedCategoryId(null),
                    green: !selectedCategoryId
                  },
                  ...tagCategories.map(({ id, name }) => ({
                    name,
                    value: id,
                    onClick: () => vars.chatSelectedCategoryId(id),
                    green: selectedCategoryId === id
                  }))
                ]
              }
            ]}
            noApplyButton
            buttonLoading={loading}
            menuPosition={OPTION_DROPDOWN_MENU_POSITIONS.RIGHT}
          />
        </TagsOptionsContainer>
      )}

      <PetTagsQuickFilterContainer>
        {loading && <VerticallyCenteredLoader size={25} />}
        {!loading && (
          <>
            <Controller
              name={`selectedQuickFilters`}
              control={control}
              defaultValue={[]}
              render={form => (
                <>
                  {quickTags?.map((item, index) => (
                    <ChatTagItem key={index} form={form} item={item as BranchAppUserTag} />
                  ))}
                </>
              )}
            />

            {!mobile && (
              <AddPetTagButton
                onClick={() =>
                  ModalDialog.openModal({
                    content: () => (
                      <BranchTagsModal
                        presetType={BRANCH_TAGS_TYPES.APP_USER}
                        defaultCategoryId={selectedCategoryId}
                        onAddTag={() => (selectedCategoryId ? refetchTagsByCategory()?.then(ModalDialog.closeModal) : null)}
                      />
                    ),
                    title: 'Add New Smart Tag'
                  })
                }
              >
                <span>+ Add</span>
              </AddPetTagButton>
            )}
          </>
        )}
      </PetTagsQuickFilterContainer>
      {!mobile && (
        <TagsOptionsContainer>
          <OptionDropdown
            menuButtonType={OPTION_DROPDOWN_MENU_BUTTON_TYPES.MORE}
            loading={loading}
            options={[
              {
                id: 'categories',
                optionType: OPTION_DROPDOWN_TYPES.BUTTONS,
                items: [
                  {
                    name: 'All',
                    value: 'all',
                    onClick: () => vars.chatSelectedCategoryId(null),
                    green: !selectedCategoryId
                  },
                  ...tagCategories.map(({ id, name }) => ({
                    name,
                    value: id,
                    onClick: () => vars.chatSelectedCategoryId(id),
                    green: selectedCategoryId === id
                  }))
                ]
              }
            ]}
            noApplyButton
            buttonLoading={loading}
          />
        </TagsOptionsContainer>
      )}
    </TagsQuickFiltersWrapper>
  );
};

export default ChatTagsQuickFilter;
