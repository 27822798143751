import React from 'react';
import { InputsWrapper } from '../../../../components/Shared/Forms/Forms';
import { StoreProductModalSectionsCommonProps } from './types';
import Customizations from '../../Customizations/Customizations';

const StoreProductModalCustomizations = ({ formOptions, defaultValues, type }: StoreProductModalSectionsCommonProps) => {
  const { control, errors } = formOptions;
  const isService = type === 'service';

  return (
    <>
      <Customizations
        formOptions={formOptions}
        defaultValues={defaultValues?.customizations}
        {...(!isService && {
          hideRules: {
            ASSIGN: true,
            DURATION: true
          }
        })}
      />
    </>
  );
};

export default StoreProductModalCustomizations;
