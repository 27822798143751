import React from 'react';
import ModalDialog from '../../../components/Modal/ModalDialog';
import { HoverRecordOptionsContainer, RecordBody, RecordDataContainer } from '../../styled';
import BusUserModal from '../BusUsers/BusUserModal';
import { BranchRoom } from './types';
import BranchRoomModal from './BranchRoomModal';
import { Booking } from '../../Bookings/components/BookingsBody/styled';
import { BtnContainer } from '../../../components/Shared/ToolBar/styles';
import { GatsbyImage } from 'gatsby-plugin-image';
import useIcons from '../../../hooks/useIcons';

const BranchRoomRecord = ({ branchRoom }: { branchRoom: BranchRoom }) => {
  const handleClick = () => {
    ModalDialog.openModal({
      content: () => <BranchRoomModal branchRoom={branchRoom} />,
      title: 'Pen',
      isMini: true
    });
  };
  const icons = useIcons();

  return (
    <Booking padding="6px 10px">
      <RecordBody fontWeight={'800'}>{branchRoom?.name}</RecordBody>
      <HoverRecordOptionsContainer>
        <BtnContainer onClick={handleClick}>
          <GatsbyImage image={icons?.moreIcon?.childImageSharp?.gatsbyImageData} alt="more" />
        </BtnContainer>
      </HoverRecordOptionsContainer>
    </Booking>
  );
};

export default BranchRoomRecord;
