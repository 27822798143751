import { useMutation } from '@apollo/client';
import React, { useCallback } from 'react';
import { RetryOrderPayment, UpdateOrderStatus } from '../../queries';
import { ORDER_STATUS, ProductOrder } from '../../views/Orders/types';
import { ButtonsContainer } from '../DrawerBar/styled';
import ModalDialog from '../Modal/ModalDialog';
import OptionDropdown, { OptionDropDownItem } from '../Shared/Menus/OptionDropdown/OptionDropdown';
import { OPTION_DROPDOWN_MENU_BUTTON_TYPES, OPTION_DROPDOWN_MENU_OVERLAY_POSITIONS, OPTION_DROPDOWN_TYPES } from '../Shared/Menus/OptionDropdown/types';
import RefundModal from '../Shared/Modals/RefundModal/RefundModal';
import { CenteredLoader } from '../Shared/Spinner';
import Alert from '../Alert/Alert';

const useProductOrderActions = ({ order, loading, refetch }: { order: ProductOrder; loading?: boolean; refetch?: () => {} }) => {
  const [retryPayment, { loading: loadingRetryPayment }] = useMutation(RetryOrderPayment);

  const shouldDisplayRetryPayment = !loading && [ORDER_STATUS.PAYMENT_PENDING, ORDER_STATUS.PAYMENT_REJECTED].includes(order.status);

  const shouldDisplayRefund = (!loading && !!order.payment.required && [ORDER_STATUS.PAYMENT_SUCCEEDED].includes(order.payment.status)) || [ORDER_STATUS.PARTIALLY_REFUNDED].includes(order.status);
  const shouldDisplayCancel = !loading;

  const shouldDisplayMarkAsPaid = !loading && [ORDER_STATUS.REQUESTED, ORDER_STATUS.PAYMENT_PENDING, ORDER_STATUS.PAYMENT_REJECTED].includes(order.status);

  const [updateOrderStatus, { loading: loadingUpdateOrderStatus }] = useMutation(UpdateOrderStatus, {
    refetchQueries: ['getBranchAppointments', 'getBranchAppointmentsViews', 'getBranchServiceOrdersViews']
  });

  const handleRetryPayment = useCallback(async () => {
    return retryPayment({ variables: { orderId: order?.id } })?.then(() => {
      refetch?.();
    });
  }, [retryPayment, order]);

  const handleMarkAsPaid = useCallback(async () => {
    Alert.alert({
      title: 'Mark Order as Paid',
      acceptButtonText: 'Yes, Mark as Paid',
      denyButtonText: 'No',
      description: 'Are you sure you want to mark this order as paid?',
      onAccept: () => {
        updateOrderStatus({ variables: { id: order?.id, status: 'CONFIRMED', payment_required: false } })?.then(() => {
          refetch?.();
        });
      }
    });
  }, [order]);

  const handleRefund = useCallback(() => {
    ModalDialog.openModal({
      content: () => <RefundModal order={order} shouldIssueRefund />,
      title: 'Refund Payment',
      onClose() {
        setTimeout(() => {
          refetch?.();
        }, 3000);
      }
    });
  }, []);

  const handleRejectOrder = () => {
    Alert.alert({
      title: 'Cancel Order',
      acceptButtonText: 'Yes',
      denyButtonText: 'No',
      description: 'Are you sure you want to cancel this order?',
      onAccept: () => {
        updateOrderStatus({ variables: { id: order.id, status: 'CANCELED' } });
      }
    });
  };
  const actions = {
    handleRetryPayment,
    handleRefund,
    handleCancel: handleRejectOrder,
    handleMarkAsPaid
  };

  const shouldDisplay = {
    shouldDisplayRetryPayment,
    shouldDisplayRefund,
    shouldDisplayMarkAsPaid,
    shouldDisplayCancel
  };

  const loadingAny = loadingRetryPayment || loadingUpdateOrderStatus;

  return {
    actions,
    shouldDisplay,
    loading: loadingAny
  };
};

const useProductOrderActionsList = ({ order, loading, refetch }: { order: ProductOrder; loading?: boolean; refetch?: () => {} }): { items: OptionDropDownItem[]; anyLoading: boolean } => {
  const {
    actions: productActions,
    shouldDisplay,
    loading: anyLoading
  } = useProductOrderActions({
    order: order,
    loading: loading,
    refetch: refetch
  });

  const items = [
    shouldDisplay.shouldDisplayRetryPayment && {
      name: 'Retry payment',
      value: 'retry',
      onClick: productActions.handleRetryPayment
    },
    shouldDisplay.shouldDisplayRefund && {
      name: 'Refund payment',
      value: 'refund',
      onClick: productActions.handleRefund
    },
    shouldDisplay.shouldDisplayMarkAsPaid && {
      name: 'Mark as Paid',
      value: 'markAsPaid',
      onClick: productActions.handleMarkAsPaid
    },
    shouldDisplay.shouldDisplayCancel && {
      name: 'Cancel',
      value: 'cancel',
      onClick: productActions.handleCancel
    }
  ].filter(Boolean) as OptionDropDownItem[];

  return {
    items,
    anyLoading: anyLoading
  };
};

const ProductOrderDrawerButtons = ({ order, loading, refetch }: { order: ProductOrder; loading?: boolean; refetch?: () => {} }) => {
  const { items, anyLoading } = useProductOrderActionsList({
    order: order,
    loading: loading,
    refetch: refetch
  });

  return (
    <ButtonsContainer>
      {loading && <CenteredLoader size={20} />}

      {!loading && (
        <OptionDropdown
          buttonLoading={anyLoading}
          menuButtonType={OPTION_DROPDOWN_MENU_BUTTON_TYPES.MENU}
          menuOverlayPosition={OPTION_DROPDOWN_MENU_OVERLAY_POSITIONS.DOWN}
          noApplyButton
          options={[
            {
              optionType: OPTION_DROPDOWN_TYPES.BUTTONS,
              id: 'productOrderOptions',
              items
            }
          ]}
        />
      )}
    </ButtonsContainer>
  );
};

export default ProductOrderDrawerButtons;
