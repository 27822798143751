import { useMutation } from '@apollo/client';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ModalDialog, { ModalLayout } from '../../../components/Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../../../components/Modal/styled';
import { Pet } from '../../../components/Pets/types';
import { FormButtonsContainer, FormError, FormLabel, FormSubmitButton, FormTextArea, InputsWrapper, WideInputGroup } from '../../../components/Shared/Forms/Forms';
import { EditPetBehavior } from '../../../queries';
import { Container } from './styled';

type FeedingRoutineFormState = {
  feeding_routine: string;
};

const HealthEditPetRecordFeedingRoutineModal = ({ petProfileBehavior, options }: { petProfileBehavior: Pet['PetRecord']['PetBehavior']; options: { includeWeight: boolean } }) => {
  const formOptions = useForm<FeedingRoutineFormState>({
    defaultValues: {
      feeding_routine: petProfileBehavior?.feeding_routine
    },
    shouldUnregister: false
  });
  const { handleSubmit, control, errors } = formOptions;

  const [editBehavior, { data, loading, error }] = useMutation(EditPetBehavior, {
    refetchQueries: ['getPetBehaviors'],
    awaitRefetchQueries: true
  });

  const onSubmit = handleSubmit(data => {
    editBehavior({
      variables: {
        id: petProfileBehavior?.id,
        feeding_routine: data.feeding_routine || null
      }
    });
  });

  useEffect(() => {
    if (data?.editPetBehavior) {
      ModalDialog.closeModal();
    }
  }, [data?.editPetBehavior]);

  return (
    <>
      <ModalLayout
        compact
        buttons={[
          <FormSubmitButton error={!!error?.message} loading={loading} onClick={onSubmit}>
            Update
          </FormSubmitButton>
        ]}
      >
        <WideInputGroup>
          <FormLabel>Feeding Routine</FormLabel>
          <Controller
            name="feeding_routine"
            control={control}
            render={({ onChange, value }) => <FormTextArea value={value} onChange={e => onChange(e.target.value)} placeholder="Feeding Routine" />}
            rules={{ required: true }}
          />
          {errors?.feeding_routine && <FormError>{errors?.feeding_routine?.message || 'This field is required'}</FormError>}
        </WideInputGroup>
      </ModalLayout>
    </>
  );
};

export default HealthEditPetRecordFeedingRoutineModal;
