import { Branch } from '../../../components/Profile/types';
import { ProviderBranchModalFormType } from './types';

export const getDeliveryOptionsDefaultValues = (deliveryOptions?: Branch['delivery_rules']) => {
  return (deliveryOptions || [])?.map(option => ({
    description: option.description,
    price: option.price,
    rule: {
      type: Object.keys(option.rule || {})[0] || 'none',
      value: Number(Object.values(option.rule || {})[0] || 0)
    }
  }));
};

export const getSelectedDeliveryOptions = (deliveryOptions: ProviderBranchModalFormType['delivery_rules']) => {
  return deliveryOptions?.map(option => ({
    description: option.description,
    price: option.price,
    rule:
      option.rule.type === 'none'
        ? {}
        : {
            [option.rule.type]: option.rule.value
          }
  }));
};
