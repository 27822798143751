import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useRef } from 'react';
import useIcons from '../../hooks/useIcons';
import { AppUserImage, AppUserImageContainer, AppUserImageNameContainer, AppUserName, RecordBody, RecordDataContainer } from '../../views/styled';
import { FlexRow } from '../Shared/Shared';
import { BillingStatus } from '../UserDrawer/styled';
import { OrderInvoice } from './types';
import { getOrderInvoiceTitles } from './utils';

const OrderInvoiceRecord = ({
  orderInvoice,
  navigateDrawer,
  children
}: {
  orderInvoice: OrderInvoice;
  navigateDrawer: ({ drawerLinkId }: { drawerLinkId: string }) => void;
  children: React.ReactNode;
}) => {
  const icons = useIcons();
  const optionsRef = useRef<HTMLDivElement>(null);

  const { creationDate, isBilled, isCanceled, isFree, isPending, isRefunded, isRejected, statusTitle, amountTitle, user, appUserName, orderInvoiceNumber, isUnbilled } =
    getOrderInvoiceTitles(orderInvoice);

  const handleClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    if (optionsRef?.current?.contains(e.target as Node)) {
      return;
    }
    navigateDrawer({ drawerLinkId: user?.id });
  };

  const hasCreditCard = !!user?.payment.method;

  const creditCardImage = getImage(icons.creditCard);

  const recordData = (
    <>
      <RecordBody width="150" fontWeight={'800'}>
        {orderInvoiceNumber || '-'}
      </RecordBody>
      <RecordBody width="80" fontWeight={'800'}>
        {creationDate}
      </RecordBody>
      <RecordBody width="150" fontWeight={'600'}>
        {amountTitle}
      </RecordBody>
      <RecordBody width="200" fontWeight="800" flexEnd noMargin>
        <BillingStatus noMaxWidth={true} blue={isFree || isPending} gray={isCanceled} green={isBilled} red={isRefunded || isRejected || isUnbilled}>
          {statusTitle}
        </BillingStatus>
      </RecordBody>
      <span ref={optionsRef}>{children}</span>
    </>
  );

  return (
    <RecordDataContainer onClick={handleClick}>
      <RecordBody>
        <AppUserImageNameContainer>
          <AppUserImageContainer>
            <AppUserImage src={user?.profile_pic || icons.user.childImageSharp.gatsbyImageData.images.fallback.src} index={0} />
          </AppUserImageContainer>
          <FlexRow gap={16}>
            <AppUserName>{appUserName}</AppUserName>
            {hasCreditCard && <GatsbyImage image={creditCardImage!} alt={'User has credit card'} />}
          </FlexRow>
        </AppUserImageNameContainer>
      </RecordBody>
      {recordData}
    </RecordDataContainer>
  );
};

export default OrderInvoiceRecord;
