import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { BranchChatRoomsViewCountsSubscription, GetBranchChatRoomsViewCounts, GetBusUserProfile } from '../queries';

export type GetBranchChatRoomsViewCountsReturn = {
  chatRoomsCount: number;
  unreadChatRoomsCount: number;
  readChatRoomsCount: number;
  appUserChatRoomsCount: number;
  appUserReadChatRoomsCount: number;
  appUserUnreadChatRoomsCount: number;
  busUserChatRoomsCount: number;
  busUserReadChatRoomsCount: number;
  busUserUnreadChatRoomsCount: number;
  shouldNotify: boolean;
};

const useBranchChatRoomsViewCounts = () => {
  const { data: { getBusUserProfile: profile = {} } = {} } = useQuery(GetBusUserProfile, {
    fetchPolicy: 'cache-only'
  });

  const { data: unreadOrdersCount, subscribeToMore } = useQuery<{ getBranchChatRoomsViewCounts: GetBranchChatRoomsViewCountsReturn }>(GetBranchChatRoomsViewCounts, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true
  });

  const {
    chatRoomsCount,
    unreadChatRoomsCount,
    appUserChatRoomsCount,
    appUserReadChatRoomsCount,
    appUserUnreadChatRoomsCount,
    busUserChatRoomsCount,
    busUserReadChatRoomsCount,
    busUserUnreadChatRoomsCount,
    readChatRoomsCount,
    shouldNotify
  } = unreadOrdersCount?.getBranchChatRoomsViewCounts || {};

  useEffect(() => {
    const sub = subscribeToMore<{ branchChatRoomsViewCountsUpdated: GetBranchChatRoomsViewCountsReturn }>({
      document: BranchChatRoomsViewCountsSubscription,
      variables: { BranchId: [profile?.Branch?.id], BusUserId: [profile?.id] },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData) {
          return prev;
        }

        const newCounts = subscriptionData?.data?.branchChatRoomsViewCountsUpdated;

        const replace = (newVal: number, prev: number) => {
          if (!newVal && newVal !== 0) {
            return prev;
          }
          return newVal;
        };

        return Object.assign({}, prev, {
          getBranchChatRoomsViewCounts: {
            chatRoomsCount: replace(newCounts.chatRoomsCount, chatRoomsCount || 0),
            unreadChatRoomsCount: replace(newCounts.unreadChatRoomsCount, unreadChatRoomsCount || 0),
            appUserChatRoomsCount: replace(newCounts.appUserChatRoomsCount, appUserChatRoomsCount || 0),
            appUserReadChatRoomsCount: replace(newCounts.appUserReadChatRoomsCount, appUserReadChatRoomsCount || 0),
            appUserUnreadChatRoomsCount: replace(newCounts.appUserUnreadChatRoomsCount, appUserUnreadChatRoomsCount || 0),
            busUserChatRoomsCount: replace(newCounts.busUserChatRoomsCount, busUserChatRoomsCount || 0),
            busUserReadChatRoomsCount: replace(newCounts.busUserReadChatRoomsCount, busUserReadChatRoomsCount || 0),
            busUserUnreadChatRoomsCount: replace(newCounts.busUserUnreadChatRoomsCount, busUserUnreadChatRoomsCount || 0),
            readChatRoomsCount: replace(newCounts.readChatRoomsCount, readChatRoomsCount || 0),
            shouldNotify: newCounts.shouldNotify,
            __typename: 'BranchChatRoomsViewCounts'
          }
        });
      }
    });
    return () => sub();
  }, [subscribeToMore, profile?.Branch?.id]);

  return {
    chatRoomsCount,
    unreadChatRoomsCount,
    appUserChatRoomsCount,
    appUserReadChatRoomsCount,
    appUserUnreadChatRoomsCount,
    busUserChatRoomsCount,
    busUserReadChatRoomsCount,
    busUserUnreadChatRoomsCount,
    readChatRoomsCount,
    shouldNotify
  };
};

export default useBranchChatRoomsViewCounts;
