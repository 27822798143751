import { useQuery } from '@apollo/client';
import React from 'react';
import Colors from '../../../../Colors';
import { Divider } from '../../../../components/DrawerBar/styled';
import ModalDialog from '../../../../components/Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../../../../components/Modal/styled';
import { FormButtonsContainer, FormLabel, FormSubmitButton, HeaderLabel } from '../../../../components/Shared/Forms/Forms';
import { Container } from '../../../../components/Shared/Shared';
import { CenteredLoader } from '../../../../components/Shared/Spinner';
import useCheckBranchAvailability, { Slots } from '../../../../hooks/useCheckBranchAvailability';
import { GetBranchSchedulesSlots } from '../../../../queries';
import { getUTCTime, toReadableDate } from '../../../../utils/dates';
import BranchSummaryDataTable from '../../../Store/BranchSummary/BranchSummaryDataTable';
import { BranchAvailability } from '../../../Store/types';

import { BOOKING_TYPE } from '../../types';
import { getBookingDates } from '../../utils';
import { BranchSchedule } from '../../../Store/BranchSchedules/types';

const SlotAvailabilityModal = ({
  selectedSlot,
  bookingDates,
  index
}: {
  selectedSlot: ReturnType<typeof useCheckBranchAvailability>['selectedSlotForEachDate'][0];
  bookingDates: ReturnType<typeof useCheckBranchAvailability>['bookingDates'];
  index: number;
}) => {
  const bookingDate = bookingDates[index];
  const { mainSlotAvailablity } = selectedSlot || {};
  const { data: { getBranchSchedulesSlots: availability } = {}, loading } = useQuery<{
    getBranchSchedulesSlots: {
      branchSchedules: {
        BranchSchedule: BranchSchedule;
        availability: BranchAvailability;
      }[];
      avialability: BranchAvailability;
    };
  }>(GetBranchSchedulesSlots, {
    variables: {
      start_date: bookingDate?.fullDate,
      slots_length: 1
    },
    fetchPolicy: 'cache-and-network'
  });

  const { branchSchedules: schedulesAvailability = [], avialability } = availability || {};

  const branchSchedules = schedulesAvailability.filter(item => item.BranchSchedule.booking_type !== BOOKING_TYPE.MULTI_SLOT);

  const branchSchedulesBookingDates = branchSchedules.map(item => {
    const branchScheduleBookingDates = getBookingDates(item.availability.slots);
    const isMultiDay = item.BranchSchedule.booking_type === BOOKING_TYPE.MULTI_DAY;
    const slots = isMultiDay ? [branchScheduleBookingDates[0]?.slots[0]] : branchScheduleBookingDates[0]?.slots;
    return {
      BranchSchedule: item.BranchSchedule,
      branchScheduleBookingDates: branchScheduleBookingDates.map(branchScheduleBookingDate => ({
        ...branchScheduleBookingDate,
        slots
      }))
    };
  });

  const branchBookingDates = getBookingDates(avialability?.slots || ({} as Slots));

  const branchSchedulesBookingDatesWithOwnSlot = branchSchedulesBookingDates.filter(item => {
    return !!item.BranchSchedule?.slots?.length && item.branchScheduleBookingDates[0].slots?.length;
  });

  const branchSchedulesBookingDatesWithBranchSlot = branchSchedulesBookingDates.filter(item => {
    return !item.BranchSchedule?.slots?.length;
  });

  return (
    <>
      <ModalBody>
        <Container>
          <div>
            {!loading && (
              <>
                <HeaderLabel>Date: {toReadableDate(bookingDate?.fullDate || new Date(), { noTime: true })}</HeaderLabel>
                {/* <FormLabel bold>
                  Operational Capacity: {branchBookingDates[0]?.slots?.[0]?.count} / {branchBookingDates[0]?.slots?.[0]?.mainSlotAvailablity}
                </FormLabel> */}
                <div>
                  <FormLabel bold>
                    {branchSchedulesBookingDatesWithBranchSlot?.length ? branchSchedulesBookingDatesWithBranchSlot?.map(item => item.BranchSchedule.name).join(', ') : 'No branchSchedules'}
                  </FormLabel>
                  <BranchSummaryDataTable
                    headerRows={[
                      {
                        label: 'Slot'
                      },
                      {
                        label: 'Availability'
                      }
                    ]}
                    bodyColums={
                      branchBookingDates?.[0]?.slots.map(slot => {
                        return {
                          rows: [
                            {
                              label: getUTCTime(slot) || '',
                              style: {
                                color: !slot?.available ? Colors.warmGrey : Colors.black
                              }
                            },
                            {
                              label: slot?.available ? String(slot?.available) : '0',
                              style: {
                                color: !slot?.available ? Colors.grey : Colors.black
                              }
                            }
                          ]
                        };
                      }) || []
                    }
                  />
                </div>

                <div style={{ marginTop: 10 }}>
                  <Divider />
                  {branchSchedulesBookingDatesWithOwnSlot.map(item => {
                    const isMultiDay = item.BranchSchedule.booking_type === BOOKING_TYPE.MULTI_DAY;
                    return (
                      <div key={item.BranchSchedule.id} style={{ marginTop: 10 }}>
                        <FormLabel bold>{item.BranchSchedule.name}</FormLabel>
                        <BranchSummaryDataTable
                          headerRows={[
                            ...(!isMultiDay
                              ? [
                                  {
                                    label: 'Slot'
                                  }
                                ]
                              : []),
                            {
                              label: 'Availability'
                            }
                          ]}
                          bodyColums={
                            item?.branchScheduleBookingDates?.[0]?.slots?.map(slot => {
                              return {
                                rows: [
                                  ...(!isMultiDay
                                    ? [
                                        {
                                          label: getUTCTime({ timestamp: slot?.timestamp }) || '',
                                          style: {
                                            color: !slot?.available ? Colors.warmGrey : Colors.black
                                          }
                                        }
                                      ]
                                    : []),
                                  {
                                    label: slot?.available ? String(slot?.available) : '0',
                                    style: {
                                      color: !slot?.available ? Colors.warmGrey : Colors.black
                                    }
                                  }
                                ]
                              };
                            }) || []
                          }
                        />
                      </div>
                    );
                  })}
                </div>
              </>
            )}
            {loading && <CenteredLoader size={30} />}
          </div>
        </Container>
      </ModalBody>

      <ModalFooter>
        <FormButtonsContainer>
          <FormSubmitButton onClick={ModalDialog.closeModal}>Done</FormSubmitButton>
        </FormButtonsContainer>
      </ModalFooter>
    </>
  );
};

export default SlotAvailabilityModal;
