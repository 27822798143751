import React, { useEffect } from 'react';
import InfiniteList from '../../../components/Shared/InfiniteList/InfiniteList';
import usePaginatedQuery from '../../../hooks/usePaginatedQuery';
import { GetBranchPayouts } from '../../../queries';
import { RecordsContainer } from '../../styled';
import Common from '../Common';
import BranchPayout from './BranchPayoutRecord';
import { BranchPayout as BranchPayoutType } from './types';
import { Booking } from '../../Bookings/components/BookingsBody/styled';
import ToolBar from '../../../components/Shared/ToolBar/ToolBar';

const BranchPayouts = () => {
  const [[hasMoreItems, setHasMoreItems], queryResult] = usePaginatedQuery<BranchPayoutType[]>({ query: GetBranchPayouts, otherParams: { fetchPolicy: 'cache-and-network' }, type: 'cursor' });

  const { data: { getBranchPayouts: payouts = [] } = {}, loading, fetchMore, refetch } = queryResult;

  useEffect(() => {
    Common.set(`BranchPayouts.GetBranchPayouts.refetch`, () => {
      refetch?.().then(() => setHasMoreItems(true));
    });
  }, []);

  return (
    <>
      <ToolBar displayString="Payouts" />
      <InfiniteList
        list={payouts}
        itemRenderer={(payout: BranchPayoutType) => <BranchPayout payout={payout} />}
        hasMoreItems={hasMoreItems}
        loading={loading}
        fetchMore={fetchMore}
        setHasMoreItems={setHasMoreItems}
        type="cursor"
        offset={payouts?.length}
        paddingL={20}
        paddingR={20}
      />
    </>
  );
};

export default BranchPayouts;
