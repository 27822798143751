import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import useIcons from '../../../hooks/useIcons';
import { areObjectsEqual } from '../../../utils/helpers';
import OptionDropdown from '../Menus/OptionDropdown/OptionDropdown';
import { OPTION_DROPDOWN_MENU_BUTTON_TYPES, OPTION_DROPDOWN_MENU_POSITIONS, OPTION_DROPDOWN_TYPES } from '../Menus/OptionDropdown/types';
import { MORE_OPTIONS_TYPES as MORE_OPTIONS_TYPES_CONFIG, SelectedFilter as SelectedFilterType } from './config';
import { FILTER_TYPES, MORE_OPTIONS_TYPES, SelectedMoreOptions } from './Filters';
import { CloseButton, HorizontalSeparator, SelectedFilter as SelectedFilterPill } from './styled';

type MoreOption = {
  values:
    | string
    | {
        name: string;
        value?: string;
      }[];
  type: MORE_OPTIONS_TYPES;
  filterType: FILTER_TYPES;
  id: string;
  title: string;
  optionType: OPTION_DROPDOWN_TYPES;
  items:
    | {
        value: string | boolean | number;
        name: string;
      }[]
    | [];
};

type SelectedFiltersWithMoreOptions = {
  moreOptions: MoreOption[];
  requisite:
    | string
    | {
        name: string;
        value?: string;
      }[];
  name: string;
  value: FILTER_TYPES;
}[];

type SelectedFilterProps = {
  filter: {
    requisite:
      | string
      | {
          name: string;
          value?: string;
        }[];
    name: string;
    value: FILTER_TYPES;
    unit?: string;
    moreOptions: {
      type: MORE_OPTIONS_TYPES;
      filterType: FILTER_TYPES;
      id: string;
      title: string;
      optionType: OPTION_DROPDOWN_TYPES;
      items:
        | {
            value: string | boolean | number;
            name: string;
          }[]
        | [];
    }[];
  };
  selectedFiltersWithMoreOptions: SelectedFiltersWithMoreOptions;
  selectedFiltersForm: ReturnType<typeof useForm>;
  selectedMoreOptionsState: [SelectedMoreOptions, React.Dispatch<React.SetStateAction<SelectedMoreOptions>>];
  REQUISITE_ID: string;
  setSelectedFilters: (selectedFilter: SelectedFilterType[]) => void;
};

const SelectedFilter: FC<SelectedFilterProps> = ({ filter, selectedFiltersWithMoreOptions, selectedFiltersForm, REQUISITE_ID, selectedMoreOptionsState, setSelectedFilters, isAlternative }) => {
  const { value, name, moreOptions, requisite, unit } = filter;
  const icons = useIcons();
  const { getValues } = selectedFiltersForm;
  const smallXIcon = icons?.closeTransparentMid?.childImageSharp.gatsbyImageData.images.fallback.src;
  const smallxIconBlack = icons?.closeModalMid?.childImageSharp.gatsbyImageData.images.fallback.src;

  const [selectedMoreOptions, setSelectedMoreOptions] = selectedMoreOptionsState;

  const clearFilterType = ({ filterType }: { filterType: FILTER_TYPES }) => {
    const filteredSelectedMoreOptions = selectedMoreOptions.filter(({ filterType: selectedFilterType }) => selectedFilterType !== filterType);

    const toClear = selectedMoreOptions.find(({ filterType: selectedFilterType }) => selectedFilterType === filterType)!;

    const requisiteValue = toClear.optionsValues.find(({ optionValue }) => optionValue === REQUISITE_ID)!;

    const newSelectedMoreOptions = [...filteredSelectedMoreOptions, { ...toClear, optionsValues: [requisiteValue] }];

    setSelectedMoreOptions(newSelectedMoreOptions);
    setSelectedFilters(selectedFiltersWithMoreOptions.filter(({ value: selectedFilterType }) => selectedFilterType !== filterType) as SelectedFilterType[]);
  };

  const getMoreOptionStringValue = ({ moreOption }: { moreOption: MoreOption }): string => {
    const { optionType, values: moreOptionValues } = moreOption;

    if (optionType === OPTION_DROPDOWN_TYPES.MULTI_SELECT) {
      return (moreOptionValues as { name: string }[])?.map(({ name }) => name).join(', ');
    }

    if (optionType === OPTION_DROPDOWN_TYPES.SELECT || optionType === OPTION_DROPDOWN_TYPES.RADIO) {
      const { items } = moreOption;

      return (items.find(({ value }) => String(value) === String(moreOptionValues)) as { name: string }).name;
    }

    return moreOptionValues as string;
  };

  const moreOptionsWithValues = selectedFiltersWithMoreOptions.find(({ value: filterValue }) => filterValue === value)?.moreOptions || [];

  const moreOptionsWithValuesTypes = moreOptionsWithValues?.map(({ type }) => type);

  const moreOptionsValues = moreOptions.map(moreOption => {
    const { type } = moreOption;

    if (!moreOptionsWithValuesTypes?.includes(type)) {
      return moreOption;
    }

    const moreOptionWithValues = moreOptionsWithValues.find(({ type: moreOptionType }) => moreOptionType === type)!;

    return {
      ...moreOption,
      values: moreOptionWithValues.values
    };
  });

  const newMoreOptions = [
    ...moreOptionsValues,
    {
      optionType: OPTION_DROPDOWN_TYPES.RADIO,
      id: `${value}.${REQUISITE_ID}`,
      items: [
        { name: 'Requisite', value: true },
        { name: 'Alternative', value: false }
      ],
      title: 'Requisite',
      values: requisite
    }
  ];

  return (
    <OptionDropdown
      key={value}
      selectedItemsForm={selectedFiltersForm}
      options={newMoreOptions}
      menuButtonType={OPTION_DROPDOWN_MENU_BUTTON_TYPES.CUSTOM_BUTTON}
      triangle
      CustomButton={({ active, ...props }) => (
        <SelectedFilterPill activeMenu={active} outlined={!!moreOptionsWithValues?.length} key={value} isAlternative={isAlternative}>
          <input {...props} />
          <span>
            <strong>{name}</strong>
            {!!moreOptionsWithValues?.length && <HorizontalSeparator isAlternative={isAlternative} />}
            {!!moreOptionsWithValues?.length &&
              moreOptionsWithValues.map((moreOption, index) => {
                const moreOptionSelectedValue = getMoreOptionStringValue({ moreOption });
                const moreOptionString = `${moreOptionSelectedValue}`;
                if (moreOption.type === MORE_OPTIONS_TYPES_CONFIG.FROM_NUMBER && moreOptionsWithValues.find(({ type }) => type === MORE_OPTIONS_TYPES_CONFIG.TO_NUMBER)) {
                  return `${moreOptionString} - `;
                }

                if (moreOptionsWithValues.length === index + 1) {
                  return moreOptionString;
                }
                return `${moreOptionString}, `;
              })}
            {!!moreOptionsWithValues?.length && ` ${unit}`}
          </span>
          {!!moreOptionsWithValues?.length && (
            <CloseButton
              small={true}
              bgImg={smallxIconBlack}
              onClick={() => {
                clearFilterType({ filterType: value });
              }}
            />
          )}
        </SelectedFilterPill>
      )}
      menuPosition={OPTION_DROPDOWN_MENU_POSITIONS.RIGHT}
      onSubmit={(options: Partial<(typeof filter)['moreOptions']>) => {
        const filterType = options[0]?.filterType!;

        const optionValues: Record<MORE_OPTIONS_TYPES, string | { name: string; value?: string }[]> = getValues()[filterType];

        const optionsValues = (Object.keys(optionValues) as MORE_OPTIONS_TYPES[]).map(key => ({ optionValue: key, values: optionValues[key], filterType })).filter(({ values }) => values?.length);

        const newMoreOption = { filterType, optionsValues };

        const alreadySelectedMoreOptions = selectedMoreOptions.find(({ filterType: selectedFilterType }) => selectedFilterType === filterType);

        if (alreadySelectedMoreOptions) {
          const newMoreOptionsValues = { filterType, optionsValues: [...optionsValues] };

          setSelectedMoreOptions([...selectedMoreOptions.filter(({ filterType: selectedFilterType }) => selectedFilterType !== filterType), newMoreOptionsValues]);
          return;
        }

        setSelectedMoreOptions([...selectedMoreOptions, newMoreOption]);
      }}
    />
  );
};

export default React.memo(SelectedFilter, areObjectsEqual);
