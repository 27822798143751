import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useRef } from 'react';
import Colors from '../../Colors';
import useIcons from '../../hooks/useIcons';
import useMediaQuery from '../../hooks/useMediaQuery';
import usePaginatedQuery from '../../hooks/usePaginatedQuery';
import { AddBusUserToChatRoom, ChatSubscription, GetBranchAppUserTags, GetBranchChatRoom, GetBranchChatRooms, GetBusUserProfile, GetMessages, UpdateMessageReadStatus } from '../../queries';
import { vars } from '../../reactive';
import client from '../../utils/client';
import { BranchAppUser } from '../../views/Store/BranchBilling/types';
import { BranchAppUserTag } from '../../views/Store/BranchTags/types';
import { AppUserImage, AppUserImageAnimation, AppUserImageContainer, AppUserImageContainerAnimation, RecordBody } from '../../views/styled';
import ChatRecordOptions, { ChatRecordOptionsRef } from '../Chat/ChatRecordOptions';
import { MobileCloseButton } from '../DrawerBar/DrawerButtons';
import { Divider, DrawerContainer, DrawerSection } from '../DrawerBar/styled';
import { DRAWER_IDS } from '../DrawerBar/types';
import { PetDrawerTag, PetDrawerTagsContainer } from '../PetDrawer/styled';
import { PetRecordOptionsContainer } from '../Pets/styled';
import InfiniteList, { InfiniteListActions } from '../Shared/InfiniteList/InfiniteList';
import { ActivityIndicator } from '../Shared/Spinner';
import InputBar from './InputBar';
import MessageItem from './MessageItem';
import { CloseButtonContainer, JoinRoomButton } from './styled';
import { GatsbyImage } from 'gatsby-plugin-image';
import { PetProfilePic, PetProfilePicsContainer } from '../UserDrawer/styled';
import { setDrawerBar } from '../../reactive/actions';

const ChatDrawer = ({ data: chatRoomId = '' }: any) => {
  const infiniteListActions = useRef<InfiniteListActions>({ scrollToEnd: () => {} });
  const { data: { getBusUserProfile: profile = {} } = {} } = useQuery(GetBusUserProfile, {
    fetchPolicy: 'cache-only'
  });
  const {
    data: { getBranchChatRooms: [room] = [] } = {},
    refetch: refetchRoom,
    loading: loadingChat,
    updateQuery: updateRoom
  } = useQuery(GetBranchChatRoom, {
    variables: { chatRoom_id: [chatRoomId], requisite_queries: ['chatRoom_id'] },
    skip: !chatRoomId
  });

  const isStaff = room?.is_bus_room;

  const chatRecordOptionsRef = useRef<ChatRecordOptionsRef>(null);
  const messageReadStatus = room?.MessageReadStatuses?.find(status => status.BusUser?.id === profile?.id);

  const [[hasMoreItems, setHasMoreItems], { subscribeToMore, data: { getMessages: messages = [] } = {}, loading, fetchMore, refetch: refetchMessages }] = usePaginatedQuery({
    query: GetMessages,
    otherVariables: { ChatRoomId: chatRoomId },
    otherParams: { fetchPolicy: 'cache-and-network' }
  });

  const [updateMessageReadStatus, { loading: loadingUpdateStatus }] = useMutation(UpdateMessageReadStatus);

  const [addUserToChat, { loading: loadingJoin }] = useMutation(AddBusUserToChatRoom);

  const isUserInvited = isStaff ? true : room?.BusUsers?.some(user => user.id === profile.id);
  const roomUsers = room ? [...(room?.AppUsers || []), ...room?.BusUsers] : [];

  const userTags = room?.AppUsers?.flatMap(user => user?.BranchAppUserTags)?.map(tag => tag);
  const { data: { getBranchAppUserTags: allTags = [] } = {} } = useQuery<{
    getBranchAppUserTags: BranchAppUserTag[];
  }>(GetBranchAppUserTags, {
    fetchPolicy: 'cache-and-network',
    variables: { offset: 0, limit: 1000 },
    skip: isStaff
  });

  const quickTags = allTags?.filter(tag => tag?.quick_action) || [];

  const joinRoom = async () => {
    await addUserToChat({ variables: { BusUserId: profile.id, ChatRoomId: room.id } });
    refetchRoom();
  };

  useEffect(() => {
    const sub = subscribeToMore({
      document: ChatSubscription,
      variables: { ChatRooms: [chatRoomId] },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData) return prev;
        const newMessage = subscriptionData?.data?.messageAdded;
        const list = [{ ...newMessage, __typename: 'Message' }, ...prev?.getMessages];
        infiniteListActions?.current?.scrollToEnd();
        return Object.assign({}, prev, { getMessages: [...(list || [])] });
      }
    });
    return () => sub();
  }, []);

  useEffect(() => {
    if (room && messages?.length > 0 && !loadingUpdateStatus) {
      recordMessageReadStatusForUser(messages[0]);
    }
  }, [JSON.stringify(room), messages?.length]);

  const recordMessageReadStatusForUser = async (message: any) => {
    if (messageReadStatus?.last_read_message === message?.id) {
      return;
    }
    await updateMessageReadStatus({
      variables: {
        ChatRoomId: chatRoomId,
        BranchId: profile?.Branch?.id,
        last_read_message: message ? message.id : null,
        last_read_timestamp: new Date(),
        status: 'READ'
      }
    });

    updateRoom((prev: any) => {
      const newRooms = prev?.getBranchChatRooms?.map((room: any) => {
        if (room.id === chatRoomId) {
          return {
            ...room,
            MessageReadStatuses: [
              ...(room.MessageReadStatuses || []).filter((status: any) => status?.BusUser?.id !== profile.id),
              {
                ...messageReadStatus,
                last_read_message: message.id,
                last_read_timestamp: new Date(),
                __typename: 'MessageReadStatus'
              }
            ],
            __typename: 'ChatRoom'
          };
        }
        return room;
      });
      return {
        getBranchChatRooms: newRooms
      };
    });
  };

  const drawer = vars.drawerBars().find(drawer => drawer.drawerId === DRAWER_IDS.CHAT_DRAWER)!;
  const { mobile, tablet } = useMediaQuery({ mobile: true, tablet: true });

  const onSendMessage = async () => {
    const { data: { getMessages: list = [] } = {} } = await refetchMessages();
    const currentQuery = client.readQuery({
      query: GetBranchChatRooms
    });
    const currentRoom = currentQuery?.getBranchChatRooms?.find(room => room.id === chatRoomId);
    const updatedRoom = {
      ...currentRoom,
      Messages: list
    };

    client.writeQuery({
      query: GetBranchChatRooms,
      data: {
        getBranchChatRooms: [...(currentQuery?.getBranchChatRooms || [])].map(room => {
          if (room.id === chatRoomId) {
            return updatedRoom;
          }
          return room;
        })
      }
    });
  };
  const users = isStaff ? room?.BusUsers?.filter(user => user.id !== profile.id) : room?.AppUsers || [];

  const icons = useIcons();
  const usersProfilePics = users?.map(user => user?.profile_pic ?? icons.user.childImageSharp.gatsbyImageData.images.fallback.src);
  const handleClick = (e: React.MouseEvent) => {
    if (!isStaff && users?.length === 1) {
      setDrawerBar({ drawerId: DRAWER_IDS.USER_DRAWER, recordData: users[0]?.id });
    }
  };

  return (
    <>
      <DrawerContainer>
        {!loadingChat && (
          <DrawerSection paddingT={mobile ? 10 : tablet ? 16 : 20} paddingB={mobile ? 10 : tablet ? 16 : 20} zIndex={10} flexRow center>
            {mobile && (
              <CloseButtonContainer>
                <MobileCloseButton drawer={drawer} />
              </CloseButtonContainer>
            )}
            <AppUserImageContainer mobileWidth="50px" onClick={handleClick} style={{ cursor: !isStaff && users?.length === 1 ? 'pointer' : 'default' }}>
              {room?.is_bus_room && (
                <AppUserImageContainer noPadding mobileWidth="40px">
                  <AppUserImageContainerAnimation noPadding>
                    <AppUserImageAnimation>
                      {room?.cover_photo ? (
                        <AppUserImage src={room?.cover_photo} index={0} />
                      ) : (
                        <GatsbyImage
                          image={icons.user.childImageSharp.gatsbyImageData}
                          alt="user"
                          style={{ width: mobile ? '44px' : '40px', height: mobile ? '44px' : '40px', borderRadius: '25px' }}
                        />
                      )}
                    </AppUserImageAnimation>
                  </AppUserImageContainerAnimation>
                </AppUserImageContainer>
              )}
              {!room?.is_bus_room && (
                <>
                  {users?.length === 1 &&
                    users?.map(user => (
                      <AppUserImageContainer noPadding key={user?.id} mobileWidth="40px" onClick={handleClick}>
                        <AppUserImageContainerAnimation noPadding>
                          <AppUserImageAnimation key={user?.id}>
                            {user?.profile_pic && <AppUserImage src={user?.profile_pic} index={0} />}
                            {!user?.profile_pic && (
                              <GatsbyImage
                                image={icons.user.childImageSharp.gatsbyImageData}
                                alt="user"
                                style={{ width: mobile ? '44px' : '40px', height: mobile ? '44px' : '40px', borderRadius: '25px' }}
                              />
                            )}
                          </AppUserImageAnimation>
                        </AppUserImageContainerAnimation>
                      </AppUserImageContainer>
                    ))}

                  {usersProfilePics?.length === 2 && (
                    <PetProfilePicsContainer>
                      {usersProfilePics?.map((profile_pic, index) => (
                        <PetProfilePic key={index} bgUrl={profile_pic} isDuo={usersProfilePics?.length === 2} />
                      ))}
                    </PetProfilePicsContainer>
                  )}
                  {usersProfilePics?.length > 2 && (
                    <PetProfilePicsContainer>
                      <PetProfilePic bgUrl={usersProfilePics[0]} isDuo />
                      <PetProfilePic isMulti> +{usersProfilePics?.length - 1}</PetProfilePic>
                    </PetProfilePicsContainer>
                  )}
                </>
              )}
            </AppUserImageContainer>

            <RecordBody
              width="120"
              color=" #212121"
              fontSize={14}
              fontWeight="800"
              noMargin
              marginLeft="8px"
              onClick={handleClick}
              style={{ cursor: !isStaff && users?.length === 1 ? 'pointer' : 'default' }}
            >
              {room?.is_bus_room ? room?.name : users.map(user => user.name).join(' & ') || 'No User'}
            </RecordBody>
            <PetRecordOptionsContainer mobileMargin="0 0 0 auto">
              <ChatRecordOptions
                ref={chatRecordOptionsRef}
                showAlways={true}
                loadTags={false}
                appUsers={room?.AppUsers as BranchAppUser[]}
                appUserQuickTags={quickTags}
                removeAppUserOptions={isStaff}
                profile={profile}
                chatRoom={room}
                left={mobile}
                showOnMobileDrawer={true}
              />
            </PetRecordOptionsContainer>
          </DrawerSection>
        )}

        <Divider />

        {!!users.length && userTags.length >= 1 && users.length === 1 && (
          <PetDrawerTagsContainer padding="12px 20px">
            {userTags?.map(tag => (
              <PetDrawerTag key={tag.id}>{tag.name}</PetDrawerTag>
            ))}
          </PetDrawerTagsContainer>
        )}

        <InfiniteList
          list={messages}
          fetchMore={fetchMore}
          hasMoreItems={hasMoreItems}
          itemRenderer={(msg: any) => <MessageItem key={msg?.id} msg={msg} users={roomUsers} messageReadStatuses={room?.MessageReadStatuses} profile={profile} isStaff={isStaff} />}
          items={messages}
          loading={loading}
          refetch={refetchMessages}
          setHasMoreItems={setHasMoreItems}
          offset={messages.length}
          inversed
          listActionsRef={infiniteListActions}
          mobilePadding="0 16px 0 16px"
          gap={10}
        />

        {isUserInvited ? (
          <InputBar chatRoomId={chatRoomId} scrollToBottom={infiniteListActions?.current?.scrollToEnd} branchId={profile?.Branch?.id} onSend={onSendMessage} />
        ) : (
          <JoinRoomButton onClick={joinRoom}>{loadingJoin ? <ActivityIndicator size={12.5} color={Colors.white} /> : 'Join the Conversation'}</JoinRoomButton>
        )}
      </DrawerContainer>
    </>
  );
};

export default ChatDrawer;
