import { useMutation, useQuery } from '@apollo/client';
import React, { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactSelect from '../../../../components/Shared/Forms/Select';
import ModalDialog, { ModalLayout } from '../../../../components/Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../../../../components/Modal/styled';
import { BusUserProfile } from '../../../../components/Profile/types';
import { FormButtonsContainer, FormLabel, FormSubmitButton, InputsWrapper, SectionSpan, WideInputGroup, selectTheme } from '../../../../components/Shared/Forms/Forms';
import { Container } from '../../../../components/Shared/Shared';
import { AssignBusUserToAppointment, GetBranchAppointments, GetAllBranchBusUsers, GetBusUserProfile } from '../../../../queries';
import { toReadableDate } from '../../../../utils/dates';
import { BOOKING_STATUS_TYPES, BOOKING_TYPE, BookingOrder } from '../../../../views/Bookings/types';
import Select from '../../../../components/Shared/Forms/Select';

interface BookingAssignModalFormProps {
  appointments: BookingOrder;
  busUsers: BusUserProfile[];
  formOptions: Partial<ReturnType<typeof useForm>>;
  loadingAdd: boolean;
  existingAssigned: string;
  onSubmit: () => void;
  assignTo: string;
}

const BookingAssignModalForm: FC<BookingAssignModalFormProps> = ({ appointments, busUsers, formOptions, loadingAdd, existingAssigned, onSubmit, assignTo }) => {
  const { control } = formOptions;
  const petsIds = [...new Set(appointments.map(appointment => appointment.PetRecord.Pet.id))];
  const pets = petsIds.map(petId => appointments.find(appointment => appointment.PetRecord.Pet.id === petId)?.PetRecord.Pet.name);
  const appUsersIds = [...new Set(appointments.map(appointment => appointment.OrderItem.Order.AppUser.id))];
  const appUsersLength = appUsersIds.length;
  const appUsers = appUsersIds.map(appUserId => appointments.find(appointment => appointment.OrderItem.Order.AppUser.id === appUserId)?.OrderItem.Order.AppUser.name);

  const appUsersNames = appUsersLength > 1 ? `${appUsersLength} Clients` : appUsers.join(' & ');

  const petsNames = appUsersLength > 1 ? `${petsIds.length} Pets` : pets.join(' & ');

  return (
    <ModalLayout
      isMini
      compact
      buttons={[
        <FormSubmitButton loading={loadingAdd} onClick={onSubmit}>
          Assign
        </FormSubmitButton>
      ]}
    >
      {/* <FormLabel>Service</FormLabel>
      <SectionSpan>{appointments?.[0]?.OrderItem.item.name}</SectionSpan>
      <FormLabel>Booking Slot</FormLabel>
      <SectionSpan>{toReadableDate(appointments?.[0]?.timestamp)}</SectionSpan>
      <FormLabel>Booked by</FormLabel>
      <SectionSpan>{appUsersNames}</SectionSpan>
      <FormLabel>Booked for</FormLabel>
      <SectionSpan>{petsNames}</SectionSpan> */}

      <WideInputGroup>
        <FormLabel>Staff Member</FormLabel>
        <Controller
          render={({ onChange, value }) => (
            <Select
              theme={selectTheme}
              onChange={onChange}
              isMulti
              value={value}
              options={busUsers}
              getOptionValue={busUser => busUser?.id}
              getOptionLabel={busUser => busUser?.name}
              name={'busUserId'}
            />
          )}
          control={control}
          name={'busUserId'}
          defaultValue={busUsers.find(({ id }) => (assignTo || existingAssigned || '') === id)}
        />
      </WideInputGroup>
    </ModalLayout>
  );
};

export const BookingAssignModal = ({ appointments, appointmentsIds, assignTo }: { appointments: BookingOrder; appointmentsIds: string[]; assignTo: string }) => {
  const { control, handleSubmit, errors } = useForm();

  const { data: { getBranchBusUsers: BusUsers = [] } = {} } = useQuery<{ getBranchBusUsers: BusUserProfile[] }>(GetAllBranchBusUsers);

  const { data: { getBranchAppointments: branchAppointments = [] } = {}, loading: loadingAppointments } = useQuery<{ getBranchAppointments: BookingOrder }>(GetBranchAppointments, {
    variables: {
      appointment_id: appointmentsIds,
      requisite_queries: ['appointment_id'],
      alternative_queries: [],
      offset: 0,
      limit: 1000,
      status: [BOOKING_STATUS_TYPES.CONFIRMED, BOOKING_STATUS_TYPES.CANCELED, BOOKING_STATUS_TYPES.REQUESTED],
      booking_type: [BOOKING_TYPE.SLOT, BOOKING_TYPE.MULTI_SLOT, BOOKING_TYPE.MULTI_DAY]
    },
    fetchPolicy: 'cache-and-network',
    skip: !!appointments?.length
  });

  const [addStaff, { data: addedStaff, loading: loadingAdd }] = useMutation<{ assignBusUserToAppointment: BookingOrder }>(AssignBusUserToAppointment);

  const allAppointments = appointments || branchAppointments || [];

  const bookingBusUsers = allAppointments?.[0]?.BusUsers;
  const existingAssigned = bookingBusUsers?.[0]?.id;

  const onSubmit = handleSubmit(({ busUserId }) => {
    addStaff({
      variables: {
        id: allAppointments?.map?.(appointment => appointment.id),
        BusUserId: busUserId?.map(item => item?.id) || null
      }
    });
  });

  useEffect(() => {
    if (addedStaff?.assignBusUserToAppointment?.length) {
      ModalDialog.closeModal();
    }
  }, [addedStaff]);

  return (
    <BookingAssignModalForm
      appointments={allAppointments}
      busUsers={BusUsers}
      formOptions={{ control, errors }}
      loadingAdd={loadingAdd}
      existingAssigned={existingAssigned}
      onSubmit={onSubmit}
      assignTo={assignTo}
    />
  );
};

export default BookingAssignModal;
