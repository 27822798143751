import React from 'react';
import { GetBranchVehicles } from '../../queries';
import InfiniteList from '../../components/Shared/InfiniteList/InfiniteList';
import usePaginatedQuery from '../../hooks/usePaginatedQuery';
import FleetsVehicleRecord from './FleetsVehicleRecord';
import { BranchVehicle } from './types';

const FleetsVehicles = () => {
  const [[hasMoreItems, setHasMoreItems], queryResult] = usePaginatedQuery<BranchVehicle[]>({
    query: GetBranchVehicles
  });

  const { data: { getBranchVehicles: branchVehicles = [] } = {}, loading, fetchMore, refetch } = queryResult;

  return (
    <>
      <InfiniteList
        paddingL={20}
        paddingR={20}
        hasMoreItems={hasMoreItems}
        loading={loading}
        fetchMore={fetchMore}
        list={branchVehicles}
        offset={branchVehicles.length}
        setHasMoreItems={setHasMoreItems}
        itemRenderer={(vehicle: BranchVehicle) => <FleetsVehicleRecord branchVehicle={vehicle} />}
      />
    </>
  );
};

export default FleetsVehicles;
