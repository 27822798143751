import React, { forwardRef, Ref, useEffect, useImperativeHandle, useState } from 'react';
import useIcons from '../../hooks/useIcons';
import { BackgroundOpacity, CloseModal, ModalBody, ModalContainer, ModalDivider, ModalHeader } from './styled';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Divider } from '../DrawerBar/styled';
import { FormButtonsContainer, InputWrapper } from '../Shared/Forms/Forms';
import { ModalWrapper } from '../Shared/Shared';

let containerRef: any;
type ModalType = {
  content: ({
    toggleModal,
    openModal,
    closeModal
  }: {
    toggleModal: ({ byModal }: { byModal?: boolean }) => void;
    openModal: () => void;
    closeModal: ({ byModal }: { byModal?: boolean }) => void;
  }) => JSX.Element;
  title?: string;
  noHeader?: boolean;
  overlayClose?: boolean;
  autoHeight?: boolean;
  onClose?: () => void;
  onCloseBySave?: () => void;
  onOpen?: () => void;
  autoOpenModal?: boolean;
  mobileBorderRadius?: string;
  isMini?: boolean;
};

const openModal = (props: ModalType) => {
  containerRef.setProps(props);
  containerRef.openModal();
  return;
};

const ModalDialog = {
  openModal,
  setContainer: (ref: Ref<any>) => (containerRef = ref),
  closeModal: () => containerRef.closeModal(),
  isOpen: () => containerRef.isOpen(),
  isMini: () => containerRef.isMini()
};

export default ModalDialog;

export const ModalDialogElement = forwardRef(({}, ref: React.Ref<any>) => {
  const [visible, setVisible] = useState(false);
  const icons = useIcons();
  const defaultProps = {
    content: () => <></>
  };
  const [props, setProps] = useState<ModalType>(defaultProps);
  const [animationShowStatus, setAnimationShowStatus] = useState(false);

  const { content, onClose, title, onOpen, autoOpenModal = false, onCloseBySave, noHeader, overlayClose, mobileBorderRadius, autoHeight, isMini } = props;
  const toggleModal = (options: { byModal?: boolean } = { byModal: false }) => {
    const { byModal } = options;
    if (visible) {
      onClose?.();
      if (!byModal) {
        onCloseBySave?.();
      }
    }
    setVisible(!visible);
  };

  const openModal = () => {
    setVisible(true);
    onOpen?.();
  };

  const closeModal = (options: { byModal?: boolean } = { byModal: false }) => {
    const { byModal } = options;
    setVisible(false);
    onClose?.();
    if (!byModal) {
      onCloseBySave?.();
    }
  };

  const isOpen = () => visible;

  useImperativeHandle(ref, () => ({
    toggleModal,
    setProps,
    openModal,
    closeModal,
    isOpen
  }));

  useEffect(() => {
    if (visible) {
      setAnimationShowStatus(true);
    }
  }, [visible]);

  useEffect(() => {
    if (autoOpenModal) {
      openModal();
    }
  }, []);

  return (
    <>
      <BackgroundOpacity
        showModal={visible}
        onAnimationEnd={() => {
          if (!visible) {
            setAnimationShowStatus(false);
            setProps(defaultProps);
          }
        }}
        onClick={e => {
          if (overlayClose && e.target === e.currentTarget) {
            ModalDialog.closeModal();
          }
        }}
      >
        <ModalContainer onMouseDown={e => e.stopPropagation()} mobileBorderRadius={mobileBorderRadius} autoHeight={autoHeight} isMini={isMini}>
          {!noHeader && (
            <ModalHeader>
              <span>{title}</span>
              <CloseModal onClick={() => closeModal({ byModal: true })}>
                <GatsbyImage
                  image={icons?.closeTrasparentWide?.childImageSharp.gatsbyImageData}
                  alt="Close"
                  style={{
                    cursor: 'pointer'
                  }}
                />
              </CloseModal>
            </ModalHeader>
          )}
          <ModalDivider />
          {animationShowStatus && content({ toggleModal, openModal, closeModal })}
        </ModalContainer>
      </BackgroundOpacity>
    </>
  );
});

export const ModalLayout = ({
  children,
  compact,
  sideTabs,
  buttons,
  isMini
}: {
  children: React.ReactNode | React.ReactNode[];
  compact?: boolean;
  sideTabs?: React.ReactNode | null;
  buttons?: React.ReactNode | React.ReactNode[];
}) => {
  return (
    <ModalBody compact={compact} isMini={isMini}>
      <ModalWrapper compact={compact} isMini={isMini}>
        {sideTabs}
        <InputWrapper isCompact={compact} isMini={isMini}>
          {children}
        </InputWrapper>
        <Divider />
      </ModalWrapper>
      {!!buttons && <FormButtonsContainer justifyContent={compact ? 'center' : 'flex-end'}>{buttons}</FormButtonsContainer>}
    </ModalBody>
  );
};
