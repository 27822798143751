import { useReactiveVar } from '@apollo/client';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { TheCalendarContainer } from '../../components/Calendar/styled';
import { CALENDAR_VIEW_BOOKING_TYPE, CALENDAR_VIEWS_NAMES, CALENDAR_VIEWS } from '../../components/Calendar/types';
import TheCalendar, { TheCalendarRef } from '../../components/Calendar/TheCalendar';
import { CalendarRangeRef } from '../../components/Calendar/CalendarRange';
import { MainContainer, ViewWrapper } from '../../components/Shared/Shared';
import SideTab, { SIDE_TABS_TYPES } from '../../components/Shared/SideTabs/SideTabs';
import useMediaQuery from '../../hooks/useMediaQuery';
import { vars } from '../../reactive';
import { logout } from '../../utils/auth';
import { adjustCalendarDomView, calcRange } from '../../components/Calendar/utils';
import { toUtc } from '../../utils/dates';

export type CalendarNavigation = {
  prev: () => void;
  next: () => void;
  today: () => void;
  navigate: (date: Date, forceSetDate?: boolean) => void;
  changeView: (view: 'day' | 'week' | 'month', date?: Date) => void;
};

const Calendar = () => {
  const { mobile } = useMediaQuery({ mobile: true });
  const selectedBookingType = useReactiveVar(vars.calendarSelectedBookingType) || CALENDAR_VIEWS.SLOTS;
  const calendarRef = useRef<TheCalendarRef>(null);
  const calendarView = useReactiveVar(vars.calendarView) || 'week';
  const calendarRange = useReactiveVar(vars.calendarRange);
  const dateRange = calendarRange ? { from: new Date(calendarRange.from), to: new Date(calendarRange.to) } : calcRange(calendarView, new Date());
  const availabilityVisible = useReactiveVar(vars.calendarAvailabilityVisible) || false;
  const [selectedUser, setSelectedUser] = useState<string>('');

  const calendarRangeRef = useRef<CalendarRangeRef>(null);
  const prevDateRanges = useRef<Record<'day' | 'week' | 'month', { from: Date; to: Date } | null>>({
    day: null,
    week: null,
    month: null
  });

  const onNavigate = (date: Date, view: 'day' | 'week' | 'month', action: 'DATE' | 'TODAY' | 'PREV' | 'NEXT', { forceApiCall = false, forceSetDate = false } = {}) => {
    adjustCalendarDomView();
    const calendarRange = vars.calendarRange();
    const dateRange = calendarRange ? { from: new Date(calendarRange.from), to: new Date(calendarRange.to) } : calcRange(calendarView, new Date());
    let newRange = { from: dateRange.from, to: dateRange.to };
    if (action === 'DATE') {
      if (!prevDateRanges.current[view] || forceSetDate) {
        prevDateRanges.current[view] = calcRange(view, date);
      }
      newRange = prevDateRanges.current[view];
    } else if (action === 'TODAY') {
      newRange = calcRange(view, new Date());
    } else if (action === 'PREV') {
      const prevDate = new Date(date);
      if (view === 'month') prevDate.setMonth(prevDate.getMonth() - 1);
      if (view === 'week') prevDate.setDate(prevDate.getDate() - 7);
      if (view === 'day') prevDate.setDate(prevDate.getDate() - 1);
      newRange = calcRange(view, prevDate);
    } else if (action === 'NEXT') {
      const nextDate = new Date(date);
      if (view === 'month') nextDate.setMonth(nextDate.getMonth() + 1);
      if (view === 'week') nextDate.setDate(nextDate.getDate() + 7);
      if (view === 'day') nextDate.setDate(nextDate.getDate() + 1);
      newRange = calcRange(view, nextDate);
    }
    prevDateRanges.current[view] = newRange;
    vars.calendarRange(newRange);
    const newBookingType = vars.calendarSelectedBookingType() || CALENDAR_VIEWS.SLOTS;
    if (
      dateRange.from.getTime() === newRange.from.getTime() &&
      dateRange.to.getTime() === newRange.to.getTime() &&
      vars.calendarView() === view &&
      newBookingType === selectedBookingType &&
      !forceApiCall
    ) {
      return;
    }
    requestAnimationFrame(() => {
      calendarRef?.current?.refetch({
        timestamp_from: toUtc(newRange.from),
        timestamp_to: toUtc(newRange.to),
        booking_type: CALENDAR_VIEW_BOOKING_TYPE[newBookingType]
      });
    });
  };

  const calendarNavigation: CalendarNavigation = {
    prev: () => {
      onNavigate(dateRange.from, calendarView, 'PREV');
    },
    next: () => {
      onNavigate(dateRange.from, calendarView, 'NEXT');
    },
    today: () => {
      onNavigate(new Date(), calendarView, 'TODAY');
    },
    navigate: (date, forceSetDate) => {
      onNavigate(date, calendarView, 'DATE', { forceSetDate });
    },
    changeView: (view, date) => {
      vars.calendarView(view);
      onNavigate(date ?? new Date(), view, 'DATE', { forceSetDate: !!date });
    }
  };

  const bookingTypeOptions = [
    {
      name: CALENDAR_VIEWS_NAMES.SLOTS,
      value: CALENDAR_VIEWS.SLOTS,
      onClick: () => {
        vars.calendarSelectedBookingType(CALENDAR_VIEWS.SLOTS);
        calendarRangeRef.current?.clearRange();
        calendarNavigation.changeView(mobile ? 'day' : 'week');
      },
      divider: true,
      green: selectedBookingType === CALENDAR_VIEWS.SLOTS
    },
    {
      name: CALENDAR_VIEWS_NAMES.MULTI_DAY,
      value: CALENDAR_VIEWS.MULTI_DAY,
      onClick: () => {
        vars.calendarSelectedBookingType(CALENDAR_VIEWS.MULTI_DAY);
        vars.calendarView('month');
        calendarRangeRef.current?.clearRange();
        vars.calendarAvailabilityVisible(false);
        calendarNavigation.changeView('month');
      },
      green: selectedBookingType === CALENDAR_VIEWS.MULTI_DAY
    },
    {
      name: CALENDAR_VIEWS_NAMES.MULTI_STAFF,
      value: CALENDAR_VIEWS.MULTI_STAFF,
      onClick: () => {
        vars.calendarSelectedBookingType(CALENDAR_VIEWS.MULTI_STAFF);
        vars.calendarView('day');
        calendarRangeRef.current?.clearRange();
        vars.calendarAvailabilityVisible(false);
        calendarNavigation.changeView('day');
      },
      green: selectedBookingType === CALENDAR_VIEWS.MULTI_STAFF
    },
    ...(mobile
      ? [
          {
            name: 'Log Out',
            value: 'logout',
            onClick: logout,
            danger: true
          }
        ]
      : [])
  ];

  useEffect(() => {
    onNavigate(dateRange.from, calendarView, 'DATE', { forceApiCall: true });
    return () => {
      vars.selectedDate(null);
    };
  }, []);

  const sections = [{ tabs: bookingTypeOptions }];
  const isMultiDay = selectedBookingType === CALENDAR_VIEWS.MULTI_DAY;
  const isStaff = selectedBookingType === CALENDAR_VIEWS.MULTI_STAFF;

  return (
    <ViewWrapper>
      {!mobile && <SideTab sections={sections} defaultTab={selectedBookingType} type={SIDE_TABS_TYPES.STATE_BUTTONS} />}
      <MainContainer>
        <TheCalendarContainer flex={isMultiDay} isStaff={isStaff} selectedUser={!!selectedUser}>
          <TheCalendar
            ref={calendarRef}
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            calendarRangeRef={calendarRangeRef}
            bookingTypeOptions={bookingTypeOptions}
            calendarNavigation={calendarNavigation}
            availabilityVisible={availabilityVisible}
            calendarView={calendarView}
            dateRange={dateRange}
            selectedBookingType={selectedBookingType}
          />
        </TheCalendarContainer>
      </MainContainer>
    </ViewWrapper>
  );
};

export default Calendar;
