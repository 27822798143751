import { useMutation } from '@apollo/client';
import React, { FC, useEffect } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import ModalDialog, { ModalLayout } from '../../../components/Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../../../components/Modal/styled';
import { Pet } from '../../../components/Pets/types';
import { FormButtonsContainer, FormError, FormInput, FormLabel, FormSelect, FormSubmitButton, InputsWrapper, WideInputGroup } from '../../../components/Shared/Forms/Forms';
import BreedsList from '../../../components/Shared/Lists/BreedsList';
import PetColorsList from '../../../components/Shared/Lists/PetColorsList';
import { Container } from '../../../components/Shared/Shared';
import { EditBranchPet, EditPetRecord } from '../../../queries';
import { getKeys } from '../../../utils/helpers';
import { PetRecordSelect } from './styled';

type HealthPetRecordModalFormProps = {
  onSubmit: () => void;
  pet: Pet;
  loading: boolean;
  error: boolean;
  formOptions: ReturnType<typeof useForm>;
};

const HealthAllergiesConditionsModalForm: FC<HealthPetRecordModalFormProps> = ({ loading, error, pet, formOptions, onSubmit }) => {
  const { control, errors, setValue } = formOptions;

  const allergiesOptions = getKeys(pet?.PetRecord?.allergies);
  const currentAllergies = allergiesOptions.filter(allergy => !!pet?.PetRecord?.allergies[allergy]);
  const chronicConditionsOptions = getKeys(pet?.PetRecord?.chronic_conditions);
  const currentChronicConditions = chronicConditionsOptions.filter(cond => !!pet?.PetRecord?.chronic_conditions[cond]);
  const disabilitiesOptions = getKeys(pet?.PetRecord?.disabilities);
  const currentDisabilities = disabilitiesOptions.filter(disability => !!pet?.PetRecord?.disabilities[disability]);

  const petType = useWatch({
    control,
    name: 'type',
    defaultValue: pet?.type
  });

  const petGender = useWatch({
    control,
    name: 'gender',
    defaultValue: pet?.gender
  });

  useEffect(() => {
    if (petType && petType !== pet?.type) {
      setValue('breedId', '');
      setValue('petColorId', '');
    }
  }, [petType]);

  return (
    <ModalLayout
      compact
      buttons={[
        <FormSubmitButton error={error} loading={loading} onClick={onSubmit}>
          Update
        </FormSubmitButton>
      ]}
    >
      <WideInputGroup>
        <FormLabel>{petGender === 'FEMALE' ? 'Spayed' : 'Neutered'}</FormLabel>
        <Controller
          render={({ onChange }) => (
            <FormSelect height={48} fontSize={16} name={'neutred'} onChange={e => onChange(e.target.value)} defaultValue={!!pet.PetRecord?.neutred + ''}>
              <option value={'true'}>YES</option>
              <option value={'false'}>NO</option>
            </FormSelect>
          )}
          control={control}
          name={'neutred'}
          rules={{ required: true }}
          defaultValue={pet.PetRecord?.neutred + ''}
        />
        {errors.neutred && <FormError>{errors.neutred.message || 'Neutered is required'}</FormError>}
      </WideInputGroup>

      <WideInputGroup>
        <FormLabel>Allergies</FormLabel>
        <Controller
          as={
            <PetRecordSelect
              options={allergiesOptions.map(allergy => ({ label: allergy, value: allergy })) || null}
              defaultValue={currentAllergies.map(allergy => ({ label: allergy, value: allergy })) || null}
              onChange={undefined}
            />
          }
          control={control}
          name={'allergies'}
          defaultValue={currentAllergies.map(allergy => ({ label: allergy, value: allergy })) || null}
        />
        {errors.allergies && <FormError>{errors.allergies.message || 'Allergies is required'}</FormError>}
      </WideInputGroup>

      <WideInputGroup>
        <FormLabel>Chronic Conditions</FormLabel>
        <Controller
          as={
            <PetRecordSelect
              options={chronicConditionsOptions.map(cond => ({ label: cond, value: cond })) || null}
              defaultValue={currentChronicConditions.map(cond => ({ label: cond, value: cond })) || null}
              onChange={undefined}
            />
          }
          control={control}
          name={'chronic_conditions'}
          defaultValue={currentChronicConditions.map(cond => ({ label: cond, value: cond })) || null}
        />
        {errors.chronic_conditions && <FormError>{errors.chronic_conditions.message || 'Chronic conditions is required'}</FormError>}
      </WideInputGroup>

      <WideInputGroup>
        <FormLabel>Disabilities</FormLabel>
        <Controller
          as={
            <PetRecordSelect
              options={disabilitiesOptions.map(disability => ({ label: disability, value: disability })) || null}
              defaultValue={currentDisabilities.map(disability => ({ label: disability, value: disability })) || null}
              onChange={undefined}
            />
          }
          control={control}
          name={'disabilities'}
          defaultValue={currentDisabilities.map(disability => ({ label: disability, value: disability })) || null}
        />
        {errors.disabilities && <FormError>{errors.disabilities.message || 'Disabilities is required'}</FormError>}
      </WideInputGroup>
    </ModalLayout>
  );
};

const HealthAllergiesConditionsModal = ({ pet }: { pet: Pet }) => {
  const formOptions = useForm();
  const { handleSubmit } = formOptions;

  const [handleEditPetRecord, { data: editedPetRecord, loading: loadingEditPetRecord, error: errorEditPetRecord }] = useMutation(EditPetRecord);

  const [handleEditPet, { data: editedPet, loading: loadingEditPet, error: errorEditPet }] = useMutation(EditBranchPet);

  const onSubmit = handleSubmit(form => {
    ModalDialog?.closeModal();
    handleEditPetRecord({
      variables: {
        id: pet?.PetRecord?.id,
        allergies: getKeys(pet?.PetRecord?.allergies).reduce((allergies, allergy) => ({ ...allergies, [allergy]: !!form.allergies.find(al => al.value === allergy) }), {}),
        chronic_conditions: getKeys(pet?.PetRecord?.chronic_conditions).reduce(
          (chronic_conditions, cond) => ({ ...chronic_conditions, [cond]: !!form.chronic_conditions.find(co => co.value === cond) }),
          {}
        ),
        disabilities: getKeys(pet?.PetRecord?.disabilities).reduce((disabilities, disability) => ({ ...disabilities, [disability]: !!form.disabilities.find(dis => dis.value === disability) }), {}),
        neutred: form.neutred === 'true'
      }
    });
  });

  useEffect(() => {
    if (editedPetRecord?.editPetRecord?.id && editedPet?.editBranchPet?.id) {
      ModalDialog?.closeModal();
    }
  }, [editedPetRecord, editedPet]);

  const loading = loadingEditPetRecord || loadingEditPet;
  const error = !!errorEditPetRecord?.message || !!errorEditPet?.message;

  return <HealthAllergiesConditionsModalForm onSubmit={onSubmit} loading={loading} error={error} pet={pet} formOptions={formOptions} />;
};

export default HealthAllergiesConditionsModal;
