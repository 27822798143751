import styled, { css } from 'styled-components';
import Colors from '../../Colors';

export const GroupHeader = styled.div`
  padding: 16px;
  font-size: 16px;
  font-weight: bold;
  color: ${Colors.secondaryText};
`;

export const SubscriptionsDashboardContainer = styled.div`
  display: flex;
  flex: 1;
`;

export const PayoutHeader = styled.div`
  display: flex;
  align-items: center;
  height: 64px;
  padding: 0 20px;
  gap: 20px;
  border-bottom: 1px solid #e9e9e9;
`;

export const SubscriptionsDashboardWrapper = styled.div<{ noBorder?: boolean }>`
  & > :nth-child(odd) {
    background-color: #f8f8f8;
  }
  & > :nth-child(even) {
    background-color: ${Colors.white};
  }
  border-radius: 16px;
  display: flex;
  flex: 1;
  height: 64px;
  border: ${({ noBorder }) => (noBorder ? 'none' : '1px solid #e9e9e9')};
  box-shadow: 0px 10px 16px 0px rgba(0, 0, 0, 0.05);
`;

export const SubscriptionsDashboardItem = styled.div<{ flexOne?: boolean; clickable?: boolean; selectable?: boolean; width?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 12px;
  outline: none;
  box-sizing: border-box;
  white-space: nowrap;
  user-select: ${({ selectable }) => (selectable ? 'text' : 'none')};
  outline: none;
  border: none;
  font-weight: 800;
  color: #000;
  padding: 0 16px;
  min-width: 160px;
  /* flex: 1; */
  align-self: stretch;
  gap: 8px;
  &:not(:last-child) {
    border-right: 1px solid ${Colors.border};
  }
  ${({ flexOne }) =>
    flexOne &&
    css`
      flex: 1;
    `}
  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
    `}
    width: ${({ width }) => width || 'auto'};
`;
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;
export const SubscriptionsDashboardItemTitle = styled.span`
  font-size: 12px;
  font-weight: 800;
  text-align: left;
`;

export const SubscriptionsDashboardItemSubtitle = styled.span`
  font-size: 14px;
  font-weight: 800;
  display: flex;
  justify-content: space-between;
  flex: 1;
`;
