import { useMutation, useQuery } from '@apollo/client';
import React from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import { ColorPicker, FormError, FormInput, FormLabel, InputGroup, selectTheme } from '../../../components/Shared/Forms/Forms';
import { AddBranchArea, GetAllServices, GetBranchAreas } from '../../../queries';
import { Product } from '../../Store/types';
import { BranchArea, BranchRoute } from '../types';
import { BranchRouteFormValues } from './types';
import Select from '../../../components/Shared/Forms/Select';

const BranchRouteModalSettings = ({ formOptions, branchRoute }: { formOptions: UseFormMethods<BranchRouteFormValues>; branchRoute?: BranchRoute }) => {
  const { control, errors } = formOptions;
  const { data: { getBranchAreas: branchAreas = [] } = {} } = useQuery<{ getBranchAreas: BranchArea[] }>(GetBranchAreas, {
    fetchPolicy: 'cache-and-network'
  });

  const [addBranchArea, { loading: loadingAddBranchArea }] = useMutation(AddBranchArea, {
    refetchQueries: ['getBranchAreas'],
    awaitRefetchQueries: true
  });

  const { data: { getProducts: services = [] } = {} } = useQuery<{ getProducts: Product[] }>(GetAllServices, {
    fetchPolicy: 'cache-and-network'
  });

  return (
    <>
      <InputGroup>
        <FormLabel>Route Name</FormLabel>
        <Controller as={<FormInput error={!!errors?.branchRoute?.name} />} control={control} name={`branchRoute.name`} defaultValue={branchRoute?.name || ''} rules={{ required: true }} />
        {!!errors?.branchRoute?.name && <FormError>{errors?.branchRoute?.name?.message || 'Name is required'}</FormError>}
      </InputGroup>
      <InputGroup>
        <FormLabel>Zone/Area</FormLabel>
        <Controller
          render={({ onChange, value }) => (
            <Select
              theme={selectTheme}
              isLoading={loadingAddBranchArea}
              onCreateOption={async newValue => {
                const { data: { addBranchArea: newBranchArea = {} } = {} } = await addBranchArea({
                  variables: {
                    name: newValue
                  }
                });
                requestAnimationFrame(() => onChange(newBranchArea.id));
              }}
              isCreatable
              onChange={newValue => onChange(newValue?.value)}
              value={value ? { label: branchAreas.find(area => area.id === value)?.name, value } : null}
              options={branchAreas.map(area => ({ label: area.name, value: area.id }))}
            />
          )}
          control={control}
          name={`branchRoute.BranchAreaId`}
          defaultValue={branchRoute?.BranchAreaId || ''}
          rules={{ required: true }}
        />
        {!!errors?.branchRoute?.BranchAreaId && <FormError>{errors?.branchRoute?.BranchAreaId?.message || 'Branch Area is required'}</FormError>}
      </InputGroup>
      <InputGroup>
        <FormLabel>Sort Index</FormLabel>
        <Controller
          as={<FormInput error={!!errors?.branchRoute?.sort_index} />}
          control={control}
          name={`branchRoute.sort_index`}
          defaultValue={branchRoute?.sort_index || ''}
          rules={{ required: true }}
        />
        {!!errors?.branchRoute?.sort_index && <FormError>{errors?.branchRoute?.sort_index?.message || 'Sort Index is required'}</FormError>}
      </InputGroup>
      <InputGroup>
        <FormLabel>Color</FormLabel>
        <Controller as={<ColorPicker type="color" />} control={control} name={`branchRoute.color`} defaultValue={branchRoute?.color || ''} rules={{ required: true }} />
        {!!errors?.branchRoute?.color && <FormError>{errors?.branchRoute?.color?.message || 'Color is required'}</FormError>}
      </InputGroup>
      <InputGroup>
        <FormLabel>Start Time</FormLabel>
        <Controller
          as={<FormInput type="time" error={!!errors?.branchRoute?.start_time} />}
          control={control}
          name={`branchRoute.start_time`}
          defaultValue={branchRoute?.start_time || ''}
          rules={{ required: true }}
        />
        {!!errors?.branchRoute?.start_time && <FormError>{errors?.branchRoute?.start_time?.message || 'Start Time is required'}</FormError>}
      </InputGroup>
      <InputGroup>
        <FormLabel>Job Duration</FormLabel>
        <Controller
          as={<FormInput type="number" error={!!errors?.branchRoute?.job_duration} />}
          control={control}
          name={`branchRoute.job_duration`}
          defaultValue={branchRoute?.job_duration || ''}
          rules={{ required: true }}
        />
        {!!errors?.branchRoute?.job_duration && <FormError>{errors?.branchRoute?.job_duration?.message || 'Job Duration is required'}</FormError>}
      </InputGroup>
      <InputGroup>
        <FormLabel>Services</FormLabel>
        <Controller
          render={({ onChange, value }) => (
            <Select
              isMulti
              theme={selectTheme}
              name={'branchRoute.ProductId'}
              onChange={newValue => {
                if (!newValue?.length) {
                  onChange(undefined);
                  return;
                }
                onChange(newValue?.map(item => item?.id));
              }}
              value={services?.filter(product => value?.includes(product?.id))}
              options={services}
              getOptionLabel={option => option?.name}
              getOptionValue={option => option?.id}
            />
          )}
          control={control}
          name={`branchRoute.ProductId`}
          defaultValue={branchRoute?.BranchRouteProducts?.map(product => product?.Product?.id) || []}
          rules={{ required: true }}
        />
      </InputGroup>
    </>
  );
};

export default BranchRouteModalSettings;
