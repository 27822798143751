import { useLazyQuery, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { navigate } from 'gatsby';
import React, { useCallback, useEffect } from 'react';
import Notifier from '../Notifier';
import Alert from '../components/Alert/Alert';
import { DRAWER_IDS } from '../components/DrawerBar/types';
import ModalDialog from '../components/Modal/ModalDialog';
import NewBookingModal from '../components/Shared/Modals/NewBookingModal/NewBookingModal';
import RefundModal from '../components/Shared/Modals/RefundModal/RefundModal';
import { CompleteOrderPayment, GetBusUserProfile, GetOrderItems, RetryOrderPayment, UpdateAppointmentsStatus, UpdateOrderStatus } from '../queries';
import { vars } from '../reactive';
import { hideCalendarActionMessage, showCalendarActionMessage, unsetDrawerBar, unsetDrawerBars } from '../reactive/actions';
import { isUserSuperVisorOrAdminOrSuper } from '../utils/helpers';
import { BOOKING_STATUS_TYPES, BOOKING_TYPE, Booking, BookingOrder } from '../views/Bookings/types';
import { ORDER_STATUS } from '../views/Orders/types';
import Common from '../views/Store/Common';
import useCheckBranchAvailability from './useCheckBranchAvailability';
import useMediaQuery from './useMediaQuery';
import useReduceAppointments from './useReduceAppointments';
import AdjustPaymentModal from '../views/Bookings/components/BookingsDrawer/AdjustPaymentModal';

export default function useBookingActions({
  booking,
  options = { loadOrderItems: false, loadAvailability: false, refetch: () => {} }
}: {
  booking?: BookingOrder;
  options?: { loadOrderItems?: boolean; loadAvailability?: boolean; refetch?: () => void };
}) {
  const { loadOrderItems, loadAvailability, refetch } = options;
  const drawerBars = useReactiveVar(vars.drawerBars);
  const orderItem = booking?.[0]?.OrderItem;
  const product = orderItem?.item;
  const order = orderItem?.Order;
  const branch = order?.Branch;
  const inNew = booking?.[0]?.status === BOOKING_STATUS_TYPES.REQUESTED;
  const inDrawer = !!drawerBars?.find(drawerBar => drawerBar.drawerId === DRAWER_IDS.BOOKING_DRAWER);
  const isMultiSlot = product?.booking_type === BOOKING_TYPE.MULTI_SLOT;
  const isMultiDay = product?.booking_type === BOOKING_TYPE.MULTI_DAY;
  const shouldDisplayAvailability = !!loadAvailability && inDrawer && !isMultiSlot && inNew;

  const { mobile } = useMediaQuery({ mobile: true });

  const [getOrderItems, { data: { getOrderItems: orderItems = [] } = {}, loading: loadingOrderItems }] = useLazyQuery<{ getOrderItems: BookingOrder }>(GetOrderItems);

  const bookingsToRender = useReduceAppointments(
    orderItems?.flatMap(item => item.Appointments) || [],
    {
      uniqueByOrderIdAndTimestamp: true
    } || []
  );

  const appointmentsDates = bookingsToRender.map(a => new Date(a?.[0]?.timestamp));

  const [bookingsUpdateStatus, { data: { updateAppointmentsStatus: updatedBooking } = {}, loading: loadingUpdateBooking, error }] = useMutation(UpdateAppointmentsStatus, {
    refetchQueries: ['getBranchAppointments', 'getBranchAppointmentsViews', 'getBranchServiceOrdersViews']
  });

  const [confirmPayment, { data: { completeOrderPayment: { status } = {} } = {}, loading: loadingConfirmPayment }] = useMutation(CompleteOrderPayment, {
    refetchQueries: ['getBranchAppointments', 'getBranchAppointmentsViews', 'getBranchServiceOrdersViews']
  });

  const [retryPayment, { loading: loadingRetryPayment }] = useMutation(RetryOrderPayment, {
    refetchQueries: ['getBranchAppointments', 'getBranchAppointmentsViews', 'getBranchServiceOrdersViews']
  });

  const [updateOrderStatus, { loading: loadingUpdateOrderStatus }] = useMutation(UpdateOrderStatus, {
    refetchQueries: ['getBranchAppointments', 'getBranchAppointmentsViews', 'getBranchServiceOrdersViews']
  });

  const { data: { getBusUserProfile: currentBusUserProfile = {} } = {} } = useQuery(GetBusUserProfile, {
    skip: !booking?.[0]?.id
  });

  const { loading: loadingAvailability, selectedSlotForEachDate } = useCheckBranchAvailability({
    appointmentsDates,
    selectedProduct: product,
    branch,
    appointmentDatesWithTime: appointmentsDates,
    skip: !shouldDisplayAvailability
  });

  const loading = loadingUpdateBooking || loadingConfirmPayment || loadingOrderItems || loadingAvailability || loadingRetryPayment || loadingUpdateOrderStatus;

  const inConfirmed = booking?.[0]?.status === BOOKING_STATUS_TYPES.CONFIRMED;
  const inCanceled = booking?.[0]?.status === BOOKING_STATUS_TYPES.CANCELED;

  const isPartiallyOrFullyPaid = [ORDER_STATUS.PAYMENT_SUCCEEDED, ORDER_STATUS.PARTIALLY_REFUNDED].includes(order?.status);

  const shouldDisplayCancel = !loading && inConfirmed;
  const hasOrderBills = !!order?.OrderBills?.length;
  const hasInvoice = !!order?.OrderBills?.[0]?.OrderInvoice?.status;
  const isUnbilled = hasOrderBills && !hasInvoice;
  const isRequested = [ORDER_STATUS.REQUESTED, ORDER_STATUS.DISCOUNT_APPLIED].includes(order?.status);
  const shouldDisplayAdjustAmount = inConfirmed && (isRequested || isUnbilled);
  const shouldDisplayRefund = !loading && (inConfirmed || inCanceled) && isPartiallyOrFullyPaid;

  const shouldDisplayConfirmPayment =
    !loading && inConfirmed && [ORDER_STATUS.REQUESTED, ORDER_STATUS.DISCOUNT_APPLIED].includes(order?.status) && BOOKING_STATUS_TYPES.CONFIRMED === booking?.[0]?.status;

  const shouldDisplayRetryPayment = !loading && inConfirmed && [ORDER_STATUS.PAYMENT_PENDING, ORDER_STATUS.PAYMENT_REJECTED].includes(order?.status);

  const shouldDisplayMarkAsPaid =
    !loading && inConfirmed && [ORDER_STATUS.REQUESTED, ORDER_STATUS.DISCOUNT_APPLIED, ORDER_STATUS.PAYMENT_PENDING, ORDER_STATUS.PAYMENT_REJECTED].includes(order?.status);

  const shouldDisplayConfirmationAndRejection =
    !loading &&
    BOOKING_STATUS_TYPES.REQUESTED === booking?.[0]?.status &&
    [ORDER_STATUS.REQUESTED, ORDER_STATUS.DISCOUNT_APPLIED, ORDER_STATUS.PAYMENT_PENDING, ORDER_STATUS.PAYMENT_SUCCEEDED, ORDER_STATUS.CONFIRMED].includes(order?.status);

  const showAdjustButton = inConfirmed && (isRequested || isUnbilled);

  const isAdmin = isUserSuperVisorOrAdminOrSuper(currentBusUserProfile);
  const adminInConfirmed = inConfirmed && isAdmin;
  const shouldDisplayAssign = !loadingOrderItems && isAdmin;
  const shouldDisplayAssignToBranchRoom = isMultiDay;
  const shouldDisplayReschedule = !loadingOrderItems && isAdmin;
  const shouldDisplayUpdateOrderBills = !loading && inConfirmed && !hasInvoice && !!order?.OrderBills?.length;
  const shouldDisplayUpdatePayment = !loading && inConfirmed && !hasInvoice && false;

  const shouldDisplayUpdateDuration = !loading && !isMultiDay;

  const handleConfirmation = useCallback(() => {
    return bookingsUpdateStatus({
      variables: { OrderId: booking?.[0]?.OrderItem?.Order?.id, status: BOOKING_STATUS_TYPES.CONFIRMED }
    });
  }, [booking, bookingsUpdateStatus]);

  const handleRejectCancel = useCallback(
    (then: (...args: unknown[]) => void = () => {}) => {
      Alert.alert({
        title: 'Cancel Booking',
        acceptButtonText: 'Yes',
        denyButtonText: 'No',
        description: 'Are you sure you want to cancel this booking?',
        onAccept: async () => {
          if (!isPartiallyOrFullyPaid) {
            return bookingsUpdateStatus({
              variables: { OrderId: booking?.[0]?.OrderItem?.Order?.id, status: BOOKING_STATUS_TYPES.CANCELED }
            }).then(then);
          }
          ModalDialog.openModal({
            content: () => (
              <RefundModal
                order={booking?.[0]?.OrderItem?.Order}
                shouldIssueRefund={false}
                onConfirm={refundVars => {
                  const refundInfo = {
                    ...refundVars,
                    amount: `${refundVars.amount}`,
                    isBulk: false
                  };
                  bookingsUpdateStatus({
                    variables: { OrderId: booking?.[0]?.OrderItem?.Order?.id, status: BOOKING_STATUS_TYPES.CANCELED, refund: refundInfo }
                  }).then(then);
                }}
                onDontRefund={() =>
                  bookingsUpdateStatus({
                    variables: { OrderId: booking?.[0]?.OrderItem?.Order?.id, status: BOOKING_STATUS_TYPES.CANCELED }
                  }).then(then)
                }
              />
            ),
            title: 'Refund Payment'
          });
        }
      });
    },
    [booking, bookingsUpdateStatus, isPartiallyOrFullyPaid]
  );

  const handleRefund = useCallback(
    (then: (...args: unknown[]) => void = () => {}) => {
      ModalDialog.openModal({
        content: () => <RefundModal order={booking?.[0]?.OrderItem?.Order} shouldIssueRefund />,
        title: 'Refund Payment',
        onClose() {
          setTimeout(() => {
            then();
          }, 3000);
        }
      });
    },
    [booking, bookingsUpdateStatus]
  );

  const handleConfirmPayment = useCallback(async () => {
    return confirmPayment({ variables: { orderId: order?.id } })?.then(() => {
      refetch?.();
    });
  }, [confirmPayment, order]);

  const handleRetryPayment = useCallback(async () => {
    return retryPayment({ variables: { orderId: order?.id } })?.then(() => {
      refetch?.();
    });
  }, [retryPayment, order]);

  const handleMarkAsPaid = useCallback(async () => {
    return updateOrderStatus({ variables: { id: order?.id, status: 'CONFIRMED', payment_required: false } })?.then(() => {
      refetch?.();
    });
  }, [confirmPayment, order]);

  useEffect(() => {
    if (loadOrderItems && !!booking?.[0]?.OrderItem?.Order?.id) {
      getOrderItems({
        variables: {
          OrderId: booking?.[0]?.OrderItem?.Order?.id
        }
      });
    }
  }, [loadOrderItems, booking, getOrderItems, booking?.[0]?.OrderItem?.Order?.id]);

  useEffect(() => {
    if (updatedBooking) {
      unsetDrawerBar(DRAWER_IDS.BOOKING_DRAWER);
      Notifier.success({ message: 'Request Successful' });
    }
  }, [updatedBooking]);

  const handleCalendarNewBooking = ({
    appointments,
    options
  }: {
    appointments: BookingOrder;
    options?: {
      openCalendar?: boolean;
    };
  }) => {
    unsetDrawerBars();
    const { openCalendar = true } = options || {};
    if (openCalendar) {
      navigate('/app/calendar');
    }
    showCalendarActionMessage();
    vars.newBookingDefaultAppointments(appointments);
  };

  const handleNewBooking = () => {
    vars.newBookingDefaultAppointments(booking);
    ModalDialog.openModal({
      content: () => <NewBookingModal />,
      title: 'New booking',
      onClose: () => {
        vars.newBookingDefaultAppointments([]);
        vars.selectedDate(null);
        hideCalendarActionMessage();
      },
      onCloseBySave: () => {
        setTimeout(() => {
          const refetchAppointments = Common.get<() => Promise<{ data: { getBranchAppointments: Booking[] } }>>(`Bookings.GetBranchAppointments.refetch`);
          refetch ? refetch() : refetchAppointments();
          vars.selectedDate(null);
        }, 3000);
      }
    });
  };

  const handleNewProductBooking = () => {
    vars.newBookingDefaultAppointments(booking);
    ModalDialog.openModal({
      content: () => <NewBookingModal productModalType />,
      title: 'New Order',
      onClose() {
        vars.newBookingDefaultAppointments([]);
        vars.selectedDate(null);
        hideCalendarActionMessage();
      }
    });
  };

  const handleAdjustAmount = () => {
    ModalDialog.openModal({
      title: 'Adjust Payment Amount',
      content: () => <AdjustPaymentModal oldAmount={order?.total} orderId={order?.id} />
    });
  };

  const pets = booking?.map(item => item?.PetRecord?.Pet);
  const uniquePets = [...new Set(pets?.map(pet => pet?.id))].map(id => pets?.find(pet => pet?.id === id)!);

  const shouldDisplayNewBooking = adminInConfirmed && !mobile;
  const shouldDisplayPetsOptions = true;
  const shouldDisplayTagsOptions = true;
  if (!booking || !booking.length) {
    return {
      orderItems: [],
      shouldDisplay: {
        shouldDisplayConfirmPayment: false,
        shouldDisplayRetryPayment: false,
        shouldDisplayMarkAsPaid: false,
        shouldDisplayConfirmationAndRejection: false,
        showAdjustButton: false,
        shouldDisplayAssign: false,
        shouldDisplayAssignToBranchRoom: false,
        shouldDisplayReschedule: false,
        shouldDisplayUpdateOrderBills: false,
        adminInConfirmed: false,
        shouldDisplayCancel: false,
        shouldDisplayRefund: false,
        inConfirimed: false,
        inNew: false,
        shouldDisplayNewBooking: false,
        shouldDisplayPetsOptions: false,
        shouldDisplayTagsOptions: false,
        shouldDisplayAvailability: false,
        shouldDisplayUpdatePayment: false,
        shouldDisplayUpdateDuration: false,
        shouldDisplayAdjustAmount: false
      },
      actions: {
        handleConfirmation: () => Promise.resolve(),
        handleRejectCancel: () => Promise.resolve(),
        handleRefund: () => Promise.resolve(),
        handleConfirmPayment: () => Promise.resolve(),
        handleCalendarNewBooking: () => {},
        handleNewBooking: () => {},
        handleNewProductBooking: () => {},
        handleRetryPayment: () => {},
        handleMarkAsPaid: () => {},
        handleAdjustAmount: () => {}
      },
      loading: false,
      error: null,
      pets: [],
      bookingsToRender: [],
      selectedSlotForEachDate: []
    };
  }

  return {
    orderItems,
    shouldDisplay: {
      shouldDisplayConfirmPayment,
      shouldDisplayRetryPayment,
      shouldDisplayMarkAsPaid,
      shouldDisplayConfirmationAndRejection,
      showAdjustButton,
      shouldDisplayAssign,
      shouldDisplayReschedule,
      shouldDisplayUpdateOrderBills,
      shouldDisplayUpdatePayment,
      adminInConfirmed,
      shouldDisplayCancel,
      shouldDisplayRefund,
      inConfirmed,
      inNew,
      shouldDisplayNewBooking,
      shouldDisplayPetsOptions,
      shouldDisplayTagsOptions,
      shouldDisplayAvailability,
      shouldDisplayUpdateDuration,
      shouldDisplayAssignToBranchRoom,
      shouldDisplayAdjustAmount
    },
    actions: {
      handleConfirmation,
      handleRejectCancel,
      handleConfirmPayment,
      handleCalendarNewBooking,
      handleNewBooking,
      handleNewProductBooking,
      handleRetryPayment,
      handleMarkAsPaid,
      handleRefund,
      handleAdjustAmount
    },
    loading,
    error,
    pets: uniquePets,
    bookingsToRender,
    selectedSlotForEachDate
  };
}

export const useGroupBookingsActions = (
  { bookings = [], options = { refetch: () => {} } }: { bookings?: BookingOrder[]; options?: { refetch?: () => void } } = { bookings: [], options: { refetch: () => {} } }
) => {
  const { refetch } = options;

  const groupPets = bookings.map(bookingsInOrder => bookingsInOrder?.map(booking => booking.PetRecord.Pet)).flat();
  const firstBooking = bookings?.[0]?.[0];
  const firstOrderId = firstBooking?.OrderItem?.Order?.id;

  const { data: { getBusUserProfile: currentBusUserProfile = {} } = {} } = useQuery(GetBusUserProfile);
  const inConfirmed = bookings.every(booking => booking[0]?.status === BOOKING_STATUS_TYPES.CONFIRMED);
  const isPartiallyOrFullyPaid = bookings?.every(booking => [ORDER_STATUS.PAYMENT_SUCCEEDED, ORDER_STATUS.PARTIALLY_REFUNDED].includes(booking?.[0]?.OrderItem?.Order.status));
  const isAdmin = isUserSuperVisorOrAdminOrSuper(currentBusUserProfile);
  const adminInConfirmed = inConfirmed && isAdmin;
  const allIds = bookings.flatMap(bookingOrder => bookingOrder.map(booking => booking.id));

  const shouldDisplayGroupAssign = isAdmin;
  const shouldDisplayGroupReschedule = isAdmin;
  const shouldDisplayGroupUpdateOrderBills =
    inConfirmed && bookings?.every(booking => !!booking?.[0]?.OrderItem?.Order?.OrderBills?.length && booking?.[0]?.OrderItem?.Order?.OrderBills?.every(orderBill => !orderBill?.OrderInvoice?.id));

  const [bookingsUpdateStatus, { data: { updateAppointmentsStatus: updatedBookings } = {}, loading: loadingUpdateBooking, error }] = useMutation(UpdateAppointmentsStatus);

  const loading = loadingUpdateBooking;

  const shouldDisplayGroupMessage = inConfirmed && false;
  const shouldDisplayPetsOptions = true;
  const shouldDisplayGroupTagsOptions = true;
  const shouldDisplayGroupCancel = !loading && bookings.every(booking => booking[0]?.status !== BOOKING_STATUS_TYPES.CANCELED);
  const shouldDisplayGroupConfirmationAndRejection = !loading && bookings.some(booking => booking[0]?.status === BOOKING_STATUS_TYPES.REQUESTED);

  const handleBulkConfirmation = useCallback(
    ({ onAccept = () => {} }: any) => {
      Alert.alert({
        title: 'Confirm Booking',
        description: 'Are you sure you want to confirm selected bookings?',
        acceptButtonText: 'Yes',
        denyButtonText: 'No',
        onAccept: async () => {
          onAccept();
          return bookingsUpdateStatus({
            variables: { id: allIds, status: BOOKING_STATUS_TYPES.CONFIRMED, disableNotifications: true }
          }).then(() => {
            unsetDrawerBar(DRAWER_IDS.BOOKING_DRAWER);
            Notifier.success({ message: 'Booking has been confirmed' });
            refetch?.();
          });
        }
      });
    },
    [bookings, bookingsUpdateStatus]
  );

  const handleBulkCancel = useCallback(
    ({ onAccept = () => {} }: any) => {
      Alert.alert({
        title: 'Cancel Booking',
        description: 'Are you sure you want to cancel selected bookings?',
        acceptButtonText: 'Yes',
        denyButtonText: 'No',
        onAccept: async () => {
          onAccept();
          if (!isPartiallyOrFullyPaid) {
            return bookingsUpdateStatus({
              variables: { id: allIds, status: BOOKING_STATUS_TYPES.CANCELED, disableNotifications: true }
            }).then(() => {
              unsetDrawerBar(DRAWER_IDS.BOOKING_DRAWER);
              Notifier.success({ message: 'Booking has been canceled' });
              refetch?.();
            });
          }

          ModalDialog.openModal({
            content: () => (
              <RefundModal
                order={bookings?.[0]?.OrderItem?.Order}
                shouldIssueRefund={false}
                onConfirm={refundVars => {
                  const refundInfo = {
                    ...refundVars,
                    amount: `${refundVars.amount}`,
                    isBulk: true
                  };
                  bookingsUpdateStatus({
                    variables: { id: allIds, status: BOOKING_STATUS_TYPES.CANCELED, refund: refundInfo, disableNotifications: true }
                  }).then(() => {
                    unsetDrawerBar(DRAWER_IDS.BOOKING_DRAWER);
                    Notifier.success({ message: 'Payment has been refunded' });
                    refetch?.();
                  });
                }}
                onDontRefund={() =>
                  bookingsUpdateStatus({
                    variables: { id: allIds, status: BOOKING_STATUS_TYPES.CANCELED, disableNotifications: true }
                  }).then(() => {
                    unsetDrawerBar(DRAWER_IDS.BOOKING_DRAWER);
                    Notifier.success({ message: 'Booking has been canceled', description: 'No payment has been refunded' });
                    refetch?.();
                  })
                }
              />
            ),
            title: 'Refund Payment'
          });
        }
      });
    },
    [bookings, bookingsUpdateStatus, isPartiallyOrFullyPaid]
  );

  if (!bookings || !bookings.length) {
    return {
      shouldDisplay: {
        shouldDisplayGroupAssign: false,
        shouldDisplayGroupReschedule: false,
        adminInConfirmed: false,
        inConfirmed: false,
        shouldDisplayGroupMessage: false,
        shouldDisplayPetsOptions: false,
        shouldDisplayGroupTagsOptions: false,
        shouldDisplayGroupCancel: false,
        shouldDisplayGroupConfirmationAndRejection: false,
        shouldDisplayGroupUpdateOrderBills: false,
        shouldDisplayGroupUpdatePayment: false,
        shouldDisplayGroupUpdateDuration: false
      },
      actions: {
        handleBulkConfirmation: () => Promise.resolve(),
        handleBulkCancel: () => Promise.resolve()
      },
      groupPets: [],
      firstOrderId: null
    };
  }

  return {
    shouldDisplay: {
      shouldDisplayGroupAssign,
      shouldDisplayGroupReschedule,
      adminInConfirmed,
      inConfirmed,
      shouldDisplayGroupMessage,
      shouldDisplayPetsOptions,
      shouldDisplayGroupTagsOptions,
      shouldDisplayGroupCancel,
      shouldDisplayGroupConfirmationAndRejection,
      shouldDisplayGroupUpdateOrderBills,
      shouldDisplayGroupUpdatePayment: false,
      shouldDisplayGroupUpdateDuration: false
    },
    actions: { handleBulkConfirmation, handleBulkCancel },
    groupPets,
    firstOrderId
  };
};
