import { SelectedFilter } from '../../../components/Shared/Filters/config';
import { getFilter, getMoreOptionsValues } from '../../../components/Shared/Filters/helpers';
import useFilters from '../../../components/Shared/Filters/useFilters';
import { OPTION_DROPDOWN_TYPES } from '../../../components/Shared/Menus/OptionDropdown/types';

enum STORE_SERVICES_FILTER_TYPES {
  STORE_SERVICES_SEARCH = 'STORE_SERVICES_SEARCH',
  STORE_SERVICES_TYPE = 'STORE_SERVICES_TYPE',
  STORE_SERVICES_STATUS = 'STORE_SERVICES_STATUS'
}

const HIDDEN_STORE_SERVICES_FILTERS = {
  [STORE_SERVICES_FILTER_TYPES.STORE_SERVICES_SEARCH]: true,
  [STORE_SERVICES_FILTER_TYPES.STORE_SERVICES_TYPE]: true,
  [STORE_SERVICES_FILTER_TYPES.STORE_SERVICES_STATUS]: true
};

const STORE_SERVICES_FILTERS_QUERIES = {
  [STORE_SERVICES_FILTER_TYPES.STORE_SERVICES_SEARCH]: ['product_search'],
  [STORE_SERVICES_FILTER_TYPES.STORE_SERVICES_TYPE]: ['type'],
  [STORE_SERVICES_FILTER_TYPES.STORE_SERVICES_STATUS]: ['status']
} as const;

enum STORE_SERVICES_MORE_OPTIONS_TYPES {
  STORE_SERVICES_SEARCH = 'STORE_SERVICES_SEARCH',
  STORE_SERVICES_TYPE = 'STORE_SERVICES_TYPE',
  STORE_SERVICES_STATUS = 'STORE_SERVICES_STATUS'
}

const STORE_SERVICES_FILTER_TYPES_NAMES: Record<STORE_SERVICES_FILTER_TYPES, string> = {
  [STORE_SERVICES_FILTER_TYPES.STORE_SERVICES_SEARCH]: 'Search',
  [STORE_SERVICES_FILTER_TYPES.STORE_SERVICES_TYPE]: 'Type',
  [STORE_SERVICES_FILTER_TYPES.STORE_SERVICES_STATUS]: 'Status'
};

const STORE_SERVICES_MORE_OPTIONS_TITLES: Record<STORE_SERVICES_MORE_OPTIONS_TYPES, string> = {
  [STORE_SERVICES_MORE_OPTIONS_TYPES.STORE_SERVICES_SEARCH]: '',
  [STORE_SERVICES_MORE_OPTIONS_TYPES.STORE_SERVICES_TYPE]: '',
  [STORE_SERVICES_MORE_OPTIONS_TYPES.STORE_SERVICES_STATUS]: ''
};

const STORE_SERVICES_MORE_OPTIONS_DROPDOWN_TYPES: Record<STORE_SERVICES_MORE_OPTIONS_TYPES, OPTION_DROPDOWN_TYPES> = {
  [STORE_SERVICES_MORE_OPTIONS_TYPES.STORE_SERVICES_SEARCH]: OPTION_DROPDOWN_TYPES.TEXT_INPUT,
  [STORE_SERVICES_MORE_OPTIONS_TYPES.STORE_SERVICES_TYPE]: OPTION_DROPDOWN_TYPES.CHECKBOX,
  [STORE_SERVICES_MORE_OPTIONS_TYPES.STORE_SERVICES_STATUS]: OPTION_DROPDOWN_TYPES.CHECKBOX
};

const STORE_SERVICES_FILTER_TYPES_UNITS: Record<STORE_SERVICES_FILTER_TYPES, string> = {
  [STORE_SERVICES_FILTER_TYPES.STORE_SERVICES_SEARCH]: '',
  [STORE_SERVICES_FILTER_TYPES.STORE_SERVICES_TYPE]: '',
  [STORE_SERVICES_FILTER_TYPES.STORE_SERVICES_STATUS]: ''
};
const STORE_SERVICES_FILTER_TYPES_MORE_OPTIONS: Record<STORE_SERVICES_FILTER_TYPES, STORE_SERVICES_MORE_OPTIONS_TYPES[]> = {
  [STORE_SERVICES_FILTER_TYPES.STORE_SERVICES_SEARCH]: [STORE_SERVICES_MORE_OPTIONS_TYPES.STORE_SERVICES_SEARCH],
  [STORE_SERVICES_FILTER_TYPES.STORE_SERVICES_TYPE]: [STORE_SERVICES_MORE_OPTIONS_TYPES.STORE_SERVICES_TYPE],
  [STORE_SERVICES_FILTER_TYPES.STORE_SERVICES_STATUS]: [STORE_SERVICES_MORE_OPTIONS_TYPES.STORE_SERVICES_STATUS]
};

const STORE_SERVICES_MORE_OPTIONS_ITEMS: Record<STORE_SERVICES_MORE_OPTIONS_TYPES, { value: string | boolean; name: string }[] | []> = {
  [STORE_SERVICES_MORE_OPTIONS_TYPES.STORE_SERVICES_SEARCH]: [],
  [STORE_SERVICES_MORE_OPTIONS_TYPES.STORE_SERVICES_TYPE]: [],
  [STORE_SERVICES_MORE_OPTIONS_TYPES.STORE_SERVICES_STATUS]: []
};

const useServicesFilters = () => {
  const filtersControl = useFilters<STORE_SERVICES_FILTER_TYPES, STORE_SERVICES_MORE_OPTIONS_TYPES>({
    toCall: [],
    getOptions: () => ({
      FILTER_TYPES: STORE_SERVICES_FILTER_TYPES,
      FILTER_TYPES_MORE_OPTIONS: STORE_SERVICES_FILTER_TYPES_MORE_OPTIONS,
      FILTER_TYPES_NAMES: STORE_SERVICES_FILTER_TYPES_NAMES,
      FILTER_TYPES_UNITS: STORE_SERVICES_FILTER_TYPES_UNITS,
      MORE_OPTIONS_DROPDOWN_TYPES: STORE_SERVICES_MORE_OPTIONS_DROPDOWN_TYPES,
      MORE_OPTIONS_TITLES: STORE_SERVICES_MORE_OPTIONS_TITLES,
      MORE_OPTIONS_TYPES: STORE_SERVICES_MORE_OPTIONS_TYPES,
      MORE_OPTIONS_ITEMS: STORE_SERVICES_MORE_OPTIONS_ITEMS
    })
  });

  return filtersControl;
};
const getServicesFiltersVariables = ({
  defaultVariables,
  selectedFilters,
  overriddenFilters
}: {
  defaultVariables?: Record<string, any>;
  selectedFilters: SelectedFilter[];
  overriddenFilters?: Partial<Record<(typeof STORE_SERVICES_FILTERS_QUERIES)[keyof typeof STORE_SERVICES_FILTERS_QUERIES][number], any>>;
}) => {
  const getFilterValue = (filter: STORE_SERVICES_FILTER_TYPES) => getFilter(selectedFilters, filter);

  // search
  const searchFilter = getFilterValue(STORE_SERVICES_FILTER_TYPES.STORE_SERVICES_SEARCH);
  const searchFilterValue = getMoreOptionsValues(searchFilter, STORE_SERVICES_MORE_OPTIONS_TYPES.STORE_SERVICES_SEARCH);

  const variables = {
    ...defaultVariables,
    product_search: searchFilterValue ? [searchFilterValue] : null,
    requisite_queries: Object.values(STORE_SERVICES_FILTER_TYPES)
      .map(filter => {
        const overriddenFiltersValues = STORE_SERVICES_FILTERS_QUERIES[filter]?.filter(query => overriddenFilters?.[query]);
        if (overriddenFiltersValues?.length) {
          return overriddenFiltersValues;
        }
        const filterType = getFilterValue(filter);
        return filterType?.requisite === 'true' ? STORE_SERVICES_FILTERS_QUERIES[filter] : null;
      })
      .filter(Boolean)
      .flat(),
    alternative_queries: Object.values(STORE_SERVICES_FILTER_TYPES)
      .map(filter => {
        const overriddenFiltersValues = STORE_SERVICES_FILTERS_QUERIES[filter]?.filter(query => overriddenFilters?.[query]);
        if (overriddenFiltersValues?.length) {
          return null;
        }
        const filterType = getFilterValue(filter);
        return filterType?.requisite === 'false' ? STORE_SERVICES_FILTERS_QUERIES[filter] : null;
      })
      .filter(Boolean)
      .flat()
  };

  return variables;
};

export { HIDDEN_STORE_SERVICES_FILTERS, STORE_SERVICES_FILTER_TYPES, STORE_SERVICES_MORE_OPTIONS_TYPES, getServicesFiltersVariables, useServicesFilters };
