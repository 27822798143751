import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { BranchSubscriptionOrdersViewCountsSubscription, GetBranchSubscriptionOrdersViewCounts, GetBusUserProfile } from '../queries';

export type GetBranchSubscriptionOrdersViewCountsReturn = {
  newSubscriptionOrdersCount: number;
  shouldNotify: boolean;
};

const useBranchSubscriptionOrdersViewCounts = () => {
  const { data: { getBusUserProfile: profile = {} } = {} } = useQuery(GetBusUserProfile, {
    fetchPolicy: 'cache-only'
  });

  const { data: unreadOrdersCount, subscribeToMore } = useQuery<{ getBranchSubscriptionOrdersViewCounts: GetBranchSubscriptionOrdersViewCountsReturn }>(GetBranchSubscriptionOrdersViewCounts, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true
  });

  const { newSubscriptionOrdersCount, shouldNotify } = unreadOrdersCount?.getBranchSubscriptionOrdersViewCounts || {};

  useEffect(() => {
    const sub = subscribeToMore<{ branchSubscriptionOrdersViewCountsUpdated: GetBranchSubscriptionOrdersViewCountsReturn }>({
      document: BranchSubscriptionOrdersViewCountsSubscription,
      variables: { BranchId: [profile?.Branch?.id] },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData) {
          return prev;
        }

        const newCounts = subscriptionData?.data?.branchSubscriptionOrdersViewCountsUpdated;

        return Object.assign({}, prev, {
          getBranchSubscriptionOrdersViewCounts: {
            newSubscriptionOrdersCount: newCounts.newSubscriptionOrdersCount,
            shouldNotify: newCounts.shouldNotify,
            __typename: 'BranchSubscriptionOrdersViewCount'
          }
        });
      }
    });
    return () => sub();
  }, [subscribeToMore, profile?.Branch?.id]);

  return {
    newSubscriptionOrdersCount,
    shouldNotify
  };
};

export default useBranchSubscriptionOrdersViewCounts;
