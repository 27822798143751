import { BRANCH_TAGS_QUERIES, BRANCH_TAGS_TYPES, BranchPetRecordTag, BranchTag } from '../views/Store/BranchTags/types';
import { LazyQueryResultTuple, QueryResult, useLazyQuery, useQuery } from '@apollo/client';

export default function useGetBranchTagsByType<T extends BranchTag = BranchTag>({
  type,
  otherParams,
  variables,
  lazy = false
}: {
  type: BRANCH_TAGS_TYPES;
  otherParams?: Record<string, any>;
  variables?: Record<string, any>;
  lazy?: boolean;
}): { branchTags: T[]; loading: boolean; getBranchTags: () => void } {
  const { GetBranchTags } = BRANCH_TAGS_QUERIES[type];
  const params = {
    fetchPolicy: 'cache-and-network',
    variables,
    ...otherParams
  } as Record<string, any>;

  const queryResult = lazy ? useLazyQuery<BranchTag>(GetBranchTags, params) : useQuery<BranchTag>(GetBranchTags, params);

  const queryData = lazy ? (queryResult as LazyQueryResultTuple<BranchTag, {}>)?.[1] : (queryResult as QueryResult<BranchTag, {}>);

  const { data: branchTagsData, loading } = queryData || {};

  const branchTags = Object.values(branchTagsData || {})[0] as T[];

  const getBranchTags = lazy ? (queryResult as LazyQueryResultTuple<BranchTag, {}>)?.[0] : () => {};

  return { branchTags, loading, getBranchTags };
}
