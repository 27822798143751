import { useMutation, useQuery } from '@apollo/client';
import React, { FC, useEffect } from 'react';
import { UseFormMethods, useForm } from 'react-hook-form';
import Colors from '../../../../Colors';
import ModalDialog, { ModalLayout } from '../../../../components/Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../../../../components/Modal/styled';
import DurationSelector, { convertDurationToMinutes } from '../../../../components/Shared/DurationSelector';
import { FormButtonsContainer, FormLabel, FormSubmitButton, InputsWrapper, SectionSpan, WideInputGroup } from '../../../../components/Shared/Forms/Forms';
import { Container, StyledSpinner } from '../../../../components/Shared/Shared';
import { EditBranchOrderItems, GetBranchAppointments } from '../../../../queries';
import { toReadableDate } from '../../../../utils/dates';
import { BOOKING_STATUS_TYPES, BOOKING_TYPE, Booking, BookingOrder } from '../../types';
import BookingRescheduleModal from './BookingRescheduleModal';

interface BoookingDurationModalProps {
  appointments: BookingOrder;
  formOptions: UseFormMethods;
  loadingAdd: boolean;
  onSubmit: () => void;
  newDuration?: number;
}

const BoookingDurationModal: FC<BoookingDurationModalProps> = ({ appointments = [], formOptions, loadingAdd, onSubmit, newDuration }) => {
  const petsIds = [...new Set(appointments.map(appointment => appointment.PetRecord.Pet.id))];
  const pets = petsIds.map(petId => appointments.find(appointment => appointment.PetRecord.Pet.id === petId)?.PetRecord.Pet.name);
  const appUsersIds = [...new Set(appointments.map(appointment => appointment.OrderItem.Order.AppUser.id))];
  const appUsersLength = appUsersIds.length;
  const appUsers = appUsersIds.map(appUserId => appointments.find(appointment => appointment.OrderItem.Order.AppUser.id === appUserId)?.OrderItem.Order.AppUser.name);

  const appUsersNames = appUsersLength > 1 ? `${appUsersLength} Clients` : appUsers.join(' & ');

  const petsNames = appUsersLength > 1 ? `${petsIds.length} Pets` : pets.join(' & ');

  return (
    <ModalLayout
      compact
      buttons={[
        <FormSubmitButton loading={loadingAdd} onClick={onSubmit}>
          Save
        </FormSubmitButton>
      ]}
      isMini
    >
      <WideInputGroup>
        <DurationSelector
          formOptions={formOptions}
          defaultDuration={newDuration || appointments[0]?.OrderItem?.item?.duration || 0}
          options={{
            title: 'New duration'
          }}
        />
      </WideInputGroup>
    </ModalLayout>
  );
};

export const BookingDurationModal = ({ appointments, appointmentsIds, newDuration, newDate }: { appointments?: BookingOrder; appointmentsIds?: string[]; newDuration?: number; newDate?: Date }) => {
  const formOptions = useForm();
  const { handleSubmit } = formOptions;

  const { data: { getBranchAppointments: branchAppointments = [] } = {}, loading: loadingAppointments } = useQuery<{ getBranchAppointments: BookingOrder }>(GetBranchAppointments, {
    variables: {
      appointment_id: appointmentsIds,
      requisite_queries: ['appointment_id'],
      alternative_queries: [],
      offset: 0,
      limit: 1000,
      status: [BOOKING_STATUS_TYPES.CONFIRMED, BOOKING_STATUS_TYPES.CANCELED, BOOKING_STATUS_TYPES.REQUESTED],
      booking_type: [BOOKING_TYPE.SLOT, BOOKING_TYPE.MULTI_SLOT, BOOKING_TYPE.MULTI_DAY]
    },
    fetchPolicy: 'cache-and-network',
    skip: !!appointments?.length
  });

  const [updateOrderItems, { data: updatedItems, loading: loadingAdd }] = useMutation<{ editBranchOrderItems: Booking['OrderItem'][] }>(EditBranchOrderItems, {
    refetchQueries: ['getBranchCalendarAppointmentsViews'],
    awaitRefetchQueries: true
  });

  const allAppointments = appointments || branchAppointments;

  const onSubmit = handleSubmit(({ duration }) => {
    if (!duration) {
      return;
    }

    updateOrderItems({
      variables: {
        id: allAppointments.map(appointment => appointment.OrderItem.id),
        item_duration: convertDurationToMinutes(duration)
      }
    });
  });

  useEffect(() => {
    if (updatedItems?.editBranchOrderItems?.length) {
      ModalDialog.closeModal();
      if (newDate) {
        ModalDialog.openModal({ content: () => <BookingRescheduleModal appointmentsIds={appointmentsIds} newDate={newDate} />, title: 'Reschedule', isMini: true });
      }
    }
  }, [updatedItems, newDate]);

  if (!allAppointments?.length || loadingAppointments) {
    return <StyledSpinner color={Colors.black} />;
  }

  return <BoookingDurationModal appointments={allAppointments} formOptions={formOptions} loadingAdd={loadingAdd} onSubmit={onSubmit} newDuration={newDuration} />;
};

export default BookingDurationModal;
