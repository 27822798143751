import { useMemo, useSyncExternalStore } from 'react';

function useMediaQueryListener(size: number, serverFallback?: boolean): boolean {
  const getServerSnapshot = serverFallback !== undefined ? () => serverFallback : undefined;

  const [getSnapshot, subscribe] = useMemo(() => {
    const mediaQueryList = window.matchMedia(`(max-width: ${size}px)`);

    return [
      () => mediaQueryList.matches,
      (notify: () => void) => {
        mediaQueryList.addEventListener('change', notify);
        return () => {
          mediaQueryList.removeEventListener('change', notify);
        };
      }
    ];
  }, [size]);

  return useSyncExternalStore(
    subscribe,
    // Fallback to getServerSnapshot only required for React 17
    typeof window !== 'undefined' ? getSnapshot : getServerSnapshot,
    getServerSnapshot
  );
}

export default function useMediaQuery({ mobile, tablet }: { mobile?: boolean; tablet?: boolean }) {
  const mobileListener = mobile ? useMediaQueryListener(768) : undefined;
  const tabletListener = tablet ? useMediaQueryListener(1200) : undefined;

  return { mobile: mobileListener, tablet: tabletListener };
}
