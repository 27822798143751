import { useQuery, useReactiveVar } from '@apollo/client';
import React, { useCallback, useState } from 'react';
import Colors from '../../Colors';
import useMediaQuery from '../../hooks/useMediaQuery';
import { GetAppUserProfileById } from '../../queries';
import { vars } from '../../reactive';
import { toggleExtendDrawerBar } from '../../reactive/actions';
import { BranchAppUser } from '../../views/Store/BranchBilling/types';
import { CloseButtonContainer, ProfileActionBarContainer } from '../ChatDrawer/styled';
import { MobileCloseButton } from '../DrawerBar/DrawerButtons';
import { ActionButton, ActionButtonsContainer, Divider, DrawerContainer, DrawerContent, DrawerList, DrawerSection, ProfileListItemsContainer } from '../DrawerBar/styled';
import { DRAWER_IDS } from '../DrawerBar/types';
import { PetContainer } from '../PetDrawer/styled';
import PetProfilesList from '../Pets/PetProfilesList';
import ProfileActionBar from '../Profile/ProfileActionBar';
import { LoadingOverlay } from '../Shared/Spinner';
import UserExtension from './UserExtension';
import UserProfileInfo from './UserProfileInfo';
import { UserExtendButton } from './styled';
import { BranchNoteType } from '../Shared/BranchNotes/types';
import BranchNotes from '../Shared/BranchNotes/BranchNotes';

const UserDrawer = ({ data: appUserId = '' }: { data: string }) => {
  const { data: { getAppUserById: profile } = {}, loading: loadingAppUserProfile } = useQuery<{ getAppUserById: BranchAppUser }>(GetAppUserProfileById, {
    variables: { id: appUserId },
    skip: !appUserId,
    fetchPolicy: 'cache-and-network'
  });

  const appUserProfile = (profile || {}) as BranchAppUser;

  const userTags = appUserProfile?.BranchAppUserTags || [];

  const pets = [...(appUserProfile?.Pets || []), ...(appUserProfile?.ChappedPets || [])].filter(Boolean);
  const { mobile, tablet } = useMediaQuery({ mobile: true, tablet: true });

  const drawerBars = useReactiveVar(vars.drawerBars);
  const drawerBar = drawerBars.find(drawer => drawer.drawerId === DRAWER_IDS.USER_DRAWER)!;

  const handleExtend = useCallback(() => toggleExtendDrawerBar(DRAWER_IDS.USER_DRAWER), [drawerBar]);
  const [selected, setSelected] = useState('details');
  return (
    <PetContainer flexOne>
      {loadingAppUserProfile && <LoadingOverlay />}
      {!loadingAppUserProfile && (
        <>
          <DrawerContainer>
            {((!drawerBar.isExtended && mobile) || !drawerBar.isExtended || (drawerBar.isExtended && !mobile)) && (
              <>
                <DrawerSection paddingT={mobile ? 12 : tablet ? 16 : 24} paddingB={12} isFixed flexRow center>
                  <CloseButtonContainer>
                    <MobileCloseButton drawer={drawerBar} />
                  </CloseButtonContainer>
                  <ProfileActionBarContainer>
                    <ProfileListItemsContainer noMargin>
                      <ProfileActionBar smallDescription={false} profile={appUserProfile} drawerId={DRAWER_IDS.PETS_DRAWER} />
                    </ProfileListItemsContainer>
                  </ProfileActionBarContainer>
                </DrawerSection>
                <Divider />
                {/* <ProfileCover profile={appUserProfile} type={PROFILE_COVER_TYPES.USER} /> */}
                <ActionButtonsContainer>
                  <ActionButton onClick={() => setSelected('details')} isSelected={selected === 'details'}>
                    Details
                  </ActionButton>
                  <ActionButton isSelected={selected === 'notes'} onClick={() => setSelected('notes')}>
                    Notes
                  </ActionButton>
                </ActionButtonsContainer>
                <DrawerContent justifyFlexStart padding="24px 20px">
                  {/* {!!userTags?.length && (
                <>
                  <PetDrawerTagsContainer>
                    {userTags?.map((item, index) => (
                      <PetDrawerTag color={item.color} key={index}>
                        <span>{item.name}</span>
                      </PetDrawerTag>
                    ))}
                  </PetDrawerTagsContainer>
                  <ExtensionDivider />
                </>
              )} */}
                  {/* <FormLinkButtonStyled textAlign={'right'} onClick={onEdit}>
                Edit
              </FormLinkButtonStyled> */}

                  {selected === 'details' && (
                    <>
                      <UserProfileInfo profile={appUserProfile} />
                      <Divider marginTop={12} marginBottom={12} />

                      {/* <DrawerSubLabel size={14} marginBottom={12} marginTop={12}>
                Pets
              </DrawerSubLabel> */}
                      <DrawerList>
                        <PetProfilesList pets={pets} drawerId={DRAWER_IDS.USER_DRAWER} />
                      </DrawerList>
                    </>
                  )}
                  {selected === 'notes' && !!appUserProfile?.id && <BranchNotes type={BranchNoteType.APPUSER} AppUserId={appUserProfile?.id} />}
                </DrawerContent>

                {!drawerBar.isExtended && (
                  <DrawerSection paddingT={16}>
                    <UserExtendButton onClick={handleExtend} backgroundColor="rgba(0, 0, 0, 1)" color={!mobile ? Colors.white : Colors.white}>
                      View Account
                    </UserExtendButton>
                  </DrawerSection>
                )}
              </>
            )}
            {drawerBar.isExtended && mobile && <UserExtension userProfile={appUserProfile} />}
          </DrawerContainer>
          {drawerBar.isExtended && !mobile && <UserExtension userProfile={appUserProfile} />}
        </>
      )}
    </PetContainer>
  );
};

export default UserDrawer;
