import useNavigateOnTab from './useNavigateOnTab';
import { UsePagintaedQueryResult } from './usePaginatedQuery';

export function filterItemsInTab<T extends Record<string, any>>({
  list,
  selected,
  tabs = [{ name: '', status: [], checkItemInList: () => false }]
}: {
  list: T[];
  selected: string;
  tabs: { name: string; status: string[]; checkItemInList: (item: T) => boolean }[];
}) {
  const selectedTab = tabs.find(tab => tab.name === selected);

  return list.filter(item => (!!selectedTab?.checkItemInList ? selectedTab?.checkItemInList(item) : selectedTab?.status.includes(item.status)));
}

export default function useCheckSelectedTabAndRefetch<T extends Record<string, any>>({
  tabs = [{ name: '', status: [], otherVariables: {} }],
  queryResult,
  setHasMoreItems,
  selected
}: {
  tabs: { name: string; status?: string[]; otherVariables?: any }[];
  queryResult: UsePagintaedQueryResult<T[]>['1'];
  setHasMoreItems: React.Dispatch<React.SetStateAction<boolean>>;
  selected: string;
}) {
  const checkSelectedTabAndRefetch = () => {
    const selectedTab = tabs.find(tab => tab.name === selected);
    if (selectedTab) {
      queryResult.refetch({
        status: selectedTab.status,
        ...selectedTab.otherVariables
      });
    }
  };

  useNavigateOnTab({
    selected,
    next: () => {
      checkSelectedTabAndRefetch();
      setHasMoreItems(true);
    }
  });

  return checkSelectedTabAndRefetch;
}
