import React, { FC, useEffect } from 'react';
import RadioSwitch from '../../components/Shared/Forms/RadioSwitch';
import RefreshButton from '../../components/Shared/RefreshButton';
import useCheckSelectedTabAndRefetch from '../../hooks/useCheckSelectedTabAndRefetch';
import usePaginatedQuery from '../../hooks/usePaginatedQuery';
import { GetBranchOrderSubscriptions, GetBranchOrderSubscriptionsViews } from '../../queries';
import Common from '../Store/Common';
import SubscriptionsList from './SubscriptionsList';
import { SUBSCRIPTION_STATUS_TYPES, SUBSCRIPTION_TABS_TYPES, Subscription } from './types';
import { getSubscriptionsFiltersVariables, useSubscriptionsFilters } from './SubscriptionsFilters';
import SubscriptionsHeader from './SubscriptionsHeader';

type SubscriptionsBodyProps = {
  selectedTabsState: string;
  tabs: { name: string; status: string[]; otherVariables: Record<string, any>; value: string }[];
};

const SubscriptionsBody: FC<SubscriptionsBodyProps> = ({ tabs, selectedTabsState: selected }) => {
  const selectedTab = tabs.find(tab => tab.value === selected) || tabs[0];
  const filtersControl = useSubscriptionsFilters();
  const { name, value, status, otherVariables } = selectedTab;

  const paginatedQueryResult = usePaginatedQuery<{ count: number; views: Subscription[] }>({
    query: GetBranchOrderSubscriptionsViews,
    defaultStatus: [SUBSCRIPTION_STATUS_TYPES.CONFIRMED],
    limit: 20,
    otherVariables: {
      ...getSubscriptionsFiltersVariables({
        selectedFilters: filtersControl.selectedFilters,
        defaultVariables: {
          ...otherVariables,
          status
        }
      })
    }
  });

  const [[_hasMoreItems, setHasMoreItems], queryResult] = paginatedQueryResult;

  const { refetch } = queryResult;

  useEffect(() => {
    Common.set(`Subscriptions.GetOrderSubscriptions.refetch`, () => {
      refetch?.().then(() => setHasMoreItems(true));
    });
  }, []);

  return (
    <>
      <SubscriptionsHeader filtersControl={filtersControl} tabs={tabs} />
      <SubscriptionsList paginatedQueryResult={paginatedQueryResult} reverseItems={[SUBSCRIPTION_STATUS_TYPES.CANCELED].includes(selected)} showTotal={{ orderTotal: true }} />
    </>
  );
};

export default SubscriptionsBody;
