import { useQuery } from '@apollo/client';
import { useParams } from '@reach/router';
import { navigate } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useEffect } from 'react';
import { sentenceCase } from 'sentence-case';
import { BackButton } from '../../../components/DrawerBar/styled';
import { BusUserProfile } from '../../../components/Profile/types';
import InfiniteList from '../../../components/Shared/InfiniteList/InfiniteList';
import { ViewContainer } from '../../../components/Shared/Shared';
import { ActivityIndicator } from '../../../components/Shared/Spinner';
import useDownload from '../../../hooks/useDownload';
import useIcons from '../../../hooks/useIcons';
import usePaginatedQuery from '../../../hooks/usePaginatedQuery';
import { GetBranchBalanceTransactions, GetBranchPayout, GetBusUserProfile } from '../../../queries';
import { toReadableDate } from '../../../utils/dates';
import { numberWithCommas } from '../../../utils/helpers';
import {
  ContentWrapper,
  PayoutHeader,
  SubscriptionsDashboardContainer,
  SubscriptionsDashboardItem,
  SubscriptionsDashboardItemSubtitle,
  SubscriptionsDashboardItemTitle,
  SubscriptionsDashboardWrapper
} from '../../Subscriptions/styled';
import { RecordsContainer } from '../../styled';
import { BranchSummaryCountMode } from '../BranchSummary/styled';
import Common from '../Common';
import BranchBalanceTransactionRecord from './BranchBalanceTransaction';
import { BranchBalanceTransaction, BranchPayout } from './types';
import ToolBar from '../../../components/Shared/ToolBar/ToolBar';
import { MenuButton } from '../../../components/Shared/Menus/OptionDropdown/styled';

const BranchPayoutDetails = () => {
  const { id } = useParams();
  const { data: { getBusUserProfile: currentBusUserProfile } = {} } = useQuery<{ getBusUserProfile: BusUserProfile }>(GetBusUserProfile, {
    fetchPolicy: 'cache-only'
  });
  const { data: { getBranchPayouts: [payout] = [] } = {} } = useQuery<{ getBranchPayouts: BranchPayout[] }>(GetBranchPayout, {
    variables: { id },
    fetchPolicy: 'cache-and-network',
    skip: !id
  });

  const [[hasMoreItems, setHasMoreItems], queryResult] = usePaginatedQuery<BranchBalanceTransaction[]>({
    query: GetBranchBalanceTransactions,
    otherParams: { fetchPolicy: 'network-only' },
    type: 'cursor',
    otherVariables: {
      payout_id: id
    }
  });

  const { data: { getBranchBalanceTransactions: transactions = [] } = {}, loading, fetchMore, refetch } = queryResult;

  useEffect(() => {
    Common.set(`BranchPayouts.GetBranchBalanceTransactions.refetch`, () => {
      refetch?.().then(() => setHasMoreItems(true));
    });
  }, []);

  const icons = useIcons();

  const chevronIcon = icons.chevronLeft.childImageSharp.gatsbyImageData;
  const exportIcon = icons?.exportSVG?.publicURL;

  const [downloadBalanceTransactions, { loading: loadingDownload, error }] = useDownload<{
    payout_id: string;
  }>({
    type: 'balance-transactions',
    variables: {
      payout_id: id
    },
    fileName: `${currentBusUserProfile?.Branch?.name}_CollarItemisedPayout_${payout?.currency?.toUpperCase?.()}_${toReadableDate(Number(payout?.arrival_date || 0) * 1000, {
      noTime: true,
      isLocale: true
    })}`,
    fileFormat: 'csv'
  });

  return (
    <>
      <PayoutHeader>
        <BackButton onClick={() => navigate('/app/operations/payouts')}>
          <GatsbyImage image={chevronIcon} alt="Back" />
        </BackButton>
        <SubscriptionsDashboardContainer style={{ padding: 0 }}>
          <SubscriptionsDashboardWrapper noBorder>
            <SubscriptionsDashboardItem flexOne>
              <ContentWrapper>
                <SubscriptionsDashboardItemTitle>Payout</SubscriptionsDashboardItemTitle>
                <SubscriptionsDashboardItemSubtitle>
                  {payout?.currency?.toUpperCase?.()} {numberWithCommas(Number(Number(payout?.amount || 0) / 100).toFixed(2))}
                </SubscriptionsDashboardItemSubtitle>
              </ContentWrapper>
            </SubscriptionsDashboardItem>
            <SubscriptionsDashboardItem flexOne>
              <ContentWrapper>
                <SubscriptionsDashboardItemTitle>Status</SubscriptionsDashboardItemTitle>
                <SubscriptionsDashboardItemSubtitle>{sentenceCase(payout?.status || '')}</SubscriptionsDashboardItemSubtitle>
              </ContentWrapper>
            </SubscriptionsDashboardItem>

            <SubscriptionsDashboardItem selectable flexOne>
              <ContentWrapper>
                <SubscriptionsDashboardItemTitle>Payout trace ID</SubscriptionsDashboardItemTitle>
                <SubscriptionsDashboardItemSubtitle>{payout?.id}</SubscriptionsDashboardItemSubtitle>
              </ContentWrapper>
            </SubscriptionsDashboardItem>

            <SubscriptionsDashboardItem flexOne>
              <ContentWrapper>
                <SubscriptionsDashboardItemTitle>{Number(payout?.arrival_date || 0) * 1000 > Date.now() ? 'Estimated arrival' : 'Date Paid'}</SubscriptionsDashboardItemTitle>
                <SubscriptionsDashboardItemSubtitle>
                  {toReadableDate(Number(payout?.arrival_date || 0) * 1000, {
                    noTime: true,
                    isLocale: true
                  })}
                </SubscriptionsDashboardItemSubtitle>
              </ContentWrapper>
            </SubscriptionsDashboardItem>
          </SubscriptionsDashboardWrapper>
        </SubscriptionsDashboardContainer>

        {!!transactions?.length && (
          <ContentWrapper>
            <BranchSummaryCountMode style={{ display: 'flex', gap: 10, paddingBottom: 10 }} onClick={() => downloadBalanceTransactions()}>
              {loadingDownload ? <ActivityIndicator size={20} /> : <span>Export</span>}
              <MenuButton bgImage={exportIcon} alt="export" width="28px" height="28px" />
            </BranchSummaryCountMode>
          </ContentWrapper>
        )}
      </PayoutHeader>

      <InfiniteList
        list={transactions}
        itemRenderer={(balanceTransaction: BranchBalanceTransaction) => <BranchBalanceTransactionRecord balanceTransaction={balanceTransaction} />}
        hasMoreItems={hasMoreItems}
        loading={loading}
        fetchMore={fetchMore}
        setHasMoreItems={setHasMoreItems}
        type="cursor"
        offset={transactions?.length}
        paddingL={20}
        paddingR={20}
      />
    </>
  );
};

export default BranchPayoutDetails;
