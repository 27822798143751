import React, { FC } from 'react';
import { sentenceCase } from 'sentence-case';
import { DRAWER_IDS } from '../../components/DrawerBar/types';
import { UserExtensionTabs } from '../../components/UserDrawer/UserExtension';
import { UserSubscriptionsListTabs } from '../../components/UserDrawer/UserOrdersList';
import useIcons from '../../hooks/useIcons';
import { setDrawerBar } from '../../reactive/actions';
import { toReadableDate } from '../../utils/dates';
import { getBranchCurrencySymbol } from '../../utils/getBranchCurrencySymbol';
import { AppUserImage, AppUserImageContainer, AppUserImageNameContainer, AppUserName, RecordBody, RecordDataContainer } from '../styled';
import { OrderSubscriptionRepeat } from './types';

type SubscriptionRepeatRecordProps = {
  subscriptionRepeat: OrderSubscriptionRepeat;
};

const SubscriptionRepeatRecord: FC<SubscriptionRepeatRecordProps> = ({ subscriptionRepeat }) => {
  const icons = useIcons();

  const user = subscriptionRepeat?.OrderItem?.Order?.AppUser;
  const name = subscriptionRepeat?.OrderItem?.item?.name;
  const petName = subscriptionRepeat?.PetRecord?.Pet?.name;
  const statusTitle = sentenceCase(subscriptionRepeat?.status);
  const total = `${getBranchCurrencySymbol()}${subscriptionRepeat?.total}`;
  const createdAt = toReadableDate(subscriptionRepeat?.payment?.invoice_date || subscriptionRepeat.createdAt, { withDay: false, isLocale: true, noTime: true });

  const handleClick = () => {
    setDrawerBar({
      drawerId: DRAWER_IDS.USER_DRAWER,
      isExtended: true,
      otherData: { defaultTab: UserExtensionTabs.MEMBERSHIPS, defaultSubTab: UserSubscriptionsListTabs.INVOICES },
      recordData: user?.id
    });
  };

  return (
    <RecordDataContainer onClick={handleClick}>
      <RecordBody>
        <AppUserImageNameContainer>
          <AppUserImageContainer>
            <AppUserImage src={user?.profile_pic || icons.user.childImageSharp.gatsbyImageData.images.fallback.src} />
          </AppUserImageContainer>
          <AppUserName>{user?.name}</AppUserName>
        </AppUserImageNameContainer>
      </RecordBody>
      <RecordBody width="240">{name}</RecordBody>
      <RecordBody>{petName}</RecordBody>
      <RecordBody>{createdAt}</RecordBody>
      <RecordBody width="30">{total}</RecordBody>
      <RecordBody flexEnd width="400">
        {subscriptionRepeat?.payment?.fail_reason || statusTitle}
      </RecordBody>
    </RecordDataContainer>
  );
};

export default SubscriptionRepeatRecord;
