import React from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import ReactSelect from 'react-select';
import { ColorPicker, FormError, FormInput, FormLabel, InputContainer, InputGroup, selectTheme } from '../../../components/Shared/Forms/Forms';
import { FlexRow } from '../../../components/Shared/Shared';
import { DiscountsFormContainer } from '../../Store/Operations/styled';
import { BranchVehicle } from '../types';
import { BranchVehicleFormValues, branchVehicleStatusOptions, branchVehicleTypesOptions, capacitySizeTypes } from './types';
import Select from '../../../components/Shared/Forms/Select';
import { sentenceCase } from 'sentence-case';

const BranchVehicleModalSettings = ({ formOptions, branchVehicle }: { formOptions: UseFormMethods<BranchVehicleFormValues>; branchVehicle?: BranchVehicle }) => {
  const { control, errors } = formOptions;

  return (
    <>
      <InputGroup>
        <FormLabel>Vehicle Name</FormLabel>
        <Controller as={<FormInput error={!!errors?.branchVehicle?.name} />} control={control} name={`branchVehicle.name`} defaultValue={branchVehicle?.name || ''} rules={{ required: true }} />
        {!!errors?.branchVehicle?.name && <FormError>{errors?.branchVehicle?.name?.message || 'Name is required'}</FormError>}
      </InputGroup>
      <InputGroup>
        <FormLabel>Vehicle Type</FormLabel>
        <Controller
          render={({ onChange, value }) => (
            <Select
              name={'branchVehicle.type'}
              onChange={newValue => {
                if (!newValue) {
                  onChange(undefined);
                  return;
                }
                onChange(newValue?.value);
              }}
              value={branchVehicleTypesOptions?.find(option => option?.value === value)}
              options={branchVehicleTypesOptions}
              getOptionLabel={option => option?.label}
              getOptionValue={option => option?.value}
            />
          )}
          control={control}
          name={`branchVehicle.type`}
          defaultValue={branchVehicle?.type || ''}
          rules={{ required: true }}
        />
      </InputGroup>

      <InputGroup>
        <FormLabel>Status</FormLabel>
        <Controller
          render={({ onChange, value }) => (
            <Select
              name={'branchVehicle.status'}
              onChange={newValue => {
                if (!newValue) {
                  onChange(undefined);
                  return;
                }
                onChange(newValue?.value);
              }}
              value={branchVehicleStatusOptions?.find(option => option?.value === value)}
              options={branchVehicleStatusOptions}
              getOptionLabel={option => option?.label}
              getOptionValue={option => option?.value}
            />
          )}
          control={control}
          name={`branchVehicle.status`}
          defaultValue={branchVehicle?.status || ''}
          rules={{ required: true }}
        />
      </InputGroup>
      <InputGroup>
        <FormLabel>Plate Number</FormLabel>
        <Controller
          render={({ onChange, value }) => <FormInput error={!!errors?.branchVehicle?.plate_number} value={value} onChange={e => onChange((e.target.value || '').toUpperCase())} />}
          control={control}
          name={`branchVehicle.plate_number`}
          defaultValue={branchVehicle?.plate_number || ''}
          rules={{ required: true }}
        />
        {!!errors?.branchVehicle?.plate_number && <FormError>{errors?.branchVehicle?.plate_number?.message || 'Plate Number is required'}</FormError>}
      </InputGroup>
      <InputGroup>
        <FormLabel>Colour</FormLabel>
        <Controller as={<ColorPicker type="color" />} control={control} name={`branchVehicle.color`} defaultValue={branchVehicle?.color || ''} rules={{ required: true }} />
        {!!errors?.branchVehicle?.color && <FormError>{errors?.branchVehicle?.color?.message || 'Color is required'}</FormError>}
      </InputGroup>
      <InputGroup>
        <FormLabel>Capacity</FormLabel>
        <FlexRow gap={8}>
          {capacitySizeTypes.map((type, index) => (
            <InputGroup key={index}>
              <FormLabel>{sentenceCase(type)}</FormLabel>
              <Controller
                as={<FormInput error={!!errors?.branchVehicle?.daily_capacity?.[index]?.capacity?.message} />}
                control={control}
                name={`branchVehicle.daily_capacity.${index}.capacity`}
                defaultValue={branchVehicle?.daily_capacity?.find(capacity => capacity.size === type)?.capacity || ''}
                rules={{ required: true }}
              />
              {!!errors?.branchVehicle?.daily_capacity?.[index]?.capacity?.message && <FormError>{errors?.branchVehicle?.daily_capacity?.[index]?.capacity?.message}</FormError>}
            </InputGroup>
          ))}
        </FlexRow>
      </InputGroup>
    </>
  );
};

export default BranchVehicleModalSettings;
