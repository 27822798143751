import React, { useEffect } from 'react';
import { DRAWER_IDS } from '../../../components/DrawerBar/types';
import { SelectedFilter } from '../../../components/Shared/Filters/config';
import InfiniteList from '../../../components/Shared/InfiniteList/InfiniteList';
import useNavigateDrawer from '../../../hooks/useNavigateDrawer';
import usePaginatedQuery from '../../../hooks/usePaginatedQuery';
import useSetDrawerOnNavigation from '../../../hooks/useSetDrawerOnNavigation';
import { GetBranchProducts } from '../../../queries';
import Common from '../Common';
import StoreSubscriptionRecord from './StoreSubscriptionRecord';
import { getStoreSubscriptionsFiltersVariables } from './StoreSubscriptionsFilters';
import useURLSearchParams from '../../../hooks/useURLSearchParams';
import { productStatuses } from '../types';

const StoreSubscriptionsBody = ({ selectedFilters }: { selectedFilters: SelectedFilter[] }) => {
  const [status] = useURLSearchParams('status');
  const [[hasMoreItems, setHasMoreItems], queryResult] = usePaginatedQuery({
    query: GetBranchProducts,
    otherVariables: {
      ...getStoreSubscriptionsFiltersVariables({
        selectedFilters,
        defaultVariables: {
          type: ['subscription'],
          status: status || productStatuses[0].value
        },
        overriddenFilters: {
          type: ['subscription'],
          status: status || productStatuses[0].value
        }
      })
    }
  });

  const { data: { getBranchProducts: storesubscriptions = [] } = {}, loading, fetchMore, refetch, variables: previousVariables } = queryResult;

  useSetDrawerOnNavigation({
    itemList: [],
    drawerId: DRAWER_IDS.STORE_SUBSCRIPTION_DRAWER
  });

  const navigateDrawer = useNavigateDrawer({
    drawerData: {
      drawerId: DRAWER_IDS.STORE_SUBSCRIPTION_DRAWER
    }
  });

  useEffect(() => {
    Common.set(`StoreSubscriptions.GetStoreSubscriptions.refetch`, () => {
      refetch?.().then(() => setHasMoreItems(true));
    });
  }, []);

  return (
    <InfiniteList
      paddingL={20}
      paddingR={20}
      list={storesubscriptions}
      itemRenderer={storesubscription => <StoreSubscriptionRecord key={storesubscription?.id} subscription={storesubscription} navigateDrawer={navigateDrawer} />}
      hasMoreItems={hasMoreItems}
      loading={loading}
      fetchMore={fetchMore}
      offset={storesubscriptions?.length}
      setHasMoreItems={setHasMoreItems}
    />
  );
};

export default StoreSubscriptionsBody;
