import React, { FC } from 'react';
import Filters from '../../../components/Shared/Filters/Filters';
import { getFilterKeyOptions } from '../../../components/Shared/Filters/helpers';
import { FiltersControl } from '../../../components/Shared/Filters/useFilters';
import ToolBar from '../../../components/Shared/ToolBar/ToolBar';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { areObjectsEqual } from '../../../utils/helpers';
import { BRANCH_APP_USERS_FILTER_TYPES, BRANCH_APP_USERS_MORE_OPTIONS_TYPES } from './BranchAppUsersFilters';
import { TabsHeaderContainer } from '../../../components/Shared/Shared';
import PetTagsQuickFilter from '../PetsHeader/PetTagsQuickFilter';
import ChatTagsQuickFilter from '../../../components/Chat/ChatsHeader/ChatTagsQuickFilter';

type PetsClientsHeaderProps = {
  filtersControl: FiltersControl<BRANCH_APP_USERS_FILTER_TYPES, BRANCH_APP_USERS_MORE_OPTIONS_TYPES>;
  showFilters?: boolean;
  middleHeaderAction?: React.ReactNode;
  activeAppUsersCount?: number;
  archivedAppUsersCount?: number;
};

const PetsClientsHeader: FC<PetsClientsHeaderProps> = ({ filtersControl, showFilters = true, middleHeaderAction, activeAppUsersCount, archivedAppUsersCount }) => {
  const { mobile } = useMediaQuery({ mobile: true });

  const { onChange } = getFilterKeyOptions({
    filtersControl,
    keys: {
      filterType: BRANCH_APP_USERS_FILTER_TYPES.BRANCH_APP_USERS_SEARCH,
      moreOption: BRANCH_APP_USERS_MORE_OPTIONS_TYPES.BRANCH_APP_USERS_SEARCH
    }
  });

  const { headerAction } = filtersControl;

  const clientsToolBarButtonsAll = [
    { name: `Active (${activeAppUsersCount || 0})`, id: 'ACTIVE' },
    { name: `Archived (${archivedAppUsersCount || 0})`, id: 'ARCHIVED' }
  ];

  return (
    <>
      {!mobile && (
        <ToolBar
          toolBarButtons={clientsToolBarButtonsAll}
          urlSearchParam={'status'}
          onSearchChange={onChange}
          middleAction={
            <>
              {headerAction}
              {middleHeaderAction}
            </>
          }
          displayAll={false}
          defaultValue={'ACTIVE'}
          selectOnlyOne
        />
      )}

      {showFilters && (
        <>
          {!mobile && <Filters filtersControl={filtersControl} />}
          {/* {!mobile && (
            <TabsHeaderContainer padding="12px 20px">
              <ChatTagsQuickFilter filtersControl={filtersControl} SelectedTab={selectedTab} />
            </TabsHeaderContainer>
          )} */}
        </>
      )}
    </>
  );
};

export default PetsClientsHeader;
