import { useQuery, useReactiveVar } from '@apollo/client';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useEffect } from 'react';
import { BackButton, BackButtonText } from '../../../components/DrawerBar/styled';
import ModalDialog from '../../../components/Modal/ModalDialog';
import { Branch, BusUserProfile } from '../../../components/Profile/types';
import { CenteredLoader } from '../../../components/Shared/Spinner';
import useIcons from '../../../hooks/useIcons';
import { GetBranchCycles, GetProviderBranches } from '../../../queries';
import { vars } from '../../../reactive';
import AppStorage from '../../../utils/AppStorage';
import { getUTCDateMonthName } from '../../../utils/dates';
import BranchBillingBranchStats from './BranchBillingBranchStats';
import BranchBillingProviderStats from './BranchBillingProviderStats';
import GenerateInvoicesModal from './GenerateInvoicesModal';
import OrderInvoicesChargeModal from './OrderInvoicesChargeModal';
import OrderInvoicesSendEmailModal from './OrderInvoicesSendEmailModal';
import { ActionButton, ActionButtonsContainer, BillingRouter, BillingStatsContainer, BranchBillingContainer, BranchBillingHeader, HeaderTitle, SelectedBillingFiltersContainer } from './styled';
import { useParams } from '@reach/router';
import { navigate } from 'gatsby';
import useURLSearchParams from '../../../hooks/useURLSearchParams';
import { BRANCH_BILLING_FILTERS, BRANCH_BILLING_FILTERS_LABELS } from './types';
import { StyledRouter } from '../../../Routes';
import Select from '../../../components/Shared/Forms/Select';
import { ToolBarFooter } from '../../../components/Shared/ToolBar/styles';

const BranchBillingDateSelector = ({ userProfile }: { userProfile: BusUserProfile }) => {
  const selectedCycle = useReactiveVar(vars.billingSelectedCycle);
  const selectedBranch = useParams()?.branchId;

  const allBranchBillingDates = userProfile?.Branch?.generatedBranchBillingDates || [];

  const currentDateBranchCycle =
    allBranchBillingDates.find(
      ({ billing_date_from, billing_date_to }) => new Date(billing_date_from).getTime() <= new Date().getTime() && new Date(billing_date_to).getTime() >= new Date().getTime()
    ) || allBranchBillingDates[0];

  const activeBillingCycle = allBranchBillingDates.find(({ billing_date_from }) => new Date(billing_date_from).getTime() === new Date(currentDateBranchCycle.billing_date_from).getTime());

  const defaultBillingCycle = selectedCycle || activeBillingCycle || allBranchBillingDates[0] || { billing_date_from: '', billing_date_to: '' };

  useEffect(() => {
    if (!selectedCycle) {
      vars.billingSelectedCycle(defaultBillingCycle);
    }
  }, [selectedCycle, defaultBillingCycle]);

  return (
    <BranchBillingHeader noPadding={!!selectedBranch}>
      {!selectedBranch && <HeaderTitle>Billing</HeaderTitle>}
      <Select
        options={allBranchBillingDates}
        value={defaultBillingCycle}
        onChange={(option: any) => {
          vars.billingSelectedCycle(option);
        }}
        getOptionLabel={option => (!!option?.billing_date_from && option?.billing_date_to!! ? `${getUTCDateMonthName(option.billing_date_from)} - ${getUTCDateMonthName(option.billing_date_to)}` : '')}
        getOptionValue={option => option.billing_date_from}
        blackTheme
      />
    </BranchBillingHeader>
  );
};

const BranchBilling = () => {
  const selectedCycle = useReactiveVar(vars.billingSelectedCycle);
  const { billing_date_from = '', billing_date_to = '' } = selectedCycle || {};

  const currentPeriod = { start: billing_date_from, end: billing_date_to };

  const { data: { getBusUserProfile: userProfile } = {}, loading } = useQuery<{ getBusUserProfile: BusUserProfile }>(GetBranchCycles);

  if (loading) {
    return <CenteredLoader />;
  }

  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
      <BillingRouter basepath={'/app/operations/billing'}>
        <BranchBillingProviderStats currentPeriod={currentPeriod} path="/" headerActions={<BranchBillingDateSelector userProfile={userProfile!} />} />
        <BranchBillingBranchView path=":branchId/*" currentPeriod={currentPeriod} headerActions={<BranchBillingDateSelector userProfile={userProfile!} />} />
      </BillingRouter>
    </div>
  );
};

const BranchBillingBranchView = ({ currentPeriod, headerActions }: { currentPeriod: { start: string; end: string }; path: string; headerActions: JSX.Element }) => {
  const selectedBranch = useParams()?.branchId;
  const { data: { getProviderBranches: [branch] = [] } = {} } = useQuery<{ getProviderBranches: Branch[] }>(GetProviderBranches, {
    variables: {
      id: selectedBranch ? [selectedBranch] : []
    },
    skip: !selectedBranch
  });

  const [selectedFilter] = useURLSearchParams<BRANCH_BILLING_FILTERS>('mainFilter');
  const invoiceFilter = selectedFilter === BRANCH_BILLING_FILTERS.MAIN_INVOICES;
  const disableBulkInvoiceSendEmail = useReactiveVar(vars.disableBulkInvoiceSendEmail);
  const disableBulkInvoiceCharge = useReactiveVar(vars.disableBulkInvoiceCharge);
  const shouldDisableBulkInvoiceSendEmail =
    disableBulkInvoiceSendEmail && disableBulkInvoiceSendEmail?.branchId === selectedBranch && new Date(disableBulkInvoiceSendEmail.until!).getTime() > Date.now();
  const shouldDisableBulkInvoiceCharge = disableBulkInvoiceCharge && disableBulkInvoiceCharge?.branchId === selectedBranch && new Date(disableBulkInvoiceCharge.until!).getTime() > Date.now();

  const handleBulkChargeInvoice = () => {
    ModalDialog.openModal({
      content: () => (
        <OrderInvoicesChargeModal
          initialValues={{
            invoice_date_from: currentPeriod.start,
            invoice_date_to: currentPeriod.end
          }}
        />
      ),
      title: 'Charge Invoices'
    });
  };

  const handleBulkSendEmailInvoice = () => {
    ModalDialog.openModal({
      content: () => (
        <OrderInvoicesSendEmailModal
          initialValues={{
            invoice_date_from: currentPeriod.start,
            invoice_date_to: currentPeriod.end
          }}
        />
      ),
      title: 'Send Invoices'
    });
  };

  const handleGenerateInvoices = () => {
    ModalDialog.openModal({
      content: () => <GenerateInvoicesModal />,
      title: 'Generate Invoices',
      isMini: true
    });
  };

  useEffect(() => {
    AppStorage.set('providerBranchId', selectedBranch);

    return () => {
      AppStorage.remove('providerBranchId');
    };
  }, [selectedBranch]);

  const icons = useIcons();
  const chevronIcon = icons.lessMid.childImageSharp.gatsbyImageData;

  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
      <BranchBillingContainer>
        <BillingStatsContainer noMargin>
          <SelectedBillingFiltersContainer>
            <BackButton onClick={() => navigate('/app/operations/billing')}>
              <GatsbyImage image={chevronIcon} alt="Back" />
              <BackButtonText>
                {BRANCH_BILLING_FILTERS_LABELS[selectedFilter]} - {branch?.name}
              </BackButtonText>
            </BackButton>
          </SelectedBillingFiltersContainer>
        </BillingStatsContainer>
        <ActionButtonsContainer>{headerActions}</ActionButtonsContainer>
      </BranchBillingContainer>
      <BranchBillingBranchStats currentPeriod={currentPeriod}>
        <>
          {invoiceFilter && (
            <>
              <ActionButton style={{ whiteSpace: 'nowrap' }} onClick={handleBulkSendEmailInvoice} disabled={shouldDisableBulkInvoiceSendEmail}>
                Send
              </ActionButton>
              <ActionButton style={{ whiteSpace: 'nowrap' }} onClick={handleBulkChargeInvoice} disabled={shouldDisableBulkInvoiceCharge}>
                Charge
              </ActionButton>
              <ActionButton style={{ whiteSpace: 'nowrap' }} onClick={handleGenerateInvoices} disabled={false}>
                Generate
              </ActionButton>
            </>
          )}
        </>
      </BranchBillingBranchStats>
    </div>
  );
};

export default BranchBilling;
