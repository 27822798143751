import React, { FC, Fragment } from 'react';
import useMediaQuery from '../../../hooks/useMediaQuery';

import { useLocation } from '@reach/router';
import { Divider } from '../../../views/Pets/Health/styled';
import Filters from '../../Shared/Filters/Filters';
import { getFilterKeyOptions } from '../../Shared/Filters/helpers';
import { FiltersControl } from '../../Shared/Filters/useFilters';
import { TabsHeaderContainer } from '../../Shared/Shared';
import ToolBar from '../../Shared/ToolBar/ToolBar';
import { CHATS_FILTER_TYPES, CHATS_MORE_OPTIONS_TYPES } from '../ChatsFilters';
import { CHAT_TABS_TYPES } from '../types';
import ChatTagsQuickFilter from './ChatTagsQuickFilter';
import RadioSwitch from '../../Shared/Forms/RadioSwitch';
import { useReactiveVar } from '@apollo/client';
import { vars } from '../../../reactive';
import RefreshButton from '../../Shared/RefreshButton';
import { ChatsBodyRef } from '../ChatsBody';
import OptionDropdown from '../../Shared/Menus/OptionDropdown/OptionDropdown';
import { OPTION_DROPDOWN_MENU_BUTTON_TYPES, OPTION_DROPDOWN_TYPES } from '../../Shared/Menus/OptionDropdown/types';
import ModalDialog from '../../Modal/ModalDialog';
import BranchChatRoomModal from '../BranchChatRoomModal';
import { GetBranchChatRoomsViewCountsReturn } from '../../../hooks/useBranchChatRoomsViewCounts';

type ChatsHeaderProps = {
  tabs: {
    name: string;
    value: string;
  }[];
  filtersControl: FiltersControl<CHATS_FILTER_TYPES, CHATS_MORE_OPTIONS_TYPES>;
  actions?: ChatsBodyRef;
  counts: Partial<GetBranchChatRoomsViewCountsReturn>;
};

const ChatsHeader: FC<ChatsHeaderProps> = ({ filtersControl, tabs, actions }) => {
  const location = useLocation();
  const tabsValues = tabs.map(tab => tab.value);
  const tabValue = tabsValues?.find(tab => tab === location.pathname.split('/')[3]);
  const selected = tabValue || CHAT_TABS_TYPES.CLIENTS.toLowerCase();
  const isStaff = selected === CHAT_TABS_TYPES.STAFF.toLowerCase();
  const { mobile } = useMediaQuery({ mobile: true });
  const { headerAction } = filtersControl;
  const { onChange: onSearch } = getFilterKeyOptions({
    filtersControl,
    keys: {
      filterType: CHATS_FILTER_TYPES.CHAT_SEARCH,
      moreOption: CHATS_MORE_OPTIONS_TYPES.CHAT_SEARCH
    }
  });

  const toolBarButtonsChat = [
    { name: 'Unread', id: 'UNREAD' },
    {
      name: 'Group',
      id: 'GROUP'
    }
  ];

  const toolBarButtons = selected === CHAT_TABS_TYPES.CLIENTS.toLowerCase() ? toolBarButtonsChat : toolBarButtonsChat;

  const activeChatViewSettings = useReactiveVar(vars.activeChatViewSettings);
  const orderByIsRead = activeChatViewSettings?.orderByIsRead || false;

  // const SwitchAction = (
  //   <RadioSwitch
  //     defaultValue={orderByIsRead}
  //     onChange={(on: boolean) => {
  //       actions.setHasMoreItems(true);
  //       vars.activeChatViewSettings({
  //         ...vars.activeChatViewSettings(),
  //         orderByIsRead: on
  //       });
  //     }}
  //   />
  // );
  // const action = !mobile ? (
  //   <Fragment>
  //     {SwitchAction}
  //     <RefreshButton checkSelectedTabAndRefetch={actions?.onRefresh} setHasMoreItems={actions?.setHasMoreItems} />
  //   </Fragment>
  // ) : (
  //   <></>
  // );

  const addChatAction = (
    <OptionDropdown
      options={[
        {
          id: 'addChat',
          optionType: OPTION_DROPDOWN_TYPES.BUTTONS,
          items: [
            {
              name: 'Add Chat',
              value: 'addChat',
              onClick: () => ModalDialog.openModal({ content: () => <BranchChatRoomModal isBusRoom={selected === CHAT_TABS_TYPES.STAFF.toLowerCase()} />, title: 'Add Chat' })
            }
          ]
        }
      ]}
      noApplyButton
      menuButtonType={OPTION_DROPDOWN_MENU_BUTTON_TYPES.PLUS_BLACK_SVG}
      width="28px"
      height="28px"
    />
  );

  return (
    <>
      <ToolBar
        toolBarButtons={toolBarButtons}
        urlSearchParam="status"
        onSearchChange={onSearch}
        selectOnlyOne
        middleAction={
          <>
            {actions}
            {!isStaff && headerAction}
            {addChatAction}
          </>
        }
      />
      {!mobile && <Filters filtersControl={filtersControl} />}
      {!mobile && (
        <TabsHeaderContainer padding="12px 20px" mobilePadding="10px 16px 0 16px" style={{ flexDirection: 'column' }}>
          {!isStaff && <ChatTagsQuickFilter filtersControl={filtersControl} />}
        </TabsHeaderContainer>
      )}
    </>
  );
};

export default ChatsHeader;
