export enum OPTION_DROPDOWN_MENU_BUTTON_TYPES {
  FILTER = 'FILTER',
  CUSTOM_BUTTON = 'CUSTOM_BUTTON',
  PLUS = 'PLUS',
  MENU = 'MENU',
  THREE_DOT = 'THREE_DOT',
  MORE = 'MORE',
  PLUS_BLACK = 'PLUS_BLACK',
  MORE_BLACK = 'MORE_BLACK',
  PLUS_BLACK_SVG = 'PLUS_BLACK_SVG'
}

export enum OPTION_DROPDOWN_TYPES {
  CHECKBOX = 'CHECKBOX',
  DATE = 'DATE',
  SELECT = 'SELECT',
  MULTI_SELECT = 'MULTI_SELECT',
  NUMBER = 'NUMBER',
  RADIO = 'RADIO',
  BUTTONS = 'BUTTONS',
  TEXT_INPUT = 'TEXT_INPUT'
}

export const OPTION_DROPDOWN_REQUIRE_ITEMS: Record<OPTION_DROPDOWN_TYPES, boolean> = {
  [OPTION_DROPDOWN_TYPES.CHECKBOX]: true,
  [OPTION_DROPDOWN_TYPES.DATE]: false,
  [OPTION_DROPDOWN_TYPES.SELECT]: true,
  [OPTION_DROPDOWN_TYPES.MULTI_SELECT]: true,
  [OPTION_DROPDOWN_TYPES.NUMBER]: false,
  [OPTION_DROPDOWN_TYPES.RADIO]: true,
  [OPTION_DROPDOWN_TYPES.BUTTONS]: true,
  [OPTION_DROPDOWN_TYPES.TEXT_INPUT]: false
};

export enum OPTION_DROPDOWN_MENU_POSITIONS {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT'
}

export enum OPTION_DROPDOWN_MENU_OVERLAY_POSITIONS {
  UP = 'UP',
  DOWN = 'DOWN'
}
