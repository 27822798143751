import { DocumentNode, OperationVariables, QueryHookOptions, QueryResult, TypedDocumentNode, useQuery } from '@apollo/client';
import { useState } from 'react';
import useWindowFocus from './useWindowFocus';
import { Anything } from '../utils/helpers';
import { combineFields, memoryFields } from '../utils/paginatedQueriesConfig';

export type UsePagintaedQueryResult<T> = [[boolean, React.Dispatch<React.SetStateAction<boolean>>], QueryResult<Record<string, T>, Record<string, any>>, () => void];

export default function usePaginatedQuery<T extends Record<string, any>[] | Record<string, Record<string, any>[] | Anything>>({
  query,
  defaultStatus = null,
  limit = 20,
  otherVariables,
  lazy = false,
  otherParams = {},
  type = 'offset_limit'
}: {
  query: DocumentNode | TypedDocumentNode<any, any>;
  defaultStatus?: string[] | null;
  limit?: number;
  otherVariables?: any;
  otherParams?: QueryHookOptions<Record<string, T>>;
  lazy?: boolean;
  type?: 'offset_limit' | 'cursor';
}): UsePagintaedQueryResult<T> {
  const hasMoreItemsState = useState(false);
  const [_, setHasMoreItems] = hasMoreItemsState;
  const queryResult = useQuery<Record<string, T>>(query, {
    variables: { limit, offset: 0, status: defaultStatus, ...otherVariables },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    skip: lazy || otherParams?.skip,
    onCompleted: data => {
      const fieldsCombined = combineFields(data);
      if (Number(fieldsCombined?.length || 0) < limit) {
        setHasMoreItems(false);
        return;
      }
      setHasMoreItems(true);
    },
    ...otherParams
  });

  useWindowFocus({
    onFocus: () => {
      if (!queryResult.loading && queryResult.data && !otherParams.skip) {
        queryResult.refetch();
      }
    }
  });

  const updatedQueryResult = {
    ...queryResult,
    refetch: variables => {
      if (!otherParams.skip) {
        return queryResult.refetch(variables);
      }
      return () => new Promise<void>(() => {});
    }
  } as QueryResult<Record<string, T>, OperationVariables>;

  return [hasMoreItemsState, updatedQueryResult, () => queryResult.refetch()];
}
