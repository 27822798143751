import React, { FC } from 'react';
import Filters from '../../../components/Shared/Filters/Filters';
import SearchBar from '../../../components/Shared/Filters/SearchBar';
import { getFilterKeyOptions } from '../../../components/Shared/Filters/helpers';
import { FiltersControl } from '../../../components/Shared/Filters/useFilters';
import ToolBar from '../../../components/Shared/ToolBar/ToolBar';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { areObjectsEqual } from '../../../utils/helpers';
import { PETS_TABS_TYPES } from '../Pets';
import { PETS_FILTER_TYPES, PETS_MORE_OPTIONS_TYPES } from '../PetsFilters';
import { BRANCH_FORMS_FILTER_TYPES, BRANCH_FORMS_MORE_OPTIONS_TYPES } from './BranchFormsFilters';

type PetsFormsHeaderProps = {
  filtersControl: FiltersControl<BRANCH_FORMS_FILTER_TYPES, BRANCH_FORMS_MORE_OPTIONS_TYPES>;
  showFilters?: boolean;
  middleHeaderAction?: React.ReactNode;
};

const PetsFormsHeader: FC<PetsFormsHeaderProps> = ({ filtersControl, showFilters = true, middleHeaderAction }) => {
  const { mobile } = useMediaQuery({ mobile: true });

  const { onChange } = getFilterKeyOptions({
    filtersControl,
    keys: {
      filterType: BRANCH_FORMS_FILTER_TYPES.BRANCH_FORMS_SEARCH,
      moreOption: BRANCH_FORMS_MORE_OPTIONS_TYPES.BRANCH_FORMS_SEARCH
    }
  });

  const { headerAction } = filtersControl;

  return (
    <>
      {!mobile && (
        <ToolBar
          urlSearchParam={'status'}
          onSearchChange={onChange}
          middleAction={
            <>
              {headerAction}
              {middleHeaderAction}
            </>
          }
          displayAll={false}
          selectOnlyOne
        />
      )}
      {mobile && <SearchBar onSearch={onChange} showAlways />}
      {showFilters && !mobile && <Filters filtersControl={filtersControl} />}
    </>
  );
};

export default PetsFormsHeader;
