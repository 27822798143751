import { useQuery } from '@apollo/client';
import React, { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactSelect from '../../../components/Shared/Forms/Select';
import ModalDialog, { ModalLayout } from '../../../components/Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../../../components/Modal/styled';
import { FormButtonsContainer, FormInput, FormLabel, FormSelect, FormSubmitButton, InputsWrapper, WideInputGroup, selectTheme } from '../../../components/Shared/Forms/Forms';
import AppUsersList from '../../../components/Shared/Lists/AppUsersList';
import ProductsList from '../../../components/Shared/Lists/ProductsList';
import { Container } from '../../../components/Shared/Shared';
import { GetAllSubscriptionsAndServices } from '../../../queries';
import { getKeys, randomId } from '../../../utils/helpers';
import { Product } from '../types';
import { BranchSummaryFilterContainer, BranchSummaryFilterDateContainer, BranchSummaryFilterInputContainer, BranchSummaryFilterWrapper } from './styled';
import { ALLOWED_COUNTS_FILTERS_TYPES, ALLOWED_COUNTS_FILTERS_TYPES_LABELS, ALLOWED_COUNTS_NAMED_GROUPS, ALLOWED_COUNTS_NAMES, BranchSummaryFormValues, allowedCountsItems } from './types';
import Select from '../../../components/Shared/Forms/Select';

type AddSummaryModalProps = {
  id?: string;
  selectedCounts: BranchSummaryFormValues['selectedCounts'];
  onSubmit?: (values: BranchSummaryFormValues['selectedCounts']) => void;
};

const AddSummaryModal: FC<AddSummaryModalProps> = ({ id, onSubmit: submitHandler, selectedCounts }) => {
  const field = selectedCounts.find(count => count.id === id);

  const formOptions = useForm<BranchSummaryFormValues['selectedCounts'][0]>({
    defaultValues: {
      appliedFilters: field?.appliedFilters,
      name: field?.name || ALLOWED_COUNTS_NAMES.serviceTotalsOrdersCountAmount
    }
  });

  const { control, handleSubmit, watch, setValue } = formOptions;

  const { name, appliedFilters } = watch(['name', 'appliedFilters']);
  const allowedFilters = allowedCountsItems[name].allows;
  const nonTimestampFilters = allowedFilters?.filter(filter => filter.type !== ALLOWED_COUNTS_FILTERS_TYPES.timestamp_from && filter.type !== ALLOWED_COUNTS_FILTERS_TYPES.timestamp_to);
  const isProductOnly =
    allowedFilters?.some(filter => filter.type === ALLOWED_COUNTS_FILTERS_TYPES.productId) &&
    allowedFilters?.every(filter => filter.type !== ALLOWED_COUNTS_FILTERS_TYPES.serviceId && filter.type !== ALLOWED_COUNTS_FILTERS_TYPES.subscriptionId);

  const { data: { getProducts: products = [] } = {} } = useQuery<{ getProducts: Product[] }>(GetAllSubscriptionsAndServices, {
    fetchPolicy: 'cache-and-network',
    skip: isProductOnly
  });

  const productsSplitByType = products.reduce(
    (acc, product) => ({
      ...acc,
      [product.type]: [...(acc[product.type] || []), product]
    }),
    {} as Record<Product['type'], Product[]>
  );

  const onSubmit = handleSubmit(values => {
    const fieldValues = {
      appliedFilters: getKeys(values.appliedFilters || {}).reduce(
        (acc, key) => ({
          ...acc,
          ...(values.appliedFilters[key] ? { [key]: values.appliedFilters[key] } : {})
        }),
        {} as typeof values.appliedFilters
      ),
      name: values.name,
      id: id || randomId()
    };
    let newCounts = [...selectedCounts];

    if (id) {
      newCounts = selectedCounts.map(count => (count.id === id ? fieldValues : count));
    } else {
      newCounts = [...newCounts, fieldValues];
    }

    submitHandler?.(newCounts);

    ModalDialog.closeModal();
  });

  useEffect(() => {
    if (name !== field?.name) {
      setValue('appliedFilters', {});
    } else {
      setValue('appliedFilters', field?.appliedFilters);
    }
  }, [name]);

  const onRemove = () => {
    const newCounts = selectedCounts.filter(count => count.id !== id);
    submitHandler?.(newCounts);
    ModalDialog.closeModal();
  };

  const productTypeOptions = [
    {
      label: 'Service',
      value: 'service'
    },
    {
      label: 'Membership',
      value: 'subscription'
    },
    {
      label: 'Product',
      value: 'product'
    }
  ];

  const dateRangeOptions = [
    {
      label: 'All Time',
      value: ''
    },
    {
      label: 'Month to Date',
      value: 'monthToDate'
    },
    {
      label: 'Quarter to Date',
      value: 'quarterToDate'
    },
    {
      label: 'Year to Date',
      value: 'yearToDate'
    },
    {
      label: 'Custom',
      value: 'custom'
    }
  ];

  return (
    <ModalLayout
      compact
      buttons={[
        id ? (
          <FormSubmitButton
            loading={false}
            secondary
            danger
            onClick={() => {
              onRemove();
            }}
          >
            Remove
          </FormSubmitButton>
        ) : (
          <></>
        ),
        <FormSubmitButton loading={false} onClick={onSubmit}>
          Show Data
        </FormSubmitButton>
      ]}
    >
      <Container width={500}>
        <WideInputGroup>
          <FormLabel>Report Type</FormLabel>
          <Controller
            control={control}
            name="name"
            as={
              <FormSelect name="name" height={80}>
                {ALLOWED_COUNTS_NAMED_GROUPS.map(group => {
                  const nameOptions = getKeys(allowedCountsItems)
                    .filter(key => group.options.includes(key))
                    .map(key => ({ value: key, label: allowedCountsItems[key].name }));

                  return (
                    <optgroup key={group.name} label={group.label}>
                      {nameOptions.map(option => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </optgroup>
                  );
                })}
              </FormSelect>
            }
          />
          {nonTimestampFilters?.map(filter => {
            const filterTypeName = `appliedFilters.${filter.type}`;
            const filterLabel = ALLOWED_COUNTS_FILTERS_TYPES_LABELS[filter.type];
            return (
              <BranchSummaryFilterContainer>
                <FormLabel>{filterLabel}</FormLabel>
                <BranchSummaryFilterWrapper>
                  <BranchSummaryFilterInputContainer>
                    {filter.type === ALLOWED_COUNTS_FILTERS_TYPES.productId && (
                      <ProductsList
                        formOptions={formOptions}
                        name={filterTypeName}
                        rules={{ required: false }}
                        defaultValues={field?.appliedFilters?.productId ? [field?.appliedFilters?.productId as string] : []}
                      />
                    )}
                    {filter.type === ALLOWED_COUNTS_FILTERS_TYPES.appUserId && (
                      <AppUsersList formOptions={formOptions} name={filterTypeName} rules={{ required: false }} defaultValues={(field?.appliedFilters?.appUserId as string[]) ?? []} isMulti />
                    )}
                    {(filter.type === ALLOWED_COUNTS_FILTERS_TYPES.subscriptionId || filter.type === ALLOWED_COUNTS_FILTERS_TYPES.serviceId) && (
                      <Controller
                        render={({ onChange, value }) => (
                          <Select
                            isMulti
                            theme={selectTheme}
                            name={filterTypeName}
                            onChange={newValue => {
                              if (!newValue?.length) {
                                onChange(undefined);
                                return;
                              }
                              onChange(newValue?.map(item => item?.id));
                            }}
                            value={products?.filter(product => value?.includes(product?.id))}
                            options={
                              filter.type === ALLOWED_COUNTS_FILTERS_TYPES.serviceId
                                ? productsSplitByType['service']
                                : filter.type === ALLOWED_COUNTS_FILTERS_TYPES.subscriptionId
                                ? productsSplitByType['subscription']
                                : []
                            }
                            getOptionLabel={option => option?.name}
                            getOptionValue={option => option?.id}
                          />
                        )}
                        control={control}
                        name={filterTypeName}
                        defaultValue={field?.appliedFilters?.[filter.type] || ''}
                        rules={{
                          required: false
                        }}
                      />
                    )}
                    {filter.type === ALLOWED_COUNTS_FILTERS_TYPES.dateRange && (
                      <>
                        <Controller
                          render={({ onChange, value }) => (
                            <Select
                              theme={selectTheme}
                              name={filterTypeName}
                              onChange={newValue => {
                                onChange(newValue?.value);
                              }}
                              value={dateRangeOptions.find(option => option.value === value)}
                              options={dateRangeOptions}
                              getOptionLabel={option => option?.label}
                              getOptionValue={option => option?.value}
                            />
                          )}
                          control={control}
                          name={filterTypeName}
                          defaultValue={field?.appliedFilters?.dateRange || ''}
                          rules={{
                            required: false
                          }}
                        />
                        {appliedFilters?.dateRange === 'custom' && (
                          <BranchSummaryFilterDateContainer>
                            <Controller
                              control={control}
                              name={'appliedFilters.timestamp_from'}
                              as={<FormInput type="date" name={'appliedFilters.timestamp_from'} height={60} fullWidth borderBox />}
                              rules={{ required: true }}
                            />
                            <Controller
                              control={control}
                              name={'appliedFilters.timestamp_to'}
                              as={<FormInput type="date" name={'appliedFilters.timestamp_to'} height={60} fullWidth borderBox />}
                              rules={{ required: true }}
                            />
                          </BranchSummaryFilterDateContainer>
                        )}
                      </>
                    )}

                    {filter.type === ALLOWED_COUNTS_FILTERS_TYPES.productType && (
                      <Controller
                        control={control}
                        name={filterTypeName}
                        defaultValue={[field?.appliedFilters?.productType || []]?.flat()}
                        render={({ onChange, value }) => (
                          <Select
                            theme={selectTheme}
                            name={filterTypeName}
                            onChange={newValue => {
                              if (!newValue?.length) {
                                onChange(undefined);
                                return;
                              }
                              onChange(newValue?.map(item => item?.value));
                            }}
                            value={[value || []]?.flat()?.map(type => ({ label: productTypeOptions.find(option => option.value === type)?.label, value: type }))}
                            isMulti
                            isClearable
                            options={productTypeOptions}
                          />
                        )}
                      />
                    )}
                  </BranchSummaryFilterInputContainer>
                  <button onClick={() => setValue(filterTypeName, '')}>Clear</button>
                </BranchSummaryFilterWrapper>
              </BranchSummaryFilterContainer>
            );
          })}
        </WideInputGroup>
      </Container>
    </ModalLayout>
  );
};

export default AddSummaryModal;
