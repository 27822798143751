import React, { FC, Fragment, forwardRef, useCallback, useImperativeHandle, useMemo, useRef } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import CreatableSelect from '../../../../components/Shared/Forms/Select';
import Colors from '../../../../Colors';
import useIcons from '../../../../hooks/useIcons';
import { FormCheckbox, FormError, FormInput, FormSelect, RadioBtnsGroup, selectTheme } from '../../Forms/Forms';
import { CenteredLoader } from '../../Spinner';
import { ApplyButton, ButtonOptionLabel, ItemButton, ItemMenu, MenuButton, OptionContainer, OptionDropdownContainer, Triangle } from './styled';
import { OPTION_DROPDOWN_MENU_BUTTON_TYPES, OPTION_DROPDOWN_MENU_OVERLAY_POSITIONS, OPTION_DROPDOWN_MENU_POSITIONS, OPTION_DROPDOWN_REQUIRE_ITEMS, OPTION_DROPDOWN_TYPES } from './types';

export type OptionDropDownItem = {
  name: string;
  value: string | boolean | number;
  onClick?: (item: OptionDropDownItem) => void;
  CustomComponent?: FC<{ Button: (props: any) => JSX.Element }>;
  danger?: boolean;
  green?: boolean;
  disabled?: boolean;
};

interface OptionDropdownProps {
  options: {
    id: string;
    optionType: OPTION_DROPDOWN_TYPES;
    title?: string;
    style?: { items?: React.CSSProperties; option?: React.CSSProperties };
    items?: OptionDropDownItem[];
    values?: string | { name: string; value?: string | boolean }[];
  }[];
  menuButtonType: OPTION_DROPDOWN_MENU_BUTTON_TYPES;
  CustomButton?: FC<{ type: React.HTMLInputTypeAttribute; onChange?: React.ChangeEventHandler<HTMLInputElement>; defaultChecked?: boolean; active: boolean }>;
  selectedItemsForm?: ReturnType<typeof useForm>;
  noApplyButton?: boolean;
  triangle?: boolean;
  menuPosition?: OPTION_DROPDOWN_MENU_POSITIONS;
  menuOverlayPosition?: OPTION_DROPDOWN_MENU_OVERLAY_POSITIONS;
  onSubmit?: (data: any) => void;
  loading?: boolean;
  buttonLoading?: boolean;
  containerRelative?: boolean;
  mobileDisplayIcon?: boolean;
}

export type OptionDropdownRef = {
  toggleMenu?: () => void;
};

const OptionDropdown = forwardRef<OptionDropdownRef, OptionDropdownProps>(
  (
    {
      options,
      menuButtonType,
      selectedItemsForm,
      CustomButton,
      noApplyButton,
      triangle,
      menuPosition = OPTION_DROPDOWN_MENU_POSITIONS.LEFT,
      menuOverlayPosition = OPTION_DROPDOWN_MENU_OVERLAY_POSITIONS.DOWN,
      onSubmit,
      loading,
      buttonLoading,
      containerRelative = false,
      mobileDisplayIcon = true,
      width,
      height
    },
    ref
  ) => {
    const itemMenuRef = useRef<HTMLDivElement>(null);
    const icons = useIcons();
    const filterIcon = icons?.filterSVG?.publicURL;
    const plusIcon = icons?.plusTransparentSmall?.childImageSharp?.gatsbyImageData?.images?.fallback?.src;
    const threeDotsIcon = icons?.edit?.childImageSharp?.gatsbyImageData?.images?.fallback?.src;
    const moreIcon = icons?.moreIconSVG?.publicURL;
    const plusBlackIcon = icons?.plusBlack?.childImageSharp?.gatsbyImageData?.images?.fallback?.src;
    const plusBlackIconSVG = icons?.plusBlackSVG?.publicURL;
    const moreBlackIcon = icons?.moreBlack?.publicURL;
    const { control, setValue } = useForm();

    const toggledMenu = useWatch({
      control,
      name: 'menu',
      defaultValue: false
    });

    const closeMenu = () => {
      setValue('menu', false);
    };

    const toggleMenu = () => {
      setValue('menu', !toggledMenu);
    };

    const handleBlur = useCallback(
      (e: React.FocusEvent<HTMLDivElement, Element>) => {
        const currentTarget = e.currentTarget;
        requestAnimationFrame(() => {
          if (!currentTarget.contains(document.activeElement)) {
            closeMenu();
          }
        });
      },
      [closeMenu]
    );
    const allButtons = options.every(({ optionType }) => OPTION_DROPDOWN_TYPES.BUTTONS === optionType);

    const menuIcon = useMemo(() => {
      switch (menuButtonType) {
        case OPTION_DROPDOWN_MENU_BUTTON_TYPES.FILTER:
          return filterIcon;
        case OPTION_DROPDOWN_MENU_BUTTON_TYPES.PLUS:
          return plusBlackIcon;
        case OPTION_DROPDOWN_MENU_BUTTON_TYPES.THREE_DOT:
          return threeDotsIcon;
        case OPTION_DROPDOWN_MENU_BUTTON_TYPES.MORE:
          return moreIcon;
        case OPTION_DROPDOWN_MENU_BUTTON_TYPES.PLUS_BLACK:
          return plusBlackIcon;
        case OPTION_DROPDOWN_MENU_BUTTON_TYPES.MORE_BLACK:
          return moreBlackIcon;
        case OPTION_DROPDOWN_MENU_BUTTON_TYPES.PLUS_BLACK_SVG:
          return plusBlackIconSVG;
        default:
          return plusIcon;
      }
    }, [menuButtonType, filterIcon, plusIcon, threeDotsIcon, plusBlackIcon]);

    const menuButtonLoaderColor = useMemo(() => {
      switch (menuButtonType) {
        case OPTION_DROPDOWN_MENU_BUTTON_TYPES.FILTER:
          return Colors.white;
        case OPTION_DROPDOWN_MENU_BUTTON_TYPES.PLUS:
          return Colors.white;
        case OPTION_DROPDOWN_MENU_BUTTON_TYPES.THREE_DOT:
          return Colors.black;
        default:
          return Colors.white;
      }
    }, [menuButtonType, filterIcon, plusIcon, threeDotsIcon, plusBlackIcon]);

    useImperativeHandle(ref, () => ({
      toggleMenu
    }));

    // useEffect(() => {
    //   //find if the container is near the bottom of the screen, if so, move the menu to upside
    //   if (itemMenuRef.current && toggledMenu) {
    //     const { bottom } = itemMenuRef.current?.getBoundingClientRect();
    //     const windowHeight = window.innerHeight;
    //     if (windowHeight - bottom < itemMenuRef.current.clientHeight + 20) {
    //       itemMenuRef.current.style.top = 'auto';
    //       itemMenuRef.current.style.bottom = '20px';
    //     }
    //   }
    // }, [toggledMenu]);

    return (
      <OptionDropdownContainer tabIndex={0} onBlur={handleBlur} relative={containerRelative}>
        <Controller
          control={control}
          name="menu"
          render={({ onChange, value }) => {
            const onChangeHandler = () => {
              if (options.length) {
                onChange(!value);
              }
            };

            if (options.every(option => !option.items?.length && OPTION_DROPDOWN_REQUIRE_ITEMS[option.optionType])) {
              return <></>;
            }

            return (
              <>
                {menuButtonType !== OPTION_DROPDOWN_MENU_BUTTON_TYPES.CUSTOM_BUTTON && (
                  <MenuButton bgImage={!buttonLoading && menuIcon} active={toggledMenu} buttonType={menuButtonType} mobileDisplayIcon={mobileDisplayIcon} width={width} height={height}>
                    {buttonLoading && <CenteredLoader size={20} color={menuButtonLoaderColor} />}
                    {!buttonLoading && (
                      <>
                        {[...Array(2)].map((_, index) => (
                          <span key={index} />
                        ))}
                        <FormInput name="menu" type="checkbox" onChange={onChangeHandler} defaultChecked={false} />
                      </>
                    )}
                  </MenuButton>
                )}

                {menuButtonType === OPTION_DROPDOWN_MENU_BUTTON_TYPES.CUSTOM_BUTTON && CustomButton && (
                  <CustomButton type="checkbox" onChange={onChangeHandler} defaultChecked={false} active={toggledMenu} />
                )}
              </>
            );
          }}
          defaultValue={false}
        />

        <ItemMenu active={toggledMenu} menuPosition={menuPosition} fullButton={allButtons} menuOverlayPosition={menuOverlayPosition} ref={itemMenuRef}>
          {triangle && <Triangle menuPosition={menuPosition} />}
          {options.map((option, optionIndex) => {
            const { optionType, items, title, id, values } = option;
            if (OPTION_DROPDOWN_REQUIRE_ITEMS[optionType] && (!items || !items.length)) {
              return <Fragment key={optionIndex}></Fragment>;
            }
            return (
              <OptionContainer active={toggledMenu} key={optionIndex} optionType={optionType}>
                {title && <ButtonOptionLabel padding={allButtons ? 8 : undefined}>{title}</ButtonOptionLabel>}
                {optionType === OPTION_DROPDOWN_TYPES.CHECKBOX && selectedItemsForm && !!items?.length && (
                  <Controller
                    control={selectedItemsForm.control}
                    name={id}
                    render={({ onChange, value }) => {
                      return (
                        <FormCheckbox
                          itemsArray={items.map(item => ({
                            id: item.value,
                            name: item.name
                          }))}
                          onChange={onChange}
                          value={value}
                          error={selectedItemsForm.errors.selectedItems}
                          fontSize={14}
                          fullWidth
                        />
                      );
                    }}
                    defaultValue={values || []}
                  />
                )}

                {optionType === OPTION_DROPDOWN_TYPES.DATE && selectedItemsForm && (
                  <>
                    <Controller
                      control={selectedItemsForm.control}
                      as={<FormInput borderBox fullWidth type={'date'} height={40} fontSize={16} name={id} />}
                      name={id}
                      defaultValue={new Date().toISOString().split('T')[0]}
                    />

                    {selectedItemsForm.errors.date && <FormError>{selectedItemsForm.errors.date.message}</FormError>}
                  </>
                )}

                {optionType === OPTION_DROPDOWN_TYPES.SELECT && selectedItemsForm && !!items?.length && (
                  <Controller
                    control={selectedItemsForm.control}
                    name={id}
                    render={({ onChange, value }) => {
                      return (
                        <FormSelect
                          height={40}
                          fontSize={16}
                          fullWidth
                          name={id}
                          onChange={e => {
                            onChange(e.target.value);
                          }}
                          value={String(value)}
                        >
                          {items.map(item => {
                            const { value, name } = item;
                            return (
                              <option key={String(value || name)} value={String(value)}>
                                {name}
                              </option>
                            );
                          })}
                        </FormSelect>
                      );
                    }}
                    defaultValue={values || String(items[0].value)}
                  />
                )}

                {optionType === OPTION_DROPDOWN_TYPES.MULTI_SELECT && selectedItemsForm && !!items?.length && (
                  <Controller
                    control={selectedItemsForm.control}
                    name={id}
                    as={
                      <CreatableSelect
                        defaultValue={[]}
                        styles={{ container: provided => ({ ...provided, width: '100%' }), valueContainer: provided => ({ ...provided, height: 32, overflowY: 'scroll' }) }}
                        options={items.map(item => ({
                          ...item,
                          value: item.value,
                          label: item.name
                        }))}
                        isMulti
                        theme={selectTheme}
                        name={id}
                      />
                    }
                    defaultValue={values || []}
                  />
                )}

                {optionType === OPTION_DROPDOWN_TYPES.NUMBER && selectedItemsForm && (
                  <Controller
                    control={selectedItemsForm.control}
                    name={id}
                    render={({ onChange, value }) => {
                      return (
                        <FormInput
                          type="number"
                          fullWidth
                          paddingTopBottom={16}
                          borderBox
                          height={24}
                          fontSize={16}
                          name={id}
                          onChange={e => {
                            onChange(e.target.value);
                          }}
                          value={value}
                        />
                      );
                    }}
                    defaultValue={values || ''}
                  />
                )}

                {optionType === OPTION_DROPDOWN_TYPES.RADIO && selectedItemsForm && !!items?.length && (
                  <Controller
                    control={selectedItemsForm.control}
                    name={id}
                    render={({ onChange, value }) => {
                      return (
                        <RadioBtnsGroup
                          options={items.map(item => String(item.value))}
                          as={items.map(item => item.name)}
                          onChange={(e: any) => {
                            onChange(e.target.value);
                          }}
                          defaultValue={(values as string) || String(items[0].value)}
                          value={String(value)}
                          name={id}
                          inputType="radio"
                        />
                      );
                    }}
                    defaultValue={values || String(items[0].value)}
                  />
                )}

                {optionType === OPTION_DROPDOWN_TYPES.BUTTONS && toggledMenu && (
                  <>
                    {!!items?.length &&
                      items.map((item, index) => {
                        const Button = (props: any) => (
                          <ItemButton
                            active={toggledMenu}
                            danger={!!item.danger}
                            green={!!item.green}
                            disabled={!!item.disabled}
                            key={index}
                            onClick={() => {
                              if (!item.disabled) {
                                props.onClick?.(item);
                                closeMenu();
                              }
                            }}
                            isLast={index === items.length - 1 && optionIndex === options.length - 1}
                          >
                            {item.name}
                          </ItemButton>
                        );
                        if (item.CustomComponent) {
                          const { CustomComponent } = item;
                          return <CustomComponent key={index} Button={Button} />;
                        }
                        return <Button onClick={item.onClick} key={index} />;
                      })}
                  </>
                )}
              </OptionContainer>
            );
          })}

          {!noApplyButton && (
            <ApplyButton
              active={toggledMenu}
              loading={loading}
              type="submit"
              onClick={() => {
                closeMenu();
                selectedItemsForm?.handleSubmit(() => {
                  onSubmit?.(options);
                })();
              }}
            >
              {!loading && 'Apply'}
              {loading && <CenteredLoader size={20} />}
            </ApplyButton>
          )}
        </ItemMenu>
      </OptionDropdownContainer>
    );
  }
);

export default OptionDropdown;
