import { useLazyQuery } from '@apollo/client';
import React, { useCallback, useEffect } from 'react';
import { sentenceCase } from 'sentence-case';
import { ExtensionDivider, ExtensionPetCaseLabel, ExtensionWideColumn, ExtensionWideColumnInfo, ExtensionWideColumnScrollable, FlexContainer, Note } from '../../../components/Pets/styled';
import { Pet } from '../../../components/Pets/types';
import { CenteredLoader } from '../../../components/Shared/Spinner';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { GetBreeds } from '../../../queries';
import { getKeys, JSONParse } from '../../../utils/helpers';
import { DeselectReportButton, MedicalCaseDetailsContainer } from '../Reports/Medical/styled';
import { PetForm } from './PetFormsHistory';

const PetFormDetails = ({
  innerRef,
  selectedFormItem,
  deselectForm,
  petForms,
  fullWidth = false,
  loadingForm,
  pet
}: {
  innerRef?: React.Ref<HTMLDivElement>;
  selectedFormItem: PetForm;
  petForms: PetForm[];
  deselectForm?: () => void;
  fullWidth?: boolean;
  loadingForm?: boolean;
  pet: Pet;
}) => {
  const hasSelectedForm = !!selectedFormItem?.id;
  const selectedForm = selectedFormItem?.form;
  const questions = selectedFormItem?.questions;
  const { tablet } = useMediaQuery({ tablet: true });
  const isAnswered = selectedFormItem?.isAnswered;
  const answers = selectedFormItem?.answers;
  const [getBreedById, { data: { getBreeds: [newBreed = {}] = [] } = {}, loading: loadingBreed }] = useLazyQuery(GetBreeds, {
    fetchPolicy: 'cache-and-network'
  });
  const breedName = loadingBreed ? '' : newBreed?.name || pet?.Breed?.name || '';
  const loading = loadingForm || loadingBreed;

  useEffect(() => {
    const breedQuestion = questions?.find(question => question?.get_answers_from === 'BREED');
    const breedId = answers?.find(answer => answer?.Question?.id === breedQuestion?.id)?.body;
    if (breedQuestion && breedId && pet?.Breed?.id !== breedId) {
      getBreedById({ variables: { id: [breedId] } });
    }
  }, [pet, questions, answers]);

  const getAnswer = useCallback(
    (questionId: string) => {
      const answer = answers?.find(answer => answer?.Question?.id === questionId);
      const question = questions?.find(question => question?.id === questionId);
      let answerString = answer?.body || answer?.AnswerOptions?.map(option => option?.title).join('\n');
      if (answerString === 'true') {
        answerString = 'Yes';
      }
      if (answerString === 'false') {
        answerString = 'No';
      }

      if (question?.get_answers_from === 'CHRONIC_CONDITIONS') {
        const parsedAnswer = JSONParse(answer?.body || '{}');
        const conditions = getKeys(parsedAnswer || {})
          .filter(cond => parsedAnswer[cond])
          .map(cond => sentenceCase(cond || ''))
          .join(', ');
        answerString = conditions;
      }

      if (question?.get_answers_from === 'BREED') {
        answerString = breedName;
      }

      if (question?.type === 'ADDRESS') {
        const parsedAnswer = JSONParse(answer?.body || '[{}]')?.[0];
        answerString = `${parsedAnswer?.country || ''} ${parsedAnswer?.city || ''} ${parsedAnswer?.line1 || ''} ${parsedAnswer?.line2 || ''}`;
      }

      if (question?.type === 'PHONE') {
        const parsedAnswer = JSONParse(answer?.body || '{}');
        answerString = `${parsedAnswer?.country_code || ''} ${parsedAnswer?.number || ''}`;
      }

      if (question?.get_answers_from === 'VET' || question?.get_answers_from === 'INSURER') {
        const parsedAnswer = JSONParse(answer?.body || '{}');
        answerString = `Name: ${parsedAnswer?.name || ''}, 
        Address: , ${parsedAnswer?.address?.line1 || ''},  ${parsedAnswer?.address?.line2 || ''}, ${parsedAnswer?.address?.city || ''},  ${parsedAnswer?.address?.country || ''}`;
      }

      return answerString;
    },
    [answers, questions, breedName]
  );

  const formName = `${selectedForm?.name || 'Completed Form'} ${selectedFormItem?.orderNumber ? `(${selectedFormItem?.orderNumber})` : ''}`;

  return (
    <>
      <ExtensionWideColumn
        ref={innerRef}
        active={hasSelectedForm}
        fullWidth={fullWidth}
        justifyContent="flex-start"
        alignItems="flex-start"
        style={{ maxWidth: '550px', marginLeft: 'auto', marginRight: 'auto' }}
      >
        {!petForms.length && loading && <CenteredLoader size={70} />}
        {!!petForms.length && hasSelectedForm && (
          <>
            <ExtensionPetCaseLabel fontSize={20} fontWeight={'900'} margingB={8}>
              {tablet && <DeselectReportButton onClick={deselectForm}>{'<'}</DeselectReportButton>}
              {!tablet && formName}
              {tablet && 'Forms'}
              <span />
            </ExtensionPetCaseLabel>
            <ExtensionDivider />
            {isAnswered && (
              <>
                <ExtensionWideColumnInfo scrollable>
                  {tablet && (
                    <>
                      <FlexContainer>
                        <ExtensionPetCaseLabel fontSize={20} fontWeight={'900'} margingB={8}>
                          {formName}
                        </ExtensionPetCaseLabel>
                      </FlexContainer>
                      <ExtensionDivider />
                    </>
                  )}
                  <ExtensionWideColumnScrollable>
                    {questions?.filter(Boolean)?.map((question, index) => (
                      <Note key={index}>
                        <ExtensionPetCaseLabel fontSize={16} fontWeight={'700'} margingB={8} whiteSpace="normal">
                          {question?.title}
                        </ExtensionPetCaseLabel>
                        <ExtensionPetCaseLabel fontSize={14} fontWeight={'400'} margingB={8} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '2px' }}>
                          {getAnswer(question?.id)
                            ?.split(', ')
                            .map((item, index) => (
                              <span key={index}>{item}</span>
                            ))}
                        </ExtensionPetCaseLabel>
                      </Note>
                    ))}
                  </ExtensionWideColumnScrollable>
                </ExtensionWideColumnInfo>
                <ExtensionDivider />
              </>
            )}
            {!isAnswered && (
              <ExtensionPetCaseLabel fontSize={16} fontWeight={'600'} margingB={8}>
                This form has not been answered yet.
              </ExtensionPetCaseLabel>
            )}
          </>
        )}

        {!hasSelectedForm && !loading && (
          <MedicalCaseDetailsContainer>
            <ExtensionPetCaseLabel fontSize={16} fontWeight={'600'} margingB={8}>
              Please select a form to view details.
            </ExtensionPetCaseLabel>
          </MedicalCaseDetailsContainer>
        )}
      </ExtensionWideColumn>
    </>
  );
};

export default PetFormDetails;
