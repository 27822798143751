import React, { useState } from 'react';
import SideTabs from '../../components/Shared/SideTabs/SideTabs';
import { BranchRoute, BranchVehicle, FLEETS_SECTIONS, FLEETS_TABS, FLEETS_TABS_TYPES } from './types';
import { MainContainer, ViewWrapper } from '../../components/Shared/Shared';
import { Header, ViewSwitchContainer } from '../Bookings/components/BookingsHeader/styled';
import BookingsNavigation from '../Bookings/components/BookingsNavigation/BookingsNavigation';
import ModalDialog from '../../components/Modal/ModalDialog';
import BranchRouteModal from './BranchRouteModal/BranchRouteModal';
import useIcons from '../../hooks/useIcons';
import { Divider } from '../../components/SideBar/styled';
import ToolBar from '../../components/Shared/ToolBar/ToolBar';
import { GetAllBranchRoutes, GetAllBranchVehicles } from '../../queries';
import { useQuery } from '@apollo/client';
import OptionDropdown from '../../components/Shared/Menus/OptionDropdown/OptionDropdown';
import { OPTION_DROPDOWN_MENU_BUTTON_TYPES, OPTION_DROPDOWN_TYPES } from '../../components/Shared/Menus/OptionDropdown/types';
import BranchVehicleModal from './BranchVehicleModal/BranchVehicleModal';
import { GatsbyImage } from 'gatsby-plugin-image';

const Fleets = () => {
  const tabsValues = FLEETS_TABS.map(tab => tab.value);
  const tabValue = tabsValues?.find(tab => tab === location.pathname.split('/')[3]) as string;
  const currentTab = tabValue || FLEETS_TABS_TYPES.ROUTES;
  const Component = FLEETS_TABS.find(tab => tab.value === currentTab)?.component || (() => null);

  const { data: { getBranchRoutes: branchRoutes = [] } = {} } = useQuery<{ getBranchRoutes: BranchRoute[] }>(GetAllBranchRoutes, {
    fetchPolicy: 'cache-and-network'
  });

  const { data: { getBranchVehicles: branchVehicles = [] } = {} } = useQuery<{ getBranchVehicles: BranchVehicle[] }>(GetAllBranchVehicles, {
    fetchPolicy: 'cache-and-network'
  });

  const toolBarButtons = currentTab === FLEETS_TABS_TYPES.ROUTES ? branchRoutes : FLEETS_TABS_TYPES.SCHEDULE ? branchVehicles : [];
  const [isShortestPath, setIsShortestPath] = useState(false);
  const icons = useIcons();
  const viewSewitchOn = icons?.viewSwitchOn?.childImageSharp.gatsbyImageData;
  const viewSewitchOff = icons?.viewSwitchOff?.childImageSharp.gatsbyImageData;

  const handleClick = () => {
    setIsShortestPath(!isShortestPath);
  };

  return (
    <ViewWrapper>
      <SideTabs sections={FLEETS_SECTIONS} defaultTab={FLEETS_TABS_TYPES.ROUTES} />
      <MainContainer>
        {currentTab === FLEETS_TABS_TYPES.SCHEDULE && (
          <>
            <Header>
              <BookingsNavigation />
            </Header>
            <Divider />
          </>
        )}

        <ToolBar
          toolBarButtons={toolBarButtons}
          urlSearchParam={currentTab === FLEETS_TABS_TYPES.ROUTES ? 'route' : currentTab === FLEETS_TABS_TYPES.VEHICLES ? 'vehicle' : 'vehicle'}
          selectOnlyOne={currentTab !== FLEETS_TABS_TYPES.SCHEDULE}
          middleAction={
            currentTab === FLEETS_TABS_TYPES.ROUTES ? (
              <ViewSwitchContainer onClick={handleClick}>
                {!isShortestPath ? (
                  <GatsbyImage image={viewSewitchOn} alt="viewSewitchOn" style={{ cursor: 'pointer' }} />
                ) : (
                  <GatsbyImage image={viewSewitchOff} alt="viewSewitchOff" style={{ cursor: 'pointer' }} />
                )}
              </ViewSwitchContainer>
            ) : null
          }
          displayString={currentTab === FLEETS_TABS_TYPES.VEHICLES && 'Vehicles'}
          customizedToolComponent={
            <OptionDropdown
              menuButtonType={OPTION_DROPDOWN_MENU_BUTTON_TYPES.PLUS}
              noApplyButton
              options={[
                {
                  id: 'add-route',
                  optionType: OPTION_DROPDOWN_TYPES.BUTTONS,
                  items: [
                    {
                      name: currentTab === FLEETS_TABS_TYPES.ROUTES ? 'Add Route' : 'Add Vehicle',
                      value: 'add-route',
                      onClick: () =>
                        currentTab === FLEETS_TABS_TYPES.ROUTES
                          ? ModalDialog.openModal({
                              title: 'New Route',
                              content: () => <BranchRouteModal />
                            })
                          : ModalDialog.openModal({
                              title: 'New Vehicle',
                              content: () => <BranchVehicleModal />
                            })
                    }
                  ]
                }
              ]}
            />
          }
          displayTools={true}
          displayAll={currentTab !== FLEETS_TABS_TYPES.ROUTES}
          defaultValue={currentTab == FLEETS_TABS_TYPES.ROUTES ? branchRoutes[0]?.id : ''}
        />
        <Component isShortestPath={isShortestPath} />
      </MainContainer>
    </ViewWrapper>
  );
};

export default Fleets;
