import { BusUserProfile } from '../components/Profile/types';
import { GetBusUserProfile } from '../queries';
import client from './client';

export const getBranchCurrencySymbol = () => {
  try {
    const cache = client.cache.readQuery<{ getBusUserProfile: BusUserProfile }>({
      query: GetBusUserProfile
    });
    return cache?.getBusUserProfile?.Branch?.branchCurrency?.symbol || '£';
  } catch (e) {
    return '£';
  }
};
