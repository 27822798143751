import { useQuery } from '@apollo/client';
import React from 'react';
import { GetBranchVehiclesRoutesViews } from '../../queries';
import { BOOKING_STATUS_TYPES, BOOKING_TYPE } from '../Bookings/types';
import { BranchVehicleRoutesViews } from './types';
import useURLSearchParams from '../../hooks/useURLSearchParams';
import { getNextDay, getStartOfDate } from '../../utils/dates';
import FleetScheduleList from './FleetScheduleList';

const FleetsSchedule = () => {
  const [defaultDate] = useURLSearchParams('date');
  const [selectedVehicles] = useURLSearchParams('vehicle');
  const selectedDate = defaultDate ? new Date(defaultDate) : new Date();
  const { data: { getBranchVehiclesRoutesViews: branchVehichleRoutesViews } = {} } = useQuery<{ getBranchVehiclesRoutesViews: BranchVehicleRoutesViews }>(GetBranchVehiclesRoutesViews, {
    fetchPolicy: 'cache-and-network',
    variables: {
      timestamp_from: getStartOfDate(selectedDate),
      timestamp_to: getStartOfDate(getNextDay(new Date(selectedDate))),
      appointment_status: [BOOKING_STATUS_TYPES.CONFIRMED],
      booking_type: [BOOKING_TYPE.SLOT, BOOKING_TYPE.MULTI_SLOT, BOOKING_TYPE.MULTI_DAY],
      BranchVehicleId: selectedVehicles?.length ? selectedVehicles : undefined
    }
  });

  const singleDayVehiclesRoutesViews = branchVehichleRoutesViews?.singleDayVehiclesRoutesViews?.[0]?.views || [];

  const branchVehicleRoutesViews = singleDayVehiclesRoutesViews?.flatMap(route => route?.branchVehicleRoutesViews || []) || [];

  return (
    <>
      <FleetScheduleList singleDayVehiclesRoutesViews={singleDayVehiclesRoutesViews} />
    </>
  );
};

export default FleetsSchedule;
