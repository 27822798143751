export enum ALLOWED_COUNTS_NAMES {
  serviceTotalsOrdersCountAmount = 'serviceTotalsOrdersCountAmount',
  serviceTimeslotsAppointmentsCountAmount = 'serviceTimeslotsAppointmentsCountAmount',
  serviceDayOfWeekAppointmentsCountAmount = 'serviceDayOfWeekAppointmentsCountAmount',
  serviceTotalsPaidUnpaidAmount = 'serviceTotalsPaidUnpaidAmount',
  serviceCanceledOrdersCount = 'serviceCanceledOrdersCount',
  productTotalsOrdersCountAmount = 'productTotalsOrdersCountAmount',
  subscriptionTotalsOrderRepeatsCountAmount = 'subscriptionTotalsOrderRepeatsCountAmount',
  subscriptionOrderStartDateMonthsCountAmount = 'subscriptionOrderStartDateMonthsCountAmount',
  subscriptionOrderEndDateMonthsCountAmount = 'subscriptionOrderEndDateMonthsCountAmount',
  appUserServiceFrequencyCountAmount = 'appUserServiceFrequencyCountAmount',
  appUserPetServiceFrequencyCountAmount = 'appUserPetServiceFrequencyCountAmount',
  neverBookedAppUserPetServiceFrequencyCountAmount = 'neverBookedAppUserPetServiceFrequencyCountAmount',
  appUserProductOrderFrequencyCountAmount = 'appUserProductOrderFrequencyCountAmount',
  appUserTopSpender = 'appUserTopSpender',
  orderCreateTimesCountAmount = 'orderCreateTimesCountAmount',
  orderCreateDayOfWeekCountAmount = 'orderCreateDayOfWeekCountAmount',
  petTypeCount = 'petTypeCount',
  petTypeBreedCount = 'petTypeBreedCount',
  petGenderCount = 'petGenderCount',
  petAgeCount = 'petAgeCount',
  petNeuteringCount = 'petNeuteringCount',
  cancelationReasonsMonthsCount = 'cancelationReasonsMonthsCount',
  capacityDaysCount = 'capacityDaysCount'
}

export const ALLOWED_COUNTS_NAMED_GROUPS: {
  name: string;
  label: string;
  options: (keyof typeof ALLOWED_COUNTS_NAMES)[];
}[] = [
  {
    name: 'service',
    label: 'Service Reports',
    options: [
      ALLOWED_COUNTS_NAMES.serviceTotalsOrdersCountAmount,
      ALLOWED_COUNTS_NAMES.serviceTimeslotsAppointmentsCountAmount,
      ALLOWED_COUNTS_NAMES.serviceDayOfWeekAppointmentsCountAmount,
      ALLOWED_COUNTS_NAMES.serviceTotalsPaidUnpaidAmount,
      ALLOWED_COUNTS_NAMES.serviceCanceledOrdersCount
    ]
  },
  {
    name: 'product',
    label: 'Product Reports',
    options: [ALLOWED_COUNTS_NAMES.productTotalsOrdersCountAmount]
  },
  {
    name: 'subscription',
    label: 'Membership Reports',
    options: [
      ALLOWED_COUNTS_NAMES.subscriptionTotalsOrderRepeatsCountAmount,
      ALLOWED_COUNTS_NAMES.subscriptionOrderStartDateMonthsCountAmount,
      ALLOWED_COUNTS_NAMES.subscriptionOrderEndDateMonthsCountAmount
    ]
  },
  {
    name: 'user',
    label: 'User Reports',
    options: [
      ALLOWED_COUNTS_NAMES.appUserServiceFrequencyCountAmount,
      ALLOWED_COUNTS_NAMES.appUserPetServiceFrequencyCountAmount,
      ALLOWED_COUNTS_NAMES.neverBookedAppUserPetServiceFrequencyCountAmount,
      ALLOWED_COUNTS_NAMES.appUserProductOrderFrequencyCountAmount,
      ALLOWED_COUNTS_NAMES.appUserTopSpender
    ]
  },
  {
    name: 'order',
    label: 'Order Reports',
    options: [ALLOWED_COUNTS_NAMES.orderCreateTimesCountAmount, ALLOWED_COUNTS_NAMES.orderCreateDayOfWeekCountAmount]
  },
  {
    name: 'pet',
    label: 'Pet Reports',
    options: [ALLOWED_COUNTS_NAMES.petTypeCount, ALLOWED_COUNTS_NAMES.petTypeBreedCount, ALLOWED_COUNTS_NAMES.petGenderCount, ALLOWED_COUNTS_NAMES.petAgeCount, ALLOWED_COUNTS_NAMES.petNeuteringCount]
  },
  {
    name: 'other',
    label: 'Other Reports',
    options: [ALLOWED_COUNTS_NAMES.cancelationReasonsMonthsCount, ALLOWED_COUNTS_NAMES.capacityDaysCount]
  }
];

export enum ALLOWED_COUNTS_FILTERS_TYPES {
  serviceId = 'serviceId',
  productId = 'productId',
  subscriptionId = 'subscriptionId',
  productType = 'productType',
  appUserId = 'appUserId',
  timestamp_from = 'timestamp_from',
  timestamp_to = 'timestamp_to',
  dateRange = 'dateRange'
}

export const ALLOWED_COUNTS_FILTERS_TYPES_OPTIONS: {
  [key in keyof typeof ALLOWED_COUNTS_FILTERS_TYPES]: {
    label: string;
    type: string;
  };
} = {
  [ALLOWED_COUNTS_FILTERS_TYPES.serviceId]: {
    label: 'Service',
    type: 'id'
  },
  [ALLOWED_COUNTS_FILTERS_TYPES.productId]: {
    label: 'Product',
    type: 'id'
  },
  [ALLOWED_COUNTS_FILTERS_TYPES.subscriptionId]: {
    label: 'Subscription',
    type: 'id'
  },
  [ALLOWED_COUNTS_FILTERS_TYPES.productType]: {
    label: 'Product Type',
    type: 'productType'
  },
  [ALLOWED_COUNTS_FILTERS_TYPES.appUserId]: {
    label: 'App User',
    type: 'appUserId'
  },
  [ALLOWED_COUNTS_FILTERS_TYPES.timestamp_from]: {
    label: 'From',
    type: 'timestamp_from'
  },
  [ALLOWED_COUNTS_FILTERS_TYPES.timestamp_to]: {
    label: 'To',
    type: 'timestamp_to'
  },
  [ALLOWED_COUNTS_FILTERS_TYPES.dateRange]: {
    label: 'Period',
    type: 'dateRange'
  }
};

export const allowedCountsItems: {
  [key in keyof typeof ALLOWED_COUNTS_NAMES]: {
    name: string;
    availableModes: ('table' | 'chart')[];
    allows: {
      type: ALLOWED_COUNTS_FILTERS_TYPES;
      as: string;
    }[];
  };
} = {
  [ALLOWED_COUNTS_NAMES.serviceTotalsOrdersCountAmount]: {
    name: 'Volume/Value',
    availableModes: ['table', 'chart'],
    allows: [
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.dateRange,
        as: 'dateRange'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.serviceId,
        as: 'id'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.timestamp_from,
        as: 'timestamp_from'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.timestamp_to,
        as: 'timestamp_to'
      }
    ]
  },
  [ALLOWED_COUNTS_NAMES.serviceTimeslotsAppointmentsCountAmount]: {
    name: 'Booking Volume (Timeslots)',
    availableModes: ['table', 'chart'],
    allows: [
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.dateRange,
        as: 'dateRange'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.serviceId,
        as: 'id'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.timestamp_from,
        as: 'timestamp_from'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.timestamp_to,
        as: 'timestamp_to'
      }
    ]
  },
  [ALLOWED_COUNTS_NAMES.serviceDayOfWeekAppointmentsCountAmount]: {
    name: 'Booking Volume (Day of Week)',
    availableModes: ['table', 'chart'],
    allows: [
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.dateRange,
        as: 'dateRange'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.serviceId,
        as: 'id'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.timestamp_from,
        as: 'timestamp_from'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.timestamp_to,
        as: 'timestamp_to'
      }
    ]
  },
  [ALLOWED_COUNTS_NAMES.serviceTotalsPaidUnpaidAmount]: {
    name: 'Money Flow',
    availableModes: ['table', 'chart'],
    allows: [
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.dateRange,
        as: 'dateRange'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.serviceId,
        as: 'id'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.timestamp_from,
        as: 'timestamp_from'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.timestamp_to,
        as: 'timestamp_to'
      }
    ]
  },
  [ALLOWED_COUNTS_NAMES.serviceCanceledOrdersCount]: {
    name: 'Cancellations',
    availableModes: ['table', 'chart'],
    allows: [
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.dateRange,
        as: 'dateRange'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.serviceId,
        as: 'id'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.timestamp_from,
        as: 'timestamp_from'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.timestamp_to,
        as: 'timestamp_to'
      }
    ]
  },
  [ALLOWED_COUNTS_NAMES.productTotalsOrdersCountAmount]: {
    name: 'Volume/Value',
    availableModes: ['table', 'chart'],
    allows: [
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.dateRange,
        as: 'dateRange'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.productId,
        as: 'id'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.timestamp_from,
        as: 'timestamp_from'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.timestamp_to,
        as: 'timestamp_to'
      }
    ]
  },
  [ALLOWED_COUNTS_NAMES.subscriptionTotalsOrderRepeatsCountAmount]: {
    name: 'Volume/Value',
    availableModes: ['table'],
    allows: [
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.dateRange,
        as: 'dateRange'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.subscriptionId,
        as: 'id'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.timestamp_from,
        as: 'timestamp_from'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.timestamp_to,
        as: 'timestamp_to'
      }
    ]
  },
  [ALLOWED_COUNTS_NAMES.subscriptionOrderStartDateMonthsCountAmount]: {
    name: 'New subscriptions (Month on Month)',
    availableModes: ['table', 'chart'],
    allows: [
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.dateRange,
        as: 'dateRange'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.subscriptionId,
        as: 'id'
      }
    ]
  },
  [ALLOWED_COUNTS_NAMES.subscriptionOrderEndDateMonthsCountAmount]: {
    name: 'Subscription Cancellations (Month on Month)',
    availableModes: ['table', 'chart'],
    allows: [
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.dateRange,
        as: 'dateRange'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.subscriptionId,
        as: 'id'
      }
    ]
  },
  [ALLOWED_COUNTS_NAMES.appUserServiceFrequencyCountAmount]: {
    name: 'Service Breakdown',
    availableModes: ['table'],
    allows: [
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.dateRange,
        as: 'dateRange'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.serviceId,
        as: 'id'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.appUserId,
        as: 'appUserId'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.timestamp_from,
        as: 'timestamp_from'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.timestamp_to,
        as: 'timestamp_to'
      }
    ]
  },
  [ALLOWED_COUNTS_NAMES.appUserPetServiceFrequencyCountAmount]: {
    name: 'Service Breakdown (By Pet)',
    availableModes: ['table'],
    allows: [
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.dateRange,
        as: 'dateRange'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.serviceId,
        as: 'id'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.appUserId,
        as: 'appUserId'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.timestamp_from,
        as: 'timestamp_from'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.timestamp_to,
        as: 'timestamp_to'
      }
    ]
  },
  [ALLOWED_COUNTS_NAMES.neverBookedAppUserPetServiceFrequencyCountAmount]: {
    name: 'Never booked within period (By Pet)',
    availableModes: ['table'],
    allows: [
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.dateRange,
        as: 'dateRange'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.serviceId,
        as: 'id'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.appUserId,
        as: 'appUserId'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.timestamp_from,
        as: 'timestamp_from'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.timestamp_to,
        as: 'timestamp_to'
      }
    ]
  },
  [ALLOWED_COUNTS_NAMES.appUserProductOrderFrequencyCountAmount]: {
    name: 'Product Breakdown',
    availableModes: ['table'],
    allows: [
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.dateRange,
        as: 'dateRange'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.productId,
        as: 'id'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.appUserId,
        as: 'appUserId'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.timestamp_from,
        as: 'timestamp_from'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.timestamp_to,
        as: 'timestamp_to'
      }
    ]
  },
  [ALLOWED_COUNTS_NAMES.appUserTopSpender]: {
    name: 'Total Spending',
    availableModes: ['table', 'chart'],
    allows: [
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.dateRange,
        as: 'dateRange'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.appUserId,
        as: 'appUserId'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.subscriptionId,
        as: 'subscriptionProductId'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.productId,
        as: 'productId'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.serviceId,
        as: 'serviceProductId'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.productType,
        as: 'productType'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.timestamp_from,
        as: 'timestamp_from'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.timestamp_to,
        as: 'timestamp_to'
      }
    ]
  },
  [ALLOWED_COUNTS_NAMES.orderCreateTimesCountAmount]: {
    name: 'Order Trends (Hour)',
    availableModes: ['table', 'chart'],
    allows: [
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.dateRange,
        as: 'dateRange'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.productId,
        as: 'productId'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.serviceId,
        as: 'productId'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.subscriptionId,
        as: 'productId'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.productType,
        as: 'productType'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.timestamp_from,
        as: 'timestamp_from'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.timestamp_to,
        as: 'timestamp_to'
      }
    ]
  },
  [ALLOWED_COUNTS_NAMES.orderCreateDayOfWeekCountAmount]: {
    name: 'Order Trends (Day)',
    availableModes: ['table', 'chart'],
    allows: [
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.dateRange,
        as: 'dateRange'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.productId,
        as: 'productId'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.serviceId,
        as: 'productId'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.subscriptionId,
        as: 'productId'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.productType,
        as: 'productType'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.timestamp_from,
        as: 'timestamp_from'
      },
      {
        type: ALLOWED_COUNTS_FILTERS_TYPES.timestamp_to,
        as: 'timestamp_to'
      }
    ]
  },
  [ALLOWED_COUNTS_NAMES.petTypeCount]: {
    name: 'Species',
    availableModes: ['table', 'chart'],
    allows: []
  },
  [ALLOWED_COUNTS_NAMES.petTypeBreedCount]: {
    name: 'Breed',
    availableModes: ['table', 'chart'],
    allows: []
  },
  [ALLOWED_COUNTS_NAMES.petGenderCount]: {
    name: 'Gender',
    availableModes: ['table', 'chart'],
    allows: []
  },
  [ALLOWED_COUNTS_NAMES.petAgeCount]: {
    name: 'Age Group',
    availableModes: ['table', 'chart'],
    allows: []
  },
  [ALLOWED_COUNTS_NAMES.petNeuteringCount]: {
    name: 'Neutering Status',
    availableModes: ['table', 'chart'],
    allows: []
  },
  [ALLOWED_COUNTS_NAMES.cancelationReasonsMonthsCount]: {
    name: 'Cancellation Reasons',
    availableModes: ['table', 'chart'],
    allows: []
  },
  [ALLOWED_COUNTS_NAMES.capacityDaysCount]: {
    name: 'Capacity',
    availableModes: ['table', 'chart'],
    allows: []
  }
};

export const ALLOWED_COUNTS_FILTERS_TYPES_LABELS = {
  [ALLOWED_COUNTS_FILTERS_TYPES.serviceId]: 'Service',
  [ALLOWED_COUNTS_FILTERS_TYPES.productId]: 'Product',
  [ALLOWED_COUNTS_FILTERS_TYPES.subscriptionId]: 'Membership',
  [ALLOWED_COUNTS_FILTERS_TYPES.productType]: 'Product Type',
  [ALLOWED_COUNTS_FILTERS_TYPES.appUserId]: 'Client',
  [ALLOWED_COUNTS_FILTERS_TYPES.timestamp_from]: 'From',
  [ALLOWED_COUNTS_FILTERS_TYPES.timestamp_to]: 'To',
  [ALLOWED_COUNTS_FILTERS_TYPES.dateRange]: 'Period'
};

export type BranchSummaryFormValues = {
  selectedCounts: {
    id: string;
    name: keyof typeof ALLOWED_COUNTS_NAMES;
    appliedFilters: {
      [key in keyof typeof ALLOWED_COUNTS_FILTERS_TYPES]?: string | number | string[] | number[] | undefined;
    } & {
      dateRange: string;
    };
  }[];
};
