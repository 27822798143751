import React from 'react';
import {
  BranchSummaryCountTable,
  BranchSummaryCountTableBody,
  BranchSummaryCountTableCell,
  BranchSummaryCountTableHeader,
  BranchSummaryCountTableHeaderCell,
  BranchSummaryCountTableRow
} from './styled';

const BranchSummaryDataTable = ({ headerRows, bodyColums }: { headerRows: { label: string }[]; bodyColums: { rows: { label: string; style?: React.CSSProperties }[] }[] }) => {
  if (!bodyColums.some(column => column.rows.length)) {
    return <p>No data</p>;
  }
  return (
    <BranchSummaryCountTable>
      <BranchSummaryCountTableHeader>
        {headerRows.map((row, index) => {
          return <BranchSummaryCountTableHeaderCell key={index}>{row.label}</BranchSummaryCountTableHeaderCell>;
        })}
      </BranchSummaryCountTableHeader>
      <BranchSummaryCountTableBody>
        {bodyColums.map((column, index) => {
          return (
            <BranchSummaryCountTableRow key={index}>
              {column.rows.map((row, index) => {
                return (
                  <BranchSummaryCountTableCell key={index} style={row.style}>
                    {row.label}
                  </BranchSummaryCountTableCell>
                );
              })}
            </BranchSummaryCountTableRow>
          );
        })}
      </BranchSummaryCountTableBody>
    </BranchSummaryCountTable>
  );
};

export default BranchSummaryDataTable;
