import { useLazyQuery, useQuery } from '@apollo/client';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useCallback } from 'react';
import { sentenceCase } from 'sentence-case';
import useIcons from '../../hooks/useIcons';
import usePaginatedQuery from '../../hooks/usePaginatedQuery';
import { reduceAppointments } from '../../hooks/useReduceAppointments';
import { GetAppUserBranchCreditsByAppUserId, GetAppUserBranchCreditTransactionsByAppUserId, GetOrderItems } from '../../queries';
import { addDrawerBar, unsetDrawerBars } from '../../reactive/actions';
import { formatDateToDayMonthAndYear } from '../../utils/dates';
import { getBranchCurrencySymbol } from '../../utils/getBranchCurrencySymbol';
import { ProductOrder } from '../../views/Orders/types';
import { BranchAppUser } from '../../views/Store/BranchBilling/types';
import { BranchSummaryCountMode } from '../../views/Store/BranchSummary/styled';
import { RecordBody } from '../../views/styled';
import { DRAWER_IDS } from '../DrawerBar/types';
import ModalDialog from '../Modal/ModalDialog';
import InfiniteList from '../Shared/InfiniteList/InfiniteList';
import { BookingListItem, BookingsDivider, BookingsListCotainer, ListHeaderContainer, ListHeaderText, ListHeaderWrapper, ListView, UserBookingContainer } from './styled';
import UserCreditsUpdateModal from './UserCreditsUpdateModal';
import { Booking as BookingStyled } from '../../views/Bookings/components/BookingsBody/styled';
import useMediaQuery from '../../hooks/useMediaQuery';
import { Divider } from '../Shared/SideTabs/styles';

type AppUserBranchCreditAction = {
  date: string;
  amount: string;
  by: { type: 'app' | 'bus'; id: string };
  type: 'CREDIT_ADDED' | 'CREDIT_REMOVED' | 'CREDIT_USED' | 'CREDIT_REFUNDED' | 'CREDIT_EXPIRED';
};
export type AppUserBranchCredit = {
  id: string;
  status: 'VALID' | 'EXPIRED';
  amount: number;
  AppUser: BranchAppUser;
  Branch: BranchAppUser;
  AppUserBranchCreditTransactions: AppUserBranchCreditTransaction[];
};
type AppUserBranchCreditTransaction = {
  id: string;
  status: 'APPLIED' | 'REVERSED';
  amount: number;
  Order: ProductOrder;
  AppUserBranchCredit: AppUserBranchCredit;
  expiry_date: string;
  action: AppUserBranchCreditAction;
};
const UserCredits = ({ userProfile }: { userProfile: BranchAppUser }) => {
  const icons = useIcons();
  const addIcon = icons?.add?.childImageSharp.gatsbyImageData;

  const { data: { getAppUserBranchCreditsByAppUserId: [credits] = [] } = {}, refetch } = useQuery<{ getAppUserBranchCreditsByAppUserId?: AppUserBranchCredit[] }>(GetAppUserBranchCreditsByAppUserId, {
    variables: {
      AppUserId: [userProfile.id]
    },
    fetchPolicy: 'cache-and-network'
  });
  const [getOrderItems] = useLazyQuery(GetOrderItems);

  const [[hasMoreCreditTransactions, setHasMoreCreditTransactions], queryResult] = usePaginatedQuery<AppUserBranchCreditTransaction[]>({
    query: GetAppUserBranchCreditTransactionsByAppUserId,
    limit: 10,
    otherVariables: {
      AppUserId: [userProfile.id]
    },
    otherParams: {
      skip: !credits
    }
  });

  const { data: { getAppUserBranchCreditTransactionsByAppUserId: transactions = [] } = {}, loading: loadingTransactions, fetchMore, refetch: refetchTransactions } = queryResult;

  const handleOrderClick = useCallback(async (order: ProductOrder) => {
    if (!order) return;
    const {
      data: {
        getOrderItems: [orderItem]
      }
    } = await getOrderItems({
      variables: {
        OrderId: order?.id
      }
    });
    const isBooking = orderItem.item.type === 'service';
    if (isBooking) {
      unsetDrawerBars();
      addDrawerBar({
        drawerId: DRAWER_IDS.BOOKING_DRAWER,
        recordData: reduceAppointments(orderItem.Appointments, {
          uniqueByOrderIdAndTimestamp: true,
          sortDesc: false
        }).flat()
      });
    } else {
      unsetDrawerBars();
      addDrawerBar({
        drawerId: DRAWER_IDS.ORDER_DRAWER,
        recordData: orderItem.Order
      });
    }
  }, []);
  const { mobile } = useMediaQuery({ mobile: true });
  const renderCreditTransaction = useCallback(
    (transaction: AppUserBranchCreditTransaction) => {
      const orderNumber = transaction.Order?.number;
      return (
        <BookingStyled key={transaction.id} onClick={() => handleOrderClick(transaction.Order)} minHeight="40px" mobileMinHeight="auto" mobilePadding="8px 10px">
          {!mobile && (
            <>
              <RecordBody fontWeight={'600'}>
                {sentenceCase(transaction.action.type)}
                {transaction.action.type === 'CREDIT_USED' && ` - ${orderNumber}`}
              </RecordBody>
              <RecordBody width="60" fontWeight={'800'} flex="unset" flexEnd>
                {getBranchCurrencySymbol()}
                {transaction.action.amount}
              </RecordBody>
              <RecordBody width="120" fontWeight={'600'} flexEnd noMargin flex="unset">
                {formatDateToDayMonthAndYear(new Date(transaction.action.date))}
              </RecordBody>
            </>
          )}
          {mobile && (
            <>
              <RecordBody mobileWidth="100%">
                <RecordBody fontWeight={'600'}>
                  {sentenceCase(transaction.action.type)}
                  {transaction.action.type === 'CREDIT_USED' && ` - ${orderNumber}`}
                </RecordBody>
                <RecordBody width="120" fontWeight={'600'} flexEnd noMargin flex="unset">
                  {formatDateToDayMonthAndYear(new Date(transaction.action.date))}
                </RecordBody>
              </RecordBody>
              <Divider />
              <RecordBody width="60" fontWeight={'800'} flex="unset" flexEnd>
                {getBranchCurrencySymbol()}
                {transaction.action.amount}
              </RecordBody>
            </>
          )}
        </BookingStyled>
      );
    },
    [handleOrderClick]
  );

  return (
    <ListView>
      <ListHeaderContainer>
        <ListHeaderWrapper>
          <ListHeaderText>Balance:</ListHeaderText>
          <ListHeaderText>
            {getBranchCurrencySymbol()}
            {credits?.amount || 0}
          </ListHeaderText>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <BranchSummaryCountMode
              style={{ display: 'flex', gap: 10, paddingBottom: 10 }}
              onClick={() =>
                ModalDialog.openModal({
                  content: () => <UserCreditsUpdateModal credits={credits} AppUserId={userProfile.id} />,
                  title: 'Add Credit',
                  onClose: () => {
                    refetch();
                    refetchTransactions();
                  }
                })
              }
            >
              <GatsbyImage image={addIcon} alt="Add Credit" />
            </BranchSummaryCountMode>
          </div>
        </ListHeaderWrapper>
        <BookingsDivider />
      </ListHeaderContainer>

      <InfiniteList
        hasMoreItems={hasMoreCreditTransactions}
        fetchMore={fetchMore}
        loading={loadingTransactions}
        setHasMoreItems={setHasMoreCreditTransactions}
        itemRenderer={renderCreditTransaction}
        list={transactions}
        offset={transactions?.length}
      />
    </ListView>
  );
};

export default UserCredits;
