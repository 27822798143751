import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import ModalDialog, { ModalLayout } from '../../../components/Modal/ModalDialog';
import SideTabs, { SIDE_TABS_TYPES } from '../../../components/Shared/SideTabs/SideTabs';
import { AddBranchRoute, EditBranchRoute } from '../../../queries';
import { FleetsConentContainer, FleetsContainer } from '../styled';
import { BranchRoute, ROUTE_MODAL_SECTIONS, ROUTE_MODAL_TABS_TYPES } from '../types';
import BranchRouteModalPets from './BranchRouteModalPets';
import BranchRouteModalSettings from './BranchRouteModalSettings';
import { BranchRouteFormValues, BranchRoutePetRecords } from './types';
import { FormSubmitButton } from '../../../components/Shared/Forms/Forms';
import SideTab from '../../../components/Shared/SideTabs/SideTabs';

const BranchRouteModal = ({ branchRoute }: { branchRoute?: BranchRoute }) => {
  const tabsState = useState(ROUTE_MODAL_TABS_TYPES.PETS);

  const [currentTab] = tabsState;

  const [addBranchRoute, { data: newBranchRoute, loading: loadingAddBranchRoute }] = useMutation(AddBranchRoute, {
    refetchQueries: ['getBranchRoutes'],
    awaitRefetchQueries: true
  });

  const [editBranchRoute, { data: editedBranchRoute, loading: loadingEditBranchRoute }] = useMutation(EditBranchRoute, {
    refetchQueries: ['getBranchRoutes'],
    awaitRefetchQueries: true
  });

  const loading = loadingAddBranchRoute || loadingEditBranchRoute;
  const added = !!newBranchRoute || !!editedBranchRoute;

  const formOptions = useForm<BranchRouteFormValues>({
    defaultValues: {
      selectedPets: [],
      branchRoute: {
        BranchAreaId: branchRoute?.BranchAreaId || '',
        color: branchRoute?.color || '#000000',
        job_duration: branchRoute?.job_duration || 0,
        name: branchRoute?.name || '',
        ProductId: branchRoute?.BranchRouteProducts?.map(product => product?.Product?.id) || [],
        sort_index: branchRoute?.sort_index || 0,
        BranchRoutePetRecords: (
          branchRoute?.BranchRoutePetRecords?.map(branchRoutePetRecord => ({
            RouteJobTypeId: branchRoutePetRecord?.RouteJobTypeId || '',
            PetRecordId: branchRoutePetRecord?.PetRecord?.id || '',
            sort_index: branchRoutePetRecord?.sort_index || 0
          })) || []
        )?.sort((a, b) => (a.sort_index || 0) - (b.sort_index || 0)),
        start_time: branchRoute?.start_time || ''
      }
    },
    shouldUnregister: false
  });

  const { control, watch, setValue, handleSubmit, formState, trigger } = formOptions;

  const { branchRoute: { BranchRoutePetRecords: watchedBranchRoutePetRecords = [] } = {}, selectedPets } = watch(['branchRoute', 'selectedPets']);

  const branchRoutePetRecordsFieldArray = useFieldArray<BranchRoutePetRecords>({
    control,
    name: 'branchRoute.BranchRoutePetRecords'
  });

  const onAddSelectedPets = () => {
    selectedPets.forEach((petId: string, index: number) => {
      const petData = watchedBranchRoutePetRecords.find(petData => petData.PetRecordId === petId);
      if (!petData) {
        branchRoutePetRecordsFieldArray.append({
          PetRecordId: petId,
          RouteJobTypeId: '',
          sort_index: watchedBranchRoutePetRecords.length + index
        });
      }
    });

    setValue('selectedPets', []);
  };

  const validate = (data: BranchRouteFormValues) => {
    const hasPets = !!data.branchRoute.BranchRoutePetRecords.length;
    const allPetsHaveJobTypes = data.branchRoute.BranchRoutePetRecords.every(pet => pet.RouteJobTypeId);
    const hasProducts = !!data.branchRoute.ProductId && !!data.branchRoute.ProductId.length;
    const allSettingsFilled =
      !!data.branchRoute.name &&
      !!data.branchRoute.BranchAreaId &&
      !!data.branchRoute.start_time &&
      !!data.branchRoute.color &&
      !!data.branchRoute.job_duration &&
      data.branchRoute.sort_index !== undefined;
    return hasPets && allPetsHaveJobTypes && allSettingsFilled && hasProducts;
  };

  const onSubmit = handleSubmit(data => {
    if (!validate(data)) {
      return;
    }

    const variables = {
      ...data.branchRoute,
      BranchRoutePetRecords: data.branchRoute.BranchRoutePetRecords.map(pet => ({
        ...pet,
        sort_index: Number(pet.sort_index)
      })),
      job_duration: Number(data.branchRoute.job_duration),
      sort_index: Number(data.branchRoute.sort_index)
    };

    if (!branchRoute?.id) {
      addBranchRoute({ variables });
      return;
    }

    editBranchRoute({
      variables: {
        id: branchRoute.id,
        ...variables
      }
    });
  });

  useEffect(() => {
    if (added) {
      ModalDialog.closeModal();
    }
  }, [added]);
  return (
    <ModalLayout
      sideTabs={
        <SideTab
          sections={ROUTE_MODAL_SECTIONS}
          type={SIDE_TABS_TYPES.STATE_BUTTONS}
          defaultTab="details"
          tabsState={tabsState}
          type={SIDE_TABS_TYPES.STATE_BUTTONS}
          isModal
          onClickTab={async () => {
            return await trigger();
          }}
          backgroundColor="f4f4f4"
        />
      }
      buttons={
        <>
          {!!selectedPets.length && <FormSubmitButton onClick={onAddSelectedPets}>Add Selected Pets</FormSubmitButton>}
          {!selectedPets.length && (
            <FormSubmitButton onClick={onSubmit} disabled={formState.isSubmitting}>
              {' '}
              {formState.isSubmitting || loading ? 'Saving...' : 'Save Changes'}
            </FormSubmitButton>
          )}
        </>
      }
      compact={false}
    >
      <>
        {currentTab === ROUTE_MODAL_TABS_TYPES.PETS && <BranchRouteModalPets formOptions={formOptions} branchRoutePetRecordsFieldArray={branchRoutePetRecordsFieldArray} branchRoute={branchRoute} />}
        {currentTab === ROUTE_MODAL_TABS_TYPES.SETTINGS && <BranchRouteModalSettings formOptions={formOptions} branchRoute={branchRoute} />}
      </>
    </ModalLayout>
  );
};

export default BranchRouteModal;
