import { useLazyQuery, useMutation } from '@apollo/client';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { GetBranchAppUserTags, GetBranchAppUsers, ReplaceBranchAppUserItemTag } from '../../queries';
import client from '../../utils/client';
import { BranchAppUser } from '../../views/Store/BranchBilling/types';
import BranchTagItemModal from '../../views/Store/BranchTags/BranchTagItemModal';
import BranchTagsModal from '../../views/Store/BranchTags/BranchTagsModal';
import { BRANCH_TAGS_TYPES, BranchAppUserTag, BranchTag } from '../../views/Store/BranchTags/types';
import Common from '../../views/Store/Common';
import ModalDialog from '../Modal/ModalDialog';
import { checkAddAppUserTagCapacity } from './utils';

type AppUserTagsModalProps = {
  appUsers: BranchAppUser[];
  refetchAppUsersAfterTag?: boolean;
  afterTag?: (newAppUsers: BranchAppUser[]) => void;
  extraTags?: BranchAppUserTag[];
};

const AppUserTagsModal: FC<AppUserTagsModalProps> = ({ appUsers, refetchAppUsersAfterTag, afterTag, extraTags }) => {
  const formOptions = useForm();
  const [loading, setLoading] = useState(false);
  const { handleSubmit } = formOptions;
  const type = BRANCH_TAGS_TYPES.APP_USER;
  const [replaceAppUserTags, { loading: loadingAddappUserTag, data: repalcedTags = {} }] = useMutation(ReplaceBranchAppUserItemTag, {
    ...(refetchAppUsersAfterTag && {
      awaitRefetchQueries: true,
      refetchQueries: ['getBranchAppUsers']
    })
  });

  const [refetchTagsById, { loading: loadingRefetchTags, data: { getBranchAppUserTags: refetchedTags = [] } = {} }] = useLazyQuery<{ getBranchAppUserTags: BranchAppUserTag[] }>(GetBranchAppUserTags, {
    fetchPolicy: 'cache-and-network'
  });

  const allAppUserTags = appUsers?.flatMap(appUser => appUser?.BranchAppUserTags || []);

  const sharedTagsBetweenappUsers = allAppUserTags.filter(tag => appUsers?.every(appUser => appUser?.BranchAppUserTags?.some(t => t?.id === tag?.id)));

  const uniqueSharedTags = [...new Set(sharedTagsBetweenappUsers?.map(tag => tag?.id))].map(id => allAppUserTags?.find(tag => tag?.id === id)!).concat(extraTags || []);

  const uniqueTagsRef = useRef<string[]>([]);
  const allTagsRef = useRef<{ tags: BranchAppUserTag[] }>({ tags: [] });

  const onSubmit = ({ selectedTags }) => {
    const tagIds = selectedTags.map((tag: any) => tag.value);
    const existingTagIds = allAppUserTags?.map(tag => tag?.id);
    const uniqueTagIds = [...new Set([...tagIds, ...existingTagIds])];

    uniqueTagsRef.current = uniqueTagIds;

    checkAddAppUserTagCapacity({
      handleAddTag: () => {
        replaceAppUserTags({ variables: { AppUserId: appUsers.map(({ id }) => id), BranchAppUserTagId: tagIds } });
      },
      appUsers,
      tags: allTagsRef?.current?.tags?.filter(tag => tagIds.includes(tag?.id))
    });
  };

  useEffect(() => {
    if (repalcedTags?.replaceBranchAppUserTagItems !== undefined) {
      const uniqueTagIds = uniqueTagsRef.current;
      refetchTagsById({ variables: { id: uniqueTagIds } });
    }
  }, [repalcedTags]);

  useEffect(() => {
    if (refetchedTags?.length) {
      setLoading(true);
      (async () => {
        const { data: { getBranchAppUsers: newAppUsers = [] } = {} } = await client.query<{ getBranchAppUsers: BranchAppUser[] }>({
          query: GetBranchAppUsers,
          variables: { appUser_id: appUsers.map(({ id }) => id), requisite_queries: ['appUser_id'], alternative_queries: [], offset: 0, limit: 1000 },
          fetchPolicy: 'network-only'
        });
        afterTag?.(newAppUsers);
      })();

      if (!refetchAppUsersAfterTag) {
        ModalDialog.closeModal();
        setLoading(false);
        return;
      }

      const refetchAppUsers = Common.get<() => Promise<void>>(`BranchAppUsers.GetBranchAppUsers.refetch`);
      if (refetchAppUsers) {
        refetchAppUsers()?.then(() => {
          setLoading(false);
        });
      }
      ModalDialog.closeModal();
    }
  }, [refetchedTags]);

  const onAddTag = useCallback(
    (newTag: BranchTag, type: BRANCH_TAGS_TYPES) => {
      const newExtraTags = [...(extraTags || []), ...(type === BRANCH_TAGS_TYPES.APP_USER ? [newTag as BranchAppUserTag] : [])];
      ModalDialog.openModal({
        content: () => <AppUserTagsModal appUsers={appUsers} refetchAppUsersAfterTag={refetchAppUsersAfterTag} afterTag={afterTag} extraTags={newExtraTags} />,
        title: appUsers.length > 1 ? 'Tag all' : `${appUsers?.[0]?.name}'s Tags`
      });
    },
    [appUsers, refetchAppUsersAfterTag, afterTag, extraTags]
  );

  const handleAddBranchTag = (tag: string) => {
    ModalDialog.openModal({
      content: () => <BranchTagsModal name={tag} presetType={type} onAddTag={onAddTag} />,
      title: 'Add New Smart Tag',
      isMini: true
    });
  };

  return (
    <BranchTagItemModal
      formOptions={formOptions}
      existingTagsItems={uniqueSharedTags}
      onSubmit={onSubmit}
      loading={loadingAddappUserTag || loadingRefetchTags || loading}
      handleAddBranchTag={handleAddBranchTag}
      type={type}
      ref={allTagsRef}
    />
  );
};

export default AppUserTagsModal;
