import { useQuery } from '@apollo/client';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useFieldArray } from 'react-hook-form';
import { BusUserProfile } from '../../../components/Profile/types';
import { FormHeaderLabel, FormLabel } from '../../../components/Shared/Forms/Forms';
import useIcons from '../../../hooks/useIcons';
import { GetAllBranchBusUsers, GetBusUserProfile } from '../../../queries';
import { Divider } from '../../Pets/Health/styled';
import { AddNewBtn, AddNewBtnIcon } from '../Products/styled';
import CustomizationsForm from './CustomizationsForm';
import { CustomizationsContainer, CustomizationsFormWrapper } from './styled';
import { CustomizationsFormRef, CustomizationsProps, CustomizationsRef, TransformedCustomization } from './types';
import { getSelectedCustomizations } from './utils';

const Customizations = forwardRef<CustomizationsRef, CustomizationsProps>(({ defaultValues, formOptions, hideRules }, ref) => {
  const icons = useIcons();
  const { data: { getBranchBusUsers: BusUsers = [] } = {} } = useQuery<{ getBranchBusUsers: BusUserProfile[] }>(GetAllBranchBusUsers);

  const { control, getValues, setValue, watch } = formOptions;

  const watchedCustomizations = watch('customizations');

  const customizationsFieldArray = useFieldArray<TransformedCustomization>({
    control,
    name: 'customizations'
  });

  const handleRemoveRow = (index: number) => {
    customizationsFieldArray.remove(index);
  };

  const handleAddRow = () => {
    const lastCustomizations = watchedCustomizations?.[watchedCustomizations?.length - 1];
    if (watchedCustomizations?.length && (lastCustomizations?.name === '' || !lastCustomizations?.options?.length || !lastCustomizations?.options?.[lastCustomizations?.options?.length - 1]?.title)) {
      return;
    }
    customizationsFieldArray.append({
      name: '',
      options: []
    });
  };

  const handleCustomizationsSubmit = () => {
    const { customizations } = getValues();

    const customizationsState = getSelectedCustomizations(customizations);

    return customizationsState;
  };

  useImperativeHandle(ref, () => ({
    handleCustomizationsSubmit
  }));

  const customizationsFormRef = useRef<(CustomizationsFormRef | null)[]>([]);

  return (
    <CustomizationsContainer>
      {customizationsFieldArray.fields.map((item, index) => (
        <CustomizationsFormWrapper key={item.id}>
          <div style={{ padding: 10 }}>
            <FormLabel bold>{watchedCustomizations[index].name || 'Variation'}</FormLabel>
          </div>

          <CustomizationsForm
            ref={el => (customizationsFormRef.current[index] = el)}
            onExpandOption={() => {
              customizationsFormRef.current.forEach((ref, i) => {
                if (i !== index) {
                  ref?.collapseAllOptions();
                }
              });
            }}
            formOptions={formOptions}
            index={index}
            customization={item}
            handleRemoveRow={handleRemoveRow}
            watchedCustomizations={watchedCustomizations}
            busUsers={BusUsers}
            hideRules={hideRules}
            defaultValue={defaultValues?.[index]}
          />
        </CustomizationsFormWrapper>
      ))}
      <AddNewBtn onClick={handleAddRow} noSpaceArround>
        <AddNewBtnIcon src={icons.plusBlack.childImageSharp.gatsbyImageData.images.fallback.src} />
        Add a variation
      </AddNewBtn>
    </CustomizationsContainer>
  );
});

export default Customizations;
