import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';
import styled, { css } from 'styled-components';
import { Rotate } from '../../components/Shared/Animations';
import { selectTheme } from '../../components/Shared/Forms/Forms';
import { ActivityIndicator } from '../../components/Shared/Spinner';
import { AddNewProductCategory, GetProductCategories } from '../../queries';
import Select from '../../components/Shared/Forms/Select';

export const ActionBtn = styled.button<{ bgImage?: string; rotateOnClick?: boolean; noMargin?: boolean; noBackgroundColor?: boolean }>`
  height: 28px
  width: 28px;
  object-fit: cover;
  border: none;
  border-radius: 15px;
  margin-right: 4px;
  background-repeat: no-repeat;

  ${({ noMargin }) =>
    noMargin &&
    css`
      margin-right: 0px;
    `}
  outline: none;
  cursor: pointer;
  ${props =>
    props.bgImage &&
    css`
      background-image: url(${props.bgImage});
    `}
  ${props =>
    props.rotateOnClick &&
    css`
      &:active {
        animation: ${Rotate} 1s cubic-bezier(0.04, 0.98, 0.71, 0.99);
      }
    `}

  ${props =>
    props.noMargin &&
    css`
      margin: 0;
    `}

    ${props =>
      props.noBackgroundColor &&
      css`
        background-color: transparent;
      `}
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
`;

export const AddNewContainer = styled.div`
  width: 60vw;
  max-width: 1170px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
export const PhotosContainer = styled.div`
  display: flex;
  align-items: center;
  overflow-x: scroll;
  overflow-y: hidden;
`;

export const AddImageContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 108px;
  height: 106px;
  background-color: ${props => (props.disabled ? '#e7e7e7' : 'white')};
  border: #7c7c7c 1px dashed;
  border-radius: 14px;
  margin-bottom: 29px;
  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
    `}
`;
export const AddImageButton = styled.div<{ disabled?: boolean }>`
  margin: 0 2px;
  &:hover {
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  }
`;
export const FlexColumnCenterBetweenContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-right: 13px;
`;
export const AddedImage = styled.img`
  width: 108px;
  height: 106px;
  border-radius: 14px;
  object-fit: cover;
`;

export const AddedImageLink = styled.a`
  width: 108px;
  height: 106px;
  border-radius: 14px;
  margin-bottom: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);
    filter: brightness(0.9);
  }
`;

export const RemoveAddedImage = styled.img<{ noMargin?: boolean }>`
  width: 16px;
  height: 16px;
  border-radius: 15px;
  object-fit: cover;
  margin-top: 11px;
  ${props => props.noMargin && 'margin: 0;'}
  &:hover {
    cursor: pointer;
  }
`;

export const CategorySelect = ({ onChange, value, createable, isMulti = true }: { onChange?: (value: any) => void; value?: any; createable?: boolean; isMulti?: boolean }) => {
  const { data: { productCategoryGet: categories = [] } = {}, loading, refetch: refetchCategories } = useQuery(GetProductCategories);
  const categoriesOptions = categories.map((category: any) => ({ value: category.id, label: category.name }));
  const [handleAddNewProductCategory, { data: newCategoryAdded }] = useMutation(AddNewProductCategory);

  const handleAddCategory = (category: any) => {
    handleAddNewProductCategory({ variables: { name: category } });
  };

  //after creating a new category to the list we reftech the new list
  useEffect(() => {
    if (newCategoryAdded) {
      refetchCategories();
    }
  }, [newCategoryAdded]);

  if (createable) {
    return (
      <CreatableSelect
        styles={{ container: (provided, state) => ({ ...provided }), valueContainer: (provided, state) => ({ ...provided, height: 48, overflowY: 'scroll' }) }}
        options={categoriesOptions}
        isMulti={isMulti}
        isLoading={loading}
        isDisabled={loading}
        theme={selectTheme}
        onCreateOption={handleAddCategory}
        value={value}
        onChange={onChange}
      />
    );
  }
  return <Select options={categoriesOptions} menuPosition="fixed" isMulti={isMulti} isLoading={loading} isDisabled={loading} theme={selectTheme} value={value} onChange={onChange} />;
};

export const LoaderContainer = styled.div`
  height: calc(100vh - 300px);
  display: grid;
  place-items: center;
`;
export const Loader = () => (
  <LoaderContainer>
    <ActivityIndicator size={30} />
  </LoaderContainer>
);
