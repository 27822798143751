import React, { useRef } from 'react';
import { Booking, PetRecordTag } from '../Bookings/components/BookingsBody/styled';
import { PetAndInfo, PetContainer, PetImg } from '../Bookings/components/BookingRecord/styled';
import { RecordBody } from '../styled';
import useIcons from '../../hooks/useIcons';
import { BranchRoute } from './types';

const RoutePetRecord = ({ petRecord, index, onClick }: { petRecord: BranchRoute['BranchRoutePetRecords'][0]; index: number; onClick?: () => void }) => {
  const icons = useIcons();
  const pet = petRecord?.PetRecord?.Pet;
  const user = pet?.AppUser;
  const routeJobType = petRecord?.RouteJobType;
  const optionsRef = useRef<HTMLDivElement>(null);
  const handleClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    if (optionsRef?.current?.contains(e.target as Node)) {
      return;
    }

    onClick?.();
  };
  const addressString = user.addresses
    .map(address => {
      const { line1 = '', line2 = '', city = '', country = '' } = address;

      return `${line1} ${line2 ? line2 + ', ' : ''}${city}, ${country}`;
    })
    .join(' | ');

  return (
    <>
      <Booking onClick={handleClick} flexDirection="column" flexStart backgroundColor="#f2f2f2" borderRadius="15px" padding="16px 6px">
        <PetContainer
          style={{
            alignItems: 'center'
          }}
        >
          <RecordBody width="40px" fontWeight="800">
            {index}
          </RecordBody>
          <PetImg src={pet?.profile_pic || icons.user.childImageSharp.gatsbyImageData.images.fallback.src} alt={pet?.name} />
          <PetAndInfo mobileFlexDirection="column">
            <RecordBody mobileWidth="100%" display="flex" flexDirection="column">
              <RecordBody fontWeight="800" color="#212121" mobileWidth="100%">
                {pet?.name},
              </RecordBody>
              <RecordBody width="200" noMargin>
                {addressString}
              </RecordBody>
            </RecordBody>
          </PetAndInfo>
        </PetContainer>
        <PetRecordTag color={'black'} style={{ marginLeft: '24px' }}>
          {routeJobType?.name}
        </PetRecordTag>
      </Booking>
    </>
  );
};

export default RoutePetRecord;
