const transformLumpsState = (formState: { [key: string]: any }) => {
  if (formState.skin_coat.lumps.status !== 'present') {
    return [];
  }
  return (formState.skin_coat.lumps.lumps || []).map(lump => ({
    details: lump.details,
    size: lump.size
  }));
};

export default transformLumpsState;
