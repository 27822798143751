import { useMutation } from '@apollo/client';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Colors from '../../../../Colors';
import Notifier from '../../../../Notifier';
import { AddRefund } from '../../../../queries';
import { ProductOrder } from '../../../../views/Orders/types';
import { OrderSubscriptionRepeat } from '../../../../views/Subscriptions/types';
import ModalDialog, { ModalLayout } from '../../../Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../../../Modal/styled';
import { FormButtonsContainer, FormInput, FormLabel, FormSelect, FormSubmitButton, FormTextArea, RadioBtnsGroup, WideInputGroup } from '../../Forms/Forms';
import { Container } from '../../Shared';

const RefundModal = ({
  order,
  repeat,
  shouldIssueRefund,
  onConfirm,
  onDontRefund
}: {
  order: ProductOrder;
  repeat?: OrderSubscriptionRepeat;
  shouldIssueRefund?: boolean;
  onConfirm?: (refund: { OrderId: string; amount: number | null; reason: string; note: string }) => void;
  onDontRefund?: () => void;
}) => {
  const [addRefund, { data: { addRefund: addedRefund } = {}, loading }] = useMutation(AddRefund);
  const { control, watch, handleSubmit, errors } = useForm();
  const isFullAmount = watch('fullAmount', true);

  const onSubmit = handleSubmit(async (data: any) => {
    const variables = {
      OrderId: order.id,
      OrderSubscriptionRepeatId: repeat?.id,
      amount: data.fullAmount ? null : Number(data.amount),
      reason: data.reason,
      note: data.note
    };
    if (!shouldIssueRefund) {
      onConfirm?.(variables);
      return ModalDialog.closeModal();
    }
    addRefund({ variables });
  });

  const handleDontRefund = () => {
    onDontRefund?.();
    return ModalDialog.closeModal();
  };

  useEffect(() => {
    if (addedRefund) {
      Notifier.info({ message: 'Refund is being processed' });
      ModalDialog.closeModal();
    }
  }, [addedRefund]);

  const leftToRefund = (repeat ?? order).total - (repeat ?? order).Refunds.reduce((acc, refund) => acc + Number(refund.amount), 0);

  return (
    <>
      <ModalLayout
        compact
        buttons={[
          <FormSubmitButton loading={loading} onClick={handleDontRefund} disabled={loading} danger>
            {'Dont Refund'}
          </FormSubmitButton>,
          <FormSubmitButton loading={loading} onClick={onSubmit} disabled={loading}>
            {loading ? 'Loading ...' : 'Refund'}
          </FormSubmitButton>
        ]}
      >
        <WideInputGroup>
          <FormLabel error={errors.reason}>Reason for Refund</FormLabel>
          <Controller
            render={({ onChange }) => (
              <FormSelect
                height={48}
                fontSize={16}
                name={'reason'}
                onChange={e => {
                  onChange(e.target.value);
                }}
                defaultValue={''}
                error={errors.reason}
              >
                <option disabled value={''}>
                  -- select a reason --
                </option>
                <option value={'REQUESTED_BY_CUSTOMER'}>Requested by customer</option>
                <option value={'DUPLICATE'}>Duplicate</option>
                <option value={'FRAUDULENT'}>Fraudulent</option>
              </FormSelect>
            )}
            control={control}
            name={'reason'}
            rules={{ required: true, validate: value => value !== '' }}
            defaultValue={''}
          />
        </WideInputGroup>
        <WideInputGroup>
          <Controller
            control={control}
            name="fullAmount"
            defaultValue={true}
            render={({ value, onChange }) => (
              <RadioBtnsGroup
                name="fullAmount"
                options={['Refund full amount']}
                defaultValue={value}
                inputType={'checkbox'}
                onChange={e => {
                  onChange(e.target.checked);
                }}
              />
            )}
          />
        </WideInputGroup>
        {!isFullAmount && (
          <WideInputGroup>
            <FormLabel error={errors.amount}>Refund Amount</FormLabel>
            <Controller
              control={control}
              name="amount"
              rules={{ required: true, min: 0.1, max: leftToRefund }}
              render={({ value, onChange }) => <FormInput type="number" name="amount" placeholder="Amount" min={0.1} max={leftToRefund} value={value} onChange={onChange} error={errors.amount} />}
            />
          </WideInputGroup>
        )}
        <WideInputGroup>
          <FormLabel>Note</FormLabel>
          <Controller
            control={control}
            name="note"
            defaultValue={''}
            rules={{ required: false }}
            render={({ value, onChange }) => <FormTextArea name="note" placeholder="Note" value={value} onChange={onChange} />}
          />
        </WideInputGroup>
      </ModalLayout>
    </>
  );
};

export default RefundModal;
