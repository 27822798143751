import { useQuery, useReactiveVar } from '@apollo/client';
import React from 'react';
import usePaginatedQuery from '../../../hooks/usePaginatedQuery';
import { GetBranchAppointmentsViews, GetBusUserProfile } from '../../../queries';
import { vars } from '../../../reactive';
import { BOOKING_RECORD_VIEW_SETTINGS, BOOKING_STATUS_TYPES, BOOKING_TABS_VALUES_TYPES, BOOKING_TYPE, Booking } from '../../Bookings/types';
import { CurrentPeriod } from './types';
import BookingsList, { BookingsListAppointmentsList } from '../../Bookings/components/BookingsList/BookingsList';
import { BusUserProfile } from '../../../components/Profile/types';

const BranchBillingBookings = ({ period, variables }: { period: CurrentPeriod; variables: Record<string, any> }) => {
  const activeViewSettings = useReactiveVar(vars.activeViewSettings);
  const { data: { getBusUserProfile: userProfile } = {} } = useQuery<{ getBusUserProfile: BusUserProfile }>(GetBusUserProfile, {
    fetchPolicy: 'cache-only'
  });
  const paginatedQueryResult = usePaginatedQuery<{ views: Booking[]; count: number }>({
    query: GetBranchAppointmentsViews,
    defaultStatus: [BOOKING_STATUS_TYPES.CONFIRMED],
    limit: 50,
    otherVariables: {
      timestamp_from: period.start,
      timestamp_to: period.end,
      booking_type: [BOOKING_TYPE.SLOT, BOOKING_TYPE.MULTI_SLOT, BOOKING_TYPE.MULTI_DAY],
      requisite_queries: [],
      alternative_queries: [],
      ...BOOKING_RECORD_VIEW_SETTINGS.map(settings => ({ [settings.id]: false })).reduce((acc, curr) => ({ ...acc, ...curr }), {}),
      ...(activeViewSettings?.record && !!activeViewSettings?.record.length ? [activeViewSettings?.record].flat()?.reduce((acc, record) => ({ ...acc, [record]: true }), {}) : {}),
      BranchId: userProfile?.Branch?.id,
      ...variables
    },
    otherParams: {
      skip: !userProfile?.Branch?.id
    }
  });

  const appointments = paginatedQueryResult[1]?.data?.getBranchAppointmentsViews?.views || [];

  return (
    <>
      <BookingsList
        paginatedQueryResult={paginatedQueryResult}
        appointmentsList={
          [
            {
              Appointments: appointments
            }
          ] as BookingsListAppointmentsList
        }
        selectedTab={{
          value: BOOKING_TABS_VALUES_TYPES.PAYMENT,
          recordOptions: {
            dispalyName: true,
            displayBreed: true,
            displayServiceName: true,
            displayBookingTime: true,
            dispalyTrailingItems: false,
            dispalyOptionalDropDown: true,
            displayOrderStatus: true,
            displayStaffName: true,
            displayPrice: true
          }
        }}
      />
    </>
  );
};

export default BranchBillingBookings;
