import { useQuery } from '@apollo/client';
import React, { useMemo } from 'react';
import { BusUserProfile } from '../../components/Profile/types';
import { MainContainer, ViewWrapper } from '../../components/Shared/Shared';
import SideTab from '../../components/Shared/SideTabs/SideTabs';
import { GetBusUserProfile } from '../../queries';
import { vars } from '../../reactive';
import { isUserSuperAdmin, isUserSuperVisorOrAdminOrSuper } from '../../utils/helpers';
import BranchBilling from './BranchBilling/BranchBilling';
import BranchFormModal from './BranchForms/BranchFormModal';
import BranchForms from './BranchForms/BranchForms';
import BranchMessageModal from './BranchMessages/BranchMessageModal';
import BranchMessages from './BranchMessages/BranchMessages';
import BranchPayouts from './BranchPayouts/BranchPayouts';
import BranchSummary from './BranchSummary/BranchSummary';
import BranchTags from './BranchTags/BranchTags';
import BranchTagsModal from './BranchTags/BranchTagsModal';
import BusUserModal from './BusUsers/BusUserModal';
import BusUsers from './BusUsers/BusUsers';
import Discounts from './Discounts/Discounts';
import DiscountsModal from './Discounts/DiscountsModal';
import ImportProductsModal from './Products/ImportProductsModal';
import Products from './Products/Products';
import Services from './Services/Services';
import Subscriptions from './StoreSubscriptions/StoreSubscriptions';
import { TABS_NAMES, TABS_TYPES } from './types';
import BranchSchedules from './BranchSchedules/BranchSchedules';
import StoreProductModal from './Operations/StoreProductModal/StoreProductModal';
import BranchPayoutDetails from './BranchPayouts/BranchPayoutDetails';
import DiscountDetails from './Discounts/DiscountDetails';
import BranchRooms from './BranchRooms/BranchRooms';
import ProviderBranches from './ProviderBranches/ProviderBranches';
import { StyledRouter } from '../../Routes';
import ProductModal from './Products/ProductModal';

const Store = () => {
  const { data: { getBusUserProfile: busUserProfile } = {}, loading } = useQuery<{ getBusUserProfile: BusUserProfile }>(GetBusUserProfile);

  const isAdmin = isUserSuperVisorOrAdminOrSuper(busUserProfile) && !loading;
  const isSuperAdmin = isUserSuperAdmin(busUserProfile) && !loading;
  const tabs = [
    {
      name: TABS_NAMES[TABS_TYPES.SERVICES],
      value: TABS_TYPES.SERVICES,
      component: () => <Services />,
      modals: [() => <StoreProductModal type="service" />]
    },
    {
      name: TABS_NAMES[TABS_TYPES.SCHEDULES],
      value: TABS_TYPES.SCHEDULES,
      component: () => <BranchSchedules />,
      modals: []
    },
    //divider
    {
      name: TABS_NAMES[TABS_TYPES.PRODUCTS],
      value: TABS_TYPES.PRODUCTS,
      component: () => <Products />,
      modals: [
        () => <StoreProductModal type="product" />,
        {
          title: 'Import Products',
          component: () => <ImportProductsModal />
        }
      ]
    },
    {
      name: TABS_NAMES[TABS_TYPES.SUBSCRIPTIONS],
      value: TABS_TYPES.SUBSCRIPTIONS,
      divider: true,
      component: () => <Subscriptions />,
      modals: [() => <StoreProductModal type="subscription" />]
    },
    //divider
    {
      name: TABS_NAMES[TABS_TYPES.BRANCH_TAGS],
      value: TABS_TYPES.BRANCH_TAGS,
      component: () => <BranchTags />,
      modals: [() => <BranchTagsModal />]
    },
    {
      name: TABS_NAMES[TABS_TYPES.FORMS],
      value: TABS_TYPES.FORMS,
      component: () => <BranchForms />,
      modals: [() => <BranchFormModal />]
    },
    {
      name: TABS_NAMES[TABS_TYPES.AUTO_MESSAGES],
      value: TABS_TYPES.AUTO_MESSAGES,
      component: () => <BranchMessages />,
      modals: [() => <BranchMessageModal />]
    },
    //divider
    isAdmin && {
      name: TABS_NAMES[TABS_TYPES.BILLING],
      value: TABS_TYPES.BILLING,
      component: () => <BranchBilling />
    },
    isSuperAdmin && {
      name: TABS_NAMES[TABS_TYPES.PAYOUTS],
      value: TABS_TYPES.PAYOUTS,
      component: () => <BranchPayouts />
    },
    {
      name: TABS_NAMES[TABS_TYPES.DISCOUNTS],
      value: TABS_TYPES.DISCOUNTS,
      component: () => <Discounts />,
      modals: [() => <DiscountsModal />]
    },
    //divider
    {
      name: TABS_NAMES[TABS_TYPES.REPORTS],
      value: TABS_TYPES.REPORTS,
      component: () => <BranchSummary />,
      modals: [],
      disabled: true
    },
    //divider
    isSuperAdmin && {
      name: TABS_NAMES[TABS_TYPES.BUS_USERS],
      value: TABS_TYPES.BUS_USERS,
      component: () => <BusUsers />,
      modals: [() => <BusUserModal />]
    },
    {
      name: TABS_NAMES[TABS_TYPES.BRANCH_ROOMS],
      value: TABS_TYPES.BRANCH_ROOMS,
      component: () => <BranchRooms />
    },
    isSuperAdmin && {
      name: TABS_NAMES[TABS_TYPES.PROVIDER_BRANCHES],
      value: TABS_TYPES.PROVIDER_BRANCHES,
      component: () => <ProviderBranches />
    }
  ].filter(Boolean) as { name: string; value: TABS_TYPES; component: () => JSX.Element; modals: { title: string; component: () => JSX.Element } | (() => JSX.Element)[] }[];

  const tabsToRender = useMemo(() => {
    const allTabs = Object.values(TABS_TYPES);

    if (isSuperAdmin) {
      return allTabs;
    }

    if (isAdmin) {
      return allTabs.filter(tab => tab !== TABS_TYPES.BUS_USERS && tab !== TABS_TYPES.BILLING && tab !== TABS_TYPES.PAYOUTS);
    }

    return allTabs.filter(tab => tab !== TABS_TYPES.BUS_USERS && tab !== TABS_TYPES.BILLING && tab !== TABS_TYPES.PAYOUTS);
  }, [isAdmin, isSuperAdmin]);

  const sections = [{ tabs: tabs.filter(tab => tabsToRender.includes(tab.value)) }];

  return (
    <ViewWrapper>
      <SideTab sections={sections} />
      <MainContainer>
        <StyledRouter basepath={'/app/operations'} style={{ height: '90%' }}>
          <Services path={`${TABS_TYPES.SERVICES}/*`} />
          <Subscriptions path={`${TABS_TYPES.SUBSCRIPTIONS}/*`} />
          <Products path={`${TABS_TYPES.PRODUCTS}/*`} />
          <BranchSchedules path={`${TABS_TYPES.SCHEDULES}/*`} />
          <BranchMessages path={`${TABS_TYPES.AUTO_MESSAGES}/*`} />
          <BranchTags path={`${TABS_TYPES.BRANCH_TAGS}/*`} />
          <BranchSummary path={`${TABS_TYPES.REPORTS}/*`} />
          {isAdmin && <BranchBilling path={`${TABS_TYPES.BILLING}/*`} />}
          <BranchForms path={`${TABS_TYPES.FORMS}/*`} />
          <Discounts path={`${TABS_TYPES.DISCOUNTS}/*`} />
          {isSuperAdmin && <BusUsers path={`${TABS_TYPES.BUS_USERS}/*`} />}
          {isSuperAdmin && <BranchPayouts path={`${TABS_TYPES.PAYOUTS}/*`} />}
          {isSuperAdmin && <BranchPayoutDetails path={`${TABS_TYPES.PAYOUTS}/:id`} />}
          <DiscountDetails path={`${TABS_TYPES.DISCOUNTS}/:id`} />
          <BranchRooms path={`${TABS_TYPES.BRANCH_ROOMS}/*`} />
          {isSuperAdmin && <ProviderBranches path={`${TABS_TYPES.PROVIDER_BRANCHES}/*`} />}
        </StyledRouter>
      </MainContainer>
    </ViewWrapper>
  );
};

export default React.memo(Store);
