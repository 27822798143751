import { Controller, useForm, UseFormMethods } from 'react-hook-form';
import { BusUserProfile } from '../Profile/types';
import React, { FC, useEffect, useRef } from 'react';
import ModalDialog, { ModalLayout } from '../Modal/ModalDialog';
import { FormInput, FormLabel, FormSubmitButton, InputGroup } from '../Shared/Forms/Forms';
import Select from '../Shared/Forms/Select';
import { AddBranchChatRoom, EditBranchChatRoom, GetAllBranchBusUsers, GetBusUserProfile } from '../../queries';
import { useMutation, useQuery } from '@apollo/client';
import { Room } from './ChatRecord';
import AppUsersList from '../Shared/Lists/AppUsersList';
import usePhotoUpload, { useDeletePhotos } from '../../hooks/usePhotoUpload';
import BinaryUpload, { BinaryUploadProps, BinaryUploadRef } from '../../views/Pets/Reports/BinaryUpload';
import { BranchAppUser } from '../../views/Store/BranchBilling/types';
import { setDrawerBar } from '../../reactive/actions';
import { DRAWER_IDS } from '../DrawerBar/types';

type BranchChatRoomModalForm = {
  name: string;
  appUserId: string[];
  busUserId: string[];
};

interface BranchChatRoomModalFormProps {
  busUsers: BusUserProfile[];
  formOptions: UseFormMethods<BranchChatRoomModalForm>;
  loading: boolean;
  onSubmit: () => void;
  defaultValues?: Partial<BranchChatRoomModalForm>;
  isBusRoom: boolean;
  binaryUploadProps: BinaryUploadProps;
}

const BranchChatRoomModalForm: FC<BranchChatRoomModalFormProps> = ({ busUsers, formOptions, loading, onSubmit, defaultValues, isBusRoom, binaryUploadProps }) => {
  const { control } = formOptions;

  const busUsersOptions = busUsers.map(busUser => ({
    value: busUser.id,
    label: busUser.name
  }));

  return (
    <ModalLayout
      compact
      buttons={[
        <FormSubmitButton loading={loading} onClick={onSubmit}>
          Save
        </FormSubmitButton>
      ]}
    >
      {isBusRoom && (
        <InputGroup>
          <FormLabel>Name</FormLabel>
          <Controller render={({ onChange, value }) => <FormInput onChange={onChange} value={value} placeholder="Name" />} control={control} name={'name'} defaultValue={defaultValues?.name || ''} />
        </InputGroup>
      )}
      <InputGroup>
        <FormLabel>Staff Members (Other than you)</FormLabel>
        <Controller
          render={({ onChange, value }) => (
            <Select
              isMulti
              options={busUsersOptions}
              value={value ? busUsersOptions.filter(option => value.includes(option.value)) : [busUsersOptions[0]]}
              onChange={values => {
                const newValues = values.map(option => option.value);
                onChange(newValues);
              }}
            />
          )}
          control={control}
          name={'busUserId'}
          rules={{
            required: isBusRoom
          }}
          defaultValue={defaultValues?.busUserId || []}
        />
      </InputGroup>
      {!isBusRoom && (
        <InputGroup>
          <FormLabel>Clients</FormLabel>
          <AppUsersList
            formOptions={formOptions}
            name={'appUserId'}
            isMulti
            defaultValues={defaultValues?.appUserId || []}
            rules={{
              required: true
            }}
          />
        </InputGroup>
      )}
      {isBusRoom && (
        <InputGroup maxWidth="450px">
          <BinaryUpload {...binaryUploadProps} ref={binaryUploadProps.ref} />
        </InputGroup>
      )}
    </ModalLayout>
  );
};

export const BranchChatRoomModal = ({ chatRoom, isBusRoom }: { chatRoom?: Room; isBusRoom: boolean }) => {
  const { data: { getBusUserProfile: profile = {} } = {} } = useQuery(GetBusUserProfile, {
    fetchPolicy: 'cache-only'
  });
  const photosRef = useRef<BinaryUploadRef>(null);
  const uploadPhotos = usePhotoUpload();
  const [_, { loading: loadingUploadPhotos, error: errorUploadPhotos }] = uploadPhotos;
  const deletePhotos = useDeletePhotos();

  const defaultValues: BranchChatRoomModalForm = {
    appUserId: (chatRoom?.AppUsers || [])?.map(appUser => appUser.id),
    name: chatRoom?.name || '',
    busUserId: (chatRoom?.BusUsers || [])?.filter(busUser => profile?.id !== busUser.id).map(busUser => busUser.id)
  };

  const formOptions = useForm<BranchChatRoomModalForm>({ defaultValues });

  const { handleSubmit } = formOptions;

  const { data: { getBranchBusUsers: BusUsers = [] } = {} } = useQuery<{ getBranchBusUsers: BusUserProfile[] }>(GetAllBranchBusUsers);

  const filteredBusUsers = BusUsers.filter(busUser => profile?.id !== busUser.id);

  const [addBranchChatRoom, { loading: loadingAdd }] = useMutation(AddBranchChatRoom, {
    refetchQueries: ['getBranchChatRoomsViews'],
    awaitRefetchQueries: true
  });

  const [editBranchChatRoom, { loading: loadingEdit }] = useMutation(EditBranchChatRoom, {
    refetchQueries: ['getBranchChatRoomsViews'],
    awaitRefetchQueries: true
  });

  const onSubmit = handleSubmit(async ({ busUserId, appUserId, name }) => {
    const variables = {
      name,
      AppUserId: appUserId || [],
      BusUserId: busUserId || []
    };

    let newChatRoomId = chatRoom?.id;

    if (!chatRoom) {
      const { data } = await addBranchChatRoom({
        variables: {
          ...variables,
          is_bus_room: isBusRoom
        }
      });
      newChatRoomId = data?.addBranchChatRoom?.id;
    }

    const photos = await photosRef.current?.getPhotos(`providers/${profile?.Provider.id}/branches/${profile?.Branch.id}/chatRooms/${newChatRoomId}`);

    const shouldEdit = (chatRoom && chatRoom.id) || photos?.length || (chatRoom?.cover_photo && !photos?.length);

    if (shouldEdit) {
      await editBranchChatRoom({
        variables: {
          ...variables,
          id: newChatRoomId,
          cover_photo: photos?.[0] || null
        }
      });
    }

    ModalDialog.closeModal();
    setDrawerBar({
      drawerId: DRAWER_IDS.CHAT_DRAWER,
      recordData: newChatRoomId
    });
  });

  const binaryUploadProps: BinaryUploadProps = {
    deletePhotos,
    uploadPhotos,
    ref: photosRef,
    defaultBinaries: {
      photos: chatRoom?.cover_photo ? [chatRoom.cover_photo] : [],
      attachments: []
    },
    maxNumberOfPhotos: 1,
    options: {
      enableAttachments: false
    }
  };

  const loading = loadingAdd || loadingEdit || loadingUploadPhotos;

  return (
    <BranchChatRoomModalForm
      busUsers={filteredBusUsers}
      formOptions={formOptions}
      loading={loading}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      binaryUploadProps={binaryUploadProps}
      isBusRoom={isBusRoom}
    />
  );
};

export default BranchChatRoomModal;
