import styled, { css } from 'styled-components';
import Colors from '../../../Colors';

export const ToolsBarContainer = styled.div<{ padding?: string; mobilePadding?: string; noMobileBoxShadow?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ padding }) => padding || '0 20px'};
  gap: 10px;
  min-height: 64px;
  @media (max-width: 768px) {
    padding: ${({ mobilePadding }) => mobilePadding || '0 16px'};
    justify-content: flex-start;
    padding: 0 0 0 16px;
    box-shadow: ${({ noMobileBoxShadow }) => (noMobileBoxShadow ? 'none' : '0px 10px 16px 0px rgba(0, 0, 0, 0.05)')};
    z-index: ${({ noMobileBoxShadow }) => (noMobileBoxShadow ? 0 : 2)};
    border-bottom: 1px solid #efefef;
  }
`;

export const ServiceButton = styled.button<{ isSelected: boolean }>`
  background-color: ${Colors.toolsBarBackgroundColor};
  border-radius: 8px;
  padding: 6px 10px 7px 10px;
  text-decoration: none;
  color: ${Colors.black};
  font-size: 12px;
  text-align: center;
  font-weight: 700;

  ${({ isSelected }) =>
    isSelected &&
    css`
      cursor: pointer;
      color: ${Colors.white};
      background-color: ${Colors.toolsBackgroundColorHover};
    `}
  ${({ isSelected }) =>
    !isSelected &&
    css`
      &:hover {
        cursor: pointer;
        background-color: ${Colors.sideTabBackgroundColor};
      }
    `}
  @media (max-width: 768px) {
    padding: 7px 11px 9px 11px;
  }
`;

export const VerticalDivider = styled.div<{ displayNone?: boolean; marginLeft?: string; height?: string; marginBottom?: string; marginRight?: string }>`
  min-width: 1px;
  max-width: 1px;
  width: 1px;
  height: ${({ height }) => height || '100%'};
  margin-left: ${({ marginLeft }) => marginLeft || '0px'};
  margin-bottom: ${({ marginBottom }) => marginBottom || '0'};
  margin-right: ${({ marginRight }) => marginRight || '0'};
  background-color: ${Colors.divider};
  @media (max-width: 800px) {
    display: ${props => props.displayNone && 'none'};
  }
`;

export const ToolsContainer = styled.div`
  display: flex;
  gap: 6px;
  height: 30px;
  z-index: 100;
  position: relative;
`;
export const BtnContainer = styled.div`
  display: flex;
  white-space: nowrap;
  gap: 10px;
  height: 30px;
  cursor: pointer;
`;

export const ButtonsContainer = styled.div<{ isVisible: boolean }>`
  display: flex;
  gap: 10px;
  height: 100%;
  align-items: center;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 768px) {
    padding: 0 16px 0 0;
  }
`;

export const ToolBarString = styled.div`
  display: flex;
  gap: 10px;
  font-weight: 800;
  color: ${Colors.black};
  font-size: 18px;
`;

export const ToolBarFooter = styled.div`
  background-color: ${Colors.white};
  border-top: 1px solid ${Colors.divider};
  padding: 24px 20px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;
