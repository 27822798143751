import config from '../config';
import { replaceVariables } from '../helpers';
import { DynamicLink, dynamicLinksHandlers, DYNAMIC_LINKS_TYPES } from './types';

export function buildLink(link: string) {
  const url = `${config.DYNAMIC_LINK_DOMAIN_PREFIX}?link=${encodeURIComponent(config.WEBSITE_PREFIX + link)}`;
  return url;
}

export const getLinkType = (dynamicLink: string) => {
  const linkType = dynamicLinksHandlers.find(({ link }) => dynamicLink.includes(link));
  return linkType;
};

export const getDeepLinkType = (dynamicLink: string, linkType: DYNAMIC_LINKS_TYPES) => {
  const deepLinkType = dynamicLinksHandlers.find(({ type }) => type === linkType)?.deepLinkTypes.find(({ link }) => dynamicLink.includes(link));

  return deepLinkType;
};

export const checkIfLinkIsDynamic = (link: string) => {
  const linkType = getLinkType(link);
  return link.includes(config.DYNAMIC_LINK_DOMAIN_PREFIX.split('://')[1]) && !!linkType?.type;
};

export const getDynamicLink = (dynamicLink: string | null) => {
  if (!dynamicLink) {
    return;
  }
  const linkType = getLinkType(dynamicLink);

  if (!linkType) {
    return;
  }

  const deepLinkType = getDeepLinkType(dynamicLink, linkType.type);

  return deepLinkType || linkType;
};

export const getDynamicLinkTitle = (dynamicLink: string, linkData: { replaceVariables: Record<string, string> }) => {
  const dynamicLinkType = getDynamicLink(dynamicLink);

  return replaceVariables(dynamicLinkType!.title, linkData?.replaceVariables);
};

export async function handleDynamicLink(dynamicLink: string) {
  const dynamicLinkType = getDynamicLink(dynamicLink);

  return dynamicLinkType!.handler(dynamicLink);
}

export const destructDynamicLink = (link: string) => {
  const linkType = getLinkType(link)!;
  const deepLinkType = linkType ? getDeepLinkType(link, linkType?.type) : null;
  const linkItems = getDynamicLink(link)!;
  return {
    link,
    title: '',
    linkType: linkType.type,
    deepLinkType: deepLinkType?.type || null,
    linkItems,
    data: {},
    isDynamic: true,
    isDeepLink: !!deepLinkType,
    handleClick: () => {},
    handleClose: () => {},
    loadModal: false,
    externalLink: link,
    isEmail: false
  };
};

export const getDynamicLinkData = async (dynamicLink: DynamicLink) => {
  try {
    const { link } = dynamicLink;
    const dynamicLinkData = await handleDynamicLink(decodeURIComponent(link));
    const title = getDynamicLinkTitle(link, dynamicLinkData);
    return { ...dynamicLink, data: dynamicLinkData, title };
  } catch (e) {
    console.log('Dynamic Link Parse Error: ' + dynamicLink);
    return dynamicLink;
  }
};
