import { useMemo } from 'react';
import { BookingOrder } from '../views/Bookings/types';

type ReduceAppointmentsInputType = (
  bookings: BookingOrder,
  options?: {
    uniqueByOrderIdAndTimestamp?: boolean;
    sortDesc?: boolean;
    sort?: boolean;
  }
) => BookingOrder[];

const useReduceAppointments: ReduceAppointmentsInputType = (
  bookings,
  options = {
    uniqueByOrderIdAndTimestamp: false,
    sortDesc: false
  }
) => {
  return useMemo(() => {
    return reduceAppointments(bookings, options);
  }, [bookings]);
};

export const reduceAppointments: ReduceAppointmentsInputType = (
  bookings,
  options = {
    uniqueByOrderIdAndTimestamp: false,
    sortDesc: false
  }
) => {
  const { uniqueByOrderIdAndTimestamp } = options;

  const currentAppointmentsReduced =
    bookings?.reduce((allAppointments: Record<string, BookingOrder>, appointment) => {
      let id = `${appointment.OrderItem?.Order?.id}`;

      if (uniqueByOrderIdAndTimestamp) {
        id += `_${appointment.timestamp}`;
      }

      if (appointment.timestamp_until) {
        id += `_${appointment.timestamp_until}`;
      }

      return {
        ...allAppointments,
        [id]: [...(allAppointments[id] || []), appointment]
      };
    }, {}) || {};
  const currentAppointments = Object.keys(currentAppointmentsReduced)
    .map(id => currentAppointmentsReduced[id])
    .slice();

  if (options?.sort) {
    const sortedAppointments = currentAppointments.sort((a, b) => (new Date(a[0]?.timestamp) > new Date(b[0]?.timestamp) ? 1 : -1)) as BookingOrder[];
    return options.sortDesc ? sortedAppointments.reverse() : sortedAppointments;
  }
  return currentAppointments;
};

export default useReduceAppointments;
