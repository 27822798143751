import React from 'react';
import ModalDialog from '../../../components/Modal/ModalDialog';
import { Divider } from '../../../components/Shared/SideTabs/styles';
import { HoverRecordOptionsContainer, RecordBody, RecordDataContainer } from '../../styled';
import BranchScheduleModal from './BranchScheduleModal/BranchScheduleModal';
import { typesOptions } from './BranchScheduleModal/types';
import { BranchSchedule } from './types';
import { Booking } from '../../Bookings/components/BookingsBody/styled';
import { BtnContainer } from '../../../components/Shared/ToolBar/styles';
import { GatsbyImage } from 'gatsby-plugin-image';
import useIcons from '../../../hooks/useIcons';

const BranchScheduleRecord = ({ branchSchedule }: { branchSchedule: BranchSchedule }) => {
  const icons = useIcons();
  const handleClick = () => {
    ModalDialog.openModal({
      content: () => <BranchScheduleModal branchSchedule={branchSchedule} />,
      title: 'Edit Branch Schedule'
    });
  };

  return (
    <>
      <Booking padding="6px 10px" gap={10}>
        <RecordBody fontWeight={'800'}>{branchSchedule?.name}</RecordBody>
        <RecordBody flex="unset" width="150" fontWeight="600" flexEnd>
          {typesOptions.find(({ id }) => id === branchSchedule?.booking_type)?.name}
        </RecordBody>
        {/* <RecordBody>{branchSchedule?.slots?.length} Slots</RecordBody> */}
        <HoverRecordOptionsContainer>
          <BtnContainer onClick={handleClick}>
            <GatsbyImage image={icons?.moreIcon?.childImageSharp?.gatsbyImageData} alt="more" />
          </BtnContainer>
        </HoverRecordOptionsContainer>
      </Booking>
      <Divider />
    </>
  );
};

export default BranchScheduleRecord;
