import { GetPetRecordReports } from '../../queries';
import client from '../client';
import { DynamicLink } from './types';

export async function petsDataHandler() {
  return new Promise(resolve => resolve);
}

export async function petsReportDataHandler(clickedLink: DynamicLink): Promise<DynamicLink> {
  const { data: { reportId = '' } = {} } = clickedLink || {};
  if (!reportId) {
    return clickedLink;
  }

  const { data: { getPetRecordReports: { MedConds = [], GroomingReports = [], TrainingReports = [], WalkingReports = [], SittingReports = [], DaycareReports = [] } = {} } = {} } = await client.query({
    query: GetPetRecordReports,
    variables: {
      id: [reportId]
    }
  });

  const medCondReport = !!MedConds?.length;
  const groomingReport = !!GroomingReports?.length;
  const trainingReport = !!TrainingReports?.length;
  const walkingReport = !!WalkingReports?.length;
  const sittingReport = !!SittingReports?.length;
  const daycareReport = !!DaycareReports?.length;

  const getSelectedReport = () => {
    if (medCondReport) return MedConds?.[0];
    if (groomingReport) return GroomingReports?.[0];
    if (trainingReport) return TrainingReports?.[0];
    if (walkingReport) return WalkingReports?.[0];
    if (sittingReport) return SittingReports?.[0];
    if (daycareReport) return DaycareReports?.[0];
    return null;
  };

  const selectedReport = getSelectedReport();

  return { ...clickedLink, data: { ...clickedLink.data, selectedReport, medCondReport, groomingReport, trainingReport, walkingReport, sittingReport, daycareReport }, loadModal: false };
}
