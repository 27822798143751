import React, { forwardRef, useImperativeHandle } from 'react';
import { Controller, useForm } from 'react-hook-form';
import CreatableSelect from 'react-select/creatable';
import { ModalBody, ModalFooter } from '../../../components/Modal/styled';
import { FormButtonsContainer, FormLabel, FormSubmitButton, WideInputGroup, selectTheme } from '../../../components/Shared/Forms/Forms';
import { Container } from '../../../components/Shared/Shared';
import { ActivityIndicator } from '../../../components/Shared/Spinner';
import useGetBranchTagsByType from '../../../hooks/useGetBranchTagsByType';
import { LoaderContainer } from '../styled';
import { BRANCH_TAGS_TYPES, BranchTag } from './types';
import Select from '../../../components/Shared/Forms/Select';
import { ModalLayout } from '../../../components/Modal/ModalDialog';

type BranchTagItemModalProps = {
  existingTagsItems: BranchTag[];
  formOptions?: ReturnType<typeof useForm>;
  onSubmit: (data: { selectedTags: { value: string; label: string }[] }) => void;
  loading: boolean;
  type: BRANCH_TAGS_TYPES;
  handleAddBranchTag?: (tags: string) => void;
  isCreatable?: boolean;
};

const BranchTagItemModal = forwardRef<{ tags: BranchTag[] }, BranchTagItemModalProps>(({ formOptions, onSubmit, loading, existingTagsItems, handleAddBranchTag, type, isCreatable = true }, ref) => {
  const { branchTags: allTags, loading: loadingAllTags } = useGetBranchTagsByType({ type, variables: { offset: 0, limit: 1000 } });

  useImperativeHandle(ref, () => ({
    tags: allTags
  }));

  const { control, handleSubmit } = formOptions || useForm();
  const allTagsOptions = allTags?.map(tag => ({ value: tag.id, label: tag.name }));
  const existingTags = allTagsOptions?.filter(tag => existingTagsItems?.findIndex(item => item.id === tag.value) !== -1);

  const SelectComponent = isCreatable ? CreatableSelect : Select;

  const submitHandler = handleSubmit(onSubmit);

  return (
    <ModalLayout
      isMini
      compact
      buttons={[
        <FormSubmitButton loading={loading} onClick={submitHandler}>
          Save
        </FormSubmitButton>
      ]}
    >
      <WideInputGroup>
        <FormLabel>Active Tags</FormLabel>
        <Controller
          name="selectedTags"
          control={control}
          defaultValue={existingTags}
          as={
            <SelectComponent
              defaultValue={existingTags}
              styles={{ container: provided => ({ ...provided }), valueContainer: provided => ({ ...provided, height: 80, overflowY: 'scroll' }) }}
              options={allTagsOptions}
              isMulti
              theme={selectTheme}
              name={'selectedTags'}
              onCreateOption={handleAddBranchTag}
            />
          }
        />
      </WideInputGroup>
    </ModalLayout>
  );
});

export default BranchTagItemModal;
