import { useLazyQuery } from '@apollo/client';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useEffect, useState } from 'react';
import ModalDialog from '../../../components/Modal/ModalDialog';
import { ActivityIndicator } from '../../../components/Shared/Spinner';
import useIcons from '../../../hooks/useIcons';
import { GetBranchSummaryCounts } from '../../../queries';
import { vars } from '../../../reactive';
import debounce from '../../../utils/debounce';
import { getKeys } from '../../../utils/helpers';
import { RecordsContainer } from '../../styled';
import { AddNewBtn, AddNewBtnIcon } from '../Products/styled';
import AddSummaryModal from './AddSummaryModal';
import BranchSummaryCount from './BranchSummaryCount';
import { BranchSummaryAddContainer, BranchSummaryCountContainer, BranchSummaryCountHeaderOptions, BranchSummaryCountMode, BranchSummaryCountsContainer, BranchSummaryLoaderContainer } from './styled';
import { ALLOWED_COUNTS_NAMES, BranchSummaryFormValues, allowedCountsItems } from './types';

const BranchSummary = () => {
  const icons = useIcons();
  const [getBranchSummaryCounts, { data: { getBranchSummaryCounts: { counts = [] } = {} } = {}, loading: loadingCounts }] = useLazyQuery<{
    getBranchSummaryCounts: { counts: { data: Record<string, any>; name: keyof typeof ALLOWED_COUNTS_NAMES }[] };
  }>(GetBranchSummaryCounts, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true
  });

  const branchSummaryCounts = vars.branchSummaryCounts();

  const cleanedDefaultValue =
    branchSummaryCounts?.counts?.reduce((acc, count) => {
      const isAllowed = ALLOWED_COUNTS_NAMES[count.name];
      const allowedFilters = allowedCountsItems[count.name].allows;
      const appliedFilters = getKeys(count.appliedFilters);
      const isAppliedFiltersValid = appliedFilters.every(filter => allowedFilters.find(allowedFilter => allowedFilter.type === filter));
      if (!isAllowed || !isAppliedFiltersValid) {
        return acc;
      }
      return [...acc, count];
    }, [] as BranchSummaryFormValues['selectedCounts']) || [];

  const [selectedCounts, setSelectedCounts] = useState<BranchSummaryFormValues['selectedCounts']>(cleanedDefaultValue);
  const [isExpanded, setIsExpanded] = useState(branchSummaryCounts?.isExpanded || false);
  const [modes, setModes] = useState<
    {
      index: number;
      mode: 'table' | 'chart';
    }[]
  >(branchSummaryCounts?.modes || []);

  const getCounts = ({ counts }: { counts: BranchSummaryFormValues['selectedCounts'] }) => {
    debounce(() => {
      const updatedCounts = counts.map(count => {
        const allowedFilters = allowedCountsItems[count.name].allows;
        const appliedFiltersKeys = getKeys(count.appliedFilters);
        const appliedFilters = appliedFiltersKeys.reduce((acc, key) => {
          const filterItems = allowedFilters.find(allowedFilter => allowedFilter.type === key);
          if (!filterItems || (Array.isArray(count.appliedFilters[key]) && !(count.appliedFilters[key] as string[] | number[])?.length)) {
            return acc;
          }

          if (key === 'dateRange') {
            const value = count.appliedFilters[key] as string;
            let output = {};
            if (value === 'custom') {
              return acc;
            }
            if (value === 'yearToDate') {
              const timestamp_from = new Date();
              timestamp_from.setMonth(0);
              timestamp_from.setUTCDate(1);
              output = { timestamp_from: timestamp_from.toISOString(), timestamp_to: new Date().toISOString() };
            } else if (value === 'monthToDate') {
              const timestamp_from = new Date();
              timestamp_from.setUTCDate(1);
              output = { timestamp_from: timestamp_from.toISOString(), timestamp_to: new Date().toISOString() };
            } else if (value === 'quarterToDate') {
              const today = new Date();
              const quarter = Math.floor((today.getMonth() + 3) / 3);
              const timestamp_from = new Date(today.getFullYear(), 3 * quarter - 3, 1);
              output = { timestamp_from: timestamp_from.toISOString(), timestamp_to: new Date().toISOString() };
            }
            return { ...acc, ...output };
          }

          const filterName = filterItems?.as || key;
          const multipleFiltersWithSameAs = acc?.[filterName]?.length;
          return { ...acc, [filterName]: multipleFiltersWithSameAs ? [...(acc?.[filterName] || []), ...(count.appliedFilters[key] as string[])] : count.appliedFilters[key] };
        }, {} as Record<string, any>);

        if (appliedFilters?.timestamp_from) {
          appliedFilters.timestamp_from = new Date(new Date(appliedFilters.timestamp_from).setUTCHours(0, 0, 0, 0)).toISOString();
        }
        if (appliedFilters?.timestamp_to) {
          appliedFilters.timestamp_to = new Date(new Date(appliedFilters.timestamp_to).setUTCHours(23, 59, 59, 999)).toISOString();
        }
        return {
          name: count.name,
          data: { ...appliedFilters }
        };
      });
      if (!updatedCounts.length) {
        return;
      }
      getBranchSummaryCounts({
        variables: {
          counts: updatedCounts
        }
      });
    }, 1000);
  };

  useEffect(() => {
    getCounts({ counts: selectedCounts });
  }, []);

  const onSubmit = (values: BranchSummaryFormValues['selectedCounts']) => {
    vars.branchSummaryCounts({ ...(vars.branchSummaryCounts() || {}), counts: values });
    setSelectedCounts(values);
    getCounts({ counts: values });
  };

  const onExpand = () => {
    vars.branchSummaryCounts({ ...(vars.branchSummaryCounts() || {}), isExpanded: !isExpanded });
    setIsExpanded(!isExpanded);
  };

  const toggleMode = (index: number, newMode?: 'table' | 'chart') => {
    const mode = newMode || (modes[index]?.mode === 'table' ? 'chart' : 'table');
    const updatedModes = [...modes];
    updatedModes[index] = { index, mode };
    vars.branchSummaryCounts({ ...(vars.branchSummaryCounts() || {}), modes: updatedModes });
    setModes(updatedModes);
  };

  const editRow = ({ id }: { id: string }) => {
    ModalDialog.openModal({
      content: () => <AddSummaryModal selectedCounts={selectedCounts} id={id} onSubmit={onSubmit} />,
      title: 'Report'
    });
  };

  const addRow = () => {
    ModalDialog.openModal({
      content: () => <AddSummaryModal selectedCounts={selectedCounts} onSubmit={onSubmit} />,
      title: 'Report'
    });
  };

  return (
    <RecordsContainer>
      <BranchSummaryCountHeaderOptions>
        <BranchSummaryCountMode onClick={onExpand}>
          <GatsbyImage image={getImage(icons.expandChart.childImageSharp.gatsbyImageData)!} alt="expand" />
        </BranchSummaryCountMode>
      </BranchSummaryCountHeaderOptions>
      {loadingCounts && (
        <BranchSummaryLoaderContainer>
          <ActivityIndicator size={20} />
          <span>Updating Counts</span>
        </BranchSummaryLoaderContainer>
      )}
      <BranchSummaryCountsContainer isExpanded={isExpanded}>
        {selectedCounts.map((field, index) => {
          const fieldCount = counts[index] || {};
          const countOptions = allowedCountsItems[field.name];
          const mode = modes[index]?.mode || 'table';
          return (
            <BranchSummaryCountContainer isExpanded={isExpanded} key={field.id}>
              <BranchSummaryCount count={fieldCount} appliedFilters={field.appliedFilters} mode={mode} isExpanded={isExpanded}>
                <BranchSummaryCountHeaderOptions>
                  {countOptions.availableModes.includes('table') && (
                    <BranchSummaryCountMode onClick={() => toggleMode(index, 'table')} isActive={mode === 'table'}>
                      <GatsbyImage image={getImage(icons.tableChart.childImageSharp.gatsbyImageData)!} alt="table" />
                    </BranchSummaryCountMode>
                  )}
                  {countOptions.availableModes.includes('chart') && (
                    <BranchSummaryCountMode onClick={() => toggleMode(index, 'chart')} isActive={mode === 'chart'}>
                      <GatsbyImage image={getImage(icons.chart.childImageSharp.gatsbyImageData)!} alt="chart" />
                    </BranchSummaryCountMode>
                  )}
                  <BranchSummaryCountMode isActive={false} onClick={() => editRow({ id: field.id })}>
                    <GatsbyImage image={getImage(icons.editChart.childImageSharp.gatsbyImageData)!} alt="edit" />
                  </BranchSummaryCountMode>
                </BranchSummaryCountHeaderOptions>
              </BranchSummaryCount>
            </BranchSummaryCountContainer>
          );
        })}
        <BranchSummaryCountContainer isNew isExpanded={isExpanded} onClick={() => addRow()}>
          <BranchSummaryAddContainer>
            <AddNewBtn
              onClick={() => {
                addRow();
              }}
              noSpaceArround
              center
            >
              <AddNewBtnIcon src={icons.addPhoto.childImageSharp.gatsbyImageData.images.fallback.src} />
              Add
            </AddNewBtn>
          </BranchSummaryAddContainer>
        </BranchSummaryCountContainer>
      </BranchSummaryCountsContainer>
    </RecordsContainer>
  );
};

export default BranchSummary;
