import { SelectedFilter } from '../../../components/Shared/Filters/config';
import { getFilter, getMoreOptionsValues } from '../../../components/Shared/Filters/helpers';
import useFilters from '../../../components/Shared/Filters/useFilters';
import { OPTION_DROPDOWN_TYPES } from '../../../components/Shared/Menus/OptionDropdown/types';

enum BRANCH_APP_USERS_FILTER_TYPES {
  BRANCH_APP_USERS_SEARCH = 'BRANCH_APP_USERS_SEARCH'
}

const HIDDEN_BRANCH_APP_USERS_FILTERS = {
  [BRANCH_APP_USERS_FILTER_TYPES.BRANCH_APP_USERS_SEARCH]: true
};

const BRANCH_APP_USERS_FILTERS_QUERIES = {
  [BRANCH_APP_USERS_FILTER_TYPES.BRANCH_APP_USERS_SEARCH]: ['name']
} as const;

enum BRANCH_APP_USERS_MORE_OPTIONS_TYPES {
  BRANCH_APP_USERS_SEARCH = 'BRANCH_APP_USERS_SEARCH'
}

const BRANCH_APP_USERS_FILTER_TYPES_NAMES: Record<BRANCH_APP_USERS_FILTER_TYPES, string> = {
  [BRANCH_APP_USERS_FILTER_TYPES.BRANCH_APP_USERS_SEARCH]: 'Search'
};

const BRANCH_APP_USERS_MORE_OPTIONS_TITLES: Record<BRANCH_APP_USERS_MORE_OPTIONS_TYPES, string> = {
  [BRANCH_APP_USERS_MORE_OPTIONS_TYPES.BRANCH_APP_USERS_SEARCH]: ''
};

const BRANCH_APP_USERS_MORE_OPTIONS_DROPDOWN_TYPES: Record<BRANCH_APP_USERS_MORE_OPTIONS_TYPES, OPTION_DROPDOWN_TYPES> = {
  [BRANCH_APP_USERS_MORE_OPTIONS_TYPES.BRANCH_APP_USERS_SEARCH]: OPTION_DROPDOWN_TYPES.TEXT_INPUT
};

const BRANCH_APP_USERS_FILTER_TYPES_UNITS: Record<BRANCH_APP_USERS_FILTER_TYPES, string> = {
  [BRANCH_APP_USERS_FILTER_TYPES.BRANCH_APP_USERS_SEARCH]: ''
};
const BRANCH_APP_USERS_FILTER_TYPES_MORE_OPTIONS: Record<BRANCH_APP_USERS_FILTER_TYPES, BRANCH_APP_USERS_MORE_OPTIONS_TYPES[]> = {
  [BRANCH_APP_USERS_FILTER_TYPES.BRANCH_APP_USERS_SEARCH]: [BRANCH_APP_USERS_MORE_OPTIONS_TYPES.BRANCH_APP_USERS_SEARCH]
};

const BRANCH_APP_USERS_MORE_OPTIONS_ITEMS: Record<BRANCH_APP_USERS_MORE_OPTIONS_TYPES, { value: string | boolean; name: string }[] | []> = {
  [BRANCH_APP_USERS_MORE_OPTIONS_TYPES.BRANCH_APP_USERS_SEARCH]: []
};

const useBranchAppUsersFilters = () => {
  const filtersControl = useFilters<BRANCH_APP_USERS_FILTER_TYPES, BRANCH_APP_USERS_MORE_OPTIONS_TYPES>({
    toCall: [],
    getOptions: () => ({
      FILTER_TYPES: BRANCH_APP_USERS_FILTER_TYPES,
      FILTER_TYPES_MORE_OPTIONS: BRANCH_APP_USERS_FILTER_TYPES_MORE_OPTIONS,
      FILTER_TYPES_NAMES: BRANCH_APP_USERS_FILTER_TYPES_NAMES,
      FILTER_TYPES_UNITS: BRANCH_APP_USERS_FILTER_TYPES_UNITS,
      MORE_OPTIONS_DROPDOWN_TYPES: BRANCH_APP_USERS_MORE_OPTIONS_DROPDOWN_TYPES,
      MORE_OPTIONS_TITLES: BRANCH_APP_USERS_MORE_OPTIONS_TITLES,
      MORE_OPTIONS_TYPES: BRANCH_APP_USERS_MORE_OPTIONS_TYPES,
      MORE_OPTIONS_ITEMS: BRANCH_APP_USERS_MORE_OPTIONS_ITEMS
    })
  });

  return filtersControl;
};
const getBranchAppUsersFiltersVariables = ({
  defaultVariables,
  selectedFilters,
  overriddenFilters
}: {
  defaultVariables?: Record<string, any>;
  selectedFilters: SelectedFilter[];
  overriddenFilters?: Partial<Record<(typeof BRANCH_APP_USERS_FILTERS_QUERIES)[keyof typeof BRANCH_APP_USERS_FILTERS_QUERIES][number], any>>;
}) => {
  const getFilterValue = (filter: BRANCH_APP_USERS_FILTER_TYPES) => getFilter(selectedFilters, filter);

  // search
  const searchFilter = getFilterValue(BRANCH_APP_USERS_FILTER_TYPES.BRANCH_APP_USERS_SEARCH);
  const searchFilterValue = getMoreOptionsValues(searchFilter, BRANCH_APP_USERS_MORE_OPTIONS_TYPES.BRANCH_APP_USERS_SEARCH);

  const variables = {
    ...defaultVariables,
    name: searchFilterValue ? [searchFilterValue] : null,
    requisite_queries: Object.values(BRANCH_APP_USERS_FILTER_TYPES)
      .map(filter => {
        const overriddenFiltersValues = BRANCH_APP_USERS_FILTERS_QUERIES[filter]?.filter(query => overriddenFilters?.[query]);
        if (overriddenFiltersValues?.length) {
          return overriddenFiltersValues;
        }
        const filterType = getFilterValue(filter);
        return filterType?.requisite === 'true' ? BRANCH_APP_USERS_FILTERS_QUERIES[filter] : null;
      })
      .filter(Boolean)
      .flat(),
    alternative_queries: Object.values(BRANCH_APP_USERS_FILTER_TYPES)
      .map(filter => {
        const overriddenFiltersValues = BRANCH_APP_USERS_FILTERS_QUERIES[filter]?.filter(query => overriddenFilters?.[query]);
        if (overriddenFiltersValues?.length) {
          return null;
        }
        const filterType = getFilterValue(filter);
        return filterType?.requisite === 'false' ? BRANCH_APP_USERS_FILTERS_QUERIES[filter] : null;
      })
      .filter(Boolean)
      .flat()
  };

  return variables;
};

export { BRANCH_APP_USERS_FILTER_TYPES, BRANCH_APP_USERS_MORE_OPTIONS_TYPES, HIDDEN_BRANCH_APP_USERS_FILTERS, getBranchAppUsersFiltersVariables, useBranchAppUsersFilters };
