import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { Controller, UseFormMethods, useForm } from 'react-hook-form';
import { sentenceCase } from 'sentence-case';
import ModalDialog, { ModalLayout } from '../../../components/Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../../../components/Modal/styled';
import { Branch, BusUserProfile } from '../../../components/Profile/types';
import { FormButtonsContainer, FormCheckbox, FormError, FormInput, FormLabel, FormSelect, FormSubmitButton, InputsWrapper, WideInputGroup, selectTheme } from '../../../components/Shared/Forms/Forms';
import { Container } from '../../../components/Shared/Shared';
import { CenteredLoader } from '../../../components/Shared/Spinner';
import { AddBranchBusUser, EditBranchBusUser, GetReducedBusUserProfile } from '../../../queries';
import { isEmail } from '../../../utils/validators';
import { BUS_USER_ROLES } from '../../Bookings/types';
import Common from '../Common';
import Select from '../../../components/Shared/Forms/Select';

type BusUserModalForm = {
  name: string;
  email: string;
  status: 'ACTIVE' | 'INACTIVE';
  role: keyof typeof BUS_USER_ROLES;
  position: string;
  contact_number: string;
  password: string;
  confirmPassword: string;
  passwordToggled: string[];
  BranchId: string;
};

type BusUserModalFormProps = {
  loading: boolean;
  formOptions: UseFormMethods<BusUserModalForm>;
  saveError: boolean;
  busUser?: BusUserProfile;
  saveLoading: boolean;
  branches: Branch[];
  defaultValues?: Partial<BusUserModalForm>;
  isSelf?: boolean;
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
};

const BusUserModalForm = ({ loading, formOptions, saveError, saveLoading, onSubmit, busUser, branches, defaultValues, isSelf }: BusUserModalFormProps) => {
  const { control, errors, watch } = formOptions;

  const { password: watchedPassword, passwordToggled } = watch(['password', 'passwordToggled']);

  const isPasswordToggled = passwordToggled.includes('passwordToggled');

  return (
    <ModalLayout
      compact
      buttons={[
        <FormSubmitButton error={saveError} loading={saveLoading} onClick={onSubmit}>
          Save
        </FormSubmitButton>
      ]}
    >
      <>
        <WideInputGroup marginBottom={0}>
          <FormLabel error={errors.name?.message}>Name</FormLabel>
          <Controller as={<FormInput error={!!errors.name} type={'text'} />} control={control} name={'name'} rules={{ required: true }} defaultValue={defaultValues?.name} />
          {errors.name && <FormError>{errors.name.message || 'Full name is required'}</FormError>}
        </WideInputGroup>
        <WideInputGroup marginBottom={0}>
          <FormLabel error={errors.contact_number?.message}>Contact Number</FormLabel>
          <Controller
            as={<FormInput error={!!errors.contact_number} role="presentation" autoComplete="off" />}
            control={control}
            name={'contact_number'}
            defaultValue={defaultValues?.contact_number}
          />
          {errors.contact_number && <FormError>{errors.contact_number.message || 'Contact Number is required'}</FormError>}
        </WideInputGroup>

        <WideInputGroup marginBottom={0}>
          <FormLabel error={errors.position?.message}>Position</FormLabel>
          <Controller as={<FormInput error={!!errors.position} type={'text'} />} control={control} name={'position'} rules={{ required: true }} defaultValue={defaultValues?.position} />
          {errors.position && <FormError>{errors.position.message || 'Position is required'}</FormError>}
        </WideInputGroup>

        {!isSelf && (
          <WideInputGroup marginBottom={0}>
            <FormLabel error={errors.role?.message}>Role</FormLabel>
            <Controller
              render={({ onChange, value }) => (
                <Select
                  theme={selectTheme}
                  options={Object.values(BUS_USER_ROLES).map(role => ({
                    value: role,
                    label: sentenceCase(role)
                  }))}
                  value={value ? { value: value, label: sentenceCase(value) } : { value: '', label: '-- Select Role --' }}
                  onChange={option => {
                    onChange(option?.value);
                  }}
                />
              )}
              control={control}
              name={'role'}
              rules={{ required: true }}
              defaultValue={defaultValues?.role}
            />

            {errors.role && <FormError>{errors.role.message || 'Role is required'}</FormError>}
          </WideInputGroup>
        )}
        {!isSelf && (
          <WideInputGroup marginBottom={0}>
            <FormLabel error={errors.email?.message}>Business Email Address</FormLabel>
            <Controller
              as={<FormInput error={!!errors.email} type={'email'} role="presentation" autoComplete="off" />}
              control={control}
              name={'email'}
              rules={{ required: true, pattern: isEmail }}
              defaultValue={defaultValues?.email}
            />
            {errors.email && <FormError>{errors.email.message || 'Email is required'}</FormError>}
          </WideInputGroup>
        )}

        <WideInputGroup marginBottom={0}>
          <FormLabel error={errors.status?.message}>Status</FormLabel>
          <Controller
            render={({ onChange, value }) => (
              <Select
                theme={selectTheme}
                options={[
                  { value: 'ACTIVE', label: 'Active' },
                  { value: 'INACTIVE', label: 'Inactive' }
                ]}
                value={value ? { value: value, label: value === 'ACTIVE' ? 'Active' : 'Inactive' } : { value: 'ACTIVE', label: 'Active' }}
                onChange={option => {
                  onChange(option?.value);
                }}
              />
            )}
            control={control}
            name={'status'}
            rules={{ required: true }}
            defaultValue={defaultValues?.status}
          />
          {errors.status && <FormError>{errors.status.message || 'Status is required'}</FormError>}
        </WideInputGroup>

        {busUser?.id && !isSelf && (
          <WideInputGroup marginBottom={0}>
            <Controller
              render={({ onChange, value }) => <FormCheckbox itemsArray={[{ id: 'passwordToggled', name: 'Change Password' }]} onChange={onChange} value={value} fontSize={16} column />}
              control={control}
              name={'passwordToggled'}
              rules={{ required: true }}
              defaultValue={defaultValues?.passwordToggled}
            />
          </WideInputGroup>
        )}

        {isPasswordToggled && (
          <>
            <WideInputGroup marginBottom={0}>
              <FormLabel error={errors.password?.message}>New Password</FormLabel>
              <Controller as={<FormInput error={!!errors.password} type={'password'} role="presentation" autoComplete="off" />} control={control} name={'password'} />
              {errors.password && <FormError>{errors.password.message || 'Password is required'}</FormError>}
            </WideInputGroup>

            {watchedPassword && (
              <WideInputGroup marginBottom={0}>
                <FormLabel error={errors.confirmPassword?.message}>Confirm New Password</FormLabel>
                <Controller
                  as={<FormInput error={!!errors.confirmPassword} type={'password'} role="presentation" autoComplete="off" />}
                  control={control}
                  name={'confirmPassword'}
                  rules={{ validate: value => value === watchedPassword || 'Passwords do not match' }}
                />
                {errors.confirmPassword && <FormError>{errors.confirmPassword.message || 'Password is required'}</FormError>}
              </WideInputGroup>
            )}
          </>
        )}

        <WideInputGroup marginBottom={0}>
          <FormLabel error={errors.BranchId?.message}>Branch</FormLabel>
          <Controller
            render={({ onChange, value }) => (
              <Select
                theme={selectTheme}
                options={branches.map(branch => ({
                  value: branch.id,
                  label: branch.name
                }))}
                value={value ? { value: value, label: branches.find(branch => branch.id === value)?.name } : { value: '', label: '-- Select Branch --' }}
                onChange={option => {
                  onChange(option?.value);
                }}
              />
            )}
            control={control}
            name={'BranchId'}
            defaultValue={defaultValues?.BranchId}
            rules={{ required: false }}
          />
          {errors.BranchId && <FormError>{errors.BranchId.message || 'Branch is required'}</FormError>}
        </WideInputGroup>
      </>
    </ModalLayout>
  );
};
const BusUserModal = ({ busUser }: { busUser?: BusUserProfile }) => {
  const { data: { getBusUserProfile: currentBusUser } = {} } = useQuery<{ getBusUserProfile: BusUserProfile }>(GetReducedBusUserProfile);

  const isSelf = busUser?.id === currentBusUser?.id;

  const branches = currentBusUser?.Provider?.Branches || [];

  const defaultValues: BusUserModalForm = {
    name: busUser?.name || '',
    email: busUser?.email || '',
    role: busUser?.role || 'STAFF',
    position: busUser?.position || '',
    contact_number: busUser?.contact_number || currentBusUser?.Provider?.contact_number || '',
    password: '',
    confirmPassword: '',
    status: busUser?.status || 'ACTIVE',
    passwordToggled: [],
    BranchId: busUser?.Branch?.id || branches[0]?.id
  };

  const formOptions = useForm<BusUserModalForm>({ defaultValues });

  const { handleSubmit } = formOptions;
  const [saveBusUser, { data: addedBusUser, loading: addLoading, error: addError }] = useMutation(AddBranchBusUser, {
    refetchQueries: ['getBranchBusUsers'],
    awaitRefetchQueries: true
  });

  const [editBusUser, { data: editedBusUser, loading: editLoading, error: editError }] = useMutation(EditBranchBusUser, {
    refetchQueries: ['getBranchBusUsers'],
    awaitRefetchQueries: true
  });

  const onSubmit = handleSubmit(data => {
    const { name, email, role, position, contact_number, password, status, BranchId } = data;
    const variables = {
      name,
      email: isSelf ? busUser?.email : email,
      ...(isSelf ? {} : { role }),
      position,
      contact_number,
      status,
      password: password || undefined,
      BranchId: BranchId || undefined
    };

    if (busUser) {
      editBusUser({
        variables: {
          id: busUser.id,
          ...variables
        }
      });

      return;
    }

    saveBusUser({ variables });
  });

  const loading = false;
  const saveError = !!addError?.message || !!editError?.message;
  const saveLoading = addLoading || editLoading;
  const savedBusUser = addedBusUser?.addBranchBusUser || editedBusUser?.editBranchBusUser;

  useEffect(() => {
    if (savedBusUser) {
      ModalDialog.closeModal();
      Common.get<() => Promise<void>>(`BusUsers.GetBranchBusUsers.refetch`)?.();
    }
  }, [savedBusUser]);

  return (
    <BusUserModalForm
      loading={loading}
      formOptions={formOptions}
      saveError={saveError}
      saveLoading={saveLoading}
      onSubmit={onSubmit}
      busUser={busUser}
      branches={branches}
      defaultValues={defaultValues}
      isSelf={isSelf}
    />
  );
};

export default BusUserModal;
