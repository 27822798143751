import React from 'react';
import InfiniteList from '../../components/Shared/InfiniteList/InfiniteList';
import { PetsListContainer, RouteColorTag, RouteHeader } from './styles';
import RoutePetRecord from './RoutePetRecord';
import { RecordBody } from '../styled';
import useIcons from '../../hooks/useIcons';
import ModalDialog from '../../components/Modal/ModalDialog';
import BranchRouteModal from './BranchRouteModal/BranchRouteModal';
import { GatsbyImage } from 'gatsby-plugin-image';
import { BtnContainer } from '../../components/Shared/ToolBar/styles';
import { BranchRoute } from './types';

const FleetsPetList = ({ branchRoute, onClickPet }: { branchRoute: BranchRoute; onClickPet: (id: string) => void }) => {
  const icons = useIcons();
  const pets = [...(branchRoute?.BranchRoutePetRecords || [])]?.sort((a, b) => (a.sort_index || 0) - (b.sort_index || 0));
  const handleClick = () => {
    ModalDialog.openModal({
      content: () => <BranchRouteModal branchRoute={branchRoute} />,
      title: branchRoute?.name
    });
  };
  return (
    <PetsListContainer>
      <RouteHeader>
        <RouteColorTag color={branchRoute?.color} />
        <RecordBody fontWeight="800" fontSize={16}>
          {branchRoute?.name}
        </RecordBody>
        <BtnContainer onClick={handleClick}>
          <GatsbyImage image={icons?.moreIcon?.childImageSharp?.gatsbyImageData} alt="more" />
        </BtnContainer>
      </RouteHeader>

      <InfiniteList
        paddingL={20}
        paddingR={20}
        list={pets}
        itemRenderer={(pet, index) => <RoutePetRecord petRecord={pet} key={pet.PetRecord.Pet.id} index={index + 1} onClick={() => onClickPet(pet.PetRecord.Pet.id)} />}
        hasMoreItems={false}
        offset={pets?.length}
        setHasMoreItems={() => {}}
        mobilePadding="8px 16px 0 16px"
      />
    </PetsListContainer>
  );
};

export default FleetsPetList;
