import { vars } from '.';
import { DRAWER_IDS } from '../components/DrawerBar/types';
import { DrawerBar, ProductId } from './main';

export const setDrawerBar = (newDrawer: DrawerBar) => {
  const newDrawerBar = {
    recordData: {},
    isExtended: false,
    isPinned: false,
    ...newDrawer
  };
  const uniquePinnedDrawerBarIds = [
    ...new Set([
      ...vars
        .drawerBars()
        .filter(drawer => drawer.isPinned)
        .map(drawer => drawer.drawerId),
      newDrawerBar.drawerId
    ])
  ];
  const newDrawerBars = uniquePinnedDrawerBarIds.map(id => (newDrawerBar.drawerId === id ? newDrawerBar : vars.drawerBars().find(drawer => drawer.drawerId === id))) as DrawerBar[];

  vars.drawerBars(newDrawerBars);
};

export const addDrawerBar = (newDrawer: DrawerBar) => {
  const newDrawerBar = {
    recordData: {},
    isExtended: false,
    isPinned: false,
    ...newDrawer
  };

  const newDrawerBars = [...vars.drawerBars().filter(drawer => drawer.drawerId !== newDrawerBar.drawerId), newDrawerBar];

  vars.drawerBars(newDrawerBars);
};

export const unsetDrawerBars = () => {
  vars.drawerBars(vars.drawerBars().filter(drawer => drawer.isPinned)).map(drawer => ({ ...drawer, isExtended: false }));
};

export const clearDrawerBars = () => {
  vars.drawerBars([]);
  // navigate(`${location.pathname?.split('/').slice(0, 4).join('/')}`);
};

export const unsetDrawerBar = (drawerId: DRAWER_IDS) => {
  vars.drawerBars(vars.drawerBars().filter(drawer => drawer.drawerId !== drawerId));
};

export const togglePinDrawerBar = (drawerId: DRAWER_IDS) => {
  const newDrawersState = vars
    .drawerBars()
    .map(drawer => {
      if (drawer.drawerId === drawerId) {
        return { ...drawer, isPinned: !drawer.isPinned };
      }
      return drawer;
    })
    .filter(drawer => drawer.drawerId === drawerId);
  vars.drawerBars(newDrawersState);
};

export const toggleExtendDrawerBar = (drawerId: DRAWER_IDS) => {
  const newDrawersState = vars
    .drawerBars()
    .map(drawer => {
      if (drawer.drawerId === drawerId) {
        return { ...drawer, isExtended: !drawer.isExtended };
      }
      return { ...drawer, isPinned: false };
    })
    .filter(drawer => drawer.drawerId === drawerId);
  vars.drawerBars(newDrawersState);
};

export const setProductId = (productId: ProductId) => {
  vars.productId(productId);
};

export const showCalendarActionMessage = () => {
  vars.calendarMessage(true);
};

export const hideCalendarActionMessage = () => {
  vars.calendarMessage(false);
};
