import React from 'react';
import ModalDialog from '../../components/Modal/ModalDialog';
import useIcons from '../../hooks/useIcons';
import { RecordBody } from '../styled';
import BranchVehicleModal from './BranchVehicleModal/BranchVehicleModal';
import { BranchVehicle } from './types';
import { Booking, PetRecordTag } from '../Bookings/components/BookingsBody/styled';
import { BtnContainer } from '../../components/Shared/ToolBar/styles';
import { GatsbyImage } from 'gatsby-plugin-image';

const FleetsVehicleRecord = ({ branchVehicle }: { branchVehicle: BranchVehicle }) => {
  const handleClick = () => {
    ModalDialog.openModal({
      content: () => <BranchVehicleModal branchVehicle={branchVehicle} />,
      title: branchVehicle.name
    });
  };
  const icons = useIcons();

  return (
    <Booking gap={10}>
      <RecordBody flex="unset" width="150" fontWeight="700" noMargin>
        {branchVehicle?.name}
      </RecordBody>
      <RecordBody width="150" flex="unset">
        {branchVehicle?.routesCount} Routes
      </RecordBody>
      <RecordBody width="250" flex="unset" noMargin>
        {branchVehicle?.busUserNames?.join(' & ')}
      </RecordBody>
      <RecordBody marginLeft="auto" flex="unset" width="auto" noMargin display="flex" gap="10px">
        <PetRecordTag color={'#3F3F3F'} borderRadius="6px">
          {branchVehicle?.plate_number.toUpperCase()}
        </PetRecordTag>
        <BtnContainer onClick={handleClick}>
          <GatsbyImage image={icons?.moreIcon?.childImageSharp?.gatsbyImageData} alt="more" />
        </BtnContainer>
      </RecordBody>
    </Booking>
  );
};

export default FleetsVehicleRecord;
