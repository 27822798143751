import React from 'react';
import CreatableSelect from 'react-select/creatable';
import styled, { css } from 'styled-components';
import Colors from '../../../Colors';
import { selectTheme } from '../../../components/Shared/Forms/Forms';
import { ValueOf } from '../../../utils/helpers';

export const Container = styled.div`
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const SectionsContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  @media (max-width: 768px) {
    &:first-child {
      margin-top: 8px;
    }
    width: 100%;
  }
`;

export const SectionContainer = styled.div<{ column?: boolean }>`
  --section-content-width: 500px;
  display: flex;
  flex-direction: ${props => (props.column ? 'column' : 'row')};
  justify-content: space-between;
  align-items: flex-start;
  width: calc(100vw - var(--section-content-width));
  box-sizing: border-box;

  @media (max-width: 1200px) {
    --section-content-width: 460px;
  }

  @media (max-width: 768px) {
    --section-content-width: 0;
  }
`;

export const HealthMeasurementWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${Colors.white};
  padding: 18px 17px;
  border-radius: 20px;
`;

export const PetRecordEntitiesContainer = styled.div<{ column?: boolean; noPadding?: boolean; noBorderRadius?: boolean; gap?: number; mobilePadding?: string }>`
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
  ${({ column }) =>
    column &&
    css`
      flex-direction: column;
      gap: ${props => props?.gap || 20}px;
    `}
  width: 100%;
  background-color: ${Colors.white};
  padding: 20px;
  ${({ noPadding }) =>
    noPadding &&
    css`
      padding: 0;
    `}

  border-radius: ${props => (props.noBorderRadius ? '0' : '20px')};
  overflow: hidden;
  @media (max-width: 768px) {
    border-radius: unset;
    padding: ${props => props.mobilePadding || '20px 16px'};
  }
`;

export const SectionWrapper = styled.div`
  width: 100%;
`;

export const HealthMeasurementContainer = styled.div`
  display: flex;
  width: 50%;
  justify-content: space-between;
  align-items: flex-start;
  gap: 15px;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const PetRecordEntity = styled.div`
  align-items: center;
  margin-bottom: 23px;
`;

export const PetRecordEntityLabel = styled.span`
  display: block;
  color: '#212121';
  font-weight: 800;
  font-size: 14px;

  @media (max-width: 1200px) {
    font-size: 14px;
  }
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;
export const PetRecordEntitySubLabel = styled.span`
  display: block;
  color: ${Colors.warmGrey};
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 6px;

  @media (max-width: 1200px) {
    font-size: 12px;
  }
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const HealthMeasurementTitle = styled.span<{ mobileColor?: string; mobileFontSize?: string; mobileFontWeight?: number }>`
  color: #727272;
  font-size: 12px;
  font-weight: 600;
  @media (max-width: 1200px) {
    font-size: 16px;
  }
  @media (max-width: 768px) {
    font-weight: ${props => props.mobileFontWeight || 800};
    color: ${props => props.mobileColor || Colors.black};
    font-size: ${props => props.mobileFontSize || '16px'};
  }
`;

export const HealthMeasurementValue = styled.span<{ color?: ValueOf<typeof Colors> }>`
  color: #212121;
  font-size: 14px;
  font-weight: 800;

  @media (max-width: 1200px) {
    font-size: 24px;
  }
`;

export const HealthMeasurementHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const HealthChartContainer = styled.div`
  height: 400px;
  width: 100%;
`;

export const ChartTooltip = styled.span`
  font-size: 16px;
  font-weight: 800;
  color: ${Colors.primary};
`;

export const Divider = styled.div<{ paddingHorizontal?: number; color?: string; height?: number; verticalMargin?: number }>`
  height: 1px;
  ${({ height }) =>
    height &&
    css`
      height: ${height}px;
    `}
  background-color: #dddddd;
  ${({ color }) =>
    color &&
    css`
      background-color: ${color};
    `}
  width: 100%;
  ${({ paddingHorizontal }) =>
    paddingHorizontal &&
    css`
      padding: 0 ${paddingHorizontal}px;
    `}
  margin: 0;
  ${({ verticalMargin }) =>
    verticalMargin &&
    css`
      margin: ${verticalMargin}px 0;
    `}
`;

export const PetRecordWrapper = styled.div<{ padding?: string; gap?: number; borderRadius?: string }>`
  display: flex;
  flex-direction: column;
  gap: ${props => props.gap || 15}px;
  background-color: ${Colors.white};
  padding: ${props => props.padding || '20px 16px'};
  border-radius: ${props => props.borderRadius || '20px'};
  max-width: 100%;
  box-sizing: border-box;
  min-width: 100%;
  @media (max-width: 768px) {
    border-radius: unset;
  }
`;

export const PetRecordRecordsOptions = styled.div<{ padding?: string; width?: string; mobilePadding?: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${props => props.width || '100%'};
  gap: 6px;
  padding: ${props => props.padding || '0'};
  @media (max-width: 768px) {
    padding: ${props => props.mobilePadding || '0'};
  }
`;

export const RecordsSectionContainer = styled.div<{ padding?: string }>`
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow-x: scroll;
  white-space: nowrap;
`;

export const PetRecordsContainer = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const PetRecordContainer = styled.div<{ due?: boolean }>`
  box-sizing: border-box;
  width: 300px;
  min-width: 300px;
  height: 84px;
  border-radius: 15px;
  border: solid 1px ${props => (props.due ? '#f0753e' : Colors.warmGrey)};
  background-color: #ffffff;
  user-select: none;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
`;

export const SuppRecordContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  max-width: calc(100% - 16px);
  min-width: calc(100% - 16px);
`;

export const ColorTag = styled.div<{ color: string }>`
  width: 8px;
  min-width: 8px;
  border-radius: 5px;
  background-color: ${props => props.color};
`;

export const VaccRecordContainer = styled.div<{ column?: boolean; width?: string }>`
  display: flex;
  flex-direction: ${props => (props.column ? 'column' : 'row')};
  padding: 12px;
  width: ${props => props.width || 'auto'};
  gap: 12px;
  max-width: calc(100% - 16px);
  min-width: calc(100% - 16px);
`;

export const PetReportName = styled.span<{ color?: string; fontWeight?: number }>`
  display: flex;
  gap: 6px;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${props => props.color || '#404040'};
  font-weight: ${props => props.fontWeight || 800};
  @media (max-width: 1200px) {
    font-size: 14px;
  }
`;

export const PetRecordInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: calc(100% - 16px);
`;

export const PetRecordDate = styled.span<{ due?: boolean; green?: boolean }>`
  font-size: 13px;
  font-weight: 500;
  color: ${props => (props.due ? '#f0753e' : props.green ? Colors.primary : Colors.black)};
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const PetRecordSelect = ({ options, defaultValue, onChange }) => {
  return (
    <CreatableSelect
      styles={{ container: (provided, state) => ({ ...provided }), valueContainer: (provided, state) => ({ ...provided, height: 48, overflowY: 'scroll' }) }}
      options={options}
      isMulti
      isLoading={false}
      isDisabled={false}
      theme={selectTheme}
      defaultValue={defaultValue}
      onChange={onChange}
    />
  );
};

export const DaysWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const VetDaycareTitle = styled.div<{ marginB?: number }>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 800;
  margin-bottom: ${props => props.marginB}px;
`;

export const HealthPetRecordDietContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;

export const DietsWrapper = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
`;

export const DietRecordsContainer = styled.div`
  display: flex;
  overflow-x: scroll;
  width: 100%;
  white-space: nowrap;
  gap: 12px;

  @media (max-width: 1200px) {
    flex-direction: column;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const DietRecordContainer = styled.div<{ minWidth?: number; flex1?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;

  min-width: ${props => `${props.minWidth}px` || 'unset'};
  background-color: #f8f8f8;
  padding: 12px;
  border-radius: 16px;
  width: 250px;

  ${({ flex1 }) =>
    flex1 &&
    css`
      flex: 1;
      width: unset;
      min-width: unset;
    `}
`;

export const DietRecordHeader = styled.div`
  display: flex;
  gap: 3px;
  opacity: 0.5;
  white-space: nowrap;
  font-size: 14px;
  color: #696969;
  font-weight: 500;
`;

export const DietRecordBody = styled.div<{ noWrap?: boolean }>`
  display: flex;
  gap: 3px;
  ${({ noWrap }) =>
    noWrap &&
    css`
      white-space: nowrap;
    `}

  color: #212121;
  font-size: 14px;
  font-weight: 800;
`;

export const VaccRecordEndContainer = styled.div`
  display: flex;
  height: 40px;
  width: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${Colors.primary};
`;
