import React, { FC } from 'react';
import Filters from '../../components/Shared/Filters/Filters';
import SearchBar from '../../components/Shared/Filters/SearchBar';
import { FiltersControl } from '../../components/Shared/Filters/useFilters';
import useMediaQuery from '../../hooks/useMediaQuery';
import { areObjectsEqual } from '../../utils/helpers';
import SubscriptionsHeaderDashboard from './SubscriptionsHeaderDashboard';
import { SUBSCRIPTION_TABS_TYPES } from './types';
import { SUBSCRIPTIONS_FILTER_TYPES, SUBSCRIPTIONS_MORE_OPTIONS_TYPES } from './SubscriptionsFilters';
import ToolBar from '../../components/Shared/ToolBar/ToolBar';
import { getFilterKeyOptions } from '../../components/Shared/Filters/helpers';

type SubscriptionsHeaderProps = {
  filtersControl: FiltersControl<SUBSCRIPTIONS_FILTER_TYPES, SUBSCRIPTIONS_MORE_OPTIONS_TYPES>;
  tabs: { name: string; status: string[]; otherVariables: Record<string, any>; value: string }[];
};

const SubscriptionsHeader: FC<SubscriptionsHeaderProps> = ({ filtersControl, tabs }) => {
  const { mobile } = useMediaQuery({ mobile: true });
  const tabsValues = tabs.map(tab => tab.value);
  const tabValue = tabsValues?.find(tab => tab === location.pathname.split('/')[3]) || 'active';
  const isActive = tabValue === SUBSCRIPTION_TABS_TYPES.ACTIVE;
  const stringToDisplay = tabs.find(tab => tab.value === tabValue)?.name + '  Memberships';
  const { onChange: onSubscriptionSearch } = getFilterKeyOptions({
    filtersControl,
    keys: {
      filterType: SUBSCRIPTIONS_FILTER_TYPES.SUBSCRIPTION_SEARCH,
      moreOption: SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_SEARCH
    }
  });

  return (
    <>
      <ToolBar urlSearchParam="status" displayString={stringToDisplay} onSearchChange={onSubscriptionSearch} middleAction={filtersControl.headerAction} />
      {isActive && <SubscriptionsHeaderDashboard />}
      {!mobile && <Filters filtersControl={filtersControl} />}
    </>
  );
};

export default React.memo(SubscriptionsHeader, areObjectsEqual);
