import React from 'react';
import { sentenceCase } from 'sentence-case';
import ModalDialog from '../../../components/Modal/ModalDialog';
import { BusUserProfile } from '../../../components/Profile/types';
import { HoverRecordOptionsContainer, RecordBody, RecordDataContainer } from '../../styled';
import BusUserModal from './BusUserModal';
import { Booking } from '../../Bookings/components/BookingsBody/styled';
import { BtnContainer } from '../../../components/Shared/ToolBar/styles';
import { GatsbyImage } from 'gatsby-plugin-image';
import useIcons from '../../../hooks/useIcons';

const BusUserRecord = ({ busUser }: { busUser: BusUserProfile }) => {
  const handleClick = () => {
    ModalDialog.openModal({
      content: () => <BusUserModal busUser={busUser} />,
      title: 'Staff'
    });
  };
  const icons = useIcons();

  return (
    <Booking padding="6px 10px" gap={10}>
      <RecordBody fontWeight={'800'}>{busUser?.name}</RecordBody>
      <RecordBody flex="unset" fontWeight="600" width="250">
        {busUser?.email}
      </RecordBody>
      <RecordBody flex="unset" fontWeight="600" width="100">
        {busUser?.position}
      </RecordBody>
      <RecordBody flex="unset" fontWeight="600" width="100">
        {sentenceCase(busUser?.role)}
      </RecordBody>
      <RecordBody flex="unset" fontWeight="600" width="100" flexEnd>
        {sentenceCase(busUser?.status)}
      </RecordBody>
      <HoverRecordOptionsContainer>
        <BtnContainer onClick={handleClick}>
          <GatsbyImage image={icons?.moreIcon?.childImageSharp?.gatsbyImageData} alt="more" />
        </BtnContainer>
      </HoverRecordOptionsContainer>
    </Booking>
  );
};

export default BusUserRecord;
