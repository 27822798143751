import React, { useRef } from 'react';
import { StoreEditButton } from '../../../components/DrawerBar/DrawerButtons';
import { DRAWER_IDS } from '../../../components/DrawerBar/types';
import { Divider } from '../../../components/Shared/SideTabs/styles';
import { HoverRecordOptionsContainer, RecordBody, RecordDataContainer } from '../../styled';
import { BRANCH_MESSAGE_TYPES_LABELS, BranchMessage } from './BranchMessages';
import { Booking } from '../../Bookings/components/BookingsBody/styled';

const BranchMessageRecord = ({ branchMessage, navigateDrawer }: { branchMessage: BranchMessage; navigateDrawer: ({ drawerLinkId }: { drawerLinkId: string }) => void }) => {
  const storeEditButtonRef = useRef<{ handleClick: () => void }>(null);

  const handleClick = () => {
    // storeEditButtonRef.current?.handleClick();
    // navigateDrawer({ drawerLinkId: branchMessage?.id });
  };

  return (
    <>
      <Booking onClick={handleClick} gap={10} padding="6px 10px">
        <RecordBody fontWeight={'800'} flex="unset" width="150">
          {branchMessage?.name}
        </RecordBody>
        <RecordBody fontWeight="500">{branchMessage?.message}</RecordBody>
        <RecordBody flex="unset" width="100" fontWeight="600">
          {branchMessage?.Products?.map(product => product.name).join(', ')}
        </RecordBody>
        <RecordBody flex="unset" width="100" fontWeight="600" flexEnd padding="0">
          {BRANCH_MESSAGE_TYPES_LABELS[branchMessage?.type]}
        </RecordBody>

        <HoverRecordOptionsContainer>
          <StoreEditButton drawer={{ drawerId: DRAWER_IDS.BRANCH_MESSAGE_DRAWER, recordData: branchMessage }} ref={storeEditButtonRef} />
        </HoverRecordOptionsContainer>
      </Booking>
      <Divider />
    </>
  );
};

export default BranchMessageRecord;
