import { Adherence, SuppRecord } from '../../../../components/Pets/types';

export const getUniqueSuppRecords = ({ suppRecords, adherences }: { suppRecords: SuppRecord[]; adherences: Adherence[] }) => {
  const suppsFromAdherences = (adherences || [])?.map(adherence => adherence?.SuppRecord).flat();

  const uniqueSupps = [...(suppRecords || []), ...(suppsFromAdherences || [])].filter((supp, index, self) => {
    return index === self.findIndex(t => t?.id === supp?.id);
  });

  return uniqueSupps;
};
