import { useQuery } from '@apollo/client';
import React, { useCallback } from 'react';
import { sentenceCase } from 'sentence-case';
import { DrawerContainer, DrawerContent, DrawerLabel, DrawerSection } from '../../components/DrawerBar/styled';
import { DRAWER_IDS } from '../../components/DrawerBar/types';
import ProfileActionBar, { SMALL_DESCRIPTION_POSITIONS } from '../../components/Profile/ProfileActionBar';
import { CenteredLoader } from '../../components/Shared/Spinner';
import { GetBranchSubmittedForms } from '../../queries';
import { getKeys } from '../../utils/helpers';
import { SubmittedForm } from '../Store/BranchForms/types';
import { Divider } from './Health/styled';
import { FormFillContainer, FormInfoFillContainer, FormPetName, FormQuestionContainer, FormQuestionTitle, FormQuestionsContainer, FormQustionAnswer, PetFormAnswers } from './styled';

const SubmittedFormDrawer = ({ data: id }: { data: string }) => {
  const { data: { getBranchSubmittedForms: submittedForms = [] } = {}, loading: loadingSubmittedForm } = useQuery<{ getBranchSubmittedForms: SubmittedForm[] }>(GetBranchSubmittedForms, {
    variables: { id: [id] },
    skip: !id,
    fetchPolicy: 'cache-and-network'
  });
  const submittedForm = submittedForms?.[0];

  const answers = submittedForm?.Answers;
  const questions = answers?.map(answer => answer?.Question).sort((a, b) => a?.sort_index - b?.sort_index);
  const form = submittedForm?.Form;
  const formName = `${form?.name || 'Completed Form'} ${submittedForm?.Order?.id ? `(${submittedForm?.Order?.number})` : ''}`;
  const nonPetQuestions = questions?.filter(question => !question?.ask_per_pet);
  const petQuestions = questions?.filter(question => question?.ask_per_pet);
  const selectedPetRecordsIds = [...new Set(answers?.map(answer => answer?.PetRecordId))].filter(id => !!id);
  const selectedPetRecords = selectedPetRecordsIds?.map(id => answers?.find(answer => answer?.PetRecordId === id)?.PetRecord);

  const getAnswer = useCallback(
    (questionId: string, petRecordId?: string) => {
      const answer = answers?.find(answer => answer?.Question?.id === questionId);
      const question = questions?.find(question => question?.id === questionId);
      let answerString = answer?.body || answer?.AnswerOptions?.map(option => option?.title).join('\n');
      if (answerString === 'true') {
        answerString = 'Yes';
      }
      if (answerString === 'false') {
        answerString = 'No';
      }

      if (question?.get_answers_from === 'CHRONIC_CONDITIONS') {
        const parsedAnswer = JSON.parse(answer?.body || '{}');
        const conditions = getKeys(parsedAnswer || {})
          .filter(cond => parsedAnswer[cond])
          .map(cond => sentenceCase(cond || ''))
          .join(', ');
        answerString = conditions;
      }

      if (question?.get_answers_from === 'BREED') {
        answerString = selectedPetRecords?.find(petRecord => petRecord?.id === petRecordId)?.Pet?.Breed?.name || '';
      }

      if (question?.type === 'ADDRESS') {
        const parsedAnswer = JSON.parse(answer?.body || '[{}]')?.[0];
        answerString = `${parsedAnswer?.country || ''} ${parsedAnswer?.city || ''} ${parsedAnswer?.line1 || ''} ${parsedAnswer?.line2 || ''}`;
      }

      if (question?.type === 'PHONE') {
        const parsedAnswer = JSON.parse(answer?.body || '{}');
        answerString = `${parsedAnswer?.country_code || ''} ${parsedAnswer?.number || ''}`;
      }

      if (question?.get_answers_from === 'VET' || question?.get_answers_from === 'INSURER') {
        const parsedAnswer = JSON.parse(answer?.body || '{}');
        answerString = `${parsedAnswer?.name || ''}, ${parsedAnswer?.address?.line1 || ''} ${parsedAnswer?.address?.line2 || ''} ${parsedAnswer?.address?.city || ''} ${
          parsedAnswer?.address?.country || ''
        }`;
      }

      return answerString;
    },
    [answers, questions]
  );

  return (
    <>
      {loadingSubmittedForm && <CenteredLoader />}
      {!loadingSubmittedForm && (
        <DrawerContainer>
          <DrawerSection paddingT={24} paddingB={20} isFixed flexRow center>
            <DrawerLabel>{formName}</DrawerLabel>
          </DrawerSection>
          <Divider />
          <DrawerContent>
            <FormInfoFillContainer>
              <FormFillContainer>
                {!!nonPetQuestions?.length && (
                  <FormQuestionsContainer>
                    {nonPetQuestions.map(question => (
                      <FormQuestionContainer key={question?.id}>
                        <FormQuestionTitle>{question?.title}</FormQuestionTitle>
                        <FormQustionAnswer>{getAnswer(question?.id, '')}</FormQustionAnswer>
                      </FormQuestionContainer>
                    ))}
                  </FormQuestionsContainer>
                )}
                {!!petQuestions?.length && !!nonPetQuestions?.length && <Divider />}
                {!!petQuestions?.length &&
                  selectedPetRecords?.map((petRecord, petRecordIndex) => (
                    <PetFormAnswers key={petRecord?.id}>
                      <FormPetName>About {petRecord?.Pet?.name}</FormPetName>
                      <FormQuestionsContainer>
                        {petQuestions?.map(question => (
                          <FormQuestionContainer key={question?.id}>
                            <FormQuestionTitle>{question?.title}</FormQuestionTitle>
                            <FormQustionAnswer>{getAnswer(question?.id, petRecord?.id)}</FormQustionAnswer>
                          </FormQuestionContainer>
                        ))}
                      </FormQuestionsContainer>
                      {petRecordIndex !== selectedPetRecords.length - 1 && <Divider />}
                    </PetFormAnswers>
                  ))}
              </FormFillContainer>
            </FormInfoFillContainer>
          </DrawerContent>
          <Divider />
          <DrawerSection paddingT={24} paddingB={20} isFixed center>
            <ProfileActionBar smallDescriptionPosition={SMALL_DESCRIPTION_POSITIONS.UP} profile={submittedForm?.AppUser} drawerId={DRAWER_IDS.SUBMITTED_FORM_DRAWER} />
          </DrawerSection>
        </DrawerContainer>
      )}
    </>
  );
};

export default SubmittedFormDrawer;
