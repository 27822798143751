import React, { FC, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useLazyQuery, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import addMinutes from 'date-fns/addMinutes';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import { CalendarProps } from 'react-big-calendar';
import { withDragAndDropProps } from 'react-big-calendar/lib/addons/dragAndDrop';
import {
  AssignBranchRoomToAppointment,
  AssignBusUserToAppointment,
  GetBranchBusUserAssignedCalendarAppointmentsViews,
  GetBranchCalendarAppointmentsViews,
  GetBranchSchedules,
  GetBranchSchedulesSlots,
  GetBusUserProfile,
  RescheduleBranchSlot
} from '../../queries';
import { vars } from '../../reactive';
import { hideCalendarActionMessage, setDrawerBar } from '../../reactive/actions';
import BookingDurationModal from '../../views/Bookings/components/BookingsDrawer/BookingDurationModal';
import BookingRescheduleModal from '../../views/Bookings/components/BookingsDrawer/BookingRescheduleModal';
import { BlockedCalendarEvent, BOOKING_STATUS_TYPES, BOOKING_TYPE, BookingOrder, CalendarEvent, CalendarEvents } from '../../views/Bookings/types';
import { DRAWER_IDS } from '../DrawerBar/types';
import ModalDialog from '../Modal/ModalDialog';
import { BusUserProfile } from '../Profile/types';
import SlotModal, { SLOTS_TABS_TYPES } from './Modals/SlotModal';
import { CALENDAR_VIEWS, CALENDAR_VIEW_BOOKING_TYPE } from './types';
import { convertDurationToMinutes } from '../Shared/DurationSelector';
import { SlotsCalendar } from './Calendars/SlotsCalendar';
import { MultiDayCalendar } from './Calendars/MultiDayCalendar';
import { BranchRoom } from '../../views/Store/BranchRooms/types';
import { BranchSchedule } from '../../views/Store/BranchSchedules/types';
import { BranchAvailability } from '../../views/Store/types';
import { toUtc } from '../../utils/dates';
import debounce from '../../utils/debounce';
import { CalendarRangeRef } from './CalendarRange';
import OptionDropdown, { OptionDropDownItem } from '../Shared/Menus/OptionDropdown/OptionDropdown';
import { adjustCalendarDomView, extractRange } from './utils';
import { CalendarNavigation } from '../../views/Calendar/Calendar';
import { BusinessUserImageContainer } from '../SideBar/styled';
import { RecordBody } from '../../views/styled';
import { OPTION_DROPDOWN_MENU_BUTTON_TYPES, OPTION_DROPDOWN_MENU_POSITIONS, OPTION_DROPDOWN_TYPES } from '../Shared/Menus/OptionDropdown/types';
import useMediaQuery from '../../hooks/useMediaQuery';

export type TheCalendarEvent = {
  isBlocked?: boolean;
  view: Partial<
    CalendarEvent &
      BlockedCalendarEvent & {
        color: string;
        BranchRooms: BranchRoom[];
        time: string;
        branch_schedules: BranchSchedule[];
        timestamp_until: string;
        BusUsers: Partial<BusUserProfile>[];
      }
  >;
  start: Date;
  end: Date;
  variables?: Record<string, unknown>;
  resourceId: string;
};

export type BranchScheduleSlotsAvailability = {
  branchSchedules: {
    BranchSchedule: BranchSchedule;
    availability: BranchAvailability;
  }[];
  slotAvailability: BranchAvailability;
  multiSlotAvailability: BranchAvailability;
  multiDayAvailability: BranchAvailability;
};

export type TheCalendarResource = { resourceId: string; resourceTitle: string };

type TheCalendarProps = {
  selectedUser: string;
  setSelectedUser: (value: string) => void;
  calendarRangeRef: React.RefObject<CalendarRangeRef>;
  bookingTypeOptions: OptionDropDownItem[];
  calendarNavigation: CalendarNavigation;
  dateRange: { from: Date; to: Date };
  selectedBookingType: CALENDAR_VIEWS;
  availabilityVisible: boolean;
  calendarView: 'day' | 'week' | 'month';
};

export type TheCalendarRef = {
  refetch: (props: any) => void;
};

const TheCalendar = forwardRef<TheCalendarRef, TheCalendarProps>(
  ({ selectedUser, setSelectedUser, calendarRangeRef, bookingTypeOptions, calendarNavigation, dateRange, selectedBookingType, availabilityVisible, calendarView }, ref) => {
    const { mobile } = useMediaQuery({ mobile: true });
    const modalTitle = useReactiveVar(vars.modalTitle);
    const multiStaffBookingType = selectedBookingType === CALENDAR_VIEWS.MULTI_STAFF;
    const myScheduleBookingType = selectedBookingType === CALENDAR_VIEWS.MY_SCHEDULE;
    const isMultiDay = selectedBookingType === CALENDAR_VIEWS.MULTI_DAY;
    const [selectedSchedules, setSelectedSchedules] = useState<string[]>([]);
    const [addStaff] = useMutation<{ assignBusUserToAppointment: BookingOrder }>(AssignBusUserToAppointment);
    const [addBranchRoom] = useMutation<{ assignBranchRoomToAppointment: BookingOrder }>(AssignBranchRoomToAppointment);

    const { data: { getBusUserProfile: busUserProfile } = {}, refetch: refetchProfile } = useQuery<{ getBusUserProfile: BusUserProfile }>(GetBusUserProfile, {
      fetchPolicy: 'cache-only'
    });

    const schedulesToReturn = !selectedSchedules?.length || isMultiDay ? null : selectedSchedules;

    const profile = (busUserProfile || {}) as BusUserProfile;

    const [handleEditBranchSlot] = useMutation(RescheduleBranchSlot);

    const [getCalendarAppointments, { data: calendarAppointmentsData, loading: loadingCalendarAppointments }] = useLazyQuery<{
      getBranchCalendarAppointmentsViews: CalendarEvents;
    }>(GetBranchCalendarAppointmentsViews, {
      fetchPolicy: 'cache-and-network'
    });

    const [getBusUserAssignedCalendarAppointments, { data: busUserAssignedCalendarAppointmentsData }] = useLazyQuery<{
      getBranchBusUserAssignedCalendarAppointmentsViews: {
        busUserViews: {
          BusUser: BusUserProfile;
          views: CalendarEvents;
        }[];
      };
    }>(GetBranchBusUserAssignedCalendarAppointmentsViews, {
      fetchPolicy: 'cache-and-network'
    });

    const [getAvailability, { data: { getBranchSchedulesSlots: availability } = {}, refetch: refetchAvailability }] = useLazyQuery<{
      getBranchSchedulesSlots: BranchScheduleSlotsAvailability;
    }>(GetBranchSchedulesSlots, {
      fetchPolicy: 'cache-and-network'
    });

    const { data: { getBranchSchedules: schedules = [] } = {} } = useQuery<{ getBranchSchedules: BranchSchedule[] }>(GetBranchSchedules, {
      fetchPolicy: 'cache-and-network',
      skip: multiStaffBookingType || isMultiDay,
      variables: {
        limit: 100,
        is_connected_to_products: true
      }
    });

    const refetch = useCallback(
      (args: Parameters<typeof getCalendarAppointments>) => {
        debounce(
          () => {
            if (multiStaffBookingType) {
              getBusUserAssignedCalendarAppointments({
                variables: {
                  status: [BOOKING_STATUS_TYPES.CONFIRMED],
                  filter_by_role: profile.role,
                  timestamp_from: toUtc(dateRange.from),
                  timestamp_to: toUtc(dateRange.to),
                  booking_type: CALENDAR_VIEW_BOOKING_TYPE[selectedBookingType],
                  ...(selectedUser && {
                    appointment_busUserAssigned_id: selectedUser
                  }),
                  ...args
                }
              });
            } else if (vars.calendarAvailabilityVisible() && !isMultiDay) {
              const variables = {
                start_date: toUtc(dateRange.from),
                slots_length: 7,
                branchScheduleId: args?.branchScheduleId ?? (selectedSchedules && selectedSchedules?.length ? selectedSchedules : undefined)
              };

              if (!variables.branchScheduleId?.length && schedules[0]?.id) {
                variables.branchScheduleId = [schedules[0]?.id];
              }
              getAvailability({ variables });
            } else {
              getCalendarAppointments({
                variables: {
                  status: [BOOKING_STATUS_TYPES.CONFIRMED],
                  filter_by_role: profile.role,
                  timestamp_from: toUtc(dateRange.from),
                  timestamp_to: toUtc(dateRange.to),
                  booking_type: CALENDAR_VIEW_BOOKING_TYPE[selectedBookingType],
                  ...(myScheduleBookingType && {
                    appointment_busUserAssigned_id: profile?.id
                  }),
                  product_branchSchedule_id: schedulesToReturn,
                  ...args
                }
              });
            }
          },
          300,
          'SPECIAL'
        );
      },
      [
        multiStaffBookingType,
        schedulesToReturn,
        myScheduleBookingType,
        profile.role,
        profile.id,
        selectedBookingType,
        dateRange.from,
        dateRange.to,
        selectedUser,
        availabilityVisible,
        isMultiDay,
        selectedSchedules,
        schedules
      ]
    );

    const events = useMemo(() => {
      const list = multiStaffBookingType
        ? busUserAssignedCalendarAppointmentsData?.getBranchBusUserAssignedCalendarAppointmentsViews?.busUserViews?.flatMap(busUserView =>
            busUserView.views?.singleDayAppointmentsViews?.flatMap(e => e.views?.map(v => ({ ...v, resourceId: busUserView?.BusUser?.id })))
          )
        : isMultiDay
        ? calendarAppointmentsData?.getBranchCalendarAppointmentsViews?.multiDayAppointmentsViews
        : calendarAppointmentsData?.getBranchCalendarAppointmentsViews?.singleDayAppointmentsViews?.map(e => e.views)?.flat();

      return (list || [])?.map(view => {
        const dt = new Date(view.timestamp);
        const dtUtc = new Date(dt.getUTCFullYear(), dt.getUTCMonth(), dt.getUTCDate(), dt.getUTCHours(), dt.getUTCMinutes(), dt.getUTCSeconds());
        const dtUntil = new Date(view.timestamp_until);
        const dtUntilUtc = new Date(dtUntil.getUTCFullYear(), dtUntil.getUTCMonth(), dtUntil.getUTCDate(), dtUntil.getUTCHours(), dtUntil.getUTCMinutes(), dtUntil.getUTCSeconds());
        const nightsNumber = Math.floor(Number(view.duration));
        const nightsToReturn = nightsNumber > 1 ? ` - ${nightsNumber} nights` : '- night';
        const color = view.color;
        return {
          view,
          title: view.petsNames + ' - ' + view.itemName + nightsToReturn,
          start: dtUtc,
          end: new Date(view.timestamp_until ? dtUntilUtc : addMinutes(dtUtc, Number(view.duration))),
          allDay: false,
          resourceId: multiStaffBookingType ? view?.resourceId : isMultiDay ? view.BranchRooms?.[0]?.id : view.BusUsers?.[0]?.id,
          color
        };
      });
    }, [JSON.stringify(calendarAppointmentsData), JSON.stringify(busUserAssignedCalendarAppointmentsData), multiStaffBookingType, isMultiDay]);

    const blockedEvents = useMemo(() => {
      return (calendarAppointmentsData?.getBranchCalendarAppointmentsViews?.blockedSlotsViews || [])
        ?.map(e => e.views)
        .flat()
        ?.map(view => {
          const count = view.count;
          const time = view.time;
          const duration = view.duration;
          const branch_schedules = view.branch_schedules;
          const dt = new Date(view.timestamp);
          const dtUtc = new Date(dt.getUTCFullYear(), dt.getUTCMonth(), dt.getUTCDate(), dt.getUTCHours(), dt.getUTCMinutes(), dt.getUTCSeconds());
          const dtUntil = new Date(view.timestamp_until);
          const dtUntilUtc = new Date(dtUntil.getUTCFullYear(), dtUntil.getUTCMonth(), dtUntil.getUTCDate(), dtUntil.getUTCHours(), dtUntil.getUTCMinutes(), dtUntil.getUTCSeconds());
          const variables = {
            timeStamp: dtUtc,
            count: Number(count),
            duration: Number(duration !== undefined ? convertDurationToMinutes(duration) || 0 : 0),
            description: view.description,
            branch_schedules,
            status: 'BLOCKED'
          };

          return {
            view,
            title: view.description,
            start: dtUtc,
            end: new Date(view.timestamp_until ? dtUntilUtc : addMinutes(dtUtc, Number(view.duration))),
            allDay: false,
            resourceId: view.BusUsers?.[0]?.id,
            isBlocked: true,
            time,
            variables
          };
        });
    }, [JSON.stringify(calendarAppointmentsData)]);

    const onEventDrop: withDragAndDropProps<TheCalendarEvent, TheCalendarResource>['onEventDrop'] = e => {
      const isBlocked = e.event.isBlocked;
      const { start, end } = extractRange({ start: e.start, end: e.end });
      const appointmentsIds = e.event.view.appointmentsIds;
      const branchSlotsIds = e.event.view.branchSlotsIds;
      const blockedNewDate = toUtc(start);
      const ecStart = new Date(e.event.view.timestamp);

      if (isBlocked) {
        handleEditBranchSlot({
          variables: {
            ...e.event.variables,
            id: branchSlotsIds[0],
            timestamp: blockedNewDate
          }
        });
        e.event.start = start;
        e.event.end = end;
      }

      const reschedule = () => {
        if (start.getTime() === e?.event?.start?.getTime() && !isMultiDay) {
          return;
        }

        const startUTC = new Date(Date.UTC(start.getFullYear(), start.getMonth(), start.getDate(), start.getHours() - 12, start.getMinutes(), start.getSeconds(), start.getMilliseconds()));
        if (isMultiDay && startUTC.getDay() === ecStart.getDay()) {
          return;
        }

        const group = appointmentsIds.length > 1 ? true : false;
        // if (!isMultiDay) {
        ModalDialog.openModal({
          content: () => <BookingRescheduleModal appointmentsIds={appointmentsIds} newDate={start} newDateUntil={isMultiDay && end} group={group} />,
          title: isMultiDay ? 'Amend Booking' : 'Reschedule',
          onClose: refetch,
          isMini: true
        });
        // }
      };

      if (e.resourceId !== e.event.resourceId && multiStaffBookingType) {
        addStaff({
          variables: {
            id: appointmentsIds,
            BusUserId: [e.resourceId]
          },
          refetchQueries: ['getBranchBusUserAssignedCalendarAppointmentsViews']
        });
      }
      if (e.resourceId !== e.event.resourceId && isMultiDay) {
        if (e.resourceId === 'unassigned') {
          addBranchRoom({
            variables: {
              id: appointmentsIds,
              BranchRoomId: []
            },
            refetchQueries: ['getBranchCalendarAppointmentsViews']
          });
          return;
        }
        addBranchRoom({
          variables: {
            id: appointmentsIds,
            BranchRoomId: [e.resourceId]
          },
          refetchQueries: ['getBranchCalendarAppointmentsViews']
        });
      }
      reschedule();
    };

    const onEventResize: withDragAndDropProps<TheCalendarEvent, TheCalendarResource>['onEventResize'] = data => {
      const isBlocked = data.event.isBlocked;
      const { start, end } = extractRange({ start: data.start, end: data.end });
      const newDuration = differenceInMinutes(end, start);
      const newDate = data.event.start !== start ? start : null;
      const appointmentsIds = data.event.view.appointmentsIds;
      const branchSlotsIds = data.event.view.branchSlotsIds;

      if (isBlocked) {
        handleEditBranchSlot({
          variables: {
            ...data.event.variables,
            id: branchSlotsIds[0],
            duration: newDuration
          }
        });
        data.event.start = start;
        data.event.end = end;
      }

      if (isMultiDay) {
        return ModalDialog.openModal({
          content: () => <BookingRescheduleModal appointmentsIds={appointmentsIds} newDate={start} newDateUntil={end} />,
          title: 'Amend Booking',
          onClose: refetch,
          isMini: true
        });
      }
      if (!isBlocked) {
        ModalDialog.openModal({
          content: () => <BookingDurationModal appointmentsIds={appointmentsIds} newDuration={newDuration} newDate={newDate} />,
          title: 'Update Duration',
          onClose: refetch,
          isMini: true
        });
      }
    };

    useImperativeHandle(ref, () => ({
      refetch
    }));

    const onSelectEvent: CalendarProps<TheCalendarEvent, TheCalendarResource>['onSelectEvent'] = event => {
      const drawerId = event.view.isGroup ? DRAWER_IDS.GROUP_BOOKINGS_DRAWER : DRAWER_IDS.BOOKING_DRAWER;
      if (event.isBlocked) {
        return ModalDialog.openModal({
          title: modalTitle,
          content: () => <SlotModal tab={SLOTS_TABS_TYPES.BLOCK} branchSlotsIds={event.view.branchSlotsIds} />,
          onClose: refetch
        });
      }
      const recordData = event.view.appointmentsIds.map(id => ({ id }));
      setDrawerBar({ drawerId, recordData, otherData: { refetch: refetch } });
    };

    const onSelectSlot: CalendarProps['onSelectSlot'] = slotInfo => {
      const slotStart = slotInfo.slots[0];
      const selectedId = slotInfo.resourceId;
      vars.selectedDate({ date: slotStart, hour: slotStart.getHours() });
      ModalDialog.openModal({
        title: modalTitle,
        content: () => <SlotModal tab={SLOTS_TABS_TYPES.BOOK} selectedId={selectedId} />,
        onCloseBySave: () => {
          hideCalendarActionMessage();
          setTimeout(() => {
            refetchProfile();
            refetch();
            vars.selectedDate(null);
            refetchAvailability();
          }, 3000);
        }
      });
    };

    if (isMultiDay) {
      return (
        <MultiDayCalendar
          bookingTypeOptions={bookingTypeOptions}
          dateRange={dateRange}
          events={events}
          blockedEvents={blockedEvents}
          onEventDrop={onEventDrop}
          onEventResize={onEventResize}
          onSelectEvent={onSelectEvent}
          onSelectSlot={onSelectSlot}
          loadingCalendarAppointments={loadingCalendarAppointments}
          calendarNavigation={calendarNavigation}
        />
      );
    }

    return (
      <SlotsCalendar
        dateRange={dateRange}
        refetch={refetch}
        calendarView={calendarView}
        selectedBookingType={selectedBookingType}
        availabilityVisible={availabilityVisible}
        calendarNavigation={calendarNavigation}
        bookingTypeOptions={bookingTypeOptions}
        events={events}
        blockedEvents={blockedEvents}
        calendarRangeRef={calendarRangeRef}
        onEventDrop={onEventDrop}
        onEventResize={onEventResize}
        onSelectEvent={onSelectEvent}
        onSelectSlot={onSelectSlot}
        multiStaffBookingType={multiStaffBookingType}
        adjustCalendarDomView={adjustCalendarDomView}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        setSelectedSchedules={setSelectedSchedules}
        selectedSchedules={availabilityVisible && !selectedSchedules?.length ? [schedules[0]?.id] : selectedSchedules}
        availability={availability}
        schedules={schedules}
      />
    );
  }
);

export default TheCalendar;
