import React, { FC } from 'react';
import Filters from '../../../components/Shared/Filters/Filters';
import SearchBar from '../../../components/Shared/Filters/SearchBar';
import { getFilterKeyOptions } from '../../../components/Shared/Filters/helpers';
import { FiltersControl } from '../../../components/Shared/Filters/useFilters';
import { FlexRow, TabsHeaderContainer } from '../../../components/Shared/Shared';
import ToolBar from '../../../components/Shared/ToolBar/ToolBar';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { PETS_TABS_TYPES } from '../Pets';
import { PETS_FILTER_TYPES, PETS_MORE_OPTIONS_TYPES } from '../PetsFilters';
import PetTagsQuickFilter from './PetTagsQuickFilter';
import { petTypesOptions } from '../../Store/Operations/StoreProductModal/types';
import { GetBranchAppUsersViewCountsReturn } from '../../../hooks/useBranchAppUsersViewCounts';
import { RecordBody } from '../../styled';
import { Divider } from '../../../components/Shared/SideTabs/styles';
import { VerticalDivider } from '../../../components/Shared/ToolBar/styles';

type PetsHeaderProps = {
  filtersControl: FiltersControl<PETS_FILTER_TYPES, PETS_MORE_OPTIONS_TYPES>;
  showFilters?: boolean;
  selectedTab: PETS_TABS_TYPES;
  middleHeaderAction?: React.ReactNode;
  petTypesCount?: GetBranchAppUsersViewCountsReturn['petsTypesCount'];
};

const PetsHeader: FC<PetsHeaderProps> = ({ filtersControl, showFilters = true, selectedTab, middleHeaderAction, petTypesCount }) => {
  const { mobile } = useMediaQuery({ mobile: true });

  const { onChange } = getFilterKeyOptions({
    filtersControl,
    keys: {
      filterType: PETS_FILTER_TYPES.SEARCH,
      moreOption: PETS_MORE_OPTIONS_TYPES.SEARCH
    }
  });

  const { headerAction } = filtersControl;
  const petsToolBarButtonsAll = petTypesOptions.map(petType => {
    const petTypeCountName = `${petType.value.toUpperCase()}_count` as keyof typeof petTypesCount;
    const count = petTypesCount?.[petTypeCountName] || 0;
    return {
      name: `${petType.label} (${count})`,
      id: petType.value
    };
  });
  const petToolBarButtons = !mobile ? petsToolBarButtonsAll : [];
  const isFiltered = filtersControl?.selectedFiltersItems?.length > 0;

  return (
    <>
      {!mobile && (
        <ToolBar
          toolBarButtons={petToolBarButtons}
          urlSearchParam={'pet_type'}
          onSearchChange={onChange}
          middleAction={
            <>
              {headerAction}
              {middleHeaderAction}
            </>
          }
          displayAll
          selectOnlyOne
        />
      )}
      {mobile && <SearchBar onSearch={onChange} showAlways />}
      {showFilters && !isFiltered && !mobile && (
        <TabsHeaderContainer padding="12px 20px">
          <PetTagsQuickFilter filtersControl={filtersControl} SelectedTab={selectedTab} />
        </TabsHeaderContainer>
      )}
      {!mobile && showFilters && isFiltered && (
        <>
          <FlexRow width="100%" gap={10} padding="0 0 0 20px">
            <RecordBody width="60" flex="unset" fontSize={14} fontWeight="700">
              Filter by
            </RecordBody>
            <VerticalDivider />
            <Filters filtersControl={filtersControl} />
          </FlexRow>
          <Divider />
        </>
      )}
    </>
  );
};

export default PetsHeader;
