import React from 'react';
import InifiniteList, { InfiniteListGroup } from '../../components/Shared/InfiniteList/InfiniteList';
import { UsePagintaedQueryResult } from '../../hooks/usePaginatedQuery';
import SubscriptionRecord from './SubscriptionRecord';
import { Subscription } from './types';
import { ListCount } from '../../components/Shared/Forms/Forms';

const SubscriptionsList = ({
  paginatedQueryResult,
  group,
  reverseItems,
  showTotal
}: {
  paginatedQueryResult: UsePagintaedQueryResult<{ views: Subscription[]; count: number }>;
  group?: (list: Subscription[]) => InfiniteListGroup;
  reverseItems?: boolean;
  showTotal?: {
    orderTotal?: boolean;
    dateFilter?: { from: string; to: string };
  };
}) => {
  const [[hasMoreItems, setHasMoreItems], queryResult] = paginatedQueryResult;

  const { data: { getBranchOrderSubscriptionsViews: subscriptions } = {}, loading, fetchMore } = queryResult;

  return (
    <>
      {!!subscriptions?.count && <ListCount>{subscriptions?.count || 0} Memberships</ListCount>}
      <InifiniteList
        paddingL={20}
        paddingR={20}
        list={subscriptions?.views}
        itemRenderer={(subscription: Subscription) => <SubscriptionRecord key={subscription?.id} subscription={subscription} showTotal={showTotal} />}
        hasMoreItems={hasMoreItems}
        loading={loading}
        fetchMore={fetchMore as any}
        offset={subscriptions?.views?.length}
        setHasMoreItems={setHasMoreItems}
        group={group?.(subscriptions?.views)}
        reversedItems={reverseItems}
      />
    </>
  );
};

export default SubscriptionsList;
