import styled, { css } from 'styled-components';
import { InputBarArea } from '../../ChatDrawer/styled';

export const BranchNotesContainer = styled.div`
  padding: 0px 0px 8px 0px
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
`;

export const BranchNoteHeader = styled.div<{ isExpanded: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 20px;
  margin-bottom: ${props => (props.isExpanded ? 8 : 0)}px;
  transition: margin-bottom 0.3s ease-in-out;
`;

export const BranchNoteHeaderInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const ExpandButton = styled.div<{ isExpanded: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  transform: ${props => (!props.isExpanded ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
`;

export const BranchNoteTitle = styled.span`
  font-size: 16px;
  font-weight: 700;
`;

export const BranchNoteSubtitle = styled.span`
  font-size: 12px;
  font-weight: 700;
  color: #000;
`;

export const BranchNoteBodyContainer = styled.div<{ isExpanded: boolean }>`
  display: grid;
  grid-template-rows: ${props => (props.isExpanded ? '1fr' : '0fr')};
  width: 100%;
  padding: ${props => (props.isExpanded ? '8px 0 0 0' : '0px')};
  transition: grid-template-rows 0.3s ease-in-out, padding 0.3s ease-in-out;
`;

export const BranchNoteHoverActions = styled.div<{ right?: number; top?: number }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 0;
  visibility: hidden;
  position: absolute;
  right: ${props => props.right ?? 10}px;
  top: ${props => props.top ?? 20}px;
`;

export const BranchNoteBody = styled.div`
  width: 100%;
  background-color: #f5f5f5;
  padding: 12px 10px;
  border-radius: 6px;
  box-sizing: border-box;
  overflow: hidden;
  grid-row: 1;
  position: relative;
  &:hover {
    ${BranchNoteHoverActions} {
      visibility: visible;
    }
  }
`;

export const BranchNoteBodyTextArea = styled(InputBarArea)`
  padding: 0px;
  background-color: #f5f5f5;
  width: 100%;
  border: none;
  &:focus {
    box-shadow: none;
  }
`;

export const BranchNoteFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 20px;
  padding: 8px 0px;
`;

export const BranchNoteButton = styled.button`
  background-color: #f0f0f0;
  border: none;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
`;

export const BranchNoteTimestamp = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: #a0a0a0;
`;

export const BranchNoteBusUserName = styled.span`
  font-size: 10px;
  font-weight: 400;
  color: #828282;
`;

export const BranchNotesActions = styled.div`
  display: flex;
`;

export const BranchNotePrivateTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 4px 8px;
  color: #fff;
  background-color: #ee1c73;
  margin-right: 8px;
  margin-left: auto;
`;

export const MarkupEditorContainer = styled.div<{ noMargin?: boolean }>`
  margin-top: 8px;
  ${props =>
    props.noMargin &&
    css`
      margin: 0;
    `}
`;

export const MarkupEditorWrapper = styled.div<{ visible: boolean }>`
  ${props =>
    props.visible
      ? css`
          visibility: visible;
          height: auto;
          overflow: visible;
          opacity: 1;
        `
      : css`
          visibility: hidden;
          height: 0;
          overflow: hidden;
          opacity: 0;
        `}
`;
