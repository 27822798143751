import { useQuery } from '@apollo/client';
import React from 'react';
import { sentenceCase } from 'sentence-case';
import { CounterBtn } from '../../../components/Calendar/styled';
import { DRAWER_IDS } from '../../../components/DrawerBar/types';
import ModalDialog from '../../../components/Modal/ModalDialog';
import { Pet, PetBehavior } from '../../../components/Pets/types';
import useIcons from '../../../hooks/useIcons';
import { GetBranchPetProfile, GetPetBehaviors } from '../../../queries';
import { vars } from '../../../reactive';
import {
  PetRecordEntitiesContainer,
  PetRecordEntity,
  PetRecordEntityLabel,
  PetRecordEntitySubLabel,
  PetRecordRecordsOptions,
  SectionContainer,
  SectionWrapper,
  SectionsContainer,
  VetDaycareTitle
} from '../Health/styled';
import BahaviorPetRecordModal from './BehaviorModal';
import RoutinePetRecordModal from './RoutineModal';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { MenuButton } from '../../../components/Shared/Menus/OptionDropdown/styled';

const BehaviorRoutine = () => {
  const { mobile } = useMediaQuery({ mobile: true });
  const petId = vars.drawerBars().find(drawer => drawer.drawerId === DRAWER_IDS.PETS_DRAWER)?.recordData;
  const { data: { getBranchPetProfile: profile } = {}, loading } = useQuery<{ getBranchPetProfile: Pet }>(GetBranchPetProfile, {
    variables: { id: petId },
    fetchPolicy: 'cache-only',
    skip: !petId
  });
  const { data: { getPetBehaviors: [petBehavior] = [] } = {}, error } = useQuery(GetPetBehaviors, {
    variables: { PetRecordId: [profile?.PetRecord?.id] },
    fetchPolicy: 'cache-and-network',
    skip: !profile?.PetRecord?.id
  });

  const petBehaviorAroundSameType = petBehavior?.behavior_around_same_type;
  const petBehaviorAroundOtherPets = petBehavior?.behavior_around_other_pets;
  const petBehaviorAroundChildren = petBehavior?.behavior_around_children;
  const petBehaviorAroundAdults = petBehavior?.behavior_around_adults;
  const petBehaviorNegative = petBehavior?.behavior_negative;
  const petBehaviorPositive = petBehavior?.behavior_positive;
  const petSleepingPlace = petBehavior?.sleep_place;
  const petFavoriteTreats = petBehavior?.favorite_treats;
  const petFavoriteActivitie = petBehavior?.favorite_activities;
  const petWalkingPlaces = petBehavior?.walking_places;
  const petDailyRoutine = petBehavior?.daily_routine;
  const petLeftAloneDuration = petBehavior?.left_alone_duration;

  const behaviorDataSections = [
    [
      { label: `Behavior around ${sentenceCase(profile?.type)}s`, subLabel: petBehaviorAroundSameType },
      { label: 'Behavior around other Pets', subLabel: petBehaviorAroundOtherPets },
      { label: 'Behavior around Children', subLabel: petBehaviorAroundChildren },
      { label: 'Behavior around Adults', subLabel: petBehaviorAroundAdults }
    ],
    [
      { label: 'Negative Behavior', subLabel: petBehaviorNegative },
      { label: 'Positive Behavior', subLabel: petBehaviorPositive }
    ]
  ];

  const routineDataSections = [
    [
      { label: 'Favourite Activities', subLabel: petFavoriteActivitie },
      { label: 'Favourite Treat', subLabel: petFavoriteTreats },
      { label: 'Sleeping Place', subLabel: petSleepingPlace },
      { label: 'Walking Place', subLabel: petWalkingPlaces }
    ],
    [
      { label: 'Can be left alone for', subLabel: petLeftAloneDuration },
      { label: 'Daily routine', subLabel: petDailyRoutine }
    ]
  ];
  return (
    <SectionsContainer>
      <SectionContainer>
        <PetRecordEntitiesContainer column>
          <VetDaycareTitle>
            Behavior
            <EditPetRecordBehavior petBehavior={petBehavior} />
          </VetDaycareTitle>

          <PetRecordEntitiesContainer noPadding noBorderRadius mobilePadding="0" column={mobile}>
            {behaviorDataSections.map((section, index) => (
              <SectionWrapper key={index}>
                {section.map((item, i) => (
                  <PetRecordEntity key={i}>
                    <PetRecordEntitySubLabel>{item.label}</PetRecordEntitySubLabel>
                    <PetRecordEntityLabel>{item.subLabel ? sentenceCase(item.subLabel) : '-'}</PetRecordEntityLabel>
                  </PetRecordEntity>
                ))}
              </SectionWrapper>
            ))}
          </PetRecordEntitiesContainer>
        </PetRecordEntitiesContainer>
      </SectionContainer>

      <SectionContainer>
        <PetRecordEntitiesContainer column>
          <PetRecordRecordsOptions>
            <VetDaycareTitle>
              Routine
              <EditPetRecordRoutine petBehavior={petBehavior} />
            </VetDaycareTitle>
          </PetRecordRecordsOptions>
          <PetRecordEntitiesContainer noPadding noBorderRadius mobilePadding="0" column={mobile}>
            {routineDataSections.map((section, index) => (
              <SectionWrapper key={index}>
                {section.map((item, i) => (
                  <PetRecordEntity key={i}>
                    <PetRecordEntitySubLabel>{item.label}</PetRecordEntitySubLabel>
                    <PetRecordEntityLabel>{item.subLabel ? item.subLabel : '-'}</PetRecordEntityLabel>
                  </PetRecordEntity>
                ))}
              </SectionWrapper>
            ))}
          </PetRecordEntitiesContainer>
        </PetRecordEntitiesContainer>
      </SectionContainer>
    </SectionsContainer>
  );
};

export default BehaviorRoutine;

const EditBtn: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  const icons = useIcons();
  const moreIcon = icons?.moreIconSVG?.publicURL;
  return <MenuButton bgImage={moreIcon} onClick={onClick} width="28px" height="28px" mobileDisplayIcon />;
};

export const EditPetRecordBehavior = ({ petBehavior }: { petBehavior: PetBehavior }) => {
  return (
    <EditBtn
      onClick={() =>
        ModalDialog.openModal({
          content: () => <BahaviorPetRecordModal petBehavior={petBehavior} />,
          title: 'Pet Behaviors'
        })
      }
    />
  );
};

export const EditPetRecordRoutine = ({ petBehavior }: { petBehavior: PetBehavior }) => {
  return (
    <EditBtn
      onClick={() =>
        ModalDialog.openModal({
          content: () => <RoutinePetRecordModal petBehavior={petBehavior} />,
          title: 'Pet Routines'
        })
      }
    />
  );
};
