import { BranchAppUser } from '../../views/Store/BranchBilling/types';
import { BranchAppUserTag } from '../../views/Store/BranchTags/types';
import Alert from '../Alert/Alert';

const checkIfTagAtCapacity = (tag: BranchAppUserTag, appUsers: BranchAppUser[]) => {
  const capcity = Number(tag.capacity);
  const newTagCount = Number(tag.appUsersCount || Infinity) + appUsers?.length;
  return capcity !== 0 && capcity < newTagCount;
};

export const checkAddAppUserTagCapacity = ({ tags, handleAddTag, appUsers }: { tags: BranchAppUserTag[]; handleAddTag: (tags: BranchAppUserTag[]) => void; appUsers: BranchAppUser[] }) => {
  if (tags.every(tag => !checkIfTagAtCapacity(tag, appUsers))) {
    handleAddTag(tags);
    return;
  }

  const description =
    tags?.length > 1
      ? 'One or more of the tags you selected is at capacity. Are you sure you want to add this appUser?'
      : `${tags[0].name} is at capacity of ${tags[0].capacity}. Are you sure you want to add this appUser?`;

  Alert.alert({
    title: 'Add Tag',
    description: description,
    acceptButtonText: 'Yes',
    denyButtonText: 'No',
    onAccept: () => handleAddTag(tags)
  });
};
