import { petsDataHandler, petsReportDataHandler } from './dataHandlers';
import { handlePetsLink, handlePetsReportLink } from './handlers';
import { PetsReportsModal } from './Modals';
import { petsReportsNavigator } from './navigators';

type LinkItems = {
  link: string;
  handler: (dynamicLink: string) => Promise<any>;
  dataHandler: (dynamicLink: DynamicLink) => Promise<DynamicLink>;
  title: string;
  navigator: (dynamicLink: DynamicLink) => void;
  Modal: React.FC<{
    dynamicLink: DynamicLink;
  }>;
};

export type DynamicLink = {
  link: string;
  linkType: DYNAMIC_LINKS_TYPES;
  linkItems: (LinkItems & { type: DYNAMIC_LINKS_TYPES; deepLinkTypes: ({ type: DEEP_DYNAMIC_LINKS } & LinkItems)[] }) | ({ type: DEEP_DYNAMIC_LINKS } & LinkItems);
  deepLinkType: DEEP_DYNAMIC_LINKS | null;
  isDeepLink: boolean;
  title: string;
  data: Record<string, any>;
  isDynamic: boolean;
  loadModal: boolean;
  handleClick: () => void;
  handleClose: () => void;
  externalLink: string;
  isEmail: boolean;
};

export enum DYNAMIC_LINKS_TYPES {
  PETS = 'PETS'
}

export enum DEEP_DYNAMIC_LINKS {
  REPORTS = 'REPORTS'
}

const DYNAMIC_LINKS_LINKS: Record<DYNAMIC_LINKS_TYPES, string> = {
  [DYNAMIC_LINKS_TYPES.PETS]: 'pets'
};

const DYNAMIC_LINKS_HANDLERS: Record<DYNAMIC_LINKS_TYPES, (dynamicLink: string) => Promise<any>> = {
  [DYNAMIC_LINKS_TYPES.PETS]: handlePetsLink
};

const DYNAMIC_LINKS_DATA_HANDLERS: Record<DYNAMIC_LINKS_TYPES, (dynamicLink: DynamicLink) => Promise<any>> = {
  [DYNAMIC_LINKS_TYPES.PETS]: petsDataHandler
};

const DYNAMIC_LINKS_TITLES: Record<DYNAMIC_LINKS_TYPES, string> = {
  [DYNAMIC_LINKS_TYPES.PETS]: 'Pets'
};

const DYANMIC_LINKS_NAVIGATORS: Record<DYNAMIC_LINKS_TYPES, (dynamicLink: DynamicLink) => void> = {
  [DYNAMIC_LINKS_TYPES.PETS]: () => {}
};

const DYNAMIC_LINKS_MODALS: Record<
  DYNAMIC_LINKS_TYPES,
  React.FC<{
    dynamicLink: DynamicLink;
  }>
> = {
  [DYNAMIC_LINKS_TYPES.PETS]: () => null
};

const DEEP_DYNAMIC_LINKS_LINKS: Record<DEEP_DYNAMIC_LINKS, string> = {
  [DEEP_DYNAMIC_LINKS.REPORTS]: 'reports'
};

const DEEP_DYNAMIC_LINKS_HANDLERS: Record<DEEP_DYNAMIC_LINKS, (dynamicLink: string) => Promise<any>> = {
  [DEEP_DYNAMIC_LINKS.REPORTS]: handlePetsReportLink
};

const DEEP_DYNAMIC_LINKS_DATA_HANDLERS: Record<DEEP_DYNAMIC_LINKS, (dynamicLink: DynamicLink) => Promise<any>> = {
  [DEEP_DYNAMIC_LINKS.REPORTS]: petsReportDataHandler
};

const DEEP_DYNAMIC_LINKS_TITLES: Record<DEEP_DYNAMIC_LINKS, string> = {
  [DEEP_DYNAMIC_LINKS.REPORTS]: "{{petName}}'s Report"
};

const DEEP_DYNAMIC_LINKS_NAVIGATORS: Record<DEEP_DYNAMIC_LINKS, (dynamicLink: DynamicLink) => void> = {
  [DEEP_DYNAMIC_LINKS.REPORTS]: petsReportsNavigator
};

const DEEP_DYNAMIC_LINKS_MODALS: Record<
  DEEP_DYNAMIC_LINKS,
  React.FC<{
    dynamicLink: DynamicLink;
  }>
> = {
  [DEEP_DYNAMIC_LINKS.REPORTS]: PetsReportsModal
};

export const DYNAMIC_LINKS_DEEP: Record<DYNAMIC_LINKS_TYPES, DEEP_DYNAMIC_LINKS[]> = {
  [DYNAMIC_LINKS_TYPES.PETS]: [DEEP_DYNAMIC_LINKS.REPORTS]
};

export const dynamicLinksHandlers: (LinkItems & { type: DYNAMIC_LINKS_TYPES; deepLinkTypes: ({ type: DEEP_DYNAMIC_LINKS } & LinkItems)[] })[] = Object.values(DYNAMIC_LINKS_TYPES).map(
  dynamicLinkType => ({
    type: dynamicLinkType,
    link: DYNAMIC_LINKS_LINKS[dynamicLinkType],
    handler: DYNAMIC_LINKS_HANDLERS[dynamicLinkType],
    dataHandler: DYNAMIC_LINKS_DATA_HANDLERS[dynamicLinkType],
    title: DYNAMIC_LINKS_TITLES[dynamicLinkType],
    navigator: DYANMIC_LINKS_NAVIGATORS[dynamicLinkType],
    Modal: DYNAMIC_LINKS_MODALS[dynamicLinkType],
    deepLinkTypes: DYNAMIC_LINKS_DEEP[dynamicLinkType].map(deepLinkType => ({
      type: deepLinkType,
      link: DEEP_DYNAMIC_LINKS_LINKS[deepLinkType],
      handler: DEEP_DYNAMIC_LINKS_HANDLERS[deepLinkType],
      dataHandler: DEEP_DYNAMIC_LINKS_DATA_HANDLERS[deepLinkType],
      title: DEEP_DYNAMIC_LINKS_TITLES[deepLinkType],
      navigator: DEEP_DYNAMIC_LINKS_NAVIGATORS[deepLinkType],
      Modal: DEEP_DYNAMIC_LINKS_MODALS[deepLinkType]
    }))
  })
);
