import React from 'react';
import ToolBar from '../../../components/Shared/ToolBar/ToolBar';
import useURLSearchParams from '../../../hooks/useURLSearchParams';
import BranchAppUserTags from './BranchAppUserTags';
import BranchAppointmentTags from './BranchAppointmentTags';
import BranchPetRecordTags from './BranchPetRecordTags';
import BranchTagsAll from './BranchTagsAll';
import { BranchTagsHeaderContainer } from './styled';
import { BRANCH_TAGS_TABS_TYPES } from './types';
import { MenuButton } from '../../../components/Shared/Menus/OptionDropdown/styled';
import useIcons from '../../../hooks/useIcons';
import ModalDialog from '../../../components/Modal/ModalDialog';
import BranchTagsModal from './BranchTagsModal';

const BranchTags = () => {
  const icons = useIcons();
  const handleClick = () => {
    ModalDialog.openModal({
      title: 'Add Tag',
      content: () => <BranchTagsModal />
    });
  };
  const blackPlusIcon = icons?.plusBlackSVG?.publicURL;
  const toolBarButtons = [
    {
      name: BRANCH_TAGS_TABS_TYPES.PETS,
      id: BRANCH_TAGS_TABS_TYPES.PETS,
      component: BranchPetRecordTags
    },
    {
      name: BRANCH_TAGS_TABS_TYPES.BOOKINGS,
      id: BRANCH_TAGS_TABS_TYPES.BOOKINGS,
      component: BranchAppointmentTags
    },
    {
      name: BRANCH_TAGS_TABS_TYPES.APP_USERS,
      id: BRANCH_TAGS_TABS_TYPES.APP_USERS,
      component: BranchAppUserTags
    }
  ];

  const [ids] = useURLSearchParams('status');
  let TagsToReturn;
  const tagsStatus = [BRANCH_TAGS_TABS_TYPES.PETS, BRANCH_TAGS_TABS_TYPES.BOOKINGS, BRANCH_TAGS_TABS_TYPES.APP_USERS];
  const selectedTagsStatuses = [...(ids || [])].flat()?.filter(id => tagsStatus?.includes(id));

  switch (true) {
    case selectedTagsStatuses?.includes(BRANCH_TAGS_TABS_TYPES.PETS):
      TagsToReturn = <BranchPetRecordTags />;

      break;

    case selectedTagsStatuses?.includes(BRANCH_TAGS_TABS_TYPES.BOOKINGS):
      TagsToReturn = <BranchAppointmentTags />;

      break;
    case selectedTagsStatuses?.includes(BRANCH_TAGS_TABS_TYPES.APP_USERS):
      TagsToReturn = <BranchAppUserTags />;
      break;

    default:
      TagsToReturn = <BranchTagsAll />;
      break;
  }

  return (
    <>
      <BranchTagsHeaderContainer>
        <ToolBar toolBarButtons={toolBarButtons} urlSearchParam="status" selectOnlyOne middleAction={<MenuButton width="28px" height="28px" bgImage={blackPlusIcon} onClick={handleClick} />} />
      </BranchTagsHeaderContainer>
      {TagsToReturn}
    </>
  );
};

export default BranchTags;
