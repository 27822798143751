import { BranchPetRecordTag } from '../../views/Store/BranchTags/types';
import Alert from '../Alert/Alert';
import { Pet } from './types';

const checkIfTagAtCapacity = (tag: BranchPetRecordTag, pets: Pet[]) => {
  const capcity = Number(tag.capacity);
  const newTagCount = Number(tag.petRecordsCount || Infinity) + pets?.length;
  return capcity !== 0 && capcity < newTagCount;
};

export const checkAddPetTagCapacity = ({ tags, handleAddTag, pets }: { tags: BranchPetRecordTag[]; handleAddTag: (tags: BranchPetRecordTag[]) => void; pets: Pet[] }) => {
  if (tags.every(tag => !checkIfTagAtCapacity(tag, pets))) {
    handleAddTag(tags);
    return;
  }

  const description =
    tags?.length > 1
      ? 'One or more of the tags you selected is at capacity. Are you sure you want to add this pet?'
      : `${tags[0].name} is at capacity of ${tags[0].capacity}. Are you sure you want to add this pet?`;

  Alert.alert({
    title: 'Add Tag',
    description: description,
    acceptButtonText: 'Yes',
    denyButtonText: 'No',
    onAccept: () => handleAddTag(tags)
  });
};
