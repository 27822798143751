import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import ReactSelect from '../../../components/Shared/Forms/Select';
import RRule from 'rrule';
import ModalDialog, { ModalLayout } from '../../../components/Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../../../components/Modal/styled';
import { Pet } from '../../../components/Pets/types';
import { FormButtonsContainer, FormError, FormInput, FormLabel, FormSubmitButton, FormTextArea, InputsWrapper, WideInputGroup, selectTheme } from '../../../components/Shared/Forms/Forms';
import { EditBranchPetRecordMeals, GetBranchCategoriesAndMealPortions, GetPetRecordMeals } from '../../../queries';
import { toMealReadableText } from '../../../utils/dates';
import { Container, Divider } from './styled';
import Select from '../../../components/Shared/Forms/Select';
import { features } from 'process';

type MealsFormState = {
  weight: number;
  mealsCount: number;
  meals: { name: string; quantity: string; time: string; unit: string; BranchCategoryId: string }[];
};

const MAX_MEALS_COUNT = 4;

const validateTime = (val: string) => Number(val.split(':')[0]) < 24 && Number(val.split(':')[1]) < 60;

const units = [
  { id: 'g', name: 'Grams' },
  { id: 'oz', name: 'Ounces' },
  { id: 'cups', name: 'Cups' },
  { id: 'serving', name: 'Serving' }
];

const HealthEditPetRecordMealsModal = ({ pet, options }: { pet: Pet; options: { includeWeight: boolean } }) => {
  const { data: { getBranchPetProfile: petProfile } = {} } = useQuery<{
    getBranchPetProfile: Pet;
  }>(GetPetRecordMeals, {
    variables: { id: pet?.id },
    skip: !pet?.id,
    fetchPolicy: 'cache-and-network'
  });

  const { data: { branchCategoryGet: branchCategories = [] } = {} } = useQuery<{
    branchCategoryGet: { id: string; name: string }[];
    mealPortionGet: { id: string; name: string; age: number }[];
  }>(GetBranchCategoriesAndMealPortions, {
    variables: { petType: petProfile?.type },
    skip: !petProfile?.type
  });

  const formOptions = useForm<MealsFormState>({
    defaultValues: {
      weight: pet?.PetRecord?.measurements?.weights?.slice(-1)?.[0]?.weight || 0,
      mealsCount: pet?.PetRecord?.Meals?.length || 1,
      meals: pet?.PetRecord?.Meals?.length
        ? pet?.PetRecord?.Meals?.map(meal => ({
            name: meal.name,
            quantity: meal.quantity,
            time: toMealReadableText(meal.time),
            unit: meal.unit,
            BranchCategoryId: meal.BranchCategoryId
          }))
        : [
            {
              name: '',
              quantity: '',
              time: '',
              unit: '',
              BranchCategoryId: branchCategories[0]?.id || ''
            }
          ]
    },
    shouldUnregister: false
  });
  const { handleSubmit, control, watch, errors, setValue } = formOptions;

  const watchedItems = watch();

  const mealsFieldArray = useFieldArray<MealsFormState['meals'][0], 'id'>({
    control,
    name: 'meals',
    keyName: 'id'
  });

  const onChangeMealsCount = (newCount: number) => {
    const newMeals = Array.from({ length: newCount }).map((_, index) => {
      if (watchedItems.meals?.[index]) {
        return watchedItems.meals[index];
      }

      return {
        name: '',
        quantity: '',
        time: '',
        unit: '',
        BranchCategoryId: branchCategories[0]?.id || ''
      };
    });
    setValue('meals', newMeals);
  };

  const [editMeals, { data, loading, error }] = useMutation(EditBranchPetRecordMeals, {
    refetchQueries: ['getBranchPetProfile'],
    awaitRefetchQueries: true
  });

  const onSubmit = handleSubmit(data => {
    editMeals({
      variables: {
        meals: data.meals.map(meal => ({
          quantity: Number(meal.quantity).toString(),
          unit: meal.unit,
          name: meal.name,
          PetRecordId: petProfile?.PetRecord?.id,
          time: RRule.optionsToString({
            byhour: Number(meal.time.split(':')[0]),
            byminute: Number(meal.time.split(':')[1])
          }),
          BranchCategoryId: meal.BranchCategoryId
        })),
        ...(options.includeWeight ? { weight: String(Number(data.weight)) } : {}),
        categories: [...new Set(data.meals.map(meal => meal.BranchCategoryId).filter(Boolean))]
      }
    });
  });

  useEffect(() => {
    if (data?.editBranchPetRecordMeals) {
      ModalDialog.closeModal();
    }
  }, [data?.editBranchPetRecordMeals]);

  return (
    <ModalLayout
      compact
      buttons={[
        <FormSubmitButton error={!!error?.message} loading={loading} onClick={onSubmit}>
          Update
        </FormSubmitButton>
      ]}
    >
      <>
        {options.includeWeight && (
          <WideInputGroup>
            <FormLabel>Weight</FormLabel>
            <Controller
              name="weight"
              control={control}
              render={({ onChange, value }) => <FormInput type="number" value={value} onChange={e => onChange(Number(e.target.value))} />}
              rules={{ required: true, pattern: /^[0-9]+$/, min: 1, max: 1000 }}
            />

            {errors?.weight && <FormError>{errors?.weight?.message || 'This field is required'}</FormError>}
          </WideInputGroup>
        )}
        <WideInputGroup>
          <FormLabel>Meals Count</FormLabel>
          <Controller
            name="mealsCount"
            control={control}
            render={({ onChange, value }) => (
              <Select
                theme={selectTheme}
                options={Array.from({ length: MAX_MEALS_COUNT }).map((_, index) => ({ value: index + 1, label: `${index + 1} Times` }))}
                onChange={selected => {
                  onChange(selected?.value);
                  onChangeMealsCount(selected?.value);
                }}
                value={{ value, label: `${value} Times` }}
              />
            )}
            rules={{ required: true }}
          />

          {errors?.mealsCount && <FormError>{errors?.mealsCount?.message || 'This field is required'}</FormError>}
        </WideInputGroup>
        {mealsFieldArray.fields.map((meal, index) => (
          <WideInputGroup key={meal.id}>
            <FormLabel>Meal {index + 1}</FormLabel>
            <WideInputGroup>
              <WideInputGroup>
                <FormLabel>Brand Name</FormLabel>
                <Controller
                  name={`meals[${index}].name`}
                  control={control}
                  render={({ onChange, value }) => <FormInput value={value} onChange={e => onChange(e.target.value)} placeholder="Brand Name" />}
                  defaultValue={meal.name}
                  rules={{ required: true }}
                />

                {errors?.meals?.[index]?.name && <FormError>{errors?.meals?.[index]?.name?.message || 'This field is required'}</FormError>}
              </WideInputGroup>
              <WideInputGroup>
                <FormLabel>Diet Type</FormLabel>
                <Controller
                  name={`meals[${index}].BranchCategoryId`}
                  control={control}
                  render={({ onChange, value }) => (
                    <>
                      <Select
                        theme={selectTheme}
                        options={branchCategories.map(({ id, name }) => ({ value: id, label: name }))}
                        onChange={selected => onChange(selected?.value)}
                        value={{ value, label: branchCategories.find(({ id }) => id === value)?.name }}
                      />
                    </>
                  )}
                  rules={{ required: true }}
                />
                {errors?.meals?.[index]?.BranchCategoryId && <FormError>{errors?.meals?.[index]?.BranchCategoryId?.message || 'This field is required'}</FormError>}
              </WideInputGroup>
              <WideInputGroup>
                <FormLabel>Quantity</FormLabel>
                <Controller
                  name={`meals[${index}].quantity`}
                  control={control}
                  render={({ onChange, value }) => <FormInput type="number" value={value} onChange={e => onChange(e.target.value)} />}
                  defaultValue={meal.quantity}
                  rules={{
                    required: true,
                    pattern: {
                      value: /^[0-9]+(\.[0-9]+)?$/,
                      message: 'Invalid number'
                    },
                    min: {
                      value: 0.1,
                      message: 'Minimum value is 0.1'
                    }
                  }}
                />
                {errors?.meals?.[index]?.quantity && <FormError>{errors?.meals?.[index]?.quantity?.message || 'This field is required'}</FormError>}
              </WideInputGroup>
              <WideInputGroup>
                <FormLabel>Time</FormLabel>
                <Controller
                  name={`meals[${index}].time`}
                  control={control}
                  render={({ onChange, value }) => <FormInput type="time" value={value} onChange={e => onChange(e.target.value)} />}
                  defaultValue={meal.time}
                  rules={{ required: true, validate: validateTime }}
                />
                {errors?.meals?.[index]?.time && <FormError>{errors?.meals?.[index]?.time?.message || 'This field is required'}</FormError>}
              </WideInputGroup>

              <WideInputGroup>
                <FormLabel>Unit</FormLabel>
                <Controller
                  name={`meals[${index}].unit`}
                  control={control}
                  render={({ onChange, value }) => (
                    <Select
                      theme={selectTheme}
                      options={units.map(({ id, name }) => ({ value: id, label: name }))}
                      onChange={selected => onChange(selected?.value)}
                      value={{ value, label: units.find(({ id }) => id === value)?.name }}
                    />
                  )}
                  defaultValue={meal.unit}
                  rules={{ required: true }}
                />
                {errors?.meals?.[index]?.unit && <FormError>{errors?.meals?.[index]?.unit?.message || 'This field is required'}</FormError>}
              </WideInputGroup>
            </WideInputGroup>
            {index !== mealsFieldArray.fields.length - 1 && <Divider />}
          </WideInputGroup>
        ))}
      </>
    </ModalLayout>
  );
};

export default HealthEditPetRecordMealsModal;
