import styled, { css } from 'styled-components';
import Colors from '../../../Colors';

export const CustomizationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  max-width: 600px;
  margin-bottom: 20px;
`;

export const CustomizationsFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CustomizationsFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${Colors.lightGrey};
  border-radius: 10px;
`;

export const CustomizationFormOptionsWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const CustomizationFormOptionsContainer = styled.div<{ column?: boolean; isTitle?: boolean; isExpanded?: boolean; gap?: number; alternate?: boolean }>`
  display: flex;
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
  width: 100%;
  flex: 1;
  gap: ${({ gap }) => gap}px;
  ${({ alternate }) =>
    alternate &&
    css`
      padding: 10px;
      &:nth-child(odd) {
        background-color: ${Colors.lightGrey2};
      }
      &:nth-child(even) {
        background-color: ${Colors.lightGrey};
      }
    `}

  ${({ isTitle }) => {
    if (isTitle) {
      return css`
        height: 20px;
        cursor: pointer;
        background-color: ${Colors.lightGrey2};
        padding: 10px;
      `;
    }
  }}
`;

export const CustomizationFormOptionsBodyWrapper = styled.div<{ backgroundColor?: string }>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  overflow: hidden;
  background-color: ${props => props.backgroundColor || Colors.white};
`;

export const CustomizationFormOptionsBodyContainer = styled.div<{ isExpanded?: boolean; backgroundColor?: string }>`
  display: grid;
  grid-template-rows: ${props => (props.isExpanded ? '1fr' : '0fr')};
  width: 100%;
  padding: ${props => (props.isExpanded ? '10px' : '0 10px')};
  background-color: ${props => props.backgroundColor || Colors.white};
  transition: grid-template-rows 0.3s ease-in-out, padding-top 0.3s ease-in-out;
`;

export const CustomizationInputContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const CenteredCustomizationInputContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
`;

export const CustomizationTypeContainer = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`;
