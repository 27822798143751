import React, { FC, useCallback, Fragment, useEffect } from 'react';
import { Pet, SuppRecord as SuppRecordType, Adherence } from '../../../../components/Pets/types';
import { EditSuppRecord, GetAllPetRecordRecordsSuppRecords } from '../../../../queries';
import { useMutation, useQuery } from '@apollo/client';
import { Controller, UseFormMethods, useForm } from 'react-hook-form';
import { ModalBody, ModalFooter } from '../../../../components/Modal/styled';
import { Container } from '../../../../components/Shared/Shared';
import { CenteredLoader } from '../../../../components/Shared/Spinner';
import { FormButtonsContainer, FormCheckbox, FormLabel, FormSubmitButton, InputsWrapper, WideInputGroup } from '../../../../components/Shared/Forms/Forms';
import { SuppRecord } from '../../Health/HealthPetRecords';
import Alert from '../../../../components/Alert/Alert';
import ModalDialog, { ModalLayout } from '../../../../components/Modal/ModalDialog';
import { getUniqueSuppRecords } from './helpers';

type EndSuppRecordsModalProps = {
  pet: Pet;
};

type EndSuppRecordsModalFormValues = {
  selectedSuppRecordIds: string[];
};

const EndSuppRecordsModalForm = ({
  formOptions,
  suppRecords,
  loading,
  loadingSubmit,
  onSubmit,
  pet
}: {
  formOptions: UseFormMethods<EndSuppRecordsModalFormValues>;
  suppRecords: SuppRecordType[];
  loading: boolean;
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  loadingSubmit: boolean;
  pet: Pet;
}) => {
  const { control, watch } = formOptions;

  const watchedValues = watch(['selectedSuppRecordIds']);

  const renderSuppRecordItem = useCallback(
    ({ id }: { id: string }) => {
      const suppRecord = suppRecords.filter(({ id: suppRecordId }) => suppRecordId === id)[0];
      return (
        <Fragment key={id}>
          {suppRecord && (
            <SuppRecord
              suppRecord={suppRecord}
              pet={pet}
              style={{
                width: '100%'
              }}
            />
          )}
        </Fragment>
      );
    },
    [suppRecords]
  );

  return (
    <ModalLayout
      compact
      buttons={[
        <FormSubmitButton loading={loadingSubmit} onClick={onSubmit} disabled={!watchedValues.selectedSuppRecordIds?.length}>
          End Cycle
        </FormSubmitButton>
      ]}
    >
      <>
        {loading && <CenteredLoader size={15} />}
        {!loading && (
          <InputsWrapper noWrap>
            <WideInputGroup>
              <FormLabel>Select Treatments</FormLabel>
              <Controller
                render={({ onChange, value }) => (
                  <FormCheckbox
                    onChange={onChange}
                    value={value}
                    itemsArray={suppRecords.map(({ id, Product: { name } }) => ({ id, name }))}
                    fontSize={16}
                    column
                    renderItem={renderSuppRecordItem}
                    reversed
                  />
                )}
                control={control}
                name={'selectedSuppRecordIds'}
                rules={{ required: true, validate: value => value.length }}
                defaultValue={''}
              />
            </WideInputGroup>
          </InputsWrapper>
        )}
      </>
    </ModalLayout>
  );
};

const EndSuppRecordsModal: FC<EndSuppRecordsModalProps> = ({ pet }) => {
  const {
    data: { getPetRecordRecords: { SuppRecords: suppRecords = [], Adherences: adherences = [] } = {} } = {},
    loading: loadingSuppRecords,
    refetch
  } = useQuery<{
    getPetRecordRecords: {
      SuppRecords: SuppRecordType[];
      Adherences: Adherence[];
    };
  }>(GetAllPetRecordRecordsSuppRecords, {
    variables: { PetRecordId: pet.PetRecord.id, filter_by_active: true },
    skip: !pet.PetRecord.id,
    fetchPolicy: 'cache-and-network'
  });

  const loading = loadingSuppRecords;

  const [editSuppRecords, { data: editedSuppRecords, loading: loadingSubmit }] = useMutation(EditSuppRecord);

  const uniqueSupps = getUniqueSuppRecords({ adherences, suppRecords });

  const sortedSuppRecords = uniqueSupps
    ?.sort((a, b) => {
      const aDate = a?.Adherences?.length ? new Date(a?.Adherences?.[0]?.timestamp) : new Date(a?.createdAt);
      const bDate = b?.Adherences?.length ? new Date(b?.Adherences?.[0]?.timestamp) : new Date(b?.createdAt);
      return aDate.getTime() - bDate.getTime();
    })
    ?.filter(({ type }) => type === 'treatment');

  const formOptions = useForm<EndSuppRecordsModalFormValues>({
    defaultValues: {
      selectedSuppRecordIds: []
    }
  });

  const { handleSubmit } = formOptions;

  const onSubmit = handleSubmit(async ({ selectedSuppRecordIds }) => {
    Alert.alert({
      title: 'End Treatment Cycle',
      description: 'This action will end the cycle of this treatment, this action is irreversible. Are you sure you want to proceed?',
      acceptButtonText: 'Yes, end cycle',
      denyButtonText: 'No',
      onAccept: () => {
        editSuppRecords({
          variables: {
            SuppRecordId: selectedSuppRecordIds,
            SuppRecord: {
              end_date: new Date().toISOString(),
              nextdate: null
            }
          }
        });
      }
    });
  });

  useEffect(() => {
    if (editedSuppRecords) {
      refetch();
      ModalDialog.closeModal();
    }
  }, [editedSuppRecords]);

  return <EndSuppRecordsModalForm formOptions={formOptions} suppRecords={sortedSuppRecords} loading={loading} onSubmit={onSubmit} loadingSubmit={loadingSubmit} pet={pet} />;
};

export default EndSuppRecordsModal;
