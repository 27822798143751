import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { Controller, UseFieldArrayMethods, UseFormMethods } from 'react-hook-form';
import Colors from '../../../Colors';
import { Pet } from '../../../components/Pets/types';
import { FormCheckbox, FormError, FormInput, RemoveRowContainer } from '../../../components/Shared/Forms/Forms';
import PetsSearchList from '../../../components/Shared/Lists/PetsSearchList';
import { AddBranchRouteJobType, GetBranchPetsReduced, GetBranchRouteJobTypes } from '../../../queries';
import { DiscountsFormContainer } from '../../Store/Operations/styled';
import { BranchRoutePetContainer } from '../styled';
import { BranchRoute, RouteJobType } from '../types';
import { BranchRouteFormValues, BranchRoutePetRecords } from './types';
import { Divider } from '../../../components/Shared/SideTabs/styles';
import Select from '../../../components/Shared/Forms/Select';
import { RecordBody } from '../../styled';
import { FlexRow } from '../../../components/Shared/Shared';
import { RemoveAddedImage } from '../../Store/styled';
import useIcons from '../../../hooks/useIcons';

const BranchRouteModalPets = ({
  formOptions,
  branchRoute,
  branchRoutePetRecordsFieldArray
}: {
  formOptions: UseFormMethods<BranchRouteFormValues>;
  branchRoute?: BranchRoute;
  branchRoutePetRecordsFieldArray: UseFieldArrayMethods<BranchRoutePetRecords, 'id'>;
}) => {
  const { control, errors, watch, setValue } = formOptions;
  const { branchRoute: { BranchRoutePetRecords: watchedBranchRoutePetRecords = [] } = {} } = watch(['branchRoute']);

  const addedPets = watchedBranchRoutePetRecords.map(pet => pet.PetRecordId);

  const {
    data: { getBranchRouteJobTypes: routeJobTypes = [] } = {},
    loading: loadingRouteJobTypes,
    called: calledRouteJobTypes
  } = useQuery<{ getBranchRouteJobTypes: RouteJobType[] }>(GetBranchRouteJobTypes, {
    fetchPolicy: 'cache-and-network'
  });

  const routeJobOptions = routeJobTypes.map(routeJobType => ({ value: routeJobType.id, label: routeJobType.name }));

  const [addBranchJobType, { loading: loadingAddBranchRouteJobType }] = useMutation(AddBranchRouteJobType, {
    refetchQueries: ['getBranchRouteJobTypes'],
    awaitRefetchQueries: true
  });

  const selectedPetsDataRef = React.useRef<Pet[]>([]);
  const selectedPetsData = selectedPetsDataRef.current;
  const { data: { getBranchPets: updatedPetsData } = {} } = useQuery<{ getBranchPets: Pet[] }>(GetBranchPetsReduced, {
    variables: {
      petRecord_id: addedPets,
      requisite_queries: ['petRecord_id'],
      alternative_queries: [],
      daycare_filter: false,
      vaccination_filter: false,
      treatment_filter: false,
      weight_filter: false,
      neutering_filter: false,
      age_filter: false,
      postcode_filter: true,
      tags_filter: true
    },
    fetchPolicy: 'cache-first',
    skip: !addedPets.length
  });

  const handleRemoveRow = (index: number) => {
    branchRoutePetRecordsFieldArray.remove(index);
  };

  const icons = useIcons();

  useEffect(() => {
    if (updatedPetsData?.length) {
      selectedPetsDataRef.current = updatedPetsData;
    }
  }, [(updatedPetsData || [])?.map(pet => pet.id).join(',')]);

  return (
    <DiscountsFormContainer>
      <Controller
        name="selectedPets"
        control={control}
        render={({ onChange, value }) => (
          <PetsSearchList
            containerStyle={{
              flexDirection: 'column',
              justifyContent: 'unset',
              height: 'unset',
              gap: 8,
              flex: 'unset'
            }}
            minLength={3}
            renderItem={pet => {
              const isAdded = addedPets.includes(pet.PetRecord.id);
              if (isAdded) {
                return null;
              }
              return (
                <BranchRoutePetContainer key={pet.PetRecord.id}>
                  <span>
                    <strong>{pet.name}</strong>, {pet.Breed.name}
                  </span>
                  <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                    <span>{pet.AppUser.name}</span>
                    <span>{pet.AppUser.postcode}</span>
                    <FormCheckbox
                      itemsArray={[{ id: pet.PetRecord.id, name: '' }]}
                      onChange={() => {
                        if (value?.includes(pet.PetRecord.id)) {
                          const newValue = [...value].filter(v => v !== pet.PetRecord.id);
                          onChange(newValue);
                        } else {
                          const newValue = [...value, pet.PetRecord.id];
                          onChange(newValue);
                        }
                      }}
                      itemWidth={20}
                      value={[...(value || [])]}
                    />
                  </div>
                </BranchRoutePetContainer>
              );
            }}
            defaultText="Search Pets"
          />
        )}
      />
      <Divider />
      {branchRoutePetRecordsFieldArray.fields.map((branchRoutePetRecord, index) => {
        const petData = (updatedPetsData || selectedPetsData).find(petData => petData.PetRecord.id === branchRoutePetRecord.PetRecordId);
        const selectedValue = watchedBranchRoutePetRecords[index].RouteJobTypeId;
        const optionName = `branchRoute.BranchRoutePetRecords[${index}]`;
        const option = watchedBranchRoutePetRecords[index];
        const optionErrors = errors?.branchRoute?.BranchRoutePetRecords?.[index];
        return (
          <BranchRoutePetContainer key={petData?.id} background={Colors.white}>
            <Controller
              as={<FormInput error={!!optionErrors?.sort_index} width={40} flexOne />}
              control={control}
              name={`${optionName}.sort_index`}
              defaultValue={option?.sort_index || ''}
              rules={{ required: true }}
            />
            {!!optionErrors?.sort_index && <FormError>{optionErrors?.sort_index?.message || 'sort_index is required'}</FormError>}
            <FlexRow gap={10} flex1>
              <RecordBody width="100">
                <strong>{petData?.name}</strong>, {petData?.Breed.name}
              </RecordBody>
              <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                <RecordBody width="80">{petData?.AppUser.name}</RecordBody>
                <RecordBody width="80">{petData?.AppUser.postcode}</RecordBody>
              </div>
              <RecordBody width="100">
                <Controller
                  name={`branchRoute.BranchRoutePetRecords[${index}].RouteJobTypeId`}
                  control={control}
                  loading={loadingAddBranchRouteJobType}
                  rules={{ required: true }}
                  render={({ onChange }) => (
                    <Select
                      options={routeJobOptions}
                      placeholder="Select..."
                      onChange={newValue => {
                        onChange(newValue?.value);
                      }}
                      value={
                        selectedValue
                          ? {
                              value: selectedValue || '',
                              label: routeJobOptions.find(routeJobOption => routeJobOption.value === selectedValue)?.label
                            }
                          : null
                      }
                      isCreatable
                      blackTheme
                      onCreateOption={async newValue => {
                        const { data: { addBranchRouteJobType: newBranchRouteJobType = {} } = {} } = await addBranchJobType({
                          variables: {
                            name: newValue
                          }
                        });
                        requestAnimationFrame(() => onChange(newBranchRouteJobType.id));
                      }}
                    />
                  )}
                  defaultValue={selectedValue || ''}
                />
              </RecordBody>
              <RemoveRowContainer noMargin>
                <RemoveAddedImage src={icons.delete.childImageSharp.gatsbyImageData.images.fallback.src} onClick={() => handleRemoveRow(index)} noMargin />
              </RemoveRowContainer>
            </FlexRow>
          </BranchRoutePetContainer>
        );
      })}
    </DiscountsFormContainer>
  );
};

export default BranchRouteModalPets;
