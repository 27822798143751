import { convertDurationToMinutes } from '../../../components/Shared/DurationSelector';
import { Discount, DiscountInput, FormDiscount } from './types';

export const getSelectedDiscounts: (discounts: FormDiscount[]) => DiscountInput[] = discounts => {
  return discounts.map(discount => ({
    ...(discount.id ? { id: discount.id } : {}),
    amount: Number(discount.amount),
    name: discount.name,
    status: discount.status,
    rules: discount.rules,
    type: discount.type,
    Vouchers: discount.vouchers.map(voucher => ({
      ...(voucher.id ? { id: voucher.id } : {}),
      code: voucher.code,
      limit: Number(voucher.limit),
      user_limit: Number(voucher.user_limit),
      status: voucher.status,
      period: {
        start: voucher.period?.start,
        end: voucher.period?.end,
        createdAt_duration: voucher.period?.createdAt_duration ? convertDurationToMinutes(voucher.period?.createdAt_duration) : null
      },
      auto_apply: voucher.auto_apply,
      AppUserId: voucher.appUsers?.length ? voucher.appUsers : null,
      ProductId: voucher.products?.length ? voucher.products : null
    }))
  }));
};

export const getDiscountsDefaultValues: (discounts?: Discount[]) => FormDiscount[] = discounts => {
  // check code duplication in vouchers
  if (!discounts?.length) {
    return [
      {
        amount: 0,
        name: '',
        status: 'ACTIVE',
        rules: [],
        type: 'percentage',
        vouchers: []
      }
    ];
  }
  return (
    discounts?.map(discount => ({
      id: discount.id,
      isUnused: discount?.Vouchers?.every(voucher => (voucher.ordersCount || 0) === 0),
      amount: discount.amount,
      name: discount.name,
      status: discount.status,
      rules: discount.rules,
      type: discount.type,
      vouchers: discount?.Vouchers?.sort((a, b) => (a.AppUsers?.[0]?.name || '').localeCompare(b.AppUsers?.[0]?.name || ''))?.map(voucher => {
        return {
          id: voucher.id,
          code: voucher.code,
          limit: voucher.limit,
          user_limit: voucher.user_limit,
          ordersCount: voucher?.ordersCount || 0,
          status: voucher.status,
          auto_apply: voucher.auto_apply,
          appUsers: voucher.AppUsers.map(appUser => appUser.id),
          products: voucher.Products.map(product => product.id),
          discountId: discount.id,
          period: voucher.period,
          createdAt: voucher.createdAt
        };
      })
    })) || []
  );
};
