import { useQuery } from '@apollo/client';
import { useParams } from '@reach/router';
import { navigate } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useEffect } from 'react';
import { sentenceCase } from 'sentence-case';
import { BackButton } from '../../../components/DrawerBar/styled';
import InfiniteList from '../../../components/Shared/InfiniteList/InfiniteList';
import { ViewContainer } from '../../../components/Shared/Shared';
import useIcons from '../../../hooks/useIcons';
import usePaginatedQuery from '../../../hooks/usePaginatedQuery';
import { GetBranchVouchers, GetReducedBranchDiscounts } from '../../../queries';
import { toReadableDate } from '../../../utils/dates';
import {
  ContentWrapper,
  SubscriptionsDashboardContainer,
  SubscriptionsDashboardItem,
  SubscriptionsDashboardItemSubtitle,
  SubscriptionsDashboardItemTitle,
  SubscriptionsDashboardWrapper
} from '../../Subscriptions/styled';
import { RecordsContainer } from '../../styled';
import Common from '../Common';
import VoucherRecord from './VoucherRecord';
import { Discount, Voucher } from './types';
import ModalDialog from '../../../components/Modal/ModalDialog';
import DiscountsModal from './DiscountsModal';
import { getVouchersFiltersVariables, useVouchersFilters, VOUCHERS_FILTER_TYPES, VOUCHERS_MORE_OPTIONS_TYPES } from './VouchersFilters';
import ToolBar from '../../../components/Shared/ToolBar/ToolBar';
import Filters from '../../../components/Shared/Filters/Filters';
import { getFilterKeyOptions } from '../../../components/Shared/Filters/helpers';
import { getBranchCurrencySymbol } from '../../../utils/getBranchCurrencySymbol';
import VoucherModal from './VoucherModal';

const DiscountDetails = () => {
  const { id } = useParams();
  const filtersControl = useVouchersFilters();

  const { selectedFilters, headerAction } = filtersControl;

  const { onChange } = getFilterKeyOptions({
    filtersControl,
    keys: {
      filterType: VOUCHERS_FILTER_TYPES.VOUCHERS_SEARCH,
      moreOption: VOUCHERS_MORE_OPTIONS_TYPES.VOUCHERS_SEARCH
    }
  });

  const { data: { getBranchDiscounts: [discount] = [] } = {} } = useQuery<{ getBranchDiscounts: Discount[] }>(GetReducedBranchDiscounts, {
    variables: { id },
    fetchPolicy: 'cache-and-network',
    skip: !id
  });

  const [[hasMoreItems, setHasMoreItems], queryResult] = usePaginatedQuery<Voucher[]>({
    query: GetBranchVouchers,
    otherParams: { fetchPolicy: 'network-only', skip: !id },
    type: 'cursor',
    otherVariables: {
      DiscountId: id ? [id] : [],
      ...getVouchersFiltersVariables({
        selectedFilters
      })
    }
  });

  const { data: { getBranchVouchers: vouchers = [] } = {}, loading, fetchMore, refetch } = queryResult;

  useEffect(() => {
    Common.set(`Discounts.GetBranchVouchers.refetch`, () => {
      refetch?.().then(() => setHasMoreItems(true));
    });
  }, []);

  const icons = useIcons();

  const chevronIcon = icons.lessMid.childImageSharp.gatsbyImageData;
  const plusBlackIcon = icons?.plusBlack?.childImageSharp?.gatsbyImageData;

  const onEdit = () => {
    ModalDialog.openModal({
      content: () => (
        <DiscountsModal
          discountId={discount?.id}
          editVouchers={false}
          onDelete={() => {
            navigate('/app/operations/discounts');
          }}
        />
      ),
      title: 'Discount'
    });
  };

  const onAdd = () => {
    ModalDialog.openModal({
      content: () => (
        <VoucherModal
          discount={{
            id: discount?.id
          }}
        />
      ),
      title: 'Add Voucher'
    });
  };

  return (
    <ViewContainer>
      <ToolBar
        displayDivider={false}
        toolBarButtons={[]}
        urlSearchParam="status"
        middleAction={
          <>
            <BackButton onClick={onAdd}>
              <GatsbyImage image={plusBlackIcon} alt="add" />
            </BackButton>
            {headerAction}
          </>
        }
        onSearchChange={onChange}
        displayAll={false}
        displayString={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '20px 0',
              gap: 10
            }}
          >
            <BackButton onClick={() => navigate('/app/operations/discounts')}>
              <GatsbyImage image={chevronIcon} alt="Back" />
            </BackButton>
            <span>{discount?.name}</span>
          </div>
        }
      />
      <Filters filtersControl={filtersControl} />
      <SubscriptionsDashboardContainer style={{ padding: 0 }}>
        <SubscriptionsDashboardWrapper>
          <SubscriptionsDashboardItem flexOne>
            <ContentWrapper>
              <SubscriptionsDashboardItemTitle>Vouchers</SubscriptionsDashboardItemTitle>
              <SubscriptionsDashboardItemSubtitle>{discount?.vouchersCount}</SubscriptionsDashboardItemSubtitle>
            </ContentWrapper>
          </SubscriptionsDashboardItem>
          <SubscriptionsDashboardItem flexOne selectable>
            <ContentWrapper>
              <SubscriptionsDashboardItemTitle>Discount Value</SubscriptionsDashboardItemTitle>
              <SubscriptionsDashboardItemSubtitle>-{discount?.type === 'percentage' ? `%${discount?.amount}` : `${getBranchCurrencySymbol()}${discount?.amount}`}</SubscriptionsDashboardItemSubtitle>
            </ContentWrapper>
          </SubscriptionsDashboardItem>
          <SubscriptionsDashboardItem flexOne>
            <ContentWrapper>
              <SubscriptionsDashboardItemTitle>Rules</SubscriptionsDashboardItemTitle>
              <SubscriptionsDashboardItemSubtitle>
                {discount?.rules?.[0] ? sentenceCase(discount?.rules?.[0].rule?.type) : 'No Rules'}
                {discount?.rules.length > 1 ? `+ ${discount?.rules.length - 1} Rules` : ''}
              </SubscriptionsDashboardItemSubtitle>
            </ContentWrapper>
          </SubscriptionsDashboardItem>
          <SubscriptionsDashboardItem flexOne>
            <ContentWrapper>
              <SubscriptionsDashboardItemTitle>Status</SubscriptionsDashboardItemTitle>
              <SubscriptionsDashboardItemSubtitle>{sentenceCase(discount?.status || '')}</SubscriptionsDashboardItemSubtitle>
            </ContentWrapper>
          </SubscriptionsDashboardItem>
          {/* <SubscriptionsDashboardItem onClick={onEdit} clickable>
            <SubscriptionsDashboardItemTitle>Edit</SubscriptionsDashboardItemTitle>
          </SubscriptionsDashboardItem> */}
        </SubscriptionsDashboardWrapper>
      </SubscriptionsDashboardContainer>

      <InfiniteList
        list={vouchers}
        itemRenderer={(voucher: Voucher) => <VoucherRecord voucher={voucher} discount={discount} />}
        hasMoreItems={hasMoreItems}
        loading={loading}
        fetchMore={fetchMore}
        setHasMoreItems={setHasMoreItems}
        type="cursor"
        offset={vouchers?.length}
        paddingL={20}
        paddingR={20}
      />
    </ViewContainer>
  );
};

export default DiscountDetails;
