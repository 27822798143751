import React, { useCallback, useEffect, useId, useRef } from 'react';
import differenceInDays from 'date-fns/differenceInDays';
import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import enGB from 'date-fns/locale/en-GB';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Calendar, CalendarProps, dateFnsLocalizer } from 'react-big-calendar';
import withDragAndDrop, { withDragAndDropProps } from 'react-big-calendar/lib/addons/dragAndDrop';
import Colors from '../../../Colors';
import { dateInUTC, getMonthName, getStartOfDate, isToday, toDay, toThreeLetterWeekDay } from '../../../utils/dates';
import { BlockedCalendarEvent, CalendarEvent } from '../../../views/Bookings/types';
import { BusUserProfile } from '../../Profile/types';
import { CALENDAR_VIEWS } from '../types';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { PetContainer } from '../../../views/Bookings/components/BookingRecord/styled';
import { PetImg } from '../../../views/Bookings/components/BookingsBody/styled';
import { RecordBody } from '../../../views/styled';
import { BusinessUserImageContainer, CalendarToolBarContainer, Divider } from '../../SideBar/styled';
import OptionDropdown, { OptionDropDownItem } from '../../Shared/Menus/OptionDropdown/OptionDropdown';
import { OPTION_DROPDOWN_MENU_BUTTON_TYPES, OPTION_DROPDOWN_MENU_POSITIONS, OPTION_DROPDOWN_TYPES } from '../../Shared/Menus/OptionDropdown/types';
import { BranchSchedule } from '../../../views/Store/BranchSchedules/types';
import useIcons from '../../../hooks/useIcons';
import { useWindowSize } from '../../../hooks/useWindowResize';
import { useQuery } from '@apollo/client';
import { GetAllBranchBusUsers, GetBranchSchedules, GetBusUserProfile } from '../../../queries';
import { vars } from '../../../reactive';
import useMediaQuery from '../../../hooks/useMediaQuery';
import {
  WeekDayTitleAndWeekDayContainer,
  WeekDayTitle,
  WeekDay,
  EventLabel,
  EventLabelsContainer,
  MonthLabel,
  StaffContainer,
  ToolbarButton,
  CounterBtn,
  ToolsContainer,
  SlotWrapperContainer,
  SlotWrapperAvailability
} from '../styled';
import ModalDialog from '../../Modal/ModalDialog';
import CalendarRange, { CalendarRangeRef, getRange } from '../CalendarRange';
import { DefaultOptionType } from 'antd/es/select';
import { BranchScheduleSlotsAvailability, TheCalendarEvent, TheCalendarResource } from '../TheCalendar';
import { BranchAvailability } from '../../../views/Store/types';
import { ViewSwitchContainer } from '../../../views/Bookings/components/BookingsHeader/styled';
import { CalendarNavigation } from '../../../views/Calendar/Calendar';
import Select from '../../Shared/Forms/Select';

const locales = {
  'en-GB': enGB
};

const MARGIN_HORIZONTAL = 250;
const ITEM_SIZE = 50;

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: (
    date: number | Date,
    options?:
      | {
          locale?: Locale | undefined;
          weekStartsOn?: 0 | 1 | 2 | 3 | 5 | 6 | 4 | undefined;
        }
      | undefined
  ) => startOfWeek(date, { ...options, weekStartsOn: 1 }),
  getDay,
  locales
});

const DnDCalendar = withDragAndDrop<TheCalendarEvent, TheCalendarResource>(Calendar);

type SlotsCalendarProps = {
  dateRange: { from: Date; to: Date };
  calendarRangeRef: React.MutableRefObject<CalendarRangeRef | null>;
  events: { view: CalendarEvent; title: string; start: Date; end: Date; allDay: boolean; resourceId: any; color: any }[];
  bookingTypeOptions: OptionDropDownItem[];
  blockedEvents: {
    view: BlockedCalendarEvent;
    title: string;
    start: Date;
    end: Date;
    allDay: boolean;
    resourceId: any;
    isBlocked: boolean;
    time: any;
    variables: { timeStamp: Date; count: number; duration: number; description: string; branch_schedules: any; status: string };
  }[];
  onEventDrop: withDragAndDropProps<TheCalendarEvent, TheCalendarResource>['onEventDrop'];
  onEventResize: withDragAndDropProps<TheCalendarEvent, TheCalendarResource>['onEventResize'];
  onSelectEvent: CalendarProps<TheCalendarEvent, TheCalendarResource>['onSelectEvent'];
  onSelectSlot: CalendarProps<TheCalendarEvent, TheCalendarResource>['onSelectSlot'];
  multiStaffBookingType: boolean;
  adjustCalendarDomView: () => void;
  selectedUser: string;
  setSelectedUser: (value: string) => void;
  setSelectedSchedules: (value: string[]) => void;
  selectedSchedules: string[];
  calendarNavigation: CalendarNavigation;
  availability?: BranchScheduleSlotsAvailability;
  availabilityVisible: boolean;
  selectedBookingType: string;
  calendarView: 'day' | 'week' | 'month';
  refetch: (args?: any) => void;
  schedules: BranchSchedule[];
};
export function SlotsCalendar({
  dateRange,
  calendarRangeRef,
  bookingTypeOptions,
  events,
  blockedEvents,
  refetch,
  onEventDrop,
  onEventResize,
  calendarView,
  schedules,
  selectedBookingType,
  availabilityVisible,
  calendarNavigation,
  onSelectEvent,
  onSelectSlot,
  multiStaffBookingType,
  adjustCalendarDomView,
  selectedUser,
  setSelectedUser,
  setSelectedSchedules,
  selectedSchedules,
  availability
}: SlotsCalendarProps): React.ReactNode {
  const { mobile } = useMediaQuery({ mobile: true });
  const { height } = useWindowSize();
  const icons = useIcons();
  const { prev, next, today, navigate, changeView } = calendarNavigation;
  if (mobile) {
    changeView('day');
  }

  const lessIcon = icons.lessMid.childImageSharp.gatsbyImageData;
  const moreIcon = icons.moreMid.childImageSharp.gatsbyImageData;

  const { data: { getBranchBusUsers: BusUsers = [] } = {} } = useQuery<{ getBranchBusUsers: BusUserProfile[] }>(GetAllBranchBusUsers);

  const viewSwitchOn = icons?.viewSwitchOn?.childImageSharp.gatsbyImageData;
  const viewSwitchOff = icons?.viewSwitchOff?.childImageSharp.gatsbyImageData;

  const handleClickAvailability = () => {
    vars.calendarAvailabilityVisible(!availabilityVisible);
    refetch();
  };

  const operationsCalendarBookingType = selectedBookingType === CALENDAR_VIEWS.SLOTS;

  const allSlots = availability?.branchSchedules[0]?.availability?.slots?.availableSlots?.flat();

  const getValueColor = ({ max, value }: { max: number; value: number }) => {
    const isLessThanZero = value <= 0;
    const isLessThanHalf = value < max / 2;
    if (isLessThanZero) {
      return Colors.lightalert;
    }
    if (isLessThanHalf) {
      return Colors.lightorange;
    }

    return Colors.lightprimary;
  };

  const getLabelValueColor = ({ max, value }: { max: number; value: number }) => {
    const isLessThanZero = value <= 0;
    const isLessThanHalf = value < max / 2;
    if (isLessThanZero) {
      return Colors.alert;
    }
    if (isLessThanHalf) {
      return Colors.orange;
    }

    return Colors.primary;
  };

  const datesRef = useRef<{ date: Date; id: string }[]>([]);

  const SlotWrapepr = useCallback(
    ({ children, value }: { children: React.ReactNode; value: Date }) => {
      const id = useId();
      const valueUtc = dateInUTC(value);

      const dateWithoutTime = getStartOfDate(valueUtc);
      const hour = valueUtc.getHours();
      const minute = valueUtc.getMinutes();

      const foundInDatesRef = datesRef.current.find(date => date.date === value);

      if (!foundInDatesRef) {
        datesRef.current.push({ date: value, id });
      }

      const slotsWithinTheHour = allSlots?.filter(slot => {
        const date = new Date(slot.timestamp);
        const slotWithoutTime = getStartOfDate(date);
        const slotHour = date.getHours();
        const slotMinute = date.getMinutes();
        return dateWithoutTime?.getTime() === slotWithoutTime?.getTime() && slotHour === hour && slotMinute >= minute;
      });

      const shouldRender = !!slotsWithinTheHour?.length && !foundInDatesRef && availabilityVisible;

      return (
        <div style={{ position: 'relative', width: '100%', transform: 'translateY(-50%)' }}>
          {children}
          {slotsWithinTheHour?.map((slot, index) => {
            const date = new Date(slot.timestamp);

            const slotMinute = date.getMinutes();
            const color = shouldRender
              ? getValueColor({
                  max: slot?.mainSlotAvailablity,
                  value: slot?.available
                })
              : 'transparent';

            const labelcolor = shouldRender
              ? getLabelValueColor({
                  max: slot?.mainSlotAvailablity,
                  value: slot?.available
                })
              : 'transparent';

            const count = slot?.available || 0;
            const atCapacity = count <= 0;
            const moreThanCapacity = count < 0;
            const extraCapacity = Math.abs(count);

            const text = atCapacity && moreThanCapacity ? `C +${extraCapacity}` : atCapacity ? 'C' : `${count}`;

            return (
              <SlotWrapperContainer backgroundColor={color} id={id} minute={slotMinute} key={index}>
                <SlotWrapperAvailability backgroundColor={labelcolor}>{text}</SlotWrapperAvailability>
              </SlotWrapperContainer>
            );
          })}
        </div>
      );
    },
    [allSlots, availabilityVisible]
  );

  const usersList = [
    {
      label: 'All Staff',
      value: ''
    },
    ...BusUsers.map(user => ({
      label: user.name,
      value: user.id
    }))
  ];

  const userSchedules = schedules.map(schedule => ({ value: schedule.id, label: schedule.name }));

  useEffect(adjustCalendarDomView, [selectedUser]);

  const isMonth = calendarView === 'month';

  const handleHeaderClick = () => {
    const currentCalendarLength = differenceInDays(dateRange.to, dateRange.from) + 1;
    ModalDialog.openModal({
      noHeader: true,
      overlayClose: true,
      isMini: true,
      autoHeight: true,
      mobileBorderRadius: '38px',
      content: () => (
        <CalendarRange
          currentCalendarLength={currentCalendarLength}
          setUTCDate={date => {
            ModalDialog.closeModal();
            navigate(date, true);
          }}
          useDatePicker={multiStaffBookingType}
          defaultValues={getRange(dateRange.from, currentCalendarLength)}
          ref={calendarRangeRef}
        />
      )
    });
  };

  const rangeFrom = multiStaffBookingType ? format(dateRange.from, 'dd MMM') : format(dateRange.from, 'MMM');

  return (
    <div
      style={{
        display: 'flex',
        height: '100%'
      }}
    >
      {isMonth && operationsCalendarBookingType && (
        <div style={{ width: '72px' }}>
          <Divider style={{ marginTop: '64px' }} />
          <div
            onClick={handleHeaderClick}
            style={{
              cursor: 'pointer',
              overflow: 'visible',
              fontWeight: '800',
              fontSize: '16px',
              padding: '10px 15px',
              borderBottom: '1px solod black',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '48px',
              width: '72px'
            }}
          >
            {format(dateRange.from, 'MMM')}
          </div>
          <Divider />
        </div>
      )}
      <DnDCalendar
        style={{ width: '100%' }}
        date={dateRange.from}
        defaultView={calendarView}
        view={calendarView}
        events={availabilityVisible ? [] : [...events, ...blockedEvents]}
        localizer={localizer}
        onEventDrop={onEventDrop}
        onEventResize={onEventResize}
        onSelectEvent={onSelectEvent}
        onSelectSlot={onSelectSlot}
        step={30}
        resizable
        selectable
        dayLayoutAlgorithm={'no-overlap'}
        popup
        showMultiDayTimes={false}
        scrollToTime={new Date()}
        enableAutoScroll
        allDayMaxRows={(height - MARGIN_HORIZONTAL) / ITEM_SIZE}
        {...(multiStaffBookingType &&
          selectedUser === '' && {
            view: 'day'
          })}
        {...(multiStaffBookingType && {
          resources: BusUsers.map(user => ({ resourceId: user.id, resourceTitle: user.name })).filter(user => (selectedUser !== '' ? user.resourceId === selectedUser : true)),
          resourceIdAccessor: r => r.resourceId,
          resourceTitleAccessor: r => r.resourceTitle
        })}
        components={{
          header: props => {
            const dateLabel = new Date(props.date);
            const isToday = dateLabel.toDateString() === new Date().toDateString();
            const isTodayMonth = dateLabel.getDay() === new Date().getDay();

            return (
              <WeekDayTitleAndWeekDayContainer onClick={() => calendarNavigation.changeView('day', dateLabel)}>
                <WeekDayTitle isToday={isTodayMonth && isMonth}>{toThreeLetterWeekDay(dateLabel)}</WeekDayTitle>
                {!isMonth && <WeekDay isToday={isToday}>{toDay(dateLabel)}</WeekDay>}
              </WeekDayTitleAndWeekDayContainer>
            );
          },
          timeGutterHeader: () => {
            return <MonthLabel onClick={handleHeaderClick}>{rangeFrom}</MonthLabel>;
          },
          timeSlotWrapper: SlotWrapepr,
          event: event => {
            const isBlocked = event.event.isBlocked;
            const blockedTitle = event.event.view?.description;
            const pets = event?.event?.view?.petsNames;
            const petsCount = event?.event?.view?.petsCount;
            const petsToReturn = petsCount > 3 ? `${petsCount} pets` : `${pets}`;
            const service = event?.event?.view?.itemName || 'Blocked';
            const eventTime = `${format(event?.event?.start, 'HH:mm')} - ${format(event?.event?.end, 'HH:mm')}`;
            const titleToDisplay = isBlocked ? blockedTitle : service;
            const eventColor = isBlocked ? Colors.blocked : event?.event?.color;

            return (
              <EventLabelsContainer style={{ backgroundColor: eventColor }} padding={calendarView === 'month' ? '4px' : '8px'}>
                <div style={{ display: 'flex', flexDirection: calendarView !== 'month' ? 'column' : 'row', gap: '4px' }}>
                  {!isBlocked && (
                    <EventLabel fontWeight={800} height="16px" lineHeight={calendarView === 'month' ? 'unset' : '11px'}>
                      {titleToDisplay}
                    </EventLabel>
                  )}
                  {isBlocked && (
                    <EventLabel fontWeight={800} whiteSpace="auto" lineHeight="16px">
                      {titleToDisplay}
                    </EventLabel>
                  )}
                  {calendarView === 'month' && !isBlocked && (
                    <EventLabel fontSize={11} whiteSpace="auto" lineHeight="16px" style={{ marginLeft: 'auto' }}>
                      {petsCount} {petsCount === 1 ? 'pet' : 'pets'}
                    </EventLabel>
                  )}
                  {!isBlocked && calendarView !== 'month' && <EventLabel fontSize={11}>{petsToReturn}</EventLabel>}
                </div>
                {calendarView !== 'month' && <EventLabel>{eventTime}</EventLabel>}
              </EventLabelsContainer>
            );
          },
          resourceHeader: ({ index, label, resource: { resourceId, resourceTitle } }) => {
            const user = BusUsers.find(u => u.id === resourceId);
            return (
              !selectedUser && (
                <StaffContainer onClick={() => setSelectedUser(selectedUser ? '' : user?.id!)}>
                  {!selectedUser && (
                    <>
                      <PetContainer mobileWidth="30px">
                        {!user?.profile_pic && <GatsbyImage image={icons?.user?.childImageSharp?.gatsbyImageData} alt={resourceTitle} style={{ height: 30, width: 30, borderRadius: 15 }} />}
                        {user?.profile_pic && <PetImg src={user.profile_pic} alt={resourceTitle} height="30px" width="30px" />}
                      </PetContainer>
                      <RecordBody width="auto" flex="unset" noMargin maxWidth={100} mobileWidth="auto" mobileMaxWidth="60px" mobileOverflow="hidden" mobileDisplay="block">
                        {label}
                      </RecordBody>
                    </>
                  )}
                </StaffContainer>
              )
            );
          },

          toolbar: props => {
            const date = props.date;
            const dateLabel = getMonthName(date);
            const dayName = toThreeLetterWeekDay(date);
            const mobileDateLabel = `${dayName}, ${format(date, 'dd  MMM  yyyy')}`;
            return (
              <CalendarToolBarContainer>
                <div style={{ display: 'flex', gap: 10, width: '250px', zIndex: 100, position: 'relative' }}>
                  {!multiStaffBookingType && (
                    <Select
                      options={userSchedules}
                      isMulti={!availabilityVisible}
                      onChange={e => {
                        let newValue = [e?.value];
                        if (!availabilityVisible) {
                          newValue = e?.map(({ value }) => value);
                        }
                        setSelectedSchedules(newValue);
                        const schedules = newValue?.length ? newValue : null;
                        refetch({ product_branchSchedule_id: schedules, branchScheduleId: schedules || undefined });
                      }}
                      value={userSchedules.filter(({ value }) => selectedSchedules?.includes(value))}
                    />
                  )}

                  {!multiStaffBookingType && <div />}
                  {multiStaffBookingType && (
                    <Select
                      options={usersList}
                      onChange={e => {
                        setSelectedUser(e?.value || '');
                        requestAnimationFrame(() =>
                          refetch({
                            appointment_busUserAssigned_id: e?.value || null
                          })
                        );
                      }}
                      value={usersList.find(user => user.value === selectedUser)}
                    />
                  )}
                </div>
                {
                  <ToolsContainer>
                    {!multiStaffBookingType && (
                      <ViewSwitchContainer onClick={handleClickAvailability} data-tooltip-id="tooltip" data-tooltip-content="your placeholder">
                        {!availabilityVisible ? (
                          <GatsbyImage image={viewSwitchOn} alt="viewSwitchOn" style={{ cursor: 'pointer' }} />
                        ) : (
                          <GatsbyImage image={viewSwitchOff} alt="viewSwitchOff" style={{ cursor: 'pointer' }} />
                        )}
                      </ViewSwitchContainer>
                    )}
                    {!mobile && (
                      <>
                        {!(multiStaffBookingType && selectedUser === '') && (
                          <div style={{ display: 'flex', gap: 0 }}>
                            <ToolbarButton onClick={() => changeView('day')} selected={calendarView === 'day'}>
                              Day
                            </ToolbarButton>
                            <ToolbarButton onClick={() => changeView('week')} selected={calendarView === 'week'}>
                              Week
                            </ToolbarButton>
                            {!multiStaffBookingType && (
                              <ToolbarButton onClick={() => changeView('month')} selected={calendarView === 'month'}>
                                Month
                              </ToolbarButton>
                            )}
                          </div>
                        )}
                        <div style={{ display: 'flex', gap: 10 }}>
                          <CounterBtn onClick={() => prev()} height="25px" width="25px">
                            <GatsbyImage image={lessIcon} alt={'Prev'} />
                          </CounterBtn>
                          <ToolbarButton onClick={() => today()} selected={isToday(props.date)} isToday style={{ background: 'transparent', fontWeight: `${isToday(props.date) ? 800 : 600}` }}>
                            Today
                          </ToolbarButton>
                          <CounterBtn onClick={() => next()} height="25px" width="25px">
                            <GatsbyImage image={moreIcon} alt={'Next'} />
                          </CounterBtn>
                        </div>
                      </>
                    )}
                  </ToolsContainer>
                }
                {/* {mobile && (
                  <div style={{ display: 'flex' }}>
                    <RecordBody onClick={handleHeaderClick} fontWeight="800" fontSize={16} style={{ cursor: 'pointer' }}>
                      {mobileDateLabel}
                    </RecordBody>
                  </div>
                )} */}
                {mobile && (
                  <BusinessUserImageContainer>
                    <RecordBody style={{ position: 'absolute', right: '3px', height: 0 }}>
                      <OptionDropdown
                        mobileDisplayIcon={mobile}
                        menuButtonType={OPTION_DROPDOWN_MENU_BUTTON_TYPES.MORE}
                        options={[
                          {
                            id: 'options',
                            optionType: OPTION_DROPDOWN_TYPES.BUTTONS,
                            items: bookingTypeOptions
                          }
                        ]}
                        noApplyButton
                        menuPosition={OPTION_DROPDOWN_MENU_POSITIONS.LEFT}
                      />
                    </RecordBody>
                    {/* {!profile?.profile_pic && <DefaultBusinessUserImage image={icons?.user?.childImageSharp?.gatsbyImageData} alt="Business user" />}
                                    {profile?.profile_pic && <BusinessUserImage src={profile.profile_pic} alt="Business user" />} */}
                  </BusinessUserImageContainer>
                )}
              </CalendarToolBarContainer>
            );
          }
        }}
      />
    </div>
  );
}
