import React, { useRef } from 'react';
import { StoreEditButton } from '../../../components/DrawerBar/DrawerButtons';
import { DRAWER_IDS } from '../../../components/DrawerBar/types';
import { Divider } from '../../../components/Shared/SideTabs/styles';
import { HoverRecordOptionsContainer, RecordBody, RecordDataContainer } from '../../styled';
import { BRANCH_FORM_LEVELS_LABELS, BRANCH_FORM_TYPES_LABELS, BranchForm } from './types';
import { Booking } from '../../Bookings/components/BookingsBody/styled';

const BranchFormRecord = ({ branchForm }: { branchForm: BranchForm }) => {
  const storeEditButtonRef = useRef<{ handleClick: () => void }>(null);
  const productNames = branchForm?.Products?.map(product => product?.name).join(', ');
  return (
    <>
      <Booking padding="6px 10px" gap={10}>
        <RecordBody fontWeight={'800'}>{branchForm?.name}</RecordBody>

        <RecordBody flex="unset" width="200" fontWeight="600">
          {BRANCH_FORM_LEVELS_LABELS[branchForm?.level]}
        </RecordBody>
        <RecordBody flex="unset" width="100" fontWeight="600">
          {productNames}
        </RecordBody>
        <RecordBody flex="unset" width="100" fontWeight="600" flexEnd>
          {BRANCH_FORM_TYPES_LABELS[branchForm?.type]}
        </RecordBody>
        <HoverRecordOptionsContainer>
          <StoreEditButton drawer={{ drawerId: DRAWER_IDS.BRANCH_FORM_DRAWER, recordData: branchForm }} ref={storeEditButtonRef} />
        </HoverRecordOptionsContainer>
      </Booking>
      <Divider />
    </>
  );
};

export default BranchFormRecord;
