import React, { useRef } from 'react';
import { sentenceCase } from 'sentence-case';
import { Divider } from '../../../components/Shared/SideTabs/styles';
import { getBranchCurrencySymbol } from '../../../utils/getBranchCurrencySymbol';
import { HoverRecordOptionsContainer, RecordBody, RecordDataContainer } from '../../styled';
import { Discount } from './types';
import { navigate } from 'gatsby';
import { Booking } from '../../Bookings/components/BookingsBody/styled';
import { BtnContainer } from '../../../components/Shared/ToolBar/styles';
import { GatsbyImage } from 'gatsby-plugin-image';
import useIcons from '../../../hooks/useIcons';
import ModalDialog from '../../../components/Modal/ModalDialog';
import DiscountsModal from './DiscountsModal';

const DiscountRecord = ({ discount }: { discount: Discount }) => {
  const ref = useRef(null);
  const handleClick = event => {
    if (ref?.current?.contains(event?.target)) {
      return;
    }
    navigate(`/app/operations/discounts/${discount.id}`);
  };
  const icons = useIcons();

  const onEdit = () => {
    ModalDialog.openModal({
      content: () => (
        <DiscountsModal
          discountId={discount?.id}
          editVouchers={false}
          onDelete={() => {
            navigate('/app/operations/discounts');
          }}
        />
      ),
      title: 'Discount'
    });
  };

  return (
    <>
      <Booking onClick={handleClick} padding="6px 10px" minHeight="40px">
        <RecordBody fontWeight={'800'}>{discount?.name}</RecordBody>
        <RecordBody flex="unset">
          Discounts {discount?.type === 'amount' ? getBranchCurrencySymbol() : '%'}
          {discount?.amount}
        </RecordBody>
        <RecordBody flex="unset" flexEnd width="100">
          {sentenceCase(discount?.status)}
        </RecordBody>
        <HoverRecordOptionsContainer>
          <BtnContainer onClick={onEdit} ref={ref}>
            <GatsbyImage image={icons?.moreIcon?.childImageSharp?.gatsbyImageData} alt="more" />
          </BtnContainer>
        </HoverRecordOptionsContainer>
      </Booking>
      <Divider />
    </>
  );
};

export default DiscountRecord;
