import { UseFormMethods } from 'react-hook-form';
import { MedCond, Pet, SuppRecord } from '../../../../components/Pets/types';
import { Product } from '../../../Store/types';

export enum TabsTypes {
  Current = 'Current',
  New = 'New'
}

export const Tabs = Object.values(TabsTypes);

export const frequencies = [
  { id: 3, name: 'Daily' },
  { id: 2, name: 'Weekly' },
  { id: 1, name: 'Monthly' },
  { id: 0, name: 'Yearly' },
  { id: -1, name: 'As Needed' },
  { id: -2, name: 'One-Off' }
];
export const everyFrequencies = [13, 3, 11, 10];

export const frequencyEndDateItems = [
  { id: 3, name: 'days', length: 30, constructor: (date: Date, count: number) => new Date(new Date(date).setUTCDate(date.getUTCDate() + count)) },
  { id: 2, name: 'weeks', length: 4, constructor: (date: Date, count: number) => new Date(new Date(date).setUTCDate(date.getUTCDate() + count * 7)) },
  { id: 1, name: 'months', length: 12, constructor: (date: Date, count: number) => new Date(new Date(date).setMonth(date.getMonth() + count)) },
  { id: 0, name: 'years', length: 5, constructor: (date: Date, count: number) => new Date(new Date(date).setFullYear(date.getFullYear() + count)) },
  { id: -1, name: 'days', length: 30, constructor: (date: Date) => date },
  { id: -2, name: 'days', length: 30, constructor: (date: Date) => date }
];

export type MedicationModalFormValues = {
  product: string;
  size: string;
  quantity: string;
  frequency: string;
  reccurence: string;
  startDate: string;
  count: string;
  endDateFrequency: string;
};

export type MedicationModalProps = {
  medCond?: MedCond;
  pet?: Pet;
  suppRecord?: SuppRecord;
};

export type AddNewMedicationProps = {
  medCond?: MedCond;
  pet: Pet;
  autoOpenModal?: boolean;
};

export type CurrentTreatmentsRef = {
  submitAdherence: () => void;
};

export type MedicationFormProps = {
  medicalCaseName: string;
  onSubmit: (form: MedicationModalFormValues) => void;
  loading: boolean;
  loadingDelete: boolean;
  error: boolean;
  errorDelete: boolean;
  handleDlete: () => void;
  pet?: Pet;
  medicalCase: MedCond;
  suppRecord?: SuppRecord;
  defaultFormValues?: MedicationModalFormValues;
};

export type NewTreatmentFormProps = {
  medicalCaseName: string;
  products?: Product[];
  isRecurring: boolean;
  intervalItems: { id: number; name: string }[];
  currentProduct?: Product;
  formOptions: UseFormMethods<MedicationModalFormValues>;
  suppRecord?: SuppRecord;
  defaultFormValues?: MedicationModalFormValues;
  loadingProducts: boolean;
};
