import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import React, { useRef, useState } from 'react';
import OptionDropdown from '../../components/Shared/Menus/OptionDropdown/OptionDropdown';
import { OPTION_DROPDOWN_MENU_BUTTON_TYPES, OPTION_DROPDOWN_MENU_POSITIONS, OPTION_DROPDOWN_TYPES } from '../../components/Shared/Menus/OptionDropdown/types';
import { MainContainer, TabNotification, ViewWrapper } from '../../components/Shared/Shared';
import SideTabs from '../../components/Shared/SideTabs/SideTabs';
import useIcons from '../../hooks/useIcons';
import useMediaQuery from '../../hooks/useMediaQuery';
import { BOOKING_TABS_TYPES } from '../Bookings/types';
import BookingsContent from './components/BookingsContent/BookingsContent';
import useBranchServiceOrdersViewCounts from '../../hooks/useBranchServiceOrdersViewCounts';

import ModalDialog from '../../components/Modal/ModalDialog';
import CalendarRange, { CalendarRangeRef, getRange } from '../../components/Calendar/CalendarRange';
import useURLSearchParams from '../../hooks/useURLSearchParams';
import { formatDate } from '../../utils/dates';
import { format } from 'path';
import { RecordBody } from '../styled';
import { MenuButton } from '../../components/Shared/Menus/OptionDropdown/styled';

const Bookings = () => {
  const { mobile } = useMediaQuery({ mobile: true });
  const [urlDate, setUrlDate] = useURLSearchParams('date');
  const selectedDate = urlDate ? new Date(`${urlDate} 00:00:00`) : new Date();
  const [date, setDate] = useState<Date>(selectedDate);

  const rangeRef = useRef<CalendarRangeRef>(null);
  const icons = useIcons();
  const dateSelector = icons.dateSelecorSVG?.publicURL;
  // const moreTabs = icons.moreTabs.childImageSharp.gatsbyImageData;

  const optionDropdownRef = useRef(null);
  const location = useLocation();
  const isConfirmed = location.pathname.split('/')[3] === 'confirmed';

  const sections = [
    {
      tabs: [
        {
          name: 'Daily',
          value: 'confirmed'
        }
      ]
    },
    {
      tabs: [
        {
          name: 'New',
          value: 'requested'
        },
        { name: 'Upcoming', value: 'upcoming' }
      ]
    },
    {
      tabs: [
        {
          name: 'Payment',
          value: 'payment'
        }
      ]
    }
    // {
    //   tabs: [
    //     {
    //       name: 'Cancelled',
    //       value: 'canceled'
    //     }
    //   ]
    // }
  ];

  const mobielSections = [
    {
      tabs: [
        {
          name: new Date(selectedDate).getDate() === new Date().getDate() ? 'Today' : formatDate(new Date(selectedDate), true)?.toString(),
          value: 'confirmed'
        },

        {
          name: 'Upcoming',
          value: 'upcoming'
        }
      ]
    }
  ];

  const sectionsToDisplay = mobile ? mobielSections : sections;
  const { newServiceOrdersCount, paymentServiceOrdersCount } = useBranchServiceOrdersViewCounts();

  const MoreTabsDropdown = () => (
    <OptionDropdown
      menuButtonType={OPTION_DROPDOWN_MENU_BUTTON_TYPES.MORE}
      noApplyButton
      containerRelative
      // buttonLoading={loading}
      options={[
        {
          optionType: OPTION_DROPDOWN_TYPES.BUTTONS,
          id: 'bookingOptions',
          items: [
            {
              name: 'New',
              value: 'requested',
              onClick: () => {
                navigate(`${location.pathname.split('/').slice(0, 3).join('/')}/requested`, { replace: true });
              }
            },
            {
              name: 'Payment',
              value: 'payment',
              onClick: () => {
                navigate(`${location.pathname.split('/').slice(0, 3).join('/')}/payment`, { replace: true });
              }
            }
          ]
        }
      ]}
      menuPosition={OPTION_DROPDOWN_MENU_POSITIONS.LEFT}
      ref={optionDropdownRef}
    />
  );

  const NewTabButton = () => (
    <TabNotification
      onClick={() => {
        navigate(`${location.pathname.split('/').slice(0, 3).join('/')}/requested`, { replace: true });
      }}
      mobileWidth="28px"
      mobileHeight="28px"
    >
      {newServiceOrdersCount}
    </TabNotification>
  );
  const DateButton = () =>
    isConfirmed && (
      <MenuButton
        bgImage={dateSelector}
        width="28px"
        height="28px"
        mobileDisplayIcon
        onClick={() => {
          ModalDialog.openModal({
            noHeader: true,
            isMini: true,
            overlayClose: true,
            autoHeight: true,
            content: () => (
              <CalendarRange
                ref={rangeRef}
                currentCalendarLength={1}
                useDatePicker={true}
                setUTCDate={date => {
                  const currDate = date.toISOString().slice(0, 10);
                  setUrlDate(currDate);
                  setDate(new Date(`${currDate} 00:00:00`));
                  ModalDialog.closeModal();
                }}
                defaultValues={getRange(date, 1)}
              />
            )
          });
        }}
      />
    );
  const components = [DateButton, NewTabButton, MoreTabsDropdown];

  return (
    <ViewWrapper>
      {!mobile && (
        <SideTabs
          sections={sectionsToDisplay}
          tabsWithCount={[
            {
              tabValue: 'requested',
              count: newServiceOrdersCount || 0,
              requiresAction: true
            },
            {
              tabValue: 'payment',
              count: paymentServiceOrdersCount || 0,
              requiresAction: true
            }
          ]}
          defaultTab="confirmed"
        />
      )}
      <MainContainer>
        {mobile && (
          <SideTabs
            sections={sectionsToDisplay}
            tabsWithCount={[
              {
                tabValue: 'requested',
                count: newServiceOrdersCount || 0
              },
              {
                tabValue: 'payment',
                count: paymentServiceOrdersCount || 0
              }
            ]}
            defaultTab="confirmed"
            components={components}
          />
        )}
        <BookingsContent />
      </MainContainer>
    </ViewWrapper>
  );
};

export default Bookings;
