import React from 'react';
import { ProductTitle } from '../ProductDrawer/styled';
import { DrawerContainer, DrawerSection } from '../../DrawerBar/styled';
import { BranchPetRecordTag, TAGS_AUTOMATIONS_TYPES_LABELS } from '../../../views/Store/BranchTags/types';

const BranchPetRecordTagDrawer = ({ data: tag }: { data: BranchPetRecordTag }) => {
  return (
    <DrawerContainer scrollable>
      <DrawerSection paddingT={55}>
        <ProductTitle>
          Name: <strong>{tag?.name}</strong>
        </ProductTitle>
        <ProductTitle>
          Quick Action: <strong>{!!tag?.quick_action ? 'Yes' : 'No'}</strong>
        </ProductTitle>
        {!!tag?.automations?.length && (
          <ProductTitle>
            Automations: <strong>{tag?.automations?.map(automation => TAGS_AUTOMATIONS_TYPES_LABELS[automation?.type]).join(', ')}</strong>
          </ProductTitle>
        )}
      </DrawerSection>
    </DrawerContainer>
  );
};

export default BranchPetRecordTagDrawer;
