import { useQuery } from '@apollo/client';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Control, Controller, FieldErrors, useWatch } from 'react-hook-form';
import { FormCheckbox, FormError, FormLabel, FormSelect, RadioBtnsGroup, RemoveRowContainer, selectTheme, WideInputGroup } from '../../../components/Shared/Forms/Forms';
import MarkupEditor, { MarkupEditorRef } from '../../../components/Shared/MarkupEditor';
import { OPTION_DROPDOWN_TYPES } from '../../../components/Shared/Menus/OptionDropdown/types';
import { CenteredLoader } from '../../../components/Shared/Spinner';
import useGetBranchTagsByType from '../../../hooks/useGetBranchTagsByType';
import useIcons from '../../../hooks/useIcons';
import { GetAllBranchMessageTemplates } from '../../../queries';
import { BranchMessage } from '../BranchMessages/BranchMessages';
import { AddNewBtn, AddNewBtnIcon } from '../Products/styled';
import { RemoveAddedImage } from '../styled';
import {
  AutomationContainer,
  AutomationRowContainer,
  AutomationRowWrapper,
  AutomationSeperator,
  AutomationTypeContainer,
  AutomationsContainer,
  AutomationsFooterContainer,
  BranchTagsExtraOptions
} from './styled';
import { AUTOMATIONS_OPTION_DROPDOWN_TYPES, BRANCH_TAGS_TYPES, BranchTag, TAGS_AUTOMATIONS_TYPES, TAGS_AUTOMATIONS_TYPES_LABELS, TAGS_AUTOMATIONS_TYPES_TITLES } from './types';
import Select from '../../../components/Shared/Forms/Select';
import { MarkupEditorContainer, MarkupEditorWrapper } from '../../../components/Shared/BranchNotes/styled';
import { Divider } from '../../../components/Shared/SideTabs/styles';
import ReactSelect from 'react-select';
import { options } from 'linkifyjs';
import BranchTagsOptions, { BranchTagsOptionsTypes, getBranchTagsOptionsDefaultValue } from './TagsModalOptions';

const AutomationRow = ({
  control,
  type,
  items,
  index,
  errors,
  defaultValue
}: {
  control: Control;
  type: TAGS_AUTOMATIONS_TYPES;
  items: { name: string; value: string }[];
  index: number;
  errors: FieldErrors;
  defaultValue?:
    | {
        value: string;
        label: string;
      }[]
    | string
    | { content: string; content_html: string };
}) => {
  const optionType = AUTOMATIONS_OPTION_DROPDOWN_TYPES[type];
  const itemsValues = items.map(item => item.value);
  const htmlTextAreaRef = useRef<MarkupEditorRef>(null);

  return (
    <AutomationRowContainer>
      <FormLabel>{TAGS_AUTOMATIONS_TYPES_TITLES[type]}</FormLabel>
      {optionType === OPTION_DROPDOWN_TYPES.SELECT && (
        <Controller
          control={control}
          name={`automations[${index}].value`}
          rules={{ required: true }}
          defaultValue={defaultValue || ''}
          render={({ onChange, value }) => {
            const options = items.map(item => ({
              value: String(item.value || item.name),
              label: item.name
            }));

            return (
              <Select
                options={options}
                value={options.find(option => option.value === value) || null}
                onChange={selectedOption => onChange(selectedOption?.value || options[0].value)}
                theme={selectTheme}
              />
            );
          }}
        />
      )}

      {optionType === OPTION_DROPDOWN_TYPES.MULTI_SELECT && (
        <Controller
          control={control}
          name={`automations[${index}].value`}
          as={
            <Select
              defaultValue={defaultValue || []}
              styles={{ container: provided => ({ ...provided, width: '100%' }), valueContainer: provided => ({ ...provided, height: 48, overflowY: 'scroll' }) }}
              options={items.map(item => ({
                ...item,
                value: item.value,
                label: item.name
              }))}
              isMulti
              theme={selectTheme}
              name={`automations[${index}].value`}
            />
          }
          defaultValue={defaultValue || []}
          rules={{ required: true, validate: (value: { value: string; label: string }[]) => value.length > 0 && value.every(selectedItem => itemsValues.includes(selectedItem.value)) }}
        />
      )}

      {optionType === OPTION_DROPDOWN_TYPES.TEXT_INPUT && (
        <Controller
          control={control}
          name={`automations[${index}].value`}
          render={({ onChange }) => {
            return (
              <MarkupEditorContainer noMargin={!defaultValue}>
                <MarkupEditorWrapper visible>
                  <MarkupEditor
                    defaultValue={defaultValue?.content_html || defaultValue?.content || ''}
                    ref={htmlTextAreaRef}
                    onChange={({ html, text }) =>
                      onChange({
                        content: text,
                        content_html: html
                      })
                    }
                  />
                </MarkupEditorWrapper>
              </MarkupEditorContainer>
            );
          }}
          rules={{ required: true }}
          defaultValue={defaultValue || ''}
        />
      )}

      {errors?.automations && errors?.automations?.[index] && errors?.automations?.[index]?.value && <FormError>Please select an automation</FormError>}
    </AutomationRowContainer>
  );
};

type BranchTagsAutomationsProps = {
  control: Control;
  errors: FieldErrors;
  existingTag?: BranchTag;
  defaultQuickTag: boolean;
  type: BRANCH_TAGS_TYPES;
  defaultType: BRANCH_TAGS_TYPES;
};

const BranchTagsAutomations = ({ control, errors, existingTag, defaultQuickTag, type, defaultType, formOptions }: BranchTagsAutomationsProps) => {
  const allAutomations = Object.values(TAGS_AUTOMATIONS_TYPES);
  const [addedRows, setAddedRows] = useState<{ index: number }[]>(existingTag?.automations?.map((_, index) => ({ index })) || []);
  const icons = useIcons();

  const { data: { branchMessageTemplateGet: branchMessages = [] } = {}, loading: loadingBranchMessages } = useQuery<{ branchMessageTemplateGet: BranchMessage[] }>(GetAllBranchMessageTemplates, {
    fetchPolicy: 'cache-and-network',
    skip: !addedRows.length
  });

  const { branchTags, loading: loadingAllTags } = useGetBranchTagsByType({
    type,
    variables: { offset: 0, limit: 1000 },
    otherParams: {
      skip: !addedRows.length
    }
  });

  const BRANCH_TAGS_AUTOMATIONS_ITEMS: Record<TAGS_AUTOMATIONS_TYPES, { name: string; value: string }[]> = {
    [TAGS_AUTOMATIONS_TYPES.SEND_MESSAGE]: branchMessages.map(branchMessage => ({
      name: branchMessage.name,
      value: branchMessage.id
    })),
    [TAGS_AUTOMATIONS_TYPES.REMOVE_TAGS]: branchTags
      ?.filter(branchTag => branchTag.id !== existingTag?.id)
      .map(branchTag => ({
        name: branchTag.name,
        value: branchTag.id
      })),
    [TAGS_AUTOMATIONS_TYPES.ADD_BRANCH_NOTE]: []
  };

  const getDefaultValues = () => {
    if (existingTag?.automations?.length) {
      const multiSelectAutomations = existingTag?.automations.filter(item => AUTOMATIONS_OPTION_DROPDOWN_TYPES[item.type] === OPTION_DROPDOWN_TYPES.MULTI_SELECT) as {
        type: TAGS_AUTOMATIONS_TYPES;
        id: string[];
      }[];

      const multiSelectAutomationsValues = multiSelectAutomations
        .map(({ type, id }) => ({
          type,
          value: BRANCH_TAGS_AUTOMATIONS_ITEMS[type].filter(item => id.includes(item.value))
        }))
        .map(item => ({
          ...item,
          value: item.value.map(({ value, name }) => ({ value, name, label: name }))
        }));

      const selectAutomations = existingTag?.automations.filter(item => AUTOMATIONS_OPTION_DROPDOWN_TYPES[item.type] === OPTION_DROPDOWN_TYPES.SELECT) as {
        type: TAGS_AUTOMATIONS_TYPES;
        id: string;
      }[];

      const selectAutomationsValues = selectAutomations.map(({ type, id }) => ({
        type,
        value: BRANCH_TAGS_AUTOMATIONS_ITEMS[type].find(item => item.value === id)?.value!
      }));

      const otherAutomations = existingTag?.automations.filter(item => ![OPTION_DROPDOWN_TYPES.MULTI_SELECT, OPTION_DROPDOWN_TYPES.SELECT].includes(AUTOMATIONS_OPTION_DROPDOWN_TYPES[item.type]));

      const automations = [...multiSelectAutomationsValues, ...selectAutomationsValues, ...otherAutomations];

      return automations;
    }
  };

  const defaultValues = getDefaultValues()! || [];
  const defaultValuesTagsOptions = getBranchTagsOptionsDefaultValue(existingTag);

  const selectedAutomations = useWatch<{ type: TAGS_AUTOMATIONS_TYPES; value: string | { value: string; label: string }[] }[]>({
    control,
    name: 'automations',
    defaultValue: defaultValues
  });

  useEffect(() => {
    if (type === defaultType) {
      control.setValue(`automations`, defaultValues);
      setAddedRows(defaultValues.map((_, index) => ({ index })));
      return;
    }

    control.setValue(`automations`, []);
    setAddedRows([]);
  }, [type]);

  const addRow = () => {
    const index = addedRows.length;
    if (index > 0) {
      const previousRow = selectedAutomations?.[index - 1];
      if (!previousRow?.type) {
        return;
      }
    }

    setAddedRows([...addedRows, { index }]);
  };

  const removeRow = (index: number) => {
    setAddedRows(addedRows.filter(row => row.index !== index));
  };
  const loading = loadingBranchMessages || loadingAllTags;
  return (
    <>
      <Divider marginBottom="20px" />
      {loading && <CenteredLoader size={20} />}
      {!loading && (
        <>
          <AutomationsContainer>
            <AutomationsFooterContainer>
              <FormLabel marginBottom={20} width="100%" bold>
                Visibility
              </FormLabel>

              <BranchTagsOptions
                formOptions={formOptions}
                type={type}
                defaultValues={defaultValuesTagsOptions}
                optionsToShow={[BranchTagsOptionsTypes.SHOW_ON_APPOINTMENTS, BranchTagsOptionsTypes.SHOW_ON_BAR, BranchTagsOptionsTypes.SHOW_ON_TAGGED_ITEM, BranchTagsOptionsTypes.QUICK_ACTION]}
              />
              <Divider marginBottom="20px" marginTop="20px" />

              <FormLabel width="100%" bold>
                Automations
              </FormLabel>
              <BranchTagsOptions formOptions={formOptions} type={type} defaultValues={defaultValuesTagsOptions} optionsToShow={[BranchTagsOptionsTypes.RESET_AT_MIDNIGHT]}></BranchTagsOptions>
              {addedRows.map(({ index }) => {
                const selectedAutomation = selectedAutomations?.[index]?.type!;
                return (
                  <Fragment key={index}>
                    <Controller
                      control={control}
                      name={`automations[${index}].type`}
                      rules={{ required: true }}
                      defaultValue={defaultValues[index]?.type || ''}
                      render={({ value, onChange }) => {
                        const options = allAutomations.map(automationType => ({
                          value: automationType,
                          label: TAGS_AUTOMATIONS_TYPES_LABELS[automationType],
                          isDisabled: selectedAutomations?.some(selectedAutomation => selectedAutomation.type === automationType)
                        }));

                        return (
                          <AutomationContainer>
                            <AutomationTypeContainer>
                              <Select
                                options={options}
                                value={options.find(option => option.value === value) || null}
                                onChange={selectedOption => onChange(selectedOption?.value || '')}
                                theme={selectTheme}
                              />
                              <RemoveRowContainer>
                                <RemoveAddedImage src={icons.delete.childImageSharp.gatsbyImageData.images.fallback.src} onClick={() => removeRow(index)} />
                              </RemoveRowContainer>
                            </AutomationTypeContainer>

                            {selectedAutomation && (
                              <AutomationRowWrapper>
                                <AutomationSeperator />
                                <AutomationRow
                                  control={control}
                                  index={index}
                                  type={selectedAutomation}
                                  items={BRANCH_TAGS_AUTOMATIONS_ITEMS[selectedAutomation]}
                                  errors={errors}
                                  defaultValue={defaultValues.find(item => item.type === selectedAutomation)?.value}
                                />
                              </AutomationRowWrapper>
                            )}
                          </AutomationContainer>
                        );
                      }}
                    />

                    {errors?.automations?.[index]?.type && <FormError>Please select an automation</FormError>}
                  </Fragment>
                );
              })}
              <AddNewBtn onClick={addRow} width="100%" marginTop="20px">
                <AddNewBtnIcon src={icons.plusBlack.childImageSharp.gatsbyImageData.images.fallback.src} />
                Add New Automation
              </AddNewBtn>
            </AutomationsFooterContainer>
          </AutomationsContainer>
        </>
      )}
    </>
  );
};

export default BranchTagsAutomations;
