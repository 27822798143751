import React from 'react';
import BranchRouteDetails from './BranchRouteDetails';
import useURLSearchParams from '../../hooks/useURLSearchParams';

const FleetsRoutes = ({ isShortestPath }: { isShortestPath: boolean }) => {
  const [route] = useURLSearchParams('route');

  return <>{route && <BranchRouteDetails routeId={route[0]} isShortestPath={isShortestPath} />}</>;
};

export default FleetsRoutes;
