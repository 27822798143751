import React from 'react';
import { Pet } from '../../../components/Pets/types';
import { formatOrdinals, sortByTime, toMealReadableText, toSuppReadableText } from '../../../utils/dates';
import { getUnit } from '../../../utils/validators';
import {
  DietRecordBody,
  DietRecordContainer,
  DietRecordHeader,
  DietRecordsContainer,
  DietsWrapper,
  Divider,
  HealthPetRecordDietContainer,
  PetRecordEntitiesContainer,
  PetRecordEntitySubLabel,
  PetRecordWrapper,
  VetDaycareTitle
} from './styled';
import { EditPetRecordFeedingRoutine, EditPetRecordMeals } from './Health';
import { FlexContainer } from '../../../components/Pets/styled';
import { useQuery } from '@apollo/client';
import { GetPetBehaviors } from '../../../queries';

export const DietMealRecord = ({ meal, name, time, quantity, unit, flex1 }: { meal: string; name: string; time: string; quantity: string; unit: string; flex1?: boolean }) => (
  <DietRecordContainer flex1={flex1}>
    <DietRecordHeader>
      <span>{meal}</span>
      <span>
        {' - '} {time}
      </span>
    </DietRecordHeader>
    <DietRecordBody>
      <span>{name}</span>
      <span>
        {' - '}
        {quantity}
        {unit}
      </span>
    </DietRecordBody>
  </DietRecordContainer>
);

export const DietSupplementRecord = ({ name, type, time, quantity, asNeeded }: { name: string; type: string; time: string; quantity: string; asNeeded: boolean }) => (
  <DietRecordContainer>
    <DietRecordHeader>
      <span>{name}</span>
      <span>
        {' - '}
        {type}
      </span>
    </DietRecordHeader>
    <DietRecordBody>
      <span>{time ? time : `${asNeeded ? 'As Needed' : 'One-Off'}`}</span>
      <span>
        {' - '}
        {quantity}
      </span>
    </DietRecordBody>
  </DietRecordContainer>
);

export const PetFeedingRoutineBehavior = ({ petBehavior }: { petBehavior: Pet['PetRecord']['PetBehavior'] }) => (
  <>
    <PetRecordEntitySubLabel>{petBehavior?.feeding_routine || ' - '}</PetRecordEntitySubLabel>
  </>
);

export const PetTreatmentRoutineBehavior = ({ petBehavior }: { petBehavior: Pet['PetRecord']['PetBehavior'] }) => (
  <PetRecordEntitySubLabel>{petBehavior?.treatment_routine || ' - '}</PetRecordEntitySubLabel>
);

const HealthPetRecordDiet = ({ pet }: { pet: Pet }) => {
  const meals = sortByTime(pet?.PetRecord?.Meals || []);
  const mealsRecords = meals.map((item, index) => (
    <DietMealRecord key={item?.id} meal={`${formatOrdinals(index + 1)} Meal`} name={item?.name} time={toMealReadableText(item.time)} unit={getUnit(item.unit)} quantity={item.quantity} />
  ));

  const suppRecords = pet?.PetRecord?.SuppRecords?.filter(record => record.type === 'supplement').map(item => (
    <DietSupplementRecord
      key={item?.id}
      name={item.Product?.ProductKinds[0]?.name}
      type={item.Product?.name}
      time={toSuppReadableText(item.time)}
      asNeeded={item.adherences_allowed}
      quantity={`${item.quantity}${getUnit(item.Product?.unit)}`}
    />
  ));

  const mealTypes = pet?.PetRecord?.BranchCategories?.map(category => category.name).join(' & ');
  const { data: { getPetBehaviors: petBehaviors } = {} } = useQuery<{ getPetBehaviors: Pet['PetRecord']['PetBehavior'][] }>(GetPetBehaviors, {
    variables: { PetRecordId: pet?.PetRecord?.id },
    fetchPolicy: 'cache-and-network'
  });

  const petProfileBehavior = petBehaviors?.[0];

  return (
    <>
      <HealthPetRecordDietContainer>
        <PetRecordWrapper>
          <FlexContainer>
            <VetDaycareTitle>Meal Schedule</VetDaycareTitle>
            <EditPetRecordMeals pet={pet} />
          </FlexContainer>
          <DietsWrapper>
            <DietRecordsContainer>{mealsRecords}</DietRecordsContainer>
          </DietsWrapper>
        </PetRecordWrapper>
        <Divider />
        {petProfileBehavior && (
          <PetRecordEntitiesContainer column>
            <VetDaycareTitle>
              <span>Feeding Routine</span>
              <EditPetRecordFeedingRoutine petProfileBehavior={petProfileBehavior} />
            </VetDaycareTitle>

            <PetFeedingRoutineBehavior petBehavior={petProfileBehavior} />
          </PetRecordEntitiesContainer>
        )}
      </HealthPetRecordDietContainer>

      {/* <PetRecordWrapper>
          <VetDaycareTitle>Supplements & Medications</VetDaycareTitle>
          <DietsWrapper>
            <DietRecordsContainer>{suppRecords}</DietRecordsContainer>
          </DietsWrapper>
        </PetRecordWrapper> */}
    </>
  );
};

export default HealthPetRecordDiet;
