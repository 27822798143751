import { useQuery } from '@apollo/client';
import React, { Fragment } from 'react';
import { Controller, UseFieldArrayMethods, UseFormMethods } from 'react-hook-form';
import { BusUserProfile } from '../../../components/Profile/types';
import { FormLabel, RemoveRowContainer } from '../../../components/Shared/Forms/Forms';
import { GetAllBranchRoutes, GetAllBranchBusUsers } from '../../../queries';
import { RemoveAddedImage } from '../../Store/styled';
import { BranchRoute, BranchVehicle } from '../types';
import { BranchVehicleFormValues, BranchVehicleRoutes } from './types';
import {
  AutomationContainer,
  AutomationRowContainer,
  AutomationRowWrapper,
  AutomationsContainer,
  AutomationSeperator,
  AutomationsFooterContainer,
  AutomationTypeContainer
} from '../../Store/BranchTags/styled';
import Select from '../../../components/Shared/Forms/Select';
import useIcons from '../../../hooks/useIcons';
import { AddNewBtn, AddNewBtnIcon } from '../../Store/Products/styled';

const BranchVehicleModalRoutes = ({
  formOptions,
  branchVehicleRoutesFieldArray
}: {
  formOptions: UseFormMethods<BranchVehicleFormValues>;
  branchVehicleRoutesFieldArray: UseFieldArrayMethods<BranchVehicleRoutes, 'id'>;
}) => {
  const { control, errors, watch } = formOptions;
  const { branchVehicle: { BranchVehicleRoutes: watchedBranchVehicleRoutes = [] } = {} } = watch(['branchVehicle']);

  const { data: { getBranchRoutes: branchRoutes = [] } = {}, loading: loadingBranchRoutes } = useQuery<{ getBranchRoutes: BranchRoute[] }>(GetAllBranchRoutes, {
    fetchPolicy: 'cache-and-network'
  });

  const { data: { getBranchBusUsers: BusUsers = [] } = {} } = useQuery<{ getBranchBusUsers: BusUserProfile[] }>(GetAllBranchBusUsers);

  const routesOptions = branchRoutes.map(route => ({ label: route.name, value: route.id }));

  const icons = useIcons();

  return (
    <AutomationsContainer>
      <AutomationsFooterContainer>
        {!loadingBranchRoutes &&
          branchVehicleRoutesFieldArray.fields.map((branchVehicleRoute, index) => {
            const hasSelectedRoute = !!watchedBranchVehicleRoutes[index]?.BranchRouteId;
            return (
              <Fragment key={branchVehicleRoute?.id}>
                <Controller
                  name={`branchVehicle.BranchVehicleRoutes[${index}].BranchRouteId`}
                  control={control}
                  rules={{ required: true }}
                  render={({ onChange, value }) => (
                    <AutomationContainer>
                      <AutomationTypeContainer>
                        <Select
                          options={routesOptions.filter(option => !watchedBranchVehicleRoutes.map(route => route?.BranchRouteId).includes(option?.value))}
                          placeholder="Select..."
                          onChange={newValue => {
                            onChange(newValue?.value);
                          }}
                          value={value ? routesOptions.find(option => option?.value === value) : null}
                        />
                        <RemoveRowContainer>
                          <RemoveAddedImage src={icons.delete.childImageSharp.gatsbyImageData.images.fallback.src} onClick={() => branchVehicleRoutesFieldArray.remove(index)} />
                        </RemoveRowContainer>
                      </AutomationTypeContainer>

                      {!!hasSelectedRoute && (
                        <AutomationRowWrapper>
                          <AutomationSeperator />
                          <AutomationRowContainer>
                            <FormLabel>Staff Member</FormLabel>
                            <Controller
                              name={`branchVehicle.BranchVehicleRoutes[${index}].BusUserId`}
                              render={({ onChange, value }) => (
                                <Select
                                  isMulti
                                  placeholder="Select..."
                                  options={BusUsers.map(user => ({ label: user.name, value: user.id }))}
                                  onChange={newValue => {
                                    onChange(newValue?.map(option => option?.value));
                                  }}
                                  value={[...(value || [])]?.map(id => ({ label: BusUsers.find(user => user.id === id)?.name, value: id }))}
                                />
                              )}
                              control={control}
                              defaultValue={branchVehicleRoute?.BusUserId || []}
                            />
                          </AutomationRowContainer>
                        </AutomationRowWrapper>
                      )}
                    </AutomationContainer>
                  )}
                  defaultValue={branchVehicleRoute?.BranchRouteId || ''}
                />
              </Fragment>
            );
          })}

        <AddNewBtn
          onClick={() => {
            branchVehicleRoutesFieldArray.append({
              BranchRouteId: '',
              BusUserId: []
            });
          }}
          width="100%"
          marginTop="20px"
        >
          <AddNewBtnIcon src={icons.plusBlack.childImageSharp.gatsbyImageData.images.fallback.src} />
          Add New Route
        </AddNewBtn>
      </AutomationsFooterContainer>
    </AutomationsContainer>
  );
};

export default BranchVehicleModalRoutes;
