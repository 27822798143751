import { useQuery } from '@apollo/client';
import React, { useRef } from 'react';
import { GetBranchRoute, GetBusUserProfile } from '../../queries';
import { BranchRoute } from './types';
import RoutesMap, { RoutesMapRef } from './RoutesMap';
import { setDrawerBar } from '../../reactive/actions';
import { DRAWER_IDS } from '../../components/DrawerBar/types';
import FleetsPetList from './FleetsPetList';
import { BusUserProfile } from '../../components/Profile/types';

const BranchRouteDetails = ({ routeId, isShortestPath }: { routeId: string; isShortestPath: boolean }) => {
  const { data: { getBusUserProfile: currentBusUserProfile } = {} } = useQuery<{ getBusUserProfile: BusUserProfile }>(GetBusUserProfile, {
    fetchPolicy: 'cache-only'
  });
  const { data: { getBranchRoutes: [branchRoute] = [] } = {} } = useQuery<{ getBranchRoutes: BranchRoute[] }>(GetBranchRoute, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: [routeId]
    }
  });

  const branchStop =
    currentBusUserProfile?.Branch?.latitude && currentBusUserProfile?.Branch?.longitude
      ? [
          {
            lat: +currentBusUserProfile?.Branch?.latitude,
            lng: +currentBusUserProfile?.Branch?.longitude,
            id: currentBusUserProfile?.Branch?.id,
            image: currentBusUserProfile?.Branch?.photos?.logo,
            onClick: () => {}
          }
        ]
      : [];

  const allStops = branchRoute?.BranchRoutePetRecords?.filter(record => !!record?.PetRecord?.Pet?.AppUser?.addresses?.[0]?.geoLocation)
    ?.sort((a, b) => (a.sort_index || 0) - (b.sort_index || 0))
    ?.map(record => {
      const geoLocation = record?.PetRecord?.Pet?.AppUser?.addresses?.[0]?.geoLocation || {};

      return {
        lat: geoLocation?.lat,
        lng: geoLocation?.lng,
        id: record?.PetRecord?.Pet?.id,
        image: record?.PetRecord?.Pet?.profile_pic,
        index: record?.sort_index,
        onClick: () => {
          setDrawerBar({
            drawerId: DRAWER_IDS.PETS_DRAWER,
            recordData: record?.PetRecord?.Pet?.id
          });
        }
      };
    });

  const ref = useRef<RoutesMapRef>(null);

  const onClickPet = (id: string) => {
    const stop = allStops?.find(stop => stop.id === id);

    if (stop) {
      ref.current?.focusOnStop(stop?.id);
    }
  };

  return (
    <div style={{ display: 'flex', height: '100%' }}>
      <FleetsPetList branchRoute={branchRoute} onClickPet={onClickPet} />

      {!!branchRoute?.id && (
        <RoutesMap
          // key={branchRoute?.id}
          stops={[...branchStop, ...allStops, ...branchStop]}
          ref={ref}
          routeColor={branchRoute?.color}
          isShortestPath={isShortestPath}
          // routeStartTime={new Date(new Date().setHours(+branchRoute?.start_time.split(':')[0], 0, 0, 0))}
        />
      )}
    </div>
  );
};

export default BranchRouteDetails;
