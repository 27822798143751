import React, { FC } from 'react';
import { ExtensionPetCaseLabel } from '../../../components/Pets/styled';

type NoteSectionHtmlProps = {
  item: Partial<Record<string, string>>;
  keyName?: string;
};

const NoteSectionHtml: FC<NoteSectionHtmlProps> = ({ item, keyName = 'details' }) => {
  const details = item?.[keyName];
  const detailsHtml = item?.[`${keyName}_html`];
  return (
    <>
      {details && (
        <>
          {!detailsHtml && (
            <ExtensionPetCaseLabel fontSize={16} fontWeight={'400'} margingB={17}>
              {details}
            </ExtensionPetCaseLabel>
          )}
          {detailsHtml && <div dangerouslySetInnerHTML={{ __html: detailsHtml }} />}
        </>
      )}
    </>
  );
};

export default NoteSectionHtml;
