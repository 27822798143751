import { vars } from '../../reactive';
import { clearDrawerBars, setDrawerBar } from '../../reactive/actions';
import { DRAWER_IDS } from '../DrawerBar/types';
import { PETS_EXTENSTION_TABS, PETS_EXTENSTION_TABS_IDS } from '../PetDrawer/PetExtension';
export enum NOTE_TYPES {
  MEDICAL = 'MEDICAL',
  GROOMING = 'GROOMING',
  TRAINING = 'TRAINING',
  WALKING = 'WALKING',
  SITTING = 'SITTING',
  DAYCARE = 'DAYCARE'
}

export const handleReportAdd = ({
  noteType,
  petProfile,
  autoOpenModal = true,
  orderId,
  selectedReportId
}: {
  noteType: NOTE_TYPES;
  petProfile: Record<string, any>;
  autoOpenModal?: boolean;
  orderId?: string;
  selectedReportId?: string;
}) => {
  const onClose = () => {
    const drawerBar = vars.drawerBars().find(drawerBar => drawerBar.drawerId === DRAWER_IDS.PETS_DRAWER);
    setDrawerBar({ ...(drawerBar || { drawerId: DRAWER_IDS.PETS_DRAWER, isExtended: true }), otherData: {}, isExtended: true });
  };

  const handleClick = ({ noteType }: { noteType: NOTE_TYPES }) => {
    clearDrawerBars();

    setDrawerBar({
      drawerId: DRAWER_IDS.PETS_DRAWER,
      recordData: petProfile.id,
      isExtended: true,
      otherData: {
        selectedTab: PETS_EXTENSTION_TABS_IDS.REPORTS,
        selectedNoteType: noteType,
        onClose,
        selectedReportId,
        autoOpenModal,
        orderId
      }
    });
  };

  return handleClick({ noteType });
};
