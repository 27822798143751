import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { BranchServiceOrdersViewCountsSubscription, GetBranchServiceOrdersViewCounts, GetBusUserProfile } from '../queries';

export type GetBranchServiceOrdersViewCountsReturn = {
  newServiceOrdersCount: number;
  paymentServiceOrdersCount: number;
  canceledServiceOrdersCount: number;
  shouldNotify: boolean;
};

const useBranchServiceOrdersViewCounts = () => {
  const { data: { getBusUserProfile: profile = {} } = {} } = useQuery(GetBusUserProfile, {
    fetchPolicy: 'cache-only'
  });

  const { data: unreadOrdersCount, subscribeToMore } = useQuery<{ getBranchServiceOrdersViewCounts: GetBranchServiceOrdersViewCountsReturn }>(GetBranchServiceOrdersViewCounts, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true
  });

  const { newServiceOrdersCount, paymentServiceOrdersCount, canceledServiceOrdersCount, shouldNotify } = unreadOrdersCount?.getBranchServiceOrdersViewCounts || {};

  useEffect(() => {
    const sub = subscribeToMore<{ branchServiceOrdersViewCountsUpdated: GetBranchServiceOrdersViewCountsReturn }>({
      document: BranchServiceOrdersViewCountsSubscription,
      variables: { BranchId: [profile?.Branch?.id] },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData) {
          return prev;
        }

        const newCounts = subscriptionData?.data?.branchServiceOrdersViewCountsUpdated;

        return Object.assign({}, prev, {
          getBranchServiceOrdersViewCounts: {
            newServiceOrdersCount: newCounts.newServiceOrdersCount,
            paymentServiceOrdersCount: newCounts.paymentServiceOrdersCount,
            canceledServiceOrdersCount: newCounts.canceledServiceOrdersCount,
            shouldNotify: newCounts.shouldNotify,
            __typename: 'BranchServiceOrdersViewCount'
          }
        });
      }
    });
    return () => sub();
  }, [subscribeToMore, profile?.Branch?.id]);

  return {
    newServiceOrdersCount,
    paymentServiceOrdersCount,
    canceledServiceOrdersCount,
    shouldNotify
  };
};

export default useBranchServiceOrdersViewCounts;
