import { useQuery } from '@apollo/client';
import { getImage } from 'gatsby-plugin-image';
import React, { Fragment } from 'react';
import { sentenceCase } from 'sentence-case';
import { ExtensionDivider, ExtensionPetCaseLabel, FlexContainer } from '../../../../components/Pets/styled';
import useIcons from '../../../../hooks/useIcons';
import { GetBusUserProfile } from '../../../../queries';
import { AddedImage, AddedImageLink, FlexColumnCenterBetweenContainer, PhotosContainer } from '../../../Store/styled';
import { DaycareReportNoteContainer, ReportNoteSubCategoryContainer, ReportNoteSubCategoryTitle } from './styled';
import { toReadableDate } from '../../../../utils/dates';
import DaycareReportNoteAction, { NOTE_ACTION_TYPES } from './DaycareReportNoteAction';

type SubCategory = {
  status?: string;
  details?: string;
  details_html?: string;
};

const DaycareReportNote = ({ note, selectedReport }: { note: Record<string, any>; selectedReport: Record<string, any> }) => {
  const icons = useIcons();
  const mealIcon = getImage(icons?.meal);
  const sleepIcon = getImage(icons?.sleep);
  const pooIcon = getImage(icons?.poo);
  const { data: { getBusUserProfile: profile = {} } = {} } = useQuery(GetBusUserProfile);
  const titles: string[] = Object.keys(note?.body || {});
  const categories: Record<string, SubCategory>[] = Object.values(note?.body || {});
  const photos = note?.body?.photos?.primary || [];
  const attachments = note?.body?.attachments?.primary || [];
  return (
    <DaycareReportNoteContainer>
      <FlexContainer marginB={16}>
        <ExtensionPetCaseLabel fontSize={18} fontWeight={'800'}>
          {selectedReport?.name}
        </ExtensionPetCaseLabel>
        {profile?.id === note?.BusUser?.id && <DaycareReportNoteAction type={NOTE_ACTION_TYPES.EDIT} note={note} selectedReport={selectedReport} />}
      </FlexContainer>
      <FlexContainer gap={8} column>
        <FlexContainer>
          <ExtensionPetCaseLabel fontSize={14} fontWeight="700">
            Date:
          </ExtensionPetCaseLabel>
          <ExtensionPetCaseLabel fontSize={14} fontWeight={'600'} margingB={10}>
            {toReadableDate(note?.timestamp, { isLocale: true })}
          </ExtensionPetCaseLabel>
        </FlexContainer>
        <ExtensionDivider />
        <FlexContainer>
          <ExtensionPetCaseLabel fontSize={14} fontWeight="700">
            Created By:
          </ExtensionPetCaseLabel>
          <ExtensionPetCaseLabel fontSize={12} fontWeight={'normal'} margingB={10}>
            {note?.BusUser?.name}
          </ExtensionPetCaseLabel>
        </FlexContainer>
        <ExtensionDivider />
      </FlexContainer>
      {selectedReport?.Appointment?.id && (
        <FlexContainer marginT={8} marginB={8}>
          <ExtensionPetCaseLabel fontSize={14} width={100} fontWeight={700}>
            Appointment:
          </ExtensionPetCaseLabel>
          <ExtensionPetCaseLabel fontSize={14} fontWeight={'500'}>
            {selectedReport.Appointment?.OrderItem?.item?.name} - {toReadableDate(selectedReport.Appointment?.timestamp || new Date(), { isLocale: true })}
          </ExtensionPetCaseLabel>
        </FlexContainer>
      )}
      {/* <FlexCenterBetweenContainer style={{ flexWrap: 'wrap' }}>
        {!!note?.body?.routine?.meals?.details && (
          <TempBPMContainer>
            <ExtensionPetCaseLabel fontSize={16} fontWeight={'bold'}>
              <TempBPMIcon image={mealIcon!} alt="bpm" />
              {note?.body?.routine?.meals?.details}
              <NoteNumbersSub>Meals</NoteNumbersSub>
            </ExtensionPetCaseLabel>
          </TempBPMContainer>
        )}
        {!!note?.body?.routine?.sleep?.details && (
          <TempBPMContainer>
            <ExtensionPetCaseLabel fontSize={16} fontWeight={'bold'}>
              <TempBPMIcon image={sleepIcon!} alt="bpm" />
              {note?.body?.routine?.sleep?.details}
              <NoteNumbersSub>Hours</NoteNumbersSub>
            </ExtensionPetCaseLabel>
          </TempBPMContainer>
        )}
        {!!note?.body?.routine?.poos?.details && (
          <TempBPMContainer>
            <ExtensionPetCaseLabel fontSize={16} fontWeight={'bold'}>
              <TempBPMIcon image={pooIcon!} alt="bpm" />
              {note?.body?.routine?.poos?.details}
              <NoteNumbersSub>Poos</NoteNumbersSub>
            </ExtensionPetCaseLabel>
          </TempBPMContainer>
        )}
      </FlexCenterBetweenContainer> */}

      {titles.map((title, index) => {
        const category = categories[index];
        const subCategoriesTitles = Object.keys(category);
        const subCategories = Object.values(category);
        if (subCategories.some(subCategory => subCategory?.status || subCategory?.details)) {
          return (
            <Fragment key={title}>
              {/* <ExtensionPetCaseLabel fontSize={18} fontWeight={'800'} margingB={16}>
                {sentenceCase(title)}
              </ExtensionPetCaseLabel> */}

              {subCategoriesTitles.map((subTitle, subIndex) => {
                const subCategory = subCategories[subIndex];
                if (subCategory?.status || subCategory?.details) {
                  return (
                    <ReportNoteSubCategoryContainer key={subTitle}>
                      {subCategoriesTitles[0] !== 'notes' && <ReportNoteSubCategoryTitle>{sentenceCase(subTitle)}:</ReportNoteSubCategoryTitle>}
                      <span>
                        {subCategory?.status && sentenceCase(subCategory?.status)}{' '}
                        {subCategory?.details && (
                          <>
                            {subCategory?.details_html && <span dangerouslySetInnerHTML={{ __html: subCategory?.details_html }} />}
                            {!subCategory?.details_html && sentenceCase(subCategory?.details)}
                          </>
                        )}
                      </span>
                    </ReportNoteSubCategoryContainer>
                  );
                }
              })}
              <ExtensionDivider />
            </Fragment>
          );
        }
      })}

      {Object.values(note?.body?.routine || {})?.some?.(number => !!number?.details) && <ExtensionDivider />}
      {!!photos?.length && (
        <>
          <ExtensionPetCaseLabel fontSize={14} fontWeight={'700'} margingB={12} margingT={12}>
            Photos
          </ExtensionPetCaseLabel>
          <PhotosContainer>
            {photos?.map((photo: string) => (
              <FlexColumnCenterBetweenContainer key={photo}>
                <AddedImageLink href={photo} target="_blank" rel="noopener noreferrer">
                  <AddedImage src={photo} alt="report image" />
                </AddedImageLink>
              </FlexColumnCenterBetweenContainer>
            ))}
          </PhotosContainer>
          <ExtensionDivider />
        </>
      )}
      {!!attachments?.length && (
        <>
          <ExtensionPetCaseLabel fontSize={14} fontWeight={'700'} margingB={12} margingT={12}>
            Attachments
          </ExtensionPetCaseLabel>
          <PhotosContainer>
            {attachments?.map((attachment: string) => (
              <FlexColumnCenterBetweenContainer key={attachment}>
                <AddedImageLink href={attachment} target="_blank" rel="noopener noreferrer">
                  <AddedImage src={icons.pdfPlaceholder.childImageSharp.gatsbyImageData.images.fallback.src} alt="report image" />
                </AddedImageLink>
              </FlexColumnCenterBetweenContainer>
            ))}
          </PhotosContainer>
          <ExtensionDivider />
        </>
      )}

      {/* {titles.map((title, index) => {
        const category = categories[index];
        const subCategoriesTitles = Object.keys(category);
        const subCategories = Object.values(category);
        if (subCategories.some(subCategory => subCategory?.status || subCategory?.details)) {
          return (
            <Fragment key={title}>
              <ExtensionPetCaseLabel fontSize={18} fontWeight={'800'} margingB={16}>
                {sentenceCase(title)}
              </ExtensionPetCaseLabel>
              {subCategoriesTitles.map((subTitle, subIndex) => {
                const subCategory = subCategories[subIndex];
                if (subCategory?.status || subCategory?.details) {
                  return (
                    <ReportNoteSubCategoryContainer key={subTitle}>
                      {subCategoriesTitles[0] !== 'notes' && <ReportNoteSubCategoryTitle>{sentenceCase(subTitle)}:</ReportNoteSubCategoryTitle>}
                      <span>
                        {subCategory?.status && sentenceCase(subCategory?.status)}{' '}
                        {subCategory?.details && (
                          <>
                            {subCategory?.details_html && <span dangerouslySetInnerHTML={{ __html: subCategory?.details_html }} />}
                            {!subCategory?.details_html && sentenceCase(subCategory?.details)}
                          </>
                        )}
                      </span>
                    </ReportNoteSubCategoryContainer>
                  );
                }
              })}
              <ExtensionDivider />
            </Fragment>
          );
        }
      })} */}
    </DaycareReportNoteContainer>
  );
};

export default DaycareReportNote;
