import { ComputedDatum, ResponsiveBar } from '@nivo/bar';
import { PropertyAccessor } from '@nivo/core';
import React from 'react';

const BarChartValueVolume = ({
  data,
  keys,
  formatLabel,
  labels,
  groupMode = 'grouped'
}: {
  data: { id: string; name: string; value: string; volume: string }[];
  keys: string[];
  labels: { left: string; bottom: string; right?: string };
  formatLabel?: PropertyAccessor<
    ComputedDatum<{
      id: string;
      name: string;
      value: string;
      volume: string;
    }>,
    string
  >;
  groupMode?: 'stacked' | 'grouped';
}) => {
  return (
    <ResponsiveBar
      data={data}
      keys={keys}
      enableGridX
      indexBy="name"
      margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
      padding={0.3}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={{ scheme: 'set3' }}
      groupMode={groupMode}
      label={formatLabel ?? undefined}
      defs={[
        {
          id: 'dots',
          type: 'patternDots',
          background: 'inherit',
          color: '#38bcb2',
          size: 4,
          padding: 1,
          stagger: true
        },
        {
          id: 'lines',
          type: 'patternLines',
          background: 'inherit',
          color: '#eed312',
          rotation: -45,
          lineWidth: 6,
          spacing: 10
        }
      ]}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 1.6]]
      }}
      axisTop={null}
      axisRight={
        labels.right
          ? {
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: labels.right,
              legendPosition: 'middle',
              legendOffset: 32
            }
          : null
      }
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: labels.bottom,
        legendPosition: 'middle',
        legendOffset: 32
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: labels.left,
        legendPosition: 'middle',
        legendOffset: -40
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: 'color',
        modifiers: [['darker', 1.6]]
      }}
      legends={[
        {
          dataFrom: 'keys',
          anchor: 'bottom-right',
          direction: 'column',
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: 'left-to-right',
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: 'hover',
              style: {
                itemOpacity: 1
              }
            }
          ]
        }
      ]}
      role="application"
      ariaLabel="Nivo bar chart demo"
      barAriaLabel={e => e.id + ': ' + e.formattedValue + ' in country: ' + e.indexValue}
    />
  );
};

export default BarChartValueVolume;
