import { useQuery } from '@apollo/client';
import React from 'react';
import { DRAWER_IDS } from '../../../components/DrawerBar/types';
import { GetBranchPetVetInsurer } from '../../../queries';
import { vars } from '../../../reactive';

import { CounterBtn } from '../../../components/Calendar/styled';
import ModalDialog from '../../../components/Modal/ModalDialog';
import { Pet } from '../../../components/Pets/types';
import useIcons from '../../../hooks/useIcons';
import {
  PetRecordEntitiesContainer,
  PetRecordEntity,
  PetRecordEntityLabel,
  PetRecordEntitySubLabel,
  PetRecordRecordsOptions,
  SectionContainer,
  SectionWrapper,
  SectionsContainer,
  VetDaycareTitle
} from '../Health/styled';
import InsurancePetRecordModal from './InsuranceModal';
import VetPetRecordModal from './VetModal';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { MenuButton } from '../../../components/Shared/Menus/OptionDropdown/styled';

const VetInsurance = () => {
  const { mobile } = useMediaQuery({ mobile: true });
  const petId = vars.drawerBars().find(drawer => drawer.drawerId === DRAWER_IDS.PETS_DRAWER)?.recordData;
  const { data: { getBranchPetProfile: petProfile } = {} } = useQuery<{ getBranchPetProfile: Pet }>(GetBranchPetVetInsurer, {
    variables: { id: petId },
    fetchPolicy: 'cache-and-network',
    skip: !petId
  });

  const insurer = petProfile?.PetRecord?.insurer;
  const vet = petProfile?.PetRecord?.vet;
  const vetAddress = vet?.address || {};
  const insurerAddress = insurer?.address || {};

  const vetDataSections = [
    [
      { label: 'Practice', subLabel: vet?.name },
      {
        label: 'Address',
        subLabel: Object.values(vetAddress)?.map((address, index) => <div key={index}>{address}</div>)
      }
    ],
    [
      { label: 'Phone', subLabel: vet?.phone?.number ? `${vet?.phone?.country_code}${vet?.phone?.number}` : '' },
      { label: 'Email Address', subLabel: vet?.email }
    ]
  ];

  const insuranceDataSections = [
    [
      { label: 'Insurer', subLabel: insurer?.name },
      { label: 'Policy Number ', subLabel: insurer?.policy_number },
      { label: 'Start Date', subLabel: insurer?.policy_start },
      { label: 'End Date', subLabel: insurer?.policy_end }
    ],
    [
      { label: 'Email', subLabel: insurer?.email },
      { label: 'Phone', subLabel: insurer?.phone?.number ? `${insurer?.phone?.country_code}${vet?.phone?.number}` : '' },
      {
        label: 'Address',
        subLabel: Object.values(insurerAddress)?.map((address, index) => <div key={index}>{address}</div>)
      }
    ]
  ];

  return (
    <SectionsContainer>
      <SectionContainer>
        <PetRecordEntitiesContainer column>
          <VetDaycareTitle>
            Vet
            {petProfile && <EditVetPetRecord petVetInsurer={petProfile} />}
          </VetDaycareTitle>

          <PetRecordEntitiesContainer noPadding noBorderRadius mobilePadding="0" column={mobile}>
            {vetDataSections.map((section, index) => (
              <SectionWrapper key={index}>
                {section.map((item, i) => (
                  <PetRecordEntity key={i}>
                    <PetRecordEntitySubLabel>{item.label}</PetRecordEntitySubLabel>
                    <PetRecordEntityLabel>{item.subLabel ? item.subLabel : '-'}</PetRecordEntityLabel>
                  </PetRecordEntity>
                ))}
              </SectionWrapper>
            ))}
          </PetRecordEntitiesContainer>
        </PetRecordEntitiesContainer>
      </SectionContainer>

      <SectionContainer>
        <PetRecordEntitiesContainer column>
          <PetRecordRecordsOptions>
            <VetDaycareTitle>
              Insurance
              {petProfile && <EditInsurerPetRecord petVetInsurer={petProfile} />}
            </VetDaycareTitle>
          </PetRecordRecordsOptions>
          <PetRecordEntitiesContainer noPadding noBorderRadius mobilePadding="0" column={mobile}>
            {insuranceDataSections.map((section, index) => (
              <SectionWrapper key={index}>
                {section.map((item, i) => (
                  <PetRecordEntity key={i}>
                    <PetRecordEntitySubLabel>{item.label}</PetRecordEntitySubLabel>
                    <PetRecordEntityLabel>{item.subLabel ? item.subLabel : '-'}</PetRecordEntityLabel>
                  </PetRecordEntity>
                ))}
              </SectionWrapper>
            ))}
          </PetRecordEntitiesContainer>
        </PetRecordEntitiesContainer>
      </SectionContainer>
    </SectionsContainer>
  );
};

export default VetInsurance;

const EditBtn: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  const icons = useIcons();
  const moreIcon = icons?.moreIconSVG?.publicURL;
  return <MenuButton bgImage={moreIcon} onClick={onClick} width="28px" height="28px" mobileDisplayIcon />;
};

export const EditVetPetRecord = ({ petVetInsurer }: { petVetInsurer: Pet }) => {
  return (
    <EditBtn
      onClick={() =>
        ModalDialog.openModal({
          content: () => <VetPetRecordModal petVetInsurer={petVetInsurer} />,
          title: 'Pet Vet'
        })
      }
    />
  );
};

export const EditInsurerPetRecord = ({ petVetInsurer }: { petVetInsurer: Pet }) => {
  return (
    <EditBtn
      onClick={() =>
        ModalDialog.openModal({
          content: () => <InsurancePetRecordModal petVetInsurer={petVetInsurer} />,
          title: 'Pet Insurance'
        })
      }
    />
  );
};
