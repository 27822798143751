import { useQuery } from '@apollo/client';
import React from 'react';
import { sentenceCase } from 'sentence-case';
import { ExtensionDivider, ExtensionPetCaseLabel, FlexContainer } from '../../../../components/Pets/styled';
import useIcons from '../../../../hooks/useIcons';
import { GetBusUserProfile } from '../../../../queries';
import { toReadableDate } from '../../../../utils/dates';
import { AddedImage, AddedImageLink, FlexColumnCenterBetweenContainer, PhotosContainer } from '../../../Store/styled';
import { ReportNoteSubCategoryContainer, ReportNoteSubCategoryTitle } from '../Grooming/styled';
import TrainingReportNoteAction, { NOTE_ACTION_TYPES, TrainingNote } from './TrainingReportNoteAction';
import { ExtensionContainer, TrainingReportNoteContainer } from './styled';

const TrainingReportNote = ({ note, selectedReport }: { note: TrainingNote; selectedReport: Record<string, any> }) => {
  const icons = useIcons();
  const { data: { getBusUserProfile: profile = {} } = {} } = useQuery(GetBusUserProfile);
  const photos = note?.body?.photos?.primary || [];
  const attachments = note?.body?.attachments?.primary || [];
  return (
    <TrainingReportNoteContainer>
      {/* <ExtensionContainer>
        {toReadableDate(note?.timestamp, { isLocale: true })}
        {profile?.id === note?.BusUser?.id && <TrainingReportNoteAction type={NOTE_ACTION_TYPES.EDIT} note={note} selectedReport={selectedReport} />}
      </ExtensionContainer>
      <ExtensionPetCaseLabel fontSize={14} fontWeight={'normal'} margingB={12}>
        By {note?.BusUser?.name}
      </ExtensionPetCaseLabel>
      <ExtensionDivider /> */}
      <FlexContainer marginB={16}>
        <ExtensionPetCaseLabel fontSize={18} fontWeight={'800'}>
          {selectedReport?.name}
        </ExtensionPetCaseLabel>
        {profile?.id === note?.BusUser?.id && <TrainingReportNoteAction type={NOTE_ACTION_TYPES.EDIT} note={note} selectedReport={selectedReport} />}
      </FlexContainer>
      <FlexContainer gap={8} column>
        <FlexContainer>
          <ExtensionPetCaseLabel fontSize={14} fontWeight="700">
            Date:
          </ExtensionPetCaseLabel>
          <ExtensionPetCaseLabel fontSize={14} fontWeight={'600'} margingB={10}>
            {toReadableDate(note?.timestamp, { isLocale: true })}
          </ExtensionPetCaseLabel>
        </FlexContainer>
        <ExtensionDivider />
        <FlexContainer>
          <ExtensionPetCaseLabel fontSize={14} fontWeight="700">
            Created By:
          </ExtensionPetCaseLabel>
          <ExtensionPetCaseLabel fontSize={12} fontWeight={'normal'} margingB={10}>
            {note?.BusUser?.name}
          </ExtensionPetCaseLabel>
        </FlexContainer>
        <ExtensionDivider />
      </FlexContainer>
      {selectedReport?.Appointment?.id && (
        <FlexContainer marginT={8} marginB={8}>
          <ExtensionPetCaseLabel fontSize={14} width={100} fontWeight={700}>
            Appointment:
          </ExtensionPetCaseLabel>
          <ExtensionPetCaseLabel fontSize={14} fontWeight={'500'}>
            {selectedReport.Appointment?.OrderItem?.item?.name} - {toReadableDate(selectedReport.Appointment?.timestamp || new Date(), { isLocale: true })}
          </ExtensionPetCaseLabel>
        </FlexContainer>
      )}

      <ExtensionPetCaseLabel fontSize={14} fontWeight={'800'} margingB={12} margingT={12}>
        Training Type: {note?.body?.trainingType?.map(type => sentenceCase(type || ''))?.join(', ')}
      </ExtensionPetCaseLabel>
      <ExtensionDivider />

      {note?.body?.subCategories?.map((subCategory, index) => {
        const titles = Object.keys(subCategory?.values || {});

        return (
          <div key={index}>
            <ExtensionPetCaseLabel fontSize={14} fontWeight={'800'} margingB={12} margingT={12}>
              {sentenceCase(subCategory.label)}
            </ExtensionPetCaseLabel>
            {titles.map(title => {
              const item = subCategory?.htmlValues?.[`${title}_html`] || subCategory?.values?.[title];
              const isArray = Array.isArray(item);
              const isHtml = !!subCategory?.htmlValues?.[`${title}_html`];
              if (!item || (isArray && !item.length)) {
                return <></>;
              }
              return (
                <ReportNoteSubCategoryContainer key={title}>
                  <ReportNoteSubCategoryTitle>{sentenceCase(title)}: </ReportNoteSubCategoryTitle>
                  {!isHtml && (
                    <span>
                      {item && !isArray && sentenceCase(item || '')}

                      {isArray && !!item.length && item.map(recommendation => sentenceCase(recommendation)).join(' & ')}
                    </span>
                  )}
                  {isHtml && !isArray && <span dangerouslySetInnerHTML={{ __html: item }} />}
                </ReportNoteSubCategoryContainer>
              );
            })}
            <ExtensionDivider />
          </div>
        );
      })}

      {note?.body?.notes?.notes?.details_html && (
        <ReportNoteSubCategoryContainer>
          <span dangerouslySetInnerHTML={{ __html: note?.body?.notes?.notes?.details_html }} />
        </ReportNoteSubCategoryContainer>
      )}

      {!!photos?.length && (
        <>
          <ExtensionPetCaseLabel fontSize={14} fontWeight={'700'} margingB={12} margingT={12}>
            Photos
          </ExtensionPetCaseLabel>

          <PhotosContainer>
            {photos?.map((photo: string) => (
              <FlexColumnCenterBetweenContainer key={photo}>
                <AddedImageLink href={photo} target="_blank" rel="noopener noreferrer">
                  <AddedImage src={photo} alt="report image" />
                </AddedImageLink>
              </FlexColumnCenterBetweenContainer>
            ))}
          </PhotosContainer>
          <ExtensionDivider />
        </>
      )}
      {!!attachments?.length && (
        <>
          <ExtensionPetCaseLabel fontSize={14} fontWeight={'700'} margingB={12} margingT={12}>
            Attachments
          </ExtensionPetCaseLabel>
          <PhotosContainer>
            {attachments?.map((attachment: string) => (
              <FlexColumnCenterBetweenContainer key={attachment}>
                <AddedImageLink href={attachment} target="_blank" rel="noopener noreferrer">
                  <AddedImage src={icons.pdfPlaceholder.childImageSharp.gatsbyImageData.images.fallback.src} alt="report image" />
                </AddedImageLink>
              </FlexColumnCenterBetweenContainer>
            ))}
          </PhotosContainer>
          <ExtensionDivider />
        </>
      )}
    </TrainingReportNoteContainer>
  );
};

export default TrainingReportNote;
