import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import useChatRoomOptions from '../../hooks/useChatRoomOptions';
import useMediaQuery from '../../hooks/useMediaQuery';
import { RequireAtLeastOne } from '../../utils/helpers';
import { BranchAppUser } from '../../views/Store/BranchBilling/types';
import { BranchAppUserTag } from '../../views/Store/BranchTags/types';
import { HoverRecordOptionsContainer } from '../../views/styled';
import OptionDropdown, { OptionDropDownItem, OptionDropdownRef } from '../Shared/Menus/OptionDropdown/OptionDropdown';
import { OPTION_DROPDOWN_MENU_BUTTON_TYPES, OPTION_DROPDOWN_MENU_POSITIONS, OPTION_DROPDOWN_TYPES } from '../Shared/Menus/OptionDropdown/types';

type ChatRecordOptionsProps = {
  appUserQuickTags?: BranchAppUserTag[];
  appUsers: BranchAppUser[];
  showAlways?: boolean;
  loadTags?: boolean;
  afterTag?: (newAppUsers: BranchAppUser[]) => void;
  right?: boolean;
  profile: any;
  chatRoom: Record<string, any>;
  showOnMobileDrawer?: boolean;
  removeAppUserOptions?: boolean;
};

type Props = RequireAtLeastOne<ChatRecordOptionsProps, ('petQuickTags' & 'bookingQuickTags' & 'appUserQuickTags') | 'loadTags'>;

export type ChatRecordOptionsRef = {
  getContainerRefOptions?: () => HTMLDivElement | null;
  getOptionDropdownRefOptions: () => OptionDropdownRef | null;
};

const ChatRecordOptions = forwardRef<ChatRecordOptionsRef, Props>(
  ({ appUserQuickTags, showAlways = false, loadTags = false, right = false, appUsers, afterTag, chatRoom, profile, showOnMobileDrawer = false, removeAppUserOptions }, chatRecordOptionsRef) => {
    const { mobile } = useMediaQuery({ mobile: true });
    const { items: chatItems, loading: chatItemsLoading } = useChatRoomOptions({ appUsers, afterTag, loadTags, quickTags: appUserQuickTags, chatRoom, profile, removeAppUserOptions });

    const items = [...chatItems].filter(Boolean) as OptionDropDownItem[];
    const loading = chatItemsLoading;

    const containerRef = useRef<HTMLDivElement>(null);
    const optionDropdownRef = useRef<OptionDropdownRef>(null);
    useImperativeHandle(chatRecordOptionsRef, () => ({
      getContainerRefOptions: () => {
        return containerRef?.current;
      },
      getOptionDropdownRefOptions: () => {
        return optionDropdownRef?.current;
      }
    }));

    return (
      <HoverRecordOptionsContainer ref={containerRef} show={loading || showAlways}>
        {items.length ? (
          <OptionDropdown
            mobileDisplayIcon={!mobile || showOnMobileDrawer}
            menuButtonType={OPTION_DROPDOWN_MENU_BUTTON_TYPES.MORE}
            noApplyButton
            containerRelative
            buttonLoading={loading}
            options={[
              {
                optionType: OPTION_DROPDOWN_TYPES.BUTTONS,
                id: 'chatOptions',
                items
              }
            ]}
            menuPosition={right ? OPTION_DROPDOWN_MENU_POSITIONS.RIGHT : OPTION_DROPDOWN_MENU_POSITIONS.LEFT}
            ref={optionDropdownRef}
          />
        ) : (
          <div style={{ height: '25px', width: '25px' }} />
        )}
      </HoverRecordOptionsContainer>
    );
  }
);

export default ChatRecordOptions;
