import styled from 'styled-components';

export const AutomationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const AutomationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  margin-bottom: 20px;
`;

export const AutomationTypeContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 12px;
`;

export const AutomationRowWrapper = styled.div`
  display: flex;
  gap: 6px;
`;

export const AutomationRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const AutomationSeperator = styled.div`
  width: 12px;
  height: 48.5px;
  border-left: solid 1px #979797;
  border-bottom: solid 1px #979797;
  border-bottom-left-radius: 12px;
  margin-left: 12px;
`;

export const AutomationsFooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  align-items: center;
`;

export const BranchTagsHeaderContainer = styled.div``;

export const BranchTagsExtraOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;
