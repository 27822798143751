import { handleReportAdd, NOTE_TYPES } from '../../components/Profile/utils';
import { DynamicLink } from './types';

export const petsReportsNavigator = (dynamicLink: DynamicLink) => {
  const {
    medCondReport = false,
    groomingReport = false,
    trainingReport = false,
    walkingReport = false,
    sittingReport = false,
    daycareReport = false,
    petProfile = {},
    selectedReport = {}
  } = dynamicLink?.data || {};

  const getNoteType = () => {
    if (medCondReport) return NOTE_TYPES.MEDICAL;
    if (groomingReport) return NOTE_TYPES.GROOMING;
    if (trainingReport) return NOTE_TYPES.TRAINING;
    if (walkingReport) return NOTE_TYPES.WALKING;
    if (sittingReport) return NOTE_TYPES.SITTING;
    if (daycareReport) return NOTE_TYPES.DAYCARE;
    return NOTE_TYPES.MEDICAL;
  };

  handleReportAdd({
    noteType: getNoteType(),
    petProfile,
    autoOpenModal: false,
    selectedReportId: selectedReport?.id
  });
};
