import { useQuery } from '@apollo/client';
import useURLSearchParams from '../../hooks/useURLSearchParams';
import React, { FC, useEffect } from 'react';
import Colors from '../../Colors';
import useSelectedState from '../../hooks/useSelectedState';
import { GetBusUserProfile } from '../../queries';
import { vars } from '../../reactive';
import { BranchAppUser } from '../../views/Store/BranchBilling/types';
import { DRAWER_IDS } from '../DrawerBar/types';
import { DrawerHeaderContainer, ExtensionBody, ExtensionContainer, TabContainer } from '../Pets/styled';
import ToolBar from '../Shared/ToolBar/ToolBar';
import TabsHeader from '../Shared/TabsHeader';
import UserCredits from './UserCredits';
import UserInvoices from './UserInvoices';
import { UserBookingsList, UserOrdersList, UserSubscriptionsList } from './UserOrdersList';
import useMediaQuery from '../../hooks/useMediaQuery';
import useIcons from '../../hooks/useIcons';
import { CounterBtn } from '../Calendar/styled';
import { setDrawerBar, unsetDrawerBars } from '../../reactive/actions';

type UserExtensionProps = {
  userProfile: BranchAppUser;
};

export enum UserExtensionTabs {
  BOOKINGS = 'Bookings',
  MEMBERSHIPS = 'Memberships',
  ORDERS = 'Orders',
  INVOICES = 'Invoices',
  CREDIT = 'Credit'
}

export enum UserExtensionTabsIds {
  BOOKINGS = 'bookings',
  MEMBERSHIPS = 'memberships',
  ORDERS = 'orders',
  INVOICES = 'invoices',
  CREDIT = 'credit'
}

const UserExtension: FC<UserExtensionProps> = ({ userProfile }) => {
  const { mobile } = useMediaQuery({ mobile: true });
  const icons = useIcons();
  const lessIcon = icons.less.childImageSharp.gatsbyImageData.images.fallback.src;
  const drawerBar = vars.drawerBars().find(drawer => drawer.drawerId === DRAWER_IDS.PETS_DRAWER);
  const userDrawerBar = vars.drawerBars().find(drawer => drawer.drawerId === DRAWER_IDS.USER_DRAWER);
  const handleBack = () => {
    setDrawerBar({
      drawerId: DRAWER_IDS.USER_DRAWER,
      isExtended: false,
      recordData: userDrawerBar?.recordData
    });
  };

  const { data: { getBusUserProfile: { Branch: { branchBillingCycle: { billing_date_from = '', billing_date_to = '' } = {} } = {} } = {} } = {} } = useQuery(GetBusUserProfile, {
    fetchPolicy: 'cache-and-network'
  });
  const currentPeriod = { start: billing_date_from, end: billing_date_to };

  const [tab] = useURLSearchParams('userSelectedTab');

  const currentTab = tab[0] || UserExtensionTabsIds.BOOKINGS;
  const toolBarButtons = [
    { name: UserExtensionTabs.BOOKINGS, id: UserExtensionTabsIds.BOOKINGS },
    { name: UserExtensionTabs.ORDERS, id: UserExtensionTabsIds.ORDERS },
    { name: UserExtensionTabs.MEMBERSHIPS, id: UserExtensionTabsIds.MEMBERSHIPS },
    { name: UserExtensionTabs.INVOICES, id: UserExtensionTabsIds.INVOICES },
    { name: UserExtensionTabs.CREDIT, id: UserExtensionTabsIds.CREDIT }
  ];

  return (
    <ExtensionContainer>
      <ToolBar
        toolBarButtons={toolBarButtons}
        urlSearchParam="userSelectedTab"
        displayAll={false}
        selectOnlyOne={true}
        displayDivider={false}
        defaultValue={UserExtensionTabsIds.BOOKINGS}
        displayTools={false}
        mobilePadding="0 0 0 16px"
        mobileMiddleAction={<CounterBtn bgImg={lessIcon} onClick={handleBack} />}
      />

      <ExtensionBody>
        <TabContainer>
          {currentTab === UserExtensionTabsIds.BOOKINGS && <UserBookingsList userProfile={userProfile} currentPeriod={currentPeriod} />}
          {currentTab === UserExtensionTabsIds.ORDERS && <UserOrdersList userProfile={userProfile} currentPeriod={currentPeriod} />}
          {currentTab === UserExtensionTabsIds.MEMBERSHIPS && <UserSubscriptionsList userProfile={userProfile} currentPeriod={currentPeriod} />}
          {currentTab === UserExtensionTabsIds.INVOICES && <UserInvoices userProfile={userProfile} />}
          {currentTab === UserExtensionTabsIds.CREDIT && <UserCredits userProfile={userProfile} />}
        </TabContainer>
      </ExtensionBody>
    </ExtensionContainer>
  );
};

export default UserExtension;
