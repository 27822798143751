import { useMutation, useQuery } from '@apollo/client';
import React, { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactSelect from '../../../components/Shared/Forms/Select';
import Alert from '../../../components/Alert/Alert';
import ModalDialog, { ModalLayout } from '../../../components/Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../../../components/Modal/styled';
import { ORDER_INVOICE_STATUS } from '../../../components/OrderInvoices/types';
import { FormButtonsContainer, FormInput, FormLabel, FormSubmitButton, InputGroup, InputsWrapper, WideInputGroup, selectTheme } from '../../../components/Shared/Forms/Forms';
import AppUsersList from '../../../components/Shared/Lists/AppUsersList';
import { Container } from '../../../components/Shared/Shared';
import { BatchSendOrderInvoiceEmail, GetBranchAppUserTags } from '../../../queries';
import { BranchAppUserTag } from '../BranchTags/types';
import Select from '../../../components/Shared/Forms/Select';

type OrderInvoiceSendEmailFormValues = {
  AppUserId: string[];
  invoice_date_from: string;
  invoice_date_to: string;
  status: 'ALL' | 'BILLED' | 'UNBILLED' | 'PENDING_REJECTED';
  BranchAppUserTagId: string[];
};

type OrderInvoiceSendEmailModalProps = {
  initialValues?: Partial<{ AppUserId: string[]; unbilled: boolean; status?: ORDER_INVOICE_STATUS[] | null; invoice_date_from: string; invoice_date_to: string; BranchAppUserTagId: string[] }>;
};

const OrderInvoicesSendEmailModal: FC<OrderInvoiceSendEmailModalProps> = ({ initialValues }) => {
  const formOptions = useForm<OrderInvoiceSendEmailFormValues>({
    defaultValues: {
      AppUserId: initialValues?.AppUserId || [],
      invoice_date_from: initialValues?.invoice_date_from ? new Date(initialValues?.invoice_date_from).toISOString().split('T')[0] : '',
      invoice_date_to: initialValues?.invoice_date_to ? new Date(initialValues?.invoice_date_to).toISOString().split('T')[0] : '',
      status: initialValues?.unbilled ? 'UNBILLED' : 'ALL',
      BranchAppUserTagId: initialValues?.BranchAppUserTagId || []
    }
  });

  const { control, handleSubmit, watch } = formOptions;

  const watchedValues = watch(['invoice_date_from']);

  const [handleBulkSendEmail, { data, loading, error }] = useMutation(BatchSendOrderInvoiceEmail);

  const onSubmit = handleSubmit(values => {
    Alert.alert({
      title: 'Are you sure you want to send invoices?',
      description: 'This action will send all invoices to users',
      onAccept: async () => {
        await handleBulkSendEmail({
          variables: {
            invoice_date_from: new Date(new Date(values.invoice_date_from).setUTCHours(0, 0, 0, 0)).toISOString(),
            invoice_date_to: new Date(new Date(values.invoice_date_to).setUTCHours(23, 59, 59, 999)).toISOString(),
            status: ['UNBILLED', 'ALL'].includes(values.status) ? null : values.status === 'PENDING_REJECTED' ? ['PAYMENT_PENDING', 'PAYMENT_REJECTED'] : ['PAYMENT_SUCCEEDED'],
            ...(values.AppUserId?.length && { AppUserId: values.AppUserId }),
            ...(values.status === 'UNBILLED' && { unbilled: true }),
            ...(values.BranchAppUserTagId?.length && { BranchAppUserTagId: values.BranchAppUserTagId })
          }
        });
      }
    });
  });

  const { data: { getBranchAppUserTags: allTags = [] } = {} } = useQuery<{
    getBranchAppUserTags: BranchAppUserTag[];
  }>(GetBranchAppUserTags, {
    fetchPolicy: 'cache-and-network',
    variables: {
      offset: 0,
      limit: 1000
    }
  });

  const billedOnToMin = watchedValues?.invoice_date_from ? new Date(watchedValues?.invoice_date_from)?.toISOString().split('T')[0] : '';

  useEffect(() => {
    if (data?.batchSendOrderInvoiceEmail) {
      ModalDialog.closeModal();
    }
  }, [data?.batchSendOrderInvoiceEmail]);

  return (
    <ModalLayout
      compact
      buttons={[
        <FormSubmitButton error={!!error} loading={loading} onClick={() => onSubmit()}>
          Send Invoices
        </FormSubmitButton>
      ]}
    >
      <WideInputGroup>
        <FormLabel>Clients</FormLabel>
        <AppUsersList isMulti formOptions={formOptions} name="AppUserId" defaultValues={initialValues?.AppUserId} />
      </WideInputGroup>
      <WideInputGroup>
        <FormLabel>Status</FormLabel>
        <Controller
          render={({ onChange, value }) => (
            <Select
              options={[
                { label: 'All', value: 'ALL' },
                { label: 'Paid', value: 'BILLED' },
                { label: 'Unpaid', value: 'UNBILLED' },
                { label: 'Pending/Rejected', value: 'PENDING_REJECTED' }
              ]}
              value={{ label: value === 'ALL' ? 'All' : value === 'BILLED' ? 'Paid' : value === 'UNBILLED' ? 'Unpaid' : 'Pending/Rejected', value }}
              onChange={option => onChange(option?.value)}
              theme={selectTheme}
            />
          )}
          control={control}
          name={'status'}
          defaultValue={initialValues?.unbilled ? 'UNBILLED' : 'ALL'}
        />
      </WideInputGroup>
      <WideInputGroup>
        <FormLabel>Client Tags</FormLabel>
        <Controller
          render={({ onChange, value }) => (
            <Select
              options={allTags.map(tag => ({ label: tag.name, value: tag.id }))}
              onChange={tags => onChange(tags?.map(tag => tag.value))}
              isMulti
              theme={selectTheme}
              value={[...(value || [])]?.map(tagId => allTags.find(tag => tag.id === tagId)).map(tag => ({ label: tag?.name, value: tag?.id })) || []}
            />
          )}
          control={control}
          name={'BranchAppUserTagId'}
          defaultValue={initialValues?.BranchAppUserTagId || []}
        />
      </WideInputGroup>
      <WideInputGroup marginTop={20} flexDirection="row" gap={10}>
        <InputGroup>
          <FormLabel>From</FormLabel>
          <Controller
            as={<FormInput type={'date'} />}
            control={control}
            name={'invoice_date_from'}
            defaultValue={initialValues?.invoice_date_from ? new Date(initialValues?.invoice_date_from).toISOString().split('T')[0] : ''}
          />
        </InputGroup>
        <InputGroup>
          <FormLabel>To</FormLabel>
          <Controller
            as={<FormInput type={'date'} min={billedOnToMin} disabled={!watchedValues?.invoice_date_from} />}
            control={control}
            name={'invoice_date_to'}
            defaultValue={initialValues?.invoice_date_to ? new Date(initialValues?.invoice_date_to).toISOString().split('T')[0] : ''}
          />
        </InputGroup>
      </WideInputGroup>
    </ModalLayout>
  );
};

export default OrderInvoicesSendEmailModal;
