import { useLazyQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import AsyncSelect from 'react-select/async';
import { GetPetColors } from '../../../queries';
import debounce from '../../../utils/debounce';
import { Pet } from '../../Pets/types';
import { Branch } from '../../Profile/types';
import { selectTheme } from '../Forms/Forms';

const getOptions = (data: Branch[]) => {
  return data?.map((user: any) => ({ value: user.id, label: user.name })) || [];
};

const PetColorsList = ({ formOptions, defaultColorId, petType, name }: { formOptions: UseFormMethods<any>; petType?: Pet['type']; defaultColorId?: string; name?: string }) => {
  const { control } = formOptions;

  const [getSearchPetColors, { data: { getPetColors: colors = [] } = {} }] = useLazyQuery(GetPetColors, {
    fetchPolicy: 'cache-and-network'
  });

  const [getColorById, { data: { getPetColors: [defaultColor = {}] = [] } = {} }] = useLazyQuery(GetPetColors, {
    fetchPolicy: 'cache-and-network'
  });

  useEffect(() => {
    if (defaultColorId) {
      getColorById({ variables: { id: [defaultColorId] } });
    }
  }, [defaultColorId]);

  const options = getOptions(colors);

  const loadOptions = (inputValue: string, callback: (options: { value: string; label: string }[]) => void) => {
    debounce(async () => {
      const { data: { getPetColors: colors = [] } = {} } = await getSearchPetColors({ variables: { name: [inputValue], type: petType ? [petType] : null } });
      const options = getOptions(colors);
      callback(options);
    }, 500);
  };

  return (
    <Controller
      name={name || 'petColorId'}
      control={control}
      defaultValue={defaultColorId || ''}
      render={({ onChange, value }) => {
        const val = value ? options.find(option => option.value === value) : '';
        const defaultVal = defaultColorId === value && defaultColorId && defaultColor ? { value: defaultColorId, label: defaultColor?.name } : '';
        return (
          <>
            <AsyncSelect
              styles={{ container: provided => ({ ...provided }), valueContainer: provided => ({ ...provided, height: 48, overflowY: 'scroll' }) }}
              loadOptions={loadOptions}
              theme={selectTheme}
              name={'selectedVetId'}
              onChange={(option: any) => {
                onChange(option?.value);
              }}
              value={defaultVal || val || ''}
              options={options}
              cacheOptions
              noOptionsMessage={({ inputValue }) => (inputValue === '' ? 'Start typing to search' : 'No results found')}
            />
          </>
        );
      }}
    />
  );
};

export default PetColorsList;
