import { Branch } from '../../components/Profile/types';
import { Slots } from '../../hooks/useCheckBranchAvailability';
import { BOOKING_TYPE } from '../Bookings/types';
import { BranchSchedule } from './BranchSchedules/types';

export enum CHARGE_TYPE {
  PREPAID = 'PREPAID',
  POSTPAID = 'POSTPAID'
}

export enum CHARGE_STATUS {
  CHARGE_NOW = 'CHARGE_NOW',
  CHARGE_LATER = 'CHARGE_LATER',
  NO_CHARGE = 'NO_CHARGE'
}

export const chargeStatuses = {
  [CHARGE_STATUS.CHARGE_NOW]: { value: CHARGE_STATUS.CHARGE_NOW, label: 'Pay per Booking', charge_type: CHARGE_TYPE.PREPAID },
  [CHARGE_STATUS.CHARGE_LATER]: { value: CHARGE_STATUS.CHARGE_LATER, label: 'Invoiced', charge_type: CHARGE_TYPE.POSTPAID },
  [CHARGE_STATUS.NO_CHARGE]: { value: CHARGE_STATUS.NO_CHARGE, label: 'No Charge', charge_type: CHARGE_TYPE.PREPAID }
};

export type Customization = {
  id: string;
  name: string;
  price: number;
  Indicator: false | '' | JSX.Element;
  title: string;
  meta: Record<string, string>;
}[];

export type Product = {
  id: string;
  name: string;
  photos?: string[] | null;
  status: string;
  ProductCategories: { id: string; name: string }[];
  cta_text: string;
  price: number;
  unit: string;
  weight: number;
  sku: string;
  charge_type: CHARGE_TYPE;
  sessions: number;
  quantity: number;
  total: number;
  stock: number | null;
  original_price: number;
  automatically_pick_best_discount: boolean;
  show_customizations_price: boolean;
  show_on_daily_appointments: boolean;
  use_pets_count: boolean;
  future_delivery: boolean;
  auto_confirm_order: boolean;
  auto_confirm_appointments: boolean;
  allows_repeated_orders: boolean;
  allows_bulk_orders: boolean;
  max_bulk_orders: number;
  type: 'product' | 'service' | 'subscription';
  booking_type: BOOKING_TYPE;
  short_description: string | null;
  Branch: Branch;
  customizations: Record<string, Customization>;
  slots: { time: string; isAvailable: boolean }[];
  BranchSchedules: BranchSchedule[];
  slots_start_date: string;
  max_slot_offset: number;
  duration: number;
  user_can_cancel: boolean;
  allows_past_slots: boolean;
  user_can_reschedule: boolean;
  enable_customizations_quantity_factor: boolean;
  ProductKinds: { id: string; name: string }[];
  payment: {
    method_optional?: boolean;
    interval: 'month' | 'year';
  };
  PharmaItemProducts: {
    id: string;
    price_margin: number;
    PharmaItem: {
      id: string;
      pack_size: string;
      description: string;
      cost: number;
      supplier: string;
      controlled_drug: boolean;
    };
  }[];
  Flags: {
    id: string;
  }[];
  SubscriptionProducts: Product[];
  additional_info: {
    payment_description: string;
    booking_order_close_message: string;
  };
  sort_index: number;
  description: string;
  colors: {
    calendar: string;
  };
  slugs: {
    calendar: string;
    path: string;
  };
  pet_rules: {
    max_pet_count: number;
    allowed_pet_types: string[];
    excluded_pet_breeds: string[];
    pet_min_age: number;
    pet_max_age: number;
  };
};

export type BranchAvailability = {
  slots: Slots;
};

export enum TABS_TYPES {
  SERVICES = 'services',
  SUBSCRIPTIONS = 'memberships',
  PRODUCTS = 'products',
  SCHEDULES = 'schedules',
  AUTO_MESSAGES = 'messages',
  BRANCH_TAGS = 'tags',
  REPORTS = 'reports',
  BILLING = 'billing',
  FORMS = 'forms',
  DISCOUNTS = 'discounts',
  BUS_USERS = 'staff',
  PAYOUTS = 'payouts',
  BRANCH_ROOMS = 'rooms',
  PROVIDER_BRANCHES = 'providerBranches'
}

export const TABS_NAMES = {
  [TABS_TYPES.SERVICES]: 'Services',
  [TABS_TYPES.SUBSCRIPTIONS]: 'Memberships',
  [TABS_TYPES.PRODUCTS]: 'Products',
  [TABS_TYPES.SCHEDULES]: 'Schedules',
  [TABS_TYPES.AUTO_MESSAGES]: 'Messages',
  [TABS_TYPES.BRANCH_TAGS]: 'Tags',
  [TABS_TYPES.REPORTS]: 'Reports',
  [TABS_TYPES.BILLING]: 'Billing',
  [TABS_TYPES.FORMS]: 'Forms',
  [TABS_TYPES.DISCOUNTS]: 'Discounts',
  [TABS_TYPES.BUS_USERS]: 'Staff',
  [TABS_TYPES.PAYOUTS]: 'Payouts',
  [TABS_TYPES.BRANCH_ROOMS]: 'Pens',
  [TABS_TYPES.PROVIDER_BRANCHES]: 'Branches'
};

export const productStatuses = [
  { value: 'IN_STOCK', label: 'In stock', availablality: 'Available' },
  { value: 'OUT_OF_STOCK', label: 'Out of stock', availablality: 'Unavailable' },
  { value: 'HIDDEN', label: 'Hidden', availablality: 'Hidden' },
  { value: 'ARCHIVED', label: 'Archived', availablality: 'Archived' }
];

export type Country = {
  id: number;
  name: string;
};

export const subscriptionPeriods = [
  {
    label: 'Monthly',
    value: 'month'
  },
  {
    label: 'Yearly',
    value: 'year'
  }
];
