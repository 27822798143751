import React from 'react';
import { Controller } from 'react-hook-form';
import { ColorPicker, FormError, FormInput, FormLabel, FormTextArea, InputGroup, selectTheme } from '../../../../components/Shared/Forms/Forms';
import { CategorySelect } from '../../styled';
import { productStatuses } from '../../types';
import { StoreProductModalSectionsCommonProps, unitOptions } from './types';
import BinaryUpload, { BinaryUploadProps } from '../../../Pets/Reports/BinaryUpload';
import MarkupEditor, { MarkupEditorRef } from '../../../../components/Shared/MarkupEditor';
import Colors from '../../../../Colors';
import { DrawerSubLabel } from '../../../../components/DrawerBar/styled';
import { getBranchCurrencySymbol } from '../../../../utils/getBranchCurrencySymbol';
import { LinkedProductContainer } from '../../Products/styled';
import Select from '../../../../components/Shared/Forms/Select';
import { getTypeName } from './utils';
import { Divider } from '../../../../components/Shared/SideTabs/styles';

const StoreProductModalDetails = ({
  formOptions,
  defaultValues,
  binaryUploadProps,
  type,
  product,
  markupEditorProps
}: StoreProductModalSectionsCommonProps & { binaryUploadProps: BinaryUploadProps } & { markupEditorProps: { ref: React.RefObject<MarkupEditorRef> } }) => {
  const { control, errors } = formOptions;
  const isProduct = type === 'product';
  const isService = type === 'service';
  const typeName = getTypeName(type);

  return (
    <>
      <InputGroup maxWidth="450px">
        <FormLabel>{typeName} Name</FormLabel>
        <Controller as={<FormInput error={errors.name?.message} />} control={control} name={'name'} defaultValue={defaultValues?.name} rules={{ required: true }} />
        {errors.name && <FormError>{errors.name.message || 'name is required'}</FormError>}
      </InputGroup>

      {isProduct && !!product?.PharmaItemProducts?.length && (
        <InputGroup maxWidth="450px">
          <LinkedProductContainer>
            <DrawerSubLabel size={14} weight={700} color={Colors.black}>
              Linked to: {product?.PharmaItemProducts?.[0]?.PharmaItem?.description}
            </DrawerSubLabel>
            <DrawerSubLabel size={14} weight={600} color={Colors.black}>
              Net Cost: {getBranchCurrencySymbol()}
              {product?.PharmaItemProducts?.[0]?.PharmaItem?.cost}
            </DrawerSubLabel>
            <DrawerSubLabel size={14} weight={600} color={Colors.black}>
              Supplier: {product?.PharmaItemProducts?.[0]?.PharmaItem?.supplier}
            </DrawerSubLabel>
            <DrawerSubLabel size={14} weight={600} color={Colors.black}>
              Controlled Drug: {product?.PharmaItemProducts?.[0]?.PharmaItem?.controlled_drug ? 'Yes' : 'No'}
            </DrawerSubLabel>
            <DrawerSubLabel size={14} weight={600} color={Colors.black}>
              Margin: {product?.PharmaItemProducts?.[0]?.price_margin}%
            </DrawerSubLabel>
            <DrawerSubLabel size={14} weight={600} color={Colors.black}>
              Pack Size: {product?.PharmaItemProducts?.[0]?.PharmaItem?.pack_size}
            </DrawerSubLabel>
          </LinkedProductContainer>
        </InputGroup>
      )}

      <InputGroup maxWidth="450px">
        <FormLabel>Category</FormLabel>
        <Controller
          render={({ onChange, value }) => {
            return <CategorySelect isMulti={false} createable={false} onChange={onChange} value={value} />;
          }}
          control={control}
          name={'category'}
          defaultValue={defaultValues?.category}
          rules={{ required: true }}
        />
        {errors.category && <FormError>{errors.category.message || 'category is required'}</FormError>}
      </InputGroup>
      <InputGroup maxWidth="450px">
        <FormLabel>Status</FormLabel>
        <Controller
          render={({ onChange, value }) => {
            return <Select options={productStatuses} getOptionLabel={option => option.availablality || option.label} isClearable onChange={onChange} value={value} />;
          }}
          control={control}
          name={'status'}
          defaultValue={defaultValues?.status}
          rules={{ required: true }}
        />
        {errors.status && <FormError>{errors.status.message || 'status is required'}</FormError>}
      </InputGroup>

      <Divider marginBottom="20" marginTop="20px" />

      {!isProduct && (
        <>
          <InputGroup maxWidth="450px">
            <FormLabel>{typeName} Slug</FormLabel>
            <Controller as={<FormInput error={errors.slugs?.path?.message} />} control={control} name={'slugs.path'} defaultValue={defaultValues?.slugs?.path || ''} />
          </InputGroup>

          <InputGroup maxWidth="450px">
            <FormLabel>Combination Slug</FormLabel>
            <Controller as={<FormInput error={errors.slugs?.calendar?.message} />} control={control} name={'slugs.calendar'} defaultValue={defaultValues?.slugs?.calendar || ''} />
          </InputGroup>

          <InputGroup maxWidth="450px">
            <FormLabel>Label Color</FormLabel>
            <Controller as={<ColorPicker error={errors.colors?.calendar?.message} />} control={control} name={'colors.calendar'} defaultValue={defaultValues?.colors?.calendar || ''} />
          </InputGroup>

          <Divider marginBottom="20" marginTop="20px" />
        </>
      )}

      {isProduct && (
        <InputGroup maxWidth="450px">
          <FormLabel>Weight</FormLabel>
          <Controller
            as={<FormInput error={!!errors.weight?.message} type={'number'} />}
            control={control}
            name={'weight'}
            defaultValue={defaultValues?.weight || ''}
            rules={{
              min: 0
            }}
          />
          {errors.weight && <FormError>{errors.weight.message || 'weight is required'}</FormError>}
        </InputGroup>
      )}
      {isProduct && (
        <InputGroup maxWidth="450px">
          <FormLabel>SKU Number</FormLabel>
          <Controller as={<FormInput error={!!errors.sku?.message} />} control={control} name={'sku'} defaultValue={defaultValues?.sku || ''} rules={{ required: false }} />
          {errors.sku && <FormError>{errors.sku.message || 'sku is required'}</FormError>}
        </InputGroup>
      )}

      {isProduct && (
        <InputGroup maxWidth="450px">
          <FormLabel>Application Unit</FormLabel>
          <Controller
            render={({ onChange, value }) => (
              <Select
                isClearable
                options={unitOptions}
                onChange={option => onChange(option?.value)}
                value={value ? { value, label: unitOptions.filter(option => option.value === value)?.[0]?.label } : null}
              />
            )}
            control={control}
            name={'unit'}
            defaultValue={defaultValues?.unit}
          />
          {errors.unit && <FormError>{errors.unit.message || 'unit is required'}</FormError>}
        </InputGroup>
      )}
      {isProduct && <Divider marginBottom="20" marginTop="20px" />}

      <InputGroup maxWidth="450px">
        <FormLabel>Short Description</FormLabel>
        <Controller as={<FormTextArea error={!!errors?.short_description?.message} isShort />} control={control} name={'short_description'} defaultValue={defaultValues?.short_description || ''} />
      </InputGroup>

      <InputGroup maxWidth="450px">
        <FormLabel>Description</FormLabel>
        <Controller
          render={({ onChange, value }) => (
            <MarkupEditor
              ref={markupEditorProps?.ref}
              defaultValue={value}
              onChange={({ html }) => {
                onChange(html);
              }}
            />
          )}
          control={control}
          name={'description'}
          defaultValue={defaultValues?.description || ''}
        />
      </InputGroup>

      <InputGroup maxWidth="450px">
        <BinaryUpload {...binaryUploadProps} ref={binaryUploadProps.ref} />
      </InputGroup>

      <Divider marginBottom="20" marginTop="20px" />

      <InputGroup maxWidth="450px">
        <FormLabel>Action Button Text</FormLabel>
        <Controller as={<FormInput error={errors.cta_text?.message} />} control={control} name={'cta_text'} defaultValue={defaultValues?.cta_text} rules={{}} />
        {errors.cta_text && <FormError>{errors.cta_text.message || 'Action Button Text is required'}</FormError>}
      </InputGroup>

      <InputGroup maxWidth="450px">
        <FormLabel>Order</FormLabel>
        <Controller as={<FormInput error={!!errors.sort_index?.message} type={'number'} />} control={control} name={'sort_index'} defaultValue={defaultValues?.sort_index || 1} />
        <FormError>{errors.sort_index?.message || ''}</FormError>
      </InputGroup>

      <Divider marginBottom="20" marginTop="20px" />

      <InputGroup maxWidth="450px">
        <FormLabel>{typeName} disclaimer</FormLabel>
        <Controller
          as={<FormInput error={!!errors?.additional_info?.payment_description?.message} />}
          control={control}
          name={'additional_info.payment_description'}
          defaultValue={defaultValues?.additional_info?.payment_description || ''}
        />
      </InputGroup>

      {isService && (
        <InputGroup maxWidth="450px">
          <FormLabel>Booking closing message</FormLabel>
          <Controller
            as={<FormTextArea error={!!errors?.additional_info?.booking_order_close_message?.message} />}
            control={control}
            name={'additional_info.booking_order_close_message'}
            defaultValue={defaultValues?.additional_info?.booking_order_close_message || ''}
          />
        </InputGroup>
      )}
    </>
  );
};

export default StoreProductModalDetails;
