import styled, { css } from 'styled-components';
import Colors from '../../Colors';
import { HideElement } from '../Shared/Animations';

export const BackgroundOpacity = styled.div<{ showModal: boolean }>`
  position: fixed;
  z-index: 500;
  left: 0;
  top: 0;
  opacity: ${props => (props.showModal ? 1 : 0)};
  width: 100%;
  height: 100%;
  overflow: auto;
  color: ${Colors.secondaryText};
  background-color: rgba(216, 216, 216, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.15s ease-in;
  ${props =>
    !props.showModal &&
    css`
      animation: ${HideElement} 0.5s ease-in forwards;
    `}
`;
export const ModalContainer = styled.div<{ mobileBorderRadius?: string; autoHeight?: boolean; isMini?: boolean }>`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: ${Colors.white};
  border-radius: 18px;
  min-width: 500px;
  max-height: ${props => (props.isMini ? 'unset' : '80vh')};
  min-height: ${props => (props.isMini ? 'unset' : '80vh')};
  height: ${props => (props.isMini ? 'unset' : '80vh')};
  box-shadow: 0 3px 25px 0 rgba(0, 0, 0, 0.1);
  ${props =>
    props.autoHeight &&
    css`
      height: auto;
      min-height: auto;
      max-height: auto;
    `}
  @media (max-width: 768px) {
    width: ${props => (props.isMini ? '90%' : '100%')};
    min-width: ${props => (props.isMini ? '90%' : '100%')};
    max-height: 100%;
    border-radius: ${props => (props.mobileBorderRadius ? props.mobileBorderRadius : props.isMini ? '20px' : '0')};
    height: ${props => (props.isMini ? 'unset' : '100%')};
  }
`;
export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px 12px 20px;
  width: 100%;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  font-size: 16px;
  font-weight: 800;
  background-color: ${Colors.backgroundGrey};
  border-bottom: 1px solid ${Colors.toolsBarBackgroundColor};
`;
export const CloseModal = styled.div`
  width: 25px;
  height: 25px;
  background-color: ${Colors.black};
  border-radius: 50%;
  padding: 5px;
  border: none;
  &:hover {
    cursor: pointer;
  }
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ModalDivider = styled.div<{ show?: boolean }>`
  height: 1px;
  background-color: rgb(231, 231, 231);
  width: 100%;
  opacity: ${props => (props.show ? 1 : 0)};
`;
export const ModalBody = styled.div<{ minHeight?: number; maxWidth?: number; padding?: string; maxHeight?: number; minWidth?: number; compact?: boolean; isMini?: boolean }>`
  display: flex;
  flex: ${props => (props.isMini ? 1 : 'unset')};
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
  /* padding: ${props => (props.padding ? props.padding : props?.compact ? '0 40px' : '0')}; */
  width: 100%;
  max-height: ${maxHeight => (maxHeight ? `${maxHeight}vh` : '75vh')};
  /* overflow-y: scroll; */
  /* min-height: ${props => (props.minHeight ? `${props.minHeight}px` : 'auto')}; */
  transition: all 0.3s ease-in-out;
  height: ${props => (props.isMini ? 'unset' : '100%')};
  min-width: ${props => (props.minWidth || props?.compact ? 650 : 900)}px;
  max-width: ${props => (props.maxWidth || props?.compact ? 650 : 900)}px;
  @media (max-width: 768px) {
    min-width: 100%;
    max-width: 100%;
    min-height: unset;
    height: 100%;
  }
`;
export const ModalFooter = styled.div<{ bgColor?: string }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 12px 20px;
  width: 100%;
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
  background-color: ${props => (props.bgColor ? props.bgColor : '#f1f1f1')};
  @media (max-width: 768px) {
    justify-self: flex-end;
  }
`;
