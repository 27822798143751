import React, { useRef } from 'react';
import { Booking, PetRecordTag } from '../Bookings/components/BookingsBody/styled';
import { PetAndInfo, PetContainer, PetImg } from '../Bookings/components/BookingRecord/styled';
import { RecordBody } from '../styled';
import useIcons from '../../hooks/useIcons';
import { setDrawerBar } from '../../reactive/actions';
import { DRAWER_IDS } from '../../components/DrawerBar/types';
import { PetRecord } from '../../components/Pets/types';
import { BranchVehicleRoutesView } from './types';

const FleetSchedulePetRecord = ({ petRecord, index }: { petRecord: BranchVehicleRoutesView['branchVehicleRoutesViews'][0]['petsViews'][0]; index: number }) => {
  const icons = useIcons();

  const optionsRef = useRef<HTMLDivElement>(null);
  const handleClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    if (optionsRef?.current?.contains(e.target as Node)) {
      return;
    }

    // if (showBulkSelect) {
    //   toggleSelectMultiple([pet?.id]);
    //   return;
    // }

    setDrawerBar({ drawerId: DRAWER_IDS.PETS_DRAWER, recordData: petRecord?.id });
  };

  return (
    <Booking onClick={handleClick} flexDirection="column" flexStart backgroundColor="#f7f7f7" borderRadius="15px" key={petRecord?.id}>
      <PetContainer>
        <RecordBody width="40px" fontWeight="800">
          {index}
        </RecordBody>
        <PetImg src={petRecord?.profile_pic || icons.user.childImageSharp.gatsbyImageData.images.fallback.src} alt={petRecord?.name} />
        <PetAndInfo mobileFlexDirection="column">
          <RecordBody display="flex" flexDirection="column">
            <RecordBody fontWeight="800" color="#212121" mobileWidth="100%">
              {petRecord?.name},
            </RecordBody>
            <RecordBody width="200" noMargin>
              {petRecord?.address}
            </RecordBody>
          </RecordBody>
        </PetAndInfo>
      </PetContainer>
      {petRecord?.routeTags.map(tag => (
        <PetRecordTag color={'black'} style={{ marginLeft: '24px' }}>
          {tag}
        </PetRecordTag>
      ))}
    </Booking>
  );
};

export default FleetSchedulePetRecord;
