import React, { useRef } from 'react';
import { StoreEditButton } from '../../../components/DrawerBar/DrawerButtons';
import { DRAWER_IDS } from '../../../components/DrawerBar/types';
import { Divider } from '../../../components/Shared/SideTabs/styles';
import { HoverRecordOptionsContainer, RecordBody, RecordDataContainer } from '../../styled';
import { BranchTag, TAGS_AUTOMATIONS_TYPES_LABELS } from './types';
import { Booking } from '../../Bookings/components/BookingsBody/styled';

const BranchTagsRecord = ({ branchTag, navigateDrawer }: { branchTag: BranchTag; navigateDrawer: ({ drawerLinkId }: { drawerLinkId: string }) => void }) => {
  const storeEditButtonRef = useRef<{ handleClick: () => void }>(null);

  const handleClick = () => {
    // storeEditButtonRef.current?.handleClick();
    // navigateDrawer({ drawerLinkId: branchTag?.id });
  };

  const tagType = branchTag.__typename === 'BranchPetRecordTag' ? 'Pet' : branchTag.__typename === 'BranchAppointmentTag' ? 'Booking' : 'App User';

  return (
    <>
      <Booking onClick={handleClick} padding="6px 10px">
        <RecordBody fontWeight={'800'} fontSize={13} width="200" flex="none">
          {branchTag?.name}
        </RecordBody>
        <RecordDataContainer noHover marginLeft="auto" padding="0">
          <RecordBody width="100" fontSize={13}>
            {tagType}
          </RecordBody>
          {/* <RecordBody fontSize={13} width="200">
            {branchTag?.automations?.map(automation => TAGS_AUTOMATIONS_TYPES_LABELS[automation?.type]).join(', ')}
          </RecordBody>
           */}
          <RecordBody fontSize={13} width="100">
            {branchTag?.BranchTagCategories?.map(category => category?.name).join(', ')}
          </RecordBody>

          <RecordBody fontSize={13} width="100">
            {!!branchTag?.quick_action ? 'Quick Action' : ''}
          </RecordBody>

          <HoverRecordOptionsContainer>
            <StoreEditButton drawer={{ drawerId: DRAWER_IDS.BRANCH_TAG_DRAWER, recordData: branchTag }} ref={storeEditButtonRef} />
          </HoverRecordOptionsContainer>
        </RecordDataContainer>
      </Booking>
      <Divider />
    </>
  );
};

export default BranchTagsRecord;
