import { DRAWER_IDS } from '../components/DrawerBar/types';
import { vars } from '../reactive';
import { setDrawerNavigation } from './useSetDrawerOnNavigation';
import useWindowLocation from './useWindowLocation';

export default function useNavigateDrawer({
  forceAdd,
  drawerData
}: {
  forceAdd?: boolean;
  drawerData: { itemsToFilterFrom?: any[]; deepFilter?: boolean; drawerId: DRAWER_IDS; otherData?: Record<string, any>; otherParams?: { isExtended?: boolean; isPinned?: boolean } };
}) {
  const { location, pushState } = useWindowLocation();

  return ({ drawerLinkId }: { drawerLinkId: string }) => {
    let nextLocation;
    if (forceAdd || vars.drawerBars().length !== 1) {
      nextLocation = `${location.pathname}/${drawerLinkId}`;
    } else {
      nextLocation = `${location.pathname.split('/').slice(0, 4).join('/')}/${drawerLinkId}`;
    }
    pushState(nextLocation);
    setDrawerNavigation({ location: nextLocation, ...drawerData });
  };
}
