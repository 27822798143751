import { useReactiveVar } from '@apollo/client';
import React, { useImperativeHandle, useRef } from 'react';
import { Icon } from '../components/Shared/Forms/Forms';
import { Checkbox, CheckboxItemContainer } from '../components/Shared/Forms/styled';
import { vars } from '../reactive';
import useBulkSelect from './useBulkSelect';
import ItemSlideDownActions, { ItemSlideDownActionsRef } from '../views/Bookings/ItemSlideDownActions';

export type ItemsBulkActionsRef = { clearBulkActions: () => void; hideBulkActions: () => void };

const useListBulkActions = <T extends Record<string, any>>({
  getItems,
  ref,
  inDrawer,
  Menu
}: {
  getItems: () => (T & { id: string })[];
  ref?: React.ForwardedRef<ItemsBulkActionsRef>;
  Menu: React.FC<{ selectedItems: string[]; handleOnClickAction?: () => void }>;
  inDrawer?: boolean;
}) => {
  const bulkSelect = useBulkSelect({ getItems });
  const { selected, show: showBulkActions, clearSelected, hide, selectedAll, toggleSelectAllItems } = bulkSelect;

  const drawerBars = useReactiveVar(vars.drawerBars);
  const itemSlideDownActionsRef = useRef<ItemSlideDownActionsRef>(null);

  const handleOnClickAction = itemSlideDownActionsRef.current?.handleOnClickAction;

  const shouldRender = !drawerBars?.length || !!inDrawer;

  const selectAction = shouldRender && (
    <ItemSlideDownActions bulkSelect={bulkSelect} items={selected} ref={itemSlideDownActionsRef}>
      {Menu && <Menu selectedItems={selected} handleOnClickAction={handleOnClickAction} />}
    </ItemSlideDownActions>
  );

  const selectAllAction = shouldRender && showBulkActions && (
    <CheckboxItemContainer checked={selectedAll} noMargin onClick={toggleSelectAllItems} noWidth>
      <Checkbox>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </Checkbox>
      <span style={{ fontWeight: 700 }}>{selectedAll ? 'Deselect All' : 'Select All'}</span>
    </CheckboxItemContainer>
  );

  useImperativeHandle(ref, () => ({
    clearBulkActions: () => {
      clearSelected();
    },
    hideBulkActions: () => {
      hide();
    }
  }));

  return { bulkSelect, selectAction, selectAllAction };
};

export default useListBulkActions;
