import React from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import { FormError, FormInput, FormInputsContainer, FormLabel, InputWrapper, WideInputGroup } from './Forms/Forms';
import { FlexColumnCenterContainer } from '../Pets/styled';

const DurationSelector = ({
  defaultDuration,
  formOptions,
  options,
  Container = InputWrapper
}: {
  defaultDuration: number;
  formOptions: UseFormMethods<any>;
  options?: { title?: string; includeDays?: boolean; name?: string; rules?: React.ComponentProps<typeof Controller>['rules']; noTitle?: boolean };
  Container?: React.ComponentType<{ children: React.ReactNode }>;
}) => {
  const { control, errors } = formOptions;
  const defaultDays = defaultDuration ? Math.floor(defaultDuration / 1440) : 0;
  const defaultHours = defaultDuration ? Math.floor((defaultDuration - defaultDays * 1440) / 60) : 0;
  const defaultMinutes = defaultDuration ? defaultDuration - defaultDays * 1440 - defaultHours * 60 : 0;
  const defaultValues = {
    days: defaultDays,
    hours: defaultHours,
    minutes: defaultMinutes
  };
  const name = options?.name || 'duration';
  return (
    <>
      {!options?.noTitle && <FormLabel bold>{options?.title || 'Service Time (Hours) (Minutes)'}</FormLabel>}
      <FormInputsContainer flexDirection="row" gap={10} nowrap>
        {options?.includeDays && (
          <Controller
            render={({ onChange, value }) => (
              <FlexColumnCenterContainer>
                <FormLabel>Days</FormLabel>
                <FormInput
                  value={value}
                  onChange={e => {
                    const valueOnlyNumbers = e.target.value.replace(/\D/g, '');
                    onChange(valueOnlyNumbers);
                  }}
                  width={80}
                />
              </FlexColumnCenterContainer>
            )}
            control={control}
            name={`${name}.days`}
            defaultValue={defaultValues.days}
            rules={{
              ...options?.rules,
              min: { value: 0, message: 'days must be greater than or equal to 0' },
              max: { value: 365, message: 'days must be less than or equal to 365' }
            }}
          />
        )}
        <Controller
          render={({ onChange, value }) => (
            <FlexColumnCenterContainer>
              <FormLabel>Hours</FormLabel>
              <FormInput
                value={value}
                onChange={e => {
                  const valueOnlyNumbers = e.target.value.replace(/\D/g, '');
                  onChange(valueOnlyNumbers);
                }}
                flexOne={true}
              />
            </FlexColumnCenterContainer>
          )}
          control={control}
          name={`${name}.hours`}
          defaultValue={defaultValues.hours}
          rules={{
            ...options?.rules,
            min: { value: 0, message: 'hours must be greater than or equal to 0' },
            max: { value: 24, message: 'hours must be less than or equal to 24' }
          }}
        />
        <Controller
          render={({ onChange, value }) => (
            <FlexColumnCenterContainer>
              <FormLabel>Minutes</FormLabel>
              <FormInput
                value={value}
                onChange={e => {
                  const valueOnlyNumbers = e.target.value.replace(/\D/g, '');
                  onChange(valueOnlyNumbers);
                }}
                flexOne={true}
              />
            </FlexColumnCenterContainer>
          )}
          control={control}
          name={`${name}.minutes`}
          defaultValue={defaultValues.minutes}
          rules={{
            ...options?.rules,
            min: { value: 0, message: 'minutes must be greater than or equal to 0' },
            max: { value: 59, message: 'minutes must be less than or equal to 59' }
          }}
        />
      </FormInputsContainer>
      {options?.includeDays && errors?.[name]?.days && <FormError>{errors?.[name]?.days?.message || 'days is required'}</FormError>}
      {errors?.[name]?.hours && <FormError>{errors?.[name]?.hours?.message || 'hours is required'}</FormError>}
      {errors?.[name]?.minutes && <FormError>{errors?.[name]?.minutes?.message || 'minutes is required'}</FormError>}
    </>
  );
};

export const convertDurationToMinutes = (duration: { days: string; hours: string; minutes: string } | string | number | null) => {
  if (!duration || typeof duration === 'string' || typeof duration === 'number') {
    return duration || null;
  }
  const { days, hours, minutes } = duration;
  return String(parseInt(days || '0') * 1440 + parseInt(hours || '0') * 60 + parseInt(minutes));
};

export default DurationSelector;
