import React from 'react';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import { ExtensionPetCaseLabel } from '../../../../components/Pets/styled';
import { PetAdministeredRecordContainer } from './styled';
import { AddNewVaccination } from './VaccinationModal';
import { VaccRecord } from '../../Health/HealthPetRecords';
import Colors from '../../../../Colors';
import { BusUserProfile } from '../../../../components/Profile/types';
import OptionDropdown from '../../../../components/Shared/Menus/OptionDropdown/OptionDropdown';
import { OPTION_DROPDOWN_MENU_BUTTON_TYPES, OPTION_DROPDOWN_TYPES } from '../../../../components/Shared/Menus/OptionDropdown/types';
import ModalDialog from '../../../../components/Modal/ModalDialog';
import { MedCond, Pet, VaccRecord as VaccRecordType } from '../../../../components/Pets/types';

const PrescribedVaccinations = ({
  vaccRecords,
  hideButton = false,
  pet,
  createdByBranch,
  medCond
}: {
  vaccRecords: VaccRecordType[];
  hideButton?: boolean;
  pet: Pet;
  createdByBranch: boolean;
  medCond: MedCond;
}) => {
  const { mobile } = useMediaQuery({ mobile: true });
  const sortedVaccRecords = [...(vaccRecords || [])].sort((a, b) => {
    const aDate = new Date(a?.dates?.[a?.dates?.length - 1]);
    const bDate = new Date(b?.dates?.[b?.dates?.length - 1]);
    return bDate.getTime() - aDate.getTime();
  });

  return (
    <>
      <ExtensionPetCaseLabel fontSize={16} fontWeight={'800'} primary justify="space-between">
        Administered Vaccines
        {!mobile && !hideButton && createdByBranch && (
          <OptionDropdown
            menuButtonType={OPTION_DROPDOWN_MENU_BUTTON_TYPES.PLUS}
            options={[
              {
                id: 'options',
                optionType: OPTION_DROPDOWN_TYPES.BUTTONS,
                items: [
                  {
                    name: 'New Vaccination',
                    value: 'addVaccination',
                    onClick: () => ModalDialog.openModal({ content: () => <AddNewVaccination medCond={medCond} autoOpenModal /> }),
                    disabled: !createdByBranch
                  }
                ]
              }
            ]}
            noApplyButton
            containerRelative
          />
        )}
      </ExtensionPetCaseLabel>
      <PetAdministeredRecordContainer>
        {!sortedVaccRecords?.length && (
          <ExtensionPetCaseLabel fontSize={14} fontWeight={'800'} normalCursor color={Colors.grey}>
            -No Vaccines-
          </ExtensionPetCaseLabel>
        )}
        {sortedVaccRecords?.map((vacc, index) => {
          return <VaccRecord vaccRecord={vacc} pet={pet} key={index} medCond={medCond} />;
        })}
      </PetAdministeredRecordContainer>
    </>
  );
};

export default PrescribedVaccinations;
