import React from 'react';
import { DRAWER_IDS } from '../../../components/DrawerBar/types';
import BarChartValueVolume from '../../../components/Shared/Charts/BarChartValueVolume';
import LineChart from '../../../components/Shared/Charts/LineChart';
import PieChart from '../../../components/Shared/Charts/PieChart';
import { FULL_DAYS, MONTH_NAMES, toReadableDate } from '../../../utils/dates';
import { getBranchCurrencySymbol } from '../../../utils/getBranchCurrencySymbol';
import { getKeys, numberWithCommas } from '../../../utils/helpers';
import BranchSummaryDataTable from './BranchSummaryDataTable';
import { BranchSummaryCountHeader, BranchSummaryCountHeaderRow, BranchSummaryCountTitle, BranchSummaryCountWrapper } from './styled';
import { ALLOWED_COUNTS_FILTERS_TYPES_OPTIONS, ALLOWED_COUNTS_NAMED_GROUPS, ALLOWED_COUNTS_NAMES, allowedCountsItems } from './types';

const BranchSummaryCount = ({
  count,
  appliedFilters,
  mode = 'table',
  isExpanded,
  children
}: {
  count: {
    data: Record<string, any>;
    name: keyof typeof ALLOWED_COUNTS_NAMES;
  };
  appliedFilters: Record<string, any>;
  mode?: 'table' | 'chart';
  isExpanded?: boolean;
  children?: React.ReactNode;
}) => {
  if (!count) {
    return null;
  }
  const allowedFilters = getKeys(appliedFilters) as (keyof typeof ALLOWED_COUNTS_FILTERS_TYPES_OPTIONS)[];

  const groupName = ALLOWED_COUNTS_NAMED_GROUPS.find(group => group.options.find(option => option === count.name))?.label;
  const optionName = allowedCountsItems[count.name]?.name || count.name;
  return (
    <>
      <BranchSummaryCountHeader>
        <BranchSummaryCountHeaderRow>
          <BranchSummaryCountTitle>
            {groupName} - {optionName}
          </BranchSummaryCountTitle>
          {children}
        </BranchSummaryCountHeaderRow>
        {/* <BranchSummaryCountHeaderFilters>
          {allowedFilters.map(filter => {
            const filterItems = allowedCountsItems[count.name]?.allows.find(allowedFilter => allowedFilter.type === filter);
            const filterOptions = ALLOWED_COUNTS_FILTERS_TYPES_OPTIONS[filter];
            const valueType = filterOptions?.type;
            const valueText = appliedFilters[filter];
            if (!filterItems) {
              return null;
            }
            return (
              <div key={filter}>
                <BranchSummaryCountFilterTitle>{filterOptions.label || filter}: {valueText}</BranchSummaryCountFilterTitle>
              </div>
            );
          })}
        </BranchSummaryCountHeaderFilters> */}
      </BranchSummaryCountHeader>
      <BranchSummaryCountWrapper isExpanded={isExpanded}>
        {count.name === 'serviceTotalsOrdersCountAmount' && mode === 'table' && (
          <BranchSummaryDataTable
            headerRows={[{ label: 'Service' }, { label: 'Sum' }, { label: 'Count' }]}
            bodyColums={count?.data?.map((item: { id: string; name: string; sum: number; count: number }) => {
              return {
                rows: [{ label: item.name }, { label: `${getBranchCurrencySymbol()}${numberWithCommas(item.sum)}` }, { label: item.count }]
              };
            })}
          />
        )}
        {count.name === 'serviceTimeslotsAppointmentsCountAmount' && mode === 'table' && (
          <BranchSummaryDataTable
            headerRows={[{ label: 'Time' }, { label: 'Service' }, { label: 'Sum' }, { label: 'Count' }]}
            bodyColums={count?.data?.map((item: { hour: number; minute: number; total_orders: number; total_count: number; item_name: string }) => {
              return {
                rows: [
                  { label: `${String(item.hour).padStart(2, '0')}:${String(item.minute).padStart(2, '0')}` },
                  { label: item.item_name },
                  { label: `${getBranchCurrencySymbol()}${numberWithCommas(item.total_orders)}` },
                  { label: item.total_count }
                ]
              };
            })}
          />
        )}
        {count.name === 'serviceDayOfWeekAppointmentsCountAmount' && mode === 'table' && (
          <BranchSummaryDataTable
            headerRows={[{ label: 'Day' }, { label: 'Service' }, { label: 'Sum' }, { label: 'Count' }]}
            bodyColums={count?.data?.map((item: { day_of_week: string; total_orders: number; total_count: number; item_name: string }) => {
              return {
                rows: [
                  { label: FULL_DAYS[Number(item.day_of_week)] },
                  { label: item.item_name },
                  { label: `${getBranchCurrencySymbol()}${numberWithCommas(item.total_orders)}` },
                  { label: item.total_count }
                ]
              };
            })}
          />
        )}
        {count.name === 'serviceTotalsPaidUnpaidAmount' && mode === 'table' && (
          <BranchSummaryDataTable
            headerRows={[{ label: 'Service' }, { label: 'Paid' }, { label: 'Unpaid' }]}
            bodyColums={count?.data?.map((item: { id: string; name: string; total_paid: number; total_unpaid: number }) => {
              return {
                rows: [
                  { label: item.name },
                  { label: `${getBranchCurrencySymbol()}${numberWithCommas(item.total_paid)}` },
                  { label: `${getBranchCurrencySymbol()}${numberWithCommas(item.total_unpaid)}` }
                ]
              };
            })}
          />
        )}
        {count.name === 'serviceCanceledOrdersCount' && mode === 'table' && (
          <BranchSummaryDataTable
            headerRows={[{ label: 'Service' }, { label: 'Sum' }, { label: 'Count' }]}
            bodyColums={count?.data?.map((item: { id: string; sum: number; count: number; name: string }) => {
              return {
                rows: [{ label: item.name }, { label: `${getBranchCurrencySymbol()}${numberWithCommas(item.sum)}` }, { label: item.count }]
              };
            })}
          />
        )}
        {count.name === 'productTotalsOrdersCountAmount' && mode === 'table' && (
          <BranchSummaryDataTable
            headerRows={[{ label: 'Product' }, { label: 'Sum' }, { label: 'Count' }]}
            bodyColums={count?.data?.map((item: { id: string; name: string; sum: number; count: number }) => {
              return {
                rows: [{ label: item.name }, { label: `${getBranchCurrencySymbol()}${numberWithCommas(item.sum)}` }, { label: item.count }]
              };
            })}
          />
        )}
        {count.name === 'subscriptionTotalsOrderRepeatsCountAmount' && mode === 'table' && (
          <BranchSummaryDataTable
            headerRows={[{ label: 'Subscription' }, { label: 'Sum' }, { label: 'Count' }]}
            bodyColums={count?.data?.map((item: { id: string; name: string; sum: number; count: number }) => {
              return {
                rows: [{ label: item.name }, { label: `${getBranchCurrencySymbol()}${numberWithCommas(item.sum)}` }, { label: item.count }]
              };
            })}
          />
        )}
        {count.name === 'subscriptionOrderStartDateMonthsCountAmount' && mode === 'table' && (
          <BranchSummaryDataTable
            headerRows={[{ label: 'Month' }, { label: 'Subscription' }, { label: 'Sum' }, { label: 'Count' }]}
            bodyColums={count?.data?.map((item: { month: string; sum: number; count: number; name: string }) => {
              return {
                rows: [{ label: MONTH_NAMES[Number(item.month) - 1] }, { label: item.name }, { label: `${getBranchCurrencySymbol()}${numberWithCommas(item.sum)}` }, { label: item.count }]
              };
            })}
          />
        )}
        {count.name === 'subscriptionOrderEndDateMonthsCountAmount' && mode === 'table' && (
          <BranchSummaryDataTable
            headerRows={[{ label: 'Month' }, { label: 'Subscription' }, { label: 'Sum' }, { label: 'Count' }]}
            bodyColums={count?.data?.map((item: { month: string; sum: number; count: number; name: string }) => {
              return {
                rows: [{ label: MONTH_NAMES[Number(item.month) - 1] }, { label: item.name }, { label: `${getBranchCurrencySymbol()}${numberWithCommas(item.sum)}` }, { label: item.count }]
              };
            })}
          />
        )}
        {count.name === 'appUserServiceFrequencyCountAmount' && (
          <BranchSummaryDataTable
            headerRows={[{ label: 'Service' }, { label: 'Count' }, { label: 'User' }, { label: 'Sum' }]}
            bodyColums={count?.data?.map((item: { appuser_id: string; appuser_name: string; item_id: string; item_name: string; total_sum: number; total_count: number }) => {
              return {
                rows: [{ label: item.item_name }, { label: item.total_count }, { label: item.appuser_name }, { label: `${getBranchCurrencySymbol()}${numberWithCommas(item.total_sum)}` }]
              };
            })}
          />
        )}
        {count.name === 'appUserPetServiceFrequencyCountAmount' && (
          <BranchSummaryDataTable
            headerRows={[{ label: 'Service' }, { label: 'Count' }, { label: 'Pet' }, { label: 'User' }, { label: 'Sum' }, { label: 'Dates' }]}
            bodyColums={count?.data?.map(
              (item: {
                appuser_id: string;
                appuser_name: string;
                pet_id: string;
                pet_name: string;
                item_id: string;
                item_name: string;
                total_sum: number;
                total_count: number;
                appointment_timestamps: string[];
              }) => {
                const dates = item.appointment_timestamps.map(ts => toReadableDate(ts, { noTime: true, isLocale: true })).join(',\n');
                return {
                  rows: [
                    { label: item.item_name },
                    { label: item.total_count },
                    { label: item.pet_name },
                    { label: item.appuser_name },
                    { label: `${getBranchCurrencySymbol()}${numberWithCommas(item.total_sum)}` },
                    { label: dates }
                  ]
                };
              }
            )}
          />
        )}
        {count.name === 'neverBookedAppUserPetServiceFrequencyCountAmount' && (
          <BranchSummaryDataTable
            headerRows={[{ label: 'Service' }, { label: 'Count' }, { label: 'Pet' }, { label: 'User' }, { label: 'Sum' }, { label: 'Dates' }]}
            bodyColums={count?.data?.map(
              (item: {
                appuser_id: string;
                appuser_name: string;
                pet_id: string;
                pet_name: string;
                item_id: string;
                item_name: string;
                total_sum: number;
                total_count: number;
                appointment_timestamps: string[];
              }) => {
                const dates = item.appointment_timestamps.map(ts => toReadableDate(ts, { noTime: true, isLocale: true })).join(',\n');
                return {
                  rows: [
                    { label: item.item_name },
                    { label: item.total_count },
                    { label: item.pet_name },
                    { label: item.appuser_name },
                    { label: `${getBranchCurrencySymbol()}${numberWithCommas(item.total_sum)}` },
                    { label: dates }
                  ]
                };
              }
            )}
          />
        )}
        {count.name === 'appUserProductOrderFrequencyCountAmount' && (
          <BranchSummaryDataTable
            headerRows={[{ label: 'Product' }, { label: 'Count' }, { label: 'User' }, { label: 'Sum' }]}
            bodyColums={count?.data?.map((item: { appuser_id: string; appuser_name: string; item_id: string; item_name: string; total_sum: number; total_count: number }) => {
              return {
                rows: [{ label: item.item_name }, { label: item.total_count }, { label: item.appuser_name }, { label: `${getBranchCurrencySymbol()}${numberWithCommas(item.total_sum)}` }]
              };
            })}
          />
        )}
        {count.name === 'appUserTopSpender' && mode === 'table' && (
          <BranchSummaryDataTable
            headerRows={[{ label: 'User' }, { label: 'Sum' }]}
            bodyColums={count?.data?.map((item: { appuser_id: string; name: string; total_sum: number; total_count: number }) => {
              return {
                rows: [{ label: item.name }, { label: `${getBranchCurrencySymbol()}${numberWithCommas(item.total_sum)}` }]
              };
            })}
          />
        )}
        {count.name === 'orderCreateTimesCountAmount' && mode === 'table' && (
          <BranchSummaryDataTable
            headerRows={[{ label: 'Time' }, { label: 'Product/Service' }, { label: 'Sum' }, { label: 'Count' }]}
            bodyColums={count?.data?.map((item: { hour: string; sum: number; count: number; item_name: string; item_id: string }) => {
              return {
                rows: [{ label: item.hour }, { label: item.item_name }, { label: `${getBranchCurrencySymbol()}${numberWithCommas(item.sum)}` }, { label: item.count }]
              };
            })}
          />
        )}
        {count.name === 'orderCreateDayOfWeekCountAmount' && mode === 'table' && (
          <BranchSummaryDataTable
            headerRows={[{ label: 'Day' }, { label: 'Product/Service' }, { label: 'Sum' }, { label: 'Count' }]}
            bodyColums={count?.data?.map((item: { day_of_week: string; sum: number; count: number; item_name: string; item_id: string }) => {
              return {
                rows: [{ label: FULL_DAYS[Number(item.day_of_week)] }, { label: item.item_name }, { label: `${getBranchCurrencySymbol()}${numberWithCommas(item.sum)}` }, { label: item.count }]
              };
            })}
          />
        )}
        {count.name === 'petTypeCount' && mode === 'table' && (
          <BranchSummaryDataTable
            headerRows={[{ label: 'Pet Type' }, { label: 'Count' }]}
            bodyColums={count?.data?.map((item: { type: string; count: number }) => {
              return {
                rows: [{ label: item.type }, { label: item.count }]
              };
            })}
          />
        )}
        {count.name === 'petTypeBreedCount' && mode === 'table' && (
          <BranchSummaryDataTable
            headerRows={[{ label: 'Pet Breed' }, { label: 'Count' }]}
            bodyColums={count?.data?.map((item: { name: string; count: number }) => {
              return {
                rows: [{ label: item.name }, { label: item.count }]
              };
            })}
          />
        )}
        {count.name === 'petGenderCount' && mode === 'table' && (
          <BranchSummaryDataTable
            headerRows={[{ label: 'Gender' }, { label: 'Count' }]}
            bodyColums={count?.data?.map((item: { gender: string; count: number }) => {
              return {
                rows: [{ label: item.gender }, { label: item.count }]
              };
            })}
          />
        )}
        {count.name === 'petAgeCount' && mode === 'table' && (
          <BranchSummaryDataTable
            headerRows={[{ label: 'Age Group' }, { label: 'Count' }]}
            bodyColums={count?.data?.map((item: { age_group: string; count: number }) => {
              return {
                rows: [{ label: `${item.age_group} - ${Number(item.age_group + 1)} Years` }, { label: item.count }]
              };
            })}
          />
        )}
        {count.name === 'petNeuteringCount' && mode === 'table' && (
          <BranchSummaryDataTable
            headerRows={[{ label: 'Neutered Count' }, { label: 'Unneutered Count' }]}
            bodyColums={count?.data?.map((item: { neutered_count: number; unneutered_count: number }) => {
              return {
                rows: [{ label: item.neutered_count }, { label: item.unneutered_count }]
              };
            })}
          />
        )}
        {count.name === 'cancelationReasonsMonthsCount' && mode === 'table' && (
          <BranchSummaryDataTable
            headerRows={[{ label: 'Month' }, { label: 'Reason' }, { label: 'Count' }]}
            bodyColums={count?.data?.map((item: { month: string; reason: string; count: number }) => {
              return {
                rows: [{ label: MONTH_NAMES[Number(item.month) - 1] }, { label: item.reason }, { label: item.count }]
              };
            })}
          />
        )}
        {count.name === 'capacityDaysCount' && mode === 'table' && (
          <BranchSummaryDataTable
            headerRows={[{ label: 'Date' }, { label: 'Bookings' }, { label: 'Capacity' }]}
            bodyColums={count?.data?.map((item: { date: string; appointments_count: number; capacity: number }) => {
              return {
                rows: [
                  { label: toReadableDate(item.date, { noTime: true, isLocale: true }) },
                  { label: item.appointments_count },
                  { label: `${((item.capacity - item.appointments_count) / item.capacity) * 100}%` }
                ]
              };
            })}
          />
        )}
        {mode === 'chart' && (
          <>
            {count.name === 'serviceTotalsOrdersCountAmount' && <ServiceTotalsOrdersCountAmount data={count?.data} />}
            {count.name === 'serviceTimeslotsAppointmentsCountAmount' && <ServiceTimeslotsAppointmentsCountAmount data={count?.data} />}
            {count.name === 'serviceDayOfWeekAppointmentsCountAmount' && <ServiceDayOfWeekAppointmentsCountAmount data={count?.data} />}
            {count.name === 'serviceTotalsPaidUnpaidAmount' && <ServiceTotalsPaidUnpaidAmount data={count?.data} />}
            {count.name === 'serviceCanceledOrdersCount' && <ServiceCanceledOrdersCount data={count?.data} />}
            {count.name === 'productTotalsOrdersCountAmount' && <ProductTotalsOrdersCountAmount data={count?.data} />}
            {/* {count.name === 'subscriptionTotalsOrderRepeatsCountAmount' && <SubscriptionTotalsOrderRepeatsCountAmount data={count?.data} />} */}
            {count.name === 'subscriptionOrderStartDateMonthsCountAmount' && <SubscriptionOrderStartDateMonthsCountAmount data={count?.data} />}
            {count.name === 'subscriptionOrderEndDateMonthsCountAmount' && <SubscriptionOrderEndDateMonthsCountAmount data={count?.data} />}
            {/* {count.name === 'appUserServiceFrequencyCountAmount' && <AppUserServiceFrequencyCountAmount data={count?.data} />} */}
            {/* {count.name === 'appUserPetServiceFrequencyCountAmount' && <AppUserPetServiceFrequencyCountAmount data={count?.data} />} */}
            {/* {count.name === 'neverBookedAppUserPetServiceFrequencyCountAmount' && <NeverBookedAppUserPetServiceFrequencyCountAmount data={count?.data} />} */}
            {/* {count.name === 'appUserProductOrderFrequencyCountAmount' && <AppUserProductOrderFrequencyCountAmount data={count?.data} />} */}
            {count.name === 'appUserTopSpender' && <AppUserTopSpender data={count?.data} />}
            {count.name === 'orderCreateTimesCountAmount' && <OrderCreateTimesCountAmount data={count?.data} />}
            {count.name === 'orderCreateDayOfWeekCountAmount' && <OrderCreateDayOfWeekCountAmount data={count?.data} />}
            {count.name === 'petTypeCount' && <PetTypeCount data={count?.data} />}
            {count.name === 'petTypeBreedCount' && <PetTypeBreedCount data={count?.data} />}
            {count.name === 'petGenderCount' && <PetGenderCount data={count?.data} />}
            {count.name === 'petAgeCount' && <PetAgeCount data={count?.data} />}
            {count.name === 'petNeuteringCount' && <PetNeuteringCount data={count?.data} />}
            {count.name === 'cancelationReasonsMonthsCount' && <CancelationReasonsMonthsCount data={count?.data} />}
            {count.name === 'capacityDaysCount' && <CapacityDaysCount data={count?.data} />}
          </>
        )}
      </BranchSummaryCountWrapper>
    </>
  );
};

const ServiceTotalsOrdersCountAmount = ({ data }: { data: Record<string, any> }) => {
  return (
    <BarChartValueVolume
      data={data?.map((item: { id: string; name: string; sum: number; count: number }) => {
        const duplicateNameValues = data?.filter((item2: { id: string; name: string; sum: number; count: number }) => item2.name === item.name);
        const currentNameIndex = duplicateNameValues.findIndex((item2: { id: string }) => item2.id === item.id);

        return {
          id: item.id,
          Value: item.sum,
          Volume: item.count,
          name: duplicateNameValues.length > 1 ? `${item.name} (${currentNameIndex + 1})` : item.name
        };
      })}
      keys={['Value', 'Volume']}
      labels={{
        left: 'Value',
        bottom: 'Service'
      }}
    />
  );
};

const ServiceTimeslotsAppointmentsCountAmount = ({ data }: { data: any }) => {
  const groupedData = data?.reduce((acc: Record<string, any>, item: { hour: number; minute: number; total_orders: number; total_count: number; item_name: string }) => {
    const name = `${String(item.hour).padStart(2, '0')}:${String(item.minute).padStart(2, '0')}`;
    const values = acc?.[name] || [];
    return { ...acc, [name]: [...values, { key: item.item_name, value: item.total_orders, volume: item.total_count }] };
  }, {});

  return (
    <BarChartValueVolume
      data={getKeys(groupedData)?.map(key => ({
        id: key,
        name: key,
        ...groupedData[key].reduce(
          (acc: Record<string, any>, item: { key: string; value: number; volume: number }) => ({
            ...acc,
            [item.key]: item.volume
          }),
          {}
        )
      }))}
      keys={[...new Set<string>(data?.map((item: { item_name: string }) => item.item_name))]}
      labels={{
        left: 'Volume',
        bottom: 'Time'
      }}
    />
  );
};

const ServiceDayOfWeekAppointmentsCountAmount = ({ data }: { data: any }) => {
  const groupedData = data?.reduce((acc: Record<string, any>, item: { day_of_week: string; total_orders: number; total_count: number; item_name: string }) => {
    const name = FULL_DAYS[Number(item.day_of_week)];
    const values = acc?.[name] || [];
    return { ...acc, [name]: [...values, { key: item.item_name, value: item.total_orders, volume: item.total_count }] };
  }, {});

  return (
    <BarChartValueVolume
      data={getKeys(groupedData)?.map(key => ({
        id: key,
        name: key,
        ...groupedData[key].reduce(
          (acc: Record<string, any>, item: { key: string; value: number; volume: number }) => ({
            ...acc,
            [item.key]: item.volume
          }),
          {}
        )
      }))}
      keys={[...new Set<string>(data?.map((item: { item_name: string }) => item.item_name))]}
      labels={{
        left: 'Volume',
        bottom: 'Day of Week'
      }}
    />
  );
};

const ServiceTotalsPaidUnpaidAmount = ({ data }: { data: any }) => {
  return (
    <BarChartValueVolume
      data={data?.map((item: { id: string; name: string; total_paid: number; total_unpaid: number }) => {
        const duplicateNameValues = data?.filter((item2: { id: string; name: string; total_paid: number; total_unpaid: number }) => item2.name === item.name);
        const currentNameIndex = duplicateNameValues.findIndex((item2: { id: string }) => item2.id === item.id);

        return {
          id: item.id,
          Paid: item.total_paid,
          Unpaid: item.total_unpaid,
          name: duplicateNameValues.length > 1 ? `${item.name} (${currentNameIndex + 1})` : item.name
        };
      })}
      keys={['Paid', 'Unpaid']}
      labels={{
        left: 'Value',
        bottom: 'Service'
      }}
    />
  );
};

const SubscriptionOrderStartDateMonthsCountAmount = ({ data }: { data: any }) => {
  const groupedData = data?.reduce((acc: Record<string, any>, item: { month: string; sum: number; count: number; name: string }) => {
    const name = MONTH_NAMES[Number(Number(item.month) - 1)];
    const values = acc?.[name] || [];
    return { ...acc, [name]: [...values, { key: item.name, value: item.sum, volume: item.count }] };
  }, {});

  return (
    <BarChartValueVolume
      data={getKeys(groupedData)?.map(key => ({
        id: key,
        name: key,
        ...groupedData[key].reduce(
          (acc: Record<string, any>, item: { key: string; value: number; volume: number }) => ({
            ...acc,
            [item.key]: item.value
          }),
          {}
        )
      }))}
      keys={[...new Set<string>(data?.map((item: { name: string }) => item.name))]}
      labels={{
        left: 'Value',
        bottom: 'Month'
      }}
      formatLabel={params => {
        return `${[...(groupedData[params.indexValue] || [])]?.find?.(item => item?.key === params.id)?.volume}`;
      }}
    />
  );
};

const SubscriptionOrderEndDateMonthsCountAmount = ({ data }: { data: any }) => {
  const groupedData = data?.reduce((acc: Record<string, any>, item: { month: string; sum: number; count: number; name: string }) => {
    const name = MONTH_NAMES[Number(Number(item.month) - 1)];
    const values = acc?.[name] || [];
    return { ...acc, [name]: [...values, { key: item.name, value: item.sum, volume: item.count }] };
  }, {});

  return (
    <BarChartValueVolume
      data={getKeys(groupedData)?.map(key => ({
        id: key,
        name: key,
        ...groupedData[key].reduce(
          (acc: Record<string, any>, item: { key: string; value: number; volume: number }) => ({
            ...acc,
            [item.key]: item.value
          }),
          {}
        )
      }))}
      keys={[...new Set<string>(data?.map((item: { name: string }) => item.name))]}
      labels={{
        left: 'Value',
        bottom: 'Month'
      }}
      formatLabel={params => {
        return `${[...(groupedData[params.indexValue] || [])]?.find?.(item => item?.key === params.id)?.volume}`;
      }}
    />
  );
};

const ServiceCanceledOrdersCount = ({ data }: { data: any }) => {
  return (
    <BarChartValueVolume
      data={data?.map((item: { id: string; name: string; sum: number; count: number }) => {
        const duplicateNameValues = data?.filter((item2: { id: string; name: string; sum: number; count: number }) => item2.name === item.name);
        const currentNameIndex = duplicateNameValues.findIndex((item2: { id: string }) => item2.id === item.id);

        return {
          id: item.id,
          Value: item.sum,
          Volume: item.count,
          name: duplicateNameValues.length > 1 ? `${item.name} (${currentNameIndex + 1})` : item.name
        };
      })}
      keys={['Value', 'Volume']}
      labels={{
        left: 'Value',
        bottom: 'Service'
      }}
    />
  );
};

const ProductTotalsOrdersCountAmount = ({ data }: { data: any }) => {
  return (
    <BarChartValueVolume
      data={data?.slice(0, 10)?.map((item: { id: string; name: string; sum: number; count: number }) => {
        const duplicateNameValues = data?.filter((item2: { id: string; name: string; sum: number; count: number }) => item2.name === item.name);
        const currentNameIndex = duplicateNameValues.findIndex((item2: { id: string }) => item2.id === item.id);

        return {
          id: item.id,
          Value: item.sum,
          Volume: item.count,
          name: duplicateNameValues.length > 1 ? `${item.name} (${currentNameIndex + 1})` : item.name
        };
      })}
      keys={['Value', 'Volume']}
      labels={{
        left: 'Value',
        bottom: 'Product'
      }}
    />
  );
};

const SubscriptionTotalsOrderRepeatsCountAmount = ({ data }: { data: any }) => {
  return (
    <BarChartValueVolume
      data={data?.map((item: { id: string; name: string; sum: number; count: number }) => {
        const duplicateNameValues = data?.filter((item2: { id: string; name: string; sum: number; count: number }) => item2.name === item.name);
        const currentNameIndex = duplicateNameValues.findIndex((item2: { id: string }) => item2.id === item.id);

        return {
          id: item.id,
          Value: item.sum,
          Volume: item.count,
          name: duplicateNameValues.length > 1 ? `${item.name} (${currentNameIndex + 1})` : item.name
        };
      })}
      keys={['Value', 'Volume']}
      labels={{
        left: 'Value',
        bottom: 'Subscription'
      }}
    />
  );
};

const AppUserServiceFrequencyCountAmount = ({ data }: { data: any }) => {
  return (
    <BarChartValueVolume
      data={data?.map((item: { appuser_id: string; appuser_name: string; item_id: string; item_name: string; total_sum: number; total_count: number }) => {
        const duplicateNameValues = data?.filter(
          (item2: { appuser_id: string; appuser_name: string; item_id: string; item_name: string; total_sum: number; total_count: number }) => item2.appuser_name === item.appuser_name
        );
        const currentNameIndex = duplicateNameValues.findIndex((item2: { appuser_id: string }) => item2.appuser_id === item.appuser_id);

        return {
          id: item.appuser_id,
          Value: item.total_sum,
          Volume: item.total_count,
          name: duplicateNameValues.length > 1 ? `${item.appuser_name} (${currentNameIndex + 1})` : item.appuser_name
        };
      })}
      keys={['Value', 'Volume']}
      labels={{
        left: 'Value',
        bottom: 'User'
      }}
    />
  );
};

const AppUserPetServiceFrequencyCountAmount = ({ data }: { data: any }) => {
  return (
    <BarChartValueVolume
      data={data?.map((item: { appuser_id: string; appuser_name: string; pet_id: string; pet_name: string; item_id: string; item_name: string; total_sum: number; total_count: number }) => {
        const duplicateNameValues = data?.filter(
          (item2: { appuser_id: string; appuser_name: string; pet_id: string; pet_name: string; item_id: string; item_name: string; total_sum: number; total_count: number }) =>
            item2.appuser_name === item.appuser_name && item2.pet_name === item.pet_name
        );
        const currentNameIndex = duplicateNameValues.findIndex((item2: { appuser_id: string; pet_id: string }) => item2.appuser_id === item.appuser_id && item2.pet_id === item.pet_id);

        return {
          id: item.appuser_id,
          Value: item.total_sum,
          Volume: item.total_count,
          name: duplicateNameValues.length > 1 ? `${item.appuser_name} (${currentNameIndex + 1})` : item.appuser_name
        };
      })}
      keys={['Value', 'Volume']}
      labels={{
        left: 'Value',
        bottom: 'User'
      }}
    />
  );
};

const NeverBookedAppUserPetServiceFrequencyCountAmount = ({ data }: { data: any }) => {
  return (
    <BarChartValueVolume
      data={data?.map((item: { appuser_id: string; appuser_name: string; pet_id: string; pet_name: string; item_id: string; item_name: string; total_sum: number; total_count: number }) => {
        const duplicateNameValues = data?.filter(
          (item2: { appuser_id: string; appuser_name: string; pet_id: string; pet_name: string; item_id: string; item_name: string; total_sum: number; total_count: number }) =>
            item2.appuser_name === item.appuser_name && item2.pet_name === item.pet_name
        );
        const currentNameIndex = duplicateNameValues.findIndex((item2: { appuser_id: string; pet_id: string }) => item2.appuser_id === item.appuser_id && item2.pet_id === item.pet_id);

        return {
          id: item.appuser_id,
          Value: item.total_sum,
          Volume: item.total_count,
          name: duplicateNameValues.length > 1 ? `${item.appuser_name} (${currentNameIndex + 1})` : item.appuser_name
        };
      })}
      keys={['Value', 'Volume']}
      labels={{
        left: 'Value',
        bottom: 'User'
      }}
    />
  );
};

const AppUserProductOrderFrequencyCountAmount = ({ data }: { data: any }) => {
  return (
    <BarChartValueVolume
      data={data?.map((item: { appuser_id: string; appuser_name: string; item_id: string; item_name: string; total_sum: number; total_count: number }) => {
        const duplicateNameValues = data?.filter(
          (item2: { appuser_id: string; appuser_name: string; item_id: string; item_name: string; total_sum: number; total_count: number }) => item2.appuser_name === item.appuser_name
        );
        const currentNameIndex = duplicateNameValues.findIndex((item2: { appuser_id: string }) => item2.appuser_id === item.appuser_id);

        return {
          id: item.appuser_id,
          Value: item.total_sum,
          Volume: item.total_count,
          name: duplicateNameValues.length > 1 ? `${item.appuser_name} (${currentNameIndex + 1})` : item.appuser_name
        };
      })}
      keys={['Value', 'Volume']}
      labels={{
        left: 'Value',
        bottom: 'User'
      }}
    />
  );
};

const AppUserTopSpender = ({ data }: { data: any }) => {
  return (
    <PieChart
      data={data?.map((item: { appuser_id: string; name: string; total_sum: number; total_count: number }) => ({
        id: item.name,
        item_id: item.appuser_id,
        label: item.name,
        value: item.total_sum
      }))}
      drawerId={DRAWER_IDS.USER_DRAWER}
    />
  );
};

const OrderCreateTimesCountAmount = ({ data }: { data: any }) => {
  const groupedData = data?.reduce((acc: Record<string, any>, item: { hour: number; sum: number; count: number; item_name: string; item_id: string }) => {
    const duplicateNameValues = data?.filter((item2: { id: string; name: string; sum: number; count: number }) => item2.name === item.item_name);
    const currentNameIndex = duplicateNameValues.findIndex((item2: { id: string }) => item2.id === item.item_id);

    const name = duplicateNameValues.length > 1 ? `${item.item_name} (${currentNameIndex + 1})` : item.item_name;

    const values = acc?.[name] || [];
    return { ...acc, [name]: [...values, { key: item.hour, value: item.sum, volume: item.count }] };
  }, {});

  const dataToRender = getKeys(groupedData)?.map(key => ({
    id: key,
    data: groupedData[key].map((item: { key: string; value: number; volume: number }) => ({
      x: item.key,
      y: item.volume
    }))
  }));

  return (
    <LineChart
      data={dataToRender}
      labels={{
        left: 'Value',
        bottom: 'Time'
      }}
    />
  );
};

const OrderCreateDayOfWeekCountAmount = ({ data }: { data: any }) => {
  const groupedData = data?.reduce((acc: Record<string, any>, item: { day_of_week: string; sum: number; count: number; item_name: string; item_id: string }) => {
    const duplicateNameValues = data?.filter((item2: { id: string; name: string; sum: number; count: number }) => item2.name === item.item_name);
    const currentNameIndex = duplicateNameValues.findIndex((item2: { id: string }) => item2.id === item.item_id);

    const name = duplicateNameValues.length > 1 ? `${item.item_name} (${currentNameIndex + 1})` : item.item_name;

    const values = acc?.[name] || [];
    return { ...acc, [name]: [...values, { key: item.day_of_week, value: item.sum, volume: item.count }] };
  }, {});

  const dataToRender = getKeys(groupedData)?.map(key => ({
    id: key,
    data: groupedData[key].map((item: { key: string; value: number; volume: number }) => ({
      x: item.key,
      y: item.volume
    }))
  }));

  return (
    <LineChart
      data={dataToRender}
      labels={{
        left: 'Value',
        bottom: 'Day of Week'
      }}
    />
  );
};

const PetTypeCount = ({ data }: { data: any }) => {
  return (
    <PieChart
      data={data?.map((item: { type: string; count: number }) => ({
        id: item.type,
        label: item.type,
        value: item.count
      }))}
    />
  );
};

const PetTypeBreedCount = ({ data }: { data: any }) => {
  return (
    <PieChart
      data={data?.map((item: { name: string; count: number }) => ({
        id: item.name,
        label: item.name,
        value: item.count
      }))}
    />
  );
};

const PetGenderCount = ({ data }: { data: any }) => {
  return (
    <PieChart
      data={data?.map((item: { gender: string; count: number }) => ({
        id: item.gender,
        label: item.gender,
        value: item.count
      }))}
    />
  );
};

const PetAgeCount = ({ data }: { data: any }) => {
  return (
    <PieChart
      data={data?.map((item: { age_group: string; count: number }) => ({
        id: `${item.age_group} - ${Number(item.age_group + 1)} Years`,
        label: `${item.age_group} - ${Number(item.age_group + 1)} Years`,
        value: item.count
      }))}
    />
  );
};

const PetNeuteringCount = ({ data }: { data: any }) => {
  return (
    <PieChart
      data={[
        {
          id: 'Neutered',
          label: 'Neutered',
          value: data?.[0]?.neutered_count
        },
        {
          id: 'Unneutered',
          label: 'Unneutered',
          value: data?.[0]?.unneutered_count
        }
      ]}
    />
  );
};

const CapacityDaysCount = ({ data }: { data: any }) => {
  const groupedData = data?.reduce((acc: Record<string, any>, item: { date: string; appointments_count: number; capacity: number }) => {
    const name = item.date;
    const values = acc?.[name] || [];
    return {
      ...acc,
      [name]: [
        ...values,
        { key: 'appointments', value: 100 - Number(((item.capacity - item.appointments_count) / item.capacity) * 100) },
        { key: 'capacity', value: Number(((item.capacity - item.appointments_count) / item.capacity) * 100) }
      ]
    };
  }, {});

  return (
    <BarChartValueVolume
      data={getKeys(groupedData)?.map(key => ({
        id: key,
        name: key,
        ...groupedData[key].reduce(
          (acc: Record<string, any>, item: { key: string; value: number }) => ({
            ...acc,
            [item.key]: item.value
          }),
          {}
        )
      }))}
      keys={['appointments', 'capacity']}
      groupMode="stacked"
      labels={{
        left: 'Value',
        bottom: 'Date'
      }}
    />
  );
};

const CancelationReasonsMonthsCount = ({ data }: { data: any }) => {
  const groupedData = data?.reduce((acc: Record<string, any>, item: { month: string; reason: string; count: number }) => {
    const name = MONTH_NAMES[Number(item.month) - 1];
    const values = acc?.[name] || [];
    return { ...acc, [name]: [...values, { key: item.reason, value: item.count }] };
  }, {});

  return (
    <BarChartValueVolume
      data={getKeys(groupedData)?.map(key => ({
        id: key,
        name: key,
        ...groupedData[key].reduce(
          (acc: Record<string, any>, item: { key: string; value: number }) => ({
            ...acc,
            [item.key]: item.value
          }),
          {}
        )
      }))}
      groupMode="stacked"
      keys={[...new Set<string>(data?.map((item: { reason: string }) => item.reason))]}
      labels={{
        left: 'Value',
        bottom: 'Month'
      }}
    />
  );
};

export default BranchSummaryCount;
