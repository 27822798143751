import React, { ReactNode, Ref, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { sentenceCase } from 'sentence-case';
import { Controller, useForm } from 'react-hook-form';
import { ModalBody, ModalFooter } from '../../../components/Modal/styled';
import { FormButtonsContainer, FormError, FormLabel, FormSubmitButton, InputsWrapper, WideInputGroup, FormInput, FormSelect } from '../../../components/Shared/Forms/Forms';
import { Container } from '../../../components/Shared/Shared';
import { EditPetRecord } from '../../../queries';
import { Shape, SHAPES } from './Health';
import ModalDialog, { ModalLayout } from '../../../components/Modal/ModalDialog';

const HealthWeightModalForm = ({ handleEditPetRecord, loading, error, pet }) => {
  const { control, handleSubmit, errors } = useForm();
  const shape: Shape = pet?.PetRecord?.shape;
  const currentWeight: string = pet?.PetRecord?.measurements?.weights?.slice(-1)?.[0]?.weight;

  const handleSave = handleSubmit(form => {
    handleEditPetRecord({
      variables: {
        id: pet?.PetRecord?.id,
        measurements: form.weight
          ? { ...pet?.PetRecord?.measurements, weights: [...(pet?.PetRecord?.measurements?.weights || []), { weight: Number(form.weight), date: new Date().toISOString() }] }
          : undefined,
        shape: form.shape
      }
    });
  });

  return (
    <ModalLayout
      compact
      buttons={[
        <FormSubmitButton error={error} loading={loading} onClick={handleSave}>
          Save Weight
        </FormSubmitButton>
      ]}
    >
      <WideInputGroup>
        <FormLabel>Current Weight</FormLabel>
        <Controller
          as={<FormInput error={errors.weight} type={'number'} flexBasis={78} />}
          control={control}
          name={'weight'}
          defaultValue={currentWeight || 0}
          rules={{
            required: true,
            min: 0.1,
            max: 999
          }}
        />
        {errors.weight && <FormError>{errors.weight.message || 'Current Weight is required'}</FormError>}
      </WideInputGroup>

      <WideInputGroup>
        <FormLabel>Body Condition Score</FormLabel>
        <Controller
          render={({ onChange }) => (
            <FormSelect height={48} fontSize={16} name={'shape'} onChange={e => onChange(e.target.value)} defaultValue={shape}>
              {SHAPES.map(shape => (
                <option key={shape} value={shape}>
                  {SHAPES.indexOf(shape) + 1} - {sentenceCase(shape === 'BELOW_IDEAL' ? 'LEAN' : shape || '')}
                </option>
              ))}
            </FormSelect>
          )}
          control={control}
          name={'shape'}
          rules={{ required: true }}
          defaultValue={shape}
        />
        {errors.shape && <FormError>{errors.shape.message || 'Body Condition Score is required'}</FormError>}
      </WideInputGroup>
    </ModalLayout>
  );
};

type HealthWeightModalProps = { pet?: {} };
const HealthWeightModal = ({ pet }: HealthWeightModalProps) => {
  const [handleEditPetRecord, { data: editedWeight, loading: loading, error: error }] = useMutation(EditPetRecord);

  useEffect(() => {
    if (editedWeight) {
      ModalDialog?.closeModal();
    }
  }, [editedWeight]);

  return <HealthWeightModalForm {...{ handleEditPetRecord, loading, error, pet }} />;
};

export default HealthWeightModal;
