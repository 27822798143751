import { useEffect, useState } from 'react';
import { checkIfTextHasLink, getAllLinksFromText, getLinkHostname } from '../../utils/helpers';
import { checkIfLinkIsDynamic, destructDynamicLink, getDynamicLinkData } from './helpers';
import { DynamicLink } from './types';

export default function useDynamicLinks(message: string, options: { loadHandler?: boolean } = {}) {
  const { loadHandler = true } = options;
  const hasLinks = checkIfTextHasLink(message);

  const links: string[] = getAllLinksFromText(message) || [];
  const allDynamicLinks = links?.filter(link => checkIfLinkIsDynamic(link));
  const regularLinks = links?.filter(link => !checkIfLinkIsDynamic(link));
  const hasDynamicLinks = allDynamicLinks?.length > 0;

  const defaultDynamicLinksState = allDynamicLinks.map(link => ({
    ...destructDynamicLink(link),
    handleClick: () => {
      handleLinkClick(link);
      loadDynamicLinkData(link);
    },
    handleClose: () => handleLinkClose(link)
  }));

  const [loading, setLoading] = useState<{ handle: boolean; data: boolean }>({ handle: false, data: false });
  const [clickedLink, setClickedLink] = useState<string>('');
  const [dynamicLinks, setDynamicLinks] = useState<DynamicLink[]>(defaultDynamicLinksState);

  const clickedDynamicLink = dynamicLinks.find(dynamicLink => dynamicLink.link === clickedLink);

  const updateDynamicLink = (link: string, data: Partial<DynamicLink>) => {
    const dynamicLinkIndex = dynamicLinks.findIndex(dynamicLink => dynamicLink.link === link);
    const dynamicLink = dynamicLinks[dynamicLinkIndex];
    const updatedDynamicLink = {
      ...dynamicLink,
      ...data
    };
    setDynamicLinks([...dynamicLinks.slice(0, dynamicLinkIndex), updatedDynamicLink, ...dynamicLinks.slice(dynamicLinkIndex + 1)]);
  };

  const handleLinkClick = (link: string) => {
    const isDynamic = checkIfLinkIsDynamic(link);
    if (!isDynamic) {
      if (typeof window !== 'undefined') {
        window.open(link, '_blank');
      }
      return;
    }

    setClickedLink(link);
  };

  const handleLinkClose = (link: string) => {
    const isDynamic = checkIfLinkIsDynamic(link);
    if (!isDynamic) {
      return;
    }

    setClickedLink('');
  };

  const allLinksWithData = links.map(link => {
    const isDynamic = checkIfLinkIsDynamic(link);
    const isEmail = link?.startsWith('mailto:');
    const externalLink = isEmail || link?.startsWith('http') ? link : `http://${link}`;
    if (!isDynamic) {
      const title = isEmail ? link.replace('mailto:', '') : getLinkHostname(link);
      return { link, title, handleClick: () => window.open(link, '_blank'), isDynamic: false, data: {}, deepLinkType: null, linkType: null, externalLink, isEmail };
    }
    return dynamicLinks.find(dynamicLink => dynamicLink.link === link)!;
  });

  const messageWithoutAnyLinks =
    links?.reduce((acc, link) => {
      try {
        return decodeURIComponent(acc).replace(link, '');
      } catch (e) {
        return acc;
      }
    }, message) || message;

  const messageWithoutDynamicLinks =
    allDynamicLinks?.reduce((acc, link) => {
      try {
        return decodeURIComponent(acc).replace(link, '');
      } catch (e) {
        return acc;
      }
    }, message) || message;

  const dynamicLinkHandler = async () => {
    setLoading({ ...loading, handle: true });
    const dynamicLinksData = await Promise.all(dynamicLinks?.map(async dynamicLink => await getDynamicLinkData(dynamicLink)));
    setDynamicLinks(dynamicLinksData);
    setLoading({ ...loading, handle: false });
  };

  useEffect(() => {
    if (!loadHandler) {
      return;
    }
    dynamicLinkHandler();

    return () => {
      setDynamicLinks(defaultDynamicLinksState);
    };
  }, [message]);

  const loadDynamicLinkData = async (link: string) => {
    const dynamicLink = dynamicLinks.find(dynamicLink => dynamicLink.link === link)!;
    try {
      const {
        linkItems: { dataHandler }
      } = dynamicLink;
      const dynamicLinkData = await dataHandler(clickedDynamicLink!);

      return dynamicLinkData;
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (clickedDynamicLink) {
      (async () => {
        setLoading({ ...loading, data: true });
        const dynamicLinkData = await loadDynamicLinkData(clickedDynamicLink.link);
        setLoading({ ...loading, data: false });
        updateDynamicLink(clickedDynamicLink.link, dynamicLinkData!);
        if (!dynamicLinkData?.loadModal) {
          dynamicLinkData?.linkItems?.navigator?.(dynamicLinkData);
        }
      })();
    }
  }, [clickedLink]);

  return {
    messageWithoutDynamicLinks,
    messageWithoutAnyLinks,
    dynamicLinks,
    hasLinks,
    hasDynamicLinks,
    regularLinks,
    allLinks: allLinksWithData || [],
    clickedLink: clickedDynamicLink,
    loadingData: loading.data,
    loadingHandle: loading.handle
  };
}
