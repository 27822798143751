import React from 'react';
import { PetRecord } from '../../components/Pets/types';
import { BusUserProfile } from '../../components/Profile/types';
import { Product } from '../Store/types';
import FleetsRoutes from './FleetsRoutes';
import FleetsSchedule from './FleetsSchedule';
import FleetsVehicles from './FleetsVehicles';

export enum FLEETS_TABS_TYPES {
  ROUTES = 'routes',
  VEHICLES = 'vehicles',
  SCHEDULE = 'schedule'
}

export const FLEETS_TABS = [
  {
    name: 'Routes',
    value: FLEETS_TABS_TYPES.ROUTES,
    component: ({ isShortestPath }: { isShortestPath: boolean }) => <FleetsRoutes isShortestPath={isShortestPath} />
  },
  {
    name: 'Vehicles',
    value: FLEETS_TABS_TYPES.VEHICLES,
    component: () => <FleetsVehicles />
  },
  {
    name: 'Schedule',
    value: FLEETS_TABS_TYPES.SCHEDULE,
    component: () => <FleetsSchedule />
  }
];

export const FLEETS_SECTIONS = [
  {
    tabs: [FLEETS_TABS[2]]
  },
  {
    tabs: [FLEETS_TABS[0], FLEETS_TABS[1]]
  }
];

export enum ROUTE_MODAL_TABS_TYPES {
  PETS = 'pets',
  SETTINGS = 'settings'
}

export const ROUTE_MODAL_TABS = [
  {
    name: 'Pets',
    value: ROUTE_MODAL_TABS_TYPES.PETS
  },
  {
    name: 'Settings',
    value: ROUTE_MODAL_TABS_TYPES.SETTINGS
  }
];

export const ROUTE_MODAL_SECTIONS = [
  {
    tabs: [ROUTE_MODAL_TABS[0]]
  },
  {
    tabs: [ROUTE_MODAL_TABS[1]]
  }
];

export enum VEHICLE_MODAL_TABS_TYPES {
  ROUTES = 'routes',
  SETTINGS = 'settings'
}

export const VEHICLE_MODAL_TABS = [
  {
    name: 'Routes',
    value: VEHICLE_MODAL_TABS_TYPES.ROUTES
  },
  {
    name: 'Settings',
    value: VEHICLE_MODAL_TABS_TYPES.SETTINGS
  }
];

export const VEHICLE_MODAL_SECTIONS = [
  {
    tabs: [VEHICLE_MODAL_TABS[0]]
  },
  {
    tabs: [VEHICLE_MODAL_TABS[1]]
  }
];

export type BranchRoute = {
  id: string;
  name: string;
  start_time: string;
  job_duration: number;
  sort_index: number;
  color: string;
  BranchAreaId: string;
  BranchRoutePetRecords: {
    id: string;
    sort_index: number;
    PetRecord: PetRecord;
    RouteJobTypeId: string;
    RouteJobType: RouteJobType;
  }[];
  BranchRouteProducts: {
    id: string;
    Product: Product;
  }[];
};

export type BranchVehicle = {
  id: string;
  name: string;
  routesCount: number;
  busUserNames: string[];
  plate_number: string;
  image: string | null;
  type: 'VAN' | 'MINI_VAN';
  status: 'ACTIVE' | 'INACTIVE';
  color: string;
  daily_capacity: {
    size: 'GENERAL' | 'SMALL' | 'MEDIUM' | 'LARGE';
    capacity: number;
  }[];
  BranchVehicleRoutes: {
    id: string;
    BranchRoute: BranchRoute;
    BusUsers: BusUserProfile[];
  }[];
};

export type RouteJobType = {
  id: string;
  name: string;
};

export type BranchArea = {
  id: string;
  name: string;
  description: string;
};

export type BranchVehicleRoutesView = {
  branchVehicleView: {
    name: string;
    color: string;
    type: string;
    status: string;
    plate_number: string;
    image: string | null;
    daily_capacity: {
      size: 'GENERAL' | 'SMALL' | 'MEDIUM' | 'LARGE';
      capacity: number;
    }[];
  };
  branchVehicleRoutesViews: {
    petsViews: {
      id: string;
      name: string;
      profile_pic: string;
      address: string;
      sortIndex: number;
      routeTags: string[];
    }[];
    branchRouteView: {
      id: string;
      name: string;
      color: string;
      start_time: string;
    };
    busUsersViews: {
      name: string;
      profile_pic: string;
    }[];
    capacity_usage: string;
  }[];
};

export type BranchVehicleRoutesViews = {
  singleDayVehiclesRoutesViews: {
    date: Date;
    views: BranchVehicleRoutesView[];
  }[];
};
