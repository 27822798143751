import { GetBranchPetProfile } from '../../queries';
import client from '../client';
import { DEEP_DYNAMIC_LINKS, DYNAMIC_LINKS_TYPES } from './types';

export async function handlePetsLink(dynamicLink: string) {
  return new Promise(resolve => resolve);
}

export async function handlePetsReportLink(dynamicLink: string) {
  const splitLink = dynamicLink.split('/pets/');
  const splitPet = splitLink[1].split('/');
  const petId = splitPet[0];
  const reportId = splitPet[splitPet.findIndex(part => part.includes('reports')) + 1];
  if (!petId || !reportId) {
    return;
  }
  const { data: { getBranchPetProfile: petProfile = {} } = {} } = await client.query({
    query: GetBranchPetProfile,
    variables: {
      id: petId
    }
  });

  return { petProfile, reportId, linkType: DYNAMIC_LINKS_TYPES.PETS, deepLinkType: DEEP_DYNAMIC_LINKS.REPORTS, replaceVariables: { petName: petProfile?.name } };
}
