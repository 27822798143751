import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Map, useMapsLibrary, useMap, useApiIsLoaded, InfoWindow } from '@vis.gl/react-google-maps';
import { ActivityIndicator } from '../../components/Shared/Spinner';
import { AppUserImage } from '../styled';
import mapStyles from '../../mapStyles';

export type MapStop = {
  id: string;
  lat: number;
  lng: number;
  index?: number;
  image: string;
  onClick: () => void;
};

const getCenter = (stops: { lat: number; lng: number }[]) => {
  if (!window?.google?.maps?.LatLngBounds) {
    return { lat: 0, lng: 0 };
  }
  const bounds = new window.google.maps.LatLngBounds();
  stops.forEach(stop => {
    bounds.extend(stop);
  });
  return bounds.getCenter().toJSON();
};
const getZoom = (stops: { lat: number; lng: number }[]) => {
  if (!window?.google?.maps?.LatLngBounds) {
    return 0;
  }
  const bounds = new window.google.maps.LatLngBounds();
  stops.forEach(stop => {
    bounds.extend(stop);
  });
  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();
  const latDiff = ne.lat() - sw.lat();
  const lngDiff = ne.lng() - sw.lng();
  const maxDiff = Math.max(latDiff, lngDiff);
  const zoom = Math.floor(Math.log2(360 / maxDiff));
  return zoom;
};

export type RoutesMapRef = {
  focusOnStop: (stopId: string) => void;
};

const RoutesMap = forwardRef<RoutesMapRef, { stops: MapStop[]; routeColor: string; routeStartTime?: Date; isShortestPath: boolean }>(({ stops, routeColor, routeStartTime, isShortestPath }, ref) => {
  const map = useMap();
  const routes = useMapsLibrary('routes');
  const [optimizedStops, setOptimizedStops] = useState<MapStop[]>([]);
  const isLoaded = useApiIsLoaded();

  useEffect(() => {
    if (!routes) {
      return;
    }
    const directionsService = new routes.DirectionsService();
    const directionsRenderer = new routes.DirectionsRenderer({
      polylineOptions: {
        strokeColor: routeColor,
        strokeWeight: 6
      }
    });
    directionsRenderer.setMap(map);
    const waypoints = stops
      .filter((_, index) => {
        return index !== 0 && index !== stops.length - 1;
      })
      .map(stop => ({
        location: stop,
        stopover: true
      }));
    directionsService.route(
      {
        origin: stops[0],
        destination: stops[stops.length - 1],
        waypoints,
        optimizeWaypoints: isShortestPath,
        travelMode: routes.TravelMode.DRIVING
      },
      (response, status) => {
        if (status === 'OK') {
          directionsRenderer.setDirections(response);
          const optimizedWaypoints = response?.routes[0].waypoint_order.map((index: number) => stops[index + 1]);
          setOptimizedStops([stops[0], ...(optimizedWaypoints || []), stops[stops.length - 1]]);
        } else {
          console.error('Directions request failed due to ' + status);
        }
      }
    );
    return () => {
      directionsRenderer.setMap(null);
    };
  }, [JSON.stringify(routes), JSON.stringify(stops), routeColor, routeStartTime, isShortestPath]);

  useImperativeHandle(ref, () => ({
    focusOnStop: (stopId: string) => {
      const stop = stops.find(stop => stop.id === stopId);
      if (stop) {
        map?.setCenter(stop);
        map?.setZoom(15);
      }
    }
  }));

  if (!isLoaded) {
    return <ActivityIndicator />;
  }

  return (
    <Map
      style={{ width: '100%', height: '100%' }}
      defaultZoom={getZoom(stops)}
      gestureHandling={'greedy'}
      disableDefaultUI={true}
      defaultCenter={getCenter(stops)}
      colorScheme="LIGHT"
      mapTypeId="roadmap"
      styles={mapStyles}
    >
      {optimizedStops.map((stop, index) => (
        <InfoWindow
          key={`${stop.id}_${index}`}
          headerDisabled
          position={stop}
          style={{
            display: 'flex',
            gap: '8px',
            alignItems: 'center'
          }}
        >
          {stop.index && (
            <span
              style={{
                fontSize: '16px',
                fontWeight: 'bold'
              }}
            >
              {index}
            </span>
          )}
          <AppUserImage src={stop.image} index={0} onClick={stop.onClick} />
        </InfoWindow>
      ))}
    </Map>
  );
});

export default RoutesMap;
