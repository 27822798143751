import React from 'react';
import ModalDialog from '../../../components/Modal/ModalDialog';
import { HoverRecordOptionsContainer, RecordBody, RecordDataContainer } from '../../styled';
import { Branch } from '../../../components/Profile/types';
import ProviderBranchModal from './ProviderBranchModal';
import { statusOptions } from './types';
import { Booking } from '../../Bookings/components/BookingsBody/styled';
import { sentenceCase } from 'sentence-case';
import { BtnContainer } from '../../../components/Shared/ToolBar/styles';
import { GatsbyImage } from 'gatsby-plugin-image';
import useIcons from '../../../hooks/useIcons';

const ProviderBranchRecord = ({ branch }: { branch: Branch }) => {
  const icons = useIcons();
  const handleClick = () => {
    ModalDialog.openModal({
      content: () => <ProviderBranchModal branch={branch} />,
      title: 'Branch Settings & Branding'
    });
  };

  const petTypes = branch?.BranchTargeting?.pet_type.map(petType => sentenceCase(petType)).join(', ');

  return (
    <Booking padding="6px 10px">
      <RecordBody fontWeight={'800'} flex="1" width="200">
        {branch?.name}
      </RecordBody>

      <RecordBody fontWeight={'600'} width="150" flex="unset">
        {petTypes}
      </RecordBody>
      <RecordBody fontWeight={'600'} width="100" flex="unset" flexEnd padding="0">
        {statusOptions.find(st => st.value === branch?.BranchTargeting?.status)?.label}
      </RecordBody>
      <HoverRecordOptionsContainer>
        <BtnContainer onClick={handleClick}>
          <GatsbyImage image={icons?.moreIcon?.childImageSharp?.gatsbyImageData} alt="more" />
        </BtnContainer>
      </HoverRecordOptionsContainer>
    </Booking>
  );
};

export default ProviderBranchRecord;
