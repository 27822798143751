import { ProductOrder } from '../../views/Orders/types';
import { BranchAppUser } from '../../views/Store/BranchBilling/types';

export enum ORDER_INVOICE_STATUS {
  PAYMENT_PENDING = 'PAYMENT_PENDING',
  PAYMENT_SUCCEEDED = 'PAYMENT_SUCCEEDED',
  PAYMENT_REJECTED = 'PAYMENT_REJECTED',
  PAYMENT_CANCELED = 'PAYMENT_CANCELED',
  PAYMENT_REFUNDED = 'PAYMENT_REFUNDED',
  PARTIALLY_REFUNDED = 'PARTIALLY_REFUNDED'
}

type OrderBill = {
  id: string;
  AppUser: BranchAppUser;
};

export type OrderInvoice = {
  id: string;
  status: ORDER_INVOICE_STATUS;
  amount: number;
  createdAt: string;
  invoice_date: string;
  OrderBills: OrderBill[];
  number: string;
  Order: ProductOrder;
};
