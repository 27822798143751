import React from 'react';
import { DrawerLabel } from '../../../components/DrawerBar/styled';
import { DRAWER_IDS } from '../../../components/DrawerBar/types';
import { Divider } from '../../../components/Shared/SideTabs/styles';
import useIcons from '../../../hooks/useIcons';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { setDrawerBar } from '../../../reactive/actions';

import { Booking } from '../../Bookings/components/BookingsBody/styled';
import { OrderDataContainer } from '../../Orders/styled';
import { SubmittedForm } from '../../Store/BranchForms/types';
import { AppUserImage, AppUserImageContainer, AppUserImageNameContainer, AppUserName, RecordBody } from '../../styled';

const PetsFormRecord = ({ submittedForm }: { submittedForm: SubmittedForm }) => {
  const icons = useIcons();

  const handleClick = () => {
    setDrawerBar({
      drawerId: DRAWER_IDS.SUBMITTED_FORM_DRAWER,
      recordData: submittedForm?.id
    });
  };

  const { mobile } = useMediaQuery({ mobile: true });
  const appUserName = submittedForm?.AppUser?.name || 'No Name';
  const orderNumber = submittedForm?.Order?.number;
  const formName = submittedForm?.Form?.name || 'Completed Form';
  // const time = toReadableDate(submittedForm?.createdAt, { isLocale: true });
  const answers = submittedForm?.Answers;
  const selectedPetRecordsIds = [...new Set(answers?.map(answer => answer?.PetRecordId))].filter(id => !!id);
  const selectedPetRecords = selectedPetRecordsIds?.map(id => answers?.find(answer => answer?.PetRecordId === id)?.PetRecord);
  const petsNames = selectedPetRecords?.map(petRecord => petRecord?.Pet?.name).join(', ') || '-';

  const timeToDisplay = () => {
    const day = new Date(submittedForm?.createdAt).getDate();
    const month = new Date(submittedForm?.createdAt).getMonth() + 1;
    const year = new Date(submittedForm?.createdAt).getFullYear();
    if (day < 10 && month < 10) {
      return `0${day}/0${month}/${year}`;
    } else if (day < 10) {
      return `0${day}/${month}/${year}`;
    } else if (month < 10) {
      return `${day}/0${month}/${year}`;
    } else {
      return `${day}/${month}/${year}`;
    }
  };

  const recordData = (
    <>
      {mobile && (
        <RecordBody>
          <AppUserName noMargin>{appUserName}</AppUserName>
        </RecordBody>
      )}

      <RecordBody flex="1" width="200" maxWidth={200} fontWeight="700">
        {formName}
      </RecordBody>
      {!mobile && (
        <RecordBody width="200" flex="1" maxWidth={200} fontWeight="700">
          {orderNumber}
        </RecordBody>
      )}
      {/* <RecordBody width='200'>{petsNames}</RecordBody> */}
      <DrawerLabel size={14} fontWeight={700}>
        {timeToDisplay()}
      </DrawerLabel>
    </>
  );

  return (
    <>
      <Booking onClick={handleClick} gap={20}>
        <AppUserImageNameContainer>
          <AppUserImageContainer>
            <AppUserImage src={submittedForm?.AppUser?.profile_pic || icons.user.childImageSharp.gatsbyImageData.images.fallback.src} index={0} />
          </AppUserImageContainer>
          {!mobile && (
            <RecordBody width="120" fontWeight="800" flex="0 0 120">
              {appUserName}
            </RecordBody>
          )}
        </AppUserImageNameContainer>

        {mobile ? <OrderDataContainer>{recordData}</OrderDataContainer> : recordData}
      </Booking>
      <Divider />
    </>
  );
};

export default PetsFormRecord;
