import React, { useMemo, useState } from 'react';
import {
  BillingStatsContainer,
  BillingStatsTitle,
  BranchBillingBody,
  ColumnHeader,
  FilterItem,
  FilterItemContainer,
  FilterItemCount,
  FilterItemText,
  SelectedBillingFiltersContainer,
  SelectedBillingFiltersWrapper,
  Table,
  TablesWrapper,
  VerticalDivider
} from './styled';
import { useQuery } from '@apollo/client';
import { GetProviderBranchesCounts } from '../../../queries';
import { Branch } from '../../../components/Profile/types';
import { toDateInput } from '../../../utils/dates';
import { BRANCH_BILLING_FILTERS, BRANCH_BILLING_FILTERS_FILTERABLE, defaultCounts, ProviderCountsOutput } from './types';
import { CenteredLoader } from '../../../components/Shared/Spinner';
import { Divider } from '../../Pets/Health/styled';
import BranchBillingStatsFilterItems from './BranchBillingStatsFilterItems';
import { ViewContainer } from '../../../components/Shared/Shared';
import Colors from '../../../Colors';
import { getCountForSelectedFilter, getCurrentBillingFilters } from './utils';
import { navigate } from 'gatsby';
import useURLSearchParams from '../../../hooks/useURLSearchParams';

const BranchBillingProviderStatsSection = ({
  title,
  providerCounts,
  branches,
  filters,
  secondary,
  currentPeriod
}: {
  title?: string;
  providerCounts: Branch['branchCounts'];
  branches: ProviderCountsOutput['getProviderBranchesCounts']['branches'];
  filters: BRANCH_BILLING_FILTERS[];
  secondary?: boolean;
  currentPeriod: { start: string; end: string };
}) => {
  const { currentBookingFilter, currentInvoiceFilter, currentSubscriptionFilter, currentUnpaidFilter } = getCurrentBillingFilters(currentPeriod);
  const selectedFilterState = useURLSearchParams<BRANCH_BILLING_FILTERS>('filter');
  const [selectedFilter] = selectedFilterState;

  const sortedBranches = useMemo(() => {
    if (!branches) {
      return [];
    }
    return [...(branches || [])].sort((a, b) => {
      const selectedFilterCountBranchA = getCountForSelectedFilter({ counts: a.counts!, selectedFilter });
      const selectedFilterCountBranchB = getCountForSelectedFilter({ counts: b.counts!, selectedFilter });

      return selectedFilterCountBranchB - selectedFilterCountBranchA;
    });
  }, [branches, selectedFilter]);

  const billingSectionsTitles = {
    period: 'Branches',
    date: `${branches.length}`
  };

  const setSelectedBranch = (branchId: string, filter: BRANCH_BILLING_FILTERS) => {
    const isFilterable = BRANCH_BILLING_FILTERS_FILTERABLE[filter] || false;
    if (!isFilterable) {
      return;
    }
    const filterValue =
      filter === BRANCH_BILLING_FILTERS.MAIN_BOOKINGS
        ? currentBookingFilter[0]
        : filter === BRANCH_BILLING_FILTERS.MAIN_INVOICES
        ? currentInvoiceFilter[0]
        : filter === BRANCH_BILLING_FILTERS.MAIN_SUBSCRIPTIONS
        ? currentSubscriptionFilter[0]
        : currentUnpaidFilter[0];
    navigate(`/app/operations/billing/${branchId}?mainFilter=${filter}&filter=${filterValue}`, { replace: true });
  };

  return (
    <>
      {title && <BillingStatsTitle>{title}</BillingStatsTitle>}
      <Table>
        <BillingStatsContainer noMargin>
          <SelectedBillingFiltersContainer isHeader>
            <SelectedBillingFiltersWrapper>
              {!secondary && (
                <FilterItemContainer secondary={secondary}>
                  <FilterItem>
                    <FilterItemText>{billingSectionsTitles.period}</FilterItemText>
                    <FilterItemCount>{billingSectionsTitles.date}</FilterItemCount>
                  </FilterItem>
                </FilterItemContainer>
              )}
              <BranchBillingStatsFilterItems
                counts={providerCounts!}
                filters={filters}
                selectedFilterState={selectedFilterState}
                secondary
                onClickItem={() => {}}
                filterable={false}
                options={{
                  renderActiveBar: false,
                  renderVerticalDivider: false,
                  hideSecondaryTotals: true
                }}
              />
            </SelectedBillingFiltersWrapper>
          </SelectedBillingFiltersContainer>
        </BillingStatsContainer>
        {sortedBranches.map(({ Branch, counts }, index) => {
          const isLastBranch = index === branches.length - 1;
          return (
            <BillingStatsContainer noMargin={!isLastBranch} key={Branch.id}>
              <SelectedBillingFiltersContainer>
                <SelectedBillingFiltersWrapper small noTopRadius noBottomRadius={!isLastBranch}>
                  {!secondary && (
                    <FilterItemContainer secondary={secondary}>
                      <FilterItem>
                        <FilterItemText inactiveColor={Colors.black}>{Branch.name}</FilterItemText>
                      </FilterItem>
                    </FilterItemContainer>
                  )}
                  <BranchBillingStatsFilterItems
                    counts={counts!}
                    filters={filters}
                    selectedFilterState={selectedFilterState}
                    options={{ renderActiveBar: false, renderVerticalDivider: false, renderTitles: false, small: true, hideSecondaryTotals: true }}
                    onClickItem={filter => setSelectedBranch(Branch.id, filter)}
                    secondary
                  />
                </SelectedBillingFiltersWrapper>
              </SelectedBillingFiltersContainer>
            </BillingStatsContainer>
          );
        })}
      </Table>
    </>
  );
};

const BranchBillingProviderStats = ({ currentPeriod, headerActions }: { currentPeriod: { start: string; end: string }; path: string; headerActions?: React.ReactNode; noTitle?: boolean }) => {
  const { data: { getProviderBranchesCounts: { providerCounts = defaultCounts, branches = [] } = {} } = {}, loading } = useQuery<ProviderCountsOutput>(GetProviderBranchesCounts, {
    variables: {
      timestamp_from: currentPeriod.start,
      timestamp_to: currentPeriod.end
    },
    fetchPolicy: 'cache-and-network'
  });

  const mainFilters = [BRANCH_BILLING_FILTERS.MAIN_BOOKINGS, BRANCH_BILLING_FILTERS.MAIN_SUBSCRIPTIONS, BRANCH_BILLING_FILTERS.MAIN_ORDERS, BRANCH_BILLING_FILTERS.MAIN_ORDERS_TOTAL];

  const secondFilters = [BRANCH_BILLING_FILTERS.MAIN_INVOICES];

  const thirdFilters = [BRANCH_BILLING_FILTERS.MAIN_UNBILLED_SUBSCRIPTION_INVOICES, BRANCH_BILLING_FILTERS.MAIN_UNBILLED_BOOKINGS];

  return (
    <>
      {loading && <CenteredLoader size={50} />}
      {!loading && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
          }}
        >
          {headerActions}
          <BranchBillingBody>
            <TablesWrapper>
              <BranchBillingProviderStatsSection branches={branches} providerCounts={providerCounts} filters={mainFilters} currentPeriod={currentPeriod} />
              <div style={{ width: 200 }}>
                <BranchBillingProviderStatsSection branches={branches} providerCounts={providerCounts} filters={secondFilters} secondary currentPeriod={currentPeriod} />
              </div>
            </TablesWrapper>

            <BranchBillingProviderStatsSection
              branches={branches}
              providerCounts={providerCounts}
              filters={thirdFilters}
              secondary
              currentPeriod={currentPeriod}
              title={'Pending and Failed Payment'}
            />
          </BranchBillingBody>
        </div>
      )}
    </>
  );
};

export default BranchBillingProviderStats;
