type ID = 'GENERAL' | 'SPECIAL';
const debounce = () => {
  let timer: { [k: string]: NodeJS.Timeout } = {};

  return (functionTobeCalled: Function, interval = 1000, id: ID = 'GENERAL') => {
    clearTimeout(timer[id]);
    timer[id] = setTimeout(() => {
      functionTobeCalled();
    }, interval);

    return;
  };
};

export default debounce();
