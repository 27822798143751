export type ProviderBranchModalFormType = {
  name: string;
  email: string;
  contact_number: string;
  address: {
    country: string;
    city: string;
    street: string;
    post_code: string;
  };
  // longitude: string;
  // latitude: string;
  colors: {
    primary: string;
    secondary: string;
  };
  opening_hours: {
    out_of_office: boolean;
  };
  payment: {
    billing_cycle: Partial<{
      frequency: string;
      date_of_month: number;
      day_of_week: number;
    }>;
    vat_number: string;
    vat_percentage: number;
    disable_automatic_billing: boolean;
    invoice_payment_disabled: boolean;
    invoice_footer: string;
  };
  photos: {
    logo: string;
  };
  branch_targeting: {
    visibility: string;
    status: string;
    pet_type: string[];
  };
  delivery_rules: {
    description: string;
    price: number;
    rule: {
      type: string;
      value: number;
    };
  }[];
};

export const billingCycleOptions = [
  { value: 'DAILY', label: 'Daily' },
  { value: 'WEEKLY', label: 'Weekly' },
  // { value: 'BIWEEKLY', label: 'Biweekly' },
  { value: 'MONTHLY', label: 'Monthly' }
  // { value: 'YEARLY', label: 'Yearly' }
];

// export const visibilityOptions = [
//   { value: 'VISIBLE', label: 'Visible' },
//   { value: 'INVISIBLE', label: 'Invisible' },
//   { value: 'PARTIAL', label: 'Partial' }
// ];

export const statusOptions = [
  { value: 'ACTIVE', label: 'Active' },
  { value: 'SOON', label: 'Coming soon' },
  { value: 'INACTIVE', label: 'Be right back' }
];

export const deliveryRulesOptions = [
  { value: 'none', label: 'None' },
  { value: 'min_total', label: 'Min Total' },
  { value: 'min_weight', label: 'Min Weight' }
];
