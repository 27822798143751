import React from 'react';
import { ExtensionPetCaseLabel } from '../../../../components/Pets/styled';
import MedicationModal from './MedicationModal';
import { getUniqueSuppRecords } from './helpers';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import { SuppRecord } from '../../Health/HealthPetRecords';
import { PetAdministeredRecordContainer } from './styled';
import Colors from '../../../../Colors';
import OptionDropdown from '../../../../components/Shared/Menus/OptionDropdown/OptionDropdown';
import { OPTION_DROPDOWN_MENU_BUTTON_TYPES, OPTION_DROPDOWN_TYPES } from '../../../../components/Shared/Menus/OptionDropdown/types';
import ModalDialog from '../../../../components/Modal/ModalDialog';
import usePetRecordOptions from '../../../../hooks/usePetRecordOptions';
import { MedCond, Pet, SuppRecord as SuppRecordType, Adherence } from '../../../../components/Pets/types';
import { BusUserProfile } from '../../../../components/Profile/types';
import PrescribedMedicationsPrintModal from './PrescribedMedicationsPrintModal';

const PrescribedMedications = ({
  medCond,
  pet,
  hideButton = false,
  busUserProfile,
  suppRecords,
  adherences
}: {
  medCond: MedCond;
  pet: Pet;
  hideButton?: boolean;
  busUserProfile: BusUserProfile;
  suppRecords: SuppRecordType[];
  adherences: Adherence[];
}) => {
  const uniqueSupps = getUniqueSuppRecords({ suppRecords, adherences });
  const { mobile } = useMediaQuery({ mobile: true });

  const sortedSuppRecords = uniqueSupps
    ?.sort((a, b) => {
      const aDate = a?.Adherences?.length ? new Date(a?.Adherences?.[0]?.timestamp) : new Date(a?.createdAt);
      const bDate = b?.Adherences?.length ? new Date(b?.Adherences?.[0]?.timestamp) : new Date(b?.createdAt);
      return aDate.getTime() - bDate.getTime();
    })
    .filter(supp => supp?.type === 'treatment');

  const allSuppRecordProducts = sortedSuppRecords?.map(supp => supp?.Product);

  const allBranchSuppRecordProductsIds = allSuppRecordProducts?.filter(product => product?.Branch?.id === busUserProfile?.Branch?.id)?.map(product => product?.id);

  const uniqueSuppRecordProductsIds = [...new Set(allBranchSuppRecordProductsIds)];

  const { items } = usePetRecordOptions({
    pets: [pet],
    loadTags: false,
    defaultProductsIds: uniqueSuppRecordProductsIds
  });

  const createdByBranch = medCond?.Branch?.id === busUserProfile?.Branch?.id;

  return (
    <>
      <ExtensionPetCaseLabel fontSize={16} fontWeight={'800'} primary justify="space-between">
        Administered Treatments
        {!mobile && !hideButton && (
          <OptionDropdown
            options={[
              {
                id: 'options',
                optionType: OPTION_DROPDOWN_TYPES.BUTTONS,
                items: [
                  {
                    name: 'New Treatment',
                    value: 'addTreatment',
                    onClick: () => ModalDialog.openModal({ content: () => <MedicationModal medCond={medCond} pet={pet} />, title: 'Add Medication' }),
                    disabled: false
                  },
                  ...items.filter(item => item.value === 'newOrder').map(item => ({ ...item, disabled: false })),
                  {
                    name: 'Print',
                    value: 'print',
                    disabled: !sortedSuppRecords?.length,
                    onClick: () => ModalDialog.openModal({ content: () => <PrescribedMedicationsPrintModal sortedSuppRecords={sortedSuppRecords} pet={pet} />, title: 'Print' })
                  }
                ].map(item => ({ ...item, disabled: !!item?.disabled || !createdByBranch }))
              }
            ]}
            menuButtonType={OPTION_DROPDOWN_MENU_BUTTON_TYPES.PLUS}
            noApplyButton
            containerRelative
          />
        )}
      </ExtensionPetCaseLabel>
      <PetAdministeredRecordContainer>
        {!sortedSuppRecords?.length && (
          <ExtensionPetCaseLabel fontSize={14} fontWeight={'800'} normalCursor color={Colors.grey}>
            -No Treatments-
          </ExtensionPetCaseLabel>
        )}
        {sortedSuppRecords.map(suppRecord => (
          <SuppRecord pet={pet} suppRecord={suppRecord} key={suppRecord?.id} />
        ))}
      </PetAdministeredRecordContainer>
    </>
  );
};

export default PrescribedMedications;
