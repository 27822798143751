import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useCallback, useEffect, useRef } from 'react';
import AppUserTagsModal from '../components/Chat/AppUserTagsModal';
import { Room } from '../components/Chat/ChatRecord';
import { checkAddAppUserTagCapacity } from '../components/Chat/utils';
import ModalDialog from '../components/Modal/ModalDialog';
import { OptionDropDownItem } from '../components/Shared/Menus/OptionDropdown/OptionDropdown';
import { AddBranchAppUserItemTag, GetBranchAppUserTags, GetBranchChatRooms, UpdateChatRoomMessageReadStatus } from '../queries';
import client from '../utils/client';
import { RequireAtLeastOne } from '../utils/helpers';
import { isChatRoomSeen } from '../views/Chats/utils';
import { BranchAppUser } from '../views/Store/BranchBilling/types';
import { BranchAppUserTag } from '../views/Store/BranchTags/types';
import Common from '../views/Store/Common';
import { BusUserProfile } from '../components/Profile/types';
import BranchChatRoomModal from '../components/Chat/BranchChatRoomModal';

type useChatRoomOptionsProps = RequireAtLeastOne<
  {
    chatRoom: Room;
    appUsers: BranchAppUser[];
    quickTags?: BranchAppUserTag[];
    loadTags?: boolean;
    afterTag?: (newAppUsers: BranchAppUser[]) => void;
    profile: BusUserProfile;
    removeAppUserOptions?: boolean;
  },
  'quickTags' | 'loadTags'
>;

export default function useChatRoomOptions({ quickTags, loadTags = false, afterTag, appUsers, chatRoom, profile, removeAppUserOptions }: useChatRoomOptionsProps) {
  const uniqueAppUserIds = [...new Set(appUsers?.map(appUser => appUser?.id))].filter(Boolean);
  const allAppUserTags = appUsers?.flatMap(appUser => appUser?.BranchAppUserTags || []) || [];
  const allAppUsersUniqueTags = [...new Set(allAppUserTags.map(item => item?.id))].map(id => allAppUserTags?.find(item => item?.id === id)!);
  const existingTagsIds = allAppUsersUniqueTags?.map(tag => tag?.id);

  const [addTag, { data: addedTag, loading: loadingAddAppUserTag }] = useMutation(AddBranchAppUserItemTag);
  const [refetchTagsById, { data: refetchedTags, loading: loadingRefetchTags }] = useLazyQuery<{ getBranchAppUserTags: BranchAppUserTag[] }>(GetBranchAppUserTags, {
    fetchPolicy: 'cache-and-network'
  });
  const [getTags, { data: { getBranchAppUserTags: tags = [] } = {} }] = useLazyQuery<{ getBranchAppUserTags: BranchAppUserTag[] }>(GetBranchAppUserTags, {
    fetchPolicy: 'cache-and-network',
    variables: { offset: 0, limit: 1000, quick_action: true }
  });

  const [updateMessageReadStatus, { loading: loadingUpdateStatus }] = useMutation(UpdateChatRoomMessageReadStatus);

  useEffect(() => {
    if (loadTags && !quickTags?.length && !removeAppUserOptions) {
      getTags();
    }
  }, [loadTags, getTags, quickTags?.length, removeAppUserOptions]);

  const currentTagId = useRef<string>('');

  const handleQuickTagClick = useCallback(
    (tag: BranchAppUserTag) => {
      checkAddAppUserTagCapacity({
        handleAddTag: () => {
          addTag({ variables: { AppUserId: uniqueAppUserIds, BranchAppUserTagId: [tag.id] } });
          currentTagId.current = tag.id;
        },
        appUsers,
        tags: [tag]
      });
    },
    [addTag, uniqueAppUserIds]
  );

  useEffect(() => {
    if (addedTag) {
      refetchTagsById({ variables: { id: [currentTagId.current] } });
    }
  }, [addedTag, refetchTagsById]);

  const updateChatRooms = Common.get<(rooms: Room[]) => void>(`Chats.GetChatRooms.updateChatRooms`);

  const onTag = async () => {
    const { data: { getBranchChatRooms: newChatRooms = [] } = {} } = await client.query<{ getBranchChatRooms: Room[] }>({
      query: GetBranchChatRooms,
      variables: { chatRoom_appUserId: uniqueAppUserIds, requisite_queries: ['chatRoom_appUserId'], alternative_queries: [], offset: 0, limit: 1000 },
      fetchPolicy: 'network-only'
    });

    updateChatRooms?.(newChatRooms);
    afterTag?.(newChatRooms?.[0]?.AppUsers || []);
  };

  useEffect(() => {
    if (!refetchedTags) {
      return;
    }

    onTag();
  }, [refetchedTags]);

  const isRoomSeen = isChatRoomSeen(chatRoom, profile);

  const updateRoomMessageReadStatus = (chatRoom: Record<string, any>) => {
    updateMessageReadStatus({
      variables: {
        chatRoomId: chatRoom.id,
        status: isRoomSeen ? 'UNREAD' : 'READ'
      }
    });
  };

  const items = [
    ...(chatRoom?.last_message
      ? [
          {
            name: isRoomSeen ? 'Mark as Unread' : 'Mark as Read',
            value: 'updateRoomMessageReadStatus',
            onClick: () => updateRoomMessageReadStatus(chatRoom)
          }
        ]
      : []),
    ...(uniqueAppUserIds.length && !removeAppUserOptions && appUsers?.length === 1
      ? [
          ...(quickTags || tags || [])
            ?.filter(({ id }) => !existingTagsIds.includes(id))
            .map(tag => ({
              name: tag.name,
              value: tag.id,
              onClick: () => handleQuickTagClick(tag)
            })),
          {
            name: uniqueAppUserIds.length > 1 ? 'Tag all Chats' : existingTagsIds?.length ? 'Edit Chat Tags' : 'Add Chat Tag',
            value: 'addTag',
            onClick: () =>
              ModalDialog.openModal({
                content: () => <AppUserTagsModal appUsers={appUsers || []} afterTag={onTag} />,
                title: uniqueAppUserIds.length > 1 ? 'Tag all Chats' : `${appUsers?.[0]?.name}'s Tags`,
                isMini: true
              })
          }
        ]
      : []),
    {
      name: 'Members',
      value: 'editChat',
      onClick: () => ModalDialog.openModal({ content: () => <BranchChatRoomModal isBusRoom={chatRoom?.is_bus_room} chatRoom={chatRoom} />, title: 'Members' })
    }
  ].filter(Boolean) as OptionDropDownItem[];

  const loading = loadingAddAppUserTag || loadingRefetchTags || loadingUpdateStatus;
  return { items, loading };
}
