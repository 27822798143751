import React, { FC, forwardRef, useImperativeHandle, useRef } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { FormError, FormInput, InputContainer, RemoveRowContainer, FormLabel, WideInputGroup } from '../../../components/Shared/Forms/Forms';
import { RemoveAddedImage } from '../styled';
import useIcons from '../../../hooks/useIcons';
import { CustomizationFormOptionsRef, CustomizationRules, CustomizationsFormProps, CustomizationsFormRef, TransformedCustomization } from './types';
import CustomizationFormOptions from './CustomizationFormOptions';
import { CenteredCustomizationInputContainer, CustomizationTypeContainer, CustomizationsFormContainer, CustomizationsFormWrapper } from './styled';
import { AddNewBtn, AddNewBtnIcon } from '../Products/styled';
import { Divider } from '../../../components/DrawerBar/styled';
import { FlexRow } from '../../../components/Shared/Shared';

const CustomizationsForm = forwardRef<CustomizationsFormRef, CustomizationsFormProps>(
  ({ formOptions, customization, index, handleRemoveRow, watchedCustomizations, busUsers, hideRules, defaultValue, onExpandOption }, ref) => {
    const { control, errors } = formOptions;
    const icons = useIcons();
    const inputError = errors?.customizations?.find((_item, itemIndex) => itemIndex === index)?.name;
    const optionsFieldArray = useFieldArray<TransformedCustomization['options'][0]>({
      control,
      name: `customizations[${index}].options`
    });

    const handleAddOptionRow = () => {
      const options = watchedCustomizations?.[index]?.options;
      if (options?.length && !options?.[options?.length - 1]?.title) {
        return;
      }
      optionsFieldArray.append({
        title: '',
        price: 0,
        meta: {
          type: 'none',
          value: ''
        },
        rules: [{ type: CustomizationRules.NONE, value: '' }]
      });
    };

    const handleRemoveOptionRow = (index: number) => {
      optionsFieldArray.remove(index);
    };

    const watchedCustomization = watchedCustomizations[index];

    const customizationsOptionsRef = useRef<(CustomizationFormOptionsRef | null)[]>([]);

    useImperativeHandle(ref, () => ({
      collapseAllOptions: () => {
        customizationsOptionsRef.current.forEach(optionRef => optionRef?.collapse());
      }
    }));

    return (
      <CustomizationsFormContainer>
        <CenteredCustomizationInputContainer>
          <FormLabel>Variation title</FormLabel>

          <CustomizationTypeContainer>
            <FlexRow gap={2} flex1>
              <Controller
                as={<FormInput error={!!errors?.customizations?.[index]?.sort_index} width={40} />}
                control={control}
                name={`customizations[${index}].sort_index`}
                defaultValue={customization.sort_index || 1}
                rules={{
                  required: true
                }}
              />
              <Controller
                as={<FormInput type={'text'} error={!!inputError} flexOne />}
                control={control}
                name={`customizations[${index}].name`}
                defaultValue={customization.name || ''}
                rules={{
                  required: true,
                  validate: (value: string) => {
                    return value.length > 0 && !value.includes('.');
                  }
                }}
              />
            </FlexRow>
            <RemoveRowContainer noMargin height="auto">
              <RemoveAddedImage src={icons.delete.childImageSharp.gatsbyImageData.images.fallback.src} onClick={() => handleRemoveRow(index)} noMargin />
            </RemoveRowContainer>
          </CustomizationTypeContainer>

          {!!inputError && <FormError>{inputError?.message || 'A valid name is required'}</FormError>}
        </CenteredCustomizationInputContainer>

        <>
          {optionsFieldArray.fields.map((item, optionIndex) => (
            <CustomizationsFormWrapper key={item.id}>
              <CustomizationFormOptions
                ref={el => (customizationsOptionsRef.current[optionIndex] = el)}
                onExpandOption={
                  onExpandOption
                    ? () => {
                        customizationsOptionsRef.current.forEach((ref, i) => {
                          if (i !== optionIndex) {
                            ref?.collapse();
                          }
                        });
                        onExpandOption();
                      }
                    : undefined
                }
                formOptions={formOptions}
                index={optionIndex}
                option={item}
                handleRemoveRow={handleRemoveOptionRow}
                watchedCustomization={watchedCustomization}
                customizationIndex={index}
                busUsers={busUsers}
                hideRules={hideRules}
                defaultValue={defaultValue?.options[optionIndex]}
              />
              <Divider />
            </CustomizationsFormWrapper>
          ))}
        </>
        <div style={{ padding: 10 }}>
          <AddNewBtn onClick={handleAddOptionRow} noSpaceArround>
            <AddNewBtnIcon src={icons.plusBlack.childImageSharp.gatsbyImageData.images.fallback.src} />
            Add an option
          </AddNewBtn>
        </div>
      </CustomizationsFormContainer>
    );
  }
);

export default CustomizationsForm;
