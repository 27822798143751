import { useQuery } from '@apollo/client';
import { useLocation } from '@reach/router';
import { Link } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import React, { useMemo } from 'react';
import useBranchUnreadItemsCount from '../../hooks/useBranchUnreadItemsCount';
import useIcons from '../../hooks/useIcons';
import useMediaQuery from '../../hooks/useMediaQuery';
import { useWindowSize } from '../../hooks/useWindowResize';
import { GetBusUserProfile } from '../../queries';
import { unsetDrawerBars } from '../../reactive/actions';
import BusinessUserContent from './BusinessUserContent';
import { BusinessUserContainer, Icon, LogoContainer, LogoImage, MenuItem, MenuItemNotification, MenuItemWrapper, Name, SideBarMenu, SideTabContainer } from './styled';

enum SIDEBAR_ITEMS {
  BOOKINGS = 'BOOKINGS',
  ORDERS = 'ORDERS',
  MEMBERSHIPS = 'MEMBERSHIPS',
  MESSAGES = 'MESSAGES',
  PETS = 'PETS',
  STORE = 'STORE',
  CALENDAR = 'CALENDAR',
  ROUTES = 'ROUTES'
}

export default function SideBar() {
  const location = useLocation();
  const icons = useIcons();
  const windowSize = useWindowSize();
  const { mobile, tablet } = useMediaQuery({ mobile: true, tablet: true });

  const { unreadChatRoomsCount, unreadProductOrdersCount, unreadServiceOrdersCount, unreadSubscriptionOrdersCount } = useBranchUnreadItemsCount();

  const items = [
    { id: SIDEBAR_ITEMS.BOOKINGS, name: 'Bookings', icon: 'bookingsSVG', to: 'bookings', defaultTab: 'confirmed', disabled: false },
    { id: SIDEBAR_ITEMS.ORDERS, name: 'Orders', icon: 'bookingsSVG', to: 'orders', defaultTab: 'new', disabled: false },
    { id: SIDEBAR_ITEMS.MEMBERSHIPS, name: 'Memberships', icon: 'membershipSVG', to: 'memberships', defaultTab: 'active', disabled: false },
    { id: SIDEBAR_ITEMS.MESSAGES, name: 'Messages', icon: 'chatSVG', to: 'chat', defaultTab: 'all', disabled: false },
    { id: SIDEBAR_ITEMS.PETS, name: 'Pets', icon: 'petsSVG', to: 'pets', defaultTab: 'active', disabled: false },
    { id: SIDEBAR_ITEMS.ROUTES, name: 'Routes', icon: 'routesSVG', to: 'routes', defaultTab: 'schedule', disabled: true },
    { id: SIDEBAR_ITEMS.STORE, name: 'Operations', icon: 'servicesSVG', to: 'operations', defaultTab: 'services', disabled: false },
    { id: SIDEBAR_ITEMS.CALENDAR, name: 'Calendar', icon: 'calendarSVG', to: 'calendar', defaultTab: '', disabled: false }
  ];

  const itemsData = {
    [SIDEBAR_ITEMS.BOOKINGS]: { notifications: unreadServiceOrdersCount },
    [SIDEBAR_ITEMS.ORDERS]: { notifications: unreadProductOrdersCount },
    [SIDEBAR_ITEMS.MEMBERSHIPS]: { notifications: unreadSubscriptionOrdersCount },
    [SIDEBAR_ITEMS.MESSAGES]: { notifications: unreadChatRoomsCount },
    [SIDEBAR_ITEMS.PETS]: { notifications: 0 },
    [SIDEBAR_ITEMS.STORE]: { notifications: 0 },
    [SIDEBAR_ITEMS.CALENDAR]: { notifications: 0 },
    [SIDEBAR_ITEMS.ROUTES]: { notifications: 0 }
  };

  const sideBarItems = [
    SIDEBAR_ITEMS.CALENDAR,
    SIDEBAR_ITEMS.BOOKINGS,
    SIDEBAR_ITEMS.PETS,
    SIDEBAR_ITEMS.ROUTES,
    SIDEBAR_ITEMS.ORDERS,
    SIDEBAR_ITEMS.MEMBERSHIPS,
    SIDEBAR_ITEMS.MESSAGES,
    SIDEBAR_ITEMS.STORE
  ];

  const mobileSideBarItems = [SIDEBAR_ITEMS.CALENDAR, SIDEBAR_ITEMS.BOOKINGS, SIDEBAR_ITEMS.PETS, SIDEBAR_ITEMS.MESSAGES];

  const handleSelect = () => {
    unsetDrawerBars();
  };

  const itemsToRender = useMemo(() => {
    let itemsToFiler = sideBarItems;
    if (tablet) {
      itemsToFiler = sideBarItems;
    }
    if (mobile) {
      itemsToFiler = mobileSideBarItems;
    }
    return items.filter(item => itemsToFiler.includes(item.id)).sort((a, b) => itemsToFiler.indexOf(a.id) - itemsToFiler.indexOf(b.id));
  }, [mobile, tablet]);
  const currentSelected = itemsToRender.slice(1).find(item => location.pathname.slice(1).split('/')[1] === item.to) || itemsToRender[0];

  const { data: { getBusUserProfile: profile = {} } = {}, loading } = useQuery(GetBusUserProfile, {
    fetchPolicy: 'cache-and-network'
  });

  return (
    <SideTabContainer windowHeight={windowSize.height}>
      {!mobile && (
        <LogoContainer>
          {/* <LogoImage alt={'Logo'} src={icons.logo.childImageSharp.gatsbyImageData} /> */}
          <LogoImage alt="logo" src={profile?.Branch?.photos?.logo} />
        </LogoContainer>
      )}
      <SideBarMenu>
        <MenuItemWrapper>
          {itemsToRender.map(item => {
            const isSelected = currentSelected!.to === item.to;
            const icon = icons[item.icon]?.publicURL;
            const { notifications } = itemsData?.[item.id];
            return (
              <Link
                key={item.id}
                to={item.disabled ? undefined : `/app/${item.to}/${item.defaultTab}`}
                style={{ textDecoration: 'none', ...(item.disabled ? { cursor: 'unset' } : {}) }}
                aria-disabled={item.disabled}
              >
                <MenuItem onClick={() => handleSelect()} selected={isSelected} disabled={item.disabled}>
                  {Number(notifications) > 0 && <MenuItemNotification>{notifications}</MenuItemNotification>}
                  {/* <Icon image={icon!} objectFit="cover" objectPosition="50% 50%" alt="Category Image" selected={isSelected} /> */}
                  <Icon src={icon} alt="icon" selected={isSelected} />
                  <Name>{item.name}</Name>
                </MenuItem>
              </Link>
            );
          })}
        </MenuItemWrapper>
      </SideBarMenu>
      {!mobile && (
        <BusinessUserContainer>
          {loading && <span>loading...</span>}
          {!loading && <BusinessUserContent profile={profile} />}
        </BusinessUserContainer>
      )}
    </SideTabContainer>
  );
}
