import { useMutation } from '@apollo/client';
import React, { FC, useEffect } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import ModalDialog, { ModalLayout } from '../../../components/Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../../../components/Modal/styled';
import { Pet } from '../../../components/Pets/types';
import { FormButtonsContainer, FormError, FormInput, FormLabel, FormSelect, FormSubmitButton, InputsWrapper, WideInputGroup } from '../../../components/Shared/Forms/Forms';
import BreedsList from '../../../components/Shared/Lists/BreedsList';
import PetColorsList from '../../../components/Shared/Lists/PetColorsList';
import { Container } from '../../../components/Shared/Shared';
import { EditBranchPet, EditPetRecord } from '../../../queries';
import { getKeys } from '../../../utils/helpers';
import { PetRecordSelect } from './styled';

type HealthPetRecordModalFormProps = {
  onSubmit: () => void;
  pet: Pet;
  loading: boolean;
  error: boolean;
  formOptions: ReturnType<typeof useForm>;
};

const HealthPetRecordModalForm: FC<HealthPetRecordModalFormProps> = ({ loading, error, pet, formOptions, onSubmit }) => {
  const { control, errors, setValue } = formOptions;

  const allergiesOptions = getKeys(pet?.PetRecord?.allergies);
  const currentAllergies = allergiesOptions.filter(allergy => !!pet?.PetRecord?.allergies[allergy]);
  const chronicConditionsOptions = getKeys(pet?.PetRecord?.chronic_conditions);
  const currentChronicConditions = chronicConditionsOptions.filter(cond => !!pet?.PetRecord?.chronic_conditions[cond]);
  const disabilitiesOptions = getKeys(pet?.PetRecord?.disabilities);
  const currentDisabilities = disabilitiesOptions.filter(disability => !!pet?.PetRecord?.disabilities[disability]);

  const petType = useWatch({
    control,
    name: 'type',
    defaultValue: pet?.type
  });

  const petGender = useWatch({
    control,
    name: 'gender',
    defaultValue: pet?.gender
  });

  useEffect(() => {
    if (petType && petType !== pet?.type) {
      setValue('breedId', '');
      setValue('petColorId', '');
    }
  }, [petType]);

  return (
    <ModalLayout
      compact
      buttons={[
        <FormSubmitButton error={error} loading={loading} onClick={onSubmit}>
          Update
        </FormSubmitButton>
      ]}
    >
      <WideInputGroup>
        <FormLabel>Name</FormLabel>
        <Controller as={<FormInput error={errors.name} type={'text'} flexBasis={78} />} control={control} name={'name'} defaultValue={pet?.name || ''} rules={{ required: false }} />
        {errors.name && <FormError>{errors.name.message || 'Name is required'}</FormError>}
      </WideInputGroup>
      <WideInputGroup>
        <FormLabel>Gender</FormLabel>
        <Controller
          render={({ onChange }) => (
            <FormSelect height={48} fontSize={16} name={'gender'} onChange={e => onChange(e.target.value)} defaultValue={pet?.gender}>
              <option value={'MALE'}>Male</option>
              <option value={'FEMALE'}>Female</option>
            </FormSelect>
          )}
          control={control}
          name={'gender'}
          rules={{ required: true }}
          defaultValue={pet?.gender}
        />
        {errors.gender && <FormError>{errors.gender.message || 'Gender is required'}</FormError>}
      </WideInputGroup>
      <WideInputGroup>
        <FormLabel>Birthdate</FormLabel>
        <Controller
          as={<FormInput error={errors.birthdate} type={'date'} flexBasis={78} />}
          control={control}
          name={'birthdate'}
          defaultValue={pet?.birthdate?.split('T')[0] || ''}
          rules={{ required: false }}
        />
        {errors.birthdate && <FormError>{errors.birthdate.message || 'Birthdate is required'}</FormError>}
      </WideInputGroup>
      <WideInputGroup>
        <FormLabel>Pet Type</FormLabel>
        <Controller
          render={({ onChange }) => (
            <FormSelect height={48} fontSize={16} name={'type'} onChange={e => onChange(e.target.value)} defaultValue={pet?.type}>
              <option value={'DOG'}>Dog</option>
              <option value={'CAT'}>Cat</option>
              <option value={'RABBIT'}>Rabbit</option>
              <option value={'HORSE'}>Horse</option>
              <option value={'DUCK'}>Duck</option>
              <option value={'GUINEA_PIG'}>Guinea Pig</option>
            </FormSelect>
          )}
          control={control}
          name={'type'}
          rules={{ required: true }}
          defaultValue={pet?.type}
        />
        {errors.type && <FormError>{errors.type.message || 'Type is required'}</FormError>}
      </WideInputGroup>
      <WideInputGroup>
        <FormLabel>Breed</FormLabel>
        <BreedsList defaultValues={pet?.Breed?.id ? [pet?.Breed?.id] : []} formOptions={formOptions} petType={[petType]} />
      </WideInputGroup>
      <WideInputGroup>
        <FormLabel>Color</FormLabel>
        <PetColorsList defaultColorId={pet?.PetColor?.id} formOptions={formOptions} petType={petType} />
      </WideInputGroup>
      {/* <WideInputGroup>
        <FormLabel>{petGender === 'FEMALE' ? 'Spayed' : 'Neutered'}</FormLabel>
        <Controller
          render={({ onChange }) => (
            <FormSelect height={48} fontSize={16} name={'neutred'} onChange={e => onChange(e.target.value)} defaultValue={!!pet.PetRecord?.neutred + ''}>
              <option value={'true'}>YES</option>
              <option value={'false'}>NO</option>
            </FormSelect>
          )}
          control={control}
          name={'neutred'}
          rules={{ required: true }}
          defaultValue={pet.PetRecord?.neutred + ''}
        />
        {errors.neutred && <FormError>{errors.neutred.message || 'Neutered is required'}</FormError>}
      </WideInputGroup> */}

      {/* <WideInputGroup>
        <FormLabel>Allergies</FormLabel>
        <Controller
          as={
            <PetRecordSelect
              options={allergiesOptions.map(allergy => ({ label: allergy, value: allergy })) || null}
              defaultValue={currentAllergies.map(allergy => ({ label: allergy, value: allergy })) || null}
            />
          }
          control={control}
          name={'allergies'}
          defaultValue={currentAllergies.map(allergy => ({ label: allergy, value: allergy })) || null}
        />
        {errors.allergies && <FormError>{errors.allergies.message || 'Allergies is required'}</FormError>}
      </WideInputGroup> */}
      {/* 
      <WideInputGroup>
        <FormLabel>Chronic Conditions</FormLabel>
        <Controller
          as={
            <PetRecordSelect
              options={chronicConditionsOptions.map(cond => ({ label: cond, value: cond })) || null}
              defaultValue={currentChronicConditions.map(cond => ({ label: cond, value: cond })) || null}
            />
          }
          control={control}
          name={'chronic_conditions'}
          defaultValue={currentChronicConditions.map(cond => ({ label: cond, value: cond })) || null}
        />
        {errors.chronic_conditions && <FormError>{errors.chronic_conditions.message || 'Chronic conditions is required'}</FormError>}
      </WideInputGroup> */}

      {/* <WideInputGroup>
        <FormLabel>Disabilities</FormLabel>
        <Controller
          as={
            <PetRecordSelect
              options={disabilitiesOptions.map(disability => ({ label: disability, value: disability })) || null}
              defaultValue={currentDisabilities.map(disability => ({ label: disability, value: disability })) || null}
            />
          }
          control={control}
          name={'disabilities'}
          defaultValue={currentDisabilities.map(disability => ({ label: disability, value: disability })) || null}
        />
        {errors.disabilities && <FormError>{errors.disabilities.message || 'Disabilities is required'}</FormError>}
      </WideInputGroup> */}

      {/* <InputsWrapper noWrap>
            <WideInputGroup>
              <FormLabel>Neck</FormLabel>
              <Controller
                as={<FormInput error={errors.neck} type={'number'}  flexBasis={78} />}
                control={control}
                name={'neck'}
                defaultValue={currentNeck || 0}
                rules={{
                  min: 0,
                  max: 999
                }}
              />
              {errors.neck && <FormError>{errors.neck.message || 'Neck is required'}</FormError>}
            </WideInputGroup>
          </InputsWrapper>
          <InputsWrapper noWrap>
            <WideInputGroup>
              <FormLabel>Back</FormLabel>
              <Controller
                as={<FormInput error={errors.back} type={'number'}  flexBasis={78} />}
                control={control}
                name={'back'}
                defaultValue={currentBack || 0}
                rules={{
                  min: 0,
                  max: 999
                }}
              />
              {errors.back && <FormError>{errors.back.message || 'Back is required'}</FormError>}
            </WideInputGroup>
          </InputsWrapper>
          <InputsWrapper noWrap>
            <WideInputGroup>
              <FormLabel>Chest</FormLabel>
              <Controller
                as={<FormInput error={errors.chest} type={'number'}  flexBasis={78} />}
                control={control}
                name={'chest'}
                defaultValue={currentChest || 0}
                rules={{
                  min: 0,
                  max: 999
                }}
              />
              {errors.chest && <FormError>{errors.chest.message || 'Chest is required'}</FormError>}
            </WideInputGroup>
          </InputsWrapper> */}

      <WideInputGroup>
        <FormLabel>Microchip Number</FormLabel>
        <Controller
          as={<FormInput error={errors.microchip_number} type={'text'} flexBasis={78} />}
          control={control}
          name={'microchip_number'}
          defaultValue={pet?.PetRecord?.microchip_number || ''}
          rules={{ required: false }}
        />
        {errors.microchip_number && <FormError>{errors.microchip_number.message || 'microchip number is required'}</FormError>}
      </WideInputGroup>

      <WideInputGroup>
        <FormLabel>Microchip Provider</FormLabel>
        <Controller
          as={<FormInput error={errors.microchip_provider} type={'text'} flexBasis={78} />}
          control={control}
          name={'microchip_provider'}
          defaultValue={pet?.PetRecord?.microchip_provider || ''}
          rules={{ required: false }}
        />
        {errors.microchip_provider && <FormError>{errors.microchip_provider.message || 'microchip provider is required'}</FormError>}
      </WideInputGroup>
    </ModalLayout>
  );
};

const HealthPetRecordModal = ({ pet }: { pet: Pet }) => {
  const formOptions = useForm();
  const { handleSubmit } = formOptions;

  const [handleEditPetRecord, { data: editedPetRecord, loading: loadingEditPetRecord, error: errorEditPetRecord }] = useMutation(EditPetRecord);

  const [handleEditPet, { data: editedPet, loading: loadingEditPet, error: errorEditPet }] = useMutation(EditBranchPet);

  const onSubmit = handleSubmit(form => {
    handleEditPet({
      variables: {
        id: pet?.id,
        name: form.name,
        BreedId: form.breedId,
        type: form.type,
        gender: form.gender,
        birthdate: form.birthdate,
        PetColorId: form.petColorId || null
      }
    });

    handleEditPetRecord({
      variables: {
        id: pet?.PetRecord?.id,
        measurements: {
          ...pet?.PetRecord?.measurements,
          necks: !form.neck ? pet?.PetRecord?.measurements?.necks : [...(pet?.PetRecord?.measurements?.necks || []), { neck: Number(form.neck), date: new Date().toISOString() }],
          backs: !form.back ? pet?.PetRecord?.measurements?.backs : [...(pet?.PetRecord?.measurements?.backs || []), { back: Number(form.back), date: new Date().toISOString() }],
          chests: !form.chest ? pet?.PetRecord?.measurements?.chests : [...(pet?.PetRecord?.measurements?.chests || []), { chest: Number(form.chest), date: new Date().toISOString() }]
        },
        neutred: form.neutred === 'true',
        microchip_number: form.microchip_number,
        microchip_provider: form.microchip_provider
      }
    });
  });

  useEffect(() => {
    if (editedPetRecord?.editPetRecord?.id && editedPet?.editBranchPet?.id) {
      ModalDialog?.closeModal();
    }
  }, [editedPetRecord, editedPet]);

  const loading = loadingEditPetRecord || loadingEditPet;
  const error = !!errorEditPetRecord?.message || !!errorEditPet?.message;

  return <HealthPetRecordModalForm onSubmit={onSubmit} loading={loading} error={error} pet={pet} formOptions={formOptions} />;
};

export default HealthPetRecordModal;
