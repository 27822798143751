import React, { FC, useEffect } from 'react';
import { BOOKING_STATUS_TYPES, BOOKING_TYPE, BookingOrder } from '../../types';
import { Controller, useForm } from 'react-hook-form';
import { BranchRoom } from '../../../Store/BranchRooms/types';
import { useMutation, useQuery } from '@apollo/client';
import { AddBranchRoom, AssignBranchRoomToAppointment, GetBranchAppointments, GetBranchRooms } from '../../../../queries';
import ModalDialog, { ModalLayout } from '../../../../components/Modal/ModalDialog';
import { Booking } from '../ServiceHeader/styled';
import { ModalBody, ModalFooter } from '../../../../components/Modal/styled';
import { Container } from '@nivo/core';
import ReactSelect from 'react-select';
import { BusUserProfile } from '../../../../components/Profile/types';
import { FormLabel, SectionSpan, InputsWrapper, WideInputGroup, selectTheme, FormButtonsContainer, FormSubmitButton } from '../../../../components/Shared/Forms/Forms';
import { toReadableDate } from '../../../../utils/dates';
import Select from '../../../../components/Shared/Forms/Select';

interface BookingAssignModalFormProps {
  appointments: BookingOrder;
  branchRooms: BranchRoom[];
  formOptions: Partial<ReturnType<typeof useForm>>;
  loadingAdd: boolean;
  existingAssigned: string;
  onSubmit: () => void;
  assignTo: string;
}

const BookingAssignToBranchRoomModalForm: FC<BookingAssignModalFormProps> = ({ appointments, branchRooms, formOptions, loadingAdd, existingAssigned, onSubmit, assignTo }) => {
  const { control } = formOptions;
  const petsIds = [...new Set(appointments.map(appointment => appointment.PetRecord.Pet.id))];
  const pets = petsIds.map(petId => appointments.find(appointment => appointment.PetRecord.Pet.id === petId)?.PetRecord.Pet.name);
  const appUsersIds = [...new Set(appointments.map(appointment => appointment.OrderItem.Order.AppUser.id))];
  const appUsersLength = appUsersIds.length;
  const appUsers = appUsersIds.map(appUserId => appointments.find(appointment => appointment.OrderItem.Order.AppUser.id === appUserId)?.OrderItem.Order.AppUser.name);

  const appUsersNames = appUsersLength > 1 ? `${appUsersLength} Clients` : appUsers.join(' & ');

  const petsNames = appUsersLength > 1 ? `${petsIds.length} Pets` : pets.join(' & ');

  return (
    <ModalLayout
      compact
      buttons={[
        <FormSubmitButton loading={loadingAdd} onClick={onSubmit}>
          Assign
        </FormSubmitButton>
      ]}
    >
      <FormLabel>Service</FormLabel>
      <SectionSpan>{appointments?.[0]?.OrderItem.item.name}</SectionSpan>
      <FormLabel>Booking Slot</FormLabel>
      <SectionSpan>{toReadableDate(appointments?.[0]?.timestamp)}</SectionSpan>
      <FormLabel>Booked by</FormLabel>
      <SectionSpan>{appUsersNames}</SectionSpan>
      <FormLabel>Booked for</FormLabel>
      <SectionSpan>{petsNames}</SectionSpan>

      <WideInputGroup>
        <FormLabel>Branch Room</FormLabel>
        <Controller
          render={({ onChange, value }) => (
            <Select
              theme={selectTheme}
              onChange={onChange}
              isMulti
              value={value}
              options={branchRooms}
              getOptionValue={branchRoom => branchRoom?.id}
              getOptionLabel={branchRoom => branchRoom?.name}
              name={'branchRoomId'}
            />
          )}
          control={control}
          name={'branchRoomId'}
          defaultValue={branchRooms?.filter(({ id }) => (assignTo || existingAssigned || []).includes(id))}
        />
      </WideInputGroup>
    </ModalLayout>
  );
};

export const BookingAssignToBranchRoomModal = ({ appointments, appointmentsIds, assignTo }: { appointments: BookingOrder; appointmentsIds: string[]; assignTo: string }) => {
  const { control, handleSubmit, errors } = useForm();

  const { data: { getBranchRooms: branchRooms } = {}, refetch: refetchRooms } = useQuery<{ getBranchRooms: BranchRoom }>(GetBranchRooms, {
    fetchPolicy: 'cache-and-network'
  });

  const { data: { getBranchAppointments: branchAppointments = [] } = {}, loading: loadingAppointments } = useQuery<{ getBranchAppointments: BookingOrder }>(GetBranchAppointments, {
    variables: {
      appointment_id: appointmentsIds,
      requisite_queries: ['appointment_id'],
      alternative_queries: [],
      offset: 0,
      limit: 1000,
      status: [BOOKING_STATUS_TYPES.CONFIRMED, BOOKING_STATUS_TYPES.CANCELED, BOOKING_STATUS_TYPES.REQUESTED],
      booking_type: [BOOKING_TYPE.SLOT, BOOKING_TYPE.MULTI_SLOT, BOOKING_TYPE.MULTI_DAY]
    },
    fetchPolicy: 'cache-and-network',
    skip: !!appointments?.length
  });

  const [addBranchRoom, { data: addedBranchRoom, loading: loadingAdd }] = useMutation<{ assignBranchRoomToAppointment: BookingOrder }>(AssignBranchRoomToAppointment);

  const allAppointments = appointments || branchAppointments || [];
  const bookingBranchRooms = allAppointments?.[0]?.BranchRooms;
  const existingAssigned = bookingBranchRooms?.[0]?.id;

  const onSubmit = handleSubmit(({ branchRoomId }) => {
    addBranchRoom({
      variables: {
        id: allAppointments?.map?.(appointment => appointment.id),
        BranchRoomId: branchRoomId?.map(item => item.id) || null
      }
    });
  });

  useEffect(() => {
    if (addedBranchRoom?.assignBranchRoomToAppointment?.length) {
      ModalDialog.closeModal();
    }
  }, [addedBranchRoom]);

  return (
    <BookingAssignToBranchRoomModalForm
      appointments={allAppointments}
      branchRooms={branchRooms}
      formOptions={{ control, errors }}
      loadingAdd={loadingAdd}
      existingAssigned={existingAssigned}
      onSubmit={onSubmit}
      assignTo={assignTo}
    />
  );
};

export default BookingAssignToBranchRoomModal;
