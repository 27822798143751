import { concat, useQuery, useReactiveVar } from '@apollo/client';
import React from 'react';
import useURLSearchParams from '../../../../hooks/useURLSearchParams';
import { GetAllServicesToShowOnDailyAppointments } from '../../../../queries';
import { vars } from '../../../../reactive';
import { getNextDay, getStartOfDate, getStartOfToday } from '../../../../utils/dates';
import { ORDER_STATUS } from '../../../Orders/types';
import { Product } from '../../../Store/types';
import { BOOKINGS_FILTER_TYPES, useBookingsFilters } from '../../BookingsFilters';
import { BOOKING_STATUS_TYPES, BOOKING_TAB, BOOKING_TABS_TYPES, BOOKING_TABS_VALUES_TYPES, BOOKING_TYPE } from '../../types';
import BookingsBody from '../BookingsBody/BookingsBody';

const BookingsContent = () => {
  const today = getStartOfToday();
  const isMultiStaffView = useReactiveVar(vars.isMultiStaffView);
  const [urlDate] = useURLSearchParams('date');

  const selectedDate = urlDate ? new Date(`${urlDate} 00:00:00`) : new Date();

  const selectedUTC = new Date(Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 0, 0, 0));

  const [selectedServices] = useURLSearchParams<string[]>('services');
  const isAllServices = !selectedServices?.length;

  const tabs: BOOKING_TAB[] = [
    {
      name: BOOKING_TABS_TYPES.CONFIRMED,
      value: BOOKING_TABS_VALUES_TYPES.CONFIRMED,
      status: [BOOKING_STATUS_TYPES.CONFIRMED],
      booking_type: [BOOKING_TYPE.SLOT, BOOKING_TYPE.MULTI_SLOT, BOOKING_TYPE.MULTI_DAY],
      timestamp_from: selectedUTC,
      timestamp_to: getNextDay(selectedUTC),
      order_status: null,
      recordOptions: {
        dispalyName: true,
        displayBreed: true,
        displayServiceName: isMultiStaffView || isAllServices,
        displayBookingTime: true,
        dispalyTrailingItems: true,
        dispalyOptionalDropDown: !isMultiStaffView,
        displayOrderStatus: false,
        displayStaffName: true
      }
    },
    {
      name: BOOKING_TABS_TYPES.NEW,
      value: BOOKING_TABS_VALUES_TYPES.NEW,
      status: [BOOKING_STATUS_TYPES.REQUESTED],
      booking_type: [BOOKING_TYPE.SLOT, BOOKING_TYPE.MULTI_SLOT, BOOKING_TYPE.MULTI_DAY],
      timestamp_from: null,
      timestamp_to: null,
      order_status: null,
      recordOptions: {
        dispalyName: true,
        displayBreed: true,
        displayServiceName: true,
        displayBookingTime: true,
        dispalyTrailingItems: true,
        dispalyOptionalDropDown: true,
        displayOrderStatus: false,
        displayStaffName: true
      }
    },

    // {
    //   name: BOOKING_TABS_TYPES.MULTI_DAY,
    //   value: BOOKING_TABS_VALUES_TYPES.MULTI_DAY,
    //   status: [BOOKING_STATUS_TYPES.CONFIRMED],
    //   booking_type: [BOOKING_TYPE.MULTI_DAY],
    //   timestamp_from: today.toISOString(),
    //   timestamp_to: null,
    //   order_status: null,
    //   recordOptions: {
    //     dispalyName: true,
    //     displayBreed: true,
    //     displayServiceName: true,
    //     displayBookingTime: true,
    //     dispalyTrailingItems: true,
    //     dispalyOptionalDropDown: true,
    //     displayOrderStatus: false,
    //     displayStaffName: true
    //   }
    // },

    {
      name: BOOKING_TABS_TYPES.UPCOMING,
      value: BOOKING_TABS_VALUES_TYPES.UPCOMING,
      status: [BOOKING_STATUS_TYPES.CONFIRMED],
      booking_type: [BOOKING_TYPE.SLOT, BOOKING_TYPE.MULTI_SLOT, BOOKING_TYPE.MULTI_DAY],
      timestamp_from: today.toISOString(),
      timestamp_to: null,
      order_status: null,
      recordOptions: {
        dispalyName: true,
        displayBreed: true,
        displayServiceName: true,
        displayBookingTime: true,
        dispalyTrailingItems: true,
        dispalyOptionalDropDown: true,
        displayOrderStatus: false,
        displayStaffName: true
      }
    },

    {
      name: BOOKING_TABS_TYPES.PAYMENT,
      value: BOOKING_TABS_VALUES_TYPES.PAYMENT,
      status: [BOOKING_STATUS_TYPES.CONFIRMED],
      booking_type: [BOOKING_TYPE.SLOT, BOOKING_TYPE.MULTI_SLOT, BOOKING_TYPE.MULTI_DAY],
      order_status: [ORDER_STATUS.PAYMENT_PENDING, ORDER_STATUS.PAYMENT_REJECTED],
      recordOptions: {
        dispalyName: true,
        displayBreed: true,
        displayServiceName: true,
        displayBookingTime: true,
        dispalyTrailingItems: false,
        dispalyOptionalDropDown: true,
        displayOrderStatus: true,
        displayStaffName: false
      }
    }
    // {
    //   name: BOOKING_TABS_TYPES.CANCELED,
    //   value: BOOKING_TABS_VALUES_TYPES.CANCELED,
    //   status: [BOOKING_STATUS_TYPES.CANCELED],
    //   booking_type: [BOOKING_TYPE.SLOT, BOOKING_TYPE.MULTI_SLOT, BOOKING_TYPE.MULTI_DAY],
    //   timestamp_from: today.toISOString(),
    //   timestamp_to: null,
    //   order_status: null,
    //   recordOptions: {
    //     dispalyName: true,
    //     displayBreed: true,
    //     displayServiceName: true,
    //     displayBookingTime: true,
    //     dispalyTrailingItems: true,
    //     dispalyOptionalDropDown: true,
    //     displayOrderStatus: false,
    //     displayStaffName: true
    //   }
    // }
  ];
  const tabsValues = tabs.map(tab => tab.value);
  const tabValue = tabsValues?.find(tab => tab === location.pathname.split('/')[3]) || BOOKING_TABS_TYPES.CONFIRMED;
  const selectedTab = tabs.find(tab => tab.value === tabValue) || tabs[0];
  const isConfirmed = selectedTab?.value === BOOKING_TABS_VALUES_TYPES.CONFIRMED;

  const hasServices = selectedServices?.length;

  const filtersControl = useBookingsFilters({
    filtersToHide: hasServices && isConfirmed ? [BOOKINGS_FILTER_TYPES.BOOKING_PRODUCT_NAME] : []
  });

  const { data: { getProducts: services = [] } = {} } = useQuery<{ getProducts: Product[] }>(GetAllServicesToShowOnDailyAppointments, {
    fetchPolicy: 'cache-and-network'
  });

  return (
    <>
      <BookingsBody tabs={tabs} filtersControl={filtersControl} services={services} />
    </>
  );
};

export default BookingsContent;
