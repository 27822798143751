import React, { useEffect } from 'react';
import { GetBranchAppUsers } from '../../../queries';
import Common from '../Common';
import InfiniteList from '../../../components/Shared/InfiniteList/InfiniteList';
import { DRAWER_IDS } from '../../../components/DrawerBar/types';
import useSetDrawerOnNavigation from '../../../hooks/useSetDrawerOnNavigation';
import usePaginatedQuery from '../../../hooks/usePaginatedQuery';
import useNavigateDrawer from '../../../hooks/useNavigateDrawer';
import BranchBillingRecord from './BranchBillingRecord';
import { BRANCH_BILLING_FILTERS, BRANCH_BILLING_FILTERS_ITEMS, BranchAppUser, CurrentPeriod } from './types';

const BranchBillingAppUsers = ({ period, variables }: { period: CurrentPeriod; variables: Record<string, any> }) => {
  const [[hasMoreItems, setHasMoreItems], queryResult] = usePaginatedQuery<BranchAppUser[]>({
    query: GetBranchAppUsers,
    otherVariables: {
      timestamp_from: period.start,
      timestamp_to: period.end,
      appUserCounts_timestamp_from: period.start,
      appUserCounts_timestamp_to: period.end,
      ...variables
    }
  });

  const { data: { getBranchAppUsers: branchAppUsers = [] } = {}, loading, fetchMore, refetch } = queryResult;

  useEffect(() => {
    Common.set(`BranchAppUsers.GetBranchAppUsers.refetch`, () => {
      refetch?.().then(() => setHasMoreItems(true));
    });
  }, []);

  return (
    <InfiniteList
      list={branchAppUsers}
      itemRenderer={(appUser: BranchAppUser) => <BranchBillingRecord key={appUser.id} branchAppUser={appUser} />}
      hasMoreItems={hasMoreItems}
      loading={loading}
      fetchMore={fetchMore}
      offset={branchAppUsers?.length}
      setHasMoreItems={setHasMoreItems}
    />
  );
};

export default BranchBillingAppUsers;
