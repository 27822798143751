import { navigate } from 'gatsby';
import { useLayoutEffect } from 'react';
import { useLocation } from '@reach/router';

export default function useNavigateOnTab({ selected, next = () => null }: { selected: string; next?: () => void }) {
  const location = useLocation();

  useLayoutEffect(() => {
    if (location.pathname.split('/').length >= 3) {
      const drawerLocation = location.pathname.split('/')[4];
      const baseLocation = `${location.pathname.split('/').slice(0, 3).join('/')}/${selected}`;
      navigate(drawerLocation ? `${baseLocation}/${drawerLocation}` : baseLocation, { replace: true });
    }
    next();
  }, [selected]);
}
