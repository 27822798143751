import React, { useEffect } from 'react';
import { DRAWER_IDS } from '../../../components/DrawerBar/types';
import InfiniteList from '../../../components/Shared/InfiniteList/InfiniteList';
import useNavigateDrawer from '../../../hooks/useNavigateDrawer';
import usePaginatedQuery from '../../../hooks/usePaginatedQuery';
import useSetDrawerOnNavigation from '../../../hooks/useSetDrawerOnNavigation';
import { GetBranchTags } from '../../../queries';
import Common from '../Common';
import BranchTagsRecord from './BranchTagsRecord';
import { BranchAppointmentTag, BranchPetRecordTag, BranchTag } from './types';

const BranchTagsAll = () => {
  const [[hasMoreItems, setHasMoreItems], queryResult] = usePaginatedQuery<{
    branchPetRecordTags: BranchPetRecordTag[];
    branchAppointmentTags: BranchAppointmentTag[];
    branchAppUserTags: BranchTag[];
  }>({
    query: GetBranchTags
  });

  const { data: { getBranchTags: { branchPetRecordTags = [], branchAppointmentTags = [], branchAppUserTags = [] } = {} } = {}, loading, fetchMore, refetch } = queryResult;

  const branchTags = [...branchPetRecordTags, ...branchAppointmentTags, ...branchAppUserTags];

  useSetDrawerOnNavigation({
    itemList: branchTags,
    drawerId: DRAWER_IDS.BRANCH_TAG_DRAWER,
    itemsToFilterFrom: branchTags
  });

  const navigateDrawer = useNavigateDrawer({
    drawerData: {
      drawerId: DRAWER_IDS.BRANCH_TAG_DRAWER,
      itemsToFilterFrom: branchTags
    }
  });

  useEffect(() => {
    Common.set(`BranchTags.GetBranchTags.refetch`, () => {
      refetch?.().then(() => setHasMoreItems(true));
    });
  }, []);

  return (
    <InfiniteList
      paddingL={20}
      paddingR={20}
      list={branchTags}
      itemRenderer={(tag: BranchTag) => <BranchTagsRecord key={tag?.id} branchTag={tag} navigateDrawer={navigateDrawer} />}
      hasMoreItems={hasMoreItems}
      loading={loading}
      fetchMore={fetchMore as any}
      offset={branchTags?.length}
      setHasMoreItems={setHasMoreItems}
    />
  );
};

export default BranchTagsAll;
