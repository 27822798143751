import React from 'react';
import { ProductTitle } from '../ProductDrawer/styled';
import { DrawerContainer, DrawerSection } from '../../DrawerBar/styled';
import { BranchForm } from '../../../views/Store/BranchForms/types';

const BranchFormDrawer = ({ data: branchMessage }: { data: BranchForm }) => {
  return (
    <DrawerContainer scrollable>
      <DrawerSection paddingT={55}>
        <ProductTitle>
          Name: <strong>{branchMessage?.name}</strong>
        </ProductTitle>
        {branchMessage?.Product?.id && (
          <ProductTitle>
            Product: <strong>{branchMessage?.Product?.name || '-'}</strong>
          </ProductTitle>
        )}
      </DrawerSection>
    </DrawerContainer>
  );
};

export default BranchFormDrawer;
