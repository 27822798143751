/**
 * @description a common clojure state to be shared across multiple components
 * @returns set and get functions to control common state
 */
const Common = (): {
  get<T>(key: string): T;
  set<T>(key: string, value: T): T;
  clear(key: string): void;
} => {
  const state: Record<string, unknown> = {};
  return {
    get: <T>(key: string): T => state[key] as T,
    set: <T>(key: string, value: T): T => {
      state[key] = value;
      return value;
    },
    clear: (key: string): void => {
      delete state[key];
    }
  };
};

export default Common();
