import React from 'react';
import ModalDialog from '../../../components/Modal/ModalDialog';
import OrderInvoicesList from '../../../components/OrderInvoices/OrderInvoicesList';
import { OrderInvoice } from '../../../components/OrderInvoices/types';
import usePaginatedQuery from '../../../hooks/usePaginatedQuery';
import { GetBranchOrderInvoices } from '../../../queries';
import OrderInvoicesDownloadModal from './OrderInvoicesDownloadModal';
import { CurrentPeriod } from './types';
import { ToolBarFooter } from '../../../components/Shared/ToolBar/styles';
import { ActionButton, ActionButtonsContainer } from './styled';

const BranchBillingInvoices = ({ period, variables, ListFooter }: { period: CurrentPeriod; variables: Record<string, any>; ListFooter?: React.ReactNode }) => {
  const paginatedQueryResult = usePaginatedQuery<OrderInvoice[]>({
    query: GetBranchOrderInvoices,
    limit: 50,
    otherVariables: {
      invoice_date_from: period.start,
      invoice_date_to: period.end,
      ...variables
    }
  });

  const onExport = () =>
    ModalDialog.openModal({
      content: () => (
        <OrderInvoicesDownloadModal
          initialValues={{
            invoice_date_from: period.start,
            invoice_date_to: period.end,
            ...variables
          }}
        />
      ),
      title: 'Export Invoices'
    });

  return (
    <>
      <OrderInvoicesList paginatedQueryResult={paginatedQueryResult} />
      <ToolBarFooter>
        <ActionButtonsContainer>
          {ListFooter}
          <ActionButton style={{ whiteSpace: 'nowrap' }} onClick={onExport} disabled={false}>
            Export
          </ActionButton>
        </ActionButtonsContainer>
      </ToolBarFooter>
    </>
  );
};

export default BranchBillingInvoices;
