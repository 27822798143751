import React, { FC, useLayoutEffect } from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import { FormCheckbox, WideInputGroup } from '../../../components/Shared/Forms/Forms';
import { BRANCH_TAGS_TYPES } from './types';

type BranchTagsOptionsProps = {
  formOptions: UseFormMethods<StoreProductModalForm>;
  type: BRANCH_TAGS_TYPES;
  defaultValues?: Partial<StoreProductModalForm['productOptions']>;
  optionsToShow?: BranchTagsOptionsTypes[];
};

export enum BranchTagsOptionsTypes {
  SHOW_ON_APPOINTMENTS = 'visibility_showOnAppointments',
  SHOW_ON_BAR = 'visibility_showOnBar',
  SHOW_ON_TAGGED_ITEM = 'visibility_showOnTaggedItem',
  QUICK_ACTION = 'quick_action',
  RESET_AT_MIDNIGHT = 'reset_at_midnight'
}

export const getSelectedBranchTagsOptions = (values: any[]) => {
  const vals = values || [];
  const selectedOptions = Object.values(BranchTagsOptionsTypes).reduce((acc, option) => {
    acc[option] = vals.includes(option);
    return acc;
  }, {} as Record<BranchTagsOptionsTypes, boolean>);

  return selectedOptions;
};

export const getBranchTagsOptionsDefaultValue = tag => {
  const defaultValue = [
    !!tag?.visibility?.showOnAppointments && BranchTagsOptionsTypes.SHOW_ON_APPOINTMENTS,
    !!tag?.quick_action && BranchTagsOptionsTypes.QUICK_ACTION,
    !!tag?.visibility?.showOnBar && BranchTagsOptionsTypes.SHOW_ON_BAR,
    !!tag?.visibility?.showOnTaggedItem && BranchTagsOptionsTypes.SHOW_ON_TAGGED_ITEM,
    tag?.reset_at !== null && BranchTagsOptionsTypes.RESET_AT_MIDNIGHT
  ].filter(Boolean) as BranchTagsOptionsTypes[];

  return defaultValue;
};

const BranchTagsOptions: FC<BranchTagsOptionsProps> = ({ formOptions, defaultValues, type, optionsToShow }) => {
  const { control, setValue, errors, watch } = formOptions;
  const petTag = type === BRANCH_TAGS_TYPES.PET_RECORD;
  const bookingTag = type === BRANCH_TAGS_TYPES.APPOINTMENT;
  const appUserTag = type === BRANCH_TAGS_TYPES.APP_USER;

  const items = [
    petTag && {
      id: BranchTagsOptionsTypes.SHOW_ON_APPOINTMENTS,
      name: 'Show on all bookings',
      description: 'When checked, this tag will show on all the booking of the tagged pet'
    },

    { id: BranchTagsOptionsTypes.QUICK_ACTION, name: 'List as a quick action item', description: 'When checked, this tag will show as a quick action item within the pet action menu' },

    { id: BranchTagsOptionsTypes.SHOW_ON_BAR, name: 'Show in the tags bar', description: 'When checked, this tag will show on the tags bar within the Pet tab view' },

    { id: BranchTagsOptionsTypes.SHOW_ON_TAGGED_ITEM, name: 'Show the tagged list item', description: 'When checked, this tag will show on the tags bar within the Pet tab view' },
    {
      id: BranchTagsOptionsTypes.RESET_AT_MIDNIGHT,
      name: 'Reset daily',
      description: 'If checked, this tag will reset automatically at the end of the day, and will be removed from all tagged items.'
    }
  ].filter(Boolean) as { id: string; name: string; description: string }[];

  const filteredItems = optionsToShow ? (optionsToShow.map(option => items.find(item => item.id === option)).filter(Boolean) as { id: string; name: string; description: string }[]) : items;

  return (
    <WideInputGroup>
      <Controller
        control={control}
        name={`branchTagsOptions`}
        defaultValue={defaultValues}
        render={({ onChange, value }) => (
          <FormCheckbox error={errors?.branchTagsOptions?.some(error => error)} column itemsArray={filteredItems} onChange={onChange} value={value || []} fontSize={14} />
        )}
      />
    </WideInputGroup>
  );
};

export default BranchTagsOptions;
