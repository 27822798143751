import RRule from 'rrule';
import { SuppRecord } from '../../../../components/Pets/types';
import { MedicationModalFormValues, frequencies, frequencyEndDateItems } from './types';

const constructFrequencyEndDateOptions = (frequencyId: number, startDate?: Date | string | null) => {
  const { length, name, constructor } = frequencyEndDateItems.find(item => item.id === frequencyId)! || {};
  const defaultDate = new Date(startDate || new Date());
  defaultDate.setUTCHours(0, 0, 0, 0);

  return Array.from({ length }, (_, index) => ({
    id: index + 1,
    name: `${index + 1} ${name}`,
    date: constructor(defaultDate, index + 1)
  }));
};

export const getFrequenciesEndDateOptions = (startDate?: Date | string | null) =>
  frequencies.map(({ id }) => ({
    frequencyId: id,
    options: constructFrequencyEndDateOptions(id, startDate)
  }));

const getFrequency = (freq: number) => {
  if (freq === RRule.DAILY) {
    return 3;
  }
  if (freq === RRule.WEEKLY) {
    return 2;
  }
  if (freq === RRule.MONTHLY) {
    return 1;
  }
  if (freq === RRule.YEARLY) {
    return 0;
  }
  return -1;
};
const rruleFromTime = (time: string) => {
  const rule = RRule.fromString(time || '');
  const freq = rule.options.freq;
  const interval = rule.options.interval;
  const frequency = getFrequency(freq);
  const recurrence = interval;
  return { frequency, recurrence };
};
const getRRule = (suppRecord?: SuppRecord) => {
  if (!suppRecord) {
    return { frequency: 3, recurrence: 1 };
  }

  if (suppRecord?.time) {
    return rruleFromTime(suppRecord?.time);
  }

  if (suppRecord?.adherences_allowed) {
    return { frequency: -1, recurrence: 1 };
  }

  return { frequency: -2, recurrence: 1 };
};
export function getSuppRecordDefaultValues(suppRecord?: SuppRecord) {
  const rrule = getRRule(suppRecord);

  const suppRecordStartDate = suppRecord?.start_date?.slice?.(0, 10) || new Date().toISOString().slice(0, 10);
  const frequencyEndDateOptions = constructFrequencyEndDateOptions(rrule?.frequency, suppRecordStartDate);
  const defaultFrequencyDate = new Date(suppRecord?.end_date || new Date()).setUTCHours(0, 0, 0, 0);
  const defaultEndDate = frequencyEndDateOptions?.find(({ date }) => date.getTime() === defaultFrequencyDate)?.id;

  const defaultFormValues: MedicationModalFormValues = {
    product: suppRecord?.Product?.id || '',
    startDate: suppRecordStartDate,
    frequency: String(rrule?.frequency),
    reccurence: String(rrule?.recurrence),
    quantity: suppRecord?.quantity ? String(suppRecord?.quantity) : '1',
    size: String(suppRecord?.Product?.weight) || '',
    count: suppRecord?.count || '1',
    endDateFrequency: suppRecord?.end_date ? String(defaultEndDate) : ''
  };
  return defaultFormValues;
}
