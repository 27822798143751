import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import { FormError, FormInput, FormLabel, FormSelect, InputContainer, RemoveRowContainer, selectTheme } from '../../../components/Shared/Forms/Forms';
import { FlexRow } from '../../../components/Shared/Shared';
import useIcons from '../../../hooks/useIcons';
import { DiscountsFormContainer } from '../Operations/styled';
import { RemoveAddedImage } from '../styled';
import { DiscountRule as DiscountRuleType, DiscountRulesProps } from './types';
import Select from '../../../components/Shared/Forms/Select';

const DiscountRule: FC<DiscountRulesProps> = ({ rule, index, formOptions, discountIndex, handleRemoveRule }) => {
  const { control, errors, watch } = formOptions;
  const watchedRules: DiscountRuleType[] = watch(`discounts[${discountIndex}].rules`);
  const watchedRule = watchedRules[index];
  const icons = useIcons();

  const previousRules = [...watchedRules].slice(0, index);

  return (
    <FlexRow>
      <DiscountsFormContainer>
        <FlexRow gap={12} margin="0 0 20px 0">
          <InputContainer>
            <FormLabel>Rule</FormLabel>
            <Controller
              render={({ onChange, value }) => (
                // <FormSelect error={!!errors?.discounts?.[discountIndex]?.rules?.[index]?.rule?.type} height={38} fontSize={16} onChange={onChange} value={value} width={160}>
                //   <option value="none" disabled>
                //     -- Select Rule --
                //   </option>
                //   <option value="min_pets" disabled={!!previousRules.find(rule => rule.rule.type === 'min_pets')}>
                //     Min Pets
                //   </option>
                //   <option value="min_total" disabled={!!previousRules.find(rule => rule.rule.type === 'min_total')}>
                //     Min Total
                //   </option>
                // </FormSelect>
                <Select
                  theme={selectTheme}
                  options={[
                    {
                      value: 'min_pets',
                      label: 'Min Pets'
                    },
                    {
                      value: 'min_total',
                      label: 'Min Total'
                    }
                    // { value: 'min_orders', label: 'Min Orders' },  // Uncomment if needed
                  ].filter(option => !previousRules.find(rule => rule.rule.type === option.value))}
                  value={value ? { value: value, label: value === 'min_pets' ? 'Min Pets' : value === 'min_total' ? 'Min Total' : '-- Select Rule --' } : { value: 'none', label: '-- Select Rule --' }}
                  onChange={option => {
                    onChange(option?.value);
                  }}
                  styles={{
                    control: provided => ({
                      ...provided,
                      width: 200
                    })
                  }}
                />
              )}
              control={control}
              name={`discounts[${discountIndex}].rules[${index}].rule.type`}
              defaultValue={rule?.rule?.type || 'none'}
              rules={{ required: true, validate: value => value !== 'none' || 'Rule is required' }}
            />
            {!!errors?.discounts?.[discountIndex]?.rules?.[index]?.rule?.type && <FormError>{errors?.discounts?.[discountIndex]?.rules?.[index]?.rule?.type?.message || 'Rule is required'}</FormError>}
          </InputContainer>
          {/* <InputContainer>
              <FormLabel>Level</FormLabel>
              <Controller
                render={({ onChange, value }) => (
                  <FormSelect error={!!errors?.discounts?.[discountIndex]?.rules?.[index]?.level} height={38} fontSize={16} onChange={onChange} value={value || 'none'} width={160}>
                    <option value="main">Main</option>
                    <option value="auto_apply">Auto Apply</option>
                  </FormSelect>
                )}
                control={control}
                name={`discounts[${discountIndex}].rules[${index}].level`}
                defaultValue={rule?.level || 'MAIN'}
                rules={{ required: true }}
              />
              {!!errors?.discounts?.[discountIndex]?.rules?.[index]?.level && <FormError>{errors?.discounts?.[discountIndex]?.rules?.[index]?.level?.message || 'Level is required'}</FormError>}
            </InputContainer> */}
          <InputContainer>
            <FormLabel>Amount</FormLabel>
            <Controller
              as={<FormInput error={!!errors?.discounts?.[discountIndex]?.rules?.[index]?.rule?.amount} height={37} fontSize={14} width={280} />}
              control={control}
              name={`discounts[${discountIndex}].rules[${index}].rule.amount`}
              defaultValue={rule?.rule?.amount || 0}
              rules={{ required: true }}
            />
            {!!errors?.discounts?.[discountIndex]?.rules?.[index]?.rule?.amount && (
              <FormError>{errors?.discounts?.[discountIndex]?.rules?.[index]?.rule?.amount?.message || 'Amount is required'}</FormError>
            )}
          </InputContainer>
          {watchedRule?.rule?.type === 'min_orders' && (
            <InputContainer>
              <FormLabel>Offset</FormLabel>
              <Controller
                as={<FormInput error={!!errors?.discounts?.[discountIndex]?.rules?.[index]?.rule?.offset} height={20} fontSize={16} width={60} />}
                control={control}
                name={`discounts[${discountIndex}].rules[${index}].rule.offset`}
                defaultValue={rule?.rule?.offset || 0}
                rules={{ required: true }}
              />
              {!!errors?.discounts?.[discountIndex]?.rules?.[index]?.rule?.offset && (
                <FormError>{errors?.discounts?.[discountIndex]?.rules?.[index]?.rule?.offset?.message || 'Offset is required'}</FormError>
              )}
            </InputContainer>
          )}
        </FlexRow>
        {/* <InputContainer>
          <FormLabel>Description</FormLabel>
          <Controller
            as={<FormInput error={!!errors?.discounts?.[discountIndex]?.rules?.[index]?.description} height={20} fontSize={16} />}
            control={control}
            name={`discounts[${discountIndex}].rules[${index}].description`}
            defaultValue={rule?.description || ''}
            rules={{ required: true }}
          />
          {!!errors?.discounts?.[discountIndex]?.rules?.[index]?.description && (
            <FormError>{errors?.discounts?.[discountIndex]?.rules?.[index]?.description?.message || 'Description is required'}</FormError>
          )}
        </InputContainer> */}
      </DiscountsFormContainer>
      <RemoveRowContainer onClick={() => handleRemoveRule(index)} noMargin>
        <RemoveAddedImage src={icons.delete.childImageSharp.gatsbyImageData.images.fallback.src} noMargin />
      </RemoveRowContainer>
    </FlexRow>
  );
};

export default DiscountRule;
