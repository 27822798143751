import styled from 'styled-components';

export const LoadingPetData = styled.div`
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  background-color: rgba(216, 216, 216, 0.8);
  z-index: 100;
`;

export const PetContainer = styled.div<{ flexOne?: boolean }>`
  display: flex;
  height: 100%;
  max-height: 100%;
  flex: 1;

  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 768px) {
    flex: ${props => (props.flexOne ? 1 : 'unset')};
    height: 100%;
    /* max-height: 100%; */
    overflow: scroll;
    flex-direction: column;
  }
`;

export const PetDrawerTagsContainer = styled.div<{ padding?: string; backgroundColor?: string }>`
  display: flex;
  align-items: center;
  gap: 4px;
  user-select: none;
  flex-wrap: wrap;
  background-color: ${props => props.backgroundColor || '#f4f4f4'};
  padding: ${props => props.padding || '12px 20px 20px 20px'};
`;

export const PetDrawerTag = styled.div<{ color?: string }>`
  display: flex;
  border-radius: 21px;
  /* border: 1px solid ${props => props.color}; */
  border: 1px solid #000;
  padding: 4px 8px;
  font-size: 10px;
  span {
    /* color: ${props => props.color}; */
    color: #000;
    font-size: 12px;
    font-weight: 800;
  }
  @media (max-width: 768px) {
    padding: 5px 10px 6px 10px;
    font-weight: 600;
    font-size: 14px;
  }
`;
