import { useReactiveVar } from '@apollo/client';
import React, { Fragment } from 'react';
import { BusUserProfile } from '../../../../components/Profile/types';
import InfiniteList, { InfiniteListGroup } from '../../../../components/Shared/InfiniteList/InfiniteList';
import { UsePagintaedQueryResult } from '../../../../hooks/usePaginatedQuery';
import { vars } from '../../../../reactive';
import { BranchAppointmentTag, BranchPetRecordTag, BranchTag } from '../../../Store/BranchTags/types';
import { Product } from '../../../Store/types';

import { BOOKING_TABS_VALUES_TYPES, Booking } from '../../types';
import BookingRecord from '../BookingRecord/BookingRecord';
import { BodyContainer, ServiceContainer } from '../BookingsBody/styled';
import ServiceHeader from '../ServiceHeader/ServiceHeader';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import { FiltersControl } from '../../../../components/Shared/Filters/useFilters';
import { BOOKINGS_FILTER_TYPES, BOOKINGS_MORE_OPTIONS_TYPES } from '../../BookingsFilters';
import { UseBulkSelectOutput } from '../../../../hooks/useBulkSelect';
import { ListCount } from '../../../../components/Shared/Forms/Forms';
import { ProductOrder } from '../../../Orders/types';

export type BookingsListAppointmentsList = {
  count: number;
  Appointments: Booking[];
  Orders: ProductOrder[];
  Product: Product;
  BusUser: BusUserProfile;
}[];

type BookingsListProps = {
  appointmentsList: BookingsListAppointmentsList;
  allQuickTags?: BranchTag[];
  tagCategories?: BranchTag['BranchTagCategories'];
  refetchAppointments: () => void;
  loadingAppointments: boolean;
  selectedTab: { value: string; recordOptions: Record<string, any> };
  itemsToDisplay?: { name: string; id: string }[];
  paginatedQueryResult?: UsePagintaedQueryResult<{
    views: (Booking | ProductOrder)[];
    count: number;
  }>;
  group?: (bookingsList: Booking[]) => InfiniteListGroup;
  filtersControl: FiltersControl<BOOKINGS_FILTER_TYPES, BOOKINGS_MORE_OPTIONS_TYPES>;
  isAllServices: boolean;
  bulkSelect: UseBulkSelectOutput;
  isMultipleServices: boolean;
  petsTags: BranchTag[];
};
const BookingsList = ({
  appointmentsList,
  refetchAppointments,
  loadingAppointments,
  selectedTab,
  itemsToDisplay,
  allQuickTags,
  tagCategories,
  paginatedQueryResult,
  group,
  filtersControl,
  isAllServices,
  bulkSelect,
  isMultipleServices,
  petsTags
}: BookingsListProps) => {
  const activeViewSettings = useReactiveVar(vars.activeViewSettings);
  const isConfirmed = selectedTab?.value === BOOKING_TABS_VALUES_TYPES.CONFIRMED;
  const isMultiStaffView = useReactiveVar(vars.isMultiStaffView) && isConfirmed;

  const isNew = selectedTab?.value === BOOKING_TABS_VALUES_TYPES.NEW;
  const isUpcoming = selectedTab?.value === BOOKING_TABS_VALUES_TYPES.UPCOMING;
  const isCanceled = selectedTab?.value === BOOKING_TABS_VALUES_TYPES.CANCELED;
  const isPayment = selectedTab?.value === BOOKING_TABS_VALUES_TYPES.PAYMENT;

  const options = selectedTab?.recordOptions;
  const currentTab = selectedTab?.value;

  const [[hasMoreItems, setHasMoreItems], { fetchMore, data }] = paginatedQueryResult || [[], {}];
  const quickTags = allQuickTags?.filter(tag => tag?.quick_action) || [];
  const tagsVisibleOnItem = allQuickTags?.filter(tag => tag?.visibility?.showOnTaggedItem).concat(petsTags || []) || [];
  const upcomingAppointments = appointmentsList?.[0]?.Appointments || [];
  const allConfirmedAppointments = appointmentsList?.[0]?.Appointments || [];

  const { mobile } = useMediaQuery({ mobile: true });

  const listToDisplay = (list: Booking[], backgroundColor = '') => {
    const padding = 20;
    const isMultiDayBooking = list?.[0]?.timestamp_until;

    return (
      <>
        {!isMultiStaffView && (isConfirmed ? isAllServices : true) && !isUpcoming && !!data?.getBranchAppointmentsViews?.count && (
          <ListCount>
            {data?.getBranchAppointmentsViews?.count || 0} {data?.getBranchAppointmentsViews?.count === 1 ? 'Booking' : 'Bookings'}
          </ListCount>
        )}
        <InfiniteList
          paddingL={padding}
          paddingR={padding}
          mobilePadding={isUpcoming ? '0 16px' : '12px 16px'}
          hasMoreItems={hasMoreItems || false}
          fetchMore={fetchMore || (() => null)}
          list={list}
          loading={loadingAppointments}
          offset={list?.length}
          setHasMoreItems={setHasMoreItems || (() => null)}
          itemRenderer={appointment => (
            <BookingRecord
              appointments={[appointment]}
              recordOptions={options}
              selectedTab={currentTab}
              isAllServices={isAllServices}
              bulkSelect={bulkSelect}
              recordActiveViewSettings={[activeViewSettings?.record].flat()}
              tagsVisibleOnItem={tagsVisibleOnItem}
              isMultipleServices={isMultipleServices}
              isLast={list?.[list.length - 1]?.id === appointment.id}
              quickTags={quickTags}
            />
          )}
          group={(isUpcoming || (isConfirmed && !isAllServices && !isMultiStaffView && isMultiDayBooking)) && group(list)}
          backgroundColor={backgroundColor}
        />
      </>
      // <InfiniteList
      //   paddingL={padding}
      //   paddingR={padding}
      //   fetchMore={fetchMore || (() => null)}
      //   list={list}
      //   loading={loadingAppointments}
      //   // offset={list?.length}
      //   hasMoreItems={hasMoreItems || (() => null)}
      //   setHasMoreItems={setHasMoreItems || (() => null)}
      //   itemRenderer={appointment => <BookingRecord
      //     key={appointment.id}
      //     booking={[appointment]}
      //     showTotal={false}
      //     quickAppointmentsTags={quickTags}
      //     // bulkSelect={bulkSelect}
      //     tagsVisibleOnItem={tagsVisibleOnItem}
      //     recordActiveViewSettings={activeViewSettings?.record}
      //     selectedTab={currentTab}
      //     recordOptionsToDisplay={options}
      //     refetchAppointments={refetchAppointments}
      //   />}

      // group={group?.(appointmentsList)}
      // />
    );
  };

  const newListToDisplay = (list: ProductOrder[], backgroundColor = '') => {
    const padding = 20;
    return (
      <>
        <ListCount>
          {data?.getBranchServiceOrdersViews?.count || 0} {data?.getBranchServiceOrdersViews?.count === 1 ? 'Booking' : 'Bookings'}
        </ListCount>
        <InfiniteList
          paddingL={padding}
          paddingR={padding}
          hasMoreItems={hasMoreItems || false}
          fetchMore={fetchMore || (() => null)}
          list={list}
          loading={loadingAppointments}
          offset={list?.length}
          setHasMoreItems={setHasMoreItems || (() => null)}
          itemRenderer={(order: ProductOrder) => (
            <BookingRecord
              appointments={order.OrderItems[0]?.Appointments}
              recordOptions={options}
              selectedTab={currentTab}
              isAllServices={isAllServices}
              bulkSelect={bulkSelect}
              recordActiveViewSettings={[activeViewSettings?.record].flat()}
              tagsVisibleOnItem={tagsVisibleOnItem}
              isMultipleServices={isMultipleServices}
              isLast={list?.[list.length - 1]?.id === order.id}
              quickTags={quickTags}
            />
          )}
          backgroundColor={backgroundColor}
        />
      </>
    );
  };

  return (
    <Fragment key={selectedTab?.value}>
      {(isCanceled || isPayment || (isConfirmed && mobile)) && listToDisplay(appointmentsList?.[0]?.Appointments)}

      {isNew && newListToDisplay(appointmentsList?.[0]?.Orders)}

      {isUpcoming && listToDisplay(upcomingAppointments)}
      {isConfirmed && !isMultiStaffView && !mobile && isAllServices && listToDisplay(allConfirmedAppointments)}

      {isConfirmed && !isMultiStaffView && !mobile && !isAllServices && (
        <BodyContainer>
          {itemsToDisplay?.map((product, index) => {
            const dailyAppointments = appointmentsList.find(productAppointments => productAppointments.Product.id === product.id)?.Appointments || [];
            const isEven = index % 2 === 0;
            const backgroundColor = isEven ? 'white' : '#f4f4f4';

            return (
              <>
                <ServiceContainer key={index}>
                  <ServiceHeader product={product} appointments={dailyAppointments} filtersControl={filtersControl} />

                  {listToDisplay(dailyAppointments, backgroundColor)}
                </ServiceContainer>
              </>
            );
          })}
        </BodyContainer>
      )}

      {isConfirmed && isMultiStaffView && (
        <BodyContainer>
          {itemsToDisplay?.map((item, index) => {
            const userAppointments = appointmentsList.find(productAppointments => productAppointments?.BusUser.id === item.id)?.Appointments || [];

            return (
              <>
                <ServiceContainer key={index} minWidth={600}>
                  <ServiceHeader product={item} appointments={userAppointments} filtersControl={filtersControl} />
                  {listToDisplay(userAppointments)}
                </ServiceContainer>
              </>
            );
          })}
        </BodyContainer>
      )}

      {/* {isMultiDay && (
        <BodyContainer>
        <>
          {itemsToDisplay?.map((item, index) => {
            const upcomingAppointments = appointmentsList?.[0]?.Appointments.filter(appointment => new Date() <= new Date(appointment?.timestamp)) || [];

            const activeAppointments = appointmentsList?.[0]?.Appointments.filter(appointment => new Date() > new Date(appointment?.timestamp)) || [];


            const appointemts = item.id === 'UPCOMING' ? upcomingAppointments : activeAppointments;
            return (
              <ServiceContainer key={index}>
                <ServiceHeader product={item} appointments={appointemts} />
                <TabsHeaderContainer padding="12px 0">
                  <BookingTagsQuickFilter filtersControl={filtersControl} />
                </TabsHeaderContainer>
                {listToDisplay(appointemts)}
              </ServiceContainer>
            );
          })}
        </>
        // </BodyContainer>
      )} */}
    </Fragment>
  );
};

export default BookingsList;
