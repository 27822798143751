import { useLazyQuery } from '@apollo/client';
import useFilters from '../../components/Shared/Filters/useFilters';
import { OPTION_DROPDOWN_TYPES } from '../../components/Shared/Menus/OptionDropdown/types';
import { GetAllSubscriptions } from '../../queries';
import { SelectedFilter } from '../../components/Shared/Filters/config';
import { getFilter, getMoreOptionsValues } from '../../components/Shared/Filters/helpers';
import { dateInUTC } from '../../utils/dates';

enum SUBSCRIPTIONS_FILTER_TYPES {
  SUBSCRIPTION_SEARCH = 'SUBSCRIPTION_SEARCH',
  SUBSCRIPTION_PRODUCT_NAME = 'SUBSCRIPTION_PRODUCT_NAME',
  SUBSCRIPTION_TRIAL_END = 'SUBSCRIPTION_TRIAL_END'
}
const HIDDEN_SUBSCRIPTIONS_FILTERS = {
  [SUBSCRIPTIONS_FILTER_TYPES.SUBSCRIPTION_SEARCH]: true,
  [SUBSCRIPTIONS_FILTER_TYPES.SUBSCRIPTION_PRODUCT_NAME]: false,
  [SUBSCRIPTIONS_FILTER_TYPES.SUBSCRIPTION_TRIAL_END]: false
};
const SUBSCRIPTIONS_FILTERS_QUERIES = {
  [SUBSCRIPTIONS_FILTER_TYPES.SUBSCRIPTION_SEARCH]: ['subscription_search'],
  [SUBSCRIPTIONS_FILTER_TYPES.SUBSCRIPTION_PRODUCT_NAME]: ['subscription_product_name'],
  [SUBSCRIPTIONS_FILTER_TYPES.SUBSCRIPTION_TRIAL_END]: ['subscription_trial_end_from', 'subscription_trial_end_to', 'subscription_trial_ended']
};
enum SUBSCRIPTIONS_MORE_OPTIONS_TYPES {
  SUBSCRIPTION_SEARCH = 'SUBSCRIPTION_SEARCH',
  SUBSCRIPTION_PRODUCT_NAME = 'SUBSCRIPTION_PRODUCT_NAME',
  SUBSCRIPTION_TRIAL_ACTIVE = 'SUBSCRIPTION_TRIAL_ACTIVE',
  SUBSCRIPTION_TRIAL_END_FROM = 'SUBSCRIPTION_TRIAL_END_FROM',
  SUBSCRIPTION_TRIAL_END_TO = 'SUBSCRIPTION_TRIAL_END_TO'
}

const SUBSCRIPTIONS_FILTER_TYPES_NAMES: Record<SUBSCRIPTIONS_FILTER_TYPES, string> = {
  [SUBSCRIPTIONS_FILTER_TYPES.SUBSCRIPTION_SEARCH]: 'Search',
  [SUBSCRIPTIONS_FILTER_TYPES.SUBSCRIPTION_PRODUCT_NAME]: 'Membership',
  [SUBSCRIPTIONS_FILTER_TYPES.SUBSCRIPTION_TRIAL_END]: 'Trial'
};

const SUBSCRIPTIONS_MORE_OPTIONS_TITLES: Record<SUBSCRIPTIONS_MORE_OPTIONS_TYPES, string> = {
  [SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_SEARCH]: '',
  [SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_PRODUCT_NAME]: 'Membership',
  [SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_TRIAL_ACTIVE]: 'Trial Active',
  [SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_TRIAL_END_FROM]: 'Trial active from',
  [SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_TRIAL_END_TO]: 'Trial active to'
};

const SUBSCRIPTIONS_MORE_OPTIONS_DROPDOWN_TYPES: Record<SUBSCRIPTIONS_MORE_OPTIONS_TYPES, OPTION_DROPDOWN_TYPES> = {
  [SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_SEARCH]: OPTION_DROPDOWN_TYPES.TEXT_INPUT,
  [SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_PRODUCT_NAME]: OPTION_DROPDOWN_TYPES.MULTI_SELECT,
  [SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_TRIAL_ACTIVE]: OPTION_DROPDOWN_TYPES.RADIO,
  [SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_TRIAL_END_FROM]: OPTION_DROPDOWN_TYPES.DATE,
  [SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_TRIAL_END_TO]: OPTION_DROPDOWN_TYPES.DATE
};

const SUBSCRIPTIONS_FILTER_TYPES_UNITS: Record<SUBSCRIPTIONS_FILTER_TYPES, string> = {
  [SUBSCRIPTIONS_FILTER_TYPES.SUBSCRIPTION_SEARCH]: '',
  [SUBSCRIPTIONS_FILTER_TYPES.SUBSCRIPTION_PRODUCT_NAME]: '',
  [SUBSCRIPTIONS_FILTER_TYPES.SUBSCRIPTION_TRIAL_END]: ''
};

const useSubscriptionsFilters = () => {
  const [getAllSubscriptions, { data: { getProducts: subscriptions = [] } = {}, called: calledSubscriptions }] = useLazyQuery(GetAllSubscriptions, {
    fetchPolicy: 'cache-and-network'
  });

  const toCall = [{ called: calledSubscriptions, get: () => getAllSubscriptions({ variables: { offset: 0, limit: 1000 } }), id: SUBSCRIPTIONS_FILTER_TYPES.SUBSCRIPTION_PRODUCT_NAME }];

  const MORE_OPTIONS_ITEMS: Record<SUBSCRIPTIONS_MORE_OPTIONS_TYPES, { value: string | boolean; name: string }[] | []> = {
    [SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_SEARCH]: [],
    [SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_PRODUCT_NAME]: subscriptions.map(({ id, name }: { id: string; name: string }) => ({ value: id, name })),
    [SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_TRIAL_ACTIVE]: [
      { value: 'true', name: 'Yes' },
      { value: 'false', name: 'No' },
      { value: 'custom', name: 'Custom' }
    ],
    [SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_TRIAL_END_FROM]: [],
    [SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_TRIAL_END_TO]: []
  };
  const filtersControl = useFilters<SUBSCRIPTIONS_FILTER_TYPES, SUBSCRIPTIONS_MORE_OPTIONS_TYPES>({
    toCall,
    getOptions: watchedValues => {
      const customEndTrial = watchedValues?.[SUBSCRIPTIONS_FILTER_TYPES.SUBSCRIPTION_TRIAL_END]?.[SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_TRIAL_ACTIVE] === 'custom';

      const SUBSCRIPTIONS_FILTER_TYPES_MORE_OPTIONS: Record<SUBSCRIPTIONS_FILTER_TYPES, SUBSCRIPTIONS_MORE_OPTIONS_TYPES[]> = {
        [SUBSCRIPTIONS_FILTER_TYPES.SUBSCRIPTION_SEARCH]: [SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_SEARCH],
        [SUBSCRIPTIONS_FILTER_TYPES.SUBSCRIPTION_PRODUCT_NAME]: [SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_PRODUCT_NAME],
        [SUBSCRIPTIONS_FILTER_TYPES.SUBSCRIPTION_TRIAL_END]: [
          SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_TRIAL_ACTIVE,
          ...(customEndTrial ? [SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_TRIAL_END_FROM, SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_TRIAL_END_TO] : [])
        ].filter(Boolean)
      };

      return {
        FILTER_TYPES: SUBSCRIPTIONS_FILTER_TYPES,
        FILTER_TYPES_MORE_OPTIONS: SUBSCRIPTIONS_FILTER_TYPES_MORE_OPTIONS,
        FILTER_TYPES_NAMES: SUBSCRIPTIONS_FILTER_TYPES_NAMES,
        FILTER_TYPES_UNITS: SUBSCRIPTIONS_FILTER_TYPES_UNITS,
        MORE_OPTIONS_DROPDOWN_TYPES: SUBSCRIPTIONS_MORE_OPTIONS_DROPDOWN_TYPES,
        MORE_OPTIONS_TITLES: SUBSCRIPTIONS_MORE_OPTIONS_TITLES,
        MORE_OPTIONS_TYPES: SUBSCRIPTIONS_MORE_OPTIONS_TYPES,
        MORE_OPTIONS_ITEMS: MORE_OPTIONS_ITEMS
      };
    }
  });

  return filtersControl;
};

const getSubscriptionsFiltersVariables = ({
  defaultVariables,
  selectedFilters,
  overriddenFilters
}: {
  defaultVariables?: Record<string, any>;
  selectedFilters: SelectedFilter[];
  overriddenFilters?: Partial<Record<(typeof SUBSCRIPTIONS_FILTERS_QUERIES)[keyof typeof SUBSCRIPTIONS_FILTERS_QUERIES][number], any>>;
}) => {
  const getFilterValue = (filter: SUBSCRIPTIONS_FILTER_TYPES) => getFilter(selectedFilters, filter);

  // search
  const searchFilter = getFilterValue(SUBSCRIPTIONS_FILTER_TYPES.SUBSCRIPTION_SEARCH);
  const searchFilterValue = getMoreOptionsValues(searchFilter, SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_SEARCH);

  // product name
  const productNameFilter = getFilterValue(SUBSCRIPTIONS_FILTER_TYPES.SUBSCRIPTION_PRODUCT_NAME);
  const productNameFilterValue = getMoreOptionsValues(productNameFilter, SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_PRODUCT_NAME, { name: true });

  // trial end
  const trialEndFilter = getFilterValue(SUBSCRIPTIONS_FILTER_TYPES.SUBSCRIPTION_TRIAL_END);
  const trialActiveFilterValue = getMoreOptionsValues(trialEndFilter, SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_TRIAL_ACTIVE);
  const trialEndFromFilterValue = getMoreOptionsValues(trialEndFilter, SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_TRIAL_END_FROM);
  const trialEndToFilterValue = getMoreOptionsValues(trialEndFilter, SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_TRIAL_END_TO);
  const trialEndedValue = trialActiveFilterValue === 'false' ? true : trialActiveFilterValue === 'true' ? false : null;
  const trialEndFromValue = trialActiveFilterValue === 'custom' && trialEndToFilterValue ? dateInUTC(trialEndFromFilterValue as string) : null;
  const trialEndToValue = trialActiveFilterValue === 'custom' && trialEndFromFilterValue ? dateInUTC(trialEndToFilterValue as string) : null;

  const variables = {
    ...defaultVariables,
    subscription_search: searchFilterValue ? [searchFilterValue] : null,
    subscription_product_name: productNameFilterValue?.length ? productNameFilterValue : null,
    subscription_trial_end_from: trialEndFromValue,
    subscription_trial_end_to: trialEndToValue,
    subscription_trial_ended: trialEndedValue,
    requisite_queries: Object.values(SUBSCRIPTIONS_FILTER_TYPES)
      .map(filter => {
        const overriddenFiltersValues = SUBSCRIPTIONS_FILTERS_QUERIES[filter]?.filter(query => overriddenFilters?.[query]);
        if (overriddenFiltersValues?.length) {
          return overriddenFiltersValues;
        }
        const filterType = getFilterValue(filter);
        return filterType?.requisite === 'true' ? SUBSCRIPTIONS_FILTERS_QUERIES[filter] : null;
      })
      .filter(Boolean)
      .flat(),
    alternative_queries: Object.values(SUBSCRIPTIONS_FILTER_TYPES)
      .map(filter => {
        const overriddenFiltersValues = SUBSCRIPTIONS_FILTERS_QUERIES[filter]?.filter(query => overriddenFilters?.[query]);
        if (overriddenFiltersValues?.length) {
          return null;
        }
        const filterType = getFilterValue(filter);
        return filterType?.requisite === 'false' ? SUBSCRIPTIONS_FILTERS_QUERIES[filter] : null;
      })
      .filter(Boolean)
      .flat()
  };

  return variables;
};

export {
  HIDDEN_SUBSCRIPTIONS_FILTERS,
  SUBSCRIPTIONS_FILTERS_QUERIES,
  SUBSCRIPTIONS_FILTER_TYPES,
  SUBSCRIPTIONS_FILTER_TYPES_NAMES,
  SUBSCRIPTIONS_FILTER_TYPES_UNITS,
  SUBSCRIPTIONS_MORE_OPTIONS_DROPDOWN_TYPES,
  SUBSCRIPTIONS_MORE_OPTIONS_TITLES,
  SUBSCRIPTIONS_MORE_OPTIONS_TYPES,
  useSubscriptionsFilters,
  getSubscriptionsFiltersVariables
};
