import styled, { css, keyframes } from 'styled-components';
import Colors from '../../../../Colors';
import { HoverRecordOptionsContainer } from '../../../styled';

const slideInFromRight = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

export const UserContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 20px;
  min-width: 600px;
  &:nth-child(even) {
    background-color: ${Colors.backgroundGrey};
  }

  &:not(:first-child) {
    animation: ${slideInFromRight} 0.4s ease-in-out;
  }
`;

export const UserNameImageContainer = styled.div`
  display: flex;
  gap: 16px;
  padding: 16px 0;
  align-items: center;
  color: #212121;
`;
export const UserName = styled.div<{ size?: number }>`
  font-size: ${({ size }) => `${size ? size : 16}px`};
  font-weight: 800;
`;

export const UserImage = styled.img`
  height: 40px;
  width: 40px;
  border-radius: 50%;
`;

export const MoreIcon = styled.div``;

export const MoreIconList = styled.div`
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out;
`;

export const BookingsListContainer = styled.div`
  padding-top: 8px;
  height: 80%;
`;

export const BookingsList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
  list-style: none;
  height: 90%;
  overflow-y: scroll;
`;

export const Booking = styled.li`
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  align-items: center;
  padding: 6px 6px;

  &:hover {
    background-color: ${Colors.toolsBarBackgroundColor};
    border-radius: 15px;
    cursor: pointer;
    ${HoverRecordOptionsContainer} {
      opacity: 1;
      visibility: visible;
    }
  }
`;

export const PetContainer = styled.div<{ width?: number }>`
  display: flex;
  gap: 8px;
  color: #212121;
  width: ${props => `${props.width ? props.width : 800}px`};
  @media (max-width: 768px) {
    width: 100%;
    position: relative;
  }
`;
export const PetInfo = styled.div`
  display: flex;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
`;
export const PetImg = styled.img`
  border-radius: 50%;
  width: 40px;
  height: 40px;
`;
export const PetName = styled.span`
  width: 200px;
  font-size: 14px;
  font-weight: 800;
  white-space: nowrap;
`;

export const StaffMemberName = styled.span`
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 600;
  min-width: 120px;
`;

export const PetRecordTagsAndTimeContainer = styled.div`
  display: flex;
  flex: 1;
  gap: 8px;
  height: 30px;
`;

export const PetRecordTagsContainer = styled.div`
  display: flex;
  flex: 1;
  gap: 10px;
  align-items: center;
  margin-right: 8px;
  min-width: 200px;
`;

export const PetRecordTags = styled.div`
  display: flex;
  gap: 10px;
  /* width: 269px; */
  flex: 1;
  /* overflow-x: scroll; */
  flex-direction: row-reverse;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const PetRecordTag = styled.div`
  white-space: nowrap;
  border-radius: 21px;
  padding: 6px 8px;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  color: ${Colors.white};
  ${({ color }) => css`
    background-color: ${color};
  `}
`;

export const BookingTime = styled.span`
  align-self: center;
  white-space: nowrap;
  color: #212121;
  font-size: 14px;
  font-weight: 800;
  text-align: right;
  width: 70px;
`;
