import React, { useMemo, useRef } from 'react';
import ChatsBody, { ChatsBodyRef } from '../../components/Chat/ChatsBody';
import { useChatsFilters } from '../../components/Chat/ChatsFilters';
import ChatsHeader from '../../components/Chat/ChatsHeader/ChatsHeader';
import { CHAT_TABS_TYPES, CHAT_TABS_TYPES_LABELS } from '../../components/Chat/types';
import { MainContainer, ViewWrapper } from '../../components/Shared/Shared';
import SideTab from '../../components/Shared/SideTabs/SideTabs';
import useMediaQuery from '../../hooks/useMediaQuery';
import useBranchChatRoomsViewCounts from '../../hooks/useBranchChatRoomsViewCounts';

const Chats = () => {
  const { mobile } = useMediaQuery({ mobile: true });
  const filtersControl = useChatsFilters();
  const tabs = [
    { name: CHAT_TABS_TYPES_LABELS.CLIENTS, value: CHAT_TABS_TYPES.CLIENTS.toLowerCase(), component: ChatsBody },
    { name: CHAT_TABS_TYPES_LABELS.STAFF, value: CHAT_TABS_TYPES.STAFF.toLowerCase(), component: ChatsBody },
    { name: CHAT_TABS_TYPES_LABELS.BROADCASTS, value: CHAT_TABS_TYPES.BROADCASTS.toLowerCase(), disabled: true }
  ];

  const sections = [{ tabs }];

  const tabsValues = tabs.map(tab => tab.value);
  const tabValue = tabsValues?.find(tab => tab === location.pathname.split('/')[3]);
  const selected = tabValue || CHAT_TABS_TYPES.CLIENTS.toLowerCase();

  const SelectedTab = useMemo(() => tabs.find(tab => tab.value === selected)?.component!, [selected, JSON.stringify(tabs)]);

  const counts = useBranchChatRoomsViewCounts();

  const { appUserUnreadChatRoomsCount, busUserUnreadChatRoomsCount } = counts;

  const tabsWithCount = [
    {
      tabValue: CHAT_TABS_TYPES.CLIENTS.toLowerCase(),
      count: appUserUnreadChatRoomsCount || 0,
      requiresAction: true
    },
    {
      tabValue: CHAT_TABS_TYPES.STAFF.toLowerCase(),
      count: busUserUnreadChatRoomsCount || 0,
      requiresAction: true
    }
  ];

  return (
    <ViewWrapper>
      {!mobile && <SideTab sections={sections} tabsWithCount={tabsWithCount} defaultTab={CHAT_TABS_TYPES.CLIENTS} />}

      <MainContainer>
        {mobile && <SideTab sections={sections} defaultTab={CHAT_TABS_TYPES.CLIENTS} />}

        <ChatsHeader tabs={tabs} filtersControl={filtersControl} counts={counts} />
        {SelectedTab && <SelectedTab filtersControl={filtersControl} tabs={tabs} counts={counts} />}
      </MainContainer>
    </ViewWrapper>
  );
};

export default Chats;
