import { useQuery } from '@apollo/client';
import React from 'react';
import { sentenceCase } from 'sentence-case';
import { DRAWER_IDS } from '../../../components/DrawerBar/types';
import { Pet } from '../../../components/Pets/types';
import { GetBranchPetProfile, GetPetBehaviors } from '../../../queries';
import { vars } from '../../../reactive';
import { EditHealthWeight, EditPetRecordFeedingRoutine, EditPetRecordMeals, EditPetRecordTreatmentRoutine, SHAPES, Shape } from '../Health/Health';
import HealthChart from '../Health/HealthChart';
import HealthPetRecordDiet, { DietSupplementRecord, PetFeedingRoutineBehavior, PetTreatmentRoutineBehavior } from '../Health/HealthPetRecordDiet';
import {
  Container,
  DietRecordsContainer,
  DietsWrapper,
  Divider,
  HealthMeasurementContainer,
  HealthMeasurementHeader,
  HealthMeasurementTitle,
  HealthMeasurementValue,
  HealthMeasurementWrapper,
  HealthPetRecordDietContainer,
  PetRecordEntitiesContainer,
  PetRecordWrapper,
  SectionContainer,
  SectionsContainer,
  VetDaycareTitle
} from '../Health/styled';
import { toSuppReadableText } from '../../../utils/dates';
import { getUnit } from '../../../utils/validators';

const Diet = () => {
  const petId = vars.drawerBars().find(drawer => drawer.drawerId === DRAWER_IDS.PETS_DRAWER)?.recordData;
  const { data: { getBranchPetProfile: pet = {} } = {} } = useQuery(GetBranchPetProfile, {
    variables: { id: petId },
    fetchPolicy: 'cache-and-network'
  });
  const shape: Shape = pet?.PetRecord?.shape;
  const currentWeight: string = pet?.PetRecord?.measurements?.weights?.slice(-1)?.[0]?.weight?.toFixed(1);

  const { data: { getPetBehaviors: petBehaviors } = {} } = useQuery<{ getPetBehaviors: Pet['PetRecord']['PetBehavior'][] }>(GetPetBehaviors, {
    variables: { PetRecordId: pet?.PetRecord?.id },
    fetchPolicy: 'cache-and-network'
  });

  const petProfileBehavior = petBehaviors?.[0];
  const suppRecords = pet?.PetRecord?.SuppRecords?.filter(record => record.type === 'supplement').map(item => (
    <DietSupplementRecord
      key={item?.id}
      name={item.Product?.ProductKinds[0]?.name}
      type={item.Product?.name}
      time={toSuppReadableText(item.time)}
      asNeeded={item.adherences_allowed}
      quantity={`${item.quantity}${getUnit(item.Product?.unit)}`}
    />
  ));

  return (
    <Container>
      <SectionsContainer>
        <SectionContainer>
          <PetRecordEntitiesContainer column noPadding mobilePadding="0">
            <HealthPetRecordDiet pet={pet} />
          </PetRecordEntitiesContainer>
        </SectionContainer>
        <SectionContainer>
          <HealthPetRecordDietContainer>
            <PetRecordWrapper borderRadius="20px 20px 0 0">
              <VetDaycareTitle>Supplements & Medications</VetDaycareTitle>
              <DietsWrapper>
                <DietRecordsContainer>{suppRecords}</DietRecordsContainer>
              </DietsWrapper>
            </PetRecordWrapper>
            <Divider />
            <PetRecordWrapper borderRadius="0 0 20px 20px">
              {petProfileBehavior && (
                <>
                  <VetDaycareTitle>
                    <span>Treatment instructions</span>
                    <EditPetRecordTreatmentRoutine petProfileBehavior={petProfileBehavior} />
                  </VetDaycareTitle>
                  <PetTreatmentRoutineBehavior petBehavior={petProfileBehavior} />
                </>
              )}
            </PetRecordWrapper>
          </HealthPetRecordDietContainer>
        </SectionContainer>
        <SectionContainer>
          <HealthMeasurementWrapper>
            <VetDaycareTitle marginB={15}>
              Weight & Body Condition
              <EditHealthWeight pet={pet} />
            </VetDaycareTitle>
            <HealthMeasurementContainer>
              <HealthMeasurementHeader>
                <HealthMeasurementTitle mobileColor="#727272" mobileFontSize="14px" mobileFontWeight={600}>
                  Current Weight
                </HealthMeasurementTitle>
                <HealthMeasurementValue>{currentWeight ? `${currentWeight} kg` : '-'}</HealthMeasurementValue>
              </HealthMeasurementHeader>
              <HealthMeasurementHeader>
                <HealthMeasurementTitle mobileColor="#727272" mobileFontSize="14px" mobileFontWeight={600}>
                  BCS
                </HealthMeasurementTitle>
                <HealthMeasurementValue>
                  {SHAPES.indexOf(shape) + 1} - {sentenceCase(shape === 'BELOW_IDEAL' ? 'LEAN' : shape || '')}
                </HealthMeasurementValue>
              </HealthMeasurementHeader>
            </HealthMeasurementContainer>
            <HealthChart pet={pet} />
          </HealthMeasurementWrapper>
        </SectionContainer>
      </SectionsContainer>

      {/* <div style={{ flex: 1, overflow: 'auto', flexDirection: 'column' }}>
        <SectionContainer>
          <PetRecordEntitiesContainer column>
            <VetDaycareTitle>
              <span>Diet & Feeding Schedule</span>
              <EditPetRecordMeals pet={pet} />
            </VetDaycareTitle>
            <Divider />
            <HealthPetRecordDiet pet={pet} />
          </PetRecordEntitiesContainer>
        </SectionContainer>
        {petProfileBehavior && (
          <SectionContainer>
            <PetRecordEntitiesContainer column>
              <VetDaycareTitle>
                <span>Feeding Routine</span>
                <EditPetRecordFeedingRoutine petProfileBehavior={petProfileBehavior} />
              </VetDaycareTitle>
              <Divider />
              <PetFeedingRoutineBehavior petBehavior={petProfileBehavior} />
            </PetRecordEntitiesContainer>
          </SectionContainer>
        )}

        {petProfileBehavior && (
          <SectionContainer>
            <PetRecordEntitiesContainer column>
              <VetDaycareTitle>
                <span>Treatment Routine</span>
                <EditPetRecordTreatmentRoutine petProfileBehavior={petProfileBehavior} />
              </VetDaycareTitle>
              <Divider />
              <PetTreatmentRoutineBehavior petBehavior={petProfileBehavior} />
            </PetRecordEntitiesContainer>
          </SectionContainer>
        )}
      </div> */}
    </Container>
  );
};

export default Diet;
