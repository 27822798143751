import React from 'react';

import { useQuery, useReactiveVar } from '@apollo/client';
import {
  GetAllBranchBusUsers,
  GetBranchAppointmentsViews,
  GetBranchAppointmentTags,
  GetBranchBusUsersAppointments,
  GetBranchPetRecordTags,
  GetBranchProductsAppointments,
  GetBranchServiceOrdersViews,
  GetBusUserProfile
} from '../../../../queries';

import { BusUserProfile } from '../../../../components/Profile/types';
import { FiltersControl } from '../../../../components/Shared/Filters/useFilters';
import usePaginatedQuery from '../../../../hooks/usePaginatedQuery';
import useURLSearchParams from '../../../../hooks/useURLSearchParams';
import { vars } from '../../../../reactive';
import { formatDateDay, getNextDay, getStartOfDate } from '../../../../utils/dates';
import { Product } from '../../../Store/types';
import { BOOKINGS_FILTER_TYPES, BOOKINGS_MORE_OPTIONS_TYPES, getBookingsVariables } from '../../BookingsFilters';
import { GroupHeader } from '../../styled';
import { BOOKING_RECORD_VIEW_SETTINGS, BOOKING_STATUS_TYPES, BOOKING_TAB, BOOKING_TABS_TYPES, BOOKING_TABS_VALUES_TYPES, BOOKING_TYPE, Booking, BookingOrder } from '../../types';
import BookingsList, { BookingsListAppointmentsList } from '../BookingsList/BookingsList';
import useBookingsListBulkActions from '../../../../hooks/useBookingsListBulkActions';
import BookingsHeader from '../BookingsHeader/BookingsHeader';
import { Divider } from '../../../../components/Shared/SideTabs/styles';
import { BranchAppointmentTag, BranchPetRecordTag } from '../../../Store/BranchTags/types';
import { ProductOrder } from '../../../Orders/types';

type BookingsBodyProps = {
  tabs: BOOKING_TAB[];
  services: Product[];
  filtersControl: FiltersControl<BOOKINGS_FILTER_TYPES, BOOKINGS_MORE_OPTIONS_TYPES>;
};

const BookingsBody = ({ tabs, services, filtersControl }: BookingsBodyProps) => {
  const { selectedFilters } = filtersControl;
  const [selectedServices] = useURLSearchParams<string[]>('services');
  const [selectedStatuses] = useURLSearchParams<string[]>('status');
  const [selectedStaffMembers] = useURLSearchParams('staff');
  const [urlDate] = useURLSearchParams('date');

  const tabsValues = tabs.map(tab => tab.value);
  const tabValue = tabsValues?.find(tab => tab === location.pathname.split('/')[3]) || BOOKING_TABS_TYPES.CONFIRMED;
  const selectedTab = tabs.find(tab => tab.value === tabValue) || tabs[0];
  const isConfirmed = selectedTab?.value === BOOKING_TABS_VALUES_TYPES.CONFIRMED;
  const isMultiStaffView = useReactiveVar(vars.isMultiStaffView) && isConfirmed;
  const isAllServices = !selectedServices?.length;
  const isNew = selectedTab?.value === BOOKING_TABS_VALUES_TYPES.NEW;
  const isMultipleServices = selectedServices?.length > 1;
  const isAllStaffMembers = !selectedStaffMembers?.length;
  const activeViewSettings = useReactiveVar(vars.activeViewSettings);

  const { data: { getBusUserProfile: userProfile } = {} } = useQuery<{ getBusUserProfile: BusUserProfile }>(GetBusUserProfile, {
    fetchPolicy: 'cache-only'
  });
  const servicesIds = services.map(service => service.id);
  const filteredServices = servicesIds.filter(id => selectedServices.includes(id));
  const filteredServicesNames = services.filter(service => filteredServices.includes(service.id)).map(service => service.name);
  const filteredServicesDetails = services.filter(service => filteredServices.includes(service.id));

  const selectedDate = new Date(urlDate)?.getTime() ? new Date(urlDate) : new Date();

  const isUpcoming = selectedTab?.value === BOOKING_TABS_VALUES_TYPES.UPCOMING;

  const { data: { getBranchAppointmentTags: appointmentTags = [] } = {} } = useQuery<{ getBranchAppointmentTags: BranchAppointmentTag[] }>(GetBranchAppointmentTags, {
    fetchPolicy: 'cache-first'
  });

  const { data: { getBranchPetRecordTags: allPetsTags = [] } = {} } = useQuery<{ getBranchPetRecordTags: BranchPetRecordTag[] }>(GetBranchPetRecordTags, {
    fetchPolicy: 'cache-first',
    variables: {
      visibility: ['showOnAppointments']
    }
  });

  const {
    data: { getBranchProductsAppointments: { productsAppointments = [] } = {} } = {},
    loading: loadingServices,
    refetch
  } = useQuery<{
    getBranchProductsAppointments: {
      productsAppointments: {
        count: number;
        Product: Product;
        Appointments: Booking[];
      }[];
    };
  }>(GetBranchProductsAppointments, {
    fetchPolicy: 'cache-and-network',
    variables: {
      ...getBookingsVariables({
        selectedFilters,
        defaultVariables: {
          ProductId: filteredServices.length ? filteredServices : null,
          status: selectedTab?.status,
          booking_type: selectedTab?.booking_type,
          timestamp_from: selectedTab?.timestamp_from,
          timestamp_to: selectedTab?.timestamp_to,
          filter_by_role: userProfile?.role,
          ...BOOKING_RECORD_VIEW_SETTINGS.map(settings => ({ [settings.id]: false })).reduce((acc, curr) => ({ ...acc, ...curr }), {}),
          ...(isNew
            ? {
                recordTags: true
              }
            : {
                ...(activeViewSettings?.record && !!activeViewSettings?.record.length ? [activeViewSettings?.record].flat()?.reduce((acc, record) => ({ ...acc, [record]: true }), {}) : {})
              }),
          BranchId: userProfile?.Branch.id
        }
      })
    },
    skip: !isConfirmed || isMultiStaffView || (isConfirmed && isAllServices) || !userProfile?.Branch?.id
  });

  const paginatedQueryResult = usePaginatedQuery<{
    views: Booking[];
    count: number;
  }>({
    query: GetBranchAppointmentsViews,
    otherVariables: {
      ...getBookingsVariables({
        selectedFilters,
        defaultVariables: {
          status: selectedTab?.status,
          booking_type: selectedTab?.booking_type,
          timestamp_from: selectedTab?.timestamp_from,
          timestamp_to: selectedTab?.timestamp_to,
          filter_by_role: userProfile?.role,
          order_status: selectedTab?.order_status || null,
          disable_count: isUpcoming,
          ...BOOKING_RECORD_VIEW_SETTINGS.map(settings => ({ [settings.id]: false })).reduce((acc, curr) => ({ ...acc, ...curr }), {}),
          ...(isNew
            ? {
                recordTags: true
              }
            : {
                ...(activeViewSettings?.record && !!activeViewSettings?.record.length ? [activeViewSettings?.record].flat()?.reduce((acc, record) => ({ ...acc, [record]: true }), {}) : {})
              }),
          BranchId: userProfile?.Branch.id
        },
        overriddenFilters: {
          ...(filteredServices.length ? { appointment_product_name: filteredServicesNames } : {})
        }
      })
    },
    otherParams: {
      fetchPolicy: 'cache-and-network',
      skip: (isConfirmed && !isAllServices) || isMultiStaffView || !userProfile?.Branch?.id || isNew
    }
  });

  const serviceOrderPaginatedQueryResult = usePaginatedQuery<{
    views: ProductOrder[];
    count: number;
  }>({
    query: GetBranchServiceOrdersViews,
    otherVariables: {
      ...getBookingsVariables({
        selectedFilters,
        defaultVariables: {
          appointment_status: selectedTab?.status,
          booking_type: selectedTab?.booking_type,
          timestamp_from: selectedTab?.timestamp_from,
          timestamp_to: selectedTab?.timestamp_to,
          filter_by_role: userProfile?.role,
          order_status: selectedTab?.order_status || null,
          ...BOOKING_RECORD_VIEW_SETTINGS.map(settings => ({ [settings.id]: false })).reduce((acc, curr) => ({ ...acc, ...curr }), {}),
          recordTags: true,
          BranchId: userProfile?.Branch.id
        },
        overriddenFilters: {
          ...(filteredServices.length ? { appointment_product_name: filteredServicesNames } : {})
        }
      })
    },
    otherParams: {
      fetchPolicy: 'cache-and-network',
      skip: !userProfile?.Branch?.id || !isNew
    }
  });

  const { data, loading: loadingAppointments, refetch: refetchAppointments } = paginatedQueryResult?.[1] || {};

  const { data: serviceOrderData, loading: loadingServiceOrders, refetch: refetchServiceOrders } = serviceOrderPaginatedQueryResult?.[1] || {};

  const bookingOrderAppointments = data?.getBranchAppointmentsViews;
  const bookingOrderServiceOrders = serviceOrderData?.getBranchServiceOrdersViews;

  const { data: { getBranchBusUsersAppointments: { busUsersAppointments = [] } = {} } = {}, loading: loadingUsers } = useQuery<{
    getBranchBusUsersAppointments: {
      busUsersAppointments: {
        count: number;
        BusUser: BusUserProfile;
        Appointments: Booking[];
      }[];
    };
  }>(GetBranchBusUsersAppointments, {
    fetchPolicy: 'cache-and-network',
    variables: {
      ...getBookingsVariables({
        selectedFilters,
        defaultVariables: {
          BusUserId: selectedStaffMembers?.length ? selectedStaffMembers : null,
          status: [BOOKING_STATUS_TYPES.CONFIRMED],
          booking_type: [BOOKING_TYPE.SLOT],
          timestamp_from: getStartOfDate(selectedDate),
          timestamp_to: getStartOfDate(getNextDay(selectedDate)),
          ...BOOKING_RECORD_VIEW_SETTINGS.map(settings => ({ [settings.id]: false })).reduce((acc, curr) => ({ ...acc, ...curr }), {}),
          ...(isNew
            ? {
                recordTags: true
              }
            : {
                ...(activeViewSettings?.record && !!activeViewSettings?.record.length ? [activeViewSettings?.record].flat()?.reduce((acc, record) => ({ ...acc, [record]: true }), {}) : {})
              }),
          BranchId: userProfile?.Branch.id
        }
      })
    },
    skip: !isMultiStaffView || !userProfile?.Branch?.id
  });

  const { data: { getBranchBusUsers: busUsers = [] } = {} } = useQuery<{ getBranchBusUsers: BusUserProfile[] }>(GetAllBranchBusUsers);

  const productsAppointmentsProducts = productsAppointments?.map(p => p.Product);

  const allProducts = productsAppointmentsProducts?.length ? productsAppointmentsProducts : services;

  const productsToDisplay = isAllServices ? allProducts : filteredServicesDetails;

  const staffIds = busUsers?.map(user => user.id);
  const filteredStaff = staffIds?.filter(id => selectedStaffMembers.includes(id));
  const filteredStaffDetails = busUsers?.filter(user => filteredStaff?.includes(user.id));
  const staffMembers = isAllStaffMembers ? busUsers : filteredStaffDetails;

  // const statuses = selectedStatuses?.length ? selectedStatuses : ['UPCOMING', 'ACTIVE'];
  const statuses = selectedStatuses?.length && selectedStatuses;
  // const multiDayStatusesToDispaly = multiDayToolBarButtons.filter(button => statuses.includes(button.id));

  const itemsToDisplay = isConfirmed && !isMultiStaffView ? productsToDisplay : isMultiStaffView ? staffMembers : [];

  const group = (bookingsList: BookingOrder[]) => ({
    by: (booking: Booking) => {
      const appointment = booking;
      const multiDayBookings = !!appointment?.timestamp_until;

      if (multiDayBookings && !isUpcoming) {
        const now = selectedDate.setUTCHours(0, 0, 0, 0);

        const bookingTimestamp = new Date(booking.timestamp).setUTCHours(0, 0, 0, 0);
        const bookingUntilTimestamp = new Date(booking.timestamp_until).setUTCHours(0, 0, 0, 0);
        const checkIn = bookingTimestamp === now;
        const checkOut = bookingUntilTimestamp === now;
        const active = bookingTimestamp < now && bookingUntilTimestamp > now;

        if (checkOut) {
          return 'Check-Out';
        } else if (checkIn) {
          return 'Check-In';
        } else if (active) {
          return 'Active';
        }
      }
      return formatDateDay(appointment?.timestamp);
    },
    separator: (seperatedBookings: Booking[], groupLabel: string) => {
      const multiDayBookings = !!seperatedBookings[0]?.timestamp_until;
      const pets = seperatedBookings.map(booking => booking?.PetRecord?.id);
      const uniquePets = [...new Set(pets)];
      if (multiDayBookings && !isUpcoming) {
      }
      // If no bookings are found, show the default group header
      return (
        <GroupHeader fontSize={!isUpcoming ? '12px' : '16px'} dispaly={isUpcoming ? 'block' : 'flex'}>
          <span style={{ fontWeight: !isUpcoming ? 800 : 600, whiteSpace: 'noWrap' }}>
            {groupLabel} {multiDayBookings}
          </span>
          {!isUpcoming && <Divider />}
          {/* <span style={{ fontWeight: 600 }}>
            {uniquePets.length} {uniquePets.length === 1 ? 'Pet' : 'pets'}
          </span> */}
        </GroupHeader>
      );
    }
  });

  const adjustOrderAppointments = (orders?: ProductOrder[]) => {
    return orders?.map(order => {
      return {
        ...order,
        OrderItems: order.OrderItems.map(orderItem => ({
          ...orderItem,
          Appointments: orderItem.Appointments.map(appointment => ({
            ...appointment,
            OrderItem: {
              ...orderItem,
              Order: order
            }
          }))
        }))
      };
    });
  };

  const appointmentsList = (
    isConfirmed && !isMultiStaffView && !isAllServices
      ? productsAppointments
      : isMultiStaffView
      ? busUsersAppointments
      : isNew
      ? [
          {
            Orders: adjustOrderAppointments(bookingOrderServiceOrders?.views) || [],
            count: bookingOrderServiceOrders?.count || 0
          }
        ]
      : [
          {
            Appointments: bookingOrderAppointments?.views || [],
            count: bookingOrderAppointments?.count || 0
          }
        ]
  ) as BookingsListAppointmentsList;

  const loadingAppointmentsProducts = isConfirmed ? loadingServices : loadingAppointments;
  const refetchAppointmentsProducts = isConfirmed ? refetch : refetchAppointments;

  const { bulkSelect, selectAction, selectAllAction } = useBookingsListBulkActions({
    getAppointments: () => appointmentsList?.flatMap(({ Appointments, Orders }) => (isNew ? Orders?.flatMap(order => order.OrderItems?.[0]?.Appointments) : Appointments)),
    inDrawer: true
  });

  const middleAction = (
    <>
      {selectAction}
      {selectAllAction}
    </>
  );

  return (
    <>
      <BookingsHeader tabs={tabs} filtersControl={filtersControl} services={services} middleAction={middleAction} />
      <BookingsList
        appointmentsList={appointmentsList}
        loadingAppointments={loadingAppointmentsProducts}
        refetchAppointments={refetchAppointmentsProducts}
        selectedTab={selectedTab}
        itemsToDisplay={itemsToDisplay}
        filtersControl={filtersControl}
        isAllServices={isAllServices}
        bulkSelect={bulkSelect}
        isMultipleServices={isMultipleServices}
        allQuickTags={appointmentTags}
        petsTags={allPetsTags}
        paginatedQueryResult={isNew ? serviceOrderPaginatedQueryResult : paginatedQueryResult}
        group={isUpcoming || (isConfirmed && !isAllServices && !isMultiStaffView) ? group : undefined}
      />
    </>
  );
};

export default BookingsBody;
