import { toReadableDate } from '../../utils/dates';
import { getBranchCurrencySymbol } from '../../utils/getBranchCurrencySymbol';
import { ORDER_STATUS } from '../../views/Orders/types';
import { ORDER_INVOICE_STATUS, OrderInvoice } from './types';

export const getOrderInvoiceTitles = (orderInvoice: OrderInvoice) => {
  const { id, status, amount, invoice_date } = orderInvoice;
  const isFree = Number(amount) === 0;
  const isCanceled = status === ORDER_INVOICE_STATUS.PAYMENT_CANCELED;
  const isBilled = status === ORDER_INVOICE_STATUS.PAYMENT_SUCCEEDED;
  const isFullyRefunded = status === ORDER_INVOICE_STATUS.PAYMENT_REFUNDED;
  const isPartiallyRefunded = status === ORDER_INVOICE_STATUS.PARTIALLY_REFUNDED;
  const isRejected = status === ORDER_INVOICE_STATUS.PAYMENT_REJECTED || orderInvoice?.Order?.status === ORDER_STATUS.PAYMENT_REJECTED;
  const isPending = status === ORDER_INVOICE_STATUS.PAYMENT_PENDING || orderInvoice?.Order?.status === ORDER_STATUS.PAYMENT_PENDING;
  const isUnbilled = !orderInvoice?.Order?.id && !isCanceled && !isFullyRefunded && !isRejected;
  const statusTitle = isPartiallyRefunded
    ? 'Partially Refunded'
    : isUnbilled
    ? 'Unpaid'
    : isPending
    ? 'Pending'
    : isRejected
    ? 'Rejected'
    : isFullyRefunded
    ? 'Refunded'
    : isCanceled
    ? 'Canceled'
    : isBilled
    ? 'Paid'
    : '';

  const shouldShowRetry = !isBilled;
  const shouldShowUpdate = !isBilled && !isCanceled;
  const shouldShowSendEmail = true;
  const shouldShowMarkAsPaid = !isBilled;
  const shouldDisplayRefund = (isBilled || isPartiallyRefunded || isCanceled) && !isFullyRefunded;

  const creationDate = invoice_date ? toReadableDate(invoice_date, { noTime: true, isLocale: true }) : '';
  const amountTitle = isFree ? 'Free' : `${getBranchCurrencySymbol()}${amount}`;
  const refundedAmount = orderInvoice?.Order?.Refunds?.reduce((acc, refund) => acc + Number(refund.amount), 0);
  const refundTitle = refundedAmount > 0 ? `${getBranchCurrencySymbol()}${refundedAmount}` : '';
  const user = orderInvoice?.OrderBills?.[0]?.AppUser;
  const appUserName = user?.name;
  const orderInvoiceNumber = orderInvoice?.number;
  return {
    id,
    isCanceled,
    isFree,
    isBilled,
    isRefunded: isFullyRefunded,
    isRejected,
    isPending,
    creationDate,
    statusTitle,
    amountTitle,
    refundTitle,
    user,
    appUserName,
    orderInvoiceNumber,
    isUnbilled,
    shouldShow: {
      shouldShowRetry,
      shouldShowUpdate,
      shouldShowSendEmail,
      shouldShowMarkAsPaid,
      shouldDisplayRefund
    }
  };
};
