import React, { Fragment, useRef } from 'react';
import { sentenceCase } from 'sentence-case';
import { DRAWER_IDS } from '../../../../components/DrawerBar/types';
import { PetRecordOptionsContainer } from '../../../../components/Pets/styled';
import { setDrawerBar } from '../../../../reactive/actions';
import { AppUserImage, AppUserImageAnimation, AppUserImageContainer, AppUserImageContainerAnimation, RecordBody } from '../../../styled';
import BookingRecordOptions, { BookingRecordOptionsRef } from './BookingRecordOptions';

import { useReactiveVar } from '@apollo/client';
import { FlexRow, RedDot } from '../../../../components/Shared/Shared';
import { Divider } from '../../../../components/Shared/SideTabs/styles';
import { VerticalDivider } from '../../../../components/Shared/ToolBar/styles';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import { vars } from '../../../../reactive';
import { calculateNights, displayTime, findMonthName, findThreeLetterDayName, formatOrdinals, sortByTime, toMealReadableText, toReadableDate } from '../../../../utils/dates';
import { Booking, BOOKING_RECORD_VIEW_SETTINGS, BOOKING_TABS_VALUES_TYPES } from '../../types';
import { Booking as BookingComponent, PetImg, PetRecordTag, PetRecordTags, PetRecordTagsAndTimeContainer, PetRecordTagsContainer, StaffMemberName } from '../BookingsBody/styled';
import { PetContainer } from '../ServiceHeader/styled';
import { PetAndInfo, PetBioExtraInfoContainer } from './styled';
import { getBranchCurrencySymbol } from '../../../../utils/getBranchCurrencySymbol';
import useBulkSelect from '../../../../hooks/useBulkSelect';
import { DietMealRecord } from '../../../Pets/Health/HealthPetRecordDiet';
import { GroupPetDetails } from '../BookingsDrawer/styled';
import Colors from '../../../../Colors';
import { getUnit } from '../../../../utils/validators';
import { BranchAppointmentTag } from '../../../Store/BranchTags/types';
import useIcons from '../../../../hooks/useIcons';
import { Checkbox, CheckboxItemContainer } from '../../../../components/Shared/Forms/styled';
import { GatsbyImage } from 'gatsby-plugin-image';
import { FormLabel, Icon } from '../../../../components/Shared/Forms/Forms';
import { PetProfilePic, PetProfilePicsContainer } from '../../../../components/UserDrawer/styled';

const BookingRecord = ({
  appointments,
  recordOptions,
  selectedTab,
  isAllServices,
  isMultipleServices,
  bulkSelect,
  recordActiveViewSettings,
  tagsVisibleOnItem,
  isLast,
  quickTags
}: {
  appointments: Booking[];
  recordOptions: Record<string, any>;
  selectedTab: string;
  isAllServices: boolean;
  bulkSelect: ReturnType<typeof useBulkSelect>;
  recordActiveViewSettings: (typeof BOOKING_RECORD_VIEW_SETTINGS)[number]['id'][];
  tagsVisibleOnItem: BranchAppointmentTag[];
  isMultipleServices: boolean;
  isLast: boolean;
  quickTags: BranchAppointmentTag[];
}) => {
  const { mobile } = useMediaQuery({ mobile: true });
  const isConfirmed = selectedTab === BOOKING_TABS_VALUES_TYPES.CONFIRMED;
  const isMultiStaffView = useReactiveVar(vars.isMultiStaffView) && isConfirmed;
  const bookingRecordOptionsRef = useRef<BookingRecordOptionsRef>(null);
  const petContainerRef = useRef<HTMLDivElement>(null);
  const appointment = appointments[0];
  const {
    id: appointmentId,
    PetRecord: { Pet: { id: petId, profile_pic, name, Breed } = {} } = {},
    BusUsers,
    BranchRooms,
    timestamp,
    timestamp_until,
    BranchAppointmentTags,
    OrderItem: { Order: { id: orderId, payment, status: orderStatus } = {}, item: { name: serviceName, booking_type } = {} } = {}
  } = appointments?.[0] || {};

  const allPets = appointments.map(({ PetRecord: { Pet } }) => Pet);
  const usersProfilePics = allPets.map(pet => pet?.profile_pic).filter(Boolean);

  const allPetsTags = appointments.map(appointment => appointment?.PetRecord?.BranchPetRecordTags).flat();
  const tagsToShow = [...(BranchAppointmentTags || []), ...(allPetsTags || [])].filter(tag => (tagsVisibleOnItem || [])?.map(({ id }) => id).includes(tag?.id)) || [];

  const { displayName, displayBreed, displayServiceName, displayBookingTime, dispalyTrailingItems, dispalyOptionalDropDown, displayOrderStatus, displayStaffName, displayPrice } = recordOptions || {};

  const isNew = selectedTab === BOOKING_TABS_VALUES_TYPES.NEW;
  const isPayment = selectedTab === BOOKING_TABS_VALUES_TYPES.PAYMENT;
  const isUpcoming = selectedTab === BOOKING_TABS_VALUES_TYPES.UPCOMING;
  const isPaymentPending = orderStatus === 'PAYMENT_PENDING';
  const isPaymntRejected = orderStatus === 'PAYMENT_REJECTED';
  const isMultiDayBooking = booking_type === 'MULTI_DAY';
  const { show: showBulkSelect, toggleSelectMultiple, isSelected } = bulkSelect || {};
  const time = toReadableDate(timestamp, { noTime: isMultiDayBooking, withoutYear: true });
  const tiemstampUntil = toReadableDate(timestamp_until, { noTime: true, withoutYear: true });
  const dailyBookingTime = toReadableDate(timestamp);

  const multiDayBookingTime = `${time} - ${tiemstampUntil} (${calculateNights(timestamp, timestamp_until)})`;

  const toggleMenu = () => {
    bookingRecordOptionsRef?.current?.getOptionDropdownRefOptions?.()?.toggleMenu?.();
  };

  const handleClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    const isTapToTagElement = e.target instanceof HTMLElement && e.target.innerText === 'Tap to tag this booking';

    if (isTapToTagElement) {
      toggleMenu();
    } else {
      if (bookingRecordOptionsRef?.current?.getContainerRefOptions?.()?.contains?.(e.target as Node)) {
        return;
      }
      if (showBulkSelect) {
        toggleSelectMultiple([appointment?.id]);
        return;
      } else if (petContainerRef?.current?.contains?.(e.target as Node)) {
        setDrawerBar({
          drawerId: DRAWER_IDS.PETS_DRAWER,
          recordData: petId
        });
      } else
        setDrawerBar({
          drawerId: DRAWER_IDS.BOOKING_DRAWER,
          recordData: appointments
        });
    }
  };

  const shouldShowTimeAndStaff = isMultipleServices && (isMultiDayBooking ? !!recordActiveViewSettings?.length : !!recordActiveViewSettings?.filter(setting => setting !== 'recordTags').length);
  const appUserAddress = appointment?.PetRecord?.Pet?.AppUser?.addresses?.[0];
  // const appUserAddressString = appUserAddress ? Object.values(appUserAddress).join(', ') : '';

  const postcode = appointment?.PetRecord?.Pet?.AppUser?.postcode;
  const line2 = appUserAddress?.line2 ? `${appUserAddress?.line2}` : '';
  const appUserAddressString = appUserAddress
    ? `${appUserAddress.line1 ? appUserAddress.line1 + ', ' : ''}${line2 ? line2 + ', ' : ''} ${postcode ? postcode : ''} ${appUserAddress.country ? ', ' + appUserAddress.country : ''}`
    : '';
  const ordersNotes = appointment?.OrderItem?.Order.Notes || [];
  const uniqueOrderNotes = ordersNotes?.filter((note, index, self) => self.findIndex(n => n?.id === note?.id) === index);
  const petsNotes = appointment?.PetRecord?.Notes || [];
  const uniquePetNotes = petsNotes?.filter((note, index, self) => self.findIndex(n => n?.id === note?.id) === index);
  const petsContent = uniquePetNotes.map(note => note?.body?.content).join(', ');
  const ordersContent = uniqueOrderNotes.map(note => note?.body?.content).join(', ');
  const petRecord = appointment?.PetRecord;
  const petBehaviour = petRecord?.PetBehavior;
  const busUserName = appointment?.BusUsers?.map(({ name }) => name).join(', ');
  const pet = appointment?.PetRecord?.Pet;
  const petBehaviorContent = petBehaviour?.feeding_routine || '';

  const petTreatmentContent = petBehaviour?.treatment_routine || '';

  const mealsForPets = [petRecord].map(petRecord => {
    const meals = sortByTime(petRecord?.Meals || []);
    const mealsRecords = meals.map((item, index) => (
      <FlexRow key={item?.id} flex1 gap={10}>
        <FlexRow key={item?.id} gap={5}>
          <FormLabel marginBottom={0}>Meal</FormLabel>
          <FormLabel marginBottom={0}>{toMealReadableText(item.time)}:</FormLabel>
        </FlexRow>
        <FormLabel bold marginBottom={0}>
          {item.quantity} {getUnit(item.unit)}
        </FormLabel>
        <FormLabel bold marginBottom={0} width="100px" mobileOverflow>
          {item.name}
        </FormLabel>
      </FlexRow>
    ));
    if (!mealsRecords.length) {
      return null;
    }

    return (
      <GroupPetDetails
        key={petRecord?.id}
        backgroundColor={Colors.info2}
        style={{
          gap: 10,
          flexDirection: 'column'
        }}
      >
        {mealsRecords}
      </GroupPetDetails>
    );
  });

  const price = appointment?.OrderItem?.Order?.total;

  const renderTags = () => {
    return (
      <PetRecordTagsContainer displayNone={!isConfirmed}>
        {!mobile && <VerticalDivider />}
        <PetRecordTags mediaWidth={!isConfirmed}>
          {tagsToShow?.map(({ name, id, color }) => (
            <PetRecordTag color={color} key={id}>
              {name}
            </PetRecordTag>
          ))}
        </PetRecordTags>
      </PetRecordTagsContainer>
    );
  };

  const renderTrailingView = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: 10, flex: 1 }}>
        {(isNew || recordActiveViewSettings?.includes('recordTags')) && renderTags()}
        {!isNew && (
          <>
            {recordActiveViewSettings?.includes('recordPetNotes') && !!petsContent && <GroupPetDetails backgroundColor={Colors.info2}>{petsContent}</GroupPetDetails>}
            {recordActiveViewSettings?.includes('recordBookingNotes') && !!ordersContent && <GroupPetDetails backgroundColor={Colors.info3}>{ordersContent}</GroupPetDetails>}
            {recordActiveViewSettings?.includes('recordAddress') && !!appUserAddressString && <GroupPetDetails backgroundColor={Colors.info}>{appUserAddressString}</GroupPetDetails>}
            {recordActiveViewSettings?.includes('recordFeedingSchedule') && mealsForPets}
            {recordActiveViewSettings?.includes('recordFeedingRoutine') && !!petBehaviorContent && <GroupPetDetails backgroundColor={Colors.info}>{petBehaviorContent}</GroupPetDetails>}
            {recordActiveViewSettings?.includes('recordTreatmentRoutine') && !!petTreatmentContent && <GroupPetDetails backgroundColor={Colors.info3}>{petTreatmentContent}</GroupPetDetails>}
          </>
        )}
      </div>
    );
  };

  const icons = useIcons();

  return (
    <Fragment key={appointmentId}>
      <BookingComponent onClick={handleClick} mobilePadding="10px 8px" dispaly={isMultiStaffView ? 'block' : 'flex'} gap={8} flexStart>
        {/* {isNew && !mobile && <RedDot top="unset" left="unset" />} */}
        <PetBioExtraInfoContainer flex={isPayment}>
          {!mobile && (
            <AppUserImageContainer noPadding={!showBulkSelect}>
              {isNew && !mobile && <RedDot />}
              <AppUserImageContainerAnimation rotateOption={!!showBulkSelect} noPadding ref={petContainerRef}>
                {!showBulkSelect && (
                  <>
                    {usersProfilePics?.length === 1 && (
                      <>
                        {pet?.profile_pic && <AppUserImage src={pet?.profile_pic} index={0} />}
                        {!pet?.profile_pic && <GatsbyImage image={icons.user.childImageSharp.gatsbyImageData} alt="user" />}
                      </>
                    )}
                    {usersProfilePics?.length === 2 && (
                      <PetProfilePicsContainer>
                        {usersProfilePics?.map((profile_pic, index) => (
                          <PetProfilePic key={index} bgUrl={profile_pic} isDuo={usersProfilePics?.length === 2} />
                        ))}
                      </PetProfilePicsContainer>
                    )}
                    {usersProfilePics?.length > 2 && (
                      <PetProfilePicsContainer>
                        <PetProfilePic bgUrl={usersProfilePics[0]} isDuo />
                        <PetProfilePic isMulti> +{usersProfilePics?.length - 1}</PetProfilePic>
                      </PetProfilePicsContainer>
                    )}
                  </>
                )}
                {showBulkSelect && (
                  <AppUserImageAnimation absolute>
                    <CheckboxItemContainer
                      checked={isSelected(appointment.id)}
                      noMargin
                      style={{
                        justifyContent: 'center'
                      }}
                    >
                      <Checkbox>
                        <Icon viewBox="0 0 24 24">
                          <polyline points="20 6 9 17 4 12" />
                        </Icon>
                      </Checkbox>
                    </CheckboxItemContainer>
                  </AppUserImageAnimation>
                )}
              </AppUserImageContainerAnimation>
            </AppUserImageContainer>
          )}
          {!mobile && (
            <PetAndInfo>
              <RecordBody fontWeight="600" width="100" color="#212121">
                {name},{' '}
                <RecordBody width="80" fontWeight="600">
                  {Breed?.name}
                </RecordBody>
              </RecordBody>
            </PetAndInfo>
          )}
          {mobile && (
            <PetContainer>
              <PetImg src={profile_pic} alt={name} ref={petContainerRef} />
              <PetAndInfo>
                {isNew && mobile && <RedDot />}
                <RecordBody mobileFlexDirection="column" mobileWidth="100%" MobileGap={4}>
                  <RecordBody fontWeight="800" color="#212121" mobileFlex="1" mobileJustifyContent="flex-start" mobileWidth="100%" MobileGap={2}>
                    {name},{' '}
                    <RecordBody mobileFontWeight={500} mobileWidth="80px" mobileOverflow="hidden" mobileDisplay="block" mobileFlex="1">
                      {' '}
                      {Breed?.name}
                    </RecordBody>
                  </RecordBody>

                  <RecordBody mobileWidth="100%">
                    {displayServiceName && (
                      <RecordBody fontWeight="500" color="#212121" mobileWidth="100px" mobileDisplay="block" mobileOverflow="hidden" flex="1" noMargin={!mobile}>
                        {serviceName}
                      </RecordBody>
                    )}

                    {!isMultiDayBooking && displayBookingTime && !isConfirmed && (
                      <RecordBody fontWeight="800" noMargin={!mobile} marginLeft="auto" mobileFlex="1 1 auto" mobileMarginTop="auto" mobileJustifyContent="flex-end" mobileMarginRight="4px">
                        {time}
                      </RecordBody>
                    )}

                    {displayBookingTime && isMultiDayBooking && !isConfirmed && (
                      <RecordBody
                        fontWeight="800"
                        mobileWidth="200px"
                        noMargin={!mobile}
                        color="#212121"
                        marginLeft="auto"
                        mobileFlex="1 1 auto"
                        mobileMarginTop="auto"
                        mobileJustifyContent="flex-end"
                        mobileMarginRight="4px"
                      >
                        {multiDayBookingTime}
                      </RecordBody>
                    )}
                  </RecordBody>
                </RecordBody>
              </PetAndInfo>
            </PetContainer>
          )}

          {displayServiceName && !mobile && (
            <RecordBody fontWeight="700" width="200" color="#212121" maxWidth={isPayment ? 200 : undefined}>
              {serviceName}
            </RecordBody>
          )}
          {displayBookingTime && !isConfirmed && !shouldShowTimeAndStaff && !isMultiDayBooking && !mobile && (
            <RecordBody fontWeight="800" noMargin width={!isConfirmed ? '200' : '200'}>
              {time}
            </RecordBody>
          )}

          {displayBookingTime && isConfirmed && !isMultiDayBooking && !shouldShowTimeAndStaff && (
            <RecordBody
              fontWeight="800"
              width={isConfirmed && !isAllServices ? '75' : '200'}
              noMargin
              mobileMarginLeft="auto"
              mobileWidth="auto"
              style={{ textAlign: `${isMultiStaffView ? 'right' : 'left'}` }}
              mobileMarginRight="4px"
            >
              {dailyBookingTime}
            </RecordBody>
          )}

          {displayBookingTime && isMultiDayBooking && !isConfirmed && !mobile && !shouldShowTimeAndStaff && (
            <RecordBody fontWeight="800" width="200" noMargin color="#212121">
              {multiDayBookingTime}
            </RecordBody>
          )}

          {displayBookingTime && isMultiDayBooking && isConfirmed && isAllServices && !shouldShowTimeAndStaff && (
            <RecordBody fontWeight="800" width="200" noMargin color="#212121" mobileMarginTop="auto" mobileMarginRight="4px">
              {multiDayBookingTime}
            </RecordBody>
          )}
          {displayStaffName && !isMultiStaffView && !mobile && !shouldShowTimeAndStaff && (
            <RecordBody fontWeight="500" width="80" noMargin>
              {busUserName}
            </RecordBody>
          )}
          {displayBookingTime && isMultiDayBooking && isConfirmed && !isAllServices && !shouldShowTimeAndStaff && (
            <RecordBody fontWeight="800" width="200" noMargin color="#212121">
              {multiDayBookingTime}
            </RecordBody>
          )}
          {isMultiDayBooking && !isAllServices && !isUpcoming && !mobile && !isPayment && (
            <RecordBody fontWeight="800" width="100" noMargin>
              {BranchRooms[0]?.name}
            </RecordBody>
          )}

          {displayOrderStatus && !mobile && (
            <RecordBody fontWeight="800" width="170" flexEnd noMargin color={isPaymntRejected ? '#DA3636' : isPaymentPending ? '#eb5e00' : ''} style={{ textAlign: 'right' }}>
              {payment?.fail_reason || sentenceCase(orderStatus || '')}
            </RecordBody>
          )}
          {displayPrice && (
            <RecordBody fontWeight="800" width="100" noMargin color="#212121" flexEnd>
              {`${getBranchCurrencySymbol()}${price}`}
            </RecordBody>
          )}
        </PetBioExtraInfoContainer>

        {mobile && <Divider />}
        {dispalyTrailingItems && dispalyOptionalDropDown && (
          <FlexRow flex1 width="100%">
            <PetRecordTagsAndTimeContainer displayNone={!isConfirmed} padding="8px 0 0 0">
              {!isMultiStaffView && <PetRecordTagsContainer displayNone={!isConfirmed}>{renderTrailingView()}</PetRecordTagsContainer>}
              {dispalyOptionalDropDown && (
                <PetRecordOptionsContainer mobileMargin="0">
                  <BookingRecordOptions appointments={[appointment]} loadTags={false} ref={bookingRecordOptionsRef} showAlways={mobile} bookingQuickTags={quickTags} />
                </PetRecordOptionsContainer>
              )}
            </PetRecordTagsAndTimeContainer>
          </FlexRow>
        )}

        {dispalyOptionalDropDown && !dispalyTrailingItems && (
          <PetRecordOptionsContainer margin="6px 0 0 0">
            <BookingRecordOptions appointments={[appointment]} loadTags={false} ref={bookingRecordOptionsRef} showAlways={mobile} bookingQuickTags={quickTags} />
          </PetRecordOptionsContainer>
        )}
        {displayOrderStatus && mobile && (
          <RecordBody fontWeight="800" width="170" marginLeft="auto" flex="1" maxWidth={130} noMargin color={isPaymntRejected ? '#DA3636' : isPaymentPending ? '#eb5e00' : ''}>
            {payment?.fail_reason || sentenceCase(orderStatus || '')}
          </RecordBody>
        )}
      </BookingComponent>
      {!mobile && !isLast && <Divider />}
    </Fragment>
  );
};

export default BookingRecord;
