export type BranchVehicleRoutes = {
  BranchRouteId: string;
  BusUserId: string[];
};

export type BranchVehicleFormValues = {
  branchVehicle: {
    name: string;
    type: 'VAN' | 'MINI_VAN';
    status: 'ACTIVE' | 'INACTIVE';
    plate_number: string;
    color: string;
    image: string;
    daily_capacity: {
      size: 'GENERAL' | 'SMALL' | 'MEDIUM' | 'LARGE';
      capacity: number;
    }[];
    BranchVehicleRoutes: BranchVehicleRoutes[];
  };
};

export const capacitySizeTypes = ['GENERAL', 'SMALL', 'MEDIUM', 'LARGE'] as const;

export const branchVehicleTypesOptions = [
  {
    label: 'Van',
    value: 'VAN'
  },
  {
    label: 'Mini Van',
    value: 'MINI_VAN'
  }
];

export const branchVehicleStatusOptions = [
  {
    label: 'In Service',
    value: 'ACTIVE'
  },
  {
    label: 'Out of Service',
    value: 'INACTIVE'
  }
];
