import React, { useCallback } from 'react';
import OrderInvoiceRecord from './OrderInvoiceRecord';
import InifiniteList from '../../components/Shared/InfiniteList/InfiniteList';
import { UsePagintaedQueryResult } from '../../hooks/usePaginatedQuery';
import { OrderInvoice } from './types';
import OrderInvoiceOptions from './OrderInvoiceOptions';
import useNavigateDrawer from '../../hooks/useNavigateDrawer';
import { DRAWER_IDS } from '../DrawerBar/types';
import { UserExtensionTabs } from '../UserDrawer/UserExtension';

const OrderInvoicesList = ({ paginatedQueryResult, ListHeader, ListFooter }: { paginatedQueryResult: UsePagintaedQueryResult<OrderInvoice[]>; ListHeader?: JSX.Element; ListFooter?: JSX.Element }) => {
  const [[hasMoreItems, setHasMoreItems], queryResult] = paginatedQueryResult;

  const { data: { getBranchOrderInvoices: orderInvoices = [] } = {}, loading, fetchMore, refetch } = queryResult;

  const navigateDrawer = useNavigateDrawer({
    drawerData: {
      drawerId: DRAWER_IDS.USER_DRAWER,
      otherParams: { isExtended: true },
      otherData: { defaultTab: UserExtensionTabs.INVOICES }
    }
  });

  const renderOrderInvoice = useCallback(
    (orderInvoice: OrderInvoice) => (
      <OrderInvoiceRecord key={orderInvoice.id} orderInvoice={orderInvoice} navigateDrawer={navigateDrawer}>
        <OrderInvoiceOptions orderInvoice={orderInvoice} refetchOrderInvoices={refetch} />
      </OrderInvoiceRecord>
    ),
    [navigateDrawer, refetch]
  );

  return (
    <InifiniteList
      ListHeader={ListHeader}
      ListFooter={ListFooter}
      list={orderInvoices}
      itemRenderer={renderOrderInvoice}
      loading={loading}
      hasMoreItems={hasMoreItems}
      fetchMore={fetchMore}
      offset={orderInvoices?.length}
      setHasMoreItems={setHasMoreItems}
    />
  );
};

export default OrderInvoicesList;
