import styled, { css } from 'styled-components';
import Colors from '../../Colors';

export const FleetsContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

export const FleetsConentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const BranchRoutesContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Colors.white};
  border-radius: 8px;
  border: 1px solid ${Colors.info3};
  padding: 10px;
  box-sizing: border-box;
  flex: 1;
  margin: 20px;
`;

export const BranchRoutesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid ${Colors.info3};
`;

export const BranchRouteButtonsContainer = styled.div`
  display: flex;
  flex: 1;
`;

export const BranchRouteButton = styled.button<{ isSelected: boolean }>`
  background-color: ${Colors.white};
  border-radius: 8px;
  padding: 6px 10px 10px 10px;
  text-decoration: none;
  color: ${Colors.black};
  font-size: 12px;
  text-align: center;
  font-weight: 700;

  ${({ isSelected }) =>
    isSelected &&
    css`
      cursor: pointer;
      color: ${Colors.white};
      background-color: ${Colors.black};
    `}
  ${({ isSelected }) =>
    !isSelected &&
    css`
      &:hover {
        cursor: pointer;
        color: ${Colors.white};
        background-color: ${Colors.black};
      }
    `}
`;

export const BranchRoutePetContainer = styled.div<{ background?: string }>`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  background-color: ${props => props.background ?? Colors.lightGrey};
  border-radius: 8px;
  padding: 10px;
  gap: 10px;
`;

export const PetsListContainer = styled.div`
  width: 600px;
  max-width: 600px;
`;

export const VansContainer = styled.div`
  width: 100%;
  overflow-x: scroll;
  display: flex;
  height: 100%;
`;

export const VanContainer = styled.div`
  flex: 1;
  min-width: 400px;
  &:nth-child(even) {
    background-color: ${Colors.lightGrey};
  }
`;

export const UserImageContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const UserImage = styled.img<{ marginLeft?: string; width?: string; height?: string }>`
  width: ${props => (props.width ? props.width : '20px')};
  height: ${props => (props.height ? props.height : '20px')};
  border-radius: 50%;
  border: 2px solid white;
  position: relative;
  margin-left: ${props => (props.marginLeft ? props.marginLeft : '-8px')};
  z-index: 1;
`;

export const StaffContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  gap: 10px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 10px 16px 0px;
  z-index: 1;
  position: relative;
`;
