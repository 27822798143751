import React, { useRef } from 'react';
import useIcons from '../../../hooks/useIcons';
import { getBranchCurrencySymbol } from '../../../utils/getBranchCurrencySymbol';
import { AppUserImage, AppUserImageContainer, AppUserImageNameContainer, AppUserName, BlankAppUserImage, RecordBody, RecordDataContainer } from '../../styled';
import { BranchAppUser } from './types';
import { setDrawerBar } from '../../../reactive/actions';
import { DRAWER_IDS } from '../../../components/DrawerBar/types';

const BranchBillingRecord = ({ branchAppUser }: { branchAppUser: BranchAppUser }) => {
  const storeEditButtonRef = useRef<{ handleClick: () => void }>(null);
  const icons = useIcons();
  const handleClick = () => {
    storeEditButtonRef.current?.handleClick();

    setDrawerBar({
      drawerId: DRAWER_IDS.USER_DRAWER,
      isExtended: true,
      recordData: branchAppUser?.id
    });
  };
  const { id, profile_pic, name, appUserCounts } = branchAppUser || {};
  const { totalAppointmentsOrdersCount, totalProductOrdersCount, totalSubscriptionOrdersCount, totalAppointmentsAmount } = appUserCounts || {};

  const ordersTitles = [
    !!totalAppointmentsOrdersCount && `${totalAppointmentsOrdersCount} Bookings`,
    !!totalProductOrdersCount && `${totalProductOrdersCount} Products`,
    !!totalSubscriptionOrdersCount && `${totalSubscriptionOrdersCount} Memberships`
  ]
    .filter(Boolean)
    .join(', ');

  return (
    <RecordDataContainer onClick={handleClick}>
      <RecordBody>
        <AppUserImageNameContainer>
          <AppUserImageContainer>
            {profile_pic && <AppUserImage key={id} src={profile_pic} index={0} />}
            {!profile_pic && <BlankAppUserImage alt="user" image={icons.user.childImageSharp.gatsbyImageData} />}
          </AppUserImageContainer>
          <AppUserName>{name}</AppUserName>
        </AppUserImageNameContainer>
      </RecordBody>
      <RecordBody>{ordersTitles}</RecordBody>
      <RecordBody flexEnd>
        {getBranchCurrencySymbol()}
        {totalAppointmentsAmount}
      </RecordBody>
    </RecordDataContainer>
  );
};

export default BranchBillingRecord;
