import React, { Fragment, useRef } from 'react';
import { OrderInvoice } from '../../components/OrderInvoices/types';
import { addDrawerBar } from '../../reactive/actions';
import { getBranchCurrencySymbol } from '../../utils/getBranchCurrencySymbol';

import { PetBioExtraInfoContainer, PetInfo, TagsAndMoreIconContainer } from '../../views/Bookings/components/BookingRecord/styled';
import { getSubscriptionRepeatTitles } from '../../views/Subscriptions/utils';
import { RecordBody } from '../../views/styled';
import { DRAWER_IDS } from '../DrawerBar/types';
import OrderInvoiceOptions from '../OrderInvoices/OrderInvoiceOptions';
import { getOrderInvoiceTitles } from '../OrderInvoices/utils';
import { BillingStatus, BookingListItem, BookingsDivider } from './styled';
import { toReadableDate } from '../../utils/dates';
import { Booking } from '../../views/Bookings/components/BookingsBody/styled';
import { Divider } from '../Shared/SideTabs/styles';
import useMediaQuery from '../../hooks/useMediaQuery';
import useURLSearchParams from '../../hooks/useURLSearchParams';
import { InvoicesTabs } from './UserInvoices';

const UserOrderInvoiceRecord = ({ invoice, refetch }: { invoice: OrderInvoice; refetch: () => void }) => {
  const { mobile } = useMediaQuery({ mobile: true });
  const [invoicesSelectedTab] = useURLSearchParams('invoicesSelectedTab');
  const isOrderInvoicesTab = invoicesSelectedTab[0] === InvoicesTabs.ORDER_INVOICES;
  const { id, amount } = invoice;
  const { isCanceled, isFree, isBilled, isRefunded, isRejected, isPending, creationDate, statusTitle, orderInvoiceNumber, isUnbilled } = getOrderInvoiceTitles(invoice);

  const {
    createdAt,
    repeatTotal,
    subscriptionRepeatOrderNumber,
    statusTitleString,
    isSubscriptionRepeatBilled,
    isSubscriptionRepeatRefunded,
    isSubscriptionRepeatRejected,
    isSubscriptionRepeatPending,
    isSubscriptionRepeatCanceled,
    isSubscriptionRepeatUnbilled,
    subscriptionRepeatId
  } = getSubscriptionRepeatTitles(invoice);

  const optionsRef = useRef<HTMLDivElement>(null);

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (optionsRef?.current?.contains(e.target as Node)) {
      return;
    }
    addDrawerBar({ drawerId: DRAWER_IDS.ORDER_INVOICE_DRAWER, recordData: id || subscriptionRepeatId, isFloating: true, drawerWidths: { normal: 800, small: 800 } });
  };

  const invoiceType = invoice?.__typename;
  const isOrderSubscriptionRepeat = invoiceType === 'OrderSubscriptionRepeat';

  return (
    <Fragment key={id}>
      <Booking
        onClick={event => {
          !isOrderSubscriptionRepeat ? handleClick(event) : null;
        }}
        gap={20}
        minHeight="40px"
        mobilePadding="8px 10px"
      >
        {!mobile && (
          <>
            <RecordBody width="200" fontWeight={'700'} flex="unset">
              {orderInvoiceNumber || subscriptionRepeatOrderNumber || '-'}
            </RecordBody>
            {!isOrderSubscriptionRepeat && (
              <RecordBody fontWeight={'700'} flex="unset">
                {creationDate}
              </RecordBody>
            )}
            {isOrderSubscriptionRepeat && (
              <RecordBody fontWeight={'700'} flex="unset">
                {createdAt ? toReadableDate(createdAt, { noTime: true, isLocale: true }) : ''}
              </RecordBody>
            )}
            <RecordBody width="200" fontWeight={'700'}>
              {isFree && 'Free'}
              {amount && !isFree ? `${getBranchCurrencySymbol()} ${amount}` : repeatTotal}
            </RecordBody>
          </>
        )}

        {mobile && (
          <>
            <RecordBody mobileWidth="100%" fontWeight={'700'} flex="unset">
              {orderInvoiceNumber || subscriptionRepeatOrderNumber || '-'}
              {isOrderSubscriptionRepeat && (
                <RecordBody fontWeight={'700'} flex="unset" mobileFlex="unset" flexEnd>
                  {createdAt ? toReadableDate(createdAt, { noTime: true, isLocale: true }) : ''}
                </RecordBody>
              )}
              {!isOrderSubscriptionRepeat && (
                <RecordBody fontWeight={'700'} flex="unset" mobileFlex="unset" flexEnd>
                  {creationDate}
                </RecordBody>
              )}
            </RecordBody>
            <RecordBody width="100%">
              <RecordBody width="200" fontWeight={'700'}>
                {isFree && 'Free'}
                {amount && !isFree ? `${getBranchCurrencySymbol()} ${amount}` : repeatTotal}
              </RecordBody>
            </RecordBody>
          </>
        )}
        {mobile && <Divider />}
        <TagsAndMoreIconContainer gap={1}>
          {isOrderSubscriptionRepeat && (
            <RecordBody width="150" fontWeight="800" flexEnd noMargin>
              <BillingStatus
                noMaxWidth
                blue={isSubscriptionRepeatPending}
                gray={isSubscriptionRepeatCanceled}
                green={isSubscriptionRepeatBilled}
                red={isSubscriptionRepeatRefunded || isSubscriptionRepeatRejected || isSubscriptionRepeatUnbilled}
              >
                {statusTitleString}
              </BillingStatus>
            </RecordBody>
          )}
          {!isOrderSubscriptionRepeat && (
            <RecordBody width="100" fontWeight="800" flexEnd noMargin>
              <BillingStatus noMaxWidth blue={isPending} gray={isCanceled} green={isBilled} red={isRefunded || isRejected || isUnbilled}>
                {statusTitle}
              </BillingStatus>
            </RecordBody>
          )}

          <OrderInvoiceOptions orderInvoice={invoice} refetchOrderInvoices={refetch} isOrderSubscriptionRepeat={isOrderSubscriptionRepeat} ref={optionsRef} />
          {isSubscriptionRepeatBilled && isOrderSubscriptionRepeat && <div style={{ width: '30px', height: '30px' }} />}
        </TagsAndMoreIconContainer>
      </Booking>
      <Divider />
    </Fragment>
  );
};

export default UserOrderInvoiceRecord;
