import { useMutation } from '@apollo/client';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { Fragment, useEffect } from 'react';
import { Controller, UseFieldArrayMethods, UseFormMethods, useFieldArray, useForm } from 'react-hook-form';
import ReactSelect from '../../../../components/Shared/Forms/Select';
import RRule from 'rrule';
import Colors from '../../../../Colors';
import ModalDialog, { ModalLayout } from '../../../../components/Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../../../../components/Modal/styled';
import {
  AlternateBackground,
  FormButtonsContainer,
  FormCheckbox,
  FormError,
  FormInput,
  FormLabel,
  FormSelect,
  FormSubmitButton,
  InputContainer,
  InputsRow,
  InputsWrapper,
  WideInputGroup,
  selectTheme
} from '../../../../components/Shared/Forms/Forms';
import { Container } from '../../../../components/Shared/Shared';
import useIcons from '../../../../hooks/useIcons';
import { AddBranchSchedule, EditBranchSchedule } from '../../../../queries';
import { THREE_LETTER_WEEK_DAYS, WEEK_DAYS_MAP, rruleToReadableTime } from '../../../../utils/dates';
import { ValueOfArrayKeys, getKeys } from '../../../../utils/helpers';
import { BOOKING_TYPE } from '../../../Bookings/types';
import Common from '../../Common';
import { AddNewBtn, AddNewBtnIcon, SlotsDaysContainer } from '../styled';
import { BranchSchedule } from '../types';
import { ALLOWED_FREQUENCIES, BranchScheduleForm, defaultRRule, defaultSlots, frequencies, getFullWeekDayFrom2Letter, getThreeLetterWeekDay, nthDays, rruleToText, typesOptions } from './types';
import Select from '../../../../components/Shared/Forms/Select';
import { Divider } from '../../../../components/Shared/SideTabs/styles';
import { VerticalDivider } from '../../../../components/Shared/ToolBar/styles';

type BranchScheduleModalFormProps = {
  formOptions: UseFormMethods<BranchScheduleForm>;
  saveError: boolean;
  saveLoading: boolean;
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  defaultValues?: Partial<BranchScheduleForm>;
  selectedSlotsFieldArray: UseFieldArrayMethods<BranchScheduleForm['selectedSlots'], 'id'>;
  closingSlotsFieldArray: UseFieldArrayMethods<BranchScheduleForm['closingSlots'], 'id'>;
};

const defaultField = {
  selectedDays: WEEK_DAYS_MAP.reduce((acc, day) => {
    acc[day] = {
      isSelected: false,
      numberOfSlots: 0,
      nth: 0
    };
    return acc;
  }, {} as BranchScheduleForm['selectedSlots'][0]['selectedDays']),
  time: '12:00',
  frequency: frequencies[0].value,
  interval: 1,
  count: 0,
  extra_dates: []
};

const BranchScheduleModalForm = ({ formOptions, saveError, saveLoading, onSubmit, defaultValues, selectedSlotsFieldArray, closingSlotsFieldArray }: BranchScheduleModalFormProps) => {
  const { control, errors, watch, setValue } = formOptions;
  const watchedValues = watch();

  const { booking_type } = watchedValues;

  const isMultiday = booking_type === 'MULTI_DAY';
  const isMultiSlot = booking_type === 'MULTI_SLOT';
  const isSlot = booking_type === 'SLOT';
  const isOther = booking_type === 'OTHER';
  const isOneOff = booking_type === 'ONE_OFF';

  const addRow = () => {
    selectedSlotsFieldArray.append([defaultField]);
  };

  const removeRow = (index: number) => {
    selectedSlotsFieldArray.remove(index);
  };

  const icons = useIcons();

  const addClosingRow = () => {
    closingSlotsFieldArray.append([
      {
        selectedDays: [],
        time: defaultField.time
      }
    ]);
  };

  const removeClosingRow = (index: number) => {
    closingSlotsFieldArray.remove(index);
  };

  return (
    <ModalLayout
      buttons={[
        <FormSubmitButton error={saveError} loading={saveLoading} onClick={onSubmit}>
          Save
        </FormSubmitButton>
      ]}
      compact
    >
      <>
        <WideInputGroup marginBottom={0}>
          <FormLabel>Schedule Name</FormLabel>
          <Controller as={<FormInput error={!!errors.name} />} control={control} name={'name'} defaultValue={defaultValues?.name} rules={{ required: true }} />
          {errors.name && <FormError>{errors.name.message || 'Name is required'}</FormError>}
        </WideInputGroup>
        <WideInputGroup marginBottom={0}>
          <FormLabel>Booking type</FormLabel>
          <WideInputGroup marginBottom={0}>
            <Controller
              render={({ onChange, value }) => (
                <Select
                  theme={selectTheme}
                  options={typesOptions.map(option => ({ value: option.id, label: option.name }))}
                  value={{ value: value, label: typesOptions.find(option => option.id === value)?.name }}
                  onChange={option => {
                    onChange(option?.value || typesOptions[0].id);
                  }}
                />
              )}
              control={control}
              name={'booking_type'}
              defaultValue={defaultValues?.booking_type}
            />
          </WideInputGroup>
          {errors.booking_type && <FormError>{errors.booking_type.message || 'type is required'}</FormError>}
        </WideInputGroup>
        <Divider />
        {!isMultiday && !isOneOff && !isOther && (
          <FormLabel bold marginBottom={0}>
            Sessions
          </FormLabel>
        )}

        {isOther && (
          <>
            <FormLabel bold marginBottom={0}>
              Availability
            </FormLabel>
            <WideInputGroup marginBottom={0}>
              <FormLabel error={!!errors?.numberOfSlots}>Available Spaces (Number of pets per slot)</FormLabel>
              <Controller
                as={<FormInput error={!!errors?.numberOfSlots} pattern="[0-9]*" />}
                control={control}
                name={'numberOfSlots'}
                defaultValue={defaultValues?.numberOfSlots}
                rules={{
                  required: true,
                  validate: (value: any) => {
                    if (+value === 0) {
                      return 'Please enter a valid number from 1 - 100';
                    }
                  },
                  pattern: { value: /^[0-9]*$/, message: 'Please enter a valid number' }
                }}
              />
              {errors?.numberOfSlots && <FormError>{errors?.numberOfSlots?.message || 'Slots is required'}</FormError>}
            </WideInputGroup>
          </>
        )}
        {isOther && <Divider />}
        {isOther && (
          <FormLabel bold marginBottom={0}>
            Available Slots
          </FormLabel>
        )}
        {!isMultiday &&
          !isOneOff &&
          selectedSlotsFieldArray.fields.map((selectedSlot, index) => {
            const defaultSlotValues = watchedValues.selectedSlots?.[index] || defaultValues?.selectedSlots?.[index] || defaultField;

            const { selectedDays, time, frequency, interval, count } = defaultSlotValues;
            const defaultName = `selectedSlots[${index}]`;
            return (
              <AlternateBackground key={selectedSlot?.id} firstColor={Colors.white} secondColor={Colors.grey} padding="16px" borderRadius="6px">
                <SlotsDaysContainer padding={!isSlot ? '0 25px 0 100px' : '0 35px 0 105px'}>
                  {(index === 0 || isOther) &&
                    THREE_LETTER_WEEK_DAYS.map(day => (
                      <FormLabel bold key={day} style={{ flex: 1, textAlign: 'center', flexBasis: '14%', fontWeight: '700' }}>
                        {getThreeLetterWeekDay(day)}
                      </FormLabel>
                    ))}
                </SlotsDaysContainer>
                <WideInputGroup key={selectedSlot?.id} marginBottom={0}>
                  <InputsRow marginBottom={0}>
                    <div
                      style={{
                        alignItems: 'flex-end',
                        display: 'flex'
                      }}
                    >
                      <InputContainer autoHeight marginBottom={0}>
                        <Controller
                          as={<FormInput error={!!errors.selectedSlots?.[index]?.time} height={38} fontSize={14} type={'time'} width={90} />}
                          control={control}
                          name={`${defaultName}.time`}
                          defaultValue={defaultSlotValues?.time}
                          rules={{
                            required: true,
                            validate: val => val.split(':')[0] !== '00'
                          }}
                        />
                        {errors.selectedSlots?.[index]?.time && <FormError>{errors.selectedSlots?.[index]?.time?.message || 'Time is required'}</FormError>}
                      </InputContainer>
                      <VerticalDivider marginLeft="6px" height="38px" marginRight={isSlot ? '6px' : '0'} />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1
                      }}
                    >
                      <div
                        style={{
                          flex: 1,
                          display: 'flex',
                          gap: 10,
                          justifyContent: isSlot ? 'unset' : 'flex-end'
                        }}
                      >
                        {WEEK_DAYS_MAP.map(day => (
                          <InputContainer
                            key={day}
                            autoHeight
                            style={{
                              flex: 1,
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                            marginBottom={0}
                          >
                            {isSlot && (
                              <>
                                <Controller
                                  render={({ onChange, value }) => (
                                    <FormInput
                                      error={!!errors.selectedSlots?.[index]?.selectedDays?.[day]?.numberOfSlots}
                                      min={0}
                                      value={value}
                                      onChange={e => {
                                        onChange(e.target.value);
                                        setValue(`${defaultName}.selectedDays.${day}.isSelected`, !!Number(e.target.value));
                                      }}
                                    />
                                  )}
                                  control={control}
                                  name={`${defaultName}.selectedDays.${day}.numberOfSlots`}
                                  defaultValue={defaultSlotValues?.selectedDays?.[day]?.numberOfSlots}
                                  rules={{
                                    required: true,
                                    validate: (value: any) => {
                                      if (isMultiday && +value === 0) {
                                        return 'Please enter a valid number from 1 - 100';
                                      }
                                    },
                                    min: 0,
                                    pattern: { value: /^[0-9]*$/, message: 'Please enter a valid number' }
                                  }}
                                />
                                {errors.selectedSlots?.[index]?.selectedDays?.[day]?.numberOfSlots && (
                                  <FormError>{errors.selectedSlots?.[index]?.selectedDays?.[day]?.numberOfSlots?.message || 'Slots is required'}</FormError>
                                )}
                              </>
                            )}
                            {!isSlot && (
                              <>
                                <Controller
                                  render={({ onChange, value }) => (
                                    <FormCheckbox
                                      itemsArray={[{ id: day, name: '' }]}
                                      value={value ? [day] : []}
                                      onChange={(newValue: string[]) => {
                                        const isSelected = newValue.includes(day);
                                        onChange(isSelected);
                                        setValue(`${defaultName}.selectedDays.${day}.numberOfSlots`, isSelected ? watchedValues.numberOfSlots : 0);
                                      }}
                                      column
                                      noSpaceArround
                                    />
                                  )}
                                  control={control}
                                  name={`${defaultName}.selectedDays.${day}.isSelected`}
                                  defaultValue={defaultSlotValues?.selectedDays?.[day]?.isSelected}
                                />
                              </>
                            )}
                          </InputContainer>
                        ))}
                      </div>
                    </div>

                    <AddNewBtn
                      marginBottom="0"
                      marginLeft={isSlot ? '10px' : '0'}
                      onClick={() => {
                        if (selectedSlotsFieldArray.fields.length === 1) {
                          return;
                        }
                        removeRow(index);
                      }}
                    >
                      <GatsbyImage
                        image={icons.deleteOutlined.childImageSharp.gatsbyImageData}
                        alt="Delete"
                        style={{
                          visibility: selectedSlotsFieldArray.fields.length === 1 ? 'hidden' : 'visible',
                          cursor: 'pointer',
                          width: 20,
                          height: 20
                        }}
                      />
                    </AddNewBtn>
                  </InputsRow>
                </WideInputGroup>
                {isOther && (
                  <>
                    <WideInputGroup>
                      <FormLabel marginTop="20px">Recurrence Type</FormLabel>
                      <Controller
                        render={({ onChange, value }) => (
                          <Select
                            theme={selectTheme}
                            options={frequencies.map(option => ({ value: option.value, label: option.label }))}
                            value={{ value: value, label: frequencies.find(option => option.value === value)?.label }}
                            onChange={option => {
                              onChange(option?.value || frequencies[0].value);
                            }}
                          />
                        )}
                        control={control}
                        name={`${defaultName}.frequency`}
                        defaultValue={defaultSlotValues?.frequency}
                        rules={{ required: true }}
                      />
                      {errors?.selectedSlots?.[index]?.frequency && <FormError>{errors?.selectedSlots?.[index]?.frequency?.message || 'Frequency is required'}</FormError>}
                    </WideInputGroup>

                    {frequency !== 'WEEKLY' &&
                      getKeys(selectedDays)
                        .filter(day => selectedDays[day]?.isSelected)
                        ?.map(day => (
                          <WideInputGroup key={day}>
                            <FormLabel error={!!errors?.selectedSlots?.[index]?.selectedDays?.[day]?.nth?.message}>{getFullWeekDayFrom2Letter(day)}</FormLabel>
                            <Controller
                              render={({ onChange, value }) => (
                                <Select
                                  theme={selectTheme}
                                  options={[
                                    {
                                      value: 0,
                                      label: `${rruleToText({
                                        freq: RRule[frequency!],
                                        interval,
                                        byweekday: RRule[day]
                                      })} (All ${getFullWeekDayFrom2Letter(day)}s)`
                                    },
                                    ...nthDays[frequency!]?.map((nthDay, index) => ({
                                      value: nthDay,
                                      label: rruleToText({
                                        freq: RRule[frequency!],
                                        interval,
                                        byweekday: RRule[day].nth(nthDay)
                                      })
                                    }))
                                  ]}
                                  value={{
                                    value: value || 0,
                                    label:
                                      value === 0
                                        ? `${rruleToText({
                                            freq: RRule[frequency!],
                                            interval,
                                            byweekday: RRule[day]
                                          })} (All ${getFullWeekDayFrom2Letter(day)}s)`
                                        : rruleToText({
                                            freq: RRule[frequency!],
                                            interval,
                                            byweekday: RRule[day].nth(value)
                                          })
                                  }}
                                  onChange={option => {
                                    onChange(option?.value);
                                  }}
                                />
                              )}
                              control={control}
                              name={`${defaultName}.selectedDays.${day}.nth`}
                              defaultValue={defaultSlotValues?.selectedDays?.[day]?.nth}
                            />
                            {errors?.selectedSlots?.[index]?.selectedDays?.[day]?.nth && (
                              <FormError>{errors?.selectedSlots?.[index]?.selectedDays?.[day]?.nth?.message || 'Nth is required'}</FormError>
                            )}
                          </WideInputGroup>
                        ))}

                    <WideInputGroup>
                      <FormLabel>Repeats Every</FormLabel>
                      <Controller
                        render={({ onChange, value }) => (
                          <FormInput
                            value={value}
                            onChange={onChange}
                            min={1}
                            max={100}
                            type={'number'}
                            defaultValue={defaultSlotValues?.interval}
                            error={!!errors?.selectedSlots?.[index]?.interval}
                          />
                        )}
                        control={control}
                        name={`${defaultName}.interval`}
                        defaultValue={defaultSlotValues?.interval}
                        rules={{ required: true, max: 100, min: 1 }}
                      />
                      {errors?.selectedSlots?.[index]?.interval && <FormError>{errors?.selectedSlots?.[index]?.interval?.message || 'Interval is required'}</FormError>}
                    </WideInputGroup>

                    <WideInputGroup>
                      <FormLabel>Repeats for</FormLabel>
                      <Controller
                        render={({ onChange, value }) => (
                          <FormInput
                            error={!!errors?.selectedSlots?.[index]?.count}
                            name={`${defaultName}.count`}
                            type="number"
                            min={0}
                            max={100}
                            value={value || ''}
                            placeholder={value === 0 ? 'Indefinitely' : undefined}
                            onChange={e => {
                              const inputValue = parseInt(e.target.value, 10);
                              onChange(isNaN(inputValue) ? 0 : inputValue);
                            }}
                          />
                        )}
                        control={control}
                        name={`${defaultName}.count`}
                        defaultValue={defaultSlotValues?.count || 0}
                        rules={{ min: 0, max: 100 }}
                      />
                      {errors?.selectedSlots?.[index]?.count && <FormError>{errors?.selectedSlots?.[index]?.count?.message || 'Please enter a valid number from 1 - 100'}</FormError>}
                    </WideInputGroup>

                    <FormLabel>
                      Schedule:{' '}
                      {rruleToText({
                        freq: RRule[frequency],
                        interval: +interval,
                        count: +count,
                        byhour: [+time.split(':')[0]],
                        byminute: [+time.split(':')[1]],
                        bysecond: [0],
                        byweekday: getKeys(selectedDays)
                          ?.filter(day => selectedDays[day]?.isSelected)
                          .map(day => (+selectedDays?.[day]?.nth ? RRule[day].nth(+selectedDays?.[day]?.nth) : RRule[day]))
                      })}{' '}
                      at {time}
                    </FormLabel>
                  </>
                )}
              </AlternateBackground>
            );
          })}

        {!isMultiday && !isOneOff && (
          <>
            <AddNewBtn onClick={addRow} gap="0">
              <AddNewBtnIcon src={icons.plusBlack.childImageSharp.gatsbyImageData.images.fallback.src} />
              Add New Slot
            </AddNewBtn>
            {!isOther && <Divider />}
          </>
        )}

        {!isSlot && !isOther && (
          <>
            <FormLabel bold marginBottom={0}>
              {isOneOff ? 'Date & Availability' : 'Availability'}
            </FormLabel>
            <WideInputGroup marginBottom={0}>
              <FormLabel error={!!errors?.numberOfSlots}>
                {!isMultiday && !isOneOff
                  ? 'Available Spaces (Number of pets per course)'
                  : isOneOff
                  ? 'Availability (Number of spaces available per event)'
                  : 'Availability (Number of spaces available per day)'}
              </FormLabel>
              <Controller
                as={<FormInput error={!!errors?.numberOfSlots} />}
                control={control}
                name={'numberOfSlots'}
                defaultValue={defaultValues?.numberOfSlots}
                rules={{
                  required: true,
                  validate: (value: any) => {
                    if (+value === 0) {
                      return 'Please enter a valid number from 1 - 100';
                    }
                  },
                  pattern: { value: /^[0-9]*$/, message: 'Please enter a valid number' }
                }}
              />
              {errors?.numberOfSlots && <FormError>{errors?.numberOfSlots?.message || 'Slots is required'}</FormError>}
            </WideInputGroup>
          </>
        )}

        {isMultiSlot && (
          <WideInputGroup>
            <FormLabel>Course Length</FormLabel>
            <Controller
              as={<FormInput error={!!errors.slots_recurrence} type={'number'} />}
              control={control}
              name={'slots_recurrence'}
              defaultValue={defaultValues?.slots_recurrence}
              rules={{
                required: true,
                min: 1,
                max: 14
              }}
            />
            {errors.slots_recurrence && <FormError>{errors.slots_recurrence.message || 'slots recurrence is required'}</FormError>}
          </WideInputGroup>
        )}

        {isOneOff && (
          <WideInputGroup>
            <FormLabel>Date</FormLabel>
            <Controller
              as={<FormInput type={'datetime-local'} error={!!errors.selectedSlots?.[0]?.extra_dates?.[0]?.message} />}
              control={control}
              name={'selectedSlots[0].extra_dates[0]'}
              defaultValue={defaultValues?.selectedSlots?.[0]?.extra_dates?.[0]}
              rules={{
                required: true
              }}
            />
            {errors.selectedSlots?.[0]?.extra_dates?.[0] && <FormError>{errors.selectedSlots?.[0]?.extra_dates?.[0]?.message || 'Date is required'}</FormError>}
          </WideInputGroup>
        )}

        {isSlot && (
          <WideInputGroup>
            <FormLabel>Closing Slots</FormLabel>
            <SlotsDaysContainer padding="0 35px 0 95px">
              {THREE_LETTER_WEEK_DAYS.map(day => (
                <FormLabel key={day} bold style={{ flex: 1, textAlign: 'center', flexBasis: '14%', fontWeight: '700' }}>
                  {getThreeLetterWeekDay(day)}
                </FormLabel>
              ))}
            </SlotsDaysContainer>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 20
              }}
            >
              {closingSlotsFieldArray.fields.map((closingSlot, index) => {
                const defaultClosingSlotValues = watchedValues.closingSlots?.[index] || defaultValues?.closingSlots?.[index] || defaultField;
                const defaultName = `closingSlots[${index}]`;

                return (
                  <AlternateBackground
                    key={closingSlot?.id}
                    firstColor={Colors.white}
                    secondColor={Colors.grey}
                    style={{
                      flex: 1
                    }}
                  >
                    <WideInputGroup key={closingSlot?.id} marginBottom={0}>
                      <InputsRow marginBottom={0}>
                        <div
                          style={{
                            alignItems: 'flex-end',
                            display: 'flex'
                          }}
                        >
                          <InputContainer autoHeight marginBottom={0}>
                            <Controller
                              as={<FormInput error={!!errors.closingSlots?.[index]?.time} height={37} fontSize={16} type={'time'} width={90} />}
                              control={control}
                              name={`${defaultName}.time`}
                              defaultValue={defaultClosingSlotValues?.time}
                              rules={{
                                required: true,
                                validate: val => val.split(':')[0] !== '00'
                              }}
                            />
                            {errors.closingSlots?.[index]?.time && <FormError>{errors.closingSlots?.[index]?.time?.message || 'Time is required'}</FormError>}
                          </InputContainer>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flex: 1
                          }}
                        >
                          <div
                            style={{
                              flex: 1,
                              alignItems: 'center',
                              display: 'flex',
                              gap: 10,
                              justifyContent: 'flex-end'
                            }}
                          >
                            <Controller
                              render={({ onChange, value }) => (
                                <FormCheckbox
                                  itemsArray={
                                    WEEK_DAYS_MAP.map(day => ({
                                      id: day,
                                      name: ''
                                    })) || []
                                  }
                                  value={value}
                                  onChange={(newValue: string[]) => {
                                    onChange(newValue);
                                  }}
                                  itemWidth={37}
                                  noFlexWrap={true}
                                  noSpaceArround
                                />
                              )}
                              control={control}
                              name={`${defaultName}.selectedDays`}
                              defaultValue={defaultClosingSlotValues?.selectedDays}
                            />
                          </div>
                        </div>
                        <AddNewBtn
                          marginBottom="0"
                          onClick={() => {
                            if (closingSlotsFieldArray.fields.length === 1) {
                              return;
                            }
                            removeClosingRow(index);
                          }}
                        >
                          <GatsbyImage
                            image={icons.deleteOutlined.childImageSharp.gatsbyImageData}
                            alt="Delete"
                            style={{
                              visibility: closingSlotsFieldArray.fields.length === 1 ? 'hidden' : 'visible',
                              cursor: 'pointer',
                              width: 20,
                              height: 20
                            }}
                          />
                        </AddNewBtn>
                      </InputsRow>
                    </WideInputGroup>
                  </AlternateBackground>
                );
              })}
              <AddNewBtn onClick={addClosingRow} noSpaceArround>
                <GatsbyImage image={icons.plusBlack.childImageSharp.gatsbyImageData} alt="Add" />
                Add New Closing Slot
              </AddNewBtn>
            </div>
          </WideInputGroup>
        )}
      </>
    </ModalLayout>
  );
};

const BranchScheduleModal = ({ branchSchedule }: { branchSchedule?: BranchSchedule }) => {
  const defaultBookingType = branchSchedule?.booking_type || BOOKING_TYPE.SLOT;

  const allSlots = [...(branchSchedule?.slots || defaultSlots)];
  const slots = allSlots.filter(slot => !slot.is_closing);
  const closingSlots = allSlots.filter(slot => slot.is_closing);
  const defaultSelectedSlots = slots.map(slot => {
    const options = RRule.fromString(slot?.time || defaultRRule || '').origOptions;
    const defaultFrequency = RRule.FREQUENCIES[options?.freq!] as ALLOWED_FREQUENCIES;
    const defaultCount = options.count || 0;
    const weekdays = [...([options?.byweekday || []] || [])]?.flat() as { weekday: number; n: number }[];

    const extra_dates = slot?.extra_dates?.map(date => new Date(date).toISOString().slice(0, -3));

    const defaultTime = rruleToReadableTime(options);

    const defaultWeekdays = WEEK_DAYS_MAP.reduce((acc, day, index) => {
      const isSelected = weekdays?.some(({ weekday }) => weekday === index);
      acc[day] = {
        numberOfSlots: slot?.available_by_day?.[day] || branchSchedule?.slots?.[0]?.available || (isSelected ? 1 : 0),
        nth: weekdays?.find(({ weekday }) => weekday === index)?.n || 0,
        isSelected
      };
      return acc;
    }, {} as BranchScheduleForm['selectedSlots'][0]['selectedDays']);

    return {
      selectedDays: defaultWeekdays || [],
      time: defaultTime || '00:00',
      frequency: defaultFrequency || frequencies[0].value,
      interval: options?.interval || 1,
      count: defaultCount || 0,
      extra_dates: extra_dates || []
    };
  });

  const defaultClosingSlots = closingSlots.map(slot => {
    const options = RRule.fromString(slot?.time || defaultRRule || '').origOptions;
    const weekdays = [...([options?.byweekday || []] || [])]?.flat() as { weekday: number; n: number }[];
    const defaultTime = rruleToReadableTime(options);

    return {
      selectedDays: weekdays.map(day => WEEK_DAYS_MAP[day.weekday]),
      time: defaultTime || '00:00'
    };
  });

  const defaultValues: Partial<BranchScheduleForm> = {
    name: branchSchedule?.name || '',
    slots_recurrence: branchSchedule?.slots_recurrence || 1,
    slots_start_date: new Date(branchSchedule?.slots_start_date || new Date()).toISOString().split('T')[0],
    booking_type: defaultBookingType || BOOKING_TYPE.SLOT,
    selectedSlots: defaultSelectedSlots || [],
    numberOfSlots: branchSchedule?.slots?.[0]?.available || 0,
    closingSlots: defaultClosingSlots
  };

  const formOptions = useForm<BranchScheduleForm>({ defaultValues, shouldUnregister: false });

  const { handleSubmit, control } = formOptions;

  const selectedSlotsFieldArray = useFieldArray<BranchScheduleForm['selectedSlots']>({
    control: control,
    name: 'selectedSlots'
  });

  const closingSlotsFieldArray = useFieldArray<BranchScheduleForm['closingSlots']>({
    control: control,
    name: 'closingSlots'
  });

  const [saveBranchSchedule, { data: addedSchedule, loading: addLoading, error: addError }] = useMutation(AddBranchSchedule);

  const [editBranchSchedule, { data: editedSchedule, loading: editLoading, error: editError }] = useMutation(EditBranchSchedule);

  const resolveVariables = (data: BranchScheduleForm) => {
    const { booking_type, numberOfSlots, selectedSlots } = data;
    if (booking_type === BOOKING_TYPE.SLOT) {
      const slots = selectedSlots.map(slot => {
        const { selectedDays, time } = slot;
        const selectedDaysArray = getKeys(selectedDays).filter(day => Number(selectedDays[day]?.numberOfSlots || 0) > 0);
        const weekdays = selectedDaysArray.map(day => RRule[day]);
        const options = {
          freq: RRule.WEEKLY,
          bysecond: [0],
          byhour: [+time.split(':')[0]],
          byminute: [+time.split(':')[1]],
          byweekday: weekdays
        };
        return {
          time: new RRule(options).toString(),
          available_by_day: selectedDaysArray.reduce((acc, day) => {
            acc[day] = Number(selectedDays[day]?.numberOfSlots);
            return acc;
          }, {} as ValueOfArrayKeys<typeof WEEK_DAYS_MAP, number>),
          available: null,
          is_closing: false
        };
      });

      const closingSlots = data.closingSlots
        .filter(day => !!day?.selectedDays?.length)
        .map(slot => {
          const { time, selectedDays } = slot;
          const options = {
            freq: RRule.WEEKLY,
            bysecond: [0],
            byhour: [+time.split(':')[0]],
            byminute: [+time.split(':')[1]],
            byweekday: selectedDays.map(day => RRule[day])
          };
          return {
            time: new RRule(options).toString(),
            available: 0,
            is_closing: true
          };
        });

      return {
        slots: [...slots, ...closingSlots]
      };
    }
    if (booking_type === BOOKING_TYPE.MULTI_SLOT) {
      return {
        slots: selectedSlots.map(slot => {
          const { selectedDays, time } = slot;
          const selectedDaysArray = getKeys(selectedDays).filter(day => !!selectedDays[day]?.isSelected);
          const weekdays = selectedDaysArray.map(day => RRule[day]);
          const options = {
            freq: RRule.WEEKLY,
            bysecond: [0],
            byhour: [+time.split(':')[0]],
            byminute: [+time.split(':')[1]],
            byweekday: weekdays
          };
          return {
            time: new RRule(options).toString(),
            available: Number(data.numberOfSlots || 0)
          };
        }),
        slots_recurrence: Number(data.slots_recurrence || 0)
      };
    }
    if (booking_type === BOOKING_TYPE.MULTI_DAY) {
      const timeRRule = new RRule({
        freq: RRule.HOURLY,
        byhour: [12],
        byminute: [0],
        bysecond: [0]
      });
      return {
        slots: Array(7)
          .fill(0)
          .map(() => ({
            available: +numberOfSlots,
            time: timeRRule.toString()
          }))
      };
    }
    if (booking_type === 'OTHER') {
      return {
        slots: selectedSlots.map(slot => {
          const { selectedDays, time, frequency, interval, count } = slot;
          const selectedDaysArray = getKeys(selectedDays).filter(day => !!selectedDays[day]?.isSelected);
          const weekdays = selectedDaysArray.map(day => {
            const nth = Number(selectedDays[day]?.nth);
            const rrule = RRule[day];
            return nth ? rrule.nth(nth) : rrule;
          });

          const options = {
            freq: RRule[frequency],
            interval: +interval,
            count: +count || undefined,
            byhour: [+time.split(':')[0]],
            byminute: [+time.split(':')[1]],
            bysecond: [0],
            byweekday: weekdays
          };

          return {
            time: new RRule(options).toString(),
            available: Number(data.numberOfSlots || 0)
          };
        })
      };
    }
    if (booking_type === 'ONE_OFF') {
      return {
        slots: [
          {
            time: null,
            available: Number(data.numberOfSlots || 0),
            extra_dates: selectedSlots?.[0]?.extra_dates.map(date => new Date(date).toISOString())
          }
        ]
      };
    }
  };

  const onSubmit = handleSubmit(data => {
    const { name, booking_type } = data;
    const otherVariables = resolveVariables(data)!;
    const variables: Omit<BranchSchedule, 'id'> = {
      name,
      booking_type,
      ...otherVariables
    };

    if (branchSchedule) {
      editBranchSchedule({
        variables: {
          ...variables,
          id: branchSchedule.id
        }
      });

      return;
    }

    saveBranchSchedule({ variables });
  });

  const saveError = !!addError?.message || !!editError?.message;
  const saveLoading = addLoading || editLoading;
  const savedSchedule = addedSchedule?.addBranchSchedule?.id || editedSchedule?.editBranchSchedule?.id;

  useEffect(() => {
    if (savedSchedule) {
      Common.get<() => Promise<void>>(`BranchSchedules.GetBranchSchedules.refetch`)?.();
      ModalDialog.closeModal();
    }
  }, [savedSchedule]);

  return (
    <BranchScheduleModalForm
      formOptions={formOptions}
      saveError={saveError}
      saveLoading={saveLoading}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      selectedSlotsFieldArray={selectedSlotsFieldArray}
      closingSlotsFieldArray={closingSlotsFieldArray}
    />
  );
};

export default BranchScheduleModal;
