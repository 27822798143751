import { useQuery } from '@apollo/client';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import useIcons from '../../../hooks/useIcons';
import { wait } from '../../../utils/helpers';
import { ActionBtn } from '../../../views/Store/styled';
import { Divider } from '../../DrawerBar/styled';
import { ActivityIndicator } from '../Spinner';
import BranchNoteBodyContent from './BranchNoteBodyContent';
import { BranchNoteBodyContainer, BranchNoteHeader, BranchNoteTitle, BranchNotesActions, BranchNotesContainer, ExpandButton } from './styled';
import { BranchNoteBodyContentRef, BranchNoteQueries, BranchNoteType, BranchNotesRef, Note } from './types';

type BranchNotesProps = {
  type: BranchNoteType;
  MedCondId?: string;
  GroomingReportId?: string;
  TrainingReportId?: string;
  WalkingReportId?: string;
  SittingReportId?: string;
  DaycareReportId?: string;
  OrderId?: string;
  AppUserId?: string;
  PetRecordId?: string;
  options?: {
    busUserName?: boolean;
    timestamp?: boolean;
    expandable?: boolean;
    deletable?: boolean;
    maxNotes?: number;
    hideHeader?: boolean;
    bottomDivider?: boolean;
    privateTag?: boolean;
    html?: boolean;
    cancelButton?: boolean;
  };
  styles?: {
    containerStyle?: (isExpanded: boolean) => React.CSSProperties;
    itemStyle?: (id: string, isExpanded: boolean) => React.CSSProperties;
  };
};

const DEFAULT_MAX_NOTES = 100 as const;

const BranchNotes = forwardRef<BranchNotesRef, BranchNotesProps>(
  (
    {
      type,
      AppUserId,
      DaycareReportId,
      MedCondId,
      OrderId,
      PetRecordId,
      SittingReportId,
      WalkingReportId,
      GroomingReportId,
      TrainingReportId,
      options = {
        busUserName: true,
        timestamp: true,
        expandable: true,
        deletable: true,
        maxNotes: DEFAULT_MAX_NOTES,
        hideHeader: false,
        bottomDivider: true,
        privateTag: false,
        cancelButton: false,
        html: false
      },
      styles: { containerStyle, itemStyle } = { containerStyle: () => ({}), itemStyle: () => ({}) }
    },
    ref
  ) => {
    // const [isExpanded, setIsExpanded] = useState(!options.expandable);
    const [isExpanded, setIsExpanded] = useState(true);
    const icons = useIcons();
    const expandIcon = icons?.chevronDown?.childImageSharp?.gatsbyImageData;
    const query = BranchNoteQueries[type];

    const idVariables = {
      ...(AppUserId && { AppUserId: [AppUserId] }),
      ...(DaycareReportId && { DaycareReportId: [DaycareReportId] }),
      ...(MedCondId && { MedCondId: [MedCondId] }),
      ...(OrderId && { OrderId: [OrderId] }),
      ...(PetRecordId && { PetRecordId: [PetRecordId] }),
      ...(SittingReportId && { SittingReportId: [SittingReportId] }),
      ...(WalkingReportId && { WalkingReportId: [WalkingReportId] }),
      ...(GroomingReportId && { GroomingReportId: [GroomingReportId] }),
      ...(TrainingReportId && { TrainingReportId: [TrainingReportId] })
    };

    const {
      data: branchNoteData = {},
      loading: loadingBranchNotes,
      refetch: refetchBranchNotes
    } = useQuery<Record<string, Note[]>>(query, {
      variables: {
        ...idVariables
      },
      skip: !Object.keys(idVariables).length,
      fetchPolicy: 'cache-and-network'
    });

    const branchNotes = branchNoteData[Object.keys(branchNoteData)[0]];

    const newTextAreaRef = useRef<BranchNoteBodyContentRef>(null);

    const { setIsEditing, textAreaFocus } = newTextAreaRef?.current || {};

    const addNote = () => {
      setIsEditing?.(true);
      setIsExpanded(true);
      wait(100).then(() => {
        textAreaFocus?.();
      });
    };

    useImperativeHandle(ref, () => ({
      addNote
    }));

    useEffect(() => {
      if (isExpanded) {
        return;
      }
      setIsExpanded(!branchNotes?.length && !!options.expandable);
    }, [branchNotes?.length]);

    const lessThanMaxNotes = (branchNotes?.length || 0) < (options?.maxNotes || DEFAULT_MAX_NOTES);

    return (
      <BranchNotesContainer style={containerStyle?.(isExpanded)}>
        {/* {!options?.hideHeader && (
          <BranchNoteHeader isExpanded={isExpanded}>
            {loadingBranchNotes && (
              <>
                <BranchNoteTitle>Notes</BranchNoteTitle>
                <ActivityIndicator size={20} />
              </>
            )}
            {!loadingBranchNotes && (
              <>
                <BranchNoteTitle>{branchNotes?.length ? 'Notes' : 'No notes'}</BranchNoteTitle>
                <BranchNotesActions>
                  {!!branchNotes?.length && options.expandable && (
                    <ActionBtn onClick={() => setIsExpanded(!isExpanded)} noMargin noBackgroundColor>
                      <ExpandButton isExpanded={isExpanded}>
                        <GatsbyImage image={expandIcon} alt="Expand" />
                      </ExpandButton>
                    </ActionBtn>
                  )}
                </BranchNotesActions>
              </>
            )}
          </BranchNoteHeader>
        )} */}
        {!loadingBranchNotes && lessThanMaxNotes && (
          <BranchNoteBodyContainer isExpanded={isExpanded} style={itemStyle?.('new', isExpanded)}>
            <BranchNoteBodyContent
              branchNote={null}
              idVariables={idVariables}
              refetchBranchNotes={refetchBranchNotes}
              ref={newTextAreaRef}
              options={{
                html: options?.html,
                cancelButton: options?.cancelButton
              }}
            />
          </BranchNoteBodyContainer>
        )}

        {!!branchNotes?.length &&
          branchNotes.map((branchNote, index) => (
            <BranchNoteBodyContainer isExpanded={isExpanded} key={branchNote?.id} style={itemStyle?.(branchNote?.id, isExpanded)}>
              <BranchNoteBodyContent
                branchNote={branchNote}
                idVariables={idVariables}
                refetchBranchNotes={refetchBranchNotes}
                options={{
                  ...options,
                  divider: index !== branchNotes.length - 1 && options.bottomDivider
                }}
              />
            </BranchNoteBodyContainer>
          ))}
        {/* {!!branchNotes?.length && lessThanMaxNotes && isExpanded && options?.bottomDivider && <Divider />} */}
      </BranchNotesContainer>
    );
  }
);

export default BranchNotes;
