import { GatsbyImage } from 'gatsby-plugin-image';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import CalendarRange, { CalendarRangeRef, getRange } from '../../../../components/Calendar/CalendarRange';
import { CounterBtn } from '../../../../components/Calendar/styled';
import ModalDialog from '../../../../components/Modal/ModalDialog';
import useIcons from '../../../../hooks/useIcons';
import useURLSearchParams from '../../../../hooks/useURLSearchParams';
import { formatDate, toUtc } from '../../../../utils/dates';
import { DateContainer, NavigationButtonsContainer } from './styles';
import { MenuButton } from '../../../../components/Shared/Menus/OptionDropdown/styled';
import debounce from '../../../../utils/debounce';

const BookingsNavigation = () => {
  const icons = useIcons();
  const [urlDate, setUrlDate] = useURLSearchParams('date');
  const selectedDate = urlDate ? new Date(`${urlDate} 00:00:00`) : new Date();
  const [date, setDate] = useState<Date>(selectedDate);

  useEffect(() => {
    setDate(selectedDate);
  }, [urlDate]);

  const selectedUTC = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));

  const lessIcon = icons.lessSVG?.publicURL;
  const moreIcon = icons.moreSVG?.publicURL;
  const rangeRef = useRef<CalendarRangeRef>(null);

  const increment = () => {
    const currentDate = new Date(selectedUTC);
    currentDate.setUTCDate(currentDate.getUTCDate() + 1);
    const utcDate = currentDate.toISOString().slice(0, 10);
    setDate(new Date(`${utcDate} 00:00:00`));
    debounce(() => setUrlDate(utcDate), 500);
  };

  const decrement = () => {
    const currentDate = new Date(selectedUTC);
    currentDate.setUTCDate(currentDate.getUTCDate() - 1);
    const utcDate = currentDate.toISOString().slice(0, 10);
    setDate(new Date(`${utcDate} 00:00:00`));
    debounce(() => setUrlDate(utcDate), 500);
  };

  return (
    <Fragment>
      <NavigationButtonsContainer>
        <MenuButton bgImage={lessIcon} width="21px" height="21px" onClick={decrement} />
        <MenuButton bgImage={moreIcon} width="21px" height="21px" onClick={increment} />
      </NavigationButtonsContainer>

      <DateContainer
        onClick={() => {
          ModalDialog.openModal({
            noHeader: true,
            overlayClose: true,
            autoHeight: true,
            content: () => (
              <CalendarRange
                ref={rangeRef}
                currentCalendarLength={1}
                useDatePicker={true}
                setUTCDate={date => {
                  const currDate = date.toISOString().slice(0, 10);
                  setUrlDate(currDate);
                  setDate(new Date(`${currDate} 00:00:00`));
                  ModalDialog.closeModal();
                }}
                defaultValues={getRange(date, 1)}
              />
            )
          });
        }}
      >
        {formatDate(new Date(date))}
      </DateContainer>
    </Fragment>
  );
};

export default BookingsNavigation;
