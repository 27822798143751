import React, { useEffect } from 'react';
import { BusUserProfile } from '../../../components/Profile/types';
import InfiniteList from '../../../components/Shared/InfiniteList/InfiniteList';
import usePaginatedQuery from '../../../hooks/usePaginatedQuery';
import { GetBranchBusUsers } from '../../../queries';
import { RecordsContainer } from '../../styled';
import Common from '../Common';
import BusUserRecord from './BusUserRecord';
import ToolBar from '../../../components/Shared/ToolBar/ToolBar';
import OptionDropdown from '../../../components/Shared/Menus/OptionDropdown/OptionDropdown';
import { OPTION_DROPDOWN_MENU_BUTTON_TYPES, OPTION_DROPDOWN_TYPES } from '../../../components/Shared/Menus/OptionDropdown/types';
import ModalDialog from '../../../components/Modal/ModalDialog';
import BusUserModal from './BusUserModal';

const BusUsers = () => {
  const [[hasMoreItems, setHasMoreItems], queryResult] = usePaginatedQuery<BusUserProfile[]>({ query: GetBranchBusUsers, otherParams: { fetchPolicy: 'cache-and-network' } });

  const { data: { getBranchBusUsers: busUsers = [] } = {}, loading, fetchMore, refetch } = queryResult;

  useEffect(() => {
    Common.set(`BusUsers.GetBranchBusUsers.refetch`, () => {
      refetch?.().then(() => setHasMoreItems(true));
    });
  }, []);

  return (
    <>
      <ToolBar
        displayString={'Staff'}
        customizedToolComponent={
          <OptionDropdown
            menuButtonType={OPTION_DROPDOWN_MENU_BUTTON_TYPES.PLUS_BLACK_SVG}
            width="28px"
            height="28px"
            noApplyButton
            options={[
              {
                id: 'add-bus-user',
                optionType: OPTION_DROPDOWN_TYPES.BUTTONS,
                items: [
                  {
                    name: 'Add',
                    value: 'add-bus-user',
                    onClick: () =>
                      ModalDialog.openModal({
                        title: 'Staff',
                        content: () => <BusUserModal />
                      })
                  }
                ]
              }
            ]}
          />
        }
      />
      <InfiniteList
        list={busUsers}
        itemRenderer={(busUser: BusUserProfile) => <BusUserRecord busUser={busUser} />}
        hasMoreItems={hasMoreItems}
        loading={loading}
        fetchMore={fetchMore}
        offset={busUsers?.length}
        setHasMoreItems={setHasMoreItems}
        paddingL={20}
        paddingR={20}
      />
    </>
  );
};

export default BusUsers;
