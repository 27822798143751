import React, { useState } from 'react';
import FormTree, { removeFormTrunkWithOneTwig, TwigComponentProps } from '../../../../components/Shared/FormTree';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { FormError, FormLabel, FormTextArea, InputContainer, WideInputGroup } from '../../../../components/Shared/Forms/Forms';
import transformLumpsState from './transformMedicalNoteLumpsState';

const RowComponent = ({ data, errors, control, idx, twig_idx }: TwigComponentProps) => {
  return (
    <WideInputGroup marginBottom={0}>
      <InputContainer>
        <FormLabel>Details</FormLabel>
        <Controller
          as={<FormTextArea error={errors.skin_coat?.lumps?.lumps?.[idx]?.details} />}
          control={control}
          name={`skin_coat.lumps.lumps.${idx}.details`}
          defaultValue={data?.details || ''}
          rules={{ required: false }}
          rows={3}
        />
        {!!errors.skin_coat?.lumps?.lumps?.[idx]?.details && <FormError>{errors.skin_coat?.lumps?.lumps?.[idx]?.details?.message || 'skin coat details is invalid'}</FormError>}
      </InputContainer>
    </WideInputGroup>
  );
};

type LumpsProps = {
  control: Control;
  errors: FieldErrors;
  defaultValue: any;
};

const Lumps = ({ control, errors, defaultValue }: LumpsProps): JSX.Element => {
  const [customTypes, setCustomTypes] = useState<any>(defaultValue ? defaultValue : [{ details: '', size: 0 }]);

  const addNewType = () => {
    const currentState = transformLumpsState(control.getValues());
    setCustomTypes([...currentState, { details: '', size: 0 }]);
  };

  const handleRemoveType = (type, idx) => {
    const filteredTypes = customTypes.filter((customType, i) => i !== idx);
    removeFormTrunkWithOneTwig(idx, customTypes.length, control, getFieldNames);
    setCustomTypes(filteredTypes);
  };

  return (
    <FormTree
      title={'Lumps'}
      newTrunkTitle={'Add New Lump'}
      newTwigTitle={''}
      trunks={customTypes.map((type: any, idx: number) => ({
        heading: `Lump (${idx + 1})`,
        title: 'Size (mm)',
        value: type?.size,
        twigs: [{ details: type.details }],
        formName: `skin_coat.lumps.lumps.${idx}.size`,
        formInputType: 'text',
        rules: { minLength: 1 }
      }))}
      TwigComponent={RowComponent}
      control={control}
      errors={errors}
      addNewTrunk={addNewType}
      handleRemoveTrunk={handleRemoveType}
    />
  );
};

export default Lumps;

const getFieldNames = (idx: number, rowIdx: number) => [`skin_coat.lumps.lumps.${idx}.size`, `skin_coat.lumps.lumps.${idx}.details`];
