import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import { useEffect } from 'react';

const JSONParse = (value: string) => {
  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
};

const JSONStringify = (value: Generic) => {
  try {
    return JSON.stringify(value);
  } catch (e) {
    return value.toString();
  }
};

type Generic = string[] | string | Record<string, unknown> | Record<string, unknown>[];

const useURLSearchParams = <T extends Generic = string>(key: string, defaultValue?: string[]): [T, (newValue: T) => void] => {
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const value = query.get(key) ? JSONParse(query.get(key) as string) : '';

  const setValue = (newValue: T) => {
    const query = new URLSearchParams(location.search);
    const value = JSONStringify(newValue);
    query.set(key, value);
    navigate(`?${query.toString()}`, { replace: true });
  };

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue as T);
    }
  }, []);

  return [value, setValue];
};

export default useURLSearchParams;
