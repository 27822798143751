import styled from 'styled-components';

export const PetsListContainer = styled.div`
  width: 600px;
  max-width: 600px;
  overflow-y: auto;
`;

export const RouteHeader = styled.div`
  display: flex;
  padding: 24px 12px;
  gap: 12px;
  max-width: 100%;
  min-width: 100%;
`;

export const RouteColorTag = styled.div<{ color: string }>`
  width: 3px;
  min-width: 4px;
  border-radius: 5px;
  background-color: ${props => props.color};
`;

export const DailyRoute = styled.div<{ column?: boolean; width?: string; gap?: string }>`
  display: flex;
  flex-direction: ${props => (props.column ? 'column' : 'row')};
  padding: 12px 16px;
  width: ${props => props.width || 'auto'};
  gap: ${props => props.gap || '12px'};
  align-items: center;
`;

export const RouteColorStrip = styled.div<{ color: string }>`
  width: 4px;
  min-width: 4px;
  border-radius: 5px;
  background-color: ${props => props.color};
  height: 45px;
`;
