import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useCallback, useEffect, useRef } from 'react';
import ModalDialog from '../components/Modal/ModalDialog';
import { AddBranchAppointmentItemTag, GetBranchAppointmentTags, GetBranchAppointments } from '../queries';
import client from '../utils/client';
import { RequireAtLeastOne } from '../utils/helpers';
import BookingTagsModal from '../views/Bookings/BookingTagsModal';
import { BOOKING_STATUS_TYPES, BOOKING_TYPE, Booking } from '../views/Bookings/types';
import { checkAddAppointmentTagCapacity } from '../views/Bookings/utils';
import { BranchAppointmentTag } from '../views/Store/BranchTags/types';
import Common from '../views/Store/Common';

type useBookingTagsOptionsProps = RequireAtLeastOne<
  {
    quickTags?: BranchAppointmentTag[];
    appointments: Booking[];
    loadTags?: boolean;
    refetchAppointmentsAfterTag?: boolean;
    afterTag?: (newAppointments: Booking[]) => void;
    onClickAction?: () => void;
    includeQuickTags?: boolean;
  },
  'quickTags' | 'loadTags'
>;

export default function useBookingTagsOptions({
  quickTags,
  appointments,
  loadTags = false,
  refetchAppointmentsAfterTag,
  afterTag,
  onClickAction,
  includeQuickTags = true
}: useBookingTagsOptionsProps) {
  const allAppointmentsTags = appointments?.flatMap(appointment => appointment?.BranchAppointmentTags);
  const allAppointmentsUniqueTags = [...new Set(allAppointmentsTags.map(item => item?.id))].map(id => allAppointmentsTags?.find(item => item?.id === id)!);
  const existingTagsIds = allAppointmentsUniqueTags?.map(tag => tag?.id);

  const [addTag, { data: addedTag, loading: loadingAddPetTag }] = useMutation(AddBranchAppointmentItemTag);

  const [refetchTagsById, { data: refetchedTags, loading: loadingRefetchTags }] = useLazyQuery<{ getBranchAppointmentTags: BranchAppointmentTag[] }>(GetBranchAppointmentTags, {
    fetchPolicy: 'cache-first'
  });

  const [getTags, { data: { getBranchAppointmentTags: tags = [] } = {} }] = useLazyQuery<{ getBranchAppointmentTags: BranchAppointmentTag[] }>(GetBranchAppointmentTags, {
    fetchPolicy: 'cache-first',
    variables: { offset: 0, limit: 1000, quick_action: true }
  });

  useEffect(() => {
    if (loadTags && !quickTags?.length) {
      getTags();
    }
  }, [loadTags, getTags]);

  const currentTagId = useRef<string>('');

  const handleQuickTagClick = useCallback(
    (tag: BranchAppointmentTag) => {
      checkAddAppointmentTagCapacity({
        handleAddTag: () => {
          addTag({
            variables: {
              AppointmentId: appointments.map(({ id }) => id),
              BranchAppointmentTagId: [tag.id]
            }
          });
          currentTagId.current = tag.id;
        },
        appointments,
        tags: [tag]
      });
    },
    [addTag, appointments]
  );

  useEffect(() => {
    if (addedTag) {
      refetchTagsById({ variables: { id: [currentTagId.current] } });
    }
  }, [addedTag, refetchTagsById]);

  const refetchAppointments = Common.get<() => Promise<{ data: { getBranchAppointments: Booking[] } }>>(`Bookings.GetBranchAppointments.refetch`);

  useEffect(() => {
    if (!refetchedTags) {
      return;
    }

    (async () => {
      const { data: { getBranchAppointments: newAppointments = [] } = {} } = await client.query<{ getBranchAppointments: Booking[] }>({
        query: GetBranchAppointments,
        variables: {
          appointment_id: appointments.map(({ id }) => id),
          offset: 0,
          limit: 1000,
          status: [BOOKING_STATUS_TYPES.CONFIRMED],
          booking_type: [BOOKING_TYPE.SLOT, BOOKING_TYPE.MULTI_SLOT, BOOKING_TYPE.MULTI_DAY],
          requisite_queries: ['appointment_id'],
          alternative_queries: []
        }
      });
      afterTag?.(newAppointments);
    })();

    if (refetchAppointmentsAfterTag && refetchAppointments) {
      refetchAppointments();
    }
  }, [refetchedTags, refetchAppointmentsAfterTag, refetchAppointments, afterTag, appointments]);

  const items = [
    {
      name: existingTagsIds?.length ? 'Tags' : 'Tag Booking',
      value: 'addTag',
      onClick: () => {
        ModalDialog.openModal({
          content: () => <BookingTagsModal appointments={appointments} refetchAppointmentsAfterTag={refetchAppointmentsAfterTag} afterTag={afterTag} />,
          title: `Tag`,
          onCloseBySave: onClickAction,
          isMini: true
        });
      }
    },
    ...(includeQuickTags
      ? [
          ...(quickTags || tags || [])
            ?.filter(({ id }) => !existingTagsIds.includes(id))
            .map(tag => ({
              name: tag.name,
              value: tag.id,
              onClick: () => {
                handleQuickTagClick(tag);
                onClickAction?.();
              }
            }))
        ]
      : [])
  ].filter(Boolean) as { name: string; value: string; onClick: () => void }[];

  const loading = loadingAddPetTag || loadingRefetchTags;
  return { items, loading };
}
