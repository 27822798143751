import { useMutation } from '@apollo/client';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Notifier from '../../../../Notifier';
import ModalDialog, { ModalLayout } from '../../../../components/Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../../../../components/Modal/styled';
import { FormButtonsContainer, FormError, FormInput, FormLabel, FormSubmitButton, InputGroup, InputsWrapper } from '../../../../components/Shared/Forms/Forms';
import { Container } from '../../../../components/Shared/Shared';
import { UpdateOrderAmount } from '../../../../queries';
import { getBranchCurrencySymbol } from '../../../../utils/getBranchCurrencySymbol';
import { isValidAmount } from '../../../../utils/validators';

const AdjustPaymentModal = ({ orderId, oldAmount }) => {
  const [updateAmount, { data: { updateOrderAmount: order } = {}, loading, error }] = useMutation(UpdateOrderAmount);
  const { control, handleSubmit, errors } = useForm();
  const onChange = args => ({ value: args[0].target.value });

  const onSubmit = handleSubmit(form => {
    updateAmount({ variables: { orderId: orderId, amount: form.amount } });
  });

  useEffect(() => {
    if (order) {
      Notifier.success({ message: 'Payment Adjusted', description: 'Payment has been adjusted successfuly' });

      ModalDialog.closeModal();
    }
  }, [order]);

  return (
    <ModalLayout
      compact
      buttons={[
        <FormSubmitButton error={error} loading={loading} onClick={() => onSubmit()}>
          Confirm Adjustment
        </FormSubmitButton>
      ]}
    >
      <InputGroup>
        <FormLabel>Current Amount</FormLabel>
        <FormInput disabled value={getBranchCurrencySymbol() + ' ' + oldAmount} />
      </InputGroup>
      <InputGroup>
        <FormLabel error={errors.amount}>New Amount</FormLabel>
        <Controller
          as={<FormInput error={errors.amount} type={'number'} />}
          control={control}
          name={'amount'}
          onChange={onChange}
          defaultValue={''}
          rules={{
            required: true,
            pattern: {
              value: isValidAmount,
              message: 'Invalid Amount'
            }
          }}
        />
        {errors.amount && <FormError>{errors.amount.message || 'New Amount is required'}</FormError>}
      </InputGroup>
    </ModalLayout>
  );
};

export default AdjustPaymentModal;
