import React from 'react';
import usePaginatedQuery from '../../../hooks/usePaginatedQuery';
import SubscriptionsList from '../../Subscriptions/SubscriptionsList';
import { SUBSCRIPTION_COLLECTION_STATUS_TYPES, SUBSCRIPTION_STATUS_TYPES, Subscription } from '../../Subscriptions/types';
import { CurrentPeriod } from './types';
import { GetBranchOrderSubscriptionsViews } from '../../../queries';

const BranchBillingSubscriptions = ({ period, variables }: { period: CurrentPeriod; variables: Record<string, any> }) => {
  const paginatedQueryResult = usePaginatedQuery<{ views: Subscription[]; count: number }>({
    query: GetBranchOrderSubscriptionsViews,
    defaultStatus: [SUBSCRIPTION_STATUS_TYPES.CONFIRMED, SUBSCRIPTION_STATUS_TYPES.CANCELED, SUBSCRIPTION_STATUS_TYPES.REQUESTED],
    limit: 20,
    otherVariables: {
      OrderSubscriptionRepeat_payment_invoice_date_from: period.start,
      OrderSubscriptionRepeat_payment_invoice_date_to: period.end,
      collectionStatus: [SUBSCRIPTION_COLLECTION_STATUS_TYPES.ACTIVE, SUBSCRIPTION_COLLECTION_STATUS_TYPES.PAUSED],
      requisite_queries: [],
      alternative_queries: [],
      ...variables
    }
  });

  return <SubscriptionsList paginatedQueryResult={paginatedQueryResult} showTotal={{ orderTotal: true }} />;
};

export default BranchBillingSubscriptions;
