import React, { Fragment, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { VerticallyCenteredLoader } from '../../components/Shared//Spinner';
import { FormError, FormInput, FormLabel, FormSelect, FormSubmitButton, InputContainer, WideInputGroup } from '../../components/Shared/Forms/Forms';
import { Controller, useForm } from 'react-hook-form';
import { ColorCircle, PatternCircle } from '../../components/Shared//Shared';
import { Customization, Product } from '../Store/types';
import { isValidURL } from '../../utils/validators';
import { getSelectedCustomizations } from '../../components/Shared//Modals/NewBookingModal/utils';
import { getKeys } from '../../utils/helpers';
import { Discount, OrderVoucher } from '../Store/Discounts/types';
import Select from '../../components/Shared/Forms/Select';
import { FormCheckbox, InputGroup } from '../../components/Shared/Forms/Forms';
import DurationSelector, { convertDurationToMinutes } from '../../components/Shared/DurationSelector';
import { DatesContainer } from '../../components/Calendar/Modals/styled';
import { Booking, BOOKING_TYPE } from './types';
import ModalDialog, { ModalLayout } from '../../components/Modal/ModalDialog';
import { GetAllBranchDiscounts, GetAllServices, GetBranchDiscounts, GetBranchOrderVouchers, UpdateServiceOrderAppointments } from '../../queries';
import { getNewTimestampUntil } from './components/BookingsDrawer/BookingRescheduleModal';

type AppointmentFormValues = {
  overrideDuration: boolean;
  overriddenDuration: number;
  productId: string;
  voucherId?: string;
  discountId?: string;
  timestamp: string;
  timestamp_until: string;
  time: string;
  customizations?: {
    [key: string]: string;
  };
  overrideTotal?: boolean;
  overriddenTotal?: number;
  notifications_disabled: boolean;
};

const EditBookingModal = ({ appointments, onUpdate }: { appointments: Booking[]; onUpdate?: () => void }) => {
  const appointment = appointments[0];
  const defaultCustomizations = appointment?.OrderItem.customizations || [];

  const order = appointment?.OrderItem?.Order;

  const { data: { getBranchOrderVouchers: orderVouchers = [] } = {}, loading: loadingOrderVouchers } = useQuery<{
    getBranchOrderVouchers: OrderVoucher[];
  }>(GetBranchOrderVouchers, { variables: { OrderId: order?.id }, fetchPolicy: 'cache-and-network', skip: !order?.id });

  const { timestamp, timestamp_until } = appointment;

  const selectedAppointmentDate = new Date(timestamp).toISOString().slice(0, 10);
  const selectedAppointmentTime = new Date(timestamp).toUTCString().slice(17, 22);
  const multiDayEndDate = new Date(timestamp_until).toISOString().slice(0, 10);

  const defaultValues: AppointmentFormValues = {
    productId: appointment?.OrderItem?.item?.id || '',
    voucherId: orderVouchers[0]?.Voucher?.id || '',
    discountId: orderVouchers[0]?.Voucher?.Discount?.id || '',
    overrideTotal: false,
    overrideDuration: false,
    overriddenDuration: appointment?.OrderItem?.item?.duration || 0,
    overriddenTotal: 0,
    customizations: defaultCustomizations?.flatMap?.(({ fields }) => getKeys(fields).reduce((acc, key) => ({ ...acc, [key]: fields[key]?.title || '' }), {}))[0] || {},
    timestamp: selectedAppointmentDate,
    timestamp_until: multiDayEndDate,
    time: selectedAppointmentTime,
    notifications_disabled: false
  };

  const formOptions = useForm<AppointmentFormValues>({ defaultValues });

  const { control, errors, handleSubmit, watch, setValue } = formOptions;

  const watchedValues = watch();

  const { data: { getProducts: allServices = [] } = {}, loading: loadingAppointments } = useQuery<{ getProducts: Product[] }>(GetAllServices, {
    fetchPolicy: 'cache-and-network'
  });

  const services = allServices.filter(({ booking_type }) => booking_type === appointment?.OrderItem?.item?.booking_type);

  useEffect(() => {
    if (!watchedValues.productId) {
      return;
    }

    if (watchedValues.productId === defaultValues.productId) {
      setValue('customizations', defaultValues.customizations);
    } else {
      setValue('customizations', {});
    }
  }, [watchedValues.productId]);

  const selectedService = services?.find(({ id }) => id === watchedValues.productId);

  const customizationsMap = selectedService?.customizations || {};

  const titlesArray: string[] = Object.keys(customizationsMap)
    .slice()
    .sort((a, b) => a.localeCompare(b));

  const customizationsList: Customization[] = titlesArray.map(property =>
    customizationsMap?.[property]?.map(item => ({
      ...item,
      id: item?.title,
      name: item?.title,
      Indicator: item?.meta?.hex ? <ColorCircle color={item?.meta?.hex} /> : item?.meta?.url && isValidURL.test(item?.meta?.url) && <PatternCircle src={`${item?.meta?.url}`} alt={item?.title} />
    }))
  );

  const { data: { getBranchDiscounts: discounts = [] } = {}, loading: loadingDiscounts } = useQuery<{ getBranchDiscounts: Discount[] }>(GetAllBranchDiscounts, {
    fetchPolicy: 'cache-and-network'
  });

  const { data: { getBranchDiscounts: [{ Vouchers: vouchers = [] } = {}] = [] } = {} } = useQuery<{ getBranchDiscounts: Discount[] }>(GetBranchDiscounts, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: [watchedValues.discountId]
    },
    skip: !watchedValues.discountId
  });

  const loading = loadingAppointments || loadingDiscounts || loadingOrderVouchers;

  const [updateAppointments, { loading: loadingUpdateAppointments, data: { updateServiceOrderAppointments: updatedAppointments = {} } = {} }] = useMutation(UpdateServiceOrderAppointments, {
    refetchQueries: ['getBranchOrderVouchers', 'getBranchProductsAppointments', 'getBranchCalendarAppointmentsViews', 'getBranchAppointmentsViews', 'getBranchServiceOrdersViews']
  });

  const handleEdit = handleSubmit(form => {
    const { productId, overrideTotal, overriddenTotal, voucherId, customizations, overriddenDuration, overrideDuration, time, timestamp, timestamp_until, notifications_disabled } = form;

    const selectedCustomizations = getSelectedCustomizations({
      customizations: customizations || {},
      customizationsMap
    });

    const newCustomizations = Object.keys(customizations || {}).length ? [selectedCustomizations] : null;
    const newTimestamp = new Date(timestamp);
    newTimestamp.setUTCHours(Number(time?.split(':')[0] || 12), Number(time?.split(':')[1] || 0), 0, 0);
    const newTimestampUntil = getNewTimestampUntil({
      newTimestamp: timestamp,
      timestamp: timestamp.split('T')[0],
      timestampUntil: timestamp_until?.split('T')?.[0]
    });

    const variables = {
      id: appointments.map(({ id }) => id),
      overriddenTotal: overrideTotal ? Number(overriddenTotal) : null,
      overriddenDuration: overrideDuration ? convertDurationToMinutes(overriddenDuration) : null,
      ProductId: productId,
      customizations: newCustomizations,
      VoucherId: (overrideTotal ? null : voucherId ? [voucherId] : []) || [],
      timestamp: newTimestamp.toISOString(),
      timestamp_until: newTimestampUntil?.toISOString(),
      notifications_disabled
    };

    updateAppointments({ variables });
  });

  useEffect(() => {
    if (updatedAppointments?.[0]?.id) {
      onUpdate?.();

      ModalDialog.closeModal();
    }
  }, [updatedAppointments?.[0]?.id]);

  const multiDay = appointment?.OrderItem?.item?.booking_type === BOOKING_TYPE.MULTI_DAY;

  const discountsOptions = [{ value: '', label: 'No discount' }, ...discounts.map(discount => ({ value: discount.id, label: discount.name }))];

  const vouchersOptions = vouchers.map(voucher => ({ value: voucher.id, label: voucher.code }));

  return (
    <ModalLayout
      buttons={[
        <FormSubmitButton loading={loadingUpdateAppointments} onClick={handleEdit}>
          Save
        </FormSubmitButton>
      ]}
      compact
    >
      <InputGroup>
        <FormLabel error={errors?.productId?.message}>Service</FormLabel>
        <Controller
          render={({ onChange, value }) => (
            <Select
              onChange={v => {
                onChange(v?.value);
                setValue('customizations', {});
              }}
              value={value ? { value: value, label: selectedService?.name } : { value: '', label: 'Select a service' }}
              options={services.map(product => ({
                value: product.id,
                label: product.name
              }))}
            />
          )}
          control={control}
          name={'productId'}
          defaultValue={defaultValues.productId}
          rules={{
            required: true,
            validate: (value: string) => {
              if (value === '') {
                return 'Please select a service';
              }
            }
          }}
        />
        {errors?.productId && <FormError>{errors?.productId.message || 'Please select a service'}</FormError>}
      </InputGroup>
      {!!Object.keys(customizationsMap).length && (
        <InputGroup>
          <FormLabel error={Object.values(errors?.customizations || {})?.find(c => c?.message)?.message}>Variations</FormLabel>
          {loading && <VerticallyCenteredLoader size={15} />}
          {!loading && (
            <Fragment>
              {titlesArray?.map((title, index) => {
                const options = customizationsList[index].map(customization => ({
                  value: customization.name,
                  label: customization.name
                }));

                return (
                  <WideInputGroup key={index}>
                    <Controller
                      control={control}
                      name={`customizations.${title}`}
                      render={({ onChange, value }) => (
                        <>
                          <FormLabel>{title}</FormLabel>
                          <Select
                            name={`customizations.${title}`}
                            onChange={e => {
                              onChange(e?.value);
                            }}
                            value={options.find(option => option.value === value) || { value: '', label: 'Select a variation' }}
                            options={options}
                          />
                        </>
                      )}
                      rules={{
                        required: true,
                        validate: (value: any) => {
                          if (value.length === 0) {
                            return 'Please select a variation';
                          }
                        }
                      }}
                      defaultValue={defaultValues.customizations?.[title]}
                    />
                  </WideInputGroup>
                );
              })}
            </Fragment>
          )}
          {errors?.customizations && <FormError>{Object.values(errors?.customizations || {})?.find(c => c?.message)?.message || 'Variations are required'}</FormError>}
        </InputGroup>
      )}

      <DatesContainer>
        <WideInputGroup>
          <FormLabel error={errors?.timestamp?.message}>{multiDay ? 'Start Date' : 'New Date'}</FormLabel>
          <Controller
            as={<FormInput error={errors?.timestamp?.message} type={'date'} name={`date`} />}
            control={control}
            name={`timestamp`}
            defaultValue={defaultValues.timestamp}
            rules={{
              required: {
                value: true,
                message: 'Please select a date'
              }
            }}
          />
          {errors?.timestamp && <FormError>{errors?.timestamp?.message || 'Please enter a valid date'}</FormError>}
        </WideInputGroup>

        {multiDay && (
          <WideInputGroup>
            <FormLabel error={errors?.timestamp_until?.message}>End Date</FormLabel>
            <Controller
              control={control}
              name="timestamp_until"
              defaultValue={defaultValues.timestamp_until}
              render={({ onChange, value }) => <FormInput value={value} onChange={onChange} error={errors?.timestamp_until?.message} type="date" />}
            />
            {errors?.timestamp_until && <FormError>{errors?.timestamp_until?.message || 'Please enter a valid date'}</FormError>}
          </WideInputGroup>
        )}

        {!multiDay && (
          <WideInputGroup>
            <FormLabel error={errors?.time?.message}>New Time</FormLabel>
            <Controller
              as={<FormInput error={errors?.time?.message} type={'time'} />}
              control={control}
              name={`time`}
              defaultValue={defaultValues.time}
              rules={{
                required: {
                  value: true,
                  message: 'Please select a time'
                }
              }}
            />
            {errors?.time && <FormError>{errors?.time.message}</FormError>}
          </WideInputGroup>
        )}
      </DatesContainer>

      <InputGroup>
        <Controller
          render={({ onChange, value }) => (
            <FormCheckbox
              itemsArray={[
                {
                  name: 'Override Total',
                  id: 'overrideTotal'
                }
              ]}
              onChange={newVal => {
                onChange(!!newVal[0]);
              }}
              value={value ? ['overrideTotal'] : []}
              column
              fontSize={14}
            />
          )}
          control={control}
          name={`overrideTotal`}
          defaultValue={defaultValues.overrideTotal}
        />
      </InputGroup>

      <InputGroup>
        {watchedValues.overrideTotal && (
          <InputContainer marginBottom={20}>
            <FormLabel error={errors?.overriddenTotal?.message}>Total</FormLabel>
            <Controller
              as={<FormInput error={errors?.overriddenTotal?.message} type={'number'} />}
              control={control}
              name={'overriddenTotal'}
              rules={{ required: true, min: { value: 0, message: 'Total must be greater than 0' } }}
              defaultValue={defaultValues.overriddenTotal}
            />
            {errors?.overriddenTotal && <FormError>{errors?.overriddenTotal.message || 'Total is required'}</FormError>}
          </InputContainer>
        )}
      </InputGroup>

      {!watchedValues.overrideTotal && (
        <>
          <WideInputGroup>
            <FormLabel error={errors?.discountId?.message}>Discount</FormLabel>
            <Controller
              render={({ onChange, value }) => (
                <Select
                  onChange={v => {
                    setValue('voucherId', null);
                    onChange(v?.value);
                  }}
                  value={value ? { value: value, label: discountsOptions.find(option => option.value === value)?.label } : discountsOptions[0]}
                  options={discountsOptions}
                />
              )}
              control={control}
              name={'discountId'}
              defaultValue={defaultValues.discountId}
            />
            {errors?.discountId && <FormError>{errors?.discountId.message || 'Please select a discount'}</FormError>}
          </WideInputGroup>
          {watchedValues.discountId && !!vouchers.length && (
            <WideInputGroup>
              <FormLabel error={errors?.voucherId?.message}>Voucher</FormLabel>
              <Controller
                render={({ onChange, value }) => (
                  <Select
                    onChange={v => {
                      onChange(v?.value);
                    }}
                    value={value ? { value: value, label: vouchersOptions.find(voucher => voucher.value === value)?.label } : vouchersOptions[0]}
                    options={vouchersOptions}
                  />
                )}
                control={control}
                name={'voucherId'}
                defaultValue={defaultValues.voucherId}
                rules={{
                  required: true,
                  validate: (value: string) => {
                    if (value === '') {
                      return 'Please select a voucher';
                    }
                  }
                }}
              />
              {errors?.voucherId && <FormError>{errors?.voucherId.message || 'Please select a voucher'}</FormError>}
            </WideInputGroup>
          )}
        </>
      )}

      {!multiDay && (
        <InputGroup>
          <Controller
            control={control}
            name="overrideDuration"
            render={({ onChange, value }) => (
              <FormCheckbox
                itemsArray={[
                  {
                    name: 'Override Duration',
                    id: 'overrideDuration'
                  }
                ]}
                onChange={newVal => {
                  onChange(!!newVal[0]);
                }}
                value={value ? ['overrideDuration'] : []}
                column
                fontSize={14}
              />
            )}
            defaultValue={defaultValues.overrideDuration}
          />
        </InputGroup>
      )}
      <InputGroup>
        {watchedValues.overrideDuration && (
          <InputGroup>
            <FormLabel>Duration</FormLabel>
            <DurationSelector
              formOptions={formOptions}
              defaultDuration={defaultValues.overriddenDuration}
              options={{
                title: 'New duration',
                name: 'overriddenDuration'
              }}
            />
          </InputGroup>
        )}
      </InputGroup>

      <InputGroup>
        <Controller
          control={control}
          name="notifications_disabled"
          render={({ onChange, value }) => (
            <FormCheckbox
              onChange={(newValue: string[]) => {
                const isSelected = newValue.includes('notifications_disabled');
                onChange(isSelected);
              }}
              column
              fontSize={14}
              value={value ? ['notifications_disabled'] : []}
              itemsArray={[{ id: 'notifications_disabled', name: 'Disable notifications' }]}
            />
          )}
          defaultValue={false}
        />
      </InputGroup>
    </ModalLayout>
  );
};

export default EditBookingModal;
