import React, { FC } from 'react';
import { sentenceCase } from 'sentence-case';
import { FormLabel } from '../../../components/Shared/Forms/Forms';
import { toReadableDate } from '../../../utils/dates';
import { DiscountVoucherEditButton, VoucherContainer, VoucherContent } from '../Operations/styled';
import { VoucherProps } from './types';

const Voucher: FC<VoucherProps> = ({ voucher, handleEditVoucher, appUsers }) => {
  return (
    <VoucherContainer gap={8} onClick={handleEditVoucher}>
      <VoucherContent flex={1} width={100}>
        <FormLabel>Code</FormLabel>
        <span>{voucher.code}</span>
      </VoucherContent>
      <VoucherContent flex={1} width={200}>
        <FormLabel>Users</FormLabel>
        <span>
          {appUsers
            .filter(user => voucher.appUsers?.includes(user.id))
            .map(appUser => appUser?.name)
            .join(', ') || 'All'}
        </span>
      </VoucherContent>
      <VoucherContent flex={1} width={80}>
        <FormLabel>Quota</FormLabel>
        <span>{voucher.limit}</span>
      </VoucherContent>
      <VoucherContent flex={1} width={80}>
        <FormLabel>Uses</FormLabel>
        <span>{voucher.ordersCount || 0}</span>
      </VoucherContent>
      <VoucherContent flex={1} width={80}>
        <FormLabel>Status</FormLabel>
        <span>{sentenceCase(voucher.status || 'ACTIVE')}</span>
      </VoucherContent>
      <VoucherContent flex={1} width={80}>
        <FormLabel>Auto Apply</FormLabel>
        <span>{voucher.auto_apply ? 'Yes' : 'No'}</span>
      </VoucherContent>
      <VoucherContent flex={1} width={200}>
        <FormLabel>Created At</FormLabel>
        <span>{voucher.createdAt ? toReadableDate(new Date(voucher.createdAt)) : '-'}</span>
      </VoucherContent>
      <VoucherContent flex={1} width={60} onClick={handleEditVoucher}>
        <DiscountVoucherEditButton>Edit</DiscountVoucherEditButton>
      </VoucherContent>
    </VoucherContainer>
  );
};

export default Voucher;
