import React from 'react';
import { TabContainer, TabButton } from '../../views/Pets/Reports/Medical/styled';

const TabGroup = ({
  tabs,
  active,
  setActive,
  fontSize,
  canUnselect
}: {
  tabs: string[];
  active: string;
  setActive: React.Dispatch<React.SetStateAction<string | null>>;
  fontSize?: number;
  canUnselect?: boolean;
}) => {
  const handleTabChange = (tab: any) => {
    if (active !== tab) {
      setActive(tab);
    } else if (canUnselect) {
      setActive(null);
    }
  };
  return (
    <TabContainer>
      {tabs.map((tab: string) => (
        <TabButton key={tab} active={active === tab} onClick={() => handleTabChange(tab)} fontSize={fontSize} isFirst={tab === tabs[0]} isLast={tab === tabs[tabs.length - 1]}>
          {tab}
        </TabButton>
      ))}
    </TabContainer>
  );
};

export default React.memo(TabGroup);
