import React from 'react';
import useListBulkActions, { ItemsBulkActionsRef } from './useListBulkActions';
import { Pet } from '../components/Pets/types';
import PetRecordOptions from '../components/Pets/PetRecordOptions';
import ModalDialog from '../components/Modal/ModalDialog';
import { OPTION_DROPDOWN_MENU_BUTTON_TYPES } from '../components/Shared/Menus/OptionDropdown/types';
import AdjustAppUserOrderBillsBilledOnModal from '../views/Bookings/components/BookingsDrawer/AdjustAppUserOrderBillsBilledOnModal';
import AdjustAppUserOrdersPaymentModal from '../views/Bookings/components/BookingsDrawer/AdjustAppUserOrdersPaymentModal';
import { BranchAppUser } from '../views/Store/BranchBilling/types';
import AppUserRecordOptions from '../views/Pets/BranchAppUsers/AppUserRecordOptions';

const useAppUsersListBulkActions = ({ getAppUsers, ref, inDrawer }: { getAppUsers: () => BranchAppUser[]; ref?: React.ForwardedRef<ItemsBulkActionsRef>; inDrawer?: boolean }) => {
  const options = useListBulkActions<BranchAppUser>({
    getItems: getAppUsers,
    ref,
    inDrawer,
    Menu: ({ selectedItems, handleOnClickAction }) => {
      const appUsers = getAppUsers()?.filter(pet => selectedItems.includes(pet?.id));
      return <AppUserRecordOptions appUsers={appUsers} showAlways menuButtonType={OPTION_DROPDOWN_MENU_BUTTON_TYPES.MENU} onClickAction={handleOnClickAction} refetchPetsAfterTag />;
    }
  });

  return options;
};

export default useAppUsersListBulkActions;
