import React, { FC, useState } from 'react';
import { Controller } from 'react-hook-form';
import Colors from '../../../../Colors';
import { getBranchCurrencySymbol } from '../../../../utils/getBranchCurrencySymbol';
import { isProductNoCharge, isProductPostpaid } from '../../../../views/Store/ProductPrice';
import { ModalBody, ModalFooter } from '../../../Modal/styled';
import {
  FormButtonsContainer,
  FormCheckbox,
  FormError,
  FormInput,
  FormLabel,
  FormSelect,
  FormSubmitButton,
  FromButton,
  InputActionContainer,
  InputContainer,
  InputsWrapper,
  OptionsContainer,
  RadioBtnsGroup,
  WideInputGroup,
  selectTheme
} from '../../Forms/Forms';
import AppUsersList from '../../Lists/AppUsersList';
import { Container } from '../../Shared';
import { VerticallyCenteredLoader } from '../../Spinner';
import OrderGroups from './OrderGroups';
import PreOrderSummary from './PreOrderSummary';
import { NewBookingModalFormProps, getProductsItems } from './utils';
import ReactSelect from 'react-select';
import { useQuery, useReactiveVar } from '@apollo/client';
import { vars } from '../../../../reactive';
import { CALENDAR_VIEWS } from '../../../Calendar/types';
import { BranchRoom } from '../../../../views/Store/BranchRooms/types';
import { GetBranchRooms } from '../../../../queries';
import { Booking, BOOKING_TYPE } from '../../../../views/Bookings/types';
import { Divider } from '../../SideTabs/styles';
import { Form } from 'antd';
import ModalDialog, { ModalLayout } from '../../../Modal/ModalDialog';
import AppUserDetailsModal from '../../../UserDrawer/AppUserDetailsModal';
import NewBookingModal from './NewBookingModal';
import { hideCalendarActionMessage } from '../../../../reactive/actions';
import Common from '../../../../views/Store/Common';
import Input from 'react-select/dist/declarations/src/components/Input';
import Select from '../../Forms/Select';
import { CheckboxFromContainer } from '../../Forms/styled';

const NewBookingModalForm: FC<NewBookingModalFormProps> = newBookingModalFormProps => {
  const {
    loadingStartOrder,
    onSubmit,
    formOptions,
    appUserProfileResponse,
    selectedProductsResponse,
    allPets,
    selectedOrderGroups,
    defaultValues,
    orderGroups,
    productModalType,
    discounts,
    vouchers,
    busUsers,
    selectedId
  } = newBookingModalFormProps;
  const { data: { getBranchRooms: branchRooms } = {}, refetch: refetchRooms } = useQuery<{ getBranchRooms: BranchRoom }>(GetBranchRooms, {
    fetchPolicy: 'cache-and-network'
  });

  const selectedBookingType = useReactiveVar(vars.calendarSelectedBookingType);
  const multiStaffBookingType = selectedBookingType === CALENDAR_VIEWS.MULTI_STAFF;
  const isMultiDayCalendar = selectedBookingType === CALENDAR_VIEWS.MULTI_DAY;
  const isMultiDay = selectedProductsResponse.selectedProducts[0]?.booking_type === BOOKING_TYPE.MULTI_DAY;
  const [preOrderSummaryForAppUserItems, setPreOrderSummaryForAppUserItems] = useState({
    loadingSummary: false,
    preOrderSummaryForAppUser: {
      isAlreadySubscribed: false
    },
    shouldRenderFees: false
  });

  const { loadingSummary, preOrderSummaryForAppUser, shouldRenderFees } = preOrderSummaryForAppUserItems;

  const { control, errors, handleSubmit, watch } = formOptions;
  const { loadingAppUserProfile } = appUserProfileResponse;
  const { selectedProducts } = selectedProductsResponse;
  const selectedProduct = selectedProducts[0];
  const subscriptionProduct = selectedProduct?.type === 'subscription';
  const isProductChargeNow = !isProductNoCharge(selectedProduct);
  const { defaultAppUserId, defaultPets } = defaultValues;

  const watchedValues = watch(['overrideTotal', 'total', 'discountId']);

  const watchedOrderGroups = watch('orderGroups');

  const isMultiOrder = selectedOrderGroups.length > 1;

  const [{ isRecurring }] = getProductsItems({ orderGroups: watchedOrderGroups, products: selectedProducts }) || [{}];

  const submitHandler = handleSubmit!(values => {
    if (loadingStartOrder) {
      return;
    }
    onSubmit(values);
  });

  const postpaidProduct = isProductPostpaid(selectedProduct);
  const selectedUser = busUsers.find(({ id }) => id === selectedId) || {};
  const selectedBranchRoom = branchRooms?.find(({ id }) => id === selectedId) || {};
  const isMultiSlot = selectedProduct?.booking_type === BOOKING_TYPE.MULTI_SLOT;

  return (
    <ModalLayout
      buttons={
        <FormSubmitButton loading={loadingStartOrder} onClick={submitHandler} disabled={!shouldRenderFees || preOrderSummaryForAppUser?.isAlreadySubscribed || (subscriptionProduct && loadingSummary)}>
          {subscriptionProduct && loadingSummary ? 'Loading ...' : preOrderSummaryForAppUser?.isAlreadySubscribed ? 'Already subscribed' : 'Confirm'}
        </FormSubmitButton>
      }
      compact
    >
      <>
        <WideInputGroup marginBottom={0}>
          <FormLabel bold>Client Details</FormLabel>
          <FormLabel error={errors?.appUserId?.message}>Search by owner name</FormLabel>
          <InputActionContainer>
            <AppUsersList formOptions={formOptions as any} defaultValues={defaultAppUserId ? [defaultAppUserId] : []} width="400px" />
            {errors?.appUserId && <FormError>{errors?.appUserId.message || 'Please select a client'}</FormError>}
            <FromButton
              onClick={() => {
                ModalDialog.openModal({
                  content: () => (
                    <AppUserDetailsModal
                      onClose={() => {
                        ModalDialog.openModal({
                          content: () => <NewBookingModal defaultValues={{ appUserId: defaultAppUserId ? defaultAppUserId : '' }} />,
                          title: 'New',
                          onClose: () => {
                            vars.newBookingDefaultAppointments([]);
                            vars.selectedDate(null);
                            hideCalendarActionMessage();
                          },
                          onCloseBySave: () => {
                            setTimeout(() => {
                              const refetchAppointments = Common.get<() => Promise<{ data: { getBranchAppointments: Booking[] } }>>(`Bookings.GetBranchAppointments.refetch`);
                              refetchAppointments();
                              vars.selectedDate(null);
                            }, 3000);
                          }
                        });
                      }}
                    />
                  ),
                  title: 'Add Client'
                });
              }}
            >
              New Client
            </FromButton>
          </InputActionContainer>
        </WideInputGroup>

        {!productModalType && (
          <WideInputGroup marginBottom={0}>
            <FormLabel error={(Object.values(errors?.pets || {}) || [])?.find(e => e?.message)?.message}>Select pets {!allPets?.length && !loadingAppUserProfile && '(Select a client)'}</FormLabel>
            {loadingAppUserProfile && <VerticallyCenteredLoader size={15} />}
            {!loadingAppUserProfile && (
              <InputContainer marginBottom={20}>
                <Controller
                  control={control}
                  name={`pets`}
                  defaultValue={defaultPets}
                  render={({ onChange, value }) => (
                    <FormCheckbox error={(Object.values(errors?.pets || {}) || [])?.find(e => e?.message)?.message} itemsArray={allPets} onChange={onChange} value={value} column alternate />
                  )}
                  rules={{
                    required: true,
                    validate: (value: any) => {
                      if (value.length === 0) {
                        return 'Please select at least one pet';
                      }
                    }
                  }}
                />
              </InputContainer>
            )}
            {errors?.pets && <FormError>{(Object.values(errors?.pets || {}) || []).find(e => e?.message)?.message || 'Please select at least one pet'}</FormError>}
          </WideInputGroup>
        )}
        <Divider />

        <OrderGroups {...newBookingModalFormProps} />

        <FormLabel bold marginBottom={12}>
          Booking Options
        </FormLabel>

        {!watchedValues.overrideTotal && !isRecurring && !isMultiOrder && (
          <>
            <WideInputGroup marginBottom={0}>
              <FormLabel error={errors?.discountId?.message}>Discount</FormLabel>
              <Controller
                control={control}
                name="discountId"
                defaultValue={discounts[0]?.id}
                render={({ onChange, value }) => (
                  <Select
                    options={[{ value: '', label: 'Select...' }, ...discounts?.map(({ id, name }) => ({ value: id, label: name }))]}
                    value={discounts?.find(discount => discount.id === value) ? { value, label: discounts.find(discount => discount.id === value)?.name } : { value: '', label: 'Select...' }}
                    onChange={selectedOption => onChange(selectedOption?.value || '')}
                    theme={selectTheme}
                  />
                )}
              />
              {errors?.discountId && <FormError>{errors?.discountId.message || 'Please select a discount'}</FormError>}
            </WideInputGroup>

            {watchedValues.discountId && (
              <WideInputGroup marginBottom={0}>
                <FormLabel error={errors?.voucherId?.message}>Voucher</FormLabel>
                <Controller
                  control={control}
                  name="voucherId"
                  defaultValue={''}
                  render={({ onChange, value }) => (
                    <Select
                      options={[{ value: '', label: 'Select...' }, ...vouchers?.map(({ id, code }) => ({ value: id, label: code }))]}
                      value={vouchers?.find(voucher => voucher.id === value) ? { value, label: vouchers?.find(voucher => voucher.id === value)?.code } : { value: '', label: 'Select...' }}
                      onChange={selectedOption => onChange(selectedOption?.value || '')}
                      theme={selectTheme}
                    />
                  )}
                />
                {errors?.voucherId && <FormError>{errors?.voucherId.message || 'Please select a voucher'}</FormError>}
              </WideInputGroup>
            )}
          </>
        )}

        {!subscriptionProduct && !productModalType && (
          <WideInputGroup>
            <FormLabel>Assign booking(s) to Staff:</FormLabel>
            <Controller
              render={({ onChange, value }) => (
                <Select
                  theme={selectTheme}
                  onChange={onChange}
                  isMulti
                  value={value}
                  options={busUsers}
                  getOptionValue={busUser => busUser?.id}
                  getOptionLabel={busUser => busUser?.name}
                  name={'busUserId'}
                />
              )}
              control={control}
              name={'busUserId'}
              defaultValue={selectedUser && multiStaffBookingType ? [selectedUser] : []}
            />
          </WideInputGroup>
        )}
        {!subscriptionProduct && !productModalType && isMultiDay && (
          <>
            <WideInputGroup>
              <FormLabel>Branch Rooms</FormLabel>
              <Controller
                render={({ onChange, value }) => (
                  <Select
                    theme={selectTheme}
                    onChange={onChange}
                    value={value}
                    options={branchRooms}
                    getOptionValue={branchRoom => branchRoom?.id}
                    getOptionLabel={branchRoom => branchRoom?.name}
                    name={'branchRoomId'}
                  />
                )}
                control={control}
                name={'branchRoomId'}
                defaultValue={selectedBranchRoom && isMultiDayCalendar ? [selectedBranchRoom] : []}
              />
            </WideInputGroup>
            <Divider marginBottom="20px" />
          </>
        )}
        {!subscriptionProduct && !postpaidProduct && isRecurring && !productModalType && (
          <>
            <FormLabel bold>Actions</FormLabel>
            <WideInputGroup marginBottom={10}>
              <InputContainer isRow flex={1} marginBottom={6}>
                <Controller
                  render={({ onChange, value }) => (
                    <FormCheckbox
                      itemsArray={[
                        {
                          name: 'Send e-mail confirmation',
                          id: 'disableNotifications'
                        }
                      ]}
                      onChange={newVal => {
                        onChange(!!newVal[0]);
                      }}
                      value={value ? ['disableNotifications'] : []}
                      column
                      fontSize={14}
                    />
                  )}
                  control={control}
                  name={'disableNotifications'}
                />
              </InputContainer>
              <FormLabel marginLeft="24px">Collar will send the client a booking confirmation by email</FormLabel>
            </WideInputGroup>
          </>
        )}
        {!subscriptionProduct && !postpaidProduct && !isRecurring && (
          <>
            <FormLabel bold>Actions</FormLabel>
            <WideInputGroup marginBottom={0} marginTop={0}>
              {!productModalType && (
                <>
                  <WideInputGroup marginBottom={10} marginTop={12}>
                    <InputContainer isRow flex={1} marginBottom={6}>
                      <Controller
                        render={({ onChange, value }) => (
                          <FormCheckbox
                            itemsArray={[
                              {
                                name: 'Auto Confirm Payment',
                                id: 'autoConfirm'
                              }
                            ]}
                            onChange={newVal => {
                              onChange(!!newVal[0]);
                            }}
                            value={value ? ['autoConfirm'] : []}
                            column
                            fontSize={14}
                          />
                        )}
                        control={control}
                        name={'autoConfirm'}
                      />
                    </InputContainer>
                    <FormLabel marginLeft="24px">Collar will charge the client upon booking creation</FormLabel>
                  </WideInputGroup>

                  <WideInputGroup marginBottom={10}>
                    <InputContainer isRow flex={1} marginBottom={6}>
                      <Controller
                        render={({ onChange, value }) => (
                          <FormCheckbox
                            itemsArray={[
                              {
                                name: 'Send e-mail confirmation',
                                id: 'disableNotifications'
                              }
                            ]}
                            onChange={newVal => {
                              onChange(!!newVal[0]);
                            }}
                            value={value ? ['disableNotifications'] : []}
                            column
                            fontSize={14}
                          />
                        )}
                        control={control}
                        name={'disableNotifications'}
                      />
                    </InputContainer>
                    <FormLabel marginLeft="24px">Collar will send the client a booking confirmation by email</FormLabel>
                  </WideInputGroup>
                </>
              )}

              {isProductChargeNow && (
                <WideInputGroup>
                  <InputContainer isRow flex={1} marginBottom={6}>
                    <Controller
                      render={({ onChange, value }) => (
                        <FormCheckbox
                          itemsArray={[
                            {
                              name: 'Skip payment',
                              id: 'markProductNoCharge'
                            }
                          ]}
                          onChange={newVal => {
                            onChange(!!newVal[0]);
                          }}
                          value={value ? ['markProductNoCharge'] : []}
                          column
                          fontSize={14}
                        />
                      )}
                      control={control}
                      name={`markProductNoCharge`}
                    />
                  </InputContainer>
                  <FormLabel marginLeft="24px" marginBottom={20}>
                    Select to mark payment paid off Collar
                  </FormLabel>
                </WideInputGroup>
              )}
            </WideInputGroup>
          </>
        )}

        {/* {!subscriptionProduct && !productModalType && (
            <WideInputGroup>
              <InputContainer isRow flex={1} marginBottom={20}>
                <Controller
                  render={({ onChange, value }) => (
                    <RadioBtnsGroup
                      options={['Ignore stock']}
                      defaultValue={''}
                      onChange={() => {
                        onChange(!value);
                      }}
                      itemStyle={{ width: '100%' }}
                      name={`ignoreStock`}
                      inputType={'checkbox'}
                    />
                  )}
                  control={control}
                  name={`ignoreStock`}
                  defaultValue={false}
                />
                <FormLabel>({selectedProduct?.stock ? `Stock: ${selectedProduct?.stock}` : 'No stock'})</FormLabel>
              </InputContainer>
            </WideInputGroup>
          )} */}
        <Divider marginBottom="20px" />

        {<PreOrderSummary {...newBookingModalFormProps} setPreOrderSummaryForAppUserItems={setPreOrderSummaryForAppUserItems} />}
        <WideInputGroup marginBottom={0}>
          <InputContainer isRow flex={1} marginBottom={20}>
            <Controller
              render={({ onChange, value }) => (
                <FormCheckbox
                  itemsArray={[
                    {
                      name: 'Override total',
                      id: 'overrideTotal'
                    }
                  ]}
                  onChange={newVal => {
                    onChange(!!newVal[0]);
                  }}
                  value={value ? ['overrideTotal'] : []}
                  column
                  fontSize={14}
                />
              )}
              control={control}
              name={'overrideTotal'}
            />
          </InputContainer>
          {watchedValues.overrideTotal && (
            <InputContainer marginBottom={20}>
              <FormLabel error={errors?.total?.message}>Total</FormLabel>
              {isMultiOrder && (
                <FormLabel>
                  *Note that this total will be applied to all orders ({getBranchCurrencySymbol()}
                  {watchedValues.total} x {orderGroups.fields.length})
                </FormLabel>
              )}
              <Controller
                as={<FormInput error={!!errors?.total} type={'number'} fontSize={14} />}
                control={control}
                name={'total'}
                rules={{ required: true, min: { value: 0, message: 'Total must be greater than 0' } }}
                defaultValue={0}
              />
              {errors?.total && <FormError>{errors?.total.message || 'Total is required'}</FormError>}
            </InputContainer>
          )}
        </WideInputGroup>
      </>
    </ModalLayout>
  );
};

export default NewBookingModalForm;
