import { useReactiveVar } from '@apollo/client';
import React from 'react';
import { DefaultProfilePhoto, Divider } from '../../../../components/DrawerBar/styled';
import { TabsHeaderContainer } from '../../../../components/Shared/Shared';
import useIcons from '../../../../hooks/useIcons';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import useURLSearchParams from '../../../../hooks/useURLSearchParams';
import { vars } from '../../../../reactive';
import { VerticalDivider } from '../../../Store/BranchBilling/styled';
import { RecordBody } from '../../../styled';
import { ServiceHeaderContainer, ServiceNameInfoContainer } from '../BookingsBody/styled';
import BookingTagsQuickFilter from '../BookingsHeader/BookingTagsQuickFilter';
import { UserImage, UserName, UserNameImageContainer } from './styled';

const ServiceHeader = ({ appointments, product, filtersControl }) => {
  const isMultiStaffView = useReactiveVar(vars.isMultiStaffView);
  const icons = useIcons();
  const { mobile } = useMediaQuery({ mobile: true });
  const [selectedServices] = useURLSearchParams<string[]>('services');

  const name = product?.name;
  const profile_pic = product?.profile_pic;
  return (
    <>
      {!isMultiStaffView && !mobile && (
        <ServiceHeaderContainer>
          <ServiceNameInfoContainer>
            {selectedServices.length > 1 && (
              <RecordBody fontWeight="800" fontSize={16} noMargin width="auto" maxWidth={300} overflow="unset">
                {name} | {appointments?.length} {selectedServices.length !== 1 && appointments?.length === 1 ? 'Pet' : 'Pets'}
              </RecordBody>
            )}
            {selectedServices.length === 1 && (
              <>
                <RecordBody fontWeight="800" fontSize={16} noMargin width="auto" maxWidth={300} overflow="unset">
                  {name}
                </RecordBody>
                <RecordBody fontWeight="600" fontSize={14} width="auto" overflow="unset">
                  | {appointments?.length} {selectedServices.length !== 1 && appointments?.length === 1 ? 'Pet' : 'Pets'}
                </RecordBody>
              </>
            )}

            {selectedServices.length === 1 && <VerticalDivider maxHeight="30px" />}
            {selectedServices.length === 1 && (
              <TabsHeaderContainer padding="0" overflowX="scroll" width={selectedServices?.length === 1 ? '100%' : 'auto'}>
                <BookingTagsQuickFilter filtersControl={filtersControl} />
              </TabsHeaderContainer>
            )}
          </ServiceNameInfoContainer>
        </ServiceHeaderContainer>
      )}

      {isMultiStaffView && (
        <ServiceHeaderContainer>
          <UserNameImageContainer>
            {!profile_pic && <DefaultProfilePhoto image={icons?.user?.childImageSharp?.gatsbyImageData} alt={'userImage'} />}
            {profile_pic && <UserImage src={profile_pic} alt={name} />}
            <UserName>{name}</UserName>({appointments?.length || 0} Pets)
          </UserNameImageContainer>
        </ServiceHeaderContainer>
      )}
      {!mobile && <Divider />}
    </>
  );
};

export default ServiceHeader;
