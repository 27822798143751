import styled, { css } from 'styled-components';
import Colors from '../../Colors';
import { HoverRecordOptionsContainer } from '../../views/styled';

export const UserBookingsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
`;

export const ListView = styled.div<{ minHeight?: number; padding?: string }>`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 20px;
  padding: ${({ padding }) => padding || '20px'};
  overflow-y: auto;
  min-height: 200px;
  ${({ minHeight }) =>
    minHeight &&
    css`
      min-height: ${minHeight}px;
    `}
  flex: 1;
  @media (max-width: 768px) {
    border-radius: 0;
  }
`;

export const BookingsListCotainer = styled.div<{ flexOne?: boolean }>`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  ${({ flexOne }) =>
    flexOne &&
    css`
      flex: 1;
    `}
`;

export const SubscriptionsInvoiceListContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 20px;
  flex: 1;
  gap: 20px;
`;

export const BookingListItem = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ListHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ListHeaderContainer = styled.div``;

export const ListHeaderText = styled.span`
  font-size: 14px;
  font-weight: 800;
  color: #000;
`;

export const ListHeaderContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

export const BookingsDivider = styled.div`
  height: 1px;
  background-color: ${Colors.divider};
  margin: 10px 0;
`;

export const UserBookingContainer = styled.div<{ clickable?: boolean; selected?: boolean; disableHover?: boolean; autoHeight?: boolean; opacity?: number }>`
  height: ${({ autoHeight }) => (autoHeight ? 'auto' : '50px')};
  min-height: 50px;
  display: flex;
  align-items: center;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
  border-radius: 12px;
  opacity: ${({ opacity }) => (opacity ? opacity : 1)};
  /* background-color: ${({ selected }) => (selected ? '#f5f5f5' : '#fff')}; */
  padding: 0 8px;
  /* ${({ disableHover }) =>
    !disableHover &&
    css`
      &:hover {
        background-color: #f5f5f5;
        ${HoverRecordOptionsContainer} {
          visibility: visible;
        }
      }
    `} */
`;

export const BillingStatus = styled.div<{ noMaxWidth: boolean; red: boolean; blue: boolean; green: boolean; gray: boolean }>`
  height: 21px;
  border-radius: 6px;
  display: flex;
  padding: 4px 8px 5px 8px;
  ${({ noMaxWidth }) => !noMaxWidth && 'max-width: 60px;'}
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: ${Colors.black};
  font-weight: 600;
  background-color: ${({ red, blue, green, gray }) => {
    if (gray) return '#919191';
    if (red) return '#FFA79B';
    if (blue) return '#b3e2e6';
    if (green) return '#86DB5D';
    return '#FFA79B';
  }};
  @media (max-width: 768px) {
    align-items: center;
  }
`;

export const UserExtendButton = styled.div<{ noMargin?: boolean; backgroundColor?: string; color?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 44px;
  border-radius: 12px;
  margin-bottom: 20px;
  background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : '#3f3f3f')};
  color: ${({ color }) => (color ? color : Colors.white)};
  font-size: 14px;
  font-weight: 800;
  /* border: 1px solid ${Colors.black}; */
  transition: all 0.2s ease-in-out;
  ${({ noMargin }) =>
    noMargin &&
    css`
      margin-bottom: 0;
    `}
  &:hover {
    opacity: 0.8;
  }
  @media (max-width: 768px) {
    background-color: ${Colors.black};
    border: 1px solid ${Colors.black};
  }
`;

export const PetProfilePicsContainer = styled.div`
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: flex-start;
  position: relative;
`;

export const PetProfilePic = styled.div<{ isDuo?: boolean; isMulti?: boolean; bgUrl?: string }>`
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background-color: #f4f4f4;
  font-size: 12px;
  font-weight: 600;
  background-image: url(${({ bgUrl }) => bgUrl});
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  color: #000;
  ${({ isDuo }) =>
    isDuo &&
    css`
      width: 22px;
      height: 22px;
      border-radius: 22px;
      &:nth-child(even) {
        position: absolute;
        border-radius: 30px;
        width: 30px;
        height: 30px;
        top: 10px;
        left: 10px;
        border: 2px solid #fff;
      }
    `}
  ${({ isMulti }) =>
    isMulti &&
    css`
      display: flex;
      justify-content: center;
      position: absolute;
      border-radius: 27px;
      width: 30px;
      height: 30px;
      top: 10px;
      left: 10px;
      background-image: none;
      align-items: center;
      border: 2px solid #fff;
    `}
`;
